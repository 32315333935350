import {Box} from '@chakra-ui/react';
import styles from './styles';

export default function MicrosoftAuthCard({children, ...props}) {
    return (
        <Box sx={styles.MicrosoftAuthCard} {...props}>
            {children}
        </Box>
    );
}
