import {
    Box,
    InputGroup,
    InputRightElement,
    Tooltip,
} from '@chakra-ui/react';
import { Input } from '..';
import { QuestionIcon } from '@chakra-ui/icons';

export default function ChipInput({ ...rest }) {
    return (
        <Box>
            <Input {...rest} />
        </Box>
    );
}