import React from 'react';
import { Button, Row } from 'react-bootstrap';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
    Alert,
    AlertTitle,
} from '@mui/material';
import LoadingFieldDropdown from '../../../../components/presentational/LoadingFieldDropdown';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { axios } from '../../../../services/axios';
import { billingDayDarkMode } from '@/constants';
import { useSelector } from 'react-redux';

const useChangeBillingDay = ({
    handleCloseModal,
    openModal,
    currentBillingDay,
    companyID,
    handleRefreshModal,
    partnerID,
    wholesalerID,
    mode,
}) => {
    const [date, setDate] = React.useState(currentBillingDay);
    const [tncChecked, setTNCChecked] = React.useState(false);
    const [error, setError] = React.useState(null);

    const handleChangeDate = (e) => {
        setDate(e);
    };

    const handleChangeTNC = (e) => {
        setTNCChecked(e.target.checked);
    };

    const handleSubmit = () => {
        setError(null);
        axios
            .post(`/${mode}/UpdateBillingDay`, {
                newBillingDay: date,
                companyID,
                partnerID,
                wholesalerID,
            })
            .then((res) => {
                handleCloseModal();
                handleRefreshModal(res.data);
            })
            .catch((e) => {
                setError(
                    `Could not update billing day at this time.\n${e?.message}\n${e?.response.data?.message}\nStatus Code: ${e?.response?.status}\n`,
                );
            });
    };
    const warningText = [
        `By changing a ${mode?.toLowerCase()} billing date you are setting the ${mode?.toLowerCase()} invoice generation date to the new date selected.`,
        'This does not take into account charges from the existing period already allocated to the invoice.',
        'You may need to apply a manual adjustment to the last invoice in order to align these new charges.',
        'All future invoices will have a period starting from the selected date above.',
    ].join('\n\n');

    const warningTitle = 'Warning';

    const termsAndConditionsTitle = 'Agreement';

    const termsAndConditions = `I understand that I am changing the billing date on behalf of this ${mode?.toLowerCase()}.`;

    const modalTitle = 'Change Billing Date';
    return {
        handleCloseModal,
        handleSubmit,
        openModal,
        warningText,
        warningTitle,
        handleChangeDate,
        date,
        termsAndConditions,
        termsAndConditionsTitle,
        handleChangeTNC,
        tncChecked,
        modalTitle,
        fieldName: 'Billing Date',
        error,
    };
};

export default function ChangeBillingDay(props) {
    const { billingDayDropdown } = props;
    const {
        handleCloseModal,
        handleSubmit,
        openModal,
        warningText,
        warningTitle,
        handleChangeDate,
        date,
        termsAndConditions,
        termsAndConditionsTitle,
        handleChangeTNC,
        tncChecked,
        fieldName,
        modalTitle,
        error,
    } = useChangeBillingDay(props);

    const { darkMode } = useSelector((state) => state.settings);

    return (
        <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogTitle>{modalTitle}</DialogTitle>
            <DialogContent>
                <Box
                    className="change-billing-date"
                    sx={darkMode ? billingDayDarkMode : null}>
                    <DialogContentText>
                        {error && (
                            <Alert
                                style={{ marginTop: 5, whiteSpace: 'pre-line' }}
                                severity="error">
                                {error}
                            </Alert>
                        )}
                        <Row style={{ padding: 10, paddingBottom: 5 }}>
                            <LoadingFieldDropdown
                                dropDownValue="value"
                                displayField="label"
                                staticEnum={billingDayDropdown}
                                onChange={handleChangeDate}
                                noEmptyOption
                                fieldName={fieldName}
                                fieldValue={date}
                            />
                        </Row>
                        <Alert
                            style={{ marginTop: 10, whiteSpace: 'pre-line' }}
                            severity="error">
                            <AlertTitle>{warningTitle}</AlertTitle>
                            {warningText}
                        </Alert>
                        <Alert style={{ marginTop: 10 }} severity="info">
                            <AlertTitle>{termsAndConditionsTitle}</AlertTitle>
                            <FormControlLabel
                                control={<Checkbox />}
                                label={termsAndConditions}
                                onChange={handleChangeTNC}
                                className="agreement-checkbox"
                            />
                        </Alert>
                    </DialogContentText>
                </Box>
            </DialogContent>
            <DialogActions
                style={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Cancel
                </Button>
                <Button disabled={!tncChecked} onClick={handleSubmit}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}
