import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import Fof from '../../../pages/fof';
import unprotectedRoutes from '../../../config/unauthenticatedRoutes';

export default function ProtectedNoMatch() {
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const navigate = useNavigate();

    if (pathname === '/' || pathname === '/sso') {
        navigate('/dashboard-services');
    } else {
        unprotectedRoutes.map((route) => {
            if (pathname === route.path) {
                sessionStorage.clear();
                localStorage.clear();
                dispatch({type: 'CLEAR_TOKEN'});
                dispatch({type: 'LOGOUT'});
            }
        });
    }

    return <Fof />;
}
