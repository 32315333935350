import { Select } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

// react multi select uses label instead of name for the option text
// instead of changing every instance of this component to use label instead of name
// ill throw in a quick workaround so I dont need change anything else
/**
 * @typedef {Object} ChakraSelectProps
 * @property {{label: string, value: string | number}[]} options
 * @param {ChakraSelectProps & import('@chakra-ui/react').SelectProps} props
 * @returns {JSX.Element}
 */
export default function ChakraSelect({ options, ...props }) {
    const { darkMode } = useSelector((state) => state.settings);
    return (
        <Select
            {...props}
            style={{
                color: darkMode ? 'white' : null,
                backgroundColor: darkMode
                    ? 'var(--chakra-colors-dark-bgDark)'
                    : null,
            }}
            sx={{
                "& option[value='']": {
                    backgroundColor: darkMode ? '#121212' : null,
                    color: darkMode ? 'white' : null,
                }, // placeholder *option* should be subtle (firefox)
                '& > option[selected]': {
                    backgroundColor: 'brand.500',
                },
            }}>
            {options.map((option) => (
                <option
                    style={{
                        backgroundColor: darkMode ? '#121212' : null,
                        color: darkMode ? 'white' : null,
                    }}
                    key={option.value}
                    value={option.value}>
                    {option.name || option.label}
                </option>
            ))}
        </Select>
    );
}
