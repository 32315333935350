import { createContext, useContext, useState, useMemo } from 'react';

const TpmContext = createContext();

export const TpmProvider = ({ children }) => {
    const [state, setState] = useState({
        number: '',
        status: 'IDLE',
        error: '',
    });

    const [addressAction, setAddressAction] = useState('GET');

    const [telephoneNumbers, setTelephoneNumbers] = useState([
        { number: '', action: 'IDLE', code: '', check: 'IDLE' },
        { number: '', action: 'IDLE', code: '', check: 'IDLE' },
        { number: '', action: 'IDLE', code: '', check: 'IDLE' },
        { number: '', action: 'IDLE', code: '', check: 'IDLE' },
    ]);

    const value = useMemo(
        () => ({
            tpmNumber: [state, setState],
            tpmAddress: [addressAction, setAddressAction],
            tpmActivatedNumber: [telephoneNumbers, setTelephoneNumbers],
        }),
        [
            state,
            setState,
            addressAction,
            setAddressAction,
            telephoneNumbers,
            setTelephoneNumbers,
        ],
    );

    return <TpmContext.Provider value={value}>{children}</TpmContext.Provider>;
};

export const useTpmContext = () => {
    const context = useContext(TpmContext);

    if (!context) {
        throw new Error('useTpmContext must be used within a TpmProvider');
    }

    return context;
};
