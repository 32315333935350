import { FormControl, FormLabel, Skeleton, Input } from '@chakra-ui/react';
/**
 * @typedef {Object} SkeletonInputType
 * @property {string} label - inject as FormLabel of the Skeleton
 * @param  {import('@chakra-ui/react').SkeletonProps & SkeletonInputType} props
 * @returns 
 */
export const SkeletonInput = ({ label }, ...props) => (
    <FormControl>
        <FormLabel>{label}</FormLabel>
        <Skeleton className="!rounded-md" {...props}>
            <Input />
        </Skeleton>
    </FormControl>
);
