import React from 'react';
import { useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { Box, ChakraProvider, GridItem, Grid } from '@chakra-ui/react';
import Text from '../../../components/v4/Text';
import Heading from '../../../components/v4/Heading';
import chakratheme from '../../../chakratheme';

const useApproval = () => {
    const { state: modalState } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    const fields = [
        { placeholder: 'Legal Entity Name', value: modalState?.entityName },
        { placeholder: 'Friendly Company  Name', value: modalState?.name },
    ];

    return {
        modalState,
        denyPrompt: 'This action cannot be undone.',
        companyInfoHeader: 'Company Information',
        requiredForApprovalHeader: 'Required For Approval',
        fields,
        requiredForApprovalPlaceholder: 'Default Plan',
        requiredForApprovalValue:
            modalState?.defaultPlan || 'No default plan set',
        requiredForApprovalBorderColor: modalState?.defaultPlan
            ? '#5bc0de'
            : 'red',
    };
};
/*
 * Approval component that renders a form for the user to fill out if seeking approval
 */
const Approval = () => {
    const {
        modalState,
        denyPrompt,
        companyInfoHeader,
        requiredForApprovalHeader,
        fields,
        requiredForApprovalBorderColor,
        requiredForApprovalPlaceholder,
        requiredForApprovalValue,
    } = useApproval();

    return (
        <ChakraProvider theme={chakratheme} resetCSS={true}>
            <Heading as="h4" size="md">
                {companyInfoHeader}
            </Heading>

            <Grid mt={'10px'} templateColumns="repeat(2, 1fr)" gap={2}>
                {fields.map(({ placeholder, value }) => (
                    <>
                        <GridItem w="100%" h="10">
                            <Text align={'left'} fontWeight={'bold'}>
                                {placeholder}
                            </Text>
                        </GridItem>
                        <GridItem w="100%" h="10">
                            <Text align={'left'}> {value} </Text>
                        </GridItem>
                    </>
                ))}
            </Grid>

            <Heading as="h4" size="md">
                {requiredForApprovalHeader}
            </Heading>
            <Box
                borderRadius={'5px'}
                border={`2px solid ${requiredForApprovalBorderColor}`}
                pl={'10px'}
                mt={'10px'}
                display={'flex'}
                justifyContent={'left'}
            >
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignSelf={'center'}
                    mt={'10px'}
                    mb={'10px'}
                >
                    <Box display={'flex'} flexDirection={'row'} gap={10}>
                        <Text alignSelf={'left'} fontWeight={'bold'}>
                            {' '}
                            {requiredForApprovalPlaceholder}{' '}
                        </Text>
                        <Text alignSelf={'justify'}>
                            {' '}
                            {requiredForApprovalValue}{' '}
                        </Text>
                    </Box>
                </Box>
            </Box>
        </ChakraProvider>
    );
};

export default Approval;
