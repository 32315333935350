import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Tooltip, forwardRef } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import Button from 'components/v4/Button';
import Grid from 'components/v4/Grid';
import Input from 'components/v4/Input';
import FormLabel from 'components/v4/FormLabel';
import updateServiceProfiles from 'API/ServiceProfiles/updateServiceProfiles';
import { Select as _MultiSelect, Select as _Select } from 'chakra-react-select';
import { multiSelectDataMapper, toSentenceCase } from 'utils/utils';
import style from './styles';
import FooterContainer from './FooterContainer';
import Heading from 'components/v4/Heading';
import Alert from 'components/v4/Alert';
import AlertDescription from 'components/v4/AlertDescription';
import AlertIcon from 'components/v4/AlertIcon';
import createServiceProfile from 'API/ServiceProfiles/createServiceProfile';
import LoadingIndicator from 'components/v4/LoadingIndicator';

import useFieldValidation from 'hooks/useFieldValidation';
import FieldValidation from 'components/v4/FieldValidation';

import { validationSchemaACS } from './schema';
import getACSRegions from 'API/ServiceProfiles/getACSRegions';
import getACSProfile from 'API/ServiceProfiles/getACSProfile';
import walkthroughIds from '../../walkthroughIds.js';
import { defaultChakraSelectStyle, chakraSelectDarkMode } from '@/constants';
import config from '@/config.json';

const TEMPLATE_NAME = 'ACS Direct Routing';

const MultiSelect = forwardRef((props, ref) => (
    <_MultiSelect selectedOptionColor="brand" ref={ref} {...props} />
));

const Select = forwardRef((props, ref) => (
    <_Select selectedOptionColor="brand" ref={ref} {...props} />
));
export default function ACS({
    mode,
    data,
    level,
    handleBackButton,
    visibleScopeOptions,
}) {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const [regionsEnum, setRegionsEnum] = useState([]);

    const { requiredScope } = useSelector((state) => state.login);
    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);
    const chakraStyles = {
        ...defaultChakraSelectStyle,
        ...chakraSelectDark,
    };

    const {
        currentWholesaler: wholesalerID,
        currentPartner: partnerID,
        wholesalers,
        companiesAndPartners,
    } = useSelector((state) => state.navigationLists);

    const { ...ufElements } = walkthroughIds.serviceProfiles;

    const DEFAULT_ACS_PAYLOAD = {
        name: TEMPLATE_NAME,
        partnerID: level === 'partner' ? partnerID : undefined,
        wholesalerID: level === 'partner' ? undefined : wholesalerID,
        voicePolicies: [],
        dialPlans: [],
        regions: [],
        visibleScope: level === 'partner' ? 20 : 60,
    };
    const [modalState, setModalState] = useState(data || DEFAULT_ACS_PAYLOAD);
    const { validationErrors, validatefn } = useFieldValidation(
        modalState,
        validationSchemaACS,
    );

    useEffect(() => {
        if (mode !== 'EDIT' && level !== 'partner' && wholesalers?.length > 0) {
            const wsName = wholesalers.find(
                ({ id, name }) => id === wholesalerID,
            )?.name;
            handleChangeState({
                name: `${wsName} - ${TEMPLATE_NAME} Wholesaler Profile`,
            });
        } else if (
            mode !== 'EDIT' &&
            level === 'partner' &&
            wholesalers?.length > 0
        ) {
            const partnerName = companiesAndPartners.find(
                ({ id, name }) => id === partnerID,
            )?.name;
            handleChangeState({
                name: `${partnerName} - ${TEMPLATE_NAME} Partner Profile`,
            });
        }
    }, [mode, companiesAndPartners, wholesalers, level]);

    /*
     * A function to get the current scope and append it for API calls
     */
    const scope = () => {
        if (level === 'partner') {
            return `Partner/${partnerID}`;
        } else {
            return `Wholesaler/${wholesalerID}`;
        }
    };

    // function to set modal state with a given payload
    const handleChangeState = (payload) => {
        setModalState((prev) => ({
            ...prev,
            ...payload,
        }));
    };

    const handleSubmit = () => {
        setError(null);
        //validate
        if (!validatefn()) {
            setError({
                response: {
                    data: {
                        message:
                            'There are some validation errors in the form. Please check and try again.',
                    },
                },
            });
            return;
        }
        setIsLoading(true);
        let result = {};
        const params = {
            type: 'ACSDirectRouting',
            payload: modalState,
            level,
            scope: requiredScope,
        };
        if (mode === 'EDIT') {
            result = updateServiceProfiles(params);
        } else {
            result = createServiceProfile(params);
        }
        result
            .then((res) => handleBackButton())
            .catch((e) => setError(e))
            .finally(() => setIsLoading(false));
    };
    const handleGetDropdown = () => {
        setIsLoading(true);
        const fetchSPData = mode === 'EDIT' && data;
        Promise.all([
            fetchSPData
                ? getACSProfile({ level: scope(), scope: requiredScope })
                : new Promise((resolve, reject) =>
                      setTimeout(() => {
                          resolve();
                      }, 1),
                  ),
            getACSRegions({ scope: scope() }),
        ])
            .then(([spResult, regionsResult]) => {
                setRegionsEnum(regionsResult.data.map(multiSelectDataMapper));
                if (fetchSPData) {
                    const regions = spResult.data.regions.map(
                        multiSelectDataMapper,
                    );

                    setModalState({
                        ...spResult.data,
                        regions,
                    });
                }
            })
            .catch((e) => {
                setError(e);
            })
            .finally(() => {
                setTimeout(() => {
                    setIsLoading(false);
                }, 500);
            });
    };

    useEffect(() => {
        handleGetDropdown();
    }, [mode, data, level]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Box sx={{ maxW: '2xl', minWidth: 'xl', margin: 'auto' }}>
            <Heading fontSize="3xl" as="h2" sx={{ marginBottom: '36px' }}>
                {`${toSentenceCase(mode)} ${modalState.name || data?.name}`}
            </Heading>

            {error && (
                <Alert status="error" sx={{ marginTop: '1rem' }}>
                    <AlertIcon />
                    <AlertDescription>
                        {error?.response?.data?.message ||
                            'An error occured. Please try again later.'}
                    </AlertDescription>
                </Alert>
            )}

            <Grid columns={1} gap={3}>
                <Box sx={style.fieldContainer}>
                    <FormLabel sx={style.label}>Service name</FormLabel>
                    <Input
                        value={modalState.name}
                        onChange={(e) =>
                            handleChangeState({ name: e.target.value })
                        }
                        sx={style.inputField}
                        data-walkthroughid={ufElements.ufServiceNameInput}
                    />
                    <FieldValidation errors={validationErrors?.name} />
                </Box>

                <Box sx={style.fieldContainer}>
                    <FormLabel sx={style.label}>
                        Visible Scope &nbsp;
                        <Tooltip
                            label="Determine visibility and service creation level"
                            hasArrow
                            placement="top-end">
                            <InfoIcon />
                        </Tooltip>
                    </FormLabel>
                    {modalState.visibleScope <= requiredScope ? (
                        <Select
                            defaultValue={visibleScopeOptions.find(
                                (option) =>
                                    option.value === modalState.visibleScope,
                            )}
                            options={visibleScopeOptions}
                            chakraStyles={chakraStyles}
                            onChange={(selectedOption) => {
                                handleChangeState({
                                    visibleScope: selectedOption.value,
                                });
                            }}
                            selectedOptionColor="brand"
                        />
                    ) : (
                        <Input
                            isDisabled
                            value={config.scope.json[modalState.visibleScope]}
                        />
                    )}
                </Box>

                <Box
                    sx={style.fieldContainer}
                    data-walkthroughid={ufElements.ufRegionsSelect}>
                    <FormLabel sx={style.label}>Regions</FormLabel>
                    <MultiSelect
                        isMulti
                        name="regions"
                        placeholder="- Select Regions -"
                        closeMenuOnSelect={false}
                        tagVariant="solid"
                        onChange={(selectedOptions) => {
                            handleChangeState({
                                regions: selectedOptions,
                            });
                        }}
                        options={regionsEnum}
                        defaultValue={modalState.regions}
                        chakraStyles={chakraStyles}
                    />
                    <FieldValidation errors={validationErrors?.regions} />
                </Box>

                <Box sx={style.fieldContainer}>
                    <FormLabel sx={style.label}> Max Channel Count </FormLabel>
                    <Input
                        placeholder="Max Channel Count"
                        onChange={(e) => {
                            handleChangeState({
                                maxChannel: e.target.value,
                            });
                        }}
                        defaultValue={modalState.maxChannel}
                        data-walkthroughid={ufElements.ufMaxChannelsInput}
                    />
                    <FieldValidation errors={validationErrors?.maxChannel} />
                </Box>
            </Grid>
            <Box className="mb-9" aria-label="spacing box" />

            <FooterContainer>
                <Button
                    variant="outline"
                    w={{ base: 'full', md: '80px' }}
                    sx={{ background: 'white' }}
                    onClick={handleBackButton}
                    data-walkthroughid={ufElements.ufCloseButton}>
                    Close
                </Button>
                <Button
                    w={{ base: 'full', md: '80px' }}
                    onClick={handleSubmit}
                    data-walkthroughid={ufElements.ufUpdateButton}>
                    {mode === 'ADD' ? 'Add' : 'Update'}
                </Button>
            </FooterContainer>
        </Box>
    );
}
