import { useEffect, useState } from 'react';
import Button from '../../../../components/v4/Button';
import { useMfaCode } from '../';
// import config from '../../../../config.json';

import MfaWizardStepProgress from './MfaWizardStepProgress';
import MfaCodeBox from './MfaCodeBox';

import { Box, Text } from '@chakra-ui/react';
import Heading from '../../../../components/v4/Heading';
// import Text from '../../../../components/v4/Text';
import Alert from '../../../../components/v4/Alert';
import AlertDescription from '../../../../components/v4/AlertDescription';
import AlertIcon from '../../../../components/v4/AlertIcon';

export default function MfaWizardStepBodyTwo({
    setStep,
    step,
    username,
    password,
    authResponse,
}) {
    const {
        mfaCode,
        mfaCodeObj,
        shouldWeSwapToNextInput,
        shouldWeGoToPreviousInput,
        mfaCodeIndexDisabled,
        refs,
        hasEnteredCode,
    } = useMfaCode();

    const [mfaCodeError, setMfaCodeError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    function onMfaLogin(e) {
        e.preventDefault();
        mfaLogin();
    }

    function mfaLogin() {
        setIsLoading(true);
        fetch(`${import.meta.env.VITE_APP_API_URL}/AdminUser/MFA`, {
            headers: {
                authorization: `bearer ${authResponse.token}`,
                accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json;',
                'x-requestscope': '20',
            },
            method: 'POST',
            mode: 'cors',
            credentials: 'omit',
            body: JSON.stringify({ code: mfaCode, username, password }),
        }).then(async (response) => {
            setIsLoading(false);
            const { status } = response;
            if (status === 400) {
                const data = await response.json();
                if (
                    data.message ===
                    'Unable to validate MFA sync. Please try again'
                ) {
                    setMfaCodeError(true);
                }
            } else {
                setMfaCodeError(false);
                setStep(3);
            }
        });
    }

    useEffect(() => {
        if (hasEnteredCode) {
            mfaLogin();
        }
    }, [hasEnteredCode]);

    return (
        <form onSubmit={onMfaLogin}>
            <Box>
                <MfaWizardStepProgress step={step} />
            </Box>
            <Box padding={'20px 30px'}>
                <Heading
                    as="h2"
                    sx={{ textAlign: 'center', marginBottom: 25 }}
                    color="black">
                    Enter your code
                </Heading>
                <Text fontSize="md" sx={{ marginBottom: 3 }} color="black">
                    Enter the six digit authentication code generated by the
                    authenticator app.
                </Text>
                <MfaCodeBox
                    mfaCodeObj={mfaCodeObj}
                    shouldWeSwapToNextInput={shouldWeSwapToNextInput}
                    shouldWeGoToPreviousInput={shouldWeGoToPreviousInput}
                    mfaCodeIndexDisabled={mfaCodeIndexDisabled}
                    refs={refs}
                    hasEnteredCode={hasEnteredCode}
                />
                {mfaCodeError && (
                    <Alert status="error">
                        <AlertIcon />
                        <AlertDescription className="!text-black">
                            The MFA code you entered is incorrect.
                        </AlertDescription>
                    </Alert>
                )}
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    padding: '0 15px',
                    height: '60px',
                }}>
                <Button
                    variant="outline"
                    className="secondary"
                    onClick={() => setStep(1)}>
                    Back
                </Button>
                <Button
                    type="submit"
                    disabled={!hasEnteredCode || isLoading}
                    onClick={() => onMfaLogin()}>
                    {isLoading ? 'Loading...' : 'Next'}
                </Button>
            </Box>
        </form>
    );
}
