import {
    SimpleGrid,
    Box,
    Heading,
    SkeletonCircle,
    Stack,
} from '@chakra-ui/react';
import { SkeletonInput } from 'components/v4/SkeletonInput/Index';
import { Button as BsButton } from 'react-bootstrap';

export default function SkeletonBuilderPage({ handleNavigateButton }) {
    return (
        <>
            <Heading as="h3" fontSize="1.75rem">
                Builder
            </Heading>

            <Box className="flex flex-col">
                <Box className="mx-auto mt-4 w-full min-w-[980px]">
                    <Box as="form">
                        <Box className="pt-0" id="builder-form-wrapper">
                            <SimpleGrid columns={2} spacing={4}>
                                <SkeletonInput
                                    className="min-w-[478px]"
                                    label="Account Name"
                                />
                                <SkeletonInput
                                    className="min-w-[478px]"
                                    label="Display Name"
                                />
                                <SkeletonInput
                                    className="min-w-[478px]"
                                    label="Timezone"
                                />
                                <SkeletonInput
                                    className="min-w-[478px]"
                                    label="Language"
                                />
                                <SkeletonInput
                                    className="min-w-[478px]"
                                    label="Template"
                                />
                            </SimpleGrid>
                        </Box>
                        <Box className="mt-4 flex justify-center">
                            <SkeletonCircle size="240" />
                        </Box>
                        <Stack
                            // spacing="6"
                            px={{ base: '4', md: '6' }}
                            pt={{ base: '5', md: '6' }}
                            pb="2px"
                            sx={{
                                justifyContent: 'center',
                                width: '170px',
                                margin: '0 auto',
                            }}
                            direction={{ base: 'column', md: 'row' }}
                        >
                            <Box>
                                <BsButton
                                    variant="secondary"
                                    className="h-10 min-w-[5rem] font-semibold"
                                    onClick={handleNavigateButton}
                                >
                                    Back
                                </BsButton>
                            </Box>
                            <Box>
                                <BsButton
                                    variant="primary"
                                    type="submit"
                                    className="ml-2 h-10 min-w-[5rem] font-semibold"
                                >
                                    Create
                                </BsButton>
                            </Box>
                        </Stack>
                    </Box>
                </Box>
            </Box>
        </>
    );
}
