// only needs to be imported once
import {
    Column,
    Table,
    ColumnSizer,
    Grid,
    CellMeasurer,
    CellMeasurerCache,
    AutoSizer,
    defaultTableHeaderRowRenderer,
} from 'react-virtualized';
import {InputGroup, InputLeftElement, Input, Box} from '@chakra-ui/react';
import {SearchIcon} from '@chakra-ui/icons';
import {useEffect} from 'react';
import {useVirtualizedTable} from './VirtualizedTableProvider';

// import {numbersAvailableMock, numbersChosenMock} from './mockdata';
import './virtualizedStyle.css';
import useVirtualizedTableFilter from 'hooks/useVirtualizedTableFilter';

import walkthroughIds from './walkthroughIds';

const cache = new CellMeasurerCache({
    defaultHeight: 100,
    minHeight: 100,
});

const {ufTransferListSearchInput, ufTransferListCheckbox} =
    walkthroughIds.teamsPhoneMobile;

export const SearchBar = ({onSearch, query}) => {
    return (
        <InputGroup>
            <InputLeftElement children={<SearchIcon />} />{' '}
            <Input
                type="text"
                placeholder="Search numbers"
                onChange={(e) => {
                    const value = e.target.value;
                    onSearch(value);
                }}
                value={query}
                data-walkthroughid={ufTransferListSearchInput}
            />
        </InputGroup>
    );
};

export default function VirtualizedTable({
    checkedArray = [],
    onClick = (e, v) => {},
    data = [],
    captionName,
    query,
}) {
    const {
        handleChangeSearch,
        isLoading,
        setSearchField,
        filteredData,
        tableData,
    } = useVirtualizedTableFilter({data});

    useEffect(() => {
        setSearchField('telephoneNumber');
    }, []);
    if (data.length === 0 || isLoading) {
        return <div> </div>;
    }
    return (
        <div className="max-w-[300px] min-w-[450px] min-h-[525px] bg-white overflow-hidden">
            <caption className="block text-center text-4xl text-gray-600 font-bold pt-2 pb-0 px-6 min-h-[50px]">
                {captionName} ({tableData.length})
            </caption>
            <Box className="py-2 px-4">
                <SearchBar onSearch={handleChangeSearch} />
            </Box>
            <AutoSizer>
                {({height, width}) => (
                    <Table
                        width={width}
                        height={height}
                        headerHeight={41.5}
                        rowHeight={54}
                        deferredMeasurementCache={cache}
                        rowCount={filteredData.length}
                        headerRowRenderer={(props) => {
                            return (
                                <div className="table-row">
                                    {props.columns.map((v, index) => {
                                        return (
                                            <div className="ReactVirtualized__Table__headerColum__custom-header-row">
                                                {index === 0 ? (
                                                    <div className="min-w-[60px] text-center text-xs text-[var(--chakra-colors-gray-600)] font-bold">
                                                        ACTION
                                                    </div>
                                                ) : (
                                                    v
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        }}
                        rowGetter={({index}) => filteredData[index]}>
                        <Column
                            className="truncate-none"
                            label="Action"
                            cellRenderer={CheckboxCell}
                            dataKey="disabled"
                            columnData={{
                                onClick,
                                checkedArray,
                            }}
                        />
                        <Column
                            label="Telephone Number"
                            dataKey="telephoneNumber"
                            cellRenderer={ColumnAutoSize}
                            minWidth="260px"
                        />
                        <Column
                            label="Status"
                            dataKey="status"
                            cellRenderer={ColumnAutoSize}
                            minWidth="100px"
                        />
                    </Table>
                )}
            </AutoSizer>
        </div>
    );
}

const ColumnAutoSize = ({dataKey, parent, rowData, rowIndex}) => {
    return (
        <CellMeasurer
            cache={cache}
            columnIndex={0}
            key={dataKey + ' row ' + rowIndex}
            parent={parent}
            rowIndex={rowIndex}>
            <span style={{minWidth: '100px'}}>{rowData[dataKey]}</span>
        </CellMeasurer>
    );
};

const CheckboxCell = ({dataKey, parent, rowData, columnData, rowIndex}) => {
    return (
        <CellMeasurer
            cache={cache}
            columnIndex={0}
            key={dataKey}
            parent={parent}
            rowIndex={rowIndex}
            data-walkthroughid={ufTransferListCheckbox}>
            <span>
                <input
                    type="checkbox"
                    className="w-4 h-4"
                    disabled={rowData.disabled}
                    onClick={(e) => {
                        columnData.onClick(e, rowData);
                    }}
                    checked={columnData.checkedArray.some(
                        (checked) => checked.id === rowData.id,
                    )}
                />
            </span>
        </CellMeasurer>
    );
};
