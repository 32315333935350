import { axios } from '@/services/axios';

export const getPasswordPolicy = async (currentCompany) => {
    const res = await axios.get(`/passwordpolicy/${currentCompany}`);
    return res.data;
};

export const updatePasswordPolicy = async (data) => {
    const res = await axios.put('/passwordpolicy', data);
    return res.data;
};

export const postPasswordPolicy = async (data) => {
    const res = await axios.post('/passwordpolicy', data);
    return res.data;
};
