export default {
    teamsPhoneMobile: {
        ufPhoneNumberInput: 'teams-phone-mobile/input/1',
        ufActivateButton: 'teams-phone-mobile/button/1',
        ufCancelButton: 'teams-phone-mobile/button/2',

        ufAuthorizationSendSmsButton: 'teams-phone-mobile/button/3',
        ufAuthorizationSendEmailButton: 'teams-phone-mobile/button/4',
        ufAuthorizationCancelButton: 'teams-phone-mobile/button/5',

        ufTransferListPortingButton: 'teams-phone-mobile/button/6',
        ufTransferListImportCsvButton: 'teams-phone-mobile/button/7',
        ufTransferListSearchInput: 'teams-phone-mobile/input/2',
        ufTransferListCheckbox: 'teams-phone-mobile/checkbox/1',
        ufTransferListMoveRightButton: 'teams-phone-mobile/button/8',
        ufTransferListMoveLeftButton: 'teams-phone-mobile/button/9',
        ufTransferListSwapRightButton: 'teams-phone-mobile/button/10',
        ufTransferListSwapLeftButton: 'teams-phone-mobile/button/11',
        ufTransferListNextButton: 'teams-phone-mobile/button/12',
        ufTransferListCancelButton: 'teams-phone-mobile/button/13',

        ufTpmSuccessDoneButton: 'teams-phone-mobile/button/14',
    },

    teamsPhoneMobileTrial: {
        ufPhoneNumberInput: 'teams-phone-mobile/trial/input/1',
        ufNextButton: 'teams-phone-mobile/trial/button/1',
        ufCancelButton: 'teams-phone-mobile/trial/button/2',

        ufMfaCode: 'teams-phone-mobile/trial/div/1',
        ufMfaNextButton: 'teams-phone-mobile/trial/button/3',
        ufMfaCancelButton: 'teams-phone-mobile/trial/button/4',

        ufTrialPromptAddButton: 'teams-phone-mobile/trial/button/5',
        ufTrialPromptSkipButton: 'teams-phone-mobile/trial/button/6',

        ufTrialTableNumberInput: 'teams-phone-mobile/trial/input/2',
        ufTrialTableNumberSendButton: 'teams-phone-mobile/trial/button/7',
        ufTrialTableNumberCodeInput: 'teams-phone-mobile/trial/input/3',
        ufTrialTableCheckButton: 'teams-phone-mobile/trial/button/8',
        ufTrialTableNextButton: 'teams-phone-mobile/trial/button/9',
        ufTrialTableCancelButton: 'teams-phone-mobile/trial/button/10',

        ufTrialTableFinishDoneButton: 'teams-phone-mobile/trial/button/11',
        ufTrialTableFinishAddButton: 'teams-phone-mobile/trial/button/12',
    },
};
