import { Link } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/react';

import { Button, Heading } from '@/components/v4';

export default function RegisterSuccess({ email }) {
    const activationSentHeading = 'Activation Email Sent!';
    const activationSentBody = `We've sent an activation email to ${
        email || 'your email account'
    }.\nClick the confirmation link in that email to begin using your account.`;
    const loginText = 'Login';

    return (
        <Box>
            <Heading
                as={'h2'}
                sx={{
                    textAlign: 'center',
                }}
                className="text-black">
                {activationSentHeading}
            </Heading>
            <Box sx={{ marginBottom: '25px', display: 'flex' }} />
            <Text whiteSpace={'pre-line'} fontSize="md" className="!text-black">
                {activationSentBody}
            </Text>
            <Box />
            <Box sx={{ marginBottom: '25px', display: 'flex' }} />
            <Box display={'flex'} justifyContent={'center'}>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <Button
                        colorScheme="messenger"
                        // _hover={{ textDecoration: 'none' }}
                        sx={{
                            textAlign: 'center',
                            margin: '10px auto 0 auto',
                            display: 'block',
                            textTransform: 'uppercase',
                        }}>
                        {loginText}
                    </Button>
                </Link>
            </Box>
        </Box>
    );
}
