import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
    Button,
    Box,
    Divider,
    Heading,
    InputGroup,
    InputRightElement,
    Text,
    Grid,
    GridItem,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    forwardRef,
} from '@chakra-ui/react';
import { AttachmentIcon, CalendarIcon } from '@chakra-ui/icons';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';

import { Form } from 'components';
import { Checkbox, Input as ChakraInput } from '@/components/v4';
import ExistingNumbersTable from './ExistingNumbersTable';
import { chakraDatepicker, chakraDatepickerDarkMode } from '@/constants';

import './style.css';

const Input = forwardRef((props, ref) => (
    <ChakraInput {...props} ref={ref} color="gray.700" />
));

import { TpmWrapper } from 'pages/tpm';

import { AutoResizeTextArea } from 'components/v4/Textarea';

import walkthroughIds from '../walkthroughIds';
import {
    ChakraDatePickerDarkModeStyles,
    ChakraDatePickerStyles,
} from '@/chakratheme';

/**
 * Bring existing numbers tab of the Numbers sidebar options
 * Used for companies to bring numbers from a previous carrier/system to TCAP
 */
const ExistingNumbersForm = () => {
    const dispatch = useDispatch();
    const [fileUpload, setFileUpload] = useState(null);

    const preloadNumbersText =
        'I require the number(s) to be loaded into the portal as soon as the porting request has been validated and prior to porting. Billing occurs from the date a number is added.';

    // state access
    const { loading, state, stateError } = useSelector((state) => {
        return { ...state.modal };
    });

    const { currentCompany } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    const { darkMode } = useSelector((state) => {
        return { ...state.settings };
    });

    const [date, setDate] = useState(new Date());

    const { bringExistingNumber } = walkthroughIds;

    // function to set modal state easily
    const setState = (payload) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload,
        });
    };

    // validation function for submission of the form
    const validateOnSubmit = () => {
        const aggregateNumbers =
            state?.aggregateTable.filter(
                (v) => v.from?.length > 0 || v.to?.length > 0,
            ) ?? [];
        const newErrorState = {
            numberlist:
                aggregateNumbers.length < 1 &&
                'At least 1 number must be ported.',
            bill:
                fileUpload?.type !== 'application/pdf' &&
                'Field is required and only pdf file is allowed.',
            carrier:
                (!state?.carrier || state?.carrier?.length < 1) &&
                'Field is required.',
            authorizedSignerEmail:
                (!state?.authorizedSignerEmail ||
                    state?.authorizedSignerEmail?.length < 1) &&
                'Field is required.',
            authorizedSignerName:
                (!state?.authorizedSignerName ||
                    state?.authorizedSignerName?.length < 1) &&
                'Field is required.',
            authorizedSignerNumber:
                (!state?.authorizedSignerNumber ||
                    state?.authorizedSignerNumber?.length < 1 ||
                    !Number(state?.authorizedSignerNumber)) &&
                'Field is required.',
        };
        dispatch({
            type: 'CHANGE_MODAL_STATE_ERROR',
            payload: newErrorState,
        });

        const tableError = stateError?.aggregateTable?.some((v) =>
            Boolean(v.to || v.from),
        );
        return !(
            tableError ||
            newErrorState.numberlist ||
            newErrorState.bill ||
            newErrorState.carrier ||
            newErrorState.authorizedSignerName ||
            newErrorState.authorizedSignerNumber ||
            newErrorState.authorizedSignerEmail
        );
    };

    // opens a modal and sets hash when the component loads
    useEffect(() => {
        dispatch({
            type: 'OPEN_MODAL',
            payload: {
                mode: 'Edit',
                state: {
                    preferredDate: Date.now(),
                    aggregateTable: [{ id: 0, from: '', to: '' }],
                },
                hash: '',
                loading: false,
                show: true,
            },
        });
        dispatch({
            type: 'CHANGE_MODAL_HASH',
        });
    }, []);

    // change handlers
    const carrierChangeHandler = (e) => {
        setState({ carrier: e.target.value });
    };

    const agreetermsChangeHandler = () => {
        setState({
            agreeterms: !state?.agreeterms,
        });
    };

    const preloadNumbersChangeHandler = () => {
        setState({
            preloadNumbers: !state?.preloadNumbers,
        });
    };

    const commentsChangeHandler = (e) => {
        setState({ comments: e.target.value });
    };

    const billChangeHandler = (e) => {
        console.log(e.target.files[0]);
        // setState({bill: e.target.files[0]});
        if (e.target.files[0].type === 'application/pdf') {
            setFileUpload(e.target.files[0]);
            if (import.meta.env.MODE === 'development') {
                console.log('fileUpload!', fileUpload);
            }
            // setState({bill: e.target.files[0]});
        } else {
            window.alert('File type not supported');
        }
    };

    const abnChangeHandler = (e) => {
        setState({ abn: e.target.value });
    };

    const authNameChangeHandler = (e) => {
        setState({
            authorizedSignerName: e.target.value,
        });
    };

    const authEmailChangeHandler = (e) => {
        setState({
            authorizedSignerEmail: e.target.value,
        });
    };

    const authContactChangeHandler = (e) => {
        setState({
            authorizedSignerNumber: e.target.value,
        });
    };

    // return of the form with correct endpoints for submission
    return (
        <Form
            reduxState={'modal'}
            loading={loading}
            validateOnSubmit={validateOnSubmit}
            reduxPrefix={''}
            mode={'Add'}
            formAdd={'/numberblock/requestport/' + currentCompany}
            formAddOptions={{
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }}
            submit={{
                disabled: !state?.agreeterms,
            }}
            formContent={
                <TpmWrapper>
                    <Box maxW="4xl" minW="xl" mx="auto">
                        <Grid
                            columnGap={4}
                            rowGap={4}
                            templateColumns="repeat(2, 1fr)">
                            {/* Carrier */}
                            <GridItem>
                                <FormControl isInvalid={stateError?.carrier}>
                                    <FormLabel>Carrier</FormLabel>
                                    <Input
                                        type="text"
                                        value={state?.carrier}
                                        onChange={carrierChangeHandler}
                                        data-walkthroughid={
                                            bringExistingNumber.carrier
                                        }
                                    />
                                    <FormErrorMessage>
                                        {stateError?.carrier}
                                    </FormErrorMessage>
                                </FormControl>
                            </GridItem>
                            {/* File Upload */}
                            <GridItem>
                                <Box width="100%" position="relative">
                                    <Text size="md" fontWeight="semibold">
                                        Latest bill from your current provider
                                        (PDF)
                                    </Text>
                                    <Button
                                        // size="sm"
                                        as={FormLabel}
                                        colorScheme="brand"
                                        sx={{
                                            marginTop: 2,
                                        }}
                                        className="hover:cursor-pointer"
                                        data-walkthroughid={
                                            bringExistingNumber.fileUpload
                                        }>
                                        {!fileUpload?.name ? (
                                            <>
                                                Choose File
                                                <AttachmentIcon ml={1} />
                                            </>
                                        ) : (
                                            fileUpload?.name
                                        )}

                                        <input
                                            style={{ display: 'none' }}
                                            type="file"
                                            id="image-input"
                                            // accept=".pdf"
                                            onChange={billChangeHandler}
                                        />
                                    </Button>
                                </Box>
                                {stateError?.bill && (
                                    <Text fontSize="sm" color="red.500">
                                        {!fileUpload?.name &&
                                            'Field is required.'}
                                    </Text>
                                )}
                            </GridItem>

                            {/* Company Registration Number */}
                            <GridItem>
                                <FormControl>
                                    <FormLabel>
                                        Company Registration Number
                                    </FormLabel>
                                    <Input
                                        type="text"
                                        value={state?.abn}
                                        onChange={abnChangeHandler}
                                        mt="5px"
                                        data-walkthroughid={
                                            bringExistingNumber.companyRegistrationNumber
                                        }
                                    />

                                    <FormHelperText>
                                        {'Customer ABN / ACN'}
                                    </FormHelperText>
                                </FormControl>
                            </GridItem>

                            {/* Preferred Porting Date */}
                            <GridItem>
                                <FormControl>
                                    <FormLabel>
                                        Preferred Porting Date
                                    </FormLabel>
                                    <InputGroup
                                        mt={'13px'}
                                        sx={
                                            darkMode
                                                ? chakraDatepickerDarkMode
                                                : chakraDatepicker
                                        }
                                        data-walkthroughid={
                                            bringExistingNumber.preferredPortingDate
                                        }>
                                        <SingleDatepicker
                                            name="date-input"
                                            date={date}
                                            onDateChange={(value) => {
                                                const newDate = new Date(value);
                                                newDate.setHours(0, 0, 0, 0);

                                                setState({
                                                    preferredDate: newDate,
                                                });
                                                setDate(newDate);
                                            }}
                                            configs={{
                                                dateFormat:
                                                    ' ccc, dd MMM, yyyy zzz',
                                            }}
                                            propsConfigs={
                                                darkMode
                                                    ? ChakraDatePickerDarkModeStyles
                                                    : ChakraDatePickerStyles
                                            }
                                            minDate={new Date()}
                                        />
                                        <InputRightElement
                                            color={
                                                darkMode ? 'white' : 'gray.500'
                                            }
                                            pointerEvents="none"
                                            children={
                                                <CalendarIcon cursor="pointer" />
                                            }
                                        />
                                    </InputGroup>
                                </FormControl>
                            </GridItem>

                            {/* Authorised Contact Full Name */}
                            <GridItem>
                                <FormControl
                                    isInvalid={
                                        stateError?.authorizedSignerName
                                    }>
                                    <FormLabel>
                                        Authorised Contact Full Name
                                    </FormLabel>
                                    <Input
                                        type="text"
                                        value={state?.authorizedSignerName}
                                        onChange={authNameChangeHandler}
                                        data-walkthroughid={
                                            bringExistingNumber.authorisedSignerFullName
                                        }
                                    />

                                    <FormErrorMessage>
                                        {stateError?.authorizedSignerName}
                                    </FormErrorMessage>
                                </FormControl>
                            </GridItem>

                            {/* Authorised Contact Email */}
                            <GridItem>
                                <FormControl
                                    isInvalid={
                                        stateError?.authorizedSignerEmail
                                    }>
                                    <FormLabel>
                                        Authorised Contact Email
                                    </FormLabel>
                                    <Input
                                        type="text"
                                        value={state?.authorizedSignerEmail}
                                        onChange={authEmailChangeHandler}
                                        data-walkthroughid={
                                            bringExistingNumber.authorisedSignerEmail
                                        }
                                    />

                                    <FormErrorMessage>
                                        {stateError?.authorizedSignerEmail}
                                    </FormErrorMessage>
                                </FormControl>
                            </GridItem>

                            {/* Authorised Contact Number */}
                            <GridItem>
                                <FormControl
                                    isInvalid={
                                        stateError?.authorizedSignerNumber
                                    }>
                                    <FormLabel>
                                        Authorised Contact Number
                                    </FormLabel>
                                    <Input
                                        type="text"
                                        value={state?.authorizedSignerNumber}
                                        onChange={authContactChangeHandler}
                                        data-walkthroughid={
                                            bringExistingNumber.authorisedSignerContact
                                        }
                                    />

                                    <FormErrorMessage>
                                        {stateError?.authorizedSignerNumber}
                                    </FormErrorMessage>
                                </FormControl>
                            </GridItem>

                            {/* Number Porting Table */}
                            <GridItem colSpan={2}>
                                <FormControl>
                                    <ExistingNumbersTable
                                        setState={setState}
                                        dataWalkthroughId={
                                            bringExistingNumber.numbersPortFrom
                                        }
                                        error={stateError?.numberlist}
                                    />
                                </FormControl>
                            </GridItem>

                            {/* Numbers Checkbox Aggreement */}

                            <GridItem
                                colSpan={2}
                                sx={{
                                    border: '2px solid #5bc0de',
                                    borderRadius: '5px',
                                    padding: '15px',
                                }}>
                                <Box
                                    display={'flex'}
                                    justifyContent="space-between">
                                    <p>
                                        I require the number(s) to be loaded
                                        into the portal as soon as they have
                                        been validated
                                    </p>
                                    <Box
                                        textAlign="right"
                                        // alignContent="center"
                                        alignItems={'center'}
                                        // display="flex"
                                        // my="auto"
                                    >
                                        <Checkbox
                                            size="lg"
                                            colorScheme="secondary"
                                            isChecked={state?.preloadNumbers}
                                            onChange={
                                                preloadNumbersChangeHandler
                                            }
                                            data-walkthroughid={
                                                bringExistingNumber.preloadNumbers
                                            }
                                        />
                                        <br />
                                    </Box>
                                </Box>
                            </GridItem>

                            {/* Comments */}
                            <GridItem colSpan={2}>
                                <FormControl
                                    data-walkthroughid={
                                        bringExistingNumber.comments
                                    }>
                                    <FormLabel>Comments</FormLabel>
                                    <AutoResizeTextArea
                                        value={state?.comments}
                                        borderColor={
                                            darkMode
                                                ? 'dark.tcap-borderGray'
                                                : 'inherit'
                                        }
                                        bg={darkMode ? 'dark.bgDark' : '#FFF'}
                                        onChange={commentsChangeHandler}
                                    />
                                </FormControl>
                            </GridItem>
                            {/* Terms and Conditions */}
                            <Heading as="h3" className="mb-2 mt-3">
                                Terms and Conditions
                            </Heading>
                            <GridItem
                                sx={{
                                    border: '2px solid #5bc0de',
                                    borderRadius: '5px',
                                    padding: '15px',
                                }}
                                colSpan={2}>
                                <Box gridColumn="span 4">
                                    <p>
                                        <b>Agreement</b>
                                    </p>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between">
                                        <p>
                                            I understand that I am authorising
                                            the listed number(s) to be ported on
                                            behalf of the company.
                                        </p>
                                        <Box
                                            textAlign="right"
                                            alignContent="center"
                                            // my="auto"
                                        >
                                            <Checkbox
                                                size="lg"
                                                colorScheme="secondary"
                                                isChecked={state?.agreeterms}
                                                onChange={
                                                    agreetermsChangeHandler
                                                }
                                                mb={5}
                                                data-walkthroughid={
                                                    bringExistingNumber.agreement
                                                }
                                            />

                                            <br />
                                        </Box>
                                    </Box>
                                </Box>

                                <Divider borderColor="#5bc0de" my={1} />

                                <Box gridColumn="span 4">
                                    <p>
                                        <b>Please note</b>
                                    </p>
                                    <p>
                                        Porting requests can take 20 days to
                                        process. A representative will be in
                                        touch to discuss scheduling.{' '}
                                    </p>
                                </Box>
                            </GridItem>
                        </Grid>
                    </Box>

                    <br />
                </TpmWrapper>
            }
            formUpload={(freshData, state) => {
                const aggregateNumbers =
                    state?.aggregateTable
                        ?.filter((v) => v.from?.length > 0 || v.to?.length > 0)
                        .map((v) =>
                            v.from < v.to
                                ? String(v.from) + '-' + String(v.to)
                                : String(v.from),
                        ) || [];
                const numberlist = aggregateNumbers.join(',');
                var formData = new FormData();

                const {
                    carrier,
                    agreeterms,
                    preferredDate,
                    authorizedSignerEmail,
                    authorizedSignerName,
                    authorizedSignerNumber,
                    // bill,
                    abn,
                    preloadNumbers,
                    comments,
                } = state;

                formData.append('file', fileUpload);
                formData.append('numberlist', numberlist);
                formData.append('agreeterms', agreeterms);
                formData.append('carrier', carrier);
                formData.append('authorizedSignerEmail', authorizedSignerEmail);
                formData.append('authorizedSignerName', authorizedSignerName);
                formData.append(
                    'authorizedSignerNumber',
                    authorizedSignerNumber,
                );
                formData.append('abn', abn);

                if (preferredDate) {
                    formData.append(
                        'preferredDate',
                        new Date(preferredDate).toUTCString(),
                    );
                }
                if (comments) {
                    formData.append('comments', comments);
                }
                if (preloadNumbers) {
                    formData.append('preloadNumbers', true);
                }

                return formData;
            }}
        />
    );
};

export default ExistingNumbersForm;
