import { Text } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { classNames } from '@/utils/classNames';

/**
 * @typedef {Object} ChakraTextProps
 * @property {import('react').ReactNode} children
 * @param {import('@chakra-ui/react').TextProps & ChakraTextProps} props
 * @returns
 */
export default function ChakraText({ children, ...rest }) {
    const location = useLocation();
    const { pathname } = location;
    const textColor = pathname.includes('services') ? 'black' : 'inherit';
    const { darkMode } = useSelector((state) => state.settings);

    return (
        <Text
            color={textColor}
            {...rest}
            className={classNames(
                darkMode ? 'text-white' : 'text-black',
                rest.className,
            )}>
            {children}
        </Text>
    );
}
