import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Heading, Text } from '@chakra-ui/react';

import MfaChakraCodeBox from './MfaChakraCodeBox';
import { Alert, AlertIcon, AlertDescription, Button } from 'components/v4';

import { useTpmContext } from './TpmProvider';

import walkthroughIds from './walkthroughIds';
import { postSubmitNumberVerification } from 'API/TPMTrial';

export default function MfaTeamsPhoneMobileStepOne() {
    const {
        tpmNumber: [state],
        tpmAddress: [, setAddressAction],
    } = useTpmContext();
    const [mfaCodeError, setMfaCodeError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hasEnteredCode, setHasEnteredCode] = useState(false);
    const [mfaChakraCode, setMfaChakraCode] = useState('');
    const { id } = useParams();
    const { darkMode } = useSelector((state) => state.settings);

    const handleMfaChakraCode = (value) => {
        setMfaChakraCode(value);
        setHasEnteredCode(true);
    };

    const { ufMfaNextButton, ufMfaCancelButton } =
        walkthroughIds.teamsPhoneMobileTrial;

    function mfaLogin() {
        setIsLoading(true);
        // TODO: backend call for Mfa
        // NOTE: enter '317317'
        // NOTE: remove this condition later when backend is ready

        postSubmitNumberVerification({
            serviceID: id,
            phoneNumber: state.number,
            code: mfaChakraCode,
        })
            .then((res) => {
                //TODO: activate your number here??
                setAddressAction('SELECT_SERVICES');
            })
            .catch((e) => {
                setMfaCodeError(true);
                // setIsLoading(false);
            })
            .finally(() => setIsLoading(false));
    }

    const handleBackButtonClick = () => {
        setAddressAction('GET');
    };

    const onMfaLogin = (e) => {
        e.preventDefault();
        if (hasEnteredCode) {
            mfaLogin();
        }
    };

    const handleSubmitButton = (e) => {
        onMfaLogin(e);
    };

    useEffect(() => {
        if (hasEnteredCode) {
            mfaLogin();
        }
    }, [hasEnteredCode]);

    return (
        <Box as="form" className="px-8 pb-8">
            <Heading as="h2" sx={{ textAlign: 'center', marginBottom: 25 }}>
                Enter your code
            </Heading>
            <Text fontSize="md" sx={{ marginBottom: 3 }}>
                Enter the six digit code sent to your mobile number
            </Text>
            <MfaChakraCodeBox onComplete={handleMfaChakraCode} />
            {mfaCodeError && (
                <>
                    <Alert status="error">
                        <AlertIcon />
                        <AlertDescription>
                            The code you entered is incorrect.
                        </AlertDescription>
                    </Alert>

                    <Box className="mb-6" />
                </>
            )}

            <Box className="flex w-full justify-between">
                <Button
                    variant="outline"
                    bg={darkMode ? 'white' : null}
                    onClick={handleBackButtonClick}
                    data-walkthroughid={ufMfaCancelButton}>
                    Back
                </Button>
                <Button
                    type="submit"
                    isDisabled={!hasEnteredCode || isLoading}
                    onClick={handleSubmitButton}
                    isLoading={isLoading}
                    loadingText="Submitting"
                    data-walkthroughid={ufMfaNextButton}>
                    Next
                </Button>
            </Box>
        </Box>
    );
}

