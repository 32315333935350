import { forwardRef } from 'react';
import { Button as BsButton } from 'react-bootstrap';
import {
    HourglassEmptyRounded as HourglassEmptyRoundedIcon,
    ErrorOutlineRounded as ErrorOutlineRoundedIcon,
    SyncRounded as SyncRoundedIcon,
} from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';

const Button = forwardRef((props, ref) => (
    <BsButton
        ref={ref}
        className="btn btn-sm !flex items-center focus:shadow-none disabled:cursor-not-allowed"
        {...props}
    />
));

/**
 * @description Sync button to sync users at resource accounts and users page
 * @typedef {Object} SyncButtonProps
 * @property {'loading' | 'success' | 'error'| 'idle'} state - sync state of company button
 * @property {() => void} onClick - action to call API to sync users
 * @param {import('react-bootstrap').ButtonProps & SyncButtonProps} props
 * */
export default function SyncButton({ state, onClick, ...props }) {
    switch (state) {
        case 'succeed':
            return (
                <Button
                    variant="outline-warning"
                    className="min-h-10 focus:!shadow-none"
                    onClick={onClick}>
                    <HourglassEmptyRoundedIcon fontSize="small" /> Sync Pending
                </Button>
            );
        case 'error':
            return (
                <Button variant="outline-danger" onClick={onClick}>
                    <ErrorOutlineRoundedIcon fontSize="small" />
                    Sync Failed
                </Button>
            );
        case 'idle':
            return (
                <Button {...props} variant="secondary" onClick={onClick}>
                    <SyncRoundedIcon fontSize="small" />
                    Sync Users
                </Button>
            );
        case 'loading':
            return (
                <Button variant="secondary" onClick={onClick}>
                    <CircularProgress
                        style={{ width: 15, height: 15, marginRight: 10 }}
                    />
                    Syncing
                </Button>
            );
        default:
            return null;
    }
}
