//external imports
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import MaterialTable from 'components/v4/MaterialTable';
import Heading from 'components/v4/Heading';

import { Button, Container } from 'react-bootstrap';
import { approvalCodesColumns } from './consts';
import {
    ChakraProvider,
    Box,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
} from '@chakra-ui/react';
import chakratheme from 'src/chakratheme';

import ApprovalCodesCreate from './Create';
import ApprovalCodesEdit from './Edit';
import ApprovalCodesDelete from './Delete';

import Edit from '@material-ui/icons/Edit';

import getApprovalCodes from 'API/ApprovalCode/getApprovalCodes';

// Util
import approvalCodeDaysRemaining from './approvalCodeDaysRemaining';
import approvalCodeAutoApprove from './approvalCodeAutoApprove';
import approvalCodeSelectiveSyncPolicy from './approvalCodeSelectiveSyncPolicy';

import StateToasts from './StateToasts';
import walkthroughIds from './walkthroughIds.js';
import { loadingColumnsConstructor } from 'components/tables/ReduxTable';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { Error } from 'components/index';
import {
    customTabStylesCombineTheme,
    selectedTabStylesCombineTheme,
} from '@/constants';
import { getApprovalCodesQuery } from '@/lib/data-router/loaders/approvalCodeLoader';

const { ufAddApprovalCodeBtn } = walkthroughIds;

function ApprovalCodesHeader({ setApprovalCodesAction }) {
    return (
        <div>
            <Button
                className="btn btn-primary btn-sm"
                style={{ marginBottom: '5%', appearance: 'none' }}
                onClick={() => setApprovalCodesAction('Create')}
                data-walkthroughid={ufAddApprovalCodeBtn}>
                {' '}
                <AddRoundedIcon
                    fontSize="small"
                    style={{ marginBottom: '2%' }}
                />{' '}
                Approval Code
            </Button>
        </div>
    );
}

export default function ApprovalCodes() {
    // Success
    const [successAction, setSuccessAction] = useState(null);
    const tableRef = useRef();

    const [selectedRowData, setSelectedRowData] = useState();
    const [approvalAction, setApprovalCodesAction] = useState('Get');
    const [error, setError] = useState(null);
    const currentPartner = useSelector(
        (state) => state.navigationLists?.currentPartner,
    );
    const { darkMode } = useSelector((state) => state.settings);

    const {
        data: approvalCodes,
        isRefetching,
        refetch,
        status,
    } = useQuery(getApprovalCodesQuery(currentPartner));

    function editApprovalCode(rowData) {
        setSelectedRowData(rowData);
        setApprovalCodesAction('Edit');
    }

    // async function get() {
    //     getApprovalCodes(currentPartner).then((response) => {
    //         if (response.data) {
    //             const data = response.data.map((approvalCode) => ({
    //                 ...approvalCode,
    //                 ...{
    //                     validToCustom: approvalCodeDaysRemaining(
    //                         approvalCode.validTo,
    //                     ),
    //                     autoApproveCustom: approvalCodeAutoApprove(
    //                         approvalCode.autoApprove,
    //                     ),
    //                     selectiveSyncPolicyCustom:
    //                         approvalCodeSelectiveSyncPolicy(
    //                             approvalCode.selectiveSyncPolicy,
    //                         ),
    //                 },
    //             }));
    //             setApprovalCodes(data);
    //         } else {
    //             setError(response);
    //         }
    //         setLoaded(true);
    //     });
    // }

    const loadingColumns = useMemo(
        () => loadingColumnsConstructor(approvalCodesColumns),
        [approvalCodesColumns],
    );
    const dummyTable = [];
    const dummyTableLength = 12;

    for (let i = 0; i < dummyTableLength; i++) {
        const obj = {
            title: `Title ${i + 1}`,
            field: `Field ${i + 1}`,
        };
        dummyTable.push(obj);
    }

    const componentLoadingState = status === 'loading' || isRefetching;

    if (!currentPartner) {
        return (
            <>
                <Error error={error} />
            </>
        );
    }

    return (
        <ChakraProvider theme={chakratheme} resetCSS={true}>
            {approvalAction === 'Get' && (
                <>
                    <StateToasts type={successAction} />

                    <Heading
                        as="h2"
                        sx={{ fontWeight: 500, marginBottom: '0.5rem' }}>
                        Approval Codes
                    </Heading>
                    <MaterialTable
                        tableRef={tableRef}
                        // refreshFn={get}
                        title={
                            <ApprovalCodesHeader
                                setApprovalCodesAction={setApprovalCodesAction}
                            />
                        }
                        columns={
                            componentLoadingState
                                ? loadingColumns
                                : approvalCodesColumns
                        }
                        data={
                            componentLoadingState ? dummyTable : approvalCodes
                        }
                        actions={[
                            {
                                icon: Edit,
                                tooltip: 'Edit',
                                isFreeAction: false,
                                onClick: (e, rowData) => {
                                    editApprovalCode(rowData);
                                },
                            },
                        ]}
                    />
                </>
            )}
            {approvalAction === 'Create' && (
                <Container fluid>
                    <Heading fontSize="3xl" as="h2" mb={'1%'}>
                        <span className="text-2xl font-semibold">
                            Add Approval Code
                        </span>
                    </Heading>
                    <ApprovalCodesCreate
                        setApprovalCodesAction={setApprovalCodesAction}
                        setSuccessAction={setSuccessAction}
                    />
                </Container>
            )}
            {approvalAction === 'Edit' && (
                <Container fluid>
                    <Heading fontSize="3xl" as="h2" mb={'1%'}>
                        <span className="text-2xl font-semibold">
                            Edit Approval Code
                        </span>
                    </Heading>

                    <Tabs
                        sx={{ maxW: '4xl', mx: 'auto' }}
                        colorScheme="brand"
                        _focus={{
                            boxShadow: '0 0 0 3px rgba(153, 202, 255, 1)',
                        }}>
                        <TabList
                            borderBottom={'none'}
                            justifyContent="center"
                            sx={{ marginBottom: '25px' }}>
                            <Tab
                                onMouseDown={(e) =>
                                    e.button === 2 && e.preventDefault()
                                }
                                sx={customTabStylesCombineTheme}
                                _selected={{
                                    ...selectedTabStylesCombineTheme,
                                    textColor: darkMode
                                        ? 'rgba(255, 255, 255)'
                                        : 'inherit',
                                }}
                                _focus={{
                                    boxShadow:
                                        '0 0 0 3px rgba(153, 202, 255, 1)',
                                }}>
                                General
                            </Tab>
                            <Tab
                                onMouseDown={(e) =>
                                    e.button === 2 && e.preventDefault()
                                }
                                sx={customTabStylesCombineTheme}
                                _selected={{
                                    ...selectedTabStylesCombineTheme,
                                    textColor: darkMode
                                        ? 'rgba(255, 255, 255)'
                                        : 'inherit',
                                }}
                                _focus={{
                                    boxShadow:
                                        '0 0 0 3px rgba(153, 202, 255, 1)',
                                }}>
                                Admin
                            </Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel sx={{ padding: 0 }}>
                                <ApprovalCodesEdit
                                    setSuccessAction={setSuccessAction}
                                    setApprovalCodesAction={
                                        setApprovalCodesAction
                                    }
                                    rowData={selectedRowData}
                                />
                            </TabPanel>
                            <TabPanel sx={{ padding: 0 }}>
                                <ApprovalCodesDelete
                                    setApprovalCodesAction={
                                        setApprovalCodesAction
                                    }
                                    setSuccessAction={setSuccessAction}
                                    rowData={selectedRowData}
                                />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Container>
            )}
        </ChakraProvider>
    );
}
