import { useSelector } from 'react-redux';
import {
    AlertTitle,
    Box,
    Image,
    Progress,
    useDisclosure,
} from '@chakra-ui/react';
import { classNames } from '@/utils/classNames';
import {
    Modal,
    ModalContent,
    ModalBody,
    ModalHeader,
    ModalOverlay,
    ModalFooter,
    Alert,
    AlertIcon,
    AlertDescription,
    Button,
} from '@/components/v4';

import styles from './styles';
import { useState } from 'react';

const walkthroughIdModal = {
    ufCancelButton: '/services-profile-add/modal/confirmation/cancel',
    ufAddButton: '/services-profile-add/modal/confirmation/add',
};

/**
 * @typedef {Object} DemoServiceProfileCardProps
 * @property {React.ReactNode} children
 * @param {DemoServiceProfileCardProps & import('@chakra-ui/react').BoxProps} props
 */
export default function DemoServiceProfileCard({ logo, children, ...props }) {
    const { darkMode } = useSelector((state) => state.settings);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isProgressOpen,
        onOpen: onProgressOpen,
        onClose: onProgressClose,
    } = useDisclosure();
    const serviceName = props['data-walkthroughid']?.split('/')[4];

    const [submitState, setSubmitState] = useState('start');
    const [serviceText, setServiceText] = useState();
    const [progressBar, setProgressBar] = useState(0);

    // the onclick adds the service with the
    const handleSubmit = async (e) => {
        e.preventDefault();
        onClose();
        setSubmitState('add');
        onProgressOpen();
        await handleAddService();
    };

    const handleAddService = async () => {
        const minDelay = 4000; // 4 seconds in milliseconds
        const maxDelay = 12000; // 12 seconds in milliseconds
        const delay =
            Math.floor(Math.random() * (maxDelay - minDelay + 1)) + minDelay;

        setProgressBar(1);
        setServiceText(`Provisioning ${serviceName}`);
        await new Promise((resolve) =>
            setTimeout(
                resolve,
                Math.floor(Math.random() * (maxDelay - minDelay + 1)) +
                    minDelay,
            ),
        );
        setProgressBar(2);
        setServiceText(`Adding ${serviceName} to company`);
        await new Promise((resolve) =>
            setTimeout(
                resolve,
                Math.floor(Math.random() * (maxDelay - minDelay + 1)) +
                    minDelay,
            ),
        );
        setProgressBar(3);
        setServiceText(`Activating ${serviceName}`);
        await new Promise((resolve) =>
            setTimeout(
                resolve,
                Math.floor(Math.random() * (maxDelay - minDelay + 1)) +
                    minDelay,
            ),
        );
        setProgressBar(4);
        setServiceText(`Configuring ${serviceName}`);
        await new Promise((resolve) =>
            setTimeout(
                resolve,
                Math.floor(Math.random() * (maxDelay - minDelay + 1)) +
                    minDelay,
            ),
        );
        setSubmitState('end');
        setServiceText(`${serviceName} provisioned successfully!`);
    };

    const handleProgressClose = () => {
        onProgressClose();
        setSubmitState('start');
    };

    return (
        <>
            <Box
                sx={styles.container}
                {...props}
                display={'flex'}
                flexDir={'column'}
                className={classNames(
                    darkMode
                        ? 'bg-[var(--chakra-colors-gray-tcap)]'
                        : 'bg-white',
                    '!shadow-lg',
                )}
                borderRadius="lg"
                onClick={onOpen}>
                {children}
            </Box>

            {submitState == 'start' && (
                <Modal
                    closeOnOverlayClick={false}
                    isCentered
                    isOpen={isOpen}
                    onClose={onClose}
                    size="md">
                    <ModalOverlay backdropFilter="blur(10px)" />
                    <ModalContent>
                        <ModalHeader>Add {serviceName}</ModalHeader>
                        <ModalBody>
                            <p className="text-base">
                                Are you sure you would like to add {serviceName}
                                ?
                            </p>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                type="button"
                                variant="outline"
                                className="secondary"
                                w='100%'
                                mr={8}
                                onClick={onClose}
                                data-walkthroughid={
                                    walkthroughIdModal.ufCancelButton
                                }>
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                onClick={handleSubmit}
                                w='100%'
                                data-walkthroughid={
                                    walkthroughIdModal.ufAddButton
                                }>
                                Add
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            )}

            {(submitState == 'add' || submitState == 'end') && (
                <Modal
                    closeOnOverlayClick={false}
                    isCentered
                    isOpen={isProgressOpen}
                    onClose={handleProgressClose}
                    size="md">
                    <ModalOverlay backdropFilter="blur(10px)" />
                    <ModalContent as="form" onSubmit={handleSubmit}>
                        <ModalHeader display={'flex'} justifyContent={'center'}>
                            <Image boxSize={100} src={logo} />
                        </ModalHeader>
                        <ModalBody>
                            {submitState == 'add' && (
                                <>
                                    <Alert status={'info'} mt={5}>
                                        <AlertIcon />
                                        <AlertDescription>
                                            {serviceText}
                                        </AlertDescription>
                                    </Alert>
                                    <Progress
                                        rounded={'md'}
                                        mt={6}
                                        hasStripe
                                        isAnimated
                                        value={progressBar}
                                        max={5}
                                    />
                                </>
                            )}
                            {submitState == 'end' && (
                                <Alert
                                    status="success"
                                    variant="subtle"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    textAlign="center"
                                    height="120px">
                                    <AlertIcon boxSize="40px" mr={0} />
                                    <AlertTitle mt={4} mb={1} fontWeight={'500'} fontSize="md">
                                        {serviceText}
                                    </AlertTitle>
                                </Alert>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                variant="outline"
                                className='secondary'
                                disabled={submitState != 'end'}
                                w='100%'
                                onClick={handleProgressClose}
                                data-walkthroughid={
                                    walkthroughIdModal.ufCancelButton
                                }>
                                Close
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            )}
        </>
    );
}
