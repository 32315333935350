import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useErrorBoundary } from 'react-error-boundary';

import { classNames } from 'utils/classNames';
import { handleGoBack } from '@/lib/data-router/utils/helpers';
import ErrorStatusPage from 'pages/fof/ErrorStatusPage';

export default function ErrorPage(props) {
    const { error } = props;
    const SUPPORT_EMAIL = ['support@pingco.cloud'];

    const { pathname } = useLocation();
    const parentPath = pathname.split('/')[1];

    const navigate = useNavigate();
    const { resetBoundary } = useErrorBoundary();
    const { darkMode, mixedMode, restError } = useSelector((state) => {
        return {
            darkMode: state.settings.darkMode,
            mixedMode: state.settings.mixedMode,
            restError: state.modal?.restError,
        };
    });

    const handleBackButton = () => {
        handleGoBack(navigate, pathname, parentPath);
        resetBoundary();
    };

    const errorStatus = error?.response?.status;

    if (errorStatus) {
        return <ErrorStatusPage status={errorStatus} />;
    }
    return (
        <div
            id="error-page"
            className={classNames(
                darkMode || mixedMode ? 'bg-inherit' : 'bg-white',
                'flex h-screen w-full flex-col items-center justify-center',
            )}
        >
            <p
                className={classNames(
                    darkMode ? 'text-blue-100' : 'text-blue-600',
                    'text-xl font-semibold',
                )}
            >
                Oops!
            </p>
            <p
                className={classNames(
                    darkMode ? 'text-gray-200' : 'text-gray-600',
                    'mt-6 text-base leading-7',
                )}
            >
                Sorry, an unexpected error has occurred.
            </p>
            <p
                className={classNames(
                    darkMode ? 'text-gray-200' : 'text-gray-600',
                    'mt-6 text-base leading-7',
                )}
            >
                <i>{error?.message || restError || error?.error?.message}</i>
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                    className="rounded-md bg-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:cursor-pointer hover:bg-blue-400 hover:no-underline focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                    onClick={handleBackButton}
                >
                    Go back
                </a>
                <a
                    href={`mailto:${SUPPORT_EMAIL}?subject=Page Error at [${pathname.toUpperCase()}] - ${error?.message || restError}`}
                    className={classNames(
                        darkMode ? 'text-gray-200 ' : 'text-gray-900',
                        'text-sm font-semibold hover:text-inherit hover:no-underline hover:opacity-75',
                    )}
                >
                    Contact support <span aria-hidden="true">&rarr;</span>
                </a>
            </div>
        </div>
    );
}
