import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import { Box, useDisclosure } from '@chakra-ui/react';
import { EditIcon, LockIcon, CheckCircleIcon } from '@chakra-ui/icons';

// OC Card
import OperatorConnectStatus from './OperatorConnectStatus';
import DashboardServiceCard from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCard';
import DashboardServiceCardOcStatusbar from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardOcStatusbar';
import DashboardServiceCardContent from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardContent';
import DashboardServiceCardHeader from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardHeader';
import DashboardServiceCardHeaderActionButton from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardHeaderActionButton';

// Components
import Text from 'components/v4/Text';
import Image from 'components/v4/Image';
import { MenuItem } from 'components/v4';
import ServiceDashboardItemSyncUsers from 'components/v4/Services/ServiceDashboard/ServiceDashboardItemSyncUsers';
import ServiceDashboardTenantId from 'components/v4/Services/ServiceDashboard/ServiceDashboardTenantId';
import { ServiceDashboardItemLastHealthCheck } from '../DirectRouting';
// Util
import { dateStringToDate } from 'utils/utils';

// API
import getService from 'API/Service/getService';

// Assets
import sbcLogo from 'assets/images/sbcaas.png';
import msTeamsLogo from 'assets/images/ms_teams_logo.png';
import path from '@/routes/constants';

export default function OperatorConnectCard({
    service,
    companyAppAuth,
    serviceWizard,
    companyData,
}) {
    const accountScope = useSelector((state) => state.login.requiredScope);
    const [data, setData] = useState(service);
    const [authData, setAuthData] = useState(null);
    const modalState = useDisclosure();
    const [serviceObj, setServiceObj] = useState(null);
    const [currentCompanyAppAuth, setCurrentCompanyAppAuth] = useState(null);
    const [cardStatus, setCardStatus] = useState({
        color: 'gray',
        isAnimated: false,
        hasStripes: false,
        status: '...',
        to: null,
        toText: null,
    });
    const [actionPermissions, setActionPermissions] = useState({
        menuEditServiceScope: 40,
    });
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (service) serviceWizard.setSelectedServiceProfile(service);
    }, [service]);

    useEffect(() => {
        if (companyAppAuth) {
            //set MS Service Auth & Service Data.
            setAuthData(serviceWizard.msServiceAuth);
            setData({
                ...serviceWizard.msServiceAuth,
                ...service,
                hasAppAuthData: true,
                lastProcessed:
                    serviceWizard.msServiceAuth?.lastProcessedTS &&
                    dateStringToDate(
                        serviceWizard.msServiceAuth?.lastProcessedTS,
                        'datetime',
                    ),
            });
            setCurrentCompanyAppAuth(companyAppAuth);
        }
        // Full service details
        getService(service.id).then((response) => {
            setServiceObj(response.data);
        });
    }, [companyAppAuth, service, serviceWizard.msServiceAuth]);

    useEffect(() => {
        if (serviceObj) {
            // Tenant ID
            if (
                !serviceWizard?.msServiceAuth?.tenantID &&
                serviceObj?.status === 2 &&
                (serviceObj?.authPolicy === 0 || serviceObj?.authPolicy === 1)
            ) {
                if (serviceObj?.company?.selectiveSyncPolicy !== 2) {
                    setActionPermissions((p) => ({
                        ...p,
                        ...{
                            menuTenantIdScope: 20,
                        },
                    }));
                }
            } else {
                setActionPermissions((p) => {
                    if (
                        Object.prototype.hasOwnProperty.call(
                            p,
                            'menuTenantIdScope',
                        )
                    ) {
                        delete p.menuTenantIdScope;
                        return p;
                    }
                    return p;
                });
            }

            // Provide Access
            if (serviceObj?.authPolicy !== 0) {
                if (
                    (serviceObj?.status === 2 &&
                        currentCompanyAppAuth === null) ||
                    (serviceObj?.status === 2 &&
                        (data.userSync === false ||
                            data.graphAuth === false)) ||
                    currentCompanyAppAuth?.status === 20
                ) {
                    setActionPermissions((p) => ({
                        ...p,
                        ...{
                            menuProvideAccessScope: 20,
                        },
                    }));
                }
            } else {
                setActionPermissions((p) => {
                    if (
                        Object.prototype.hasOwnProperty.call(
                            p,
                            'menuProvideAccessScope',
                        )
                    ) {
                        delete p.menuProvideAccessScope;
                        return p;
                    }
                    return p;
                });
            }

            //TODO: Enable User Sync After Manual App Auth

            if (serviceObj?.authPolicy !== 0) {
                if (
                    serviceWizard?.msServiceAuth?.tenantID &&
                    data.graphAuth === false && //have tenant id & no graph auth
                    data.userSync === false // hide if userSync is enabled
                ) {
                    setActionPermissions((p) => ({
                        ...p,
                        ...{
                            menuEnableSyncScope: 20,
                        },
                    }));
                }
            } else {
                setActionPermissions((p) => {
                    if (
                        Object.prototype.hasOwnProperty.call(
                            p,
                            'menuEnableSyncScope',
                        )
                    ) {
                        delete p.menuEnableSyncScope;
                        return p;
                    }
                    return p;
                });
            }
        }
    }, [currentCompanyAppAuth, companyAppAuth, serviceObj, service, data]);

    const classes = classNames({
        flash: service.changes === 'added',
    });

    useEffect(() => {
        for (const [key, value] of Object.entries(actionPermissions)) {
            if (accountScope >= value || actionPermissions[key] === 20) {
                setShow(true);
            }
        }
    }, [actionPermissions]);

    // const ActionsMenu = memo(function ActionsMenu() {
    //     // Iterate obj, are any of these above or equal to my account level scope
    //     const [show, setShow] = useState(false);

    //     useEffect(() => {
    //         for (const [key, value] of Object.entries(actionPermissions)) {
    //             if (accountScope >= value) {
    //                 setShow(true);
    //             }
    //         }
    //     }, []);

    //     if (!show) return null;

    //     return (
    //         <DashboardServiceCardHeaderActionButton
    //             dataWalkthroughId={service.name}>
    //             {/* Edit Service */}
    //             {accountScope >= actionPermissions.menuEditServiceScope && (
    //                 <MenuItem
    //                     icon={<EditIcon />}
    //                     onClick={() => {
    //                         navigate('/services', {
    //                             state: {
    //                                 serviceId: data.id,
    //                             },
    //                         });
    //                     }}
    //                     data-walkthroughid={`/dashboard-services/card/${service.name}/edit-service`}>
    //                     <Text marginBottom={'0'}>Edit Service</Text>
    //                 </MenuItem>
    //             )}

    //             {/* Selective Sync */}
    //             {accountScope >= actionPermissions.menuSelectiveSyncScope && (
    //                 <MenuItem
    //                     icon={<RepeatIcon />}
    //                     onClick={() => {
    //                         serviceWizard.setSelectiveSyncEditData(authData);
    //                         if (serviceWizard?.msServiceAuth?.id) {
    //                             serviceWizard.setFlow('SelectiveSyncUsersEdit');
    //                         } else {
    //                             serviceWizard.ocFlow();
    //                         }
    //                     }}
    //                     data-walkthroughid={`/dashboard-services/card/${service.name}/selective-sync`}>
    //                     <Text marginBottom={'0'}>Selective Sync</Text>
    //                 </MenuItem>
    //             )}

    //             {/* Tenant ID */}
    //             {accountScope >= actionPermissions.menuTenantIdScope && (
    //                 <MenuItem
    //                     icon={<LockIcon />}
    //                     onClick={() => {
    //                         modalState.onOpen();
    //                     }}
    //                     data-walkthroughid={`/dashboard-services/card/${service.name}/tenant-id`}>
    //                     <Text marginBottom={'0'}>Tenant ID</Text>
    //                 </MenuItem>
    //             )}

    //             {/* Provide Access */}
    //             {accountScope >= actionPermissions.menuProvideAccessScope && (
    //                 <MenuItem
    //                     icon={<LockIcon />}
    //                     onClick={async () => {
    //                         if (
    //                             serviceObj?.company?.selectiveSyncPolicy === 2
    //                         ) {
    //                             if (serviceWizard.msServiceAuth?.id) {
    //                                 await serviceWizard.microsoftAuth();
    //                             } else {
    //                                 serviceWizard.ocFlow();
    //                             }
    //                         } else if (
    //                             serviceObj?.company?.selectiveSyncPolicy ===
    //                                 1 ||
    //                             serviceObj?.company?.selectiveSyncPolicy === 0
    //                         ) {
    //                             await serviceWizard.microsoftAuth();
    //                         }
    //                     }}
    //                     data-walkthroughid={`/dashboard-services/card/${service.name}/provide-access`}>
    //                     <Text marginBottom={'0'}>Provide Access</Text>
    //                 </MenuItem>
    //             )}
    //             {/* TODO: create Enable Sync,  */}
    //             {accountScope >= actionPermissions.menuEnableSyncScope && (
    //                 <MenuItem
    //                     icon={<CheckCircleIcon />}
    //                     onClick={async () => {
    //                         if (
    //                             serviceObj?.company?.selectiveSyncPolicy === 2 //forced
    //                         ) {
    //                             serviceWizard.ocFlow();
    //                         } else if (
    //                             serviceObj?.company?.selectiveSyncPolicy ===
    //                                 1 ||
    //                             serviceObj?.company?.selectiveSyncPolicy === 0
    //                         ) {
    //                             await serviceWizard.microsoftAuth();
    //                         }
    //                     }}
    //                     data-walkthroughid={`/dashboard-services/card/${service.name}/enable-sync`}>
    //                     <Text marginBottom={'0'}>Enable Sync</Text>
    //                 </MenuItem>
    //             )}
    //         </DashboardServiceCardHeaderActionButton>
    //     );
    // });

    return (
        <>
            <ServiceDashboardTenantId {...modalState} {...serviceWizard} />
            <DashboardServiceCard
                className={classes}
                dataWalkthroughId={service.name}>
                <DashboardServiceCardOcStatusbar status={cardStatus} />
                <DashboardServiceCardHeader>
                    <Text fontSize="lg" className="truncate" marginBottom={'0'}>
                        {data.name}
                    </Text>
                    {/* <ActionsMenu /> NOTE: comment this because of flickering button */}
                    {show && (
                        <DashboardServiceCardHeaderActionButton
                            id={'operator-connect'}
                            dataWalkthroughId={service.name}>
                            {/* Edit Service */}
                            {accountScope >=
                                actionPermissions.menuEditServiceScope && (
                                <Link
                                    id="edit-oc-service"
                                    className="bg-transparent !no-underline hover:text-inherit"
                                    to={`${path.SERVICES_OPERATOR_CONNECT}/${data.id}/edit`}
                                    state={{
                                        serviceId: data.id,
                                    }}>
                                    <MenuItem
                                        icon={<EditIcon />}
                                        // onClick={() => {
                                        //     navigate('/services', {
                                        //         state: {
                                        //             serviceId: data.id,
                                        //         },
                                        //     });
                                        // }}
                                        data-walkthroughid={`/dashboard-services/card/${service.name}/edit-service`}>
                                        <Text marginBottom={'0'}>
                                            Edit Service
                                        </Text>
                                    </MenuItem>
                                </Link>
                            )}

                            {/* Tenant ID */}
                            {accountScope >=
                                actionPermissions.menuTenantIdScope && (
                                <MenuItem
                                    id="tenant-id"
                                    icon={<LockIcon />}
                                    onClick={() => {
                                        modalState.onOpen();
                                    }}
                                    data-walkthroughid={`/dashboard-services/card/${service.name}/tenant-id`}>
                                    <Text marginBottom={'0'}>Tenant ID</Text>
                                </MenuItem>
                            )}

                            {/* Provide Access */}
                            {accountScope >=
                                actionPermissions.menuProvideAccessScope && (
                                <MenuItem
                                    id="oc-provide-access"
                                    icon={<LockIcon />}
                                    onClick={async () => {
                                        if (
                                            serviceObj?.company
                                                ?.selectiveSyncPolicy === 2
                                        ) {
                                            if (
                                                serviceWizard.msServiceAuth?.id
                                            ) {
                                                await serviceWizard.microsoftAuth();
                                            } else {
                                                serviceWizard.ocFlow();
                                            }
                                        } else if (
                                            serviceObj?.company
                                                ?.selectiveSyncPolicy === 1 ||
                                            serviceObj?.company
                                                ?.selectiveSyncPolicy === 0
                                        ) {
                                            await serviceWizard.microsoftAuth();
                                        }
                                    }}
                                    data-walkthroughid={`/dashboard-services/card/${service.name}/provide-access`}>
                                    <Text marginBottom={'0'}>
                                        Provide Access
                                    </Text>
                                </MenuItem>
                            )}

                            {accountScope >=
                                actionPermissions.menuEnableSyncScope && (
                                <MenuItem
                                    icon={<CheckCircleIcon />}
                                    onClick={async () => {
                                        if (
                                            serviceObj?.company
                                                ?.selectiveSyncPolicy === 2 //forced
                                        ) {
                                            serviceWizard.ocFlow();
                                        } else if (
                                            serviceObj?.company
                                                ?.selectiveSyncPolicy === 1 ||
                                            serviceObj?.company
                                                ?.selectiveSyncPolicy === 0
                                        ) {
                                            await serviceWizard.microsoftAuth();
                                        }
                                    }}
                                    data-walkthroughid={`/dashboard-services/card/${service.name}/enable-sync`}>
                                    <Text marginBottom={'0'}>Enable Sync</Text>
                                </MenuItem>
                            )}
                        </DashboardServiceCardHeaderActionButton>
                    )}
                </DashboardServiceCardHeader>
                <DashboardServiceCardContent>
                    <Box>
                        <Image
                            src={
                                service.serviceType === 'SBCaaS'
                                    ? sbcLogo
                                    : msTeamsLogo
                            }
                            sx={{
                                maxWidth: '50px',
                                margin: '0 auto 15px auto',
                            }}
                        />

                        <Box
                            sx={{
                                display: 'flex',
                                flexDir: 'column',
                            }}>
                            {/* Status */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: '15px',
                                }}>
                                <Text
                                    sx={{
                                        fontWeight: '500',
                                    }}>
                                    Status
                                </Text>
                                <OperatorConnectStatus
                                    companyData={companyData}
                                    serviceObj={serviceObj}
                                    authData={authData}
                                    cardStatus={cardStatus}
                                    setCardStatus={setCardStatus}
                                    accountScope={accountScope}
                                />
                            </Box>

                            {/* Sync Time */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: '15px',
                                }}>
                                <Text
                                    sx={{
                                        fontWeight: '500',
                                    }}>
                                    Sync
                                </Text>
                                <Text sx={{ textAlign: 'left' }}>
                                    <ServiceDashboardItemSyncUsers
                                        data={data}
                                    />
                                </Text>
                            </Box>

                            {/* Last Health Check */}
                            <ServiceDashboardItemLastHealthCheck data={data} />
                        </Box>
                    </Box>
                </DashboardServiceCardContent>
            </DashboardServiceCard>
        </>
    );
}
