import React, { useRef } from 'react';
import { Row, Button, Col } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import QueueRoundedIcon from '@material-ui/icons/QueueRounded';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from '@material-ui/lab';

import { TableAndModal } from '../../../../components';
import config from '../../../../config.json';
import { renderTelephoneNumber } from '../../../../utils/utils';
import { axios } from 'services/axios';
import walkthroughIds from 'pages/telephony/numbers/walkthroughIds';

/*
 * Availible Number blocks tab of the Request new Block form of Numbers Sidebar Item
 */
const AvailableBlock = (props) => {
    const dispatch = useDispatch();
    const tableRef = useRef();

    // state access
    const { data: modalData, state: modalState } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    const { currentCompany, currentCompanyData } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    const { requestAvailableBlock } = walkthroughIds;

    // default columns for the availible number blocks tab
    const defaultColumns = [
        {
            title: 'First Number',
            field: 'first',
            defaultSort: 'asc',
            render: (rowData) => renderTelephoneNumber(rowData, 'first'),
        },

        {
            title: 'Block Size',
            field: 'blockSize',
        },
    ];

    // return the table and modal with the right endpoints and modal Content
    return (
        <>
            <TableAndModal
                tableRef={tableRef}
                filterable={false}
                tableFetch={`company/${currentCompany}/numberblocks/available`}
                // modalUpdate={'/numberblock/'}
                onModalUpdate={() => {
                    return axios.post(
                        `/numberblock/requestexisting/${currentCompany}/${modalData?.id}`,
                    );
                }}
                modalUpload={(freshData) => {
                    return {
                        ...freshData,
                        companyID: currentCompany,
                    };
                }}
                noColumnsButton={true}
                modalApproval={'/numberblock/'}
                title={'Request Block'}
                defaultColumns={defaultColumns}
                maxBodyHeight={window.innerHeight * config.tabView.tableHeight}
                modalTitle={'Requesting Available Block'}
                noTitle
                additionalActions={[
                    (rowData) => ({
                        icon: () => (
                            <QueueRoundedIcon
                                data-walkthroughid={
                                    rowData.tableData.id === 0
                                        ? requestAvailableBlock.addButton
                                        : null
                                }
                            />
                        ),
                        isFreeAction: false,
                        tooltip: '',
                        onClick: (e, rowData) => {
                            dispatch({
                                type: 'OPEN_MODAL',
                                payload: {
                                    mode: 'Edit',
                                    data: rowData,
                                    loading: false,
                                },
                            });
                        },
                    }),
                ]}
                modalButtons={
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={!currentCompanyData?.approved}
                        walkthroughId={requestAvailableBlock.requestBlock}>
                        Request Block
                    </Button>
                }
                modalContent={
                    <>
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <TextField
                                    id="outlined-basic"
                                    fullWidth
                                    placeholder="First Number"
                                    size="small"
                                    label="First Number"
                                    value={modalData?.first}
                                    onChange={(e) => {}}
                                    disabled
                                    data-walkthroughid={
                                        requestAvailableBlock.firstNumber
                                    }
                                />
                            </Col>
                        </Row>
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <TextField
                                    id="outlined-basic"
                                    fullWidth
                                    placeholder="Block Size"
                                    size="small"
                                    label="Block Size"
                                    value={modalData?.blockSize}
                                    onChange={(e) => {}}
                                    disabled
                                    data-walkthroughid={
                                        requestAvailableBlock.blockSize
                                    }
                                />
                            </Col>
                        </Row>
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <Alert severity="info">
                                    This constitutes an order and the block will
                                    be added to your account. Charges based on
                                    your plan will apply.
                                </Alert>
                            </Col>
                        </Row>
                    </>
                }
            />
        </>
    );
};

export default AvailableBlock;
