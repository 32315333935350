export default {
    createMode: {
        ufNameInput: '/approval-codes/create/input/name',
        ufDefaultPlanSelect: '/approval-codes/create/select/defaultPlan',
        ufSelectiveSyncSelect: '/approval-codes/create/select/selectiveSync',
        ufCodeInput: '/approval-codes/create/input/code',
        ufAutoApproveSelect: '/approval-codes/create/select/autoApproval',
        ufTrialDurationInput: '/approval-codes/create/input/trialDuration',
        ufApprovalExpiryDate: '/approval-codes/create/input/approvalExpiryDate',
        ufBackBtn: '/approval-codes/create/button/back',
        ufCreateBtn: '/approval-codes/create/button/create',
    },
    ufAddApprovalCodeBtn: '/approval-codes/table/button/add'
};
