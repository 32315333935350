import React from 'react';
import {Box, Grid, Alert, AlertTitle} from '@mui/material';
import EditSipAuth from './EditSipAuth';
import EditSipCollectionPort from './EditSipCollectionPort';
import EditSipCollectionHost from './EditSipCollectionHost';
import EditSipTerminationEndpoint from './EditSipTerminationEndpoint';
import EditSipRegistration from './EditSipRegistration';
import EditSipRegistrationAcl from './EditSipRegistrationAcl';
import { useSelector } from 'react-redux';
/*
 * Advanced trunk page that shows 3 component tables
 * Collection Host List
 * Collection Port List
 * Termination Endpoint List
 */
const EditSipEndpoints = ({
    trunkValid,
    authPassword
}) => {

  // state access
  const { state } = useSelector((state) => {
    return {
        ...state.modal,
    };
  });

    return (
        <Box sx={{flexGrow: 1}}>
            {!trunkValid && (
                <Alert
                    severity="warning"
                    variant="filled"
                    style={{marginTop: 5, marginBottom: 15}}>
                    <AlertTitle>Incomplete Trunk</AlertTitle>
                    You must add Collection Ports and Termination IPs before
                    trunk is operational.
                </Alert>
            )}
            {state && <Grid container spacing={2} className={'justify-start'}>
                <Grid item xs={6}>
                    <EditSipAuth />
                </Grid>
                <Grid item xs={6}>
                    <EditSipCollectionPort />
                </Grid>
                {state?.authType === 0 && (
                  <>
                    <Grid item xs={6}>
                        <EditSipCollectionHost />
                    </Grid>
                    <Grid item xs={6}>
                        <EditSipTerminationEndpoint />
                    </Grid>
                  </>
                )}
                {state?.authType === 1 && (
                  <>
                    <Grid item xs={6}>
                        <EditSipRegistration
                          aPass={authPassword}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <EditSipRegistrationAcl />
                    </Grid>
                  </>
                )}
            </Grid>}
        </Box>
    );
};

export default EditSipEndpoints;
