import React, {useState, useRef, useEffect} from 'react';

export default function useMfaCode() {
    const [mfaCodeObj, setMfaCodeObj] = useState({
        0: '',
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
    });

    const [mfaCodeIndexDisabled, setMfaCodeIndexDisabled] = useState({
        0: false,
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
    });

    const [hasEnteredCode, setHasEnteredCode] = useState(false);
    const [mfaCode, setMfaCode] = useState(null);

    const zero = useRef(null);
    const one = useRef(null);
    const two = useRef(null);
    const three = useRef(null);
    const four = useRef(null);
    const five = useRef(null);

    const refs = {
        0: zero,
        1: one,
        2: two,
        3: three,
        4: four,
        5: five,
    };

    function convertMfaObjectToString() {
        const convertedMfaCode = Object.values(mfaCodeObj)
            .map((v) => `${v}`)
            .toString()
            .replaceAll(',', '');

        setMfaCode(convertedMfaCode);
    }

    function hasEnteredCodeCheck() {
        // Do we have all 6 filled in?
        let _hasEnteredCode = false;
        Object.entries(mfaCodeObj).map((row) => {
            if (row[1] === '') {
                _hasEnteredCode = false;
            } else {
                _hasEnteredCode = true;
            }
        });

        if (_hasEnteredCode) setHasEnteredCode(true);
        else setHasEnteredCode(false);
    }

    useEffect(() => {
        hasEnteredCodeCheck();
        convertMfaObjectToString();
    }, [mfaCodeObj]);

    function shouldWeSwapToNextInput(e, i) {
        const digit = e.target.value;
        setMfaCodeObj((p) => ({
            ...p,
            [i]: digit,
        }));

        // Non integar, stop here
        if (isNaN(digit)) {
            setMfaCodeObj((p) => ({
                ...p,
                [i]: '',
            }));
            return;
        }

        // All but last
        if (digit !== '' && i !== 5) {
            // Enable next input
            setMfaCodeIndexDisabled((p) => ({
                ...p,
                [i]: true,
                [i + 1]: false,
            }));
            // Focus next input
            setTimeout(() => {
                refs[i + 1].current.focus();
            });
        }

        // Last
        if (digit !== '' && i === 5) {
            // Loose focus after last
            //refs[i].current.blur();
        }
    }

    function shouldWeGoToPreviousInput(e, i) {
        const digit = e.target.value;

        if (e.which == 8 || e.keyCode == 8) {
            if (digit === '' && i !== 0) {
                // Clear previous
                setMfaCodeObj((p) => ({
                    ...p,
                    [i - 1]: '',
                }));
                // Enable next input
                setMfaCodeIndexDisabled((p) => ({
                    ...p,
                    [i]: true,
                    [i - 1]: false,
                }));
                // Focus next input
                setTimeout(() => {
                    refs[i - 1].current.focus();
                });
            }
        }
    }

    return {
        mfaCode,
        mfaCodeObj,
        shouldWeSwapToNextInput,
        shouldWeGoToPreviousInput,
        mfaCodeIndexDisabled,
        refs,
        hasEnteredCode,
    };
}

// Implementation
/*function MfaCode() {
    const {
        mfaCode,
        shouldWeSwapToNextInput,
        shouldWeGoToPreviousInput,
        mfaCodeIndexDisabled,
        refs,
    } = useMfaCode();

    return [...Array(6)].map((k, i) => {
        return (
            <input
                key={i}
                style={{width: 50}}
                value={mfaCode[i]}
                onChange={(e) => shouldWeSwapToNextInput(e, i)}
                onKeyDown={(e) => shouldWeGoToPreviousInput(e, i)}
                ref={refs[i]}
                maxLength={1}
                disabled={mfaCodeIndexDisabled[i]}
            />
        );
    });
}
*/
