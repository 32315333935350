export const inputGroupStyle = {
    'label[data-focus] + div.chakra-input__group .chakra-input__right-addon': {
        borderColor: 'brand.500',
        boxShadow: '0 0 0 1px var(--chakra-colors-brand-500)',
    },
    '.chakra-input__group:hover .chakra-input__right-addon': {
        borderColor: 'gray.300',
    },
};
export const inputBorderStyle = {
    borderRight: 'none',
    borderEndRadius: 0,
};

export const inputFocusStyle = {
    borderColor: 'brand.500',
    boxShadow: '-1px 0 0 1px var(--chakra-colors-brand-500)',
};
export const inputHoverStyle = {
    borderColor: 'gray.300',
    '.chakra-input__right-addon': {
        borderColor: 'gray.300',
    },
};
