import {Box, Progress, Text } from '@chakra-ui/react';

export default function ProgressBarView({current, total, completionCounter}) {
    return (
        <Box
            mt={5}
            shadow={'md'}
            rounded={'md'}
            bg={'blue.100'}
            padding={'10px'}>
            <Text size="md" color='black'>{`Working on Request ${current} of ${total}`}</Text>
            <Progress
                rounded={'md'}
                mt={2}
                hasStripe
                isAnimated
                value={completionCounter}
                max={total}
            />
        </Box>
    );
}
