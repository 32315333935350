import React from "react";
import MaterialTable from "../../../../../components/v4/MaterialTable";
import { axios } from "../../../../../services/axios";

function useConsentHistory({ data, columns }) {
    const [historyTableData, setHistoryTableData] = React.useState([]);

    React.useEffect(() => {
        if (data.id) {
            fetchHistoryTableData();
        }
    }, [data]);

    const fetchHistoryTableData = async () => {
        try {
            const historyTableDataResponse = await axios.get(`/OperatorConnectOperatorConsent/${data.id}/History`);
            setHistoryTableData(historyTableDataResponse.data);
        }
        catch (error) {
            window.alert("We are unable to retrieve Consent History. Please try again later.");
        }
    };

    return { historyTableData, columns };
}

export default function ConsentHistory(props) {
    const { historyTableData, columns } = useConsentHistory(props);

    return (
        <React.Fragment>
            <MaterialTable
                title="Consent History"
                data={historyTableData}
                columns={columns}
            />
        </React.Fragment>)
}
