import { useEffect, useState } from 'react';
import { Link as RLink } from 'react-router-dom';
import { Image } from '@chakra-ui/react';
import Button from '../../../../components/v4/Button';
import Heading from '../../../../components/v4/Heading';
import OrderedList from '../../../../components/v4/OrderedList';
import ListItem from '../../../../components/v4/ListItem';
import Spinner from '../../../../components/v4/Spinner';
import Link from '../../../../components/v4/Link';
import Text from '../../../../components/v4/Text';
import TcapLogo from 'assets/images/TCAP-Favicon.svg';

import { axios } from 'services/axios';

import QRCode from 'react-qr-code';
// import config from '../../../../config.json';
import MfaWizardStepProgress from './MfaWizardStepProgress';
import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

export default function MfaWizardStepBodyOne({ authResponse, step, setStep }) {
    const [qrUrl, setQrUrl] = useState(null);
    const {favIconPath} = useSelector(state => state.header);

    useEffect(() => {
        fetch(`${import.meta.env.VITE_APP_API_URL}/AdminUser/MFA`, {
            headers: {
                accept: 'application/json, text/plain, */*',
                authorization: `bearer ${authResponse.token}`,
                'x-requestscope': '20',
            },
            method: 'GET',
            mode: 'cors',
            credentials: 'omit',
        }).then(async (response) => {
            const QRUrl = await response.json();
            setQrUrl(QRUrl);
        });
    }, []);

    return (
        <>
            <Box>
                <MfaWizardStepProgress step={step} />
            </Box>
            <Box padding={'20px 30px'}>
                <Heading
                    as="h2"
                    sx={{ textAlign: 'center', marginBottom: 25 }}
                    color="black">
                    Let's setup multi factor authentication
                </Heading>

                <OrderedList
                    sx={{
                        listStyle: 'numbered',
                        li: {
                            color: 'black',
                        },
                    }}>
                    <ListItem fontSize="md" sx={{ marginBottom: 3 }}>
                        Install the {` `}
                        <Link
                            target="_blank"
                            color="brand.500"
                            _hover={{ color: 'brand.500' }}
                            href="https://support.microsoft.com/en-us/account-billing/download-and-install-the-microsoft-authenticator-app-351498fc-850a-45da-b7b6-27e523b8702a">
                            Microsoft Authenticator {` `}
                        </Link>
                        or the {` `}
                        <Link
                            target="_blank"
                            color="brand.500"
                            _hover={{ color: 'brand.500' }}
                            href="https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DAndroid">
                            Google Authenticator
                        </Link>{' '}
                        app on your phone.
                    </ListItem>
                    <ListItem fontSize="md" sx={{ marginBottom: 3 }}>
                        Open the app, tap begin setup.
                    </ListItem>
                    <ListItem fontSize="md" sx={{ marginBottom: 3 }}>
                        Choose to scan barcode, then scan the QR code below.
                    </ListItem>
                </OrderedList>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: 13,
                        margin: '0 auto',
                        width: '158px',
                        height: '158px',
                        alignItems: 'center',
                        position: 'relative',
                    }}>
                    {qrUrl ? (
                        <>
                            <Image
                                fallbackSrc={TcapLogo}
                                src={import.meta.env.VITE_APP_IMAGE_BASE + favIconPath}
                                boxSize="36px"
                                className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/3 bg-white"
                                borderRadius={4}
                                shadow="xs"
                                aria-label="Partner Logo"
                            />
                            <QRCode value={qrUrl} size={158} style={{ borderRadius: '4px' }} />
                        </>
                    ) : (
                        <Spinner color="brand.500" />
                    )}
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    padding: '0 15px',
                    height: '60px',
                }}>
                <Button onClick={() => setStep(2)}>Next</Button>
            </Box>
        </>
    );
}
