import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const MENU_COLLAPSE = 'MENU_COLLAPSE';
export const APP_SETTINGS = 'appSettings';
export const DEFAULT_COLOR = 'primary';
const LayoutContext = createContext(null);

export function LayoutProvider({ children }) {
    const dispatch = useDispatch();
    const { colorScheme } = useSelector((state) => state.settings);
    const [selectScope, setSelectScope] = useState(20);
    const [appSettings, setAppSettings] = useState(() => {
        return (
            JSON.parse(localStorage.getItem(APP_SETTINGS)) || {
                isMenuCollapse: false,
                touched: false,
                colorScheme,
            }
        );
    });

    const [color, setColor] = useState(colorScheme);

    const onMenuToggle = useCallback(() => {
        setAppSettings((prevSettings) => ({
            ...prevSettings,
            isMenuCollapse: !prevSettings.isMenuCollapse,
            touched: true,
        }));
    }, []);

    const handleColorChange = useCallback(
        (value) => {
            setColor(value);
            dispatch({
                type: 'CHANGE_COLOR_SCHEME',
                payload: value,
            });

            localStorage.setItem(
                APP_SETTINGS,
                JSON.stringify({ ...appSettings, colorScheme: value }),
            );
        },
        [appSettings, dispatch],
    );

    const value = useMemo(
        () => ({
            selectScope,
            setSelectScope,
            isMenuCollapse: appSettings.isMenuCollapse || false,
            touched: appSettings.touched || false,
            onMenuToggle,
            color,
            setColor,
            handleColorChange,
            dispatch,
        }),
        [
            selectScope,
            setSelectScope,
            appSettings.isMenuCollapse,
            appSettings.touched,
            color,
            setColor,
            onMenuToggle,
            handleColorChange,
            dispatch,
        ],
    );

    useEffect(() => {
        const storedSettings = JSON.parse(localStorage.getItem(APP_SETTINGS));
        setAppSettings((prevSettings) => ({
            ...prevSettings,
            touched: false,
        }));
        dispatch({
            type: 'LOAD_SETTINGS_FROM_LOCAL_STORAGE',
            payload: storedSettings,
        });
    }, [dispatch]);

    useEffect(() => {
        localStorage.setItem(
            APP_SETTINGS,
            JSON.stringify({
                ...appSettings,
                isMenuCollapse: appSettings.isMenuCollapse,
            }),
        );
        dispatch({
            type: 'CHANGE_MENU_COLLAPSE',
            payload: appSettings.isMenuCollapse,
        });
    }, [appSettings.isMenuCollapse, appSettings, dispatch]);

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if ((e.ctrlKey || e.metaKey) && e.key === '.') {
                setAppSettings((prevSettings) => ({
                    ...prevSettings,
                    isMenuCollapse: !prevSettings.isMenuCollapse,
                    touched: true,
                }));
            }
        });
    }, []);

    return (
        <LayoutContext.Provider value={value}>
            {children}
        </LayoutContext.Provider>
    );
}

export const useLayoutContext = () => {
    const context = useContext(LayoutContext);

    if (!context) {
        throw new Error(
            'useLayoutContext must be used within a LayoutProvider',
        );
    }

    return context;
};

LayoutContext.displayName = 'LayoutContext';
