import { Box, Heading } from '@chakra-ui/react';

import {
    TpmWrapper,
    TpmContent,
    MfaTeamsPhoneMobileWizard,
    TpmTrialProcess,
    TpmTrialTable,
    TpmTrialTableFinish,
    useTpmContext,
} from './';

function TeamsPhoneMobileTrial() {
    const {
        tpmAddress: [addressAction],
    } = useTpmContext();

    return (
        <TpmWrapper>
            <Heading as="h1" className="!font-semibold mb-6">
                Teams Phone Mobile Activation
            </Heading>
            <TpmContent className="flex h-tpm flex-col items-center justify-center">
                <Box className="mx-auto my-0">
                    <Box className="max-w-[700px] mx-auto my-0">
                        {addressAction === 'SMS_AUTHORIZATION' && (
                            <MfaTeamsPhoneMobileWizard />
                        )}

                        {addressAction === 'SELECT_SERVICES' && (
                            <TpmTrialTable />
                        )}

                        {/* {addressAction === 'TRIAL_TABLE' && <TpmTrialTable />} */}

                        {addressAction === 'TRIAL_DONE' && (
                            <TpmTrialTableFinish />
                        )}

                        {addressAction === 'GET' && <TpmTrialProcess />}
                    </Box>
                </Box>
            </TpmContent>
        </TpmWrapper>
    );
}

export default TeamsPhoneMobileTrial;
