export default {
    container: {
        position: 'relative',
        borderRadius: 'base',
        padding: '10px',
        // backgroundColor: '#FFF',
        boxShadow: 'base',
        maxWidth: '250px',
        minWidth: '250px',
        width: '100%',
        transition: 'all 0.5s',
        ':hover': {
            cursor: 'pointer',
            transform: 'scale(1.05)',
            boxShadow: 'lg',
        },
    },
    title: {
        fontWeight: 'bold',
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '120px',
    },
    image: {
        maxHeight: '75px',
    },
};
