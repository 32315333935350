import React from 'react';
import { Button, Row, Col, Container, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';

import { axios } from 'services/axios';
import { onModalError } from 'components/tables/TableAndModal';

import 'pages/organisations/companies/edit/alert.css';
import { COMPANY, PARTNER, WHOLESALER } from './BillingForm';
import { CANNOT_DELETE_MODE } from 'components/tables/ReduxModal';
import walkthroughIds from '../walkthroughIds';

/*
 * Admin form for companies in the edit tab
 * Has a danger zone component with more senstive settings
 * Also has the ability to convert a company off trial
 */
const DeletePartnerWS = ({ mode }) => {
    const dispatch = useDispatch();

    // modal data from state
    const { state, data: modalData } = useSelector((state) => {
        return { ...state.modal };
    });

    const isPartner = mode === PARTNER;
    const isWS = mode === WHOLESALER;
    const entity = isPartner ? 'Partner' : 'Wholesaler';
    const entitySub = isPartner ? 'Companies' : 'Partners';

    // onclick Delete button, checks if partner/WS still have companies/partners under them & numbers assigned
    const onDeleteHandler = () => {
        Promise.all([
            isPartner
                ? axios.get(`/partner/${state?.id}/numberblocks/`)
                : axios.get(`/numberblocks/${state?.id}/`),
            isPartner
                ? axios.get(`/companies/${state?.id}`)
                : axios.get(`/partners/${state?.id}`),
        ])
            .then(([numBlockResponse, subResponse]) => {
                const numBlockIsEmpty =
                    numBlockResponse.data.length === 0; // dont proceed if they have >= 1 num blocks assigned under them
                const partnerSubData =
                    isPartner &&
                    subResponse.data.filter((v) => v.id !== state?.id);
                const partnerSubIsEmpty =
                    isPartner && partnerSubData.length === 0; // have at least 1 company under them that is not themself, dont proceed

                const wsSubIsEmpty = isWS && subResponse.data.length === 0; // dont proceed if they have >= 1 partner(s) under them

                const issues = {
                    numberBlocks: {
                        message:
                            !numBlockIsEmpty &&
                            `There are still ${numBlockResponse.data.length} Number Blocks assigned to this ${entity}.`,

                        response: numBlockResponse.data,
                    },
                    sub: {
                        message:
                            (isPartner ? !partnerSubIsEmpty : !wsSubIsEmpty) &&
                            `There are still ${
                                isPartner
                                    ? partnerSubData.length
                                    : subResponse.data.length
                            } ${entitySub} assigned to this ${entity}.`,

                        response: isPartner ? partnerSubData : subResponse.data,
                    },
                };

                dispatch({
                    type: 'OPEN_MODAL',
                    payload: {
                        mode: CANNOT_DELETE_MODE,
                        uploading: false,
                        loading: false,
                        data: modalData,
                        state: {
                            ...state,
                            issues,
                            isNextButtonDisabled:
                                issues.numberBlocks.message ||
                                issues.sub.message,
                        },
                    },
                });
            })
            .catch(onModalError);
    };

    // rendering the form and danger zone
    return (
        <Form>
            <Box
                sx={{
                    marginInline: '8%',
                }}>
                <h4> Danger Zone </h4>
                <Container fluid className="roundBox danger">
                    <Row style={{ minHeight: 60 }}>
                        <Col style={{ minWidth: '80%' }}>
                            <b> Delete</b> <br />
                            {`By deleting a ${entity},
                            invoices will change to finalizing and no further
                            billing will occur`}
                        </Col>
                        <Col
                            style={{
                                minWidth: '20%',
                                textAlign: 'right',
                                marginTop: 10,
                            }}>
                            <Button
                                variant="danger"
                                onClick={onDeleteHandler}
                                data-walkthroughid={
                                    walkthroughIds.partners.ufDeleteBtn
                                }>
                                Delete
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Box>
        </Form>
    );
};

export default DeletePartnerWS;
