import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, useToast, Stack, Heading } from '@chakra-ui/react';
import { Button as BsButton } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
import {
    getLanguagesList,
    getTemplatesList,
    getTimezonesList,
    postResourceAccount,
} from '@/API/ResourceAccount';
import { getAppAuthByCompanyId, getAppAuthById } from '@/API/AppAuth';
import PictureViewer from 'components/pictureViewer/PictureViewer';

import { Error } from 'components/index';
import noImage from 'assets/images/noImage.png';

import { initialStates } from '../constants';
import walkthroughIds from '../../users/walkthroughIds';
import BuilderPageForm from './BuilderPageForm';
import SkeletonBuilderPage from './SkeletonBuilderPage';

function ResourceAccBuilder() {
    const navigate = useNavigate();
    const toast = useToast();

    const [builder, setBuilder] = useState(initialStates);
    const [initialDomain, setInitialDomain] = useState('');
    const [userSync, setUserSync] = useState(false);
    const [imagePath, setImagePath] = useState(noImage);
    const [hasAppAuth, setHasAppAuth] = useState(true);
    const [isAppAuthLoading, setIsAppAuthLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formState, setFormState] = useState('idle');
    const [timezones, setTimezones] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [errors, setErrors] = useState(initialStates);

    const { currentCompany } = useSelector((state) => state.navigationLists);
    const dispatch = useDispatch();
    const { autoAttendant } = walkthroughIds;

    const createBuilder = (data) => {
        setFormState('submitting');
        postResourceAccount({
            formData: {
                ...data,
                companyId: currentCompany,
            },
        })
            .then((res) => {
                toast({
                    title: 'Success',
                    description: 'Builder has been created successfully',
                    status: 'success',
                    duration: 3000,
                });

                setTimeout(() => {
                    dispatch({
                        type: 'CHANGE_CURRENT_SCOPE',
                        payload: 20,
                    });
                    navigate('/jobs');
                }, 500);
            })
            .catch((err) => {
                toast({
                    title: 'Error',
                    description: `Cannot create new builder: ${
                        err.response.data.message || 'Invalid Permission'
                    }`,
                    status: 'error',
                    duration: 3000,
                });
            })
            .finally(() => setFormState('idle'));
    };

    const onSubmit = (data) => {
        setBuilder({
            ...builder,
            ...data,
        });

        createBuilder(data);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBuilder((prev) => ({
            ...prev,
            [name]: value,
        }));
        if (builder?.name && !/^[0-9a-zA-Z-_.]+$/.test(builder?.name)) {
            setErrors((prev) => ({
                ...prev,
                name: 'Field is required and can only contain upper and lower case letters, numbers, periods, underscores, or hyphens.',
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                name: '',
            }));
        }
    };

    const handleSelectTimezone = (e) => {
        setBuilder((prev) => ({
            ...prev,
            timezone: e.id,
        }));
    };

    const handleSelectLanguage = (e) => {
        setBuilder((prev) => ({
            ...prev,
            language: e.id,
        }));
    };

    const handleSelectTemplate = (e) => {
        const selectedTemplate = templates.find((a) => a.id === e.id);
        let path;
        if (selectedTemplate) {
            path = `${import.meta.env.VITE_APP_IMAGE_BASE}/images/${
                selectedTemplate.image
            }`;
        } else {
            path = noImage;
        }
        setBuilder((prev) => ({
            ...prev,
            template: e.id,
        }));
        setImagePath(path);
    };

    const handleNavigateButton = () => {
        navigate('/resource-accounts');
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(builder);
    };

    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            getTimezonesList().then((res) => {
                setTimezones(res.data);
            }),

            getLanguagesList().then((res) => {
                setLanguages(res.data);
            }),

            getTemplatesList().then((res) => {
                setTemplates(res.data);
            }),
            getAppAuthByCompanyId(currentCompany).then(async (res) => {
                const mAppAuth = await res.data.find(
                    (a) => a?.appAuthTypeID === 'Microsoft',
                )?.id;
                setIsAppAuthLoading(true);
                if (mAppAuth) {
                    getAppAuthById(mAppAuth).then((resp) => {
                        setInitialDomain(resp.data.initialDomain);
                        setUserSync(resp.data.userSync);
                        setIsAppAuthLoading(false);
                    });
                } else {
                    setHasAppAuth(false);
                    setIsAppAuthLoading(false);
                }
            }),
        ]).finally(() => setIsLoading(false));
    }, [currentCompany]);

    const componentLoadingState = isLoading || isAppAuthLoading;
    if (componentLoadingState) {
        return (
            <SkeletonBuilderPage handleNavigateButton={handleNavigateButton} />
        );
    }

    if (!componentLoadingState) {
        if (!hasAppAuth) {
            return (
                <>
                    <Error
                        error={{
                            message:
                                'You need to have Microsoft App Auth to create a Resource Account.',
                        }}
                    />
                    <Stack
                        px={{ base: '4', md: '6' }}
                        pt={{ base: '5', md: '6' }}
                        pb="2px"
                        sx={{
                            justifyContent: 'center',
                            width: '170px',
                            margin: '0 auto',
                        }}
                        direction={{ base: 'column', md: 'row' }}
                    >
                        <Box>
                            <BsButton
                                variant="secondary"
                                className="h-10 min-w-[5rem] font-semibold"
                                onClick={handleNavigateButton}
                            >
                                Back
                            </BsButton>
                        </Box>
                    </Stack>
                </>
            );
        } else if (!initialDomain || !userSync) {
            return (
                <>
                    <Error
                        error={{
                            message:
                                'Additional configuration required on AppAuth to use this feature.',
                        }}
                    />
                    <Stack
                        px={{ base: '4', md: '6' }}
                        pt={{ base: '5', md: '6' }}
                        pb="2px"
                        sx={{
                            justifyContent: 'center',
                            width: '170px',
                            margin: '0 auto',
                        }}
                        direction={{ base: 'column', md: 'row' }}
                    >
                        <Box>
                            <BsButton
                                variant="secondary"
                                className="h-10 min-w-[5rem] font-semibold"
                                onClick={handleNavigateButton}
                            >
                                Back
                            </BsButton>
                        </Box>
                    </Stack>
                </>
            );
        }
    }

    return (
        <>
            <Heading as="h3" fontSize="1.75rem">
                Builder
            </Heading>

            <Box className="flex flex-col">
                <Box className="mx-auto mt-4 w-full">
                    <Box as="form" onSubmit={handleSubmit}>
                        <Box className="pt-0" id="builder-form-wrapper">
                            <BuilderPageForm
                                initialDomain={initialDomain}
                                builder={builder}
                                errors={errors}
                                timezones={timezones}
                                languages={languages}
                                templates={templates}
                                handleInputChange={handleInputChange}
                                handleSelectTimezone={handleSelectTimezone}
                                handleSelectLanguage={handleSelectLanguage}
                                handleSelectTemplate={handleSelectTemplate}
                            />
                        </Box>

                        <Box
                            className="mx-auto"
                            data-walkthroughid={autoAttendant.ufTemplateImage}
                        >
                            {imagePath !== '' && (
                                <PictureViewer
                                    imageURL={imagePath || noImage}
                                />
                            )}
                        </Box>
                        <Stack
                            // spacing="4"
                            px={{ base: '4', md: '6' }}
                            pt={{ base: '5', md: '6' }}
                            pb="2px"
                            sx={{
                                justifyContent: 'center',
                                width: '170px',
                                margin: '0 auto',
                            }}
                            direction={{ base: 'column', md: 'row' }}
                        >
                            <Box>
                                <BsButton
                                    variant="secondary"
                                    className="h-10 min-w-[5rem] font-semibold"
                                    onClick={handleNavigateButton}
                                >
                                    Back
                                </BsButton>
                            </Box>
                            <Box>
                                <BsButton
                                    variant="primary"
                                    type="submit"
                                    className="ml-2 h-10 min-w-[5rem] font-semibold"
                                >
                                    {formState === 'submitting'
                                        ? 'Creating...'
                                        : 'Create'}
                                </BsButton>
                            </Box>
                        </Stack>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default ResourceAccBuilder;
