import {useState, useEffect} from 'react';
import {Box, FormLabel} from '@chakra-ui/react';
import {AddIcon, DownloadIcon} from '@chakra-ui/icons';
import differenceBy from 'lodash/differenceBy';
import take from 'lodash/take';

import {numbersAvailableMock, numbersChosenMock} from './mockdata';

import Button from 'components/v4/Button';
import LoadingIndicator from 'components/v4/LoadingIndicator';

// import TpmHeader from './TpmHeader';
// import TpmWrapper from './TpmWrapper';
import {useNavigate} from 'react-router-dom';
import VirtualizedTable from './VirtualizedTable';
import {
    // useVirtualizedTable,
    VirtualizedTableProvider,
} from './VirtualizedTableProvider';
import walkthroughIds from './walkthroughIds';
import {useTpmContext} from './TpmProvider';

// const TableWrapper = ({title, children}) => {
//     return (
//         <TableContainer
//             overflowY="auto"
//             className="w-1/2 max-w-fit max-h-[50vh] rounded-lg">
//             <Table
//                 variant="striped"
//                 colorScheme={'messenger'}
//                 className="w-full">
//                 {children}
//             </Table>
//         </TableContainer>
//     );
// };
// const TableHeader = ({children}) => {
//     return (
//         <Thead position="sticky" top="59px" background="white" margin={0}>
//             {children}
//         </Thead>
//     );
// };

function BadgeWithCount({count}) {
    return (
        <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-brand-400 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
            {count}
        </div>
    );
}

function MoveButton({children, isDisabled, handleOnClick, dataWalkthroughId}) {
    return (
        <button
            type="button"
            class="relative inline-flex justify-center items-center p-3 h-8 leading-7 font-medium text-center text-blue-700 border-brand-500 border-[1px] rounded-lg disabled:opacity-40 disabled:cursor-not-allowed focus:ring-4 focus:outline-none focus:ring-blue-30"
            disabled={isDisabled}
            onClick={handleOnClick}
            data-walkthroughid={dataWalkthroughId}>
            {children}
        </button>
    );
}

export default function TransferList() {
    const [numbersAvailable, setNumbersAvailable] = useState([]);
    const [numbersChosen, setNumbersChosen] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [checkedAvailable, setCheckedAvailable] = useState([]);
    const [checkedChosen, setCheckedChosen] = useState([]);
    const [fileUpload, setFileUpload] = useState(null);
    const [query, setQuery] = useState('');
    const {
        tpmAddress: [, setAddressAction],
    } = useTpmContext();

    const {
        ufTransferListPortingButton,
        ufTransferListImportCsvButton,
        ufTransferListMoveRightButton,
        ufTransferListMoveLeftButton,
        ufTransferListSwapRightButton,
        ufTransferListSwapLeftButton,
        ufTransferListNextButton,
        ufTransferListCancelButton,
    } = walkthroughIds.teamsPhoneMobile;
    const navigate = useNavigate();

    const fileName = fileUpload ? fileUpload.name : 'Import CSV Number';

    const importNumberChangeHandler = (e) => {
        // TODO: need to setup fileUpload to table later
        // second case is for firefox
        if (e.target.files[0].type === 'text/csv' || e.target.files[0].type === 'application/vnd.ms-excel') {
            setFileUpload(e.target.files[0]);
        } else {
            window.alert('File must be .csv type');
        }
    };

    const handleGetData = () => {
        setIsLoading(true);
        setTimeout(() => {
            setNumbersChosen(take(numbersChosenMock, 10));
            setNumbersAvailable(take(numbersAvailableMock, 10));
            setIsLoading(false);
        }, 200);
    };

    const handleMoveToAvailable = () => {
        setNumbersAvailable((prev) => [...prev, ...checkedChosen]);
        setNumbersChosen((all) => differenceBy(all, checkedChosen, 'id'));
        setCheckedChosen([]);
    };
    const handleMoveToChosen = () => {
        setNumbersChosen((prev) => [...prev, ...checkedAvailable]);
        setNumbersAvailable((all) => differenceBy(all, checkedAvailable, 'id'));
        setCheckedAvailable([]);
    };
    const handleCheckAvailableRow = (e, data) => {
        if (e.target.checked) {
            setCheckedAvailable((prev) => [...prev, data]);
            return;
        }
        setCheckedAvailable((prev) => prev.filter((v) => v.id !== data.id));
    };
    const handleCheckChosenRow = (e, data) => {
        if (e.target.checked) {
            setCheckedChosen((prev) => [...prev, data]);
            return;
        }
        setCheckedChosen((prev) => prev.filter((v) => v.id !== data.id));
    };
    const handleMoveAllToAvailable = () => {
        const movableNumbers = numbersChosen.filter((v) => !v.disabled);
        setNumbersAvailable((prev) => [...prev, ...movableNumbers]);
        setNumbersChosen((all) => differenceBy(all, movableNumbers, 'id'));
        setCheckedChosen([]);
    };
    const handleMoveAllToChosen = () => {
        const movableNumbers = numbersAvailable.filter((v) => !v.disabled);
        setNumbersChosen((prev) => [...prev, ...movableNumbers]);
        setNumbersAvailable((all) => differenceBy(all, movableNumbers, 'id'));
        setCheckedAvailable([]);
    };

    const handleNumberPorting = () => {
        // TODO: need to setup number porting later
    };

    const handleCancelButton = () => {
        navigate('/dashboard-services');
    };

    const handleNextButton = () => {
        setAddressAction('WIZARD_SUCCESS');
    };

    useEffect(() => {
        handleGetData();
    }, []);

    if (isLoading) {
        return (
            <Box className="flex h-tpm flex-col items-center justify-center">
                <Box
                    className="justify-center items-center"
                    sx={{
                        '& .chakra-progress': {
                            marginTop: 'auto',
                            marginBottom: 'auto',
                        },
                    }}>
                    <LoadingIndicator className="my-auto" />;
                </Box>
            </Box>
        );
    }

    return (
        <>
            <Box className="w-full">
                <Box className="flex justify-start my-6 ml-[6px] ">
                    <Button
                        leftIcon={<AddIcon />}
                        size="sm"
                        mr={9}
                        onClick={handleNumberPorting}
                        data-walkthroughid={ufTransferListPortingButton}>
                        Add Number Porting
                    </Button>
                    <Button
                        as={FormLabel}
                        leftIcon={<DownloadIcon />}
                        size="sm"
                        cursor="pointer"
                        data-walkthroughid={ufTransferListImportCsvButton}>
                        {fileName}
                        <input
                            className="hidden"
                            type="file"
                            id="image-input"
                            accept=".csv"
                            onChange={importNumberChangeHandler}
                        />
                    </Button>
                </Box>
            </Box>
            <Box className="flex flex-row justify-center">
                <VirtualizedTableProvider data={numbersAvailable}>
                    <VirtualizedTable
                        checkedArray={checkedAvailable}
                        onClick={handleCheckAvailableRow}
                        data={numbersAvailable}
                        captionName="Available Numbers"
                        query={query}
                        setQuery={setQuery}
                    />
                    {/* </Box> */}
                    <Box className="flex flex-col gap-4 p-4 justify-center">
                        <MoveButton
                            isDisabled={checkedAvailable.length === 0}
                            handleOnClick={handleMoveToChosen}
                            dataWalkthroughId={ufTransferListMoveRightButton}>
                            &gt;
                            <BadgeWithCount count={checkedAvailable.length} />
                        </MoveButton>

                        <MoveButton
                            isDisabled={checkedChosen.length === 0}
                            handleOnClick={handleMoveToAvailable}
                            dataWalkthroughId={ufTransferListMoveLeftButton}>
                            &lt;
                            <BadgeWithCount count={checkedChosen.length} />
                        </MoveButton>

                        <MoveButton
                            isDisabled={
                                !numbersAvailable.some((v) => !v.disabled)
                            }
                            handleOnClick={handleMoveAllToChosen}
                            dataWalkthroughId={ufTransferListSwapRightButton}>
                            &gt;&gt;
                        </MoveButton>

                        <MoveButton
                            isDisabled={!numbersChosen.some((v) => !v.disabled)}
                            handleOnClick={handleMoveAllToAvailable}
                            dataWalkthroughId={ufTransferListSwapLeftButton}>
                            &lt;&lt;
                        </MoveButton>
                    </Box>

                    <VirtualizedTable
                        checkedArray={checkedChosen}
                        onClick={handleCheckChosenRow}
                        data={numbersChosen}
                        captionName="Chosen Numbers"
                        query={query}
                        setQuery={setQuery}
                    />
                </VirtualizedTableProvider>
            </Box>

            <Box className="flex justify-center mt-12">
                <Button
                    className="mr-14"
                    variant="outline"
                    w="84px"
                    onClick={handleCancelButton}
                    data-walkthroughid={ufTransferListCancelButton}>
                    Cancel
                </Button>
                <Button
                    className="ml-14"
                    w="84px"
                    onClick={handleNextButton}
                    data-walkthroughid={ufTransferListNextButton}>
                    Next
                </Button>
            </Box>
        </>
    );
}
