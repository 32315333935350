// NOTE: use .jsx to import JSX from libraries
import { MdCheck } from 'react-icons/md';
import { FiberManualRecordRounded } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

import { dateStringToDate } from 'utils/utils';
import { MuiCustomIcon } from './MuiCustomIcon';
import config from '@/config.json';

export const statusEnum = (tableData = []) => {
    return tableData?.reduce((prev, current) => {
        if (!prev[current.status]) {
            switch (current.status) {
                case 0:
                    prev[current.status] = (
                        <Tooltip
                            title={
                                config.companies.statusNavbarJSON[
                                    current.status
                                ]
                            }>
                            <FiberManualRecordRounded
                                style={{
                                    color: 'green',
                                    fontSize: 'small',
                                }}
                            />
                        </Tooltip>
                    );
                    break;
                case 3: //for pending approval
                    prev[current.status] = (
                        <Tooltip
                            title={
                                config.companies.statusNavbarJSON[
                                    current.status
                                ]
                            }>
                            <FiberManualRecordRounded
                                style={{
                                    color: 'purple',
                                    fontSize: 'small',
                                }}
                            />
                        </Tooltip>
                    );
                    break;
                case 20:
                    prev[current.status] = (
                        <Tooltip
                            title={
                                config.companies.statusNavbarJSON[
                                    current.status
                                ]
                            }>
                            <FiberManualRecordRounded
                                style={{
                                    color: 'red',
                                    fontSize: 'small',
                                }}
                            />
                        </Tooltip>
                    );
                    break;
                default:
                    prev[current.status] = (
                        <Tooltip
                            title={
                                config.companies.statusNavbarJSON[
                                    current.status
                                ]
                            }>
                            <FiberManualRecordRounded
                                style={{
                                    color: '#c1c92a',
                                    fontSize: 'small',
                                }}
                            />
                        </Tooltip>
                    );
                    break;
            }
        }
        return prev;
    }, {});
};

/**
 * @type {import('material-table').Column<{title: string; field: string; & any}>[]}
 * @const
 */
export const companiesColumns = [
    {
        title: 'Friendly Company Name',
        field: 'name',
    },
    {
        title: 'Legal Entity Name',
        field: 'entityName',
    },
    {
        title: 'Status',
        field: 'status',
    },
    {
        title: 'Active',
        field: 'isActive',
        render: (rowData) =>
            rowData.isActive ? <MdCheck fontSize={24} /> : <MuiCustomIcon />,
    },
    {
        title: 'On Trial',
        field: 'isTrial',
        render: (rowData) =>
            rowData.isTrial ? <MdCheck fontSize={24} /> : <MuiCustomIcon />,
    },
    {
        title: 'Initial Domain',
        field: 'initialDomain',
    },
    {
        title: 'Last Pairing',
        field: 'lastSuccessfulPair',
        // type: 'datetime',
        // TODO: change to backend data when available
        render: (rowData) =>
            rowData.lastSuccessfulPair ? (
                <span>
                    {dateStringToDate(
                        '2022-11-25T05:04:23.1329142',
                        'datetime',
                    )}
                </span>
            ) : (
                ''
            ),
    },
    {
        title: 'Default Plan',
        field: 'defaultPlan',
    },
    {
        title: 'Trial On Hold',
        field: 'trialHold',
        render: (rowData) =>
            rowData.isTrial ? <MdCheck fontSize={24} /> : <MuiCustomIcon />,
    },
    {
        title: 'Company ID',
        field: 'id',
        hidden: true,
        hideFilterIcon: true,
    },
];

/**
 * @type {import('material-table').Column<any>[]}
 * @const
 */
export const columnDataRender = (companies) => [
    ...companiesColumns.slice(0, 2),
    {
        title: 'Status',
        field: 'status',
        lookup: statusEnum(companies),
        customSort: (l, r) => {
            const leftValue = typeof l === 'object' ? l.status ?? 0 : l ?? 0;
            const rightValue = typeof r === 'object' ? r.status ?? 0 : r ?? 0;
            return leftValue - rightValue;
        },
    },
    ...companiesColumns.slice(3),
];
