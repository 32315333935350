import {
    Box,
    Button,
    Progress,
    Stack,
    UnorderedList,
    ListItem,
    Text,
} from '@chakra-ui/react';
import { Alert, AlertIcon } from 'components/v4';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

/**
 * @typedef {Object} AddStatusProps
 * @property {number} successCount
 * @property {number} failedCount
 * @property {aggregateTable} aggregateTable
 * @property {VoidFunction} setSubmit
 * @property {VoidFunction} setSuccessCount
 * @property {VoidFunction} setFailedCount
 * @param {AddStatusProps} props
 * @returns
 */
export default function AddStatus(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        aggregateTable,
        updateAggregateTable,
        successValues,
        setSuccessValues,
        setSubmit,
        errorValues,
        setErrorValues,
    } = props;
    const totalCount = aggregateTable.length;
    const successCount = successValues.length;
    const failedCount = errorValues.length;
    const completeStatus = successCount + failedCount >= totalCount;

    const handleCloseModal = () => {
        setSubmit(false);
        setErrorValues([]);
        setSuccessValues([]);
        updateAggregateTable((prev) => {
            const aggregatedErrors = prev.filter((item) =>
                errorValues.some(
                    (errorItem) => errorItem.number === item.number,
                ),
            );
            return aggregatedErrors;
        });
    };

    return (
        <Box>
            {completeStatus ? (
                <>
                    <Alert status={failedCount ? 'error' : 'success'} mt={5}>
                        <AlertIcon />

                        <Text size="sm" whiteSpace="pre-line">
                            {failedCount ? 'Incomplete' : 'Complete'}
                        </Text>
                    </Alert>
                </>
            ) : (
                <Box
                    mt={5}
                    mb={10}
                    shadow={'md'}
                    rounded={'md'}
                    bg={'blue.200'}
                    padding={'10px'}>
                    <Text size="md">{`Working on Request ${
                        successCount + failedCount
                    } of ${totalCount}`}</Text>
                    <Progress
                        rounded={'md'}
                        mt={2}
                        hasStripe
                        isAnimated
                        value={successCount + failedCount}
                        max={totalCount}
                    />
                </Box>
            )}

            <Box mt={5} padding={'10px'}>
                <Text sx={{ color: 'green.500' }} size="md">
                    Successful : {successCount}
                </Text>
                <Text sx={{ color: 'red.500' }} mt={1} size="md">
                    Failed : {failedCount}
                </Text>
                <Box sx={{ color: 'green.500' }} mt={3} size="md">
                    <UnorderedList color="green.500">
                        {successValues.map((number) => (
                            <ListItem key={number.id}>
                                {`${number.number} with block size ${number.blockSize} has been added successfully`}
                            </ListItem>
                        ))}
                    </UnorderedList>
                </Box>
                <Box sx={{ color: 'red.500' }} mt={3} size="md">
                    <UnorderedList color="red.500">
                        {errorValues.map((error) => (
                            <ListItem key={error.number}>
                                {error.message &&
                                    `${error.number}: ${error.message}`}
                            </ListItem>
                        ))}
                    </UnorderedList>
                </Box>
            </Box>

            {successCount + failedCount !== totalCount && (
                <Box mt={5} padding={'10px'}>
                    <Text size="md">
                        Do not close this window or navigate away. This may take
                        a few minutes.
                    </Text>
                </Box>
            )}

            {successCount + failedCount === totalCount && (
                <Stack
                    spacing="6"
                    px={{ base: '4', md: '6' }}
                    pt={{ base: '5', md: '6' }}
                    pb="2px"
                    sx={{
                        justifyContent: 'center',
                        width: '170px',
                        margin: '0 auto',
                    }}
                    direction={{ base: 'column', md: 'row' }}>
                    {/* <Button
                        variant="outline"
                        bgColor="white"
                        colorScheme="messenger"
                        minW={['100%', '80px']}
                        onClick={handleClose}>
                        Back
                    </Button> */}
                    <Button minW={['100%', '80px']} onClick={handleCloseModal}>
                        Close
                    </Button>
                </Stack>
            )}
        </Box>
    );
}
