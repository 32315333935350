import {Box, Text} from '@chakra-ui/react';
import {CheckCircleIcon} from '@chakra-ui/icons';
import {useNavigate} from 'react-router-dom';
import Button from 'components/v4/Button';

import walkthroughIds from './walkthroughIds';

export default function TPMSuccess() {
    const navigate = useNavigate();

    const {ufTpmSuccessDoneButton} = walkthroughIds.teamsPhoneMobile;

    const handleSubmitButton = () => {
        navigate('/dashboard-services');
    };

    return (
        <Box>
            <Box className="flex h-tpm flex-col items-center justify-center">
                <Box className="px-8 pb-8">
                    <Box className="w-[610px] mx-auto text-center">
                        <CheckCircleIcon
                            color="#38A169"
                            className="text-center mb-6"
                            w={16}
                            h={16}
                        />
                        <Text className="text-base mb-3">
                            Your TPM Service has been successfully created. You
                            may now leave this wizard.
                        </Text>
                    </Box>

                    <Box className="flex justify-center mt-12">
                        <Button
                            type="submit"
                            onClick={handleSubmitButton}
                            data-walkthroughid={ufTpmSuccessDoneButton}>
                            Done
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
