import { chakraSelectDarkMode, defaultChakraSelectStyle } from '@/constants';
import { AttachmentIcon, InfoIcon } from '@chakra-ui/icons';
import {
    Box,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    Image,
    InputGroup,
    InputLeftElement,
    Skeleton,
    Stack,
    Tooltip,
} from '@chakra-ui/react';
import noImage from 'assets/images/noImage.png';
import { Select } from 'chakra-react-select';
import { FormLabel, Input } from 'components/v4';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import walkthroughIds from '../walkthroughIds';

export default function EditGeneral({
    serviceCategory,
    setServiceCategory,
    isDisabled,
    errorState,
    handleChange,
    handleError,
    serviceCategoryLoading,
}) {
    // image upload
    const inputRef = useRef();

    const options = [
        {
            value: '0',
            label: 'Standard',
        },
        {
            value: '1',
            label: 'Location',
        },
    ];

    const { ufID, ufName, ufDescription, ufImage } =
        walkthroughIds.serviceCategories;

    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);

    return (
        <Box
            sx={{ flexGrow: 1, paddingTop: '1%', paddingBlock: '1%' }}
            className="mx-auto min-w-[36rem] max-w-4xl">
            <Stack direction={['column', 'row']} spacing={'24px'}>
                <FormControl
                    isRequired
                    mb={2}
                    isInvalid={errorState.id.isError}>
                    <FormLabel>ID</FormLabel>
                    {serviceCategoryLoading ? (
                        <Skeleton>
                            {' '}
                            <Input />{' '}
                        </Skeleton>
                    ) : (
                        <Input
                            data-walkthroughid={ufID}
                            value={serviceCategory.id}
                            isDisabled={true}
                            placeholder="Please enter an ID"
                        />
                    )}

                    {errorState.id.isError ? (
                        <FormErrorMessage>
                            {errorState.id.message}
                        </FormErrorMessage>
                    ) : (
                        <FormHelperText>
                            eg. TPM (alphabets and hyphens)
                        </FormHelperText>
                    )}
                </FormControl>

                <FormControl
                    isRequired
                    mb={2}
                    isInvalid={errorState.name.isError}>
                    <FormLabel>Name</FormLabel>
                    {serviceCategoryLoading ? (
                        <Skeleton>
                            {' '}
                            <Input />{' '}
                        </Skeleton>
                    ) : (
                        <Input
                            data-walkthroughid={ufName}
                            value={serviceCategory.name}
                            isDisabled={isDisabled}
                            onChange={(e) => handleChange('name', e)}
                            placeholder="Please enter a Name"
                        />
                    )}
                    {errorState.name.isError ? (
                        <FormErrorMessage>
                            {errorState.name.message}
                        </FormErrorMessage>
                    ) : (
                        <FormHelperText>eg. Teams Phone Mobile</FormHelperText>
                    )}
                </FormControl>
            </Stack>

            <Stack direction={['column', 'row']} spacing={'24px'}>
                {/* Category Type */}
                <FormControl isRequired mb={2}>
                    <FormLabel>
                        Type
                        <Tooltip
                            label={`'Standard' indicates the service's filter option, while 'Location' specifies its grouping.`}
                            hasArrow
                            placement="top-end">
                            <InfoIcon ml={1} />
                        </Tooltip>
                    </FormLabel>
                    <Select
                        value={options?.find(
                            (item) => item.value === serviceCategory.type,
                        )}
                        options={options}
                        placeholder="Please select an option"
                        getOptionLabel={(i) => i.label}
                        onChange={(e) => {
                            setServiceCategory((prevState) => ({
                                ...prevState,
                                type: e.value,
                            }));
                        }}
                        chakraStyles={{
                            ...defaultChakraSelectStyle,
                            ...chakraSelectDark,
                        }}
                        isDisabled={isDisabled}
                        selectedOptionColor="brand"
                    />

                    <FormHelperText>Default: Standard</FormHelperText>
                </FormControl>

                <FormControl
                    isRequired
                    isInvalid={errorState.image.isError}
                    mb={2}>
                    <FormLabel>Image</FormLabel>
                    {serviceCategoryLoading ? (
                        <Skeleton>
                            {' '}
                            <Input />{' '}
                        </Skeleton>
                    ) : (
                        <InputGroup>
                            <InputLeftElement pointerEvents="none">
                                <AttachmentIcon />
                            </InputLeftElement>
                            <input
                                type="file"
                                onChange={(e) => {
                                    if (e.target.files.length > 0) {
                                        if (
                                            !(
                                                e.target.files[0].name
                                                    .split('.')
                                                    .pop() == 'png' ||
                                                e.target.files[0].name
                                                    .split('.')
                                                    .pop() == 'svg'
                                            )
                                        ) {
                                            handleError(
                                                'image',
                                                true,
                                                'Invalid file format.',
                                            );
                                        } else {
                                            // reset error
                                            if (errorState.image.isError)
                                                handleError('image', false, '');
                                            setServiceCategory((prevState) => ({
                                                ...prevState,
                                                image: e.target.files[0],
                                            }));
                                        }
                                    }
                                }}
                                hidden
                                name={'imageUpload'}
                                accept={'.png,.svg'}
                                ref={inputRef}
                            />
                            <Input
                                data-walkthroughid={ufImage}
                                pl="2.5rem"
                                placeholder="Select an image to upload"
                                onClick={(e) => {
                                    inputRef.current.click();
                                }}
                                isDisabled={isDisabled}
                                isReadOnly={true}
                                value={
                                    serviceCategory.image?.name ||
                                    serviceCategory.image?.split('/')[1]
                                }
                            />
                        </InputGroup>
                    )}
                    {errorState.image.isError ? (
                        <FormErrorMessage>
                            {errorState.image.message}
                        </FormErrorMessage>
                    ) : (
                        <FormHelperText>Allowed types .png .svg</FormHelperText>
                    )}
                </FormControl>
            </Stack>

            <FormControl mb={2}>
                <FormLabel>Description</FormLabel>
                {serviceCategoryLoading ? (
                    <Skeleton>
                        {' '}
                        <Input />{' '}
                    </Skeleton>
                ) : (
                    <Input
                        data-walkthroughid={ufDescription}
                        value={serviceCategory.description}
                        isDisabled={isDisabled}
                        onChange={(e) => handleChange('description', e)}
                        placeholder="Please enter a Description"
                    />
                )}
                <FormHelperText>
                    eg. Answer and move calls seamlessly between devices
                </FormHelperText>
            </FormControl>

            <Box display={'flex'} justifyContent={'center'} maxBlockSize={300}>
                {serviceCategoryLoading ? (
                    <Skeleton borderRadius={5} boxSize={300} />
                ) : (
                    <Image
                        fit={'contain'}
                        src={
                            serviceCategory.image instanceof File
                                ? URL.createObjectURL(serviceCategory.image)
                                : process.env.NODE_ENV === 'development'
                                  ? import.meta.env.VITE_APP_IMAGE_BASE +
                                    '/images/' +
                                    serviceCategory.image
                                  : window.location.origin +
                                    '/images/' +
                                    serviceCategory.image
                        }
                        fallbackSrc={noImage}
                    />
                )}
            </Box>
        </Box>
    );
}
