import React, { useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core';

import {
    modalTitle,
    onModalError,
} from '../../../components/tables/TableAndModal';
import IntRates from './rates/Index';
import config from '../../../config.json';
import {
    ModalCopy,
    MultiChild,
    LoadingFieldDropdown,
} from '../../../components';

import walkthroughIds from './walkthroughIds';

/*
 * Component for the base page for the International Rates List Tab from the Sidebar, this also covers the add and edit pages in ModalContent
 */
const InternationalRatesList = () => {
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const tableRef = useRef();

    // parent from table state
    const { parent } = useSelector((state) => {
        return {
            ...state.table,
        };
    });

    // modal information from state
    const {
        data: modalData,
        state: modalState,
        mode: modalMode,
        stateError: modalStateError,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // wholesaler/partners from state
    const { currentWholesaler, currentPartner } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    // scope from state
    const { currentScope, requiredScope } = useSelector((state) => {
        return { ...state.login };
    });

    const { internationalRates } = walkthroughIds;

    // function to determine endpoint to use for the table fetch based on scope
    const tableFetch = () => {
        switch (currentScope) {
            case 80:
                return `/internationalratelists/`;
            case 60:
                return `/internationalratelists/wholesaler/${currentWholesaler}/?withPublic=true`;
            default:
                return `/internationalratelists/partner/${currentPartner}/?withPublic=true`;
        }
    };

    // default columns for the table
    const defaultColumns = [
        {
            title: 'Wholesaler',
            field: 'wholesaler',
            hidden: currentScope !== 80,
            hiddenByColumnsButton: true,
        },
        {
            title: 'Partner',
            field: 'partner',
            hidden: currentScope === 40,
            hiddenByColumnsButton: true,
        },
        {
            title: 'Name',
            field: 'name',
            defaultSort: 'asc',
        },
        {
            title: 'Public',
            field: 'isPublic',
            type: 'boolean',
            width: '5%',
        },
        {
            title: 'Active',
            field: 'isActive',
            type: 'boolean',
            width: '5%',
        },
    ];

    // function to determine if a row can be edited or not
    const canEdit = (rowData) => {
        switch (requiredScope) {
            case 40:
                return rowData?.partnerID === currentPartner;
            case 60:
                return rowData?.wholesalerID === currentWholesaler;
            default:
                return true;
        }
    };

    // return a multichild based on the functions above
    return (
        <MultiChild
            tableRef={tableRef}
            tableFetch={tableFetch()}
            title="International Rate List"
            defaultColumns={defaultColumns}
            add
            modalFetch={'/internationalratelist/' + modalData?.id}
            modalAdd="/internationalratelist/"
            modalUpdate="/internationalratelist/"
            newPage
            tabs={
                !disabled
                    ? [
                          { details: true },
                          {
                              component: <IntRates disabled={disabled} />,
                              label: 'International Rates',
                          },
                      ]
                    : [
                          {
                              component: <IntRates disabled={disabled} />,
                              label: 'International Rates',
                          },
                      ]
            }
            loadingFieldDropdown={{
                onChange: (v) => {
                    const childData = parent.find(
                        (parentValue) => parentValue.id === v,
                    );
                    dispatch({
                        type: 'CHANGE_CHILD_TABLE',
                        payload: childData,
                    });
                    const currentlyDisabled = !canEdit(childData);
                    if (currentlyDisabled) {
                        dispatch({
                            type: 'CHANGE_BREADCRUMB_VIEW',
                            payload: 0,
                        });
                    } else if (!currentlyDisabled && disabled) {
                        dispatch({
                            type: 'CHANGE_BREADCRUMB_VIEW',
                            payload: 1,
                        });
                    }
                    setDisabled(currentlyDisabled);
                },
            }}
            additionalActions={[
                (rowData) => ({
                    icon: () => (
                        <FileCopyRoundedIcon
                            fontSize="small"
                            data-walkthroughid={
                                rowData?.tableData.id === 0
                                    ? internationalRates.ufCopyBtn
                                    : null
                            }
                        />
                    ),
                    tooltip: 'Copy International Rate List',
                    position: 'row',
                    hidden: !canEdit(rowData),
                    onClick: (event, rowData) => {
                        dispatch({
                            type: 'OPEN_MODAL',
                            payload: {
                                mode: 'Copy',
                                data: rowData,
                                state: {
                                    copyName: `Copy of ${rowData?.name}`,
                                },
                            },
                        });
                    },
                }),
                (rowData) => ({
                    icon: () => (
                        <EditRoundedIcon
                            fontSize="small"
                            data-walkthroughid={
                                rowData?.tableData.id === 0
                                    ? internationalRates.ufEditBtn
                                    : null
                            }
                        />
                    ),
                    tooltip: 'Edit International Rate List',
                    position: 'row',
                    hidden: !canEdit(rowData),
                    onClick: (event, rowData) => {
                        const currentlyDisabled = !canEdit(rowData);
                        setDisabled(currentlyDisabled);
                        dispatch({
                            type: 'OPEN_CHILD_TABLE',
                            payload: rowData,
                        });
                        dispatch({
                            type: 'CHANGE_BREADCRUMB_VIEW',
                            payload: 0,
                        });
                    },
                }),
                (rowData) => ({
                    icon: () => <VisibilityRoundedIcon fontSize="small" />,
                    tooltip: 'View International Rate List',
                    position: 'row',
                    hidden: canEdit(rowData),
                    onClick: (event, rowData) => {
                        const currentlyDisabled = !canEdit(rowData);
                        setDisabled(currentlyDisabled);
                        dispatch({
                            type: 'OPEN_CHILD_TABLE',
                            payload: rowData,
                        });
                        dispatch({
                            type: 'CHANGE_BREADCRUMB_VIEW',
                            payload: 0,
                        });
                    },
                }),
            ]}
            onTableSucceed={(data) => {
                return data.map(({ wholesaler, partner, ...rest }) => ({
                    ...rest,
                    wholesaler:
                        wholesaler?.length > 0
                            ? wholesaler
                            : config.notAllocated.table,
                    partner:
                        partner?.length > 0
                            ? partner
                            : config.notAllocated.table,
                }));
            }}
            modalValidate={() => {
                var newErrorState;
                if (modalMode === 'Copy') {
                    newErrorState = {
                        copyName:
                            (!modalState?.copyName ||
                                modalState?.copyName?.length < 1 ||
                                modalState?.copyName?.length > 100) &&
                            'Field is required and maximum field length is 100 characters.',
                    };
                    dispatch({
                        type: 'CHANGE_MODAL_STATE_ERROR',
                        payload: newErrorState,
                    });
                    return !newErrorState.copyName;
                }
                newErrorState = {
                    name:
                        (!modalState?.name ||
                            modalState?.name?.length < 1 ||
                            modalState?.name?.length > 100) &&
                        'Field is required and maximum field length is 100 characters.',
                };
                dispatch({
                    type: 'CHANGE_MODAL_STATE_ERROR',
                    payload: newErrorState,
                });
                return !newErrorState.name;
            }}
            modalTitle={modalTitle(
                modalData,
                'name',
                ' International Rate List ',
                modalMode,
            )}
            modalUpload={(freshData, state) => {
                return {
                    ...freshData,
                    ...state,
                };
            }}
            modalInitState={{
                isActive: true,
                wholesalerID: currentWholesaler,
                partnerID: currentPartner,
            }}
            modalCopy={`/internationalratelist/${modalData?.id}/copy`}
            groupable
            filterable
            maxBodyHeight={window.innerHeight * config.plainView.tableHeight}
            editable={{
                update: true,
            }}
            modalContent={
                modalMode === 'Copy' ? (
                    <ModalCopy
                        modalData={modalData}
                        modalState={modalState}
                        modalStateError={modalStateError}
                        setModalState={(payload) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload,
                            });
                        }}
                    />
                ) : (
                    <div className="mx-auto my-2 max-w-4xl p-6">
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    type="text"
                                    value={modalState?.name ?? ''}
                                    error={Boolean(modalStateError?.name)}
                                    helperText={modalStateError?.name}
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                name: e.target.value,
                                            },
                                        });
                                    }}
                                    data-walkthroughid={
                                        internationalRates.ufNameInput
                                    }
                                />
                            </Col>
                            <Col>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={modalState?.isPublic}
                                            onChange={() =>
                                                dispatch({
                                                    type: 'CHANGE_MODAL_STATE',
                                                    payload: {
                                                        isPublic:
                                                            !modalState?.isPublic,
                                                    },
                                                })
                                            }
                                            data-walkthroughid={
                                                internationalRates.ufPublicCheckbox
                                            }
                                        />
                                    }
                                    label="Public"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={modalState?.isActive}
                                            onChange={() =>
                                                dispatch({
                                                    type: 'CHANGE_MODAL_STATE',
                                                    payload: {
                                                        isActive:
                                                            !modalState?.isActive,
                                                    },
                                                })
                                            }
                                            data-walkthroughid={
                                                internationalRates.ufActiveCheckbox
                                            }
                                        />
                                    }
                                    label="Active"
                                />
                            </Col>
                        </Row>
                        {requiredScope === 80 && (
                            <Row style={{ minHeight: 60 }}>
                                <Col>
                                    <LoadingFieldDropdown
                                        searchable
                                        fieldFetch="/wholesalers/"
                                        fieldName="Wholesaler"
                                        dropDownValue="id"
                                        displayField="name"
                                        onChange={(v) => {
                                            dispatch({
                                                type: 'CHANGE_MODAL_STATE',
                                                payload: {
                                                    wholesalerID: v,
                                                    partnerID: null,
                                                },
                                            });
                                        }}
                                        toggleLimit={4}
                                        fieldValue={modalState?.wholesalerID}
                                        onError={onModalError}
                                        dataWalkthroughid={
                                            internationalRates.ufWholesalerSelect
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                        {requiredScope > 40 && modalState?.wholesalerID && (
                            <Row style={{ minHeight: 60 }}>
                                <Col>
                                    <LoadingFieldDropdown
                                        searchable
                                        fieldFetch={
                                            '/partners/' +
                                            modalState?.wholesalerID
                                        }
                                        fieldName="Partner"
                                        dropDownValue="id"
                                        displayField="name"
                                        onChange={(v) => {
                                            dispatch({
                                                type: 'CHANGE_MODAL_STATE',
                                                payload: {
                                                    partnerID: v,
                                                },
                                            });
                                        }}
                                        dependency={modalState?.wholesalerID}
                                        hasDependency={true}
                                        onError={onModalError}
                                        fieldValue={modalState?.partnerID}
                                        dataWalkthroughid={
                                            internationalRates.ufPartnerSelect
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                    </div>
                )
            }
        />
    );
};

export default InternationalRatesList;
