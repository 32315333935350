export default {
    ufFirstNumberInput: '/number-blocks/input/firstNumber',
    ufBlockSizeSelect: '/number-blocks/select/blockSize',
    ufInboundCarrier: '/number-blocks/select/inboundCarrier',
    ufOutboundCarrier: '/number-blocks/select/outboundCarrier',
    ufPublicPoolCheckbox: '/number-blocks/checkbox/publicPool',
    ufGlobalEntityCheckbox: '/number-blocks/checkbox/globalEntity',
    ufWholesalerSelect: '/number-blocks/select/wholesaler',
    ufPartnerSelect: '/number-blocks/select/partner',
    ufCompanySelect: '/number-blocks/select/company',
    ufCountrySelect: '/number-blocks/input/country',
    ufNumBlockPortingType: '/number-blocks/select/numBlockPortingType',
    ufCustomAttribute: '/number-blocks/input/customAttribute',
    ufNotesComment: '/number-blocks/input/notes',
    ufAddButton: '/number-blocks/button/add',
    ufTabButton: '/number-blocks/tab/',
    ufInputSearch: '/number-blocks/input/number',
    ufSearchButton: '/number-blocks/button/search',
    ufInputClearButton: '/number-blocks/button/input/clear',
    ufClearButton: '/number-blocks/button/clear',

    ufOutboundCallerId: '/number-blocks/select/outboundCallerId',
    ufDeleteButton: '/number-blocks/button/delete',

    ufDeallocateAllButton: '/number-blocks/button/deallocateAll',
    ufDeallocateButton: '/number-blocks/button/deallocate',

    ufSplitBlocksizeButton: '/number-blocks/button/splitBlocksize',
    ufJoinBlocksizeButton: '/number-blocks/button/joinBlocksize',
    ufCloseButton: '/number-blocks/button/close',
    ufBackButton: '/number-blocks/button/back',
    ufSubmitButton: '/number-blocks/button/submit',
};
