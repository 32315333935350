export default function objChangeDetection(serverJson, newServerJson) {
    if (!serverJson.length) {
        return newServerJson.map((item) => ({...item, ...{changes: null}}));
    }

    return newServerJson.map((newObj) => {
        let aId, bId;

        const isSame = serverJson.some((o) => {
            if (newObj.id === o.id) {
                aId = o.id;
                bId = newObj.id;
            }
            return newObj.id === o.id;
        });

        if (isSame) {
            const itemA = serverJson.filter((el) => el.id === aId);
            const itemB = newServerJson.filter((el) => el.id === bId);

            const itemAStr = JSON.stringify(itemA);
            const itemBStr = JSON.stringify(itemB);

            const isModified = itemAStr === itemBStr;

            if (isModified) {
                return {...newObj, ...{changes: null}};
            } else {
                return {...newObj, ...{changes: 'modified'}};
            }
        } else {
            return {...newObj, ...{changes: 'added'}};
        }
    });
}
