/* eslint-disable react-refresh/only-export-components */
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { connect } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { useDeepMerge } from '@/hooks/useDeepMerge';

import ApplyTheme from '@/components/v4/Theme/ApplyTheme';
import { router } from '@tcap/data-router/router';
import { queryClient } from '@tcap/data-router/utils/queryClient';

import '@/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    const { theme, chakratheme } = useDeepMerge();
    return (
        <ChakraProvider theme={theme} resetCSS={false}>
            <ApplyTheme>
                <QueryClientProvider client={queryClient}>
                    <RouterProvider router={router} />
                    <ColorModeScript
                        initialColorMode={chakratheme.config.initialColorMode}
                        theme={chakratheme}
                    />
                    <ReactQueryDevtools
                        initialIsOpen={false}
                        position="bottom-right"
                    />
                </QueryClientProvider>
            </ApplyTheme>
        </ChakraProvider>
    );
}

const MainApp = connect()(App);
export default MainApp;
