import React, { useRef, useState } from 'react';
import { TableAndModal } from '../../../../components';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import config from '../../../../config.json';
import UsersGeneralForm from '../../../accounts/users/edit/General';
import { renderTelephoneNumber } from '../../../../utils/utils';
import { Message } from 'pages/telephony/numbers/edit/Index';
import { Tooltip, Box } from '@mui/material';
import LoadingModal from 'pages/telephony/numbers/edit/LoadingModal';
import { axios } from 'services/axios';
import walkthroughIds from '../walkthroughIds';

/*
 *This component is used for de-allocating the numbers from a user
 *Once this has happened the entire number block can be deleted
 *The number block cannot be deleted until all numbers are deallocated
 */
const DeleteNumberBlocksTable = (props) => {
    const dispatch = useDispatch();
    const tableRef = useRef();

    // state access
    const { modalData, modalState, modalMode } = useSelector((state) => {
        return {
            modalLoading: state.modal.loading,
            modalData: state.modal.data,
            modalState: state.modal.state,
            modalMode: state.modal.mode,
            modalStateError: state.modal.stateError,
            modalUploading: state.modal.uploading,
            modalLoadFields: state.modal.loadFields,
            modalShow: state.modal.show,
        };
    });
    const { state: tableState, loading: tableLoading } = useSelector(
        (state) => {
            return { ...state.table };
        },
    );

    const { requiredScope } = useSelector((state) => {
        return { ...state.login };
    });

    const { child } = useSelector((state) => {
        return { ...state.table };
    });

    const [showLoading, setShowLoading] = useState(false);
    const [actionedErrors, setActionedErrors] = useState(false);
    const buttonCtrRef = useRef(0);

    // return table and modal used for deleted number blocks
    return (
        <>
            <TableAndModal
                tableRef={tableRef}
                onTableSucceed={(data) => {
                    setShowLoading(false);
                    setActionedErrors(false);
                    buttonCtrRef.current = 0;
                    return data.map(({ status, isEnabled, ...rest }) => ({
                        status:
                            !isEnabled && status === 0
                                ? 'Disabled'
                                : status,
                            isEnabled,
                        ...rest,
                    }));
                }}
                tableFetch={'/numbers/' + child?.id}
                modalButtons={
                    modalMode === 'Edit' && (
                        <Button
                            variant="danger"
                            onClick={() => {
                                dispatch({
                                    type: 'ON_MODAL_SUBMIT',
                                    payload: true,
                                });
                            }}
                            data-walkthroughid={
                                walkthroughIds.ufDeallocateButton
                            }>
                            Deallocate
                        </Button>
                    )
                }
                modalTitle={`Deallocate +${modalData?.telephoneNumber} from ${modalState?.displayName}`}
                titleProps={
                    <>
                        {requiredScope >= 40 &&
                            !tableLoading &&
                            tableState?.data.some((a) => a.userID != null) && (
                                <Button
                                    className="btn  btn-sm"
                                    style={{
                                        backgroundColor: '#DC3545',
                                        borderColor: '#DC3545',
                                        color: 'white',
                                    }}
                                    variant="primary"
                                    onClick={() => {
                                        setShowLoading(true);
                                        setActionedErrors(false);

                                        axios
                                            .post(
                                                `/NumberBlock/${child.id}/DeallocateFromUsers`,
                                            )
                                            .then((res) => {
                                                const allData = res.data.map(
                                                    (v) => ({
                                                        ...v,
                                                        user: v.user,
                                                        userID: v.userID,
                                                        status:
                                                            !v.isEnabled &&
                                                            v.status === 0
                                                                ? 'Disabled'
                                                                : v.status,
                                                    }),
                                                );
                                                setTimeout(() => {
                                                    setShowLoading(false);
                                                });
                                                var newTableData = [
                                                    ...tableState.data,
                                                ].map((old) => {
                                                    const newData =
                                                        allData.find(
                                                            (v) =>
                                                                v.id === old.id,
                                                        );
                                                    return newData || old;
                                                });
                                                dispatch({
                                                    type: 'CHANGE_TABLE_STATE',
                                                    payload: {
                                                        ...tableState,
                                                        data: newTableData,
                                                    },
                                                });
                                            })
                                            .catch((e) => {
                                                console.error('Failed!', { e });

                                                setTimeout(() => {
                                                    setActionedErrors(e);
                                                });
                                            });
                                    }}
                                    data-walkthroughid={
                                        walkthroughIds.ufDeallocateAllButton
                                    }>
                                    Deallocate All
                                </Button>
                            )}
                    </>
                }
                overrideOnRowSelected={(e, path, dataClicked) => {
                    const isAdding = e.target.checked;
                    const newVal = isAdding
                        ? buttonCtrRef.current + 1
                        : buttonCtrRef.current - 1;
                    const newPos = newVal > 0 ? 'left' : 'right';
                    buttonCtrRef.current = newVal;
                }}
                modalUpload={(freshData, state) => {
                    return {
                        ...state,
                        numberID: null,
                        planID: null,
                        dialPlanID: null,
                        voicePolicyID: null,
                    };
                }}
                modalUpdate={
                    modalData?.usedOnResourceAccount
                        ? '/resourceaccount/'
                        : '/user/'
                }
                modalFetch={
                    modalData?.usedOnResourceAccount
                        ? `/resourceaccount/${modalData?.userID}`
                        : `/user/${modalData?.userID}`
                }
                additionalActions={[
                    {
                        icon: ({ disabled }) => {
                            return (
                                <Button
                                    className="btn  btn-sm"
                                    style={{ marginBottom: '4px' }}
                                    variant="primary">
                                    Actioned {`(${buttonCtrRef.current})`}
                                </Button>
                            );
                        },
                        tooltip: 'Done',
                        position: 'toolbarOnSelect',
                        onClick: (event, data) => {
                            setShowLoading(true);
                            setActionedErrors(false);

                            const actionedResponses = data.map((v) =>
                                axios.post(`/Number/${v.id}/Actioned`),
                            );
                            Promise.all(actionedResponses)
                                .then((res) => {
                                    const allData = res.map((v) => ({
                                        ...v.data,
                                        status:
                                            !v.data.isEnabled &&
                                            v.data.status === 0
                                                ? 'Disabled'
                                                : config.numbers.statusJSON[
                                                      v.data.status
                                                  ],
                                    }));
                                    setTimeout(() => {
                                        setShowLoading(false);
                                    });
                                    var newTableData = [...tableState.data].map(
                                        (old) => {
                                            const newData = allData.find(
                                                (v) => v.id === old.id,
                                            );
                                            return newData || old;
                                        },
                                    );
                                    buttonCtrRef.current = 0;
                                    dispatch({
                                        type: 'CHANGE_TABLE_STATE',
                                        payload: {
                                            ...tableState,
                                            data: newTableData,
                                        },
                                    });
                                })
                                .catch((e) => {
                                    console.error('Failed!', { e });

                                    setTimeout(() => {
                                        setActionedErrors(e);
                                    });
                                });
                        },
                    },
                    (rowData) => ({
                        icon: ({ disabled }) => {
                            if (disabled) {
                                return;
                            }
                            return (
                                <div
                                    style={{
                                        backgroundColor: '#DC3545',
                                        color: 'white',
                                    }}
                                    className="btn  btn-sm"
                                    data-walkthroughid={
                                        walkthroughIds.ufDeallocateButton
                                    }>
                                    Deallocate
                                </div>
                            );
                        },
                        tooltip: 'Deallocate Number',
                        position: 'row',
                        onClick: (e, rowData) => {
                            dispatch({
                                type: 'OPEN_MODAL',
                                payload: {
                                    mode: 'Edit',
                                    data: rowData,
                                },
                            });
                        },
                        hidden: !rowData?.userID,
                    }),
                ]}
                modalContent={<UsersGeneralForm />}
                title="Number Blocks"
                defaultColumns={React.useMemo(() => {
                    return [
                        {
                            title: 'Telephone Number',
                            field: 'telephoneNumber',
                            render: (rowData) =>
                                renderTelephoneNumber(
                                    rowData,
                                    'telephoneNumber',
                                ),
                        },
                        {
                            title: 'Wholesaler',
                            field: 'wholesaler',
                            hidden: requiredScope < 80,
                        },
                        {
                            title: 'Partner',
                            field: 'partner',
                        },
                        {
                            title: 'Company',
                            field: 'company',
                        },
                        {
                            title: 'User',
                            field: 'user',
                            defaultSort: 'desc',
                        },
                        {
                            title: 'Status',
                            field: 'status',
                            render: (rowData) => (
                                <Box display="flex">
                                    {config.numbers.statusJSON[rowData.status]} &nbsp;
                                    {rowData?.engineMessage &&
                                        rowData?.engineMessage?.length > 0 && (
                                            <Message title={rowData.engineMessage} />
                                        )}
                                </Box>
                            ),
                            lookup: config.numbers.statusJSON,
                        },
                        {
                            title: 'Trunk',
                            field: 'trunk',
                        },
                    ];
                }, [])}
                selectionProps={(rowData) => ({
                    hidden:
                        rowData.status !== 7 ||
                        rowData.overStampNumber,
                    color: 'primary',
                })}
                showSelectAllCheckbox={false}
                selection
                groupable
                filterable
                noTitle
                maxBodyHeight={window.innerHeight * config.tabView.tableHeight}
            />

            <LoadingModal
                show={showLoading}
                error={actionedErrors}
                onHide={() => {
                    if (actionedErrors) {
                        setShowLoading(false);
                        setActionedErrors(false);
                    }
                }}
            />
        </>
    );
};

export default DeleteNumberBlocksTable;
