import { axios } from '../services/axios';

export default async function getCompanyServices(companyid) {
    return await axios
        .get(`/Services/Company/${companyid}`, {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
        })
        .then((response) => response)
        .catch((error) => error);
}
