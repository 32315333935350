import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Service APIs
import createOperatorConnectService from 'API/Service/createOperatorConnectService';
import createDirectRoutingService from 'API/Service/createDirectRoutingService';
import createSbcService from 'API/Service/createSbcService';

// Region API
import getAvailableRegionsFromACompany from '../API/Service/getAvailableRegionsFromACompany';

// Company API
import getCompany from 'API/Company/getCompany';
import getMsLoginLink from 'API/Company/getMsLoginLink';

// Auth API
// import createMicrosoftAppAuth from 'API/AppAuth/createMicrosoftAppAuth';
import { createMicrosoftAppAuth, updateAppAuth } from 'API/AppAuth/';
import getAppAuthByCompanyId from 'API/AppAuth/getAppAuthByCompanyId';
import getAppAuthById from 'API/AppAuth/getAppAuthById';
import getTrunkTypesServiceCompany from 'API/Service/getTrunkTypesServiceCompany';
import { useToast } from '@chakra-ui/react';
import { axios } from '@/services/axios';
import { useDashboardMenu } from 'context/DashboardMenuContext';

export default function useServiceWizard() {
    const navigate = useNavigate();
    const toast = useToast();
    // Wizard Flow
    const [flow, setFlow] = useState('Start'); // Start | CreateService | SelectiveSyncUsers | SelectiveSyncUsersEdit | MsAuth

    const [selectedServiceProfile, setSelectedServiceProfile] = useState(null);
    const [serviceCreationResponse, setServiceCreationResponse] =
        useState(null);

    // Selective Sync
    const [filterGroup, setFilterGroup] = useState(null);
    const [filterLocations, setFilterLocations] = useState(null);
    const [selectiveSyncEditData, setSelectiveSyncEditData] = useState(null);

    // State
    const [company, setCompany] = useState();
    const [companyAppAuth, setCompanyAppAuth] = useState([]);
    const [msServiceAuth, setMsServiceAuth] = useState([]);

    const [isCreatingService, setIsCreatingService] = useState(false);
    const [isCreatingAuth, setIsCreatingAuth] = useState(false);

    // Errors
    const [hasCreateServiceError, setHasCreateServiceError] = useState(null);

    // Other
    const currentCompany = useSelector(
        (state) => state.navigationLists.currentCompany,
    );

    const { requiredScope: accountScope } = useSelector((state) => state.login);
    const { restError: errorMessage } = useSelector((state) => ({
        ...state.modal,
    }));

    const { setIsQueryDisabled } = useDashboardMenu();

    function reset() {
        setFlow('Start');
        setSelectedServiceProfile(null);
        setFilterGroup('');
        setFilterLocations(null);
        setIsCreatingService(false);
        setIsCreatingAuth(false);
        setHasCreateServiceError(null);
        setSelectiveSyncEditData(null);
        setIsQueryDisabled(false);
    }

    function updateLocations(multiselectValues) {
        let items = [];
        multiselectValues.map((multiSelect) => {
            items.push(multiSelect.value);
        });
        setFilterLocations(items);
    }

    async function microsoftAuth() {
        const redirectUrl = await getMsLoginLink(currentCompany);
        window.location.href = redirectUrl.data;
    }

    async function createMicrosoftAuth(selectiveSync = false) {
        setIsCreatingAuth(true);
        await createMicrosoftAppAuth(
            {
                filterGroup,
                filterLocations,
                companyID: currentCompany,
                selectiveSync: selectiveSync,
            },
            accountScope,
        )
            .then((response) => response)
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setIsCreatingAuth(false));
    }

    /**
     * disables your selective sync
     */
    async function onSelectiveSyncDisable() {
        await updateAppAuth(
            {
                id: msServiceAuth.id,
                tenantID: msServiceAuth.tenantID,
                filterGroup: null,
                filterLocations: null,
                companyID: currentCompany,
                selectiveSync: false,
                userSync: msServiceAuth.userSync, //TODO: check
            },
            accountScope,
        )
            .then((response) => {
                setFlow('Start');
                toast({
                    title: 'Selective Sync Updated',
                    description: 'Selective Sync has been updated.',
                    status: 'success',
                    duration: 3000,
                });
            })
            .catch((error) => {
                console.error(error);
                toast({
                    title: 'Error',
                    description:
                        errorMessage ||
                        'There was an error updating selective sync.',
                    status: 'error',
                    duration: 3000,
                });
            });
    }

    // This is called when clicking no to selective sync
    async function onSelectiveSyncDeny() {
        const { serviceType } = selectedServiceProfile;

        if (serviceType === 'DirectRouting') {
            await microsoftAuth();
        } else if (serviceType === 'OperatorConnect') {
            setFlow('Start');
        }
    }

    // This is called when clicking next in the selective sync form
    async function onSelectiveSync() {
        await createMicrosoftAuth(true).then(async () => {
            await microsoftAuth();
        });
    }

    // This is called when editing selective sync
    async function onSelectiveSyncEdit() {
        let items = [];
        filterLocations.map((item) => {
            items.push(item.value);
        });
        await updateAppAuth(
            {
                id: msServiceAuth.id,
                tenantID: msServiceAuth.tenantID,
                filterGroup,
                filterLocations: items,
                companyID: currentCompany,
                selectiveSync: true,
                userSync: true,
            },
            accountScope,
        )
            .then((response) => {
                setFlow('Start');
                toast({
                    title: 'Selective Sync Updated',
                    description: 'Selective Sync has been updated.',
                    status: 'success',
                    duration: 3000,
                });
            })
            .catch((error) => {
                console.error(err);
                toast({
                    title: 'Error',
                    description:
                        errorMessage ||
                        'There was an error updating selective sync.',
                    status: 'error',
                    duration: 3000,
                });
            });
    }

    async function ocFlow(serviceResponse) {
        // Flags
        let syncDisabled, syncEnabled, syncForced;
        let authDisabled, authEnabled, authForced;
        let userSync = false;
        let graphAuth = false;
        let serviceAuth;

        // Set all sync policy manually if were calling this function directly
        // Otherwise, grab the values from the service create response

        if (!serviceResponse) {
            const company = await getCompany(currentCompany);
            const { authPolicy } = selectedServiceProfile;
            const { selectiveSyncPolicy } = company.data;
            authDisabled = authPolicy === 0;
            authEnabled = authPolicy === 1;
            authForced = authPolicy === 2;
            syncDisabled = selectiveSyncPolicy === 0;
            syncEnabled = selectiveSyncPolicy === 1;
            syncForced = selectiveSyncPolicy === 2;
        } else {
            authDisabled = serviceResponse.authPolicy === 0;
            authEnabled = serviceResponse.authPolicy === 1;
            authForced = serviceResponse.authPolicy === 2;
            syncDisabled = serviceResponse.company.selectiveSyncPolicy === 0;
            syncEnabled = serviceResponse.companyselectiveSyncPolicy === 1;
            syncForced = serviceResponse.company.selectiveSyncPolicy === 2;
        }

        // Get the auth status
        const companyAppAuths = await getAppAuthByCompanyId(currentCompany);
        const companyAppAuthsContainThisService = companyAppAuths.data.find(
            (appAuth) => {
                return serviceResponse
                    ? serviceResponse.appAuthType === appAuth.appAuthTypeID
                    : companyAppAuths.data.appAuthType ===
                          appAuth.appAuthTypeID;
            },
        );

        if (companyAppAuthsContainThisService?.id) {
            serviceAuth = await getAppAuthById(
                companyAppAuthsContainThisService.id,
            );
            if (serviceAuth.data?.userSync) {
                userSync = true;
            }
            if (serviceAuth.data?.graphAuth) {
                graphAuth = true;
            }
        }
        // console.log('193 going to if branch', {
        //     syncDisabled,
        //     syncEnabled,
        //     syncForced,
        //     authDisabled,
        //     authEnabled,
        //     authForced,
        //     userSync,
        //     graphAuth,
        //     serviceAuth,
        //     companyAppAuthsContainThisService,
        //     companyAppAuths,
        // });

        // #1
        if (
            (authEnabled || authForced) &&
            (syncEnabled || syncForced) &&
            serviceAuth?.data?.status === 20 && //Error
            !graphAuth &&
            !userSync
        ) {
            setFlow('SelectiveSyncUsers');
        }
        // #2
        else if (
            (authEnabled || authForced) &&
            (syncEnabled || syncForced) &&
            serviceAuth?.data?.status === 2 && //Pending Access
            graphAuth &&
            userSync
        ) {
            setFlow('Start');
        }
        // #3
        else if (
            (authEnabled || authForced) &&
            (syncEnabled || syncForced) &&
            serviceAuth?.data?.status === 2 &&
            !graphAuth &&
            !userSync
        ) {
            //TODO:
            setFlow('SelectiveSyncUsers');
        }
        // #4
        else if (
            authForced &&
            syncDisabled &&
            serviceAuth?.data?.status === 2 &&
            graphAuth &&
            userSync
        ) {
            await microsoftAuth();
        }
        // #5
        else if (
            authEnabled &&
            syncDisabled &&
            serviceAuth?.data?.status === 2
        ) {
            await microsoftAuth();
        }
        // #6
        else if (authDisabled && syncDisabled) {
            setFlow('Start');
        }
        // #7
        else if (authDisabled && syncEnabled) {
            setFlow('Start');
        }
        // #8
        else if (authEnabled && syncForced && !serviceAuth) {
            setFlow('SelectiveSyncUsers');
        }
        // #9
        else if (authEnabled && syncEnabled && !serviceAuth) {
            setFlow('SelectiveSyncUsers');
        }
        // #10
        else if (authForced && syncDisabled && !serviceAuth) {
            await microsoftAuth();
        }
        // #11
        else if (authForced && syncEnabled && !serviceAuth) {
            setFlow('SelectiveSyncUsers');
        }
        // #12
        else if (authForced && syncForced && !serviceAuth) {
            setFlow('SelectiveSyncUsers');
        }
        // #13
        else if (authEnabled && syncDisabled && !serviceAuth) {
            await microsoftAuth();
        } else {
            navigate('/reload/dashboard-services');
        }
    }

    async function drFlow(serviceResponse) {
        // Flags
        let syncDisabled, syncEnabled, syncForced;
        let userSync,
            graphAuth = false;
        let serviceAuth;

        // Set all sync policy manually if were calling this function directly
        // Otherwise, grab the values from the service create response
        if (!serviceResponse) {
            const company = await getCompany(currentCompany);
            const { selectiveSyncPolicy } = company.data;
            syncDisabled = selectiveSyncPolicy === 0;
            syncEnabled = selectiveSyncPolicy === 1;
            syncForced = selectiveSyncPolicy === 2;
        } else {
            syncDisabled = serviceResponse.company.selectiveSyncPolicy === 0;
            syncEnabled = serviceResponse.company.selectiveSyncPolicy === 1;
            syncForced = serviceResponse.company.selectiveSyncPolicy === 2;
        }

        // Get the auth status
        const companyAppAuths = await getAppAuthByCompanyId(currentCompany);

        const companyAppAuthsContainThisService = companyAppAuths.data.find(
            (appAuth) => {
                return serviceResponse
                    ? serviceResponse.appAuthType === appAuth.appAuthTypeID
                    : companyAppAuths.data.appAuthType ===
                          appAuth.appAuthTypeID;
            },
        );

        if (companyAppAuthsContainThisService?.id) {
            serviceAuth = await getAppAuthById(
                companyAppAuthsContainThisService.id,
            );
            if (serviceAuth.data?.userSync) {
                userSync = true;
            }
            if (serviceAuth.data?.graphAuth) {
                graphAuth = true;
            }
        }

        // DR flow
        // #1
        if ((syncEnabled || syncForced) && serviceAuth?.data?.status === 20) {
            setFlow('SelectiveSyncUsers');
        }
        // #2
        else if (
            (syncEnabled || syncForced) &&
            serviceAuth?.data?.status === 0 &&
            graphAuth &&
            userSync
        ) {
            setFlow('Start');
        }
        // #3
        else if (
            (syncEnabled || syncForced) &&
            serviceAuth?.data?.status === 0 &&
            !graphAuth &&
            !userSync
        ) {
            setFlow('SelectiveSyncUsers');
        }
        // #4
        else if (
            syncDisabled &&
            serviceAuth?.data?.status === 0 &&
            graphAuth &&
            userSync
        ) {
            setFlow('Start');
        }
        // #6 (NOT IN EXCEL)
        else if ((syncEnabled || syncForced) && !serviceAuth) {
            setFlow('SelectiveSyncUsers');
        }
        // #5 (NOT IN EXCEL)
        else if (!serviceAuth) {
            await microsoftAuth();
        } else {
            navigate('/dashboard-services');
        }
    }

    async function onServiceCreate(serviceResponse) {
        setServiceCreationResponse(serviceResponse);

        // 3. If SBC service, finish now
        if (
            serviceResponse.serviceType === 'SBCaaS' ||
            serviceResponse.serviceType === 'OperatorConnectConferencing'
        ) {
            setFlow('Start');
            setIsQueryDisabled(false);
        }

        // 4. Direct Routing
        else if (serviceResponse.serviceType === 'DirectRouting') {
            drFlow(serviceResponse);
        }

        // 5. Operator Connect
        else if (serviceResponse.serviceType === 'OperatorConnect') {
            ocFlow(serviceResponse);
        }
    }

    async function createService(serviceProfile) {
        setSelectedServiceProfile(serviceProfile);
        setIsCreatingService(true);
        setHasCreateServiceError(null);

        switch (serviceProfile.serviceType) {
            case 'DirectRouting':
                createDirectRoutingService(currentCompany).then((response) => {
                    if (response?.response?.data?.message) {
                        setHasCreateServiceError(
                            response.response.data.message,
                        );
                    } else {
                        onServiceCreate(response.data);
                    }
                });
                break;
            case 'DirectRouting-':
                onServiceCreate({
                    serviceType: 'DirectRouting',
                    authPolicy: 2,
                    company: { selectiveSyncPolicy: 2 },
                    appAuthType: 'Microsoft',
                });
                setServiceCreationResponse({
                    serviceType: 'DirectRouting',
                    authPolicy: 2,
                    company: { selectiveSyncPolicy: 2 },
                    appAuthType: 'Microsoft',
                });
                break;
            case 'OperatorConnect':
                // Pre-check before creating an OC service
                const { selectiveSyncPolicy } = company;
                const { authPolicy } = serviceProfile;

                if (
                    authPolicy === 0 &&
                    (selectiveSyncPolicy === 1 || selectiveSyncPolicy === 2)
                ) {
                    setIsCreatingService(false);
                    setHasCreateServiceError(
                        'Selective Sync is enabled for this Company, however the Service Profile for Operator Connect does not allow Auth.',
                    );
                    return;
                }

                createOperatorConnectService(currentCompany).then(
                    (response) => {
                        if (response?.response?.data?.message) {
                            setHasCreateServiceError(
                                response.response.data.message,
                            );
                        } else {
                            onServiceCreate(response.data);
                        }
                    },
                );
                break;
            case 'OperatorConnect--':
                onServiceCreate({
                    authPolicy: 1,
                    company: { selectiveSyncPolicy: 1 },
                    appAuthType: 'Microsoft',
                    serviceType: 'OperatorConnect',
                });
                setServiceCreationResponse({
                    authPolicy: 1,
                    company: { selectiveSyncPolicy: 1 },
                    appAuthType: 'Microsoft',
                    serviceType: 'OperatorConnect',
                });
                break;
            case 'SBCaaS':
                Promise.all([
                    getAvailableRegionsFromACompany(currentCompany),
                    getTrunkTypesServiceCompany(currentCompany),
                ]).then(([regionsResult, trunkTypesResult]) => {
                        createSbcService(currentCompany, regionsResult.data, trunkTypesResult.data).then(
                            (response) => {
                                if(response?.response?.data.message) {
                                    setHasCreateServiceError(response.response.data.message);
                                } else {
                                    onServiceCreate(response.data)
                                }
                            }
                        )
                });

                break;
            case 'OperatorConnectConferencing':
                axios
                    .post(
                        `/Service/OperatorConnectConferencing/Magic/${currentCompany}`,
                        {
                            name: 'Operator Connect Conferencing',
                            currentCompany,
                        },
                    )
                    .then((res) => {
                        if (res?.response?.data?.message) {
                            setHasCreateServiceError(res.response.data.message);
                        } else {
                            onServiceCreate(res.data);
                        }
                    })
                    .catch((e) => {
                        setHasCreateServiceError(e.response.data.message);
                    });
                break;
        }
    }

    return {
        flow,
        setFlow,
        createService,
        onSelectiveSync,
        onSelectiveSyncEdit,
        onSelectiveSyncDeny,
        setIsCreatingService,
        isCreatingService,

        // Selective Sync
        filterGroup,
        setFilterGroup,
        filterLocations,
        setFilterLocations,
        updateLocations,

        // Service profile
        setSelectedServiceProfile,
        selectedServiceProfile,

        // Server response after creating a service
        serviceCreationResponse,

        // Auth check
        microsoftAuth,
        createMicrosoftAuth,

        // State
        isCreatingService,
        isCreatingAuth,
        hasCreateServiceError,
        reset,
        msServiceAuth,
        setMsServiceAuth,
        selectiveSyncEditData,
        setSelectiveSyncEditData,
        companyAppAuth,
        setCompanyAppAuth,
        drFlow,
        ocFlow,
        company,
        setCompany,
        onSelectiveSyncDisable,
        setHasCreateServiceError,
    };
}
