import { redirect } from 'react-router-dom';
import authenticatedRoutes from '@/config/authenticatedRoutes';
import { isLoggedIn } from '@tcap/data-router/utils/helpers';

const NOT_AVAILABLE = ['services'];

export async function requireAuth(request) {
    const pathname = new URL(request.url).pathname;
    const tcapPathname = pathname.slice(3);
    // NOTE: shorten to parent path if want to visit child path, as need to work on logic of scope, back button
    const paths = tcapPathname.split('/');
    const _tcapPathname = `/${paths[1]}`;

    const authenticatedPaths = [...authenticatedRoutes()].some((route) => {
        const isMatch =
            tcapPathname?.split('/')[1] === route.path?.split('/')[1] &&
            !NOT_AVAILABLE.some((na) => paths[1]?.startsWith(na));
        return isMatch;
    });

    if (!isLoggedIn() && authenticatedPaths) {
        throw redirect(
            `/?message=You must login first.&redirectTo=${encodeURIComponent(
                _tcapPathname,
            )}`,
        );
    }

    return null;
}
