import { useRef, useEffect } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useDispatch } from 'react-redux';

import { loginRequest } from 'pages/auth/new/sso/config/authConfig';
import { useNavigateLocation } from 'hooks/useNavigateLocation/useNavigateLocation';
import { classNames } from 'utils/classNames';
import { useAuth } from '@sso/context/authContext';

import walkthroughId from '@/pages/auth/new/walkthroughId';

export function MicrosoftButton() {
    const buttonRef = useRef(null);
    const { instance } = useMsal();
    const { setErrorMessage } = useAuth();

    const handleLogin = async (loginType) => {
        if (loginType === 'popup') {
            setErrorMessage('');
            const requestHeaders = new Headers();
            try {
                let redirectUri;
                // localhost is not recognize, it gets overriden later
                const reference =
                    import.meta.env.MODE === 'staging'
                        ? 'launch.tcap.dev'
                        : process.env.NODE_ENV === 'development'
                          ? 'teamsdev.tcap.dev'
                          : window.location.host;

                const response = await fetch(
                    `${import.meta.env.VITE_APP_API_URL}/domain`,
                    {
                        method: 'POST',
                        headers: {
                            ...requestHeaders,
                            ...{
                                'Content-Type': 'application/json',
                                'X-RequestScope': 20,
                            },
                        },
                        mode: 'cors',
                        credentials: 'omit',
                        body: JSON.stringify(reference),
                    },
                );

                if (!response.ok) {
                    const errorData = await response.json();
                    setErrorMessage(errorData?.message);
                    return;
                }

                const result = await response.json();
                const decodedReference = window.atob(result).split('|');

                const referenceID = decodedReference[0];

                if (process.env.NODE_ENV === 'development') {
                    redirectUri = `${window.location.origin}/ui/sso/ms/redirect`;
                } else if (window.location.host == 'teamsdev.tcap.dev') {
                    redirectUri = `https://${window.location.host}/ui/sso/ms/redirect`;
                } else {
                    redirectUri = `https://${decodedReference[1]}/ui/sso/ms/redirect`;
                }

                instance
                    .loginPopup({
                        ...loginRequest,
                        state: referenceID,
                        redirectUri: redirectUri,
                    })
                    .catch((e) => {
                        console.log(e);
                        if (
                            e?.message.includes(
                                'Interaction is currently in progress',
                            )
                        ) {
                            setErrorMessage(
                                "There's another interactive popup window in progress!",
                            );
                        } else if (
                            e?.message.includes('User cancelled the flow')
                        ) {
                            setErrorMessage('');
                        }
                    });
            } catch (e) {
                setErrorMessage('Unable to retrieve domain reference for SSO.');
            }
        }
    };

    // NOTE: only allow popup sign-in for now
    const onClick = () => handleLogin('popup');

    useEffect(() => {
        if (buttonRef.current) {
            buttonRef.current?.focus();
        }
    }, [buttonRef]);
    return (
        <button
            ref={buttonRef}
            type="button"
            onClick={onClick}
            className="relative inline-flex w-full items-center justify-center gap-x-2 rounded-md border !border-gray-300 bg-[#FFF] px-4 py-2.5 text-sm font-semibold text-black shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-blue-50"
            data-walkthroughid={walkthroughId.ufSignInMsButton}
        >
            <span
                className="windows inline-block h-5 w-5 bg-contain bg-[50%] bg-no-repeat text-sm font-semibold leading-6"
                data-provider="windowslive"
            />
            <span className="pl-8">Sign in with Microsoft</span>
        </button>
    );
}

export function MicrosoftSignOutButton() {
    const { instance } = useMsal();
    const dispatch = useDispatch();
    const { formState, setErrorMessage } = useAuth();

    const handleLogout = (logoutType) => {
        setErrorMessage(null);
        if (logoutType === 'popup') {
            dispatch({ type: 'CLEAR_TOKEN' });
            dispatch({ type: 'LOGOUT' });
            instance.logoutPopup({
                postLogoutRedirectUri: '/',
                mainWindowRedirectUri: '/',
            });
            localStorage.clear();
            sessionStorage.clear();
        }
    };

    const onClick = () => handleLogout('popup');
    return (
        <button
            type="button"
            onClick={onClick}
            disabled={formState == 'pending'}
            className="tcap-sign-button-center"
        >
            <span className="text-center">Sign out</span>
        </button>
    );
}

function SsoButtons(props) {
    const { handleBackBtn } = props;
    return (
        <>
            {/* NOTE: more button options, e.g: Facebook, Google*/}
            <MicrosoftButton />
            <button
                onClick={handleBackBtn}
                className={classNames(
                    'uppercase hover:border-blue-600 hover:text-blue-600',
                    'tcap-sign-button-center',
                )}
            >
                Back
            </button>
        </>
    );
}

function TwButtons() {
    const isAuthenticated = useIsAuthenticated();
    const { setErrorMessage } = useAuth();
    const { handleNavigateLocation: navigate } = useNavigateLocation();
    const handleBackBtn = () => {
        setErrorMessage('');
        sessionStorage.clear();
        navigate('/');
    };
    return (
        <div className={'mt-2 grid grid-cols-1 gap-4'}>
            {isAuthenticated ? (
                <>
                    <button
                        onClick={handleBackBtn}
                        className={classNames(
                            'uppercase hover:border-blue-600 hover:text-blue-600',
                            'tcap-sign-button-center',
                        )}
                    >
                        Back
                    </button>
                </>
            ) : (
                <SsoButtons handleBackBtn={handleBackBtn} />
            )}
        </div>
    );
}

export { TwButtons };
