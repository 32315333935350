import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Table from './edit/Index';
import RequestBlock from './requestBlock/Index';
import ExistingNumbers from './existingNumbers/Index';
import { TabView } from '../../../components';
import config from '../../../config.json';

/**
 * Component for the Number Tab of TCAP
 * Handles CRUD for this asepct of the app
 */
const Numbers = () => {
    const dispatch = useDispatch();

    // state access
    const { tab } = useSelector((state) => {
        return { ...state.view };
    });

    // use effect to change tab view using redux
    useEffect(() => {
        if (Number.isInteger(tab)) {
            dispatch({
                type: 'CHANGE_TAB_VIEW',
                payload: {
                    level1: 0,
                    level2: 0,
                },
            });
        }
    }, [tab]);

    // Returns the Tab view of the numbers page
    return (
        <>
            <h1 className="mb-2 text-[2.5rem] font-medium">Numbers</h1>
            <TabView
                onChange={(e, v) => {
                    dispatch({
                        type: 'CHANGE_TAB_VIEW',
                        payload: {
                            level1: v,
                            level2: 0,
                        },
                    });
                }}
                value={tab['level1']}
                tabLabels={config.numbers.companies.tabs}
                contents={[<Table />, <RequestBlock />, <ExistingNumbers />]}
            />
        </>
    );
};

export default Numbers;
