import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';

import { ChakraProvider, LightMode } from '@chakra-ui/react';

import { useAccessList } from '@tcap/data-router/hooks/useAccessList';

import { LoadingScreen } from '@/lib/loading-screen';
import TcapPageBackgroundLoader from '@/components/v4/partners/TcapPageBackgroundLoader';

import chakratheme from '@/chakratheme';
// login page
export default function AuthLayout() {
    const { loadAccessList, token, reLogin, showMFA } = useSelector(
        (state) => state.login,
    );
    const { getAccessList } = useAccessList();
    const [searchParams] = useSearchParams();
    let redirectTo =
        new URLSearchParams(searchParams).get('redirectTo') ||
        '/dashboard-services';
    /**
     * After logging in, this flag kicks off the data fetching process
     * required for TCAP.
     */
    useEffect(() => {
        //load access list after a succesful login/ relogin.
        if (loadAccessList) {
            getAccessList();
        }
    }, [loadAccessList, getAccessList]);

    if (loadAccessList) {
        return (
            <ChakraProvider theme={chakratheme} resetCSS={true}>
                <LoadingScreen />
            </ChakraProvider>
        );
    }

    if (!token || reLogin || showMFA) {
        window.sessionStorage.clear();
        return (
            <ChakraProvider theme={chakratheme} resetCSS={true}>
                <TcapPageBackgroundLoader
                    className="auth-page-"
                    sx={{
                        minHeight: '100vh',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <LightMode>
                        <Outlet context={{ token, loadAccessList }} />
                    </LightMode>
                </TcapPageBackgroundLoader>
            </ChakraProvider>
        );
    }

    return <Navigate to={redirectTo} replace />;
}
