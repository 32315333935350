import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import { Tooltip, Typography, Box } from '@mui/material';
import Info from '@mui/icons-material/Info';

import config from '../../../../config.json';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { axios } from '@/services/axios';
import tableIcons from 'utils/MaterialTableIcons';

/*
 * component for the status icons that just have a different title
 */
const StatusIcon = ({ trunkValid, title }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}>
            <Tooltip title={!trunkValid ? 'Incomplete Trunk' : title}>
                <FiberManualRecordRoundedIcon
                    style={{ color: '#c1c92a', fontSize: 'small' }}
                />
            </Tooltip>
            <Typography sx={{ paddingLeft: 2 }}>
                {!trunkValid ? 'Incomplete Trunk' : title}
            </Typography>
        </Box>
    );
};

// shows a 'Information' Icon that shows the title/message when you hover over it
const Message = ({ title, darkMode }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
            }}>
            {(title !== '' || title !== undefined) && (
                <Tooltip title={title}>
                    <Info
                        style={{
                            color: darkMode
                                ? 'rgba(255, 255, 255, 0.7)'
                                : 'black',
                        }}
                    />
                </Tooltip>
            )}
        </Box>
    );
};

// Component to display list and handle crud for SBCs
const EditSipSbc = ({ trunkValid, trunk, setTrunk }) => {
    const muiTheme = useMemo(
        () =>
            createTheme({
                head: {
                    backgroundColor: null,
                },
                overrides: {
                    MuiToolbar: {
                        root: {
                            color: 'rgba(255, 255, 255, 0.7)',
                            backgroundColor: '#212121',
                        },
                    },
                    MuiTable: {
                        root: {
                            WebkitTextFillColor:
                                'rgba(255, 255, 255, 0.7) !important',
                            backgroundColor: '#212121 !important',
                        },
                    },
                    MuiTableHead: {
                        root: {
                            backgroundColor: '#212121 !important',
                        },
                    },
                    MuiTablePagination: {
                        root: {
                            backgroundColor: 'white',
                        },
                    },
                    MuiPaper: {
                        root: {
                            backgroundColor: null,
                        },
                    },
                    MuiIconButton: {
                        label: {
                            color: 'rgba(255, 255, 255, 0.3)',
                        },
                    },
                },
            }),
        [],
    );
    const tableRef = useRef(MaterialTable);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    const { darkMode } = useSelector((state) => state.settings);

    const sbcDomain = useSelector((state) => state.login.sbcDomain);

    const dispatch = useDispatch();
    // state access
    const { state } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // default columns for the material table and how they are rendered and the field that they draw from in the DB
    const columns = [
        { title: '#', field: 'priority', width: '5%', sorting: false },
        { title: 'SBC FQDN', field: 'fqdnName', sorting: false },
        { title: 'Public IP', field: 'publicIPAddress', sorting: false },
        {
            title: 'Status',
            field: 'status',
            sorting: false,
            lookupDescription: {
                0: !trunkValid ? 'Incomplete Trunk' : 'Okay',
                20: !trunkValid ? 'Incomplete Trunk' : 'Error',
                ...config.trunks.sbcList.statusJSON,
            },
            lookup: {
                0: (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}>
                        <Tooltip
                            title={!trunkValid ? 'Incomplete Trunk' : 'Okay'}>
                            <FiberManualRecordRoundedIcon
                                style={{ color: 'green', fontSize: 'small' }}
                            />
                        </Tooltip>
                        <Typography sx={{ paddingLeft: 2 }}>
                            {!trunkValid ? 'Incomplete Trunk' : 'Okay'}
                        </Typography>
                    </Box>
                ),
                20: (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}>
                        <Tooltip
                            title={!trunkValid ? 'Incomplete Trunk' : 'Error'}>
                            <FiberManualRecordRoundedIcon
                                style={{ color: 'red', fontSize: 'small' }}
                            />
                        </Tooltip>
                        <Typography sx={{ paddingLeft: 2 }}>
                            {!trunkValid ? 'Incomplete Trunk' : 'Error'}
                        </Typography>
                    </Box>
                ),
                21: <StatusIcon trunkValid={trunkValid} title="Purging" />,
                41: <StatusIcon trunkValid={trunkValid} title="Adding" />,
                42: <StatusIcon trunkValid={trunkValid} title="Restoring" />,
                44: <StatusIcon trunkValid={trunkValid} title="Updating" />,
                45: <StatusIcon trunkValid={trunkValid} title="Draining" />,
                46: <StatusIcon trunkValid={trunkValid} title="Drained" />,
                49: <StatusIcon trunkValid={trunkValid} title="Deleting" />,
            },
        },
        {
            title: 'Last Message',
            field: 'statusMessage',
            sorting: false,
            width: '20%',
            render: (rowData) => (
                <Message title={rowData.statusMessage} darkMode={darkMode} />
            ),
        },
    ];

    /*
     * Logic to set the rows of the table based on the state
     * If there is a detailed list in the state and its not loading ->
     * for each in the detailed list, pull of its data and append it to result
     * Set the tables rows to this result
     * Do this whenever the state's debtailed list changes
     */
    useEffect(() => {
        if (state?.sbcListDetailed && loading === false) {
            let result = [];
            state.sbcListDetailed.forEach((v) => {
                const temp = {
                    fqdnName: v.fqdnName,
                    id: v.id,
                    status: v.status,
                    priority: v.priority,
                    publicIPAddress: v.publicIPAddress,
                    statusMessage: v.statusMessage,
                };
                result.push(temp);
            });
            setRows([...result]);
        }
    }, [state?.sbcListDetailed, loading]);

    /*
     * Logic when you click the up arrow in the SBC List(rowData is the clicked row)
     * create variables for the reordering of the list, values above, values lower than, and the value to go up/down
     * oldData is the state of the current table -> for each row of the old table ->
     * If that row's id = the passed in rowData.id then it needs to be moved.
     * create array of things above, things below and the two values to be swapped
     * create new table state by combining these arrays and setting state to the new array
     */
    const moveUpHandler = (rowData) => {
        const oldData = tableRef.current.state.data;
        let valuesUnder = [];
        let valuesOver = [];
        let valueDown;
        let valueUp;
        let result = [];
        oldData.forEach((row) => {
            if (row.tableData.id > rowData.tableData.id) {
                valuesOver.push(row);
            } else if (row.tableData.id === rowData.tableData.id - 1) {
                valueDown = row;
            } else if (row.tableData.id === rowData.tableData.id) {
                valueUp = row;
            } else {
                valuesUnder.push(row);
            }
        });
        result = [...valuesUnder, valueUp, valueDown, ...valuesOver];
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                sbcListDetailed: result,
            },
        });
    };

    /*
     * Same logic as the move up handler ->
     * move down instead so the else if logic is slightly differnet
     */
    const moveDownHandler = (rowData) => {
        const oldData = tableRef.current.state.data;
        let valuesUnder = [];
        let valuesOver = [];
        let valueDown;
        let valueUp;
        let result = [];
        oldData.forEach((row) => {
            if (row.tableData.id < rowData.tableData.id) {
                valuesUnder.push(row);
            } else if (row.tableData.id === rowData.tableData.id) {
                valueDown = row;
            } else if (row.tableData.id === rowData.tableData.id + 1) {
                valueUp = row;
            } else {
                valuesOver.push(row);
            }
        });
        result = [...valuesUnder, valueUp, valueDown, ...valuesOver];
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                sbcListDetailed: result,
            },
        });
    };

    /*
     * Gets data from the API when the compoent first renders and sets it to state/sets the rows
     * If the mode is Edit -> get the data from the endpoint using the state.id
     * For each value in the result -> create a header used for display(FQDN name is a series of fields concatenated) ->
     * Create a temp with the object and push it to the result variable
     * Set this result array to the state/rows
     */
    useEffect(() => {
        setLoading(true);
        axios.get('/trunk/' + state?.id).then((res) => {
            let result = [];
            res.data.sbcListDetailed.forEach((value) => {
                const header = state?.sipHeader.split('.');
                const temp = {
                    fqdnName:
                        header[0] +
                        '.' +
                        value.item.locationID.toLowerCase() +
                        '.' +
                        sbcDomain,
                    id: value.item.id,
                    status: value.item.status,
                    statusMessage: value.item.statusMessage,
                    priority: value.priority,
                    publicIPAddress: value.item.publicIPAddress,
                };

                result.push(temp);
            });
            dispatch({
                type: 'CHANGE_MODAL_STATE',
                payload: {
                    sbcListDetailed: result,
                },
            });
            setRows(result);
            setLoading(false);
        });
    }, []);

    /*
     * Material Table from the material table documentation https://material-table.com/#/
     */
    return (
        <ThemeProvider theme={darkMode ? muiTheme : null}>
            <Box
                sx={{
                    height: '100%',
                    th: {
                        height: '80.5px',
                        backgroundColor: darkMode
                            ? '#212121 !important'
                            : 'white !important',
                    },
                    td: {
                        color: darkMode ? 'black !important' : null,
                    },
                    background: darkMode ? '#212121 !important' : null,
                    boxShadow: darkMode ? 1 : 0,
                    borderWidth: darkMode ? 1 : 0,
                    borderStyle: darkMode ? 'solid' : null,
                    borderColor: darkMode ? '#DDD' : null,
                }}>
                <MaterialTable
                    style={{ height: '100%' }}
                    tableRef={tableRef}
                    title="Session Border Controllers"
                    icons={tableIcons}
                    columns={columns}
                    data={rows}
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        sorting: true,
                        pageSize: 3,
                        pageSizeOptions: [],
                        draggable: false,
                    }}
                    actions={[
                        (rowData) => ({
                            icon: tableIcons.MoveDown,
                            tooltip: 'Decrease Priority',
                            onClick: (e, rowData) => {
                                moveDownHandler(rowData);
                            },
                            hidden: rowData.tableData.id === rows.length - 1,
                        }),
                        (rowData) => ({
                            icon: tableIcons.MoveUp,
                            tooltip: 'Increase Priority',
                            onClick: (e, rowData) => {
                                moveUpHandler(rowData);
                            },
                            hidden: rowData.tableData.id === 0,
                        }),
                    ]}
                    localization={{
                        header: {
                            actions: '',
                        },
                    }}
                />
            </Box>
        </ThemeProvider>
    );
};

export default EditSipSbc;
