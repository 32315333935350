import React from 'react';
import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { classNames } from 'utils/classNames';

import styles from './styles';

export default function DashboardServiceAddServiceCard({
    children,
    dataWalkthroughId,
    ...props
}) {
    const { darkMode } = useSelector((state) => state.settings);

    return (
        <Box
            sx={styles.DashboardServiceAddServiceCard}
            {...props}
            className={classNames(
                darkMode ? 'bg-[var(--chakra-colors-dark-tcap)]' : 'bg-white',
                'rounded-l rounded-r !shadow-lg',
                props?.className,
            )}
            data-walkthroughid={dataWalkthroughId}
        >
            {children}
        </Box>
    );
}
