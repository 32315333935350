export const containerStyles = {
    marginBottom: '150px',
    maxWidth: 600,
    width: '100%',
    shadow: 'md',
    padding: '20px',
    background: '#FFF',
    boxShadow: 'md',
    borderRadius: '10px',
};

export const logoStyles = {
    display: 'block',
    maxWidth: '300px',
    maxHeight: '150px',
    margin: '0 auto 25px auto',
};

export const stepContainerStyles = {
    marginBottom: '7px',
    transitionDuration: '100ms',
};

export const progressbarStyles = {
    marginTop: '20px',
    marginBottom: '20px',
    background: 'green.100',
    borderRadius: 'md',
};
