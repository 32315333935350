import React, {useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import Alert from '@material-ui/lab/Alert';

import {axios} from '../../../services/axios';
import config from '../../../config.json';
import {TableAndModal} from '../../../components/';

/*
 * Component for the Jobs tab in the Sidebar that used the TableAndModal component
 * This table has no Read or Update/Delete and is just for viewing what jobs are active currently
 */
const Jobs = () => {
    const tableRef = useRef(null);
    // scope from state
    const {currentScope} = useSelector((state) => {
        return {...state.login};
    });

    // current comapny, wholeasler and partner from state
    const {currentCompany, currentWholesaler, currentPartner} = useSelector(
        (state) => {
            return {...state.navigationLists};
        },
    );

    // default columns for the table
    const defaultColumns = useMemo(() => {
        return [
            {
                title: 'Name',
                field: 'description',
            },

            {
                title: 'Status',
                field: 'jobStatus',

                width: '5%',
                cellStyle: {
                    textAlign: 'center',
                },
                lookupDescription: config.jobs.statusJSON,
                lookup: {
                    0: (
                        <Tooltip title="Open">
                            <FiberManualRecordRoundedIcon
                                style={{
                                    color: 'purple',
                                    fontSize: 'small',
                                    textAlign: 'center',
                                }}
                            />
                        </Tooltip>
                    ),
                    1: (
                        <Tooltip title="In Progress">
                            <FiberManualRecordRoundedIcon
                                style={{
                                    color: '#c1c92a',
                                    fontSize: 'small',
                                    textAlign: 'center',
                                }}
                            />
                        </Tooltip>
                    ),
                    2: (
                        <Tooltip title="Completed">
                            <FiberManualRecordRoundedIcon
                                style={{
                                    color: 'green',
                                    fontSize: 'small',
                                    textAlign: 'center',
                                }}
                            />
                        </Tooltip>
                    ),
                    3: (
                        <Tooltip title="Cancelled">
                            <FiberManualRecordRoundedIcon
                                style={{
                                    color: 'red',
                                    fontSize: 'small',
                                    textAlign: 'center',
                                }}
                            />
                        </Tooltip>
                    ),
                    20: (
                        <Tooltip title="Error">
                            <FiberManualRecordRoundedIcon
                                style={{
                                    color: 'red',
                                    fontSize: 'small',
                                    textAlign: 'center',
                                }}
                            />
                        </Tooltip>
                    ),
                },
            },

            {
                title: 'Worker Type',
                field: 'workerType',
                lookup: config.jobs.workersJSON,
            },
            {
                title: 'Worker ID',
                field: 'workerID',
                hidden: currentScope !== 80,
                hiddenByColumnsButton: true,
            },

            {title: 'Comments', field: 'comments'},
            {
                title: 'Last Updated At',
                field: 'lastUpdatedTS',
                type: 'datetime',
            },
            {
                title: 'Created At',
                field: 'createTS',
                type: 'datetime',
                defaultSort: 'desc',
            },
            {
                title: 'Detail Type',
                field: 'detailType',
                lookup: config.jobs.detailsJSON,
                hidden: true,
                hiddenByColumnsButton: true,
            },
            {
                title: 'Details',
                field: 'details',
                hidden: true,
                hiddenByColumnsButton: true,
            },

            {
                title: 'Owner ID',
                field: 'ownerID',
                hidden: true,
                hiddenByColumnsButton: true,
            },
            {
                title: 'Wholesaler ID',
                field: 'wholesalerID',
                hidden: true,
                hiddenByColumnsButton: true,
            },
            {
                title: 'Partner ID',
                field: 'partnerID',
                hidden: true,
                hiddenByColumnsButton: true,
            },
            {
                title: 'Company ID',
                field: 'companyID',
                hidden: true,
                hiddenByColumnsButton: true,
            },
        ];
    }, [currentScope]);

    // Return a Table with the Jobs Data, No modals as this is just a list
    return (
        <TableAndModal
            tableRef={tableRef}
            onTableFetch={() => {
                return axios.get('/jobs', {
                    params: {
                        wholesalerID:
                            currentScope === 60 ? currentWholesaler : null,
                        partnerID: currentScope === 40 ? currentPartner : null,
                        companyID: currentScope === 20 ? currentCompany : null,
                    },
                });
            }}
            modalContent={
                <>
                    <Alert severity="success">Job submitted correctly.</Alert>
                </>
            }
            title="Jobs"
            defaultColumns={defaultColumns}
            groupable
            filterable
            maxBodyHeight={window.innerHeight * config.plainView.tableHeight}
            // remove below line to allow for actions column
            noActionsColumn
        />
    );
};

export default Jobs;
