import { MenuItem } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

/**
 * @typedef {Object} ChakraMenuItemProps
 * @property {import('react').ReactNode} children
 * @param {import('@chakra-ui/react').MenuItemProps} props
 */
export default function ChakraMenuItem({ children, ...props }) {
    const { darkMode } = useSelector((state) => state.settings);
    return (
        <MenuItem
            _focus={{
                bg: darkMode ? 'rgba(255, 255, 255, 0.16)' : null,
            }}
            {...props}
        >
            {children}
        </MenuItem>
    );
}
