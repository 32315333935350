import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MultiChild, Error } from '../../../components';
import { useSelector, useDispatch } from 'react-redux';
import { renderTelephoneNumber } from '../../../utils/utils';
import config from '../../../config.json';
import General from './edit/General';
import AddBlock from './add/AddBlock';
import Allocations from './edit/Allocations';
import Custom from './edit/Custom';
import { Alert } from '@material-ui/lab';
import Admin from './edit/Admin';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import { Button } from 'react-bootstrap';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import SplitJoinBlock from './split-join/SplitJoinBlock';
import {
    Box,
    ChakraProvider,
    FormErrorMessage,
    FormHelperText,
    Button as ChakraButton,
    InputGroup,
    InputRightElement,
    useDisclosure,
    Stack,
} from '@chakra-ui/react';
import { axios } from '@/services/axios';
import { FormControl, FormLabel, Input } from 'components/v4';
import {
    Tabs as ChakraTabs,
    TabList,
    TabPanels,
    Tab as ChakraTab,
    TabPanel as ChakraTabPanel,
} from '@chakra-ui/react';
import {
    customTabStylesCombineTheme,
    selectedTabStylesCombineTheme,
} from '@/constants';
import { useDeepMerge } from 'hooks/useDeepMerge';
import { nullError } from 'components/error';
import walkthroughIds from './walkthroughIds';
import { SearchIcon } from '@chakra-ui/icons';
import { isEmpty } from 'lodash';

/*
 * Component for the Number Blocks Tab of the Sidebar options
 */
function NumberBlocks(props) {
    const dispatch = useDispatch();
    const { theme } = useDeepMerge();
    const tabList = ['Search', 'Number Blocks'];
    const tableRef = useRef();
    const { onOpen, isOpen, onClose } = useDisclosure();

    const [nbID, setNBID] = useState(null);
    const [state, setState] = useState('idle');

    const [loadList, setLoadList] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showTable, setShowTable] = useState(false);

    const [value, setValue] = useState('');
    const handleChange = (event) => {
        setErrorMessage(null);
        setIsError(false);
        setValue(event.target.value);
    };

    const {
        ufAddButton,
        ufTabButton,
        ufInputSearch,
        ufInputClearButton,
        ufSearchButton,
    } = walkthroughIds;

    // state access
    const { modalData, modalState, modalMode } = useSelector((state) => {
        return {
            modalLoading: state.modal.loading,
            modalData: state.modal.data,
            modalState: state.modal.state,
            modalMode: state.modal.mode,
            modalStateError: state.modal.stateError,
            modalUploading: state.modal.uploading,
            modalLoadFields: state.modal.loadFields,
            modalShow: state.modal.show,
        };
    });

    const { loading: tableLoading, state: tableState } = useSelector(
        (state) => {
            return { ...state.table };
        },
        // recompute the values on when loading property is changed,
        // updates the table slice of the state
        (prev, next) => {
            return prev.loading === next.loading && prev.state === next.state;
        },
    );

    const { requiredScope, currentScope } = useSelector((state) => {
        return { ...state.login };
    });

    const { currentWholesaler, currentPartner } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    const { darkMode } = useSelector((state) => state.settings);

    // default columns for the number blocks table
    const defaultColumns = useMemo(() => {
        var columns = [
            {
                title: 'Wholesaler',
                defaultSort: 'asc',
                field: 'wholesalerName',
                hidden: currentScope < 80,
                hiddenByColumnsButton: true,
            },
            {
                title: 'Partner',
                defaultSort: currentScope === 60 && 'asc',
                field: 'partnerName',
                hidden: currentScope < 60,
                hiddenByColumnsButton: true,
            },
            {
                title: 'Company',
                defaultSort: currentScope === 40 && 'asc',
                field: 'companyName',
            },
            {
                title: 'Block Size',
                field: 'blockSize',
                width: '10%',
                doNotOverrideSort: true,
                lookup: {
                    1: 1,
                    5: 5,
                    10: 10,
                    100: 100,
                },
            },
            {
                title: 'First Number',
                field: 'first',
                render: (rowData) => renderTelephoneNumber(rowData, 'first'),
            },
            {
                title: 'Last Number',
                field: 'last',
                render: (rowData) => renderTelephoneNumber(rowData, 'last'),
            },
            {
                title: 'Inbound Carrier',
                field: 'inboundCarrier',
            },
            {
                title: 'Outbound Carrier',
                field: 'outboundCarrier',
            },
            {
                title: 'Special Number',
                field: 'isSpecialNumber',
                type: 'boolean',
                hiddenByColumnsButton: true,
                hidden: currentScope < 80,
            },
            {
                title: 'Public Number',
                field: 'publicPool',
                type: 'boolean',
                hiddenByColumnsButton: true,
                hidden: true,
            },
            ...config.table.custom,
        ];

        // no longer coming down with list endpoint
        // if (currentScope === 80) {
        //     columns.push({
        //         title: 'Notes',
        //         width: '15%',
        //         field: 'internalNotes',
        //     });
        // }
        return columns;
    }, [currentScope]);

    // search tab columns
    const searchColumns = useMemo(() => {
        var columns = [
            {
                title: 'Wholesaler',
                defaultSort: 'asc',
                field: 'wholesaler',
                hidden: currentScope < 80,
                hiddenByColumnsButton: true,
            },
            {
                title: 'Partner',
                defaultSort: currentScope === 60 && 'asc',
                field: 'partner',
                hidden: currentScope < 60,
                hiddenByColumnsButton: true,
            },
            {
                title: 'Company',
                defaultSort: currentScope === 40 && 'asc',
                field: 'company',
            },
            {
                title: 'Block Size',
                field: 'blockSize',
                width: '10%',
                doNotOverrideSort: true,
                lookup: {
                    1: 1,
                    5: 5,
                    10: 10,
                    100: 100,
                },
            },
            {
                title: 'First Number',
                field: 'first',
                render: (rowData) => renderTelephoneNumber(rowData, 'first'),
            },
            {
                title: 'Last Number',
                field: 'last',
                render: (rowData) => renderTelephoneNumber(rowData, 'last'),
            },
            {
                title: 'Inbound Carrier',
                field: 'inboundCarrier',
            },
            {
                title: 'Outbound Carrier',
                field: 'outboundCarrier',
            },
            {
                title: 'Special Number',
                field: 'isSpecialNumber',
                type: 'boolean',
                hiddenByColumnsButton: true,
                hidden: currentScope < 80,
            },
            {
                title: 'Public Number',
                field: 'publicPool',
                type: 'boolean',
                hiddenByColumnsButton: true,
                hidden: true,
            },
            ...config.table.custom,
        ];

        // no longer coming down with list endpoint
        // if (currentScope === 80) {
        //     columns.push({
        //         title: 'Notes',
        //         width: '15%',
        //         field: 'internalNotes',
        //     });
        // }
        return columns;
    }, [currentScope]);

    // function for table fetch of number blocks
    const tableFetch = () => {
        switch (currentScope) {
            case 40:
                return '/partner/' + currentPartner + '/numberblocks/list';
            case 60:
                return '/numberblocks/list/' + currentWholesaler;
            case 80:
                return '/numberblocks/list';
        }
    };

    const validateOnSubmit = () => {
        var newErrorState = {};
        newErrorState = {
            // first:
            //     (!modalState?.first || modalState?.first?.length < 1) &&
            //     'Field is required.',
            // blockSize:
            //     (!modalState?.blockSize || modalState?.blockSize?.length < 1) &&
            //     'Field is required.',
            inboundCarrierID:
                (!modalState?.inboundCarrierID ||
                    modalState?.inboundCarrierID?.length < 1) &&
                'Field is required.',
            outboundCarrierID:
                (!modalState?.outboundCarrierID ||
                    modalState?.outboundCarrierID?.length < 1) &&
                'Field is required.',
            wholesalerID:
                (!modalState?.wholesalerID ||
                    modalState?.wholesalerID?.length < 1) &&
                'Field is required.',
        };
        dispatch({
            type: 'CHANGE_MODAL_STATE_ERROR',
            payload: newErrorState,
        });
        return !(
            // newErrorState.first ||
            // newErrorState.blockSize ||
            (
                newErrorState.inboundCarrierID ||
                newErrorState.outboundCarrierID ||
                newErrorState.wholesalerID
            )
        );
    };

    const splitJoinHandler = (rowData) => {
        setState('idle');
        setNBID(rowData.id);
        onOpen();
    };

    useEffect(() => {
        if (tabIndex === 1 && !loadList) {
            setLoadList(true);
        }
    }, [tabIndex]);

    useEffect(() => {
        if (tableLoading && showTable) {
            handleSearch();
        }
    }, [currentScope]);

    const handleSearch = async () => {
        dispatch({
            type: 'RESET_TABLE_MULTICHILD',
        });
        if (value) {
            setIsError(false);
            // Germany has the longest phone number 14 digits
            if (value.length > 15) {
                setErrorMessage('Invalid telephone number.');
                setIsError(true);
                return;
            }
            try {
                setShowTable(true);
                if (!tableLoading) {
                    dispatch({ type: 'CHANGE_TABLE_LOADING' });
                }
                const result = await axios.get(
                    '/NumberBlock/Find?telephoneNumber=' + value,
                );
                dispatch({
                    type: 'CHANGE_TABLE_STATE',
                    payload: {
                        //transform data when data is fetched using default columns and onTableSuceed prop if its passsed in
                        columns: searchColumns,
                        data: [result.data],
                    },
                });
                if (tableLoading) {
                    dispatch({ type: 'CHANGE_TABLE_LOADING' });
                }
            } catch (e) {
                if (tableLoading) {
                    dispatch({ type: 'CHANGE_TABLE_LOADING' });
                }
                setErrorMessage(e.response.data.message);
                setIsError(true);
            }
        } else {
            setShowTable(false);
            setErrorMessage('Field is required.');
            setIsError(true);
        }
    };

    const handleClear = () => {
        setIsError(false);
        setErrorMessage('');
        setShowTable(false);
        setValue('');
        dispatch({
            type: 'RESET_TABLE_MULTICHILD',
        });
    };

    const handleListClear = () => {
        setLoadList(false);
        dispatch({
            type: 'RESET_TABLE_MULTICHILD',
        });
    };

    if (!currentWholesaler || !currentPartner) {
        return <Error error={nullError} />;
    }

    if (modalMode === 'Add') {
        return <AddBlock handleBack={handleClear} />;
    }

    return (
        <ChakraProvider resetCSS={false} theme={theme}>
            <h1 className="mb-2 text-[2.5rem] font-medium">Number Blocks</h1>
            {requiredScope > 40 && (
                <>
                    <Button
                        className="btn btn-primary btn-sm"
                        data-walkthroughid={ufAddButton}
                        style={{ marginBottom: '1%' }}
                        onClick={() => {
                            // from edit and click on add nb, it doesn't remove the previous edit state
                            dispatch({
                                type: 'RESET_MODAL',
                            });
                            dispatch({
                                type: 'OPEN_MODAL',
                                payload: {
                                    state: {
                                        numberBlockPortingType: 0, //BY DEFAULT IS 0
                                        wholesalerID: currentWholesaler,
                                    },
                                    loading: false,
                                    mode: 'Add',
                                    hash: '',
                                },
                            });
                            dispatch({
                                type: 'CHANGE_MODAL_HASH',
                            });
                        }}>
                        {' '}
                        <AddRoundedIcon
                            fontSize="small"
                            style={{
                                marginBottom: '2%',
                            }}
                        />{' '}
                        Number Blocks
                    </Button>
                </>
            )}
            <ChakraTabs
                isLazy
                index={tabIndex}
                onChange={(index) => {
                    if (tabIndex == 1) {
                        // resets the list tab
                        handleListClear();
                    } else {
                        // resets the search tab
                        handleClear();
                    }
                    setTabIndex(index);
                }}>
                <TabList borderBottom={'none'}>
                    {tabList.map((tab, index) => (
                        <ChakraTab
                            data-walkthroughid={ufTabButton + tab}
                            key={index}
                            onMouseDown={(e) =>
                                e.button === 2 && e.preventDefault()
                            }
                            sx={{
                                ...customTabStylesCombineTheme,
                                color: darkMode
                                    ? 'rgba(255, 255, 255)'
                                    : 'inherit',
                            }}
                            _selected={{
                                ...selectedTabStylesCombineTheme,
                                textColor: darkMode
                                    ? 'rgba(255, 255, 255)'
                                    : 'inherit',
                            }}>
                            {tab}
                        </ChakraTab>
                    ))}
                </TabList>

                <TabPanels padding={0}>
                    <ChakraTabPanel>
                        <Box maxW={'30%'} pb={5}>
                            <FormControl isRequired isInvalid={isError}>
                                <FormLabel>Telephone Number</FormLabel>
                                <Stack display={'flex'} direction={'row'}>
                                    <InputGroup>
                                        <Input
                                            data-walkthroughid={ufInputSearch}
                                            value={value}
                                            onChange={handleChange}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                    handleSearch();
                                                }
                                            }}
                                            placeholder="e.g. 1234567890"
                                        />
                                        {isEmpty(value) && (
                                            <InputRightElement pointerEvents="none">
                                                <SearchIcon color="gray.300" />
                                            </InputRightElement>
                                        )}
                                    </InputGroup>
                                    <Button
                                        data-walkthroughid={ufSearchButton}
                                        bg={'none'}
                                        color={darkMode ? 'white' : 'inherit'}
                                        _hover={{
                                            bg: 'none',
                                            color: darkMode
                                                ? 'white'
                                                : 'inherit',
                                        }}
                                        onClick={() => handleSearch()}
                                        className="btn btn-primary">
                                        Search
                                    </Button>
                                    <Button
                                        data-walkthroughid={ufInputClearButton}
                                        style={{
                                            height: 40,
                                            marginLeft: 5,
                                            display: 'inline-block',
                                            // alignSelf: 'end',
                                        }}
                                        onClick={() => handleClear()}
                                        variant="secondary">
                                        {' '}
                                        Clear{' '}
                                    </Button>
                                </Stack>
                                {isError ? (
                                    <FormErrorMessage>
                                        {errorMessage}
                                    </FormErrorMessage>
                                ) : (
                                    <FormHelperText>
                                        Search number in E.164 format without
                                        the +
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Box>
                        {showTable && (
                            <MultiChild
                                showBreadcrumbs={false}
                                tabs={[
                                    {
                                        component: <General />,
                                        label: 'General',
                                    },
                                    { component: <Custom />, label: 'Custom' },
                                    {
                                        component: (
                                            <Admin reset={handleClear} />
                                        ),
                                        label: 'Admin',
                                    },
                                    {
                                        component: <Allocations />,
                                        label: 'Allocations',
                                    },
                                ]}
                                onModalSucceed={function () {
                                    dispatch({
                                        type: 'RESET_MODAL',
                                    });
                                    dispatch({
                                        type: 'RESET_TABLE',
                                    });
                                }}
                                // do not show table once unmount
                                tableRef={tableRef}
                                modalFetch={'/numberblock/' + modalData?.id}
                                modalAdd="/numberblock/"
                                size={'md'}
                                defaultColumns={searchColumns}
                                overrideTableLoading
                                modalUpdate="/numberblock/"
                                modalUpload={(freshData, state) => {
                                    return {
                                        ...freshData,
                                        ...state,
                                    };
                                }}
                                additionalActions={[
                                    (rowData) => ({
                                        icon: () => {
                                            return (
                                                <VerticalSplitIcon fontSize="small" />
                                            );
                                        },
                                        tooltip: 'Split/Join Block',
                                        position: 'row',
                                        hidden:
                                            requiredScope <= 40 ||
                                            rowData.companyName,
                                        onClick: () =>
                                            splitJoinHandler(rowData),
                                    }),
                                ]}
                                rowActions={{
                                    multiChildTable: {
                                        name: 'Edit Number Blocks',
                                        to: 0,
                                    },
                                }}
                                title="Number Blocks"
                                noTitle
                                noRefresh
                                groupable
                                filterable
                                maxBodyHeight={
                                    window.innerHeight *
                                    config.tabView.tableHeight
                                }
                                modalValidate={validateOnSubmit}
                                newPage
                                dynamicScope={{ minimumScope: 40 }}
                            />
                        )}
                    </ChakraTabPanel>
                    <ChakraTabPanel>
                        {loadList && (
                            <MultiChild
                                tabs={[
                                    {
                                        component: <General />,
                                        label: 'General',
                                    },
                                    { component: <Custom />, label: 'Custom' },
                                    {
                                        component: (
                                            <Admin reset={handleClear} />
                                        ),
                                        label: 'Admin',
                                    },
                                    {
                                        component: <Allocations />,
                                        label: 'Allocations',
                                    },
                                ]}
                                onModalSucceed={function () {
                                    dispatch({
                                        type: 'RESET_MODAL',
                                    });
                                    dispatch({
                                        type: 'RESET_TABLE',
                                    });
                                }}
                                onTableFetch={() => {
                                    if (loadList) {
                                        return axios.get(tableFetch());
                                    }
                                }}
                                tableRef={tableRef}
                                modalFetch={'/numberblock/' + modalData?.id}
                                size={'md'}
                                loadingFieldDropdown={{
                                    dropDownValue: 'id',
                                    displayField: 'first',
                                    onDisplayDropdown: (value) => {
                                        const { first, last } = value;
                                        return `+${first} - +${last}`;
                                    },
                                }}
                                modalUpdate="/numberblock/"
                                modalUpload={(freshData, state) => {
                                    return {
                                        ...freshData,
                                        ...state,
                                    };
                                }}
                                additionalActions={[
                                    (rowData) => ({
                                        icon: () => {
                                            return (
                                                <VerticalSplitIcon fontSize="small" />
                                            );
                                        },
                                        tooltip: 'Split/Join Block',
                                        position: 'row',
                                        hidden:
                                            requiredScope <= 40 ||
                                            rowData.companyName,
                                        onClick: () =>
                                            splitJoinHandler(rowData),
                                    }),
                                ]}
                                rowActions={{
                                    multiChildTable: {
                                        name: 'Edit Number Blocks',
                                        to: 0,
                                    },
                                }}
                                noTitle
                                title="Number Blocks"
                                defaultColumns={defaultColumns}
                                groupable
                                filterable
                                maxBodyHeight={
                                    window.innerHeight *
                                    config.tabView.tableHeight
                                }
                                modalValidate={validateOnSubmit}
                                newPage
                                dynamicScope={{ minimumScope: 40 }}
                            />
                        )}
                    </ChakraTabPanel>
                </TabPanels>
            </ChakraTabs>

            <SplitJoinBlock
                isOpen={isOpen}
                onClose={onClose}
                id={nbID}
                setId={setNBID}
                state={state}
            />
        </ChakraProvider>
    );
}

export default NumberBlocks;
