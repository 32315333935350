import { useState, useEffect } from 'react';
import { Box, Stack, useToast } from '@chakra-ui/react';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Select } from 'chakra-react-select';
import { useMutation } from '@tanstack/react-query';

import { updateAppAuth, getAppAuthById } from 'API/AppAuth';
import getCompany from 'API/Company/getCompany';
import { classNames } from 'utils/classNames';

import LoadingIndicator from 'components/v4/LoadingIndicator';
import AppAuthSelectiveSync from './AppAuthSelectiveSync';
import {
    FormControl,
    FormLabel,
    Button,
    Checkbox,
    Heading,
    Input,
} from '@/components/v4';

import { chakraSelectDarkMode, defaultChakraSelectStyle } from '@/constants';
import config from '@/config.json';

import walkthroughIds from '../walkthroughIds';

export default function AppAuthEdit() {
    const queryClient = useQueryClient();
    const toast = useToast();
    const navigate = useNavigate();
    const params = useParams();
    const { id } = params;
    const { restError: errorMessage } = useSelector((state) => ({
        ...state.modal,
    }));
    const { currentCompany: companyId, currentCompanyData } = useSelector(
        (state) => ({
            ...state.navigationLists,
        }),
    );
    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);
    const chakraStyles = {
        ...defaultChakraSelectStyle,
        ...chakraSelectDark,
    };
    const initialState = {
        defaultManagedBy: 0,
        selectiveSync: false,
        maxAccessFailures: 0,
        accessFailureWindow: 0,
        userSync: false,
        tenantID: '',
        filterGroup: '',
        filterLocations: [],
        username: '',
    };
    const [formValues, setFormValues] = useState(initialState);
    const { requiredScope: accountScope } = useSelector((state) => ({
        ...state.login,
    }));

    const { appAuth } = walkthroughIds;

    const managedByOptions = config.users.managedBy;

    const selectiveSyncRequired = currentCompanyData?.selectiveSyncPolicy === 2;

    const {
        data: appAuthData,
        status,
        isFetching,
    } = useQuery({
        queryKey: ['appAuth', { id }],
        queryFn: async () => {
            const res = await getAppAuthById(id);
            return res.data;
        },
        enabled: !!id,
        onSuccess: (data) => {
            setFormValues({
                maxAccessFailures: data.maxAccessFailures,
                accessFailureWindow: data.accessFailureWindow,
                tenantID: data.tenantID,
                defaultManagedBy: data.defaultManagedBy,
                selectiveSync: data.selectiveSync,
                userSync: data.userSync,
                filterGroup: data.filterGroup,
                filterLocations: data.filterLocations,
                username: data.username,
            });
        },
        refetchOnWindowFocus: false,
    });

    const handleSelectChange = (item, name) => {
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            [name]: item.value,
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            [name]: value,
        }));
    };

    const handleUserSync = () => {
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            userSync: !prevFormValues.userSync,
        }));
    };

    /**
     * Reset the selective sync form whenever the checkboxes are toggled.
     */
    const handleSelectiveSync = () => {
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            selectiveSync: !prevFormValues.selectiveSync,
            filterGroup: null,
            filterLocations: [],
        }));
    };

    const updateManaged = useMutation({
        mutationFn: () =>
            updateAppAuth(
                {
                    ...formValues,
                    maxAccessFailures: Number(formValues.maxAccessFailures),
                    accessFailureWindow: Number(formValues.accessFailureWindow),
                    id,
                },
                accountScope,
            ),
        onSuccess: (data) => {
            toast({
                title: 'Success',
                description: 'App Auth updated successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            queryClient.setQueryData(['appAuth', { id }], data);
        },
        onError: (_err) => {
            if (errorMessage) {
                toast({
                    title: 'Error',
                    description: `${
                        errorMessage ||
                        'App Auth update failed. Please try again later'
                    }`,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        },
    });

    const onSubmit = () => {
        updateManaged.mutate({ id });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit();
    };

    if (status === 'loading' || isFetching) {
        return <LoadingIndicator />;
    }

    if (appAuthData?.companyID !== companyId) {
        return <Navigate to="/dashboard-services" />;
    }

    return (
        <Box
            sx={{
                padding: '10px 0',
                maxWidth: '2xl',
                minWidth: 'xl',
                margin: '0 auto',
            }}>
            <Heading fontSize="3xl" as="h2" mb={2}>
                Edit App Auth
            </Heading>
            <Box as="form" onSubmit={handleSubmit}>
                <Stack
                    spacing="5"
                    px={{ base: '4', md: '6' }}
                    py={{ base: '5', md: '6' }}>
                    <Stack
                        spacing="6"
                        direction={{ base: 'column', md: 'row' }}>
                        <FormControl
                            id="service admin account"
                            data-walkthroughid={appAuth.ufServiceAdminAccount}>
                            <FormLabel>Service Admin Account</FormLabel>
                            <Input
                                name="service admin account"
                                isDisabled
                                value={formValues.username}
                            />
                        </FormControl>
                    </Stack>
                    <Stack
                        spacing="6"
                        direction={{ base: 'column', md: 'row' }}>
                        <FormControl
                            id="defaultManagedBy"
                            data-walkthroughid={appAuth.ufManagedBy}>
                            <FormLabel>Default Managed By</FormLabel>
                            <Select
                                options={managedByOptions}
                                value={managedByOptions.find(
                                    (item) =>
                                        item.value ===
                                        formValues.defaultManagedBy,
                                )}
                                name="defaultManagedBy"
                                getOptionLabel={(option) => option.name}
                                onChange={(item) =>
                                    handleSelectChange(item, 'defaultManagedBy')
                                }
                                chakraStyles={chakraStyles}
                                selectedOptionColor="brand"
                            />
                        </FormControl>

                        <FormControl>
                            <FormLabel>Tenant ID</FormLabel>
                            <Input
                                isDisabled={appAuthData.tenantID?.length > 0}
                                value={formValues.tenantID}
                                onChange={handleInputChange}
                                name="tenantId"
                                data-walkthroughid={appAuth.ufTenantId}
                            />
                        </FormControl>
                    </Stack>

                    {accountScope > 20 && (
                        <Stack
                            spacing="6"
                            direction={{ base: 'column', md: 'row' }}>
                            <FormControl id="maxAccessFailures">
                                <FormLabel>Max Access Failures</FormLabel>
                                <Input
                                    type="number"
                                    name="maxAccessFailures"
                                    value={formValues.maxAccessFailures}
                                    onChange={handleInputChange}
                                    data-walkthroughid={
                                        appAuth.ufMaxAccessFailures
                                    }
                                />
                            </FormControl>
                            <FormControl id="accessFailureWindow">
                                <FormLabel>Access Failure Window</FormLabel>
                                <Input
                                    type="number"
                                    name="accessFailureWindow"
                                    value={formValues.accessFailureWindow}
                                    onChange={handleInputChange}
                                    data-walkthroughid={
                                        appAuth.ufAccessFailureWindow
                                    }
                                />
                            </FormControl>
                        </Stack>
                    )}

                    <Stack spacing="6" direction="row">
                        <FormControl id="selectiveSync">
                            <Box display="flex" alignItems="center">
                                <Checkbox
                                    isChecked={
                                        formValues.selectiveSync ||
                                        selectiveSyncRequired
                                    }
                                    isDisabled={
                                        currentCompanyData?.selectiveSyncPolicy ==
                                            0 || selectiveSyncRequired
                                    }
                                    onChange={handleSelectiveSync}
                                    colorScheme="whatsapp"
                                    name="selectiveSync"
                                    mb={0}
                                    data-walkthroughid={appAuth.ufSelectiveSync}
                                />
                                <FormLabel mb={0} ml={2}>
                                    Selective Sync
                                </FormLabel>
                            </Box>
                        </FormControl>
                        <FormControl id="userSync">
                            <Box display="flex" alignItems="center">
                                <Checkbox
                                    isChecked={formValues.userSync}
                                    onChange={handleUserSync}
                                    colorScheme="whatsapp"
                                    name="userSync"
                                    mb={0}
                                    data-walkthroughid={appAuth.ufUserSync}
                                />
                                <FormLabel mb={0} ml={2}>
                                    User Sync
                                </FormLabel>
                            </Box>
                            {/* <FormHelperText>
                                *To disable this please turn off DR first
                            </FormHelperText> */}
                        </FormControl>
                    </Stack>

                    {(formValues.selectiveSync || selectiveSyncRequired) && (
                        <AppAuthSelectiveSync
                            formValues={formValues}
                            handleInputChange={handleInputChange}
                            setFormValues={setFormValues}
                        />
                    )}
                </Stack>

                <Stack
                    spacing="6"
                    px={{ base: '4', md: '6' }}
                    py={{ base: '5', md: '6' }}
                    sx={{
                        justifyContent: 'center',
                        width: '170px',
                        margin: '0 auto',
                    }}
                    direction={{ base: 'column', md: 'row' }}>
                    <Box>
                        <Button
                            variant="outline"
                            className={classNames(
                                darkMode ? 'bg-white hover:opacity-70' : null,
                            )}
                            minW={['100%', '85px']}
                            onClick={() => navigate('/dashboard-services')}
                            data-walkthroughid={appAuth.ufCloseButton}>
                            Close
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            type="submit"
                            minW={['100%', '85px']}
                            data-walkthroughid={appAuth.ufUpdateButton}>
                            Update
                        </Button>
                    </Box>
                </Stack>
            </Box>
            {/* End of form */}
        </Box>
    );
}
