import { useRouteError } from 'react-router-dom';

import TcapErrorPage from '@/components/maintenance/ErrorPage';

export function ErrorPage() {
    const error = useRouteError();
    console.log('error', error);

    return <TcapErrorPage error={error} />;
}
