// import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    // Input,
    FormControl,
    FormLabel,
    InputGroup,
    InputLeftElement,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    Alert,
    AlertIcon,
    AlertDescription,
    Button,
    Input,
} from '@/components/v4';

import { TpmBody, TpmFooter, useTpmContext } from './';
import { useNavigateLocation } from 'hooks/useNavigateLocation/useNavigateLocation';
// import Button from 'components/v4/Button';

import walkthroughIds from './walkthroughIds';
import { postRequestNumberVerification } from 'API/TPMTrial';

import { AddIcon } from '@chakra-ui/icons';

function TpmTrialProcess() {
    const {
        tpmNumber: [state, setState],
        tpmAddress: [, setAddressAction],
    } = useTpmContext();

    const navigate = useNavigate();
    const { id } = useParams();
    const { handleNavigateLocation } = useNavigateLocation();
    // console.log('useNavigateLocation', handleNavigateLocation);
    const { darkMode } = useSelector((state) => state.settings);

    const { ufPhoneNumberInput, ufNextButton, ufCancelButton } =
        walkthroughIds.teamsPhoneMobileTrial;

    const handleChangeNumber = (e) => {
        setState({
            status: 'SUBMITTING',
            number: e.target.value,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        if (!id) {
            window.alert('no valid ID specified');
            return;
        }

        setState({
            status: 'LOADING',
            number: state.number,
            error: null,
        });

        postRequestNumberVerification({
            serviceID: id,
            phoneNumber: state.number,
        })
            .then((res) => {
                setAddressAction('SMS_AUTHORIZATION');
                setState({
                    status: 'SUCCESS',
                    number: state.number,
                });
            })
            .catch((error) => {
                console.log('error post', { error });
                const msg =
                    error?.response?.data?.message ||
                    'Error submitting request. Please try again.';
                setState({
                    status: 'ERROR',
                    error: msg,
                    number: state.number,
                });
            });
    };

    if (!id) {
        // handleNavigateLocation('/dashboard-services');
        navigate('/dashboard-services');
    }

    return (
        <Box className="py-16">
            <Box as="form" onSubmit={handleSubmit}>
                <TpmBody>
                    <FormControl
                        data-walkthroughid={ufPhoneNumberInput}
                        isInvalid={state.status === 'ERROR'}>
                        <FormLabel htmlFor="activate-number">
                            Enter Mobile Number
                        </FormLabel>
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents="none"
                                children={
                                    <AddIcon
                                        fontSize="xs"
                                        color={darkMode ? 'gray.700' : null}
                                    />
                                }
                            />
                            <Input
                                w={375}
                                type="text"
                                id="activate-number"
                                value={state.number}
                                onChange={handleChangeNumber}
                                className="!px-8"
                            />
                        </InputGroup>
                    </FormControl>
                </TpmBody>

                {state.error && (
                    <Alert status="error" className="mb-7 mt-4 max-w-sm">
                        <AlertIcon />
                        {state.error}
                    </Alert>
                )}

                <TpmFooter className="mx-auto justify-between">
                    <Button
                        variant="outline"
                        w={{ base: 'full', md: '80px' }}
                        bg={darkMode ? 'white' : null}
                        onClick={() =>
                            handleNavigateLocation('/dashboard-services')
                        }
                        data-walkthroughid={ufCancelButton}>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        w={{ base: 'full', md: '80px' }}
                        isDisabled={!state.number || state.status === 'LOADING'}
                        data-walkthroughid={ufNextButton}>
                        Next
                    </Button>
                </TpmFooter>
            </Box>
        </Box>
    );
}

export default TpmTrialProcess;

