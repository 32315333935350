import { useSelector } from 'react-redux';
import { Select } from 'chakra-react-select';
import { FormControl, Input } from 'components/v4';

import FormLabel from 'components/v4/FormLabel';

import iso3166CountryCodes from 'utils/iso3166CountryCodes';
import { chakraSelectDarkMode, defaultChakraSelectStyle } from '@/constants';

import walkthroughIds from '../walkthroughIds';

/**
 * Selective Sync as a part of AppAuth
 */
function AppAuthSelectiveSync({
    formValues,
    setFormValues,
    handleInputChange,
}) {
    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);
    const countryOptions = iso3166CountryCodes.map((country) => ({
        label: country.name,
        value: country['alpha-2'],
    }));

    const { appAuth } = walkthroughIds;

    function updateLocations(multiSelectValues = []) {
        const items = [];
        multiSelectValues.map((multiSelect) => {
            items.push(multiSelect.value);
        });
        setFormValues((prev) => ({
            ...prev,
            filterLocations: items,
        }));
    }

    return (
        <>
            <FormControl id="filterGroup">
                <FormLabel>
                    Group Name{' '}
                    <i className="font-light">
                        (The name entered below must exactly match the group
                        name in Active Directory)
                    </i>
                </FormLabel>
                <Input
                    value={formValues.filterGroup}
                    onChange={handleInputChange}
                    name="filterGroup"
                    data-walkthroughid={appAuth.ufGroupName}
                />
            </FormControl>
            <FormControl
                id="filterLocations"
                data-walkthroughid={appAuth.ufUsageLocation}>
                <FormLabel>Usage Locations</FormLabel>
                <Select
                    isMulti
                    defaultValue={formValues.filterLocations}
                    name="filterLocations"
                    placeholder="- Select Locations -"
                    closeMenuOnSelect={false}
                    tagVariant="solid"
                    options={countryOptions}
                    onChange={(obj) => updateLocations(obj)}
                    chakraStyles={{
                        ...defaultChakraSelectStyle,
                        ...chakraSelectDark,
                    }}
                    value={countryOptions.filter((obj) => {
                        return formValues.filterLocations.includes(obj.value);
                    })}
                />
            </FormControl>
        </>
    );
}

export default AppAuthSelectiveSync;
