import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Box, IconButton, Link, Tooltip } from '@chakra-ui/react';
import { InfoIcon, ExternalLinkIcon } from '@chakra-ui/icons';

import Text from 'components/v4/Text';
import { SERVICE_STATUS_HAS_PDF } from '../constants';

export default function AppAuthStatus({
    serviceObj,
    authData,
    cardStatus,
    dispatch,
    accountScope,
}) {
    // App auth last modified time
    // const lastModifiedTS = _authData?.lastModifiedTS;

    const { darkMode } = useSelector((state) => state.settings);

    useEffect(() => {
        if (!authData) return;

        if (authData?.status !== 0 && authData?.engineMessage) {
            dispatch({
                type: 'SET_STATUS_DEGRADED',
                payload: {
                    authData,
                    SERVICE_STATUS_HAS_PDF,
                },
            });
            return;
        } else if (authData.status === 0) {
            dispatch({
                type: 'SET_STATUS_SUCCESS',
            });
        } else if (authData.status === 4) {
            dispatch({
                type: 'SET_STATUS_PAIRING',
                payload: authData,
            });
        } else {
            dispatch({
                type: 'SET_STATUS_ERROR',
            });
        }
    }, [authData]);

    // if (!serviceObj) return <Text>...</Text>;
    // TODO: APPAUTH change this status, just a static for now
    if (!serviceObj) return <Text>...</Text>;
    if (cardStatus?.tooltip) {
        return (
            <Box
                sx={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                <Text>Degraded</Text>

                <span className="mx-1 inline-flex flex-shrink-0 self-center">
                    <Tooltip
                        label={cardStatus?.status}
                        hasArrow
                        placement="bottom">
                        <IconButton
                            icon={<InfoIcon />}
                            size="xs"
                            bg="transparent"
                            _hover={{ bg: 'transparent' }}
                        />
                    </Tooltip>
                </span>

                {cardStatus?.to && (
                    <span className="my-auto inline-flex flex-shrink-0 self-center">
                        <Link
                            isExternal
                            href={cardStatus?.to}
                            className="hover:bg-inherit hover:text-inherit">
                            <IconButton
                                icon={<ExternalLinkIcon />}
                                size="xs"
                                variant="outline"
                                _hover={{
                                    bg: darkMode
                                        ? 'rgba(255, 255, 255, 0.16)'
                                        : 'gray.100',
                                }}
                                _active={{
                                    bg: darkMode ? 'gray.400' : 'gray.200',
                                }}
                            />
                        </Link>
                    </span>
                )}
            </Box>
        );
    }

    if (cardStatus?.to) {
        return (
            <Text
                sx={{
                    textAlign: 'left',
                    display: 'flex',
                    alignItems: 'center',
                }}>
                {cardStatus.status}
                {accountScope >= cardStatus.toScope && (
                    <Box sx={{ fontSize: 'xs', ml: '4px', color: 'blue' }}>
                        <NavLink
                            fontSize="sm"
                            to={cardStatus.to}
                            state={cardStatus.toPayload}>
                            ({cardStatus.toText})
                        </NavLink>
                    </Box>
                )}
            </Text>
        );
    }

    return <Text>{cardStatus.status}</Text>;
}
