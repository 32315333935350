import {Box, Text} from '@chakra-ui/react';
import {CheckCircleIcon} from '@chakra-ui/icons';
import {useNavigate} from 'react-router-dom';

import {useTpmContext} from './TpmProvider';

import Button from 'components/v4/Button';

import walkthroughIds from './walkthroughIds';

function TpmTrialTableFinish() {
    const {
        tpmAddress: [, setAddressAction],
    } = useTpmContext();
    const navigate = useNavigate();

    const {ufTrialTableFinishDoneButton, ufTrialTableFinishAddButton} =
        walkthroughIds.teamsPhoneMobileTrial;

    const handleAddButton = () => setAddressAction('TRIAL_TABLE');

    const handleDoneButton = () => navigate('/dashboard-services');

    return (
        <>
            <Box>
                <Box className="flex flex-col justify-center  min-w-4xl">
                    <Box className=" mx-auto text-center">
                        <CheckCircleIcon
                            color="#38A169"
                            className="text-center mb-6"
                            w={16}
                            h={16}
                        />
                        <Text className='font-medium'>Congratulations. You are all setup.</Text>
                    </Box>
                </Box>
            </Box>

            <Box className="flex justify-center mt-6">
                {/* <Button
                    variant="outline"
                    onClick={handleAddButton}
                    data-walkthroughid={ufTrialTableFinishAddButton}>
                    Add now
                </Button> */}
                <Button
                    type="submit"
                    onClick={handleDoneButton}
                    data-walkthroughid={ufTrialTableFinishDoneButton}>
                    Done
                </Button>
            </Box>
        </>
    );
}

export default TpmTrialTableFinish;