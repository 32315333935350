import React from 'react';

/*
 * Component that will be for the admin tab of operator profiles
 * will most likely involve soemthing to do with consents as listed in the whimsical
 */
const ProfilesAdmin = (props) => {
    return <></>;
};

export default ProfilesAdmin;
