//TODO: RENAME HELMET REDUCER
//USED FOR helmet image
const initHeaderState = {};

export const headerReducer = (state = initHeaderState, action) => {
    //maybe wanna default to pingco's?
    switch (action.type) {
        case 'LOGOUT':
            return {...initHeaderState};
        case 'HEADER_TITLE_SUCCESS':
            return {
                ...state,
                partnerName: action.payload,
            };
        case 'HEADER_FAVICON_SUCCESS':
            return {
                ...state,
                favIconPath: action.payload,
            };
        default:
            return state;
    }
};
