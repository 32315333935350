import React, { useRef } from 'react';
import { TextField } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import { TableAndModal } from '../../../../components';
import { NonNegativeInteger } from '../../../../components/maintenance/NumberFormatCustom';
import config from '../../../../config.json';

import walkthroughIds from '../walkthroughIds';

/*
 * Dial Rules tab for the Edit Dial Plan functionality
 */
const DialEdit = () => {
    const dispatch = useDispatch();
    const tableRef = useRef();

    // state access
    const { modalData, modalState, modalMode, modalStateError } = useSelector(
        (state) => {
            return {
                modalLoading: state.modal.loading,
                modalData: state.modal.data,
                modalState: state.modal.state,
                modalMode: state.modal.mode,
                modalStateError: state.modal.stateError,
                modalUploading: state.modal.uploading,
            };
        },
    );
    const { child } = useSelector((state) => {
        return {
            ...state.table,
        };
    });

    const { rules } = walkthroughIds;

    // default columns
    const defaultColumns = [
        {
            title: 'Name',
            field: 'name',
            width: '30%',
        },
        {
            title: 'Priority',
            field: 'priority',
            defaultSort: 'asc',
            doNotOverrideSort: true,
        },
        {
            title: 'Pattern',
            field: 'pattern',
        },
        {
            title: 'Translation',
            field: 'translation',
        },
    ];

    // changer handlers
    const nameChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                name: e.target.value,
            },
        });
    };

    const priorityChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                priority: e.target.value,
            },
        });
    };

    const patternChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                pattern: e.target.value,
            },
        });
    };

    const translationChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                translation: e.target.value,
            },
        });
    };

    // Render the table and modal
    return (
        <TableAndModal
            tableRef={tableRef}
            tableFetch={'/rules/' + child?.id}
            modalFetch={'/rule/' + modalData?.id}
            modalAdd="/rule/"
            modalUpdate="/rule/"
            noTitle
            editable={{
                update: true,
            }}
            modalUpload={(freshData, state) => {
                return {
                    ...freshData,
                    ...state,
                    dialPlanID: child?.id,
                };
            }}
            modalInitState={{ dialPlanID: child?.id, isActive: true }}
            title={'Rules'}
            defaultColumns={defaultColumns}
            add
            groupable
            filterable
            maxBodyHeight={
                window.innerHeight * config.breadcrumbView.tableHeight
            }
            rowActions={{
                editable: true,
            }}
            modalTitle={
                modalMode === 'Add'
                    ? 'Add Rule'
                    : 'Edit ' + modalData?.name + ' Rule'
            }
            modalValidate={() => {
                const newErrorState = {
                    name:
                        (!modalState?.name || modalState?.name?.length < 1) &&
                        'Field is required.',
                    priority:
                        (!modalState?.priority ||
                            modalState?.priority?.length < 1) &&
                        'Field is required.',
                    pattern:
                        (!modalState?.pattern ||
                            modalState?.pattern?.length < 1) &&
                        'Field is required.',
                    translation:
                        (!modalState?.translation ||
                            modalState?.translation?.length < 1) &&
                        'Field is required.',
                };
                dispatch({
                    type: 'CHANGE_MODAL_STATE_ERROR',
                    payload: newErrorState,
                });
                return !(
                    newErrorState.name ||
                    newErrorState.priority ||
                    newErrorState.pattern ||
                    newErrorState.translation
                );
            }}
            modalContent={
                <>
                    <Row style={{ minHeight: 60 }}>
                        <Col>
                            <TextField
                                fullWidth
                                id="Name"
                                label="Name"
                                type="text"
                                value={modalState?.name}
                                error={Boolean(modalStateError?.name)}
                                helperText={modalStateError?.name}
                                onChange={nameChangeHandler}
                                data-walkthroughid={rules.ufNameInput}
                            />
                        </Col>
                        <Col>
                            <TextField
                                fullWidth
                                id="Priority"
                                label="Priority"
                                InputProps={{
                                    inputComponent: NonNegativeInteger,
                                }}
                                error={Boolean(modalStateError?.priority)}
                                helperText={modalStateError?.priority}
                                value={modalState?.priority}
                                onChange={priorityChangeHandler}
                                data-walkthroughid={rules.ufPriorityInput}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextField
                                fullWidth
                                id="Pattern"
                                label="Pattern"
                                type="text"
                                error={Boolean(modalStateError?.pattern)}
                                helperText={modalStateError?.pattern}
                                value={modalState?.pattern}
                                onChange={patternChangeHandler}
                                data-walkthroughid={rules.ufPatternInput}
                            />
                        </Col>
                        <Col>
                            <TextField
                                fullWidth
                                id="Translation"
                                label="Translation"
                                type="text"
                                error={Boolean(modalStateError?.translation)}
                                helperText={modalStateError?.translation}
                                value={modalState?.translation}
                                onChange={translationChangeHandler}
                                data-walkthroughid={rules.ufTranslationInput}
                            />
                        </Col>
                    </Row>
                </>
            }
        />
    );
};

export default DialEdit;
