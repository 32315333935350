import React, {useMemo, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    FormControlLabel,
    Checkbox,
    CircularProgress,
    Grid,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useSelector, useDispatch} from 'react-redux';
import {Button, Form, Row, Col} from 'react-bootstrap';

import config from '../../config.json';
import timeZones from './timezones.json';
import {removeDuplicates} from '../../utils/utils';
import {axios} from '../../services/axios';
import {Succeed, Error, LoadingFieldDropdown} from '../../components/';

// themes from material ui
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

/*
 * Settings function that has a accordion for Limiting rows in invoice lines and calls table
 * Also has date and time display for the user
 */
export default function ControlledAccordions() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [succeed, setSucceed] = useState(false);
    const [error, setError] = useState(false);

    // change handler for expanded state
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    //temporary "modal" state
    const [modalState, setModalState] = useState({
        timeZone: {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hourCycle: 'h12',
            timeZone: 'UTC',
            second: 'numeric',
            timeZoneName: 'short',
            language: 'en-AU',
            hour12: undefined,
        },
        allowUnlimited: false,
    });

    // access to the user settings from state
    const {
        allowUnlimited,
        timeZone = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hourCycle: 'h12',
            timeZone: 'UTC',
            second: 'numeric',
            timeZoneName: 'short',
            language: 'en-AU',
            hour12: undefined,
        },
        ...rest
    } = useSelector((state) => {
        return {...state.settings};
    });

    //extract a list of timezone from given json
    const timeZoneList = useMemo(() => {
        const alltimeZones = timeZones.reduce((result, current) => {
            return result.concat(
                current.utc.map((timeZone) => ({
                    timeZone,
                    id: timeZone,
                })),
            );
        }, []);
        return removeDuplicates(alltimeZones);
    }, [timeZones]);

    // migrate if there is no information in redux
    useEffect(() => {
        dispatch({type: 'INIT_TIMEZONE'});
        setModalState({
            allowUnlimited,
            timeZone,
            ...rest,
        });
    }, []);

    // submis handler for saving settings
    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setSucceed(false);
        setError(false);

        const lastUpdate = Date.now();
        axios
            .post('/adminuser/uisettings', null, {
                headers: {
                    'Content-Type': 'application/json',
                },
                params: {settings: {...modalState, lastUpdate}},
            })
            .then((res) => {
                setLoading(false);
                dispatch({
                    type: 'CHANGE_ALL_SETTINGS',
                    payload: {
                        ...modalState,
                        lastUpdate,
                    },
                });
                setSucceed(true);
            })
            .catch((e) => {
                setLoading(false);
                setError(e);
            });
    };

    // return state banners or accordions with settings
    return (
        <Form className={classes.root} onSubmit={onSubmit}>
            <h1> Settings </h1>
            {succeed && (
                <Succeed style={{marginBottom: 10}} message="Successful" />
            )}
            {error && <Error error={error} style={{marginBottom: 10}} />}
            {loading ? (
                <CircularProgress
                    style={{marginTop: '20%', marginLeft: '50%'}}
                />
            ) : (
                <>
                    <Accordion
                        style={{marginTop: 10}}
                        expanded={expanded === 'panel2'}
                        onChange={handleChange('panel2')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className={classes.heading}>
                                Limit Rows{' '}
                                <b>only for Invoice Lines and Calls tables</b>
                            </Typography>
                            <Typography className={classes.secondaryHeading}>
                                Highest number of rows allowed to be fetched -
                                11 000 or unlimited
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size="small"
                                        checked={modalState?.allowUnlimited}
                                        onChange={(e) => {
                                            setModalState({
                                                ...modalState,
                                                allowUnlimited:
                                                    !modalState?.allowUnlimited,
                                            });
                                        }}
                                    />
                                }
                                label="Allow to fetch more than 11000 rows"
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === 'timeZone'}
                        onChange={handleChange('timeZone')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className={classes.heading}>
                                Date &amp; Time Display Settings
                            </Typography>
                            <Typography className={classes.secondaryHeading}>
                                Change Date &amp; Time Display Settings
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container direction="column">
                                <Grid
                                    item
                                    style={{
                                        minWidth: 250,
                                        height: 30,
                                        marginLeft: 10,
                                    }}>
                                    {`Sample: ` +
                                        new Intl.DateTimeFormat(
                                            modalState?.timeZone.language ??
                                                'en-AU',
                                            {
                                                ...modalState?.timeZone,
                                            },
                                        ).format(
                                            new Date(
                                                '2021-01-01T05:28:11.8022439Z',
                                            ),
                                        )}
                                </Grid>
                                <Grid
                                    item
                                    style={{minWidth: 250, marginTop: 10}}>
                                    <LoadingFieldDropdown
                                        noEmptyOption
                                        fieldName="Timezone"
                                        staticEnum={timeZoneList}
                                        additional={[{timeZone: 'UTC'}]}
                                        displayField="timeZone"
                                        dropDownValue="timeZone"
                                        searchable
                                        onChange={(v) => {
                                            setModalState({
                                                ...modalState,
                                                timeZone: {
                                                    ...modalState?.timeZone,
                                                    timeZone: v,
                                                },
                                            });
                                        }}
                                        fieldValue={
                                            modalState?.timeZone.timeZone
                                        }
                                    />
                                </Grid>
                                <Grid item style={{minWidth: 250}}>
                                    <LoadingFieldDropdown
                                        noEmptyOption
                                        fieldName="Language"
                                        staticEnum={config.datetime.language}
                                        displayField="displayName"
                                        dropDownValue="id"
                                        searchable
                                        onChange={(v) => {
                                            setModalState({
                                                ...modalState,
                                                timeZone: {
                                                    ...modalState?.timeZone,
                                                    language: v,
                                                },
                                            });
                                        }}
                                        fieldValue={
                                            modalState?.timeZone.language
                                        }
                                    />
                                </Grid>
                                <Grid item style={{minWidth: 250}}>
                                    <LoadingFieldDropdown
                                        noEmptyOption
                                        fieldName="Day"
                                        staticEnum={config.datetime.weekday}
                                        displayField="value"
                                        dropDownValue="name"
                                        onChange={(v) => {
                                            setModalState({
                                                ...modalState,
                                                timeZone: {
                                                    ...modalState?.timeZone,
                                                    weekday: v,
                                                },
                                            });
                                        }}
                                        fieldValue={
                                            modalState?.timeZone.weekday
                                        }
                                    />
                                    <LoadingFieldDropdown
                                        noEmptyOption
                                        fieldName="Month"
                                        staticEnum={config.datetime.month}
                                        displayField="value"
                                        dropDownValue="name"
                                        onChange={(v) => {
                                            setModalState({
                                                ...modalState,
                                                timeZone: {
                                                    ...modalState?.timeZone,
                                                    month: v,
                                                },
                                            });
                                        }}
                                        fieldValue={modalState?.timeZone.month}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    style={{minWidth: 250}}
                                    justify="center">
                                    <LoadingFieldDropdown
                                        noEmptyOption
                                        fieldName="Hours"
                                        staticEnum={[
                                            {value: '12 Hours', name: 'h12'},
                                            {value: '24 Hours', name: 'h23'},
                                        ]}
                                        displayField="value"
                                        dropDownValue="name"
                                        onChange={(v) => {
                                            setModalState({
                                                ...modalState,
                                                timeZone: {
                                                    ...modalState?.timeZone,
                                                    hourCycle: v,
                                                    hour12: undefined,
                                                },
                                            });
                                        }}
                                        fieldValue={
                                            modalState?.timeZone.hourCycle
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Row>
                        <Col style={{textAlign: 'center', marginTop: 10}}>
                            <Button variant="primary" type="submit">
                                Save
                            </Button>
                        </Col>
                    </Row>
                </>
            )}
        </Form>
    );
}
