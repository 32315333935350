export const guidErrorStyles = {
    marginBottom: '150px',
    maxWidth: 600,
    width: '100%',
    shadow: 'md',
    padding: '20px',
    background: '#FFF',
    borderRadius: "10px"
};

export const guidErrorHeadingStyles = {
    textAlign: 'center',
    marginBottom: '25px',
};
