import Text from '../../Text';
import styles from './styles';

export default function ServiceProfileCardTitle({children, ...props}) {
    return (
        <Text sx={styles.title} {...props}>
            {children}
        </Text>
    );
}
