import React from 'react';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import Button from '../../../../components/v4/Button';
import {Box} from '@chakra-ui/react';
import MaterialTable from '../../../../components/v4/MaterialTable';
import Heading from '../../../../components/v4/Heading';
import {
    CloseIcon,
    CheckIcon,
    DownloadIcon,
    ArrowBackIcon,
} from '@chakra-ui/icons';

function useAPIResultsView({succeed, error}) {
    return {
        header: 'Import Result',
        suceedHeading: 'Successful',
        failedHeading: 'Failed',
        maxBodyHeight: 350,
        doneButtonText: 'Start Over',
        succeedData: succeed.map((res) => res.data),
        showSucceed: succeed.length > 0,
        showFailed: error.length > 0,
    };
}

const styles = {
    mainContainer: {display: 'block'},
    succeedContainer: {
        marginBottom: 10,
        width: '100%',
    },
    failedContainer: {
        width: '100%',
    },
    actionContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'left',
    },
};

export default function APIResultsView(props) {
    const {
        error,
        succeed,
        handleExportFailedData,
        handleDone,
        succeedAPIColumns,
        failedAPIColumns,
    } = props;
    const {
        suceedHeading,
        failedHeading,
        maxBodyHeight,
        doneButtonText,
        succeedData,
        header,
        showSucceed,
        showFailed,
    } = useAPIResultsView({succeed, error});

    return (
        <Box display={'block'}>
            <Heading size="xl">{header}</Heading>
            {showSucceed && (
                <Box sx={styles.succeedContainer}>
                    <MaterialTable
                        title={
                            <Heading size="lg">
                                {suceedHeading}
                                <CheckIcon
                                    color="green.500"
                                    boxSize="1rem"
                                    ml={3}
                                />
                            </Heading>
                        }
                        columns={succeedAPIColumns}
                        data={succeedData}
                        options={{
                            maxBodyHeight,
                        }}
                    />
                </Box>
            )}
            {showFailed && (
                <Box sx={styles.failedContainer}>
                    <MaterialTable
                        title={
                            <Heading size="lg">
                                {failedHeading}
                                <CloseIcon
                                    color="red.500"
                                    boxSize="1rem"
                                    ml={3}
                                />
                            </Heading>
                        }
                        columns={failedAPIColumns}
                        data={error}
                        actions={[
                            {
                                icon: DownloadRoundedIcon,
                                tooltip: 'Export to CSV',
                                isFreeAction: true,
                                onClick: () => handleExportFailedData(),
                            },
                        ]}
                        options={{
                            maxBodyHeight,
                            exportButton: false,
                        }}
                    />
                </Box>
            )}
            <Box mt={5} sx={styles.actionContainer}>
                <Button leftIcon={<ArrowBackIcon />} onClick={handleDone}>
                    {doneButtonText}
                </Button>
            </Box>
        </Box>
    );
}
