export default {
    table: {
        ufSearchInput: 'table/input/search',
        ufShowColumn: 'table/button/showColumn',
        ufExportButton: 'table/button/export',
        ufFilterButton: 'table/button/filter',
        ufRefreshButton: 'table/button/refresh',
        ufGroupButton: 'table/button/group',
        ufProfileTypeSelect: 'table/select/profileType',
    },
};
