import { axios } from '@/services/axios';
import { DeleteBox } from 'components/index';
import { Box, useDisclosure } from '@chakra-ui/react';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from 'components/v4';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import walkthroughIds from '../walkthroughIds';

export default function EditAdmin({ serviceCategory, serviceCategoryLoading }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isBusy, setIsBusy] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { ufClose, ufDelete } = walkthroughIds.serviceCategories;

    const handleDelete = async () => {
        try {
            setIsBusy(true);
            await axios.delete(`/servicecategory/${serviceCategory?.id}`);
            toast.success('Service Category deleted successfully!');
            dispatch({
                type: 'RESET_TABLE',
            });
            navigate('/service-categories');
        } catch (e) {
            toast.error(e?.response?.data?.message);
        } finally {
            setIsBusy(false);
        }
    };

    return (
        <>
            <Box
                sx={{ flexGrow: 1, paddingTop: '1%', paddingBlock: '1%' }}
                className="mx-auto min-w-[36rem] max-w-4xl">
                <DeleteBox
                    onClick={() => onOpen()}
                    isDisabled={serviceCategoryLoading}
                    deleteText={'Delete Service Category'}
                    buttonText={'Delete'}
                    warningText={
                        'Before deleting a service category, please ensure it is not in use by any service profiles or vendor defined services.'
                    }
                />
            </Box>

            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent minW={'30%'}>
                    <ModalHeader>
                        Delete Service Category: {serviceCategory?.name}
                    </ModalHeader>
                    <ModalBody>
                        <Alert status="warning">
                            <AlertIcon />
                            <AlertDescription>
                                Are you sure you would like to delete this
                                Service Category? This cannot be undone.
                            </AlertDescription>
                        </Alert>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            data-walkthroughid={ufClose}
                            colorScheme="messenger"
                            isDisabled={isBusy}
                            mr={3}
                            onClick={() => onClose()}>
                            Close
                        </Button>
                        <Button
                            data-walkthroughid={ufDelete}
                            isDisabled={serviceCategoryLoading}
                            variant="solid"
                            colorScheme="red"
                            isLoading={isBusy}
                            onClick={() => handleDelete()}>
                            Delete
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
