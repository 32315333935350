import React, { useRef } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import {useSelector, useDispatch} from 'react-redux';
import {Alert} from '@material-ui/lab';
import {TabPanel, IconButton} from '../../../components/';
import BillingForm, {WHOLESALER} from '../sharedComponents/BillingForm';
import TableAndModal, {
    modalTitle,
} from '../../../components/tables/TableAndModal';
import {CustomFieldsForm} from '../';
import config from '../../../config.json';
import WholesalerEdit from './edit/WholesalerEditForm';
import CouldNotDeletePartnerWS from '../sharedComponents/CouldNotDeletePartnerWS';
import DeletePartnerWS from '../sharedComponents/DeletePartnerWS';
import {CANNOT_DELETE_MODE} from 'components/tables/ReduxModal';
import {Button} from 'react-bootstrap';
import EntityCustomFieldsForm from '../sharedComponents/EntityCustomFieldsForm';

/**
 * Wholesaler Form when clicking the 'Wholesaler' Option in the GA tab of the sidebar
 */
export default function Index(props) {
    const dispatch = useDispatch();
    const tableRef = useRef();

    // view data from state
    const {modalTab} = useSelector((state) => {
        return {...state.view};
    });

    // modal data from state
    const {modalData, modalState, modalMode} = useSelector((state) => {
        return {
            modalLoading: state.modal.loading,
            modalData: state.modal.data,
            modalState: state.modal.state,
            modalMode: state.modal.mode,
            modalStateError: state.modal.stateError,
            modalUploading: state.modal.uploading,
        };
    });

    // function to simplify change handlers
    const setState = (payload) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload,
        });
    };

    // default columsn for the table
    const defaultColumns = [
        {
            title: 'Name',
            field: 'name',
        },
        {
            title: 'Default Plan',
            field: 'defaultWholesalerPlan.name',
            render: (rowData) => {
                if (!rowData.defaultWholesalerPlan) {
                    return (
                        <IconButton
                            placement="top"
                            tooltip="No Default Plan has been set. This wholesaler will not be billed."
                            Icon={
                                <WarningRoundedIcon
                                    style={{color: '#ff9800'}}
                                />
                            }
                        />
                    );
                }
                return rowData?.defaultWholesalerPlan?.name;
            },
        },
        ...config.table.custom,
        {
            title: "Service Countries",
            field: "serviceCountries",
            hiddenByColumnsButton: true,
            hidden: true
        }
    ];

    // function to render the modal content
    const renderModalContent = () => {
        switch (modalMode) {
            case CANNOT_DELETE_MODE:
                return <CouldNotDeletePartnerWS mode={WHOLESALER} />;
            case 'Delete':
                return (
                    <Alert style={{marginTop: 5}} severity="error">
                        Are you sure you want to delete {modalState?.name} ?
                        This will de-activate {modalState?.name} , set the
                        latest invoice to finalizing, and stop all future
                        billing.
                    </Alert>
                );
            case 'Edit':
                return (
                    <>
                        <Tabs
                            value={modalTab}
                            onChange={(e, i) => {
                                dispatch({
                                    type: 'CHANGE_MODAL_TAB_VIEW',
                                    payload: i,
                                });
                            }}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                            aria-label="full width tabs example">
                            <Tab label="General" />
                            <Tab label="Billing  &amp; Invoice" />
                            <Tab label="Custom" />
                            <Tab label="Admin" />
                        </Tabs>
                        <TabPanel value={modalTab} index={0}>
                            <WholesalerEdit setState={setState} />
                        </TabPanel>
                        <TabPanel value={modalTab} index={1}>
                            <BillingForm mode={WHOLESALER} />
                        </TabPanel>
                        <TabPanel value={modalTab} index={2}>
                            <EntityCustomFieldsForm mode={WHOLESALER} />
                        </TabPanel>
                        <TabPanel value={modalTab} index={3}>
                            <DeletePartnerWS mode={WHOLESALER} />
                        </TabPanel>
                    </>
                );
            default:
                return <WholesalerEdit setState={setState} />;
        }
    };

    // returns a Table and a Modal
    return (
        <TableAndModal
            tableRef={tableRef}
            newPage
            tableFetch={'/wholesalers/'}
            modalFetch={'/wholesaler/' + modalData?.id}
            modalDelete={'/wholesaler/' + modalData?.id}
            modalAdd="/wholesaler/"
            modalUpdate="/wholesaler/"
            onModalSucceed={
                modalMode === 'Add' &&
                function (result) {
                    dispatch({
                        type: 'OPEN_MODAL',
                        payload: {
                            mode: 'Edit',
                            data: result?.data,
                        },
                    });
                }
            }
            modalUpload={(freshData, state) => {
                if (state.trialEnd) {
                    return {
                        ...freshData,
                        ...state,
                        trialEnd: new Date(state?.trialEnd).toISOString(),
                    };
                }
                return {
                    ...freshData,
                    ...state,
                };
            }}
            title="Wholesalers"
            defaultColumns={defaultColumns}
            add
            groupable
            filterable
            rowActions={{
                editable: true,
            }}
            modalButtons={
                modalMode === CANNOT_DELETE_MODE && (
                    <Button
                        variant="danger"
                        disabled={modalState?.isNextButtonDisabled}
                        onClick={(e) => {
                            e.preventDefault();
                            dispatch({
                                type: 'OPEN_MODAL',
                                payload: {
                                    mode: 'Delete',
                                    uploading: false,
                                    loading: false,
                                    data: modalData,
                                    state: modalState,
                                },
                            });
                        }}>
                        Delete
                    </Button>
                )
            }
            modalValidate={() => {
                const newErrorState = {
                    name:
                        (!modalState?.name || modalState?.name?.length < 1) &&
                        'Field is required.',
                    attribute1:
                        modalState?.attribute1?.length > 100 &&
                        'Maximum field length is 100 characters. ',
                    attribute2:
                        modalState?.attribute2?.length > 100 &&
                        'Maximum field length is 100 characters. ',
                    attribute3:
                        modalState?.attribute3?.length > 100 &&
                        'Maximum field length is 100 characters. ',
                };
                dispatch({
                    type: 'CHANGE_MODAL_STATE_ERROR',
                    payload: newErrorState,
                });
                return !(
                    newErrorState.name ||
                    newErrorState.attribute1 ||
                    newErrorState.attribute2 ||
                    newErrorState.attribute3
                );
            }}
            maxBodyHeight={window.innerHeight * config.plainView.tableHeight}
            modalTitle={modalTitle(
                modalData,
                'name',
                ' Wholesalers ',
                modalMode === CANNOT_DELETE_MODE &&
                    !modalState?.isNextButtonDisabled
                    ? 'Delete'
                    : modalMode,
            )}
            modalContent={renderModalContent()}
        />
    );
}
