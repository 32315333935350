export const brandOptions = [
    {
        label: 'Select an option',
        value: 'Select an option',
    },
    {
        label: 'Yes - Partner branded',
        value: true,
    },
    {
        label: 'No - TCAP branded',
        value: false,
    },
];

export const albyDevApiKey = 'V8GKLC4AHMT1ZKJIHME82S4LLLA273DZLP0N';


