import { useEffect, useState } from 'react';
import {
    Box,
    FormErrorMessage,
    InputGroup,
    InputRightElement,
} from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';
import Heading from 'components/v4/Heading';
import useFieldValidation from 'hooks/useFieldValidation';
import {
    validationSchema,
    selectiveSyncOptions,
    autoApprovalOptions,
} from './consts';
import { FormControl, Stack, forwardRef } from '@chakra-ui/react';
import Input from 'components/v4/Input';
import FormLabel from 'components/v4/FormLabel';
import Button from 'components/v4/Button';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import createApprovalCode from 'API/ApprovalCode/createApprovalCode';
import { useSelector } from 'react-redux';
import { Select } from 'chakra-react-select';

import getPartnerPlans from 'API/Partner/getPartnerPlans';
import LoadingIndicator from 'components/v4/LoadingIndicator';

import Alert from 'components/v4/Alert';
import AlertDescription from 'components/v4/AlertDescription';
import AlertIcon from 'components/v4/AlertIcon';
import {
    ChakraDatePickerDarkModeStyles,
    ChakraDatePickerStyles,
} from '@/chakratheme';
import walkthroughIds from './walkthroughIds';
import { classNames } from 'utils/classNames';
import {
    chakraDatepicker,
    chakraDatepickerDarkMode,
    chakraSelectDarkMode,
    defaultChakraSelectStyle,
} from '@/constants';

const CRS = forwardRef((props, ref) => (
    <Select selectedOptionColor="brand" ref={ref} {...props} />
));
export default function ApprovalCodeCreate({
    setApprovalCodesAction,
    setSuccessAction,
}) {
    const [error, setError] = useState(null);
    const [partnerPlans, setPartnerPlans] = useState(null);
    const [validToTime, setValidToTime] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);
    const [fields, setFields] = useState({
        code: '',
        name: '',
        trialDays: 14,
        autoApprove: 1,
        validTo: '',
        selectiveSyncPolicy: 0,
        planID: '',
    });

    const { validationErrors, validatefn } = useFieldValidation(
        fields,
        validationSchema,
    );

    const currentPartner = useSelector(
        (state) => state.navigationLists?.currentPartner,
    );

    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);
    const chakraStyles = {
        ...defaultChakraSelectStyle,
        ...chakraSelectDark,
    };

    const { createMode } = walkthroughIds;

    async function create() {
        setIsLoading(true);
        setError(null);
        setSuccessAction(null);

        // Validation
        if (!validatefn()) {
            setIsLoading(false);
            return;
        }

        // Hidden fields
        const hiddenFields = {
            partnerID: currentPartner,
        };

        // Create approval code
        createApprovalCode({ ...fields, ...hiddenFields })
            .then((response) => {
                if (response.status === 200) {
                    setSuccessAction('Create');
                    setApprovalCodesAction('Get');
                    setTimeout(() => {
                        setSuccessAction(null);
                    }, 6000);
                } else {
                    setError(response.response.data.message);
                }
            })
            .catch((error) => {
                if (error?.resposne?.data?.message) {
                    setError(error.resposne.data.message);
                } else {
                    setError(
                        'Something went wrong when creating an approval code.',
                    );
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function updateFields(key, value) {
        setFields((p) => ({ ...p, [key]: value }));
    }

    async function _getPartnerPlans() {
        getPartnerPlans(currentPartner)
            .then((response) => {
                const data = response.data.map((plan) => ({
                    label: plan.name,
                    value: plan.id,
                }));
                setPartnerPlans(data);
            })
            .catch((error) => {
                console.error(error.resposne);
            })
            .finally(() => {});
    }

    function setInitial90DayDate() {
        var nowPlus90Days = new Date();
        nowPlus90Days.setDate(nowPlus90Days.getDate() + 90);
        updateFields('validTo', nowPlus90Days.toISOString()); // for backend submission
        setValidToTime(nowPlus90Days); // for UI displaying
    }

    useEffect(() => {
        _getPartnerPlans();
        setInitial90DayDate();
    }, []);

    if (!partnerPlans) return <LoadingIndicator />;

    return (
        <Box sx={{ maxW: '4xl', mx: 'auto' }}>
            <Stack spacing={'25px'}>
                {/* Name */}
                <FormControl isInvalid={validationErrors?.name}>
                    <FormLabel>Name</FormLabel>
                    <Input
                        value={fields.name}
                        onChange={(e) => updateFields('name', e.target.value)}
                        data-walkthroughid={createMode.ufNameInput}
                    />
                    <FormErrorMessage>
                        {validationErrors?.name}
                    </FormErrorMessage>
                </FormControl>

                {/* Plan */}
                <FormControl isInvalid={validationErrors?.planID}>
                    <FormLabel>Customer Default Plan</FormLabel>
                    <CRS
                        value={partnerPlans.find(
                            (plan) => plan.planID === fields.planID,
                        )}
                        onChange={(e) => updateFields('planID', e.value)}
                        placeholder="- Select Partner Plan -"
                        options={partnerPlans}
                        data-walkthroughid={createMode.ufDefaultPlanSelect}
                        chakraStyles={chakraStyles}
                    />
                    <FormErrorMessage>
                        {validationErrors?.planID}
                    </FormErrorMessage>
                </FormControl>

                {/* Selective Sync Policy */}
                <FormControl isInvalid={validationErrors?.selectiveSyncPolicy}>
                    <FormLabel>Selective Sync Policy</FormLabel>
                    <CRS
                        value={selectiveSyncOptions.find(
                            (op) => op.value === fields.selectiveSyncPolicy,
                        )}
                        onChange={(e) =>
                            updateFields('selectiveSyncPolicy', e.value)
                        }
                        options={selectiveSyncOptions}
                        data-walkthroughid={createMode.ufSelectiveSyncSelect}
                        chakraStyles={chakraStyles}
                    />
                    <FormErrorMessage>
                        {validationErrors?.selectiveSyncPolicy}
                    </FormErrorMessage>
                </FormControl>

                {/* Code */}
                <FormControl isInvalid={validationErrors?.code}>
                    <FormLabel>Code</FormLabel>
                    <Input
                        value={fields.code}
                        onChange={(e) => updateFields('code', e.target.value)}
                        data-walkthroughid={createMode.ufCodeInput}
                    />
                    <FormErrorMessage>
                        {validationErrors?.code}
                    </FormErrorMessage>
                </FormControl>

                {/* Auto Approve */}
                <FormControl isInvalid={validationErrors?.autoApprove}>
                    <FormLabel>Auto Approval</FormLabel>
                    <CRS
                        value={autoApprovalOptions.find(
                            (op) => op.value === fields.autoApprove,
                        )}
                        onChange={(e) => updateFields('autoApprove', e.value)}
                        options={autoApprovalOptions}
                        data-walkthroughid={createMode.ufAutoApproveSelect}
                        chakraStyles={chakraStyles}
                    />
                    <FormErrorMessage>
                        {validationErrors?.autoApprove}
                    </FormErrorMessage>
                </FormControl>

                {/* Trial Days */}
                <FormControl isInvalid={validationErrors?.trialDays}>
                    <FormLabel>Trial Duration (days)</FormLabel>
                    <Input
                        type="number"
                        value={fields.trialDays}
                        onChange={(e) =>
                            updateFields('trialDays', e.target.value)
                        }
                        data-walkthroughid={createMode.ufTrialDurationInput}
                    />
                    <FormErrorMessage>
                        {validationErrors?.trialDays}
                    </FormErrorMessage>
                </FormControl>

                {/* Valid To */}
                <FormControl isInvalid={validationErrors?.validTo}>
                    <FormLabel>Approval Code Expiry Date</FormLabel>
                    <InputGroup
                        data-walkthroughid={createMode.ufApprovalExpiryDate}
                        sx={
                            darkMode
                                ? chakraDatepickerDarkMode
                                : chakraDatepicker
                        }>
                        <SingleDatepicker
                            configs={{
                                dateFormat: 'dd-MM-yyyy',
                            }}
                            date={validToTime}
                            onDateChange={(value) => {
                                updateFields(
                                    'validTo',
                                    new Date(value).toISOString(),
                                );
                                setValidToTime(value);
                            }}
                            propsConfigs={
                                darkMode
                                    ? ChakraDatePickerDarkModeStyles
                                    : ChakraDatePickerStyles
                            }
                            className="chakra-datepicker"
                        />
                        <InputRightElement
                            color={darkMode ? 'white' : 'gray.500'}
                            pointerEvents="none"
                            children={<CalendarIcon cursor="pointer" />}
                        />
                    </InputGroup>
                    <FormErrorMessage>
                        {validationErrors?.validTo}
                    </FormErrorMessage>
                </FormControl>

                {/* Submit */}
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant="outline"
                        className="secondary hover:opacity-80"
                        sx={{ marginRight: '1.25rem' }}
                        onClick={() => {
                            setApprovalCodesAction('Get');
                        }}
                        isDisabled={isLoading}
                        data-walkthroughid={createMode.ufBackBtn}>
                        Back
                    </Button>
                    <Button
                        onClick={create}
                        isLoading={isLoading}
                        data-walkthroughid={createMode.ufCreateBtn}>
                        Create
                    </Button>
                </Box>

                {/* Error */}
                {error && (
                    <Alert status="error" sx={{ margin: '25px 0' }}>
                        <AlertIcon />
                        <AlertDescription>{error}</AlertDescription>
                    </Alert>
                )}
            </Stack>
        </Box>
    );
}
