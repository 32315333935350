import React from 'react';
import {TextField} from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import {Row, Col} from 'react-bootstrap';
import {
    NegativeSixDP,
    NonNegativeInteger,
} from 'components/maintenance/NumberFormatCustom';
import {LoadingFieldDropdown} from 'components/index';
import {Alert} from '@material-ui/lab';
import walkthroughIds from '../walkthroughIds';

const ITEM_CODE = [
    {value: 'CDR', label: 'CDR'},
    {value: 'Company', label: 'Company'},
    {value: 'NumberBlock', label: 'Number Block'},
    {value: 'NumberBlockPorting', label: 'Number Block Porting'},
    {value: 'RGS', label: 'RGS'},
    {value: 'TeamsUser', label: 'Teams User'},
    {value: 'Trunk', label: 'Trunk'},
];

/*
 * Component for the modal content of the invoice lines component
 */
const InvoiceLineEdit = () => {
    const dispatch = useDispatch();

    // modal state
    const {state: modalState, mode: modalMode} = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    const {invoiceLineEdit} = walkthroughIds;

    return (
        <>
            <Row style={{minHeight: 60}}>
                <Col>
                    <TextField
                        disabled={modalMode === 'Delete'}
                        fullWidth
                        label="Quantity"
                        type="text"
                        InputProps={{
                            inputComponent: NonNegativeInteger,
                        }}
                        value={modalState?.quantity}
                        onChange={(e) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    quantity: e.target.value,
                                },
                            });
                        }}
                        data-walkthroughid={invoiceLineEdit.ufQuantityInput}
                    />
                </Col>
            </Row>
            <Row style={{minHeight: 60}}>
                <Col>
                    <TextField
                        disabled={modalMode === 'Delete'}
                        fullWidth
                        label="Unit Price"
                        type="text"
                        InputProps={{
                            inputComponent: NegativeSixDP,
                        }}
                        value={modalState?.unitPrice}
                        onChange={(e) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    unitPrice: e.target.value,
                                },
                            });
                        }}
                        data-walkthroughid={invoiceLineEdit.ufUnitPriceInput}
                    />
                </Col>
            </Row>
            <Row style={{minHeight: 60}}>
                <Col>
                    <TextField
                        disabled={modalMode === 'Delete'}
                        fullWidth
                        label="Description"
                        type="text"
                        value={modalState?.description}
                        onChange={(e) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    description: e.target.value,
                                },
                            });
                        }}
                        data-walkthroughid={invoiceLineEdit.ufDescriptionInput}
                    />
                </Col>
            </Row>
            {modalMode !== 'Edit' && (
                <Row style={{minHeight: 60}}>
                    <Col>
                        <LoadingFieldDropdown
                            disabled={modalMode === 'Delete'}
                            staticEnum={ITEM_CODE}
                            fieldName="Item Code"
                            dropDownValue={'value'}
                            displayField={'label'}
                            fieldValue={modalState?.itemCode}
                            noEmptyOption
                            onChange={(v) => {
                                dispatch({
                                    type: 'CHANGE_MODAL_STATE',
                                    payload: {
                                        itemCode: v,
                                    },
                                });
                            }}
                            dataWalkthroughid={invoiceLineEdit.ufModalItemCode}
                        />
                    </Col>
                </Row>
            )}
            {modalMode === 'Delete' && (
                <Alert style={{marginTop: 5}} severity="error">
                    Are you sure you want to delete this invoice line ? This
                    action cannot be undone.
                </Alert>
            )}
        </>
    );
};

export default InvoiceLineEdit;
