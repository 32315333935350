import React, { useState, useEffect, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form, Container, Modal, Button as BsButton } from 'react-bootstrap';

import Error from '../maintenance/Error';
import walkthroughIds from './walkthroughIds';

const Button = forwardRef((props, ref) => (
    <BsButton className="min-w-[5rem]" ref={ref} {...props} />
));

const EditForm = (props) => {
    const dispatch = useDispatch();
    const [errorState, setErrorState] = useState(true);
    const {
        modalContent,
        modalFooter,
        modalTitle,
        modalButtons,
        noBackButton,
        noSaveButton,
    } = props;

    // state access
    const { loading, restError, uploading, mode } = useSelector((state) => {
        return { ...state.modal };
    });

    const { stateUrl, modalMode } = useSelector((state) => {
        return {
            stateUrl: state.url,
            modalMode: state.modal.mode,
        };
    });

    // NOTE: hot fix for add number blocks page
    const isAddNumberBlockPage =
        stateUrl.pathname.includes('numberblocks') && modalMode === 'Add';

    const { ufBackBtn, ufSaveChangesBtn, ufCreateBtn, ufCopyBtn, ufDeleteBtn } =
        walkthroughIds;

    // dispatches the on_modal_submit action
    const onModalSubmit = () => {
        dispatch({ type: 'ON_MODAL_SUBMIT', payload: true });
    };

    // returns a specific button based on modal state
    const submitButton = () => {
        if (modalButtons) {
            return { ...modalButtons };
        }
        switch (mode) {
            case 'Delete':
                return (
                    <Button
                        variant="danger"
                        type="submit"
                        data-walkthroughid={ufDeleteBtn}>
                        Delete
                    </Button>
                );
            case 'Copy':
                return (
                    <Button
                        variant="primary"
                        type="submit"
                        data-walkthroughid={ufCopyBtn}>
                        Copy
                    </Button>
                );
            case 'Add':
                return (
                    <Button
                        variant="primary"
                        type="submit"
                        data-walkthroughid={ufCreateBtn}>
                        Create
                    </Button>
                );
            default:
                return (
                    <Button
                        variant="primary"
                        type="submit"
                        data-walkthroughid={ufSaveChangesBtn}>
                        Save
                    </Button>
                );
        }
    };

    // resets the modal
    const onHide = () => {
        dispatch({
            type: 'RESET_MODAL',
        });
    };

    // sets error state
    useEffect(() => {
        if (restError?.name === undefined) {
            setErrorState(false);
        } else {
            setErrorState(true);
        }
    }, [restError, setErrorState]);

    /*
     * Render a container that has a form with a back and submit button
     * Render whatever modal content/title/footer exists in the props
     */
    return (
        <Container fluid>
            <Modal.Title> {modalTitle} </Modal.Title>
            {errorState && (
                <Error error={restError} style={{ marginBottom: 10 }} />
            )}
            <Form
                style={{ paddingTop: '1%', paddingBottom: '1%' }}
                onSubmit={(e) => {
                    e.preventDefault();
                    onModalSubmit();
                }}>
                {!loading && !uploading ? (
                    <>
                        {modalContent}
                        {modalFooter && (
                            <div
                                style={{
                                    border: 'none',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    marginTop: 10,
                                    marginBottom: 10,
                                    width: '500px !important',
                                }}>
                                {modalFooter}
                            </div>
                        )}

                        {!isAddNumberBlockPage && (
                            <div
                                className="edit-form-buttons"
                                style={{
                                    border: 'none',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    marginTop: 16,
                                    marginBottom: 10,
                                }}>
                                {!noBackButton && (
                                    <Button
                                        variant="secondary"
                                        onClick={onHide}
                                        data-walkthroughid={ufBackBtn}>
                                        Back
                                    </Button>
                                )}
                                {!noSaveButton && <div
                                    style={{
                                        marginLeft: 24,
                                        display: 'inline',
                                    }}>
                                    {submitButton()}
                                </div>}
                            </div>
                        )}
                    </>
                ) : (
                    <CircularProgress
                        style={{ marginTop: '2.5%', marginLeft: '50%' }}
                    />
                )}
            </Form>
        </Container>
    );
};

export default EditForm;
