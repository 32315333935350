// eslint-disable-next-line import/no-anonymous-default-export
// NOTE: legacy walkthroughid
export default {
    editNumbersGeneral: {
        tab: '/numbers/tab',
        outboundCallerId: 'numbers-general/select/1',
        trunk: 'numbers-general/select/2',
        address: 'numbers-general/select/3',
        country: 'numbers-general/select/4',
        callingEnabled: 'numbers-general/checkbox/1',
        wholesaler: 'numbers-general/input/1',
        partner: 'numbers-general/input/2',
        company: 'numbers-general/input/3',
        userId: 'numbers-general/input/4',
        countryInput: 'numbers-general/input/4',

        operatorConnectCallingProfile: 'numbers-general/select/4',
        operatorConnectTelephoneNumberUsage: 'numbers-general/select/5',

        status: 'numbers-general/checkbox/2',
        actionButton: 'numbers-general/button/1',
        overStampNumber: 'numbers-general/input/5',
        overrideOutboundCallerId: 'numbers-general/input/6',
    },

    requestAvailableBlock: {
        addButton: 'numbers-requestAvailableBlock/table/button/1',
        firstNumber: 'numbers-requestAvailableBlock/input/1',
        blockSize: 'numbers-requestAvailableBlock/input/2',
        requestBlock: 'number-requestAvailableBlock/button/1',
    },

    requestNewBlock: {
        contactName: 'numbers-requestNewBlock/input/1',
        contactPhone: 'numbers-requestNewBlock/input/2',
        contactEmail: 'numbers-requestNewBlock/input/3',
        blockSize: 'numbers-requestNewBlock/select/1',
        quantity: 'numbers-requestNewBlock/select/2',
        country: 'numbers-requestNewBlock/input/4',
        region: 'numbers-requestNewBlock/input/5',
        street: 'numbers-requestNewBlock/input/6',
        suburb: 'numbers-requestNewBlock/input/7',
        state: 'numbers-requestNewBlock/input/8',
        postcode: 'numbers-requestNewBlock/input/9',
        comments: 'numbers-requestNewBlock/textarea/1',
    },

    bringExistingNumber: {
        carrier: 'numbers-bringExistingNumbers/input/1',
        fileUpload: 'numbers-bringExistingNumbers/input/2',
        companyRegistrationNumber: 'numbers-bringExistingNumbers/input/3',
        preferredPortingDate: 'numbers-bringExistingNumbers/input/4',
        authorisedSignerFullName: 'numbers-bringExistingNumbers/input/5',
        authorisedSignerEmail: 'numbers-bringExistingNumbers/input/6',
        authorisedSignerContact: 'numbers-bringExistingNumbers/input/7',
        numbersPortFrom: 'numbers-bringExistingNumbers/input/8',
        numbersPortTo: 'numbers-bringExistingNumbers/input/9',
        preloadNumbers: 'numbers-bringExistingNumbers/checkbox/1',
        comments: 'numbers-bringExistingNumbers/textarea/1',
        agreement: 'numbers-bringExistingNumbers/checkbox/2',
    },
};
