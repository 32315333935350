import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { axios } from '@/services/axios';
import extractAccessList from '@/utils/accessList';
import { DEFAULT_COLOR } from 'context/LayoutContext';

export function useAccessList() {
    const {
        id: adminID,
        loadAccessList,
        defaultCompany,
        defaultPartner,
        defaultWholesaler,
    } = useSelector((state) => state.login);

    const { currentWholesaler } = useSelector((state) => state.navigationLists);
    const dispatch = useDispatch();

    const extractAdminUser = (result) => {
        dispatch({
            type: 'LOGIN_SUCCESS',
            payload: {
                ...result.data,
                // companyID: null,
            },
        });
    };

    //1 way update. if backend is empty, ask to initiate settings (i.e. redirect to settings page)
    //otherwise update redux.
    const extractAdminUserSettings = (result) => {
        var update = 'NONE';
        var backendSettings = null;
        try {
            backendSettings = JSON.parse(result.data);
            update = 'BACKEND_NOT_EMPTY';
        } catch (e) {
            update = 'EMPTY_BACKEND';
        } finally {
            //if you want it 1 way, change this "BACKEND" to redirect to settings page, and tell them to fill it in.
            if (update === 'EMPTY_BACKEND') {
                // TODO: redirect to settings page, ask to set everything
            } else {
                dispatch({
                    type: 'CHANGE_ALL_SETTINGS',
                    payload: backendSettings,
                });
                dispatch({
                    type: 'SAVE_USER_SETTINGS',
                    payload: {
                        loginColorScheme:
                            backendSettings?.colorScheme || DEFAULT_COLOR,
                    },
                });
            }
        }
    };

    /**
     * If we come back to the app, ie in a new tab, let's re-load
     * all the set data to resume the session.
     * known bug: you dont want to do this, if still logging in,
     * needed as feature:  you want to do this when open a new tab
     */
    useEffect(() => {
        //by default redirect to homepage.
        if (
            !loadAccessList && //login process finished
            (defaultPartner || defaultCompany || defaultWholesaler) && // we have default data
            !currentWholesaler //currently managing dropdown not set
        ) {
            //if current stuffs arent set yet, set them to default.
            dispatch({
                type: 'CHANGE_CURRENT_PARTNER',
                payload: defaultPartner,
            });
            dispatch({
                type: 'CHANGE_CURRENT_COMPANY',
                payload: defaultCompany,
            });
            dispatch({
                type: 'CHANGE_CURRENT_WHOLESALER',
                payload: defaultWholesaler,
            });
        }
    }, [
        // defaultPartner,
        currentWholesaler,
        // defaultCompany,
        // defaultWholesaler,
        // loadAccessList,
    ]);

    const getAccessList = useCallback(async () => {
        try {
            const res = await Promise.all([
                axios.get('/accesslist', {
                    params: { host: window.location.host },
                }),

                axios.get('/adminuser/uisettings'),
            ]);
            extractAdminUserSettings(res[1]);
            extractAccessList(res[0], dispatch);

            if (!adminID) {
                const result = await axios.get(`/adminuser/me`);
                extractAdminUser(result);
            }
        } catch (e) {
            dispatch({
                type: 'ACCESS_LIST_ERROR',
                payload: e,
            });
            if (e?.name?.includes('401')) {
                window.alert(
                    'Unable to log in - could not find any permissions.',
                );
                dispatch({ type: 'LOGOUT' });
                return;
            }
        }
    }, [adminID]);

    return { getAccessList };
}
