import { NOT_ALLOCATED, ACTIONS } from '../constants.js';

function getFieldError(value) {
    if (!value) return 'Field is required!';

    return null;
}

export const reducerValidationFn = (state, action) => {
    switch (action.type) {
        case ACTIONS.VALIDATE_VOICE_POLICY: {
            return {
                ...state,
                voicePolicyError: getFieldError(action.voicePolicyID),
            };
        }

        case ACTIONS.VALIDATE_DIAL_PLAN: {
            return {
                ...state,
                dialPlanError: getFieldError(action.dialPlanID),
            };
        }
    }
};

export const reducerFn = (state, action) => {
    switch (action.type) {
        case ACTIONS.CHANGE_DIAL_PLAN_ID: {
            return {
                ...state,
                dialPlanID: action.dialPlanID,
            };
        }

        case ACTIONS.CHANGE_NUMBER_ID: {
            if (!action?.numberID) {
                return {
                    ...state,
                    numberID: null,
                    planID: null,
                    telephoneNumber: null,
                    voicePolicyID: null,
                    dialPlanID: null,
                    isDrNumber: false,
                };
            }

            return {
                ...state,
                numberID: action.numberID,
                isDrNumber: action.isDrNumber,
            };
        }
        case ACTIONS.CHANGE_VOICE_POLICY_ID: {
            return {
                ...state,
                voicePolicyID: action.voicePolicyID,
            };
        }

        case ACTIONS.CHANGE_MANAGED_BY: {
            return {
                ...state,
                managedBy: action.managedBy,
            };
        }

        case ACTIONS.CHANGE_USER_PLAN_MODE: {
            return {
                ...state,
                planID: action.planID,
            };
        }

        case ACTIONS.SET_NUMBER: {
            return {
                ...state,
                number: action.payload.number,
                isDrNumber: action.payload.isDrNumber,
            };
        }

        case ACTIONS.SET_USER_PLAN_MODE: {
            return {
                ...state,
                userPlanMode: action.userPlanMode,
            };
        }

        case ACTIONS.SUBMIT_FORM: {
            return {
                ...state,
                formData: action.payload,
                formStatus: 'pending',
            };
        }

        case ACTIONS.SUBMIT_FORM_SUCCESS: {
            return {
                ...state,
                formStatus: 'success',
            };
        }

        case ACTIONS.SUBMIT_FORM_FAILURE: {
            return {
                ...state,
                formStatus: 'error',
                message: action.message,
            };
        }

        case ACTIONS.RESET_FORM_STATUS: {
            return {
                ...state,
                formStatus: 'idle',
            };
        }

        case ACTIONS.SET_NUMBER_LIST: {
            return {
                ...state,
                numberList: [
                    {
                        id: null,
                        telephoneNumber: NOT_ALLOCATED,
                    },
                    ...action.payload,
                ],
            };
        }

        case ACTIONS.SET_DR_VOICE_POLICIES: {
            return {
                ...state,
                voicePolicies: [
                    {
                        id: null,
                        name: NOT_ALLOCATED,
                    },
                    ...action.payload,
                ],
            };
        }

        case ACTIONS.SET_DR_DIAL_PLANS: {
            return {
                ...state,
                dialPlans: [
                    {
                        id: null,
                        name: NOT_ALLOCATED,
                    },
                    ...action.payload,
                ],
            };
        }

        case ACTIONS.INIT: {
            return {
                ...state,
                ...action.payload,
            };
        }

        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
};
