import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@chakra-ui/react';

import config from '../../../../config.json';
import { PictureViewer } from '../../../../components';
import { NonNegativeInteger } from '../../../../components/maintenance/NumberFormatCustom';
import Button from 'components/v4/Button';
import FormLabel from 'components/v4/FormLabel';
import { AttachmentIcon } from '@chakra-ui/icons';

// Component for the Add and Edit page of Templates - child of AATemplateTable.jsx
const TemplateEditForm = (props) => {
    const dispatch = useDispatch();
    const { state: modalState, stateError: modalStateError } = useSelector(
        (state) => {
            return {
                ...state.modal,
            };
        },
    );

    const imageChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                newImage: e.target.files[0],
            },
        });
    };

    const nameChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                name: e.target.value,
            },
        });
    };

    const idChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                id: e.target.value,
            },
        });
    };

    return (
        <Box
            sx={{ flexGrow: 1, paddingTop: '1%', paddingBlock: '1%' }}
            className="min-w=-[36rem] mx-auto max-w-4xl">
            <Row style={{ minHeight: 60 }}>
                <Col>
                    <TextField
                        fullWidth
                        label="ID"
                        type="text"
                        value={modalState?.id}
                        error={Boolean(modalStateError?.id)}
                        InputProps={{ inputComponent: NonNegativeInteger }}
                        helperText={modalStateError?.id}
                        onChange={idChangeHandler}
                    />
                </Col>
                <Col>
                    <TextField
                        fullWidth
                        label="Name"
                        type="text"
                        value={modalState?.name}
                        error={Boolean(modalStateError?.name)}
                        helperText={modalStateError?.name}
                        onChange={nameChangeHandler}
                    />
                </Col>
                <Col>
                    <FormControl
                        fullWidth
                        error={Boolean(modalStateError?.image)}>
                        <Button
                            as={FormLabel}
                            sx={{ marginY: '5px', width: '100%' }}
                            className="hover:cursor-pointer">
                            <AttachmentIcon mr={2} />{' '}
                            <p className="min-w-0 truncate">
                                {!modalState?.newImage
                                    ? 'Choose Image'
                                    : modalState?.newImage?.name}
                                <input
                                    style={{ display: 'none' }}
                                    type="file"
                                    id="image-input"
                                    accept="image/png"
                                    onChange={imageChangeHandler}
                                />
                            </p>
                        </Button>

                        {modalStateError?.newImage && (
                            <FormHelperText
                                error={Boolean(modalStateError?.newImage)}>
                                {modalStateError?.newImage}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Col>
            </Row>
            {modalState?.newImage ? (
                <PictureViewer
                    imageURL={URL.createObjectURL(modalState?.newImage)}
                />
            ) : modalState?.image ? (
                <PictureViewer
                    imageURL={config.imageBase + '/images/' + modalState?.image}
                />
            ) : null}
        </Box>
    );
};

export default TemplateEditForm;
