import { Box, HStack, Image, Spinner, Text, Tooltip } from '@chakra-ui/react';

import ServiceProfileCard from 'components/v4/Cards/ServiceProfileCard/ServiceProfileCard';
import ServiceProfileCardImage from 'components/v4/Cards/ServiceProfileCard/ServiceProfileCardImage';
import ServiceProfileCardTitle from 'components/v4/Cards/ServiceProfileCard/ServiceProfileCardTitle';

import msTeamsLogo from 'assets/images/ms_teams_logo.png';
import callLogo from 'assets/images/call.png';
import walkthroughIds from 'pages/services/walkthroughIds';
import { useSelector } from 'react-redux';

function OcService({ serviceProfile, serviceWizard, id }) {
    const { ufNewServiceProfileCard } = walkthroughIds.dashboardServices;
    const { currentPartner } = useSelector((state) => state.navigationLists);
    return (
        <ServiceProfileCard
            pointerEvents={serviceWizard.isCreatingService ? 'none' : 'auto'}
            onClick={(e) => serviceWizard.createService(serviceProfile)}
            key={id}
            data-walkthroughid={`${ufNewServiceProfileCard}/${id}`}>
            {serviceWizard.isCreatingService && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        left: '0',
                        top: '0',
                        background: 'white',
                        opacity: '0.7',
                    }}>
                    {serviceWizard.selectedServiceProfile.serviceType ===
                        'OperatorConnect' && (
                        <Spinner
                            thickness="4px"
                            emptyColor="gray.200"
                            color="brand.500"
                            size="xl"
                        />
                    )}
                </Box>
            )}
            <ServiceProfileCardImage src={msTeamsLogo} />
            <ServiceProfileCardTitle>Operator Connect</ServiceProfileCardTitle>
            <Text>
                Authorize your Microsoft Teams tenancy to connect using Operator
                Connect API endpoints. This is only available to approved
                partners.
            </Text>
            {currentPartner == '100d5296-0c38-4192-1c43-08dbec03eb47' && (
                <HStack mt={'auto'}>
                    <Tooltip label="Microsoft">
                        <Image boxSize={'5'} src={msTeamsLogo} />
                    </Tooltip>
                    <Tooltip label="Fixed Line Calling">
                        <Image boxSize={'5'} src={callLogo} />
                    </Tooltip>
                </HStack>
            )}
        </ServiceProfileCard>
    );
}

export default OcService;
