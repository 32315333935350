import { useEffect, useState } from 'react';
import {
    Box,
    FormErrorMessage,
    InputGroup,
    InputRightElement,
} from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';
import Heading from 'components/v4/Heading';
import useFieldValidation from 'hooks/useFieldValidation';
import {
    validationSchema,
    selectiveSyncOptions,
    autoApprovalOptions,
} from './consts';
import { FormControl, Stack, forwardRef } from '@chakra-ui/react';
import Input from 'components/v4/Input';
import FormLabel from 'components/v4/FormLabel';
import Button from 'components/v4/Button';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { Select } from 'chakra-react-select';
import updateApprovalCode from 'API/ApprovalCode/updateApprovalCode';
import { useSelector } from 'react-redux';
import Alert from 'components/v4/Alert';
import AlertDescription from 'components/v4/AlertDescription';
import AlertIcon from 'components/v4/AlertIcon';
import getPartnerPlans from 'API/Partner/getPartnerPlans';
import LoadingIndicator from 'components/v4/LoadingIndicator';
import {
    chakraDatepicker,
    chakraDatepickerDarkMode,
    chakraSelectDarkMode,
    defaultChakraSelectStyle,
} from '@/constants';
import {
    ChakraDatePickerStyles,
    ChakraDatePickerDarkModeStyles,
} from '@/chakratheme';

const CRS = forwardRef((props, ref) => (
    <Select selectedOptionColor="brand" ref={ref} {...props} />
));

export default function ApprovalCodeEdit({
    rowData,
    setApprovalCodesAction,
    setSuccessAction,
}) {
    const [partnerPlans, setPartnerPlans] = useState(null);
    const [validToTime, setValidToTime] = useState(new Date());
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [fields, setFields] = useState({
        code: '',
        name: '',
        trialDays: '',
        autoApprove: 0,
        validTo: new Date().toISOString(),
        selectiveSyncPolicy: 0,
        planID: '',
    });

    const { validationErrors, validatefn } = useFieldValidation(
        fields,
        validationSchema,
    );

    const currentPartner = useSelector(
        (state) => state.navigationLists?.currentPartner,
    );

    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);
    const chakraStyles = {
        ...defaultChakraSelectStyle,
        ...chakraSelectDark,
    };

    async function update() {
        setIsLoading(true);
        setError(null);
        setSuccessAction(null);

        // Validation
        if (!validatefn()) {
            setIsLoading(false);
            return;
        }

        // Hidden fields
        const hiddenFields = {
            id: rowData.id,
            partnerID: currentPartner,
        };

        // Create approval code
        updateApprovalCode({ ...fields, ...hiddenFields })
            .then((response) => {
                if (response.status === 200) {
                    setSuccessAction('Edit');
                    setApprovalCodesAction('Get');
                    setTimeout(() => {
                        setSuccessAction(null);
                    }, 6000);
                } else {
                    setError(response.response.data.message);
                }
            })
            .catch((error) => {
                if (error?.response?.data?.message) {
                    setError(error.response.data.message);
                } else {
                    setError(
                        'Something went wrong when editing this approval code.',
                    );
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function updateFields(key, value) {
        setFields((p) => ({ ...p, [key]: value }));
    }

    async function _getPartnerPlans() {
        getPartnerPlans(currentPartner)
            .then((response) => {
                const data = response.data.map((plan) => ({
                    label: plan.name,
                    value: plan.id,
                }));
                setPartnerPlans(data);
            })
            .catch((error) => {
                console.log(error.response);
            })
            .finally(() => {});
    }

    function setEditData() {
        if (rowData) {
            setFields({
                code: rowData.code,
                name: rowData.name,
                trialDays: rowData.trialDays,
                autoApprove: rowData.autoApprove,
                validTo: rowData.validTo,
                selectiveSyncPolicy: rowData.selectiveSyncPolicy,
                planID: rowData.planID,
            });
            setValidToTime(new Date(rowData.validTo));
        }
    }

    useEffect(() => {
        _getPartnerPlans();
        setEditData();
    }, []);

    if (!partnerPlans) return <LoadingIndicator />;

    return (
        <Box sx={{ maxW: '4xl', mx: 'auto' }}>
            <Stack spacing={'25px'}>
                {/* Name */}
                <FormControl isInvalid={validationErrors?.name}>
                    <FormLabel>Name</FormLabel>
                    <Input
                        value={fields.name}
                        onChange={(e) => updateFields('name', e.target.value)}
                    />
                    <FormErrorMessage>
                        {validationErrors?.name}
                    </FormErrorMessage>
                </FormControl>

                {/* Plan */}
                <FormControl isInvalid={validationErrors?.planID}>
                    <FormLabel>Customer Default Plan</FormLabel>
                    <CRS
                        value={partnerPlans.find(
                            (op) => op.value === fields.planID,
                        )}
                        onChange={(e) => updateFields('planID', e.value)}
                        placeholder="- Select Partner Plan -"
                        options={partnerPlans}
                        chakraStyles={chakraStyles}
                    />
                    <FormErrorMessage>
                        {validationErrors?.planID}
                    </FormErrorMessage>
                </FormControl>

                {/* Selective Sync Policy */}
                <FormControl isInvalid={validationErrors?.selectiveSyncPolicy}>
                    <FormLabel>Selective Sync Policy</FormLabel>
                    <CRS
                        value={selectiveSyncOptions.find(
                            (op) => op.value === fields.selectiveSyncPolicy,
                        )}
                        onChange={(e) =>
                            updateFields('selectiveSyncPolicy', e.value)
                        }
                        options={selectiveSyncOptions}
                        chakraStyles={chakraStyles}
                    />
                    <FormErrorMessage>
                        {validationErrors?.selectiveSyncPolicy}
                    </FormErrorMessage>
                </FormControl>

                {/* Code */}
                <FormControl isInvalid={validationErrors?.code}>
                    <FormLabel>Code</FormLabel>
                    <Input
                        value={fields.code}
                        onChange={(e) => updateFields('code', e.target.value)}
                    />
                    <FormErrorMessage>
                        {validationErrors?.code}
                    </FormErrorMessage>
                </FormControl>

                {/* Auto Approve */}
                <FormControl isInvalid={validationErrors?.autoApprove}>
                    <FormLabel>Auto Approve</FormLabel>
                    <CRS
                        value={autoApprovalOptions.find(
                            (op) => op.value === fields.autoApprove,
                        )}
                        onChange={(e) => updateFields('autoApprove', e.value)}
                        options={autoApprovalOptions}
                        chakraStyles={chakraStyles}
                    />
                    <FormErrorMessage>
                        {validationErrors?.autoApprove}
                    </FormErrorMessage>
                </FormControl>

                {/* Trial Days */}
                <FormControl isInvalid={validationErrors?.trialDays}>
                    <FormLabel>Trial Days</FormLabel>
                    <Input
                        type="number"
                        value={fields.trialDays}
                        onChange={(e) =>
                            updateFields('trialDays', e.target.value)
                        }
                    />
                    <FormErrorMessage>
                        {validationErrors?.trialDays}
                    </FormErrorMessage>
                </FormControl>

                {/* Valid To */}
                <FormControl isInvalid={validationErrors?.validTo}>
                    <FormLabel>Approval Code Expiry Date</FormLabel>
                    <InputGroup
                        sx={
                            darkMode
                                ? chakraDatepickerDarkMode
                                : chakraDatepicker
                        }>
                        <SingleDatepicker
                            configs={{
                                dateFormat: 'dd-MM-yyyy',
                            }}
                            date={validToTime}
                            onDateChange={(value) => {
                                updateFields(
                                    'validTo',
                                    new Date(value).toISOString(),
                                );
                                setValidToTime(value);
                            }}
                            propsConfigs={
                                darkMode
                                    ? ChakraDatePickerDarkModeStyles
                                    : ChakraDatePickerStyles
                            }
                        />
                        <InputRightElement
                            color={darkMode ? 'white' : 'gray.500'}
                            pointerEvents="none"
                            children={<CalendarIcon cursor="pointer" />}
                        />
                    </InputGroup>
                    <FormErrorMessage>
                        {validationErrors?.validTo}
                    </FormErrorMessage>
                </FormControl>

                {/* Error */}
                {error && (
                    <Alert status="error" sx={{ margin: '25px 0' }}>
                        <AlertIcon />
                        <AlertDescription>{error}</AlertDescription>
                    </Alert>
                )}
                {/* Submit */}
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant="outline"
                        className="secondary hover:opacity-80"
                        sx={{ marginRight: '20px' }}
                        onClick={() => {
                            setApprovalCodesAction('Get');
                        }}
                        isDisabled={isLoading}>
                        Back
                    </Button>
                    <Button onClick={update} isLoading={isLoading}>
                        Update
                    </Button>
                </Box>
            </Stack>
        </Box>
    );
}
