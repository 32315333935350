import { useEffect } from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, IconButton, Tooltip } from '@chakra-ui/react';
import { InfoIcon, ExternalLinkIcon, EditIcon } from '@chakra-ui/icons';

import Text from 'components/v4/Text';
import { SERVICE_STATUS_HAS_PDF } from '../constants';

export default function DirectRoutingCustomStatus({
    serviceObj,
    authData,
    cardStatus,
    setCardStatus,
    companyData,
    accountScope,
}) {
    // DR settings
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const hasRegions = serviceObj?.regions?.length;
    const hasVoicePolicies = serviceObj?.voicePolicies?.length;
    const hasDialPlans = serviceObj?.dialPlans?.length;

    // App auth last modified time
    const lastModifiedTS = authData?.lastModifiedTS;

    // Has is been less than 5 mins since creating the auth?
    let isAuthlessThanFive = false;
    if (new Date() - new Date(lastModifiedTS + 'Z') < 5 * 60 * 1000) {
        isAuthlessThanFive = true;
    }

    const { darkMode } = useSelector((state) => state.settings);

    useEffect(() => {
        if (!serviceObj) return;
        const DrEdit = `/services/${serviceObj.serviceType}/${serviceObj.id}/edit`;

        //TODO: convert to reducer pattern
        // Server status
        if (authData?.status !== 0 && authData?.engineMessage) {
            const hasPDFLink = Object.entries(SERVICE_STATUS_HAS_PDF).find(
                ([errorMsg]) =>
                    authData?.engineMessage
                        ?.toLowerCase()
                        .includes(errorMsg.toLowerCase()),
            );
            const pdfLink = hasPDFLink && hasPDFLink[1];
            setCardStatus({
                color: 'yellow',
                isAnimated: false,
                hasStripes: false,
                status: authData?.engineMessage,
                tooltip: true,
                to: pdfLink?.payload,
            });
            return;
        }
        if (serviceObj.status === 0) {
            setCardStatus({
                color: 'green',
                isAnimated: false,
                hasStripes: false,
                status: 'Healthy',
                to: '/service-profiles',
                toText: 'edit',
            });
        } else if (serviceObj.status === 2) {
            if (authData?.graphAuth) {
                if (!companyData.approved) {
                    setCardStatus({
                        color: 'yellow',
                        isAnimated: false,
                        hasStripes: false,
                        status: 'Approval Required',
                    });
                } else {
                    if (isAuthlessThanFive) {
                        setCardStatus({
                            color: 'green',
                            isAnimated: true,
                            hasStripes: true,
                            status: 'Confirming Access',
                            isAuthlessThanFive: true,
                        });
                    } else {
                        setCardStatus({
                            color: 'yellow',
                            isAnimated: true,
                            hasStripes: true,
                            status: 'Access Required',
                        });
                    }
                }
            } else {
                setCardStatus({
                    color: 'yellow',
                    isAnimated: false,
                    hasStripes: false,
                    status: 'Access Required',
                });
            }
        } else if (serviceObj.status === 4) {
            setCardStatus({
                color: 'green',
                isAnimated: true,
                hasStripes: true,
                status: 'Pairing',
            });
        } else if (serviceObj.status === 10) {
            if (!hasRegions) {
                setCardStatus({
                    color: 'yellow',
                    isAnimated: false,
                    hasStripes: false,
                    status: 'Region not set',
                    to: DrEdit,
                    toText: 'edit',
                    toPayload: {
                        serviceId: serviceObj.id,
                    },
                    toScope: 40,
                });
            } else {
                setCardStatus({
                    color: 'green',
                    isAnimated: true,
                    hasStripes: true,
                    status: 'Provisioning DNS',
                });
            }
        } else if (serviceObj.status === 11) {
            setCardStatus({
                color: 'green',
                isAnimated: true,
                hasStripes: true,
                status: 'Provisioning SBC',
            });
        } else if (serviceObj.status === 12) {
            setCardStatus({
                color: 'green',
                isAnimated: true,
                hasStripes: true,
                status: 'Activating Teams Phone',
            });
        } else if (serviceObj.status === 13) {
            if (!hasVoicePolicies) {
                setCardStatus({
                    color: 'yellow',
                    isAnimated: false,
                    hasStripes: false,
                    status: 'Voice Policy not set',
                    to: DrEdit,
                    toText: 'edit',
                    toPayload: {
                        serviceId: serviceObj.id,
                    },
                    toScope: 40,
                });
            } else if (!hasDialPlans) {
                setCardStatus({
                    color: 'yellow',
                    isAnimated: false,
                    hasStripes: false,
                    status: 'Dial Plans not set',
                    to: DrEdit,
                    toText: 'edit',
                    toPayload: {
                        serviceId: serviceObj.id,
                    },
                    toScope: 40,
                });
            } else {
                setCardStatus({
                    color: 'green',
                    isAnimated: true,
                    hasStripes: true,
                    status: 'Provisioning Teams Phone',
                });
            }
        } else if (serviceObj.status === 20) {
            setCardStatus({
                color: 'red',
                isAnimated: false,
                hasStripes: false,
                status: 'Error',
            });
        }
    }, [serviceObj, authData]);

    if (!serviceObj) return <Text>...</Text>;
    if (cardStatus?.tooltip) {
        return (
            <Box
                sx={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <Text>Degraded</Text>

                <span className="mx-1 inline-flex flex-shrink-0 self-center">
                    <Tooltip
                        label={cardStatus?.status}
                        hasArrow
                        placement="bottom"
                    >
                        <IconButton
                            icon={<InfoIcon />}
                            size="xs"
                            bg="transparent"
                            _hover={{ bg: 'transparent' }}
                        />
                    </Tooltip>
                </span>

                {/* NOTE: for now leave the guide to AppAuth only - Alby
                {cardStatus?.to && (
                    <span className="my-auto inline-flex flex-shrink-0 self-center">
                        <Link
                            to={`${window.location.host}${cardStatus?.to}`}
                            target="_blank"
                            replace
                            className="hover:bg-inherit hover:text-inherit">
                            <IconButton
                                icon={<ExternalLinkIcon />}
                                size="xs"
                                variant="outline"
                                _hover={{
                                    bg: darkMode
                                        ? 'rgba(255,255,255,0.16)'
                                        : 'gray.100',
                                }}
                                _active={{
                                    bg: darkMode ? 'gray.400' : 'gray.200',
                                }}
                            />
                        </Link>
                    </span>
                )}
                */}
            </Box>
        );
    }

    if (cardStatus?.to) {
        return (
            <span>
                {cardStatus.status}
                {accountScope >= cardStatus.toScope && (
                    <Tooltip label={cardStatus.toText} className="capitalize">
                        <Link
                            to={cardStatus.to}
                            className="hover:bg-inherit hover:text-inherit"
                        >
                            <IconButton
                                ml={2}
                                onClick={() => {
                                    // NOTE: a hack to service-profiles
                                    dispatch({
                                        type: 'CHANGE_CURRENT_SCOPE',
                                        payload: cardStatus.toScope,
                                    });
                                    navigate(cardStatus.to, {
                                        state: cardStatus.toPayload,
                                    });
                                }}
                                border="none"
                                icon={<EditIcon />}
                                size="xs"
                                variant="outline"
                                _hover={{
                                    bg: darkMode
                                        ? 'rgba(255,255,255, 0.16)'
                                        : 'gray.100',
                                }}
                                _active={{
                                    bg: darkMode ? 'gray.400' : 'gray.200',
                                }}
                            />
                        </Link>
                    </Tooltip>
                )}
            </span>
        );
    }

    return <Text>{cardStatus.status}</Text>;
}
