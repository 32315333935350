export const addressColumns = [
    {
        title: 'Description',
        field: 'description',
    },
    {
        title: 'Address',
        field: 'address',
    },
    // Fields
    {
        title: 'Building Type',
        field: 'buildingType',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'Building 1st Number',
        field: 'building1stNumber',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'Building 1st Suffix',
        field: 'building1stSuffix',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'Building 2nd Number',
        field: 'building2ndNumber',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'Building 2nd Suffix',
        field: 'building2ndSuffix',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'Building Floor Type',
        field: 'buildingFloorType',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'Building Floor Number',
        field: 'buildingFloorNumber',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'Building Floor Number Suffix',
        field: 'buildingFloorNumberSuffix',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'Building Property',
        field: 'buildingProperty',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'Building Location',
        field: 'buildingLocation',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'Street House Number 1',
        field: 'streetHouseNumber1',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'Street House Number 1 Suffix',
        field: 'streetHouseNumber1Suffix',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'Street House Number 2',
        field: 'streetHouseNumber2',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'Street House Number 2 Suffix',
        field: 'streetHouseNumber2Suffix',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'Street Name 1',
        field: 'streetName1',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'Street Type 1',
        field: 'streetType1',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'Street Suffix 1',
        field: 'streetSuffix1',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'Street Name 2',
        field: 'streetName2',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'Street Type 2',
        field: 'streetType2',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'Street Suffix 2',
        field: 'streetSuffix2',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'Locality',
        field: 'locality',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'District',
        field: 'district',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'State',
        field: 'state',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'Postcode',
        field: 'postcode',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'Country',
        field: 'country',
        hidden: true,
        hiddenByColumnsButton: true,
    },
    {
        title: 'Google Place ID',
        field: 'googlePlaceID',
        hidden: true,
        hiddenByColumnsButton: true,
    },
];
