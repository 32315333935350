import { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import Text from '../../../../components/v4/Text';
import TcapPageBackgroundLoader from '../TcapPageBackgroundLoader';
import FakeLogin from './FakeLogin';
import Image from '../../../../components/v4/Image';
import useDetectFileType from '../../../../hooks/useDetectFileType';
import CloseIcon from '@material-ui/icons/Close';
import ImageLoader from '../../../../components/v4/ImageLoader';

import TCAPFavicon from '../../../../assets/images/TCAP-Favicon.svg';

export default function PartnerLoginPreview({
    logo,
    favicon,
    brandName = 'TCAP',
    email = '',
    backgroundOptions,
    // dirty prop, used to overide padding, cause we're reusing
    // this component on partner trials, and I need a quick way to change
    // padding
    paddingOveride = { padding: '25px 0' },
    dataWalkthroughId,
}) {
    return (
        <Box sx={{ display: 'flex', flexDir: 'column', width: '100%' }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDir: 'column',
                    width: '100%',
                    //shadow: "xl",
                    padding: '25px 0',
                    ...paddingOveride,
                }}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                    }}>
                    {/* Tab */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '7px 7px 5px 7px',
                            border: '1px solid #DDD',
                            borderBottom: 'none',
                            background: '#FAFAFA',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                            height: '40px',
                            width: '220px',
                            color: 'gray.700',
                        }}>
                        <ImageLoader
                            image={favicon || TCAPFavicon}
                            objectProps={{
                                alt: 'Domain Logo',
                                style: {
                                    width: '18px',
                                    margin: '0 5px 0 0',
                                },
                                type: 'image/svg+xml',
                            }}
                            imageProps={{
                                sx: {
                                    width: '18px',
                                    margin: '0 5px 0 0',
                                },
                            }}
                        />
                        {brandName}
                        <CloseIcon
                            style={{
                                marginLeft: 'auto',
                                color: '#777',
                                width: '0.75em',
                            }}
                        />
                    </Box>
                </Box>

                <TcapPageBackgroundLoader
                    optionsOveride={backgroundOptions}
                    sx={{
                        minHeight: 'auto',
                        width: '100%',
                        border: '1px solid #DDD',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                    }}>
                    <FakeLogin
                        dataWalkthroughId={dataWalkthroughId}
                        logo={logo}
                        favicon={favicon}
                        email={email}
                    />
                </TcapPageBackgroundLoader>
            </Box>
        </Box>
    );
}
