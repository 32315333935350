export default {
    container: {
        position: 'relative',
    },
    horizontalLine: {
        display: 'flex',
        height: '1px',
        width: '100%',
        background: 'gray.300',
    },
};
