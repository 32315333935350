import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Stack,
    FormControl,
    FormLabel,
    FormHelperText,
} from '@chakra-ui/react';

import { Select as ChakraSelect } from 'chakra-react-select';
import { useQuery } from '@tanstack/react-query';

import { getAdminPermission } from 'API/AdminUser';
import { Input, Checkbox } from '@/components/v4';

import { chakraSelectDarkMode, defaultChakraSelectStyle } from '@/constants';

import walkthroughIds from '../walkthroughIds';

// Render General tab of Create Admin
const General = (props) => {
    const dispatch = useDispatch();

    const {
        ufFirstName,
        ufLastName,
        ufIsActive,
        ufPermissionSet,
        ufMfaSelect,
        ufEmail,
    } = walkthroughIds.adminsGeneral;

    const { state: modalState, stateError: modalStateError } = useSelector(
        (state) => {
            return {
                ...state.modal,
            };
        },
    );

    const { requiredScope: accountScope } = useSelector((state) => state.login);
    const { currentCompany } = useSelector((state) => state.navigationLists);
    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);

    const mfaDropdown = [
        { key: 'Enabled', value: true },
        { key: 'Disabled', value: false },
    ];

    const { data: permissionSets } = useQuery({
        queryKey: ['permissionSets', currentCompany],
        queryFn: () => getAdminPermission({ accountScope, currentCompany }),
        enabled: Boolean(currentCompany),
        onSuccess: (data) => {
            return data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
        },
    });
    const firstChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                firstName: e.target.value,
            },
        });
    };

    const lastChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                lastName: e.target.value,
            },
        });
    };

    const activeChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                isActive: !modalState?.isActive,
            },
        });
    };

    const permissionChangeHandler = (v) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                permissionSetID: v.id,
            },
        });
    };

    const emailChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                email: e.target.value,
            },
        });
    };

    const changeMFAHandler = (v) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                mfaEnabled: v.value,
            },
        });
    };

    return (
        <Stack spacing="3">
            <Stack
                spacing="6"
                direction={{
                    base: 'column',
                    md: 'row',
                }}>
                <FormControl isInvalid={Boolean(modalStateError?.firstName)}>
                    <FormLabel>First Name</FormLabel>
                    <Input
                        type="text"
                        name="firstName"
                        onChange={firstChangeHandler}
                        value={modalState?.firstName}
                        data-walkthroughid={ufFirstName}
                    />
                    <FormHelperText>First name is required</FormHelperText>
                </FormControl>

                <FormControl>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                        type="text"
                        name="lastName"
                        onChange={lastChangeHandler}
                        value={modalState?.lastName}
                        data-walkthroughid={ufLastName}
                    />
                </FormControl>
            </Stack>

            <Stack spacing="6" direction="row">
                <FormControl id="isActive">
                    <Box display="flex" alignItems="center">
                        <Checkbox
                            isChecked={modalState?.isActive}
                            onChange={activeChangeHandler}
                            colorScheme="whatsapp"
                            name="isActive"
                            mb={0}
                            data-walkthroughid={ufIsActive}
                        />
                        <FormLabel mb={0} ml={2}>
                            Active
                        </FormLabel>
                    </Box>
                </FormControl>
            </Stack>
            <FormControl
                isInvalid={Boolean(modalStateError?.permission)}
                data-walkthroughid={ufPermissionSet}>
                <FormLabel>Permission</FormLabel>
                <ChakraSelect
                    value={permissionSets?.find(
                        (item) => item.id === modalState.permissionSetID,
                    )}
                    options={permissionSets}
                    name="permissionSetID"
                    onChange={permissionChangeHandler}
                    placeholder="Select Permission"
                    getOptionLabel={(i) => i.name}
                    getOptionValue={(i) => i.id}
                    chakraStyles={{
                        ...defaultChakraSelectStyle,
                        ...chakraSelectDark,
                    }}
                    selectedOptionColor="brand"
                />
                <FormHelperText>Field is required</FormHelperText>
            </FormControl>

            {/*************/}
            {/* MFA stuff */}
            {/*************/}
            <FormControl data-walkthroughid={ufMfaSelect}>
                <FormLabel>MFA</FormLabel>
                <Box
                    sx={{
                        display: 'flex',
                        width: 'full',
                        alignItems: 'flex-start',
                    }}>
                    <ChakraSelect
                        className="w-full"
                        name="mfaEnabled"
                        options={mfaDropdown}
                        value={mfaDropdown.find(
                            (item) => item.value === modalState?.mfaEnabled,
                        )}
                        placeholder="Select MFA"
                        onChange={changeMFAHandler}
                        getOptionLabel={(i) => i.key}
                        chakraStyles={{
                            ...defaultChakraSelectStyle,
                            ...chakraSelectDark,
                        }}
                        selectedOptionColor="brand"
                    />
                </Box>
            </FormControl>

            {/* Email */}
            <FormControl isInvalid={Boolean(modalStateError?.email)}>
                <FormLabel>Email</FormLabel>
                <Box
                    sx={{
                        display: 'flex',
                        width: 'full',
                        alignItems: 'flex-start',
                    }}>
                    <Input
                        className="w-full"
                        type="email"
                        value={modalState?.email}
                        name="email"
                        onChange={emailChangeHandler}
                        placeholder="eg: abc@outlook.com"
                        data-walkthroughid={ufEmail}
                    />
                </Box>
                <FormHelperText>Email is required</FormHelperText>
            </FormControl>
        </Stack>
    );
};

export default General;
