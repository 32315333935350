import { useState } from "react";

export default function useFileUpload(options) {
  const [fileReaderResult, setFileReaderResult] = useState(null);
  const [file, setFile] = useState(null);

  function uploadHandler(e) {
    if (!fileValidation(e)) return;
    e.persist()
    fileRead(e);
    setFile(e.target.files[0])
  }

  function fileRead(e) {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]); // Converts image to "browser readable" format
    reader.onload = function () {
      setFileReaderResult(reader.result);
    };
  }

  function fileValidation(e) {
    const allowedExtensions = options.allowedExtensions
      .toString()
      .replaceAll(",", " ").toLowerCase();  // ['.png', '.jpg'] => .png .jpg
    const fileExt = e.target.value.split(".").pop().toLowerCase(); // fileNameHere.png => png
    const isFileExtAllowed = allowedExtensions.includes(fileExt);

    if (!isFileExtAllowed) {
      options.fileRef.current.value = "";
      return false;
    } else {
      return true;
    }
  }

  return { file, fileReaderResult, uploadHandler };
}
