import {axios} from '../../services/axios';

export default async function createSbcService(companyId, regions, trunkTypes) {
    return axios
        .post(`/Service/SBCaaS`, {
            name: 'SBC as a Service',
            companyId: companyId,
            maxChannel: 0,
            maxRedundancy: 0,
            regions: regions,
            trunkTypes: trunkTypes
        })
        .then((res) => res)
        .catch((e) => e);
}
