import React from 'react';
import Papa from 'papaparse';
import {axios} from '../../../../services/axios';
import {downloadCSV} from '../../../../utils/utils';
import { useSelector } from 'react-redux';

const DEFAULT_BULK_UPDATE_STATUS = {
    apiErrors: '',
};
const ERROR_MESSAGE = 'Something went wrong. Please try again later.';
const FILENAME = 'FailedUploadingData.csv';

const FIELDS_TO_EXPORT = {
    o365UserPrincipalName: 'UPN',
    displayName: 'Display Name',
    telephoneNumber: 'Telephone Number',
    dialPlan: 'Dial Plan',
    voicePolicy: 'Voice Policy',
    usageLocation: 'Usage Location',
    managedBy: 'Managed By (TCAP/Other)',
    plan: 'Plan',
    attribute1: 'Attribute 1',
    attribute2: 'Attribute 2',
    attribute3: 'Attribute 3',
    apiErrors: 'Errors',
};

export default function useBulkUpdate() {
    const [error, setError] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [succeed, setSucceed] = React.useState([]);
    const [counter, setCounter] = React.useState(0);
    const [errorCounter, setErrorCounter] = React.useState(0);
    const [uploadPayloads, setUploadPayloads] = React.useState([]);
    const isIgnoredFilter = (v) => v.ignored;
    const {requiredScope} = useSelector((state) => state.login);

    //add more UI states to BE payloads.
    const transformSubmitData = (payloads) => {
        return payloads
            .filter((v) => !isIgnoredFilter(v))
            .map((payload) => ({
                ...DEFAULT_BULK_UPDATE_STATUS,
                ...payload,
            }));
    };

    //entry point to start API call
    const processSubmission = async (payloads) => {
        setLoading(true);
        const finalPayloads = transformSubmitData(payloads);
        handleBulkUpdate(finalPayloads);
    };

    //reset UI
    const handleReset = () => {
        setCounter(0);
        setErrorCounter(0);
        setSucceed([]);
        setError([]);
    };

    //after API calls, set UI State
    const handleFinishBulkUpdate = ({errors, responses}) => {
        setError(errors);
        setSucceed(responses);
    };

    //handle api calls
    const handleBulkUpdate = async (payloads) => {
        const errors = [];
        const responses = [];
        setUploadPayloads(payloads);
        payloads.forEach(async (payload, index) => {
            try {
                //api call
                const response = await axios.put(
                    payload.attributesUpdateOnly
                        ? `/User/${payload.id}/Attributes`
                        : '/user/',
                    payload,
                    {
                        headers: {
                            'X-RequestScope': requiredScope,
                        },
                    }
                );
                //then store the success response
                responses.push(response);
            } catch (e) {
                //put to failed table
                errors.push({
                    ...payload,
                    apiErrors:
                        `${e?.message}\n${e?.response.data?.message}\n${e?.response?.status}` ||
                        ERROR_MESSAGE,
                });
                //count as error
                setErrorCounter((prev) => prev + 1);
            } finally {
                //set UI counter
                setCounter((prev) => prev + 1);
                if (index === payloads.length - 1) {
                    //setState for Failed & Succeed table
                    handleFinishBulkUpdate({errors, responses});
                }
            }
        });
    };

    /**
     * handle UI button - start api call when payload is ready.
     */
    const handlePayloadChange = React.useCallback((payloads) => {
        processSubmission(payloads);
    }, []);

    /**
     * handle UI button - export API calls that failed to CSV.
     */
    const handleExportFailedData = () => {
        const failedData = transformJSONToCSV(error);
        const res = Papa.unparse(failedData);
        downloadCSV(res, FILENAME);
    };

    /**
     * transform BE JSON field to more human readable CSV heading columns.
     * @param {*} data
     * @returns
     */
    const transformJSONToCSV = (data) => {
        return data.map((user) => {
            return Object.keys(FIELDS_TO_EXPORT).reduce((acc, key) => {
                const value = user[key];
                const newKey = FIELDS_TO_EXPORT[key];
                acc[newKey] = value;
                if (key === 'managedBy') {
                    acc[newKey] = value === 0 ? 'TCAP' : 'Other';
                }
                return acc;
            }, {});
        });
    };

    return {
        handlePayloadChange,
        loading,
        counter,
        errorCounter,
        error,
        succeed,
        handleReset,
        handleExportFailedData,
        total: uploadPayloads.length,
    };
}
