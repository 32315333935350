import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, ChakraProvider, HStack, Stack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';

import AvailableServiceProfiles from './AvailableServiceProfiles';
import getAvailableServiceProfiles from '../../../../API/ServiceProfiles/getAvailableServiceProfiles';
// import createService from '../../../../API/createService';
import { classNames } from 'utils/classNames';

import { Heading, Text, Button } from '@/components/v4';
import styles from './styles';
import chakratheme from '@/chakratheme';
import DemoServiceProfiles from './Demo/DemoServiceProfiles';
import { useWindowSize } from '@uidotdev/usehooks';

const walkthroughIdPrefix = `/dashboard-services/new-service`;

import FilterServiceProfiles from './Demo/FilterServiceProfiles';
import { FilterProvider } from './Demo/FilterContext';

export default function ServiceWizard({ serviceWizard }) {
    // Global
    const { currentPartner, currentCompany } = useSelector(
        (state) => state.navigationLists,
    );
    const { darkMode } = useSelector((state) => state.settings);
    const { requiredScope } = useSelector((state) => state.login);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const size = useWindowSize();

    // Local
    const [serviceProfiles, setServiceProfiles] = useState(null);
    const [serviceData, setServiceData] = useState([]);

    const handleGetData = async () => {
        if (currentCompany) {
            const serviceprofiles =
                await getAvailableServiceProfiles(currentCompany);
            setServiceProfiles(serviceprofiles);
        }
    };

    useEffect(() => {
        handleGetData();
    }, [currentCompany]);

    useEffect(() => {
        const filteredData = serviceProfiles?.filter(
            (service) => service.visibleScope <= requiredScope,
        );
        setServiceData(filteredData);
    }, [serviceProfiles]);

    return (
        <ChakraProvider theme={chakratheme} resetCSS={true}>
            <Box sx={styles.container}>
                <Heading as="h2">Add a new service</Heading>

                <Button
                    disabled={serviceWizard.isCreatingService}
                    size="sm"
                    onClick={() => serviceWizard.reset()}
                    sx={{ position: 'absolute', top: '6px', right: 0 }}
                    data-walkthroughid={`${walkthroughIdPrefix}/button/back`}>
                    <ArrowBackIcon />
                    Back to services
                </Button>

                <Box sx={{ marginBottom: '15px', marginTop: '15px' }} />
                <Text width={'800px'}>
                    Select the service you would like to add to your company.
                </Text>
                <Box sx={{ marginBottom: '30px', marginTop: '30px' }} />

                <Box sx={styles.horizontalLine} />

                <Box sx={{ marginBottom: '30px', marginTop: '30px' }} />

                {/* change here for the demo */}
                {currentPartner == '100d5296-0c38-4192-1c43-08dbec03eb47' ? (
                    <FilterProvider>
                        <HStack
                            height={'74vh'}
                            flexDir={'row'}
                            display={'flex'}
                            alignItems={'start'}
                            spacing="24px">
                            <DemoServiceProfiles
                                serviceProfiles={serviceProfiles}
                                serviceWizard={serviceWizard}
                            />

                            {size.width > 1300 && (
                                <FilterServiceProfiles
                                    serviceProfiles={serviceProfiles}
                                />
                            )}
                        </HStack>
                    </FilterProvider>
                ) : (
                    <AvailableServiceProfiles
                        serviceProfiles={serviceProfiles}
                        serviceWizard={serviceWizard}
                    />
                )}
            </Box>
        </ChakraProvider>
    );
}
