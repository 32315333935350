import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMfaCode from '../useMfaCode';

import MfaCodeBox from '../MfaWizard/MfaCodeBox';
// import config from '../../../../config.json';

import { Box } from '@chakra-ui/react';
import Heading from '../../../../components/v4/Heading';
import Text from '../../../../components/v4/Text';
import Alert from '../../../../components/v4/Alert';
import AlertDescription from '../../../../components/v4/AlertDescription';
import AlertIcon from '../../../../components/v4/AlertIcon';
import Button from '../../../../components/v4/Button';
import { useNavigate } from 'react-router-dom';
import { classNames } from 'utils/classNames';

export default function LoginMfa({ username, password, cancelLogin }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { darkMode } = useSelector((state) => state.settings);

    const {
        mfaCode,
        mfaCodeObj,
        shouldWeSwapToNextInput,
        shouldWeGoToPreviousInput,
        mfaCodeIndexDisabled,
        refs,
        hasEnteredCode,
    } = useMfaCode();

    const [mfaCodeError, setMfaCodeError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (hasEnteredCode) {
            mfaLogin();
        }
    }, [hasEnteredCode]);

    function onLoginWithMfaCode(e) {
        e.preventDefault();
        mfaLogin();
    }

    function mfaLogin() {
        setLoading(true);
        fetch(`${import.meta.env.VITE_APP_API_URL}/auth`, {
            headers: {
                accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json;',
                'x-requestscope': '20',
            },
            method: 'POST',
            mode: 'cors',
            credentials: 'omit',
            body: JSON.stringify({ code: mfaCode, username, password }),
        }).then(async (response) => {
            setLoading(false);
            const data = await response.json();
            if (data.status === 401) {
                if (data.title === 'Unauthorized') {
                    setMfaCodeError(true);
                }
            } else {
                setMfaCodeError(false);
                dispatch({
                    type: 'LOGIN_SUCCESS',
                    payload: {
                        ...data,
                        loadAccessList: true,
                        mfaEnabled: true,
                        mfatotpConfirmed: true,
                        email: data?.user,
                    },
                });
                // navigate('/dashboard-services');
            }
        });
    }

    return (
        <Box as="form" onSubmit={onLoginWithMfaCode}>
            <Heading
                as="h2"
                sx={{ textAlign: 'center', marginBottom: '25px' }}
                color="black">
                Enter your code
            </Heading>

            <Text fontSize="md" color="black">
                Enter the six digit authentication code generated by the
                authenticator app.
            </Text>

            <MfaCodeBox
                mfaCodeObj={mfaCodeObj}
                shouldWeSwapToNextInput={shouldWeSwapToNextInput}
                shouldWeGoToPreviousInput={shouldWeGoToPreviousInput}
                mfaCodeIndexDisabled={mfaCodeIndexDisabled}
                refs={refs}
                hasEnteredCode={hasEnteredCode}
            />

            {mfaCodeError && (
                <Alert status="error" sx={{ marginBottom: '25px' }}>
                    <AlertIcon />
                    <AlertDescription color='gray.700'>
                        The MFA code you entered is incorrect.
                    </AlertDescription>
                </Alert>
            )}

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                <Button
                    variant="outline"
                    colorScheme="messenger"
                    className='secondary'
                    onClick={cancelLogin}>
                    Cancel
                </Button>
                <Button type="submit" disabled={!hasEnteredCode || loading}>
                    {loading ? 'Loading...' : 'Login'}
                </Button>
            </Box>
        </Box>
    );
}
