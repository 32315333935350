import {ChakraProvider, Box} from '@chakra-ui/react';
import chakratheme from 'src/chakratheme';

/**
 * @param {Object} props
 * @param {import('react').ReactNode} props.children
 * @param {import('react').CSSProperties} props.className
 * @returns
 */

function TpmWrapper({children, className = ''}) {
    return (
        <ChakraProvider theme={chakratheme}>
            <Box className={className}>{children}</Box>
        </ChakraProvider>
    );
}

export default TpmWrapper;
