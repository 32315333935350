import {axios} from '../../services/axios';

export default async function createDirectRoutingService(companyId) {
    return axios
        .post(`/Service/DirectRouting`, {
            name: 'Direct Routing',
            companyId,
        })
        .then((res) => res)
        .catch((e) => e);
}
