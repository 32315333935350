import {useState} from 'react';
import {Box} from '@chakra-ui/react';
import {ArrowBackIcon} from '@chakra-ui/icons';

// Components
import Button from '../../../../components/v4/Button';
import SelectiveSyncForm from './SelectiveSyncForm';

// Edit
import ServiceWizardEditSyncUsersHeader from './Edit/ServiceWizardSyncUsersHeader';

// Create
import ServiceWizardCreateSyncUsersHeader from './Create/ServiceWizardSyncUsersHeader';
import ServiceWizardCreateSyncUsersQuestion from './Create/ServiceWizardSyncUsersQuestion';

export default function ServiceWizardSyncUsers({serviceWizard, edit = false}) {
    const [syncUsers, setSyncUsers] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedGroupName, setSelectedGroupName] = useState(false);
    const [selectedGroupLocations, setSelectedGroupLocations] = useState(false);

    return edit ? (
        <Box sx={{pos: 'relative'}}>
            <Button
                size="sm"
                onClick={() => serviceWizard.reset()}
                sx={{position: 'absolute', top: '6px', right: 0}}>
                <ArrowBackIcon />
                Back to services
            </Button>

            {/* Header */}
            <Box pt="55px" />
            <ServiceWizardEditSyncUsersHeader />

            {/* Selective Sync Form */}
            <SelectiveSyncForm
                edit={edit}
                selectedGroupName={selectedGroupName}
                setSelectedGroupName={setSelectedGroupName}
                selectedGroupLocations={selectedGroupLocations}
                setSelectedGroupLocations={setSelectedGroupLocations}
                serviceWizard={serviceWizard}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
            />
        </Box>
    ) : (
        <Box sx={{pos: 'relative'}}>
            <Button
                size="sm"
                onClick={() => serviceWizard.reset()}
                sx={{position: 'absolute', top: '6px', right: 0}}>
                <ArrowBackIcon />
                Back to services
            </Button>
            {/* Header */}
            <ServiceWizardCreateSyncUsersHeader serviceWizard={serviceWizard} />

            {/* Yes no question to selective sync */}
            <ServiceWizardCreateSyncUsersQuestion
                serviceWizard={serviceWizard}
                isLoading={isLoading}
                setSyncUsers={setSyncUsers}
            />

            {(serviceWizard.company
                .selectiveSyncPolicy == 2 ||
                syncUsers) && (
                <SelectiveSyncForm
                    edit={edit}
                    selectedGroupName={selectedGroupName}
                    setSelectedGroupName={setSelectedGroupName}
                    selectedGroupLocations={selectedGroupLocations}
                    setSelectedGroupLocations={setSelectedGroupLocations}
                    serviceWizard={serviceWizard}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                />
            )}
        </Box>
    );
}
