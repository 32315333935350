export default {
  ufSaveChangesBtn: 'form/button/saveChangesBtn',
  ufBackBtn: 'form/button/backBtn',
  ufCreateBtn: 'form/button/createBtn',
  ufCopyBtn: 'form/button/copyBtn',
  ufDeleteBtn: 'form/button/deleteBtn',
  ufSubmitBtn: 'form/button/submitBtn',
  ufBreadcrumbs: 'form/link/breadcrumbs',
  ufDropdown: 'form/link/dropdown',
};
