import { InfoIcon } from '@chakra-ui/icons';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Tooltip,
} from '@chakra-ui/react';
import { Input } from 'components/v4';
import style from '../../edit/styles';
import { useDefinedAttributes } from '../DefinedAttributesContext';
import useDefinedAttributeValidation from './useDefinedAttributeValidation';

export default function TextAttribute({
    attribute,
    index,
    requiredScope,
}) {
    const validation = JSON.parse(attribute.definedAttributeValidationType);
    const { attributesErrors } = useDefinedAttributes();
    const error = attributesErrors.find((obj) => obj.id === index);
    const { removeAttributeError, handleAttributeChange } =
        useDefinedAttributeValidation();

    return (
        <Box sx={style.fieldContainer}>
            <FormControl isRequired={validation.Required} isInvalid={error}>
                <FormLabel sx={style.label}>
                    {attribute.name}{' '}
                    {attribute.toolTip && (
                        <Tooltip
                            label={attribute.toolTip}
                            hasArrow
                            placement="top-end">
                            <InfoIcon />
                        </Tooltip>
                    )}
                </FormLabel>
                <Input
                    type="text"
                    placeholder="Please enter a value"
                    maxLength={
                        validation?.MaxLength > 0 ? validation.MaxLength : null
                    }
                    value={attribute.value}
                    onChange={(e) => {
                        removeAttributeError(index);
                        handleAttributeChange(e.target.value, index);
                    }}
                    isDisabled={requiredScope < attribute.updateScope}
                />

                {error ? (
                    <FormErrorMessage>{error.message}</FormErrorMessage>
                ) : attribute?.description ? (
                    <FormHelperText>{attribute?.description}</FormHelperText>
                ) : null}
            </FormControl>
        </Box>
    );
}
