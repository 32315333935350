export default {
    root: {
        // backgroundColor: '#FFF',
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        flexDirection: 'column',
        maxWidth: '400px',
        minWidth: '200px',
        boxShadow: 'base',
        paddingLeft: '10px',
        '&:hover': {
            boxShadow: 'md',
            transition: 'all 0.5s',
        },
    },
    contentContainer: {
        display: 'flex',
        flex: 1,
        padding: '15px',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        height: '40px',
        fontWeight: 'bold',
        padding: '15px',
    },
    actionButton: {
        position: 'absolute',
        top: '0',
        right: '5px',
        boxShadow: 'none',
        border: 'none',
        _focus: {
            boxShadow: 'none',
        },
        _hover: {
            boxShadow: 'none',
        },
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center',
    },
    verticalBar: {
        width: '10px',
        position: 'absolute',
        left: 0,
        h: '100%',
    },
    verticalBarStatus: {
        0: {
            background: 'green.500',
        },
        ok: {
            background: 'green.500',
        },
        1: {
            background: 'yellow.400',
        },
        degraded: {
            background: 'yellow.400',
        },
        2: {
            background: 'red.500',
        },
        error: {
            background: 'red.500',
        },
    },
};
