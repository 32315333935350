import React, { forwardRef } from 'react';

// Table icons
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';

// Custom components
import CustomTableCell from '../../../components/tables/CustomTableCell';
import CustomTableToolbar from 'components/tables/CustomTableToolbar';
import CustomTableFilterRow from 'components/tables/CustomTableFilterRow';
import Container from 'react-bootstrap/Container';

//

import {
  MTableToolbar,
  MTableBodyRow,
  MTableAction,
  MTableActions,
} from 'material-table';

import './styles.css';

import walkthroughIds from './walkthroughIds.js';

const { table } = walkthroughIds;

export const tableIcons = {
  Add: forwardRef((props, ref) => (
    <AddBox {...props} ref={ref} fontSize="small" />
  )),
  Check: forwardRef((props, ref) => (
    <Check {...props} ref={ref} fontSize="small" />
  )),
  Clear: forwardRef((props, ref) => (
    <Clear {...props} ref={ref} fontSize="small" />
  )),
  Delete: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} fontSize="small" />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} fontSize="small" />
  )),
  Edit: forwardRef((props, ref) => (
    <Edit {...props} ref={ref} fontSize="small" />
  )),
  Export: forwardRef((props, ref) => (
    <SaveAlt
      {...props}
      ref={ref}
      fontSize="small"
      // data-walkthroughid={table.ufExportButton}
    />
  )),
  Filter: forwardRef((props, ref) => (
    <FilterList
      {...props}
      ref={ref}
      fontSize="small"
      data-walkthroughid={table.ufFilterButton}
    />
  )),
  FirstPage: forwardRef((props, ref) => (
    <FirstPage {...props} ref={ref} fontSize="small" />
  )),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => (
    <ViewColumn
      {...props}
      ref={ref}
      // data-walkthroughid={table.ufShowColumn}
    />
  )),
};

export const components = {
  Cell: (props) => {
    return <CustomTableCell {...props} />;
  },
  Container: (props) => {
    return (
      <Container
        fluid
        {...props}
        style={{ padding: 0 }}
        className="mui-data-table"
      />
    );
  },
  Toolbar: (props) => {
    const toolbarButtonAlignment =
      props.selectedRows.length > 0
        ? 'left'
        : props.toolbarButtonAlignment;
    return (
      <CustomTableToolbar
        {...props}
        toolbarButtonAlignment={toolbarButtonAlignment}
      />
    );
  },
  // Toolbar: (props) => {
  //     return <MTableToolbar {...props}>dsdsds</MTableToolbar>;
  // },
  Row: (props) => {
    const actions = props.actions.map((v) =>
      typeof v.action === 'function'
        ? { action: v.action, position: 'row' }
        : v,
    );
    const newProps = { ...props, actions };

    if (
      Object.keys(props.data).includes('isActive') &&
      !props.data.isActive
    ) {
      return (
        <MTableBodyRow {...newProps} className="tableRow inactive" />
      );
    }

    const titleName = 'nothingfornow';
    if (
      titleName === 'Engines' &&
      props.data?.lastActive &&
      props.data?.lastActive !== '0001-01-01T00:00:00'
    ) {
      if (
        new Date() - new Date(props.data?.lastActive + 'Z') <
        15 * 60 * 1000
      ) {
        return <MTableBodyRow {...newProps} className="tableRow" />;
      }

      if (
        new Date() - new Date(props.data?.lastActive + 'Z') <
        30 * 60 * 1000
      ) {
        return (
          <MTableBodyRow {...newProps} className="tableRow yellow" />
        );
      }
      return <MTableBodyRow {...newProps} className="tableRow red" />;
    }
    return <MTableBodyRow {...newProps} className="tableRow" />;
  },
  Action: (props) => {
    return <MTableAction {...props} />;
  },
  OverlayLoading: () => {
    return (
      <CircularProgress
        style={{
          marginTop: '25%',
          marginLeft: '50%',
        }}
      />
    );
  },
  FilterRow: (props) => {
    return <CustomTableFilterRow {...props} />;
  }
};
