export default {
    /** @type {services} */
    SERVICES: 'services',

    /** @type {service-profiles} */
    SERVICE_PROFILES: 'service-profiles',

    /**@type {'directrouting'} */
    DIRECT_ROUTING: 'directrouting',
    /**@type {'sbcaas'} */
    SBCAAS: 'sbcaas',
    /**@type {'operatorconnect'} */
    OPERATOR_CONNECT: 'operatorconnect',
    /**@type {'occ'} */
    OPERATOR_CONNECT_CONFERENCE: 'occ',
    /**@type {'tpm'} */
    TEAM_PHONE_MOBILE: 'tpm',
    /**@type {'acsservice'} */
    AZURE_CONNECT_SERVICE: 'acsservice',
    /**@type {'appauth'} */
    APP_AUTH: 'appauth',
    /**@type {'vendordefined'} */
    VENDOR_DEFINED: 'vendordefined',

    /** @type {'/services/sbcaaas'} */
    SERVICES_SBCAAAS: '/services/sbcaaas',

    /** @type {'/services/operatorconnect'} */
    SERVICES_OPERATOR_CONNECT: '/services/operatorconnect',

    /** @type {'services/occ'} */
    SERVICES_OCC: '/services/occ',

    /** @type {'services/directrouting'} */
    SERVICES_DIRECT_ROUTING: '/services/directrouting',

    /** @type {'services/acsservice'} */
    SERVICES_AZURE_CONNECT_SERVICE: '/services/acsservice',

    /** @type {'/services/tpm'} */
    SERVICES_TPM: '/services/tpm',

    /** @type {':id/activation} */
    SERVICES_TPM_TRIAL: ':id/activation',

    /** @type {'/service-profiles/occ'} */
    SERVICE_PROFILES_OCC: `/service-profiles/occ`,

    /** @type {'/services/appauth'} */
    SERVICES_APP_AUTH: `/services/appauth`,

    /** @type {':id/users'} */
    SERVICES_VD_USERS: ':id/users',
    /** @type {':id/numbers'} */
    SERVICES_VD_NUMBERS: ':id/numbers'
};
