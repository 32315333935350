import Button from '../../../../components/v4/Button';

import MfaWizardStepProgress from './MfaWizardStepProgress';

import {Box, Text} from '@chakra-ui/react';
import Heading from '../../../../components/v4/Heading';
// import Text from '../../../../components/v4/Text';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

export default function MfaWizardStepBodyThree({step, authResponse}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return (
        <>
            <Box>
                <MfaWizardStepProgress step={step} />
            </Box>
            <Box padding={'20px 30px'}>
                <Heading as="h2" sx={{textAlign: 'center', marginBottom: 25}} color='black'>
                    Multi Factor Authentication enabled!
                </Heading>
                <Text fontSize="md" sx={{marginBottom: 3}} color='black'>
                    From now on when you sign, you will need to enter both your
                    password and an authentication code from your authenticator
                    app.
                </Text>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    padding: '0 15px',
                    height: '60px',
                }}>
                <Button
                    onClick={() => {
                        dispatch({
                            type: 'LOGIN_SUCCESS',
                            payload: {
                                ...authResponse,
                                loadAccessList: true,
                                showMFA: false,
                            },
                        });
                        navigate('/');
                    }}>
                    Done
                </Button>
            </Box>
        </>
    );
}
