import {useState} from 'react';
import {Box} from '@chakra-ui/react';

import MfaWizardStepBodyOne from './MfaWizardStepBodyOne';
import MfaWizardStepBodyTwo from './MfaWizardStepBodyTwo';
import MfaWizardStepBodyThree from './MfaWizardStepBodyThree';

export default function MfaWizard(props) {
    const [step, setStep] = useState(1);

    return (
        <Box
            sx={{
                background: 'white',
                boxShadow: '2xl',
                maxWidth: '700px',
                margin: '0 auto',
                borderRadius: "10px"
            }}>
            {step === 1 && (
                <MfaWizardStepBodyOne
                    {...props}
                    step={step}
                    setStep={setStep}
                />
            )}
            {step === 2 && (
                <MfaWizardStepBodyTwo
                    {...props}
                    step={step}
                    setStep={setStep}
                />
            )}
            {step === 3 && (
                <MfaWizardStepBodyThree
                    {...props}
                    step={step}
                    setStep={setStep}
                />
            )}
        </Box>
    );
}
