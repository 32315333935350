/* eslint-disable react/no-deprecated */
//external imports
import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import CircularProgress from '@material-ui/core/CircularProgress';

//local imports
import store, { persistor } from './store/store';
import { Header, ErrorPage } from './components/';

//style imports
import Themes from './themes/index';
import './index.css';
import { ToastContainer } from 'react-toastify';
import DataRouter from '@/lib/data-router';
import 'react-toastify/dist/ReactToastify.min.css';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={<CircularProgress />} persistor={persistor}>
                <Header />
                <Themes>
                    <ErrorBoundary FallbackComponent={ErrorPage}>
                        <ToastContainer
                            theme="colored"
                            autoClose={5000}
                            limit={1}
                        />
                        <DataRouter />
                    </ErrorBoundary>
                </Themes>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);
