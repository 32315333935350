import React, { useRef } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Row, Col} from 'react-bootstrap';
import {TextField, Checkbox, FormControlLabel} from '@material-ui/core';

import TableAndModal, {
    modalTitle,
    onModalError,
} from '../../../components/tables/TableAndModal';
import config from '../../../config.json';
import {LoadingFieldDropdown} from '../../../components';

/*
 * SBC Tab rednered inside Regions and Locations -> Locations -> Edit Location -> SBC Tab
 */
const SBC = () => {
    const dispatch = useDispatch();
    const tableRef = useRef();

    // state access
    const {
        data: modalData,
        state: modalState,
        mode: modalMode,
        stateError: modalStateError,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    const {child} = useSelector((state) => {
        return {
            ...state.table,
        };
    });

    // default columns for table and modal
    const defaultColumns = [
        {
            title: 'Priority',
            field: 'priority',
            type: 'number',
            defaultSort: 'asc',
            minWidth: 50,

            doNotOverrideSort: true,
        },
        {
            title: 'Name',
            field: 'name',
            minWidth: 300,
        },

        {
            title: 'Status',
            field: 'status',
            lookup: config.sbc.statusJSON,
            minWidth: 300,
        },
        {
            title: 'Active',
            field: 'isActive',
            type: 'boolean',
        },
    ];

    // change handlers for edit/add fields
    const priorityChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                priority: e.target.value,
            },
        });
    };

    const nameChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                name: e.target.value,
            },
        });
    };

    const locationChangeHandler = (v) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                locationID: v,
            },
        });
    };

    const checkboxChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                isActive: !modalState?.isActive,
            },
        });
    };

    // render table and modal with fields for editing and adding
    return (
        <TableAndModal
            tableRef={tableRef}
            tableFetch={'/sessionbordercontrollers/' + child?.id}
            modalFetch={'/sessionbordercontroller/' + modalData?.id}
            modalAdd="/sessionbordercontroller/"
            modalUpdate="/sessionbordercontroller/"
            modalUpload={(freshData, state) => {
                return {
                    ...freshData,
                    ...state,
                };
            }}
            title="SBC"
            defaultColumns={defaultColumns}
            add
            noTitle
            groupable
            filterable
            maxBodyHeight={
                window.innerHeight * config.breadcrumbView.tableHeight
            }
            modalTitle={modalTitle(modalData, 'name', ' SBC ', modalMode)}
            notify={'/notify/sbc'}
            rowActions={{
                editable: true,
            }}
            modalInitState={{
                locationID: child?.id,
                status: 0,
                isActive: true,
            }}
            newPage
            modalValidate={() => {
                const newErrorState = {
                    name:
                        !(
                            modalState?.name &&
                            /^[a-zA-Z0-9-]+$/.test(modalState?.name)
                        ) &&
                        'Field is required and can only contain alphanumberic characters and hyphens.',
                    status:
                        !config.sbc.status.some(
                            (v) => v.value === modalState?.status,
                        ) && 'Field is required.',
                    locationID: !modalState?.locationID && 'Field is required.',
                };
                dispatch({
                    type: 'CHANGE_MODAL_STATE_ERROR',
                    payload: newErrorState,
                });
                return !(
                    newErrorState.name ||
                    newErrorState.status ||
                    newErrorState.locationID
                );
            }}
            modalContent={
                <>
                    <Row style={{minHeight: 40}}>
                        <Col>
                            <TextField
                                fullWidth
                                id="priority"
                                label="Priority"
                                type="text"
                                error={Boolean(modalStateError?.priority)}
                                helperText={modalStateError?.priority}
                                value={modalState?.priority}
                                onChange={priorityChangeHandler}
                            />
                        </Col>
                        <Col>
                            <TextField
                                fullWidth
                                id="Name"
                                label="Name"
                                type="text"
                                error={Boolean(modalStateError?.name)}
                                helperText={modalStateError?.name}
                                value={modalState?.name}
                                onChange={nameChangeHandler}
                            />
                        </Col>

                        <Col>
                            <LoadingFieldDropdown
                                searchable
                                fieldFetch="/trunklocations/"
                                fieldName="Location"
                                noEmptyOption
                                dropDownValue="id"
                                displayField="location"
                                onChange={locationChangeHandler}
                                onError={onModalError}
                                fieldValue={modalState?.locationID}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{left: 10}}>
                            <FormControlLabel
                                label="Active"
                                control={
                                    <Checkbox
                                        size="small"
                                        checked={modalState?.isActive}
                                        onChange={checkboxChangeHandler}
                                    />
                                }
                            />
                        </Col>
                    </Row>
                </>
            }
        />
    );
};

export default SBC;
