import { useSelector } from 'react-redux';
import { Spinner, Box, Flex } from '@chakra-ui/react';

import { PartnerLogo } from './PartnerLogo';
import { classNames } from 'utils/classNames';
import './loadingScreen.css';

function ChakraSpinner() {
    const { darkMode } = useSelector((state) => state.settings);
    return (
        <Spinner
            thickness="2px"
            speed="0.65s"
            emptyColor="gray.200"
            color={darkMode ? 'brand.400' : 'brand.500'}
            size="xl"
        />
    );
}

function LoadingPhrases() {
    const { darkMode } = useSelector((state) => state.settings);
    return (
        <div className="loading-phrases-container">
            <div className="loading-phrases">
                {[
                    'Teams Calling Automation Platform',
                    'Fetching data...',
                    'Updating statuses...',
                    'Preparing data...',
                ].map((phrase, index) => (
                    <div
                        key={`key ${index + 1}`}
                        className={classNames(
                            darkMode ? '!text-gray-300' : '',
                            'loading-phrase',
                        )}>
                        {phrase}
                    </div>
                ))}
            </div>
        </div>
    );
}

function LoadingScreenLogo() {
    return (
        <div className="loading_screen_logo_wrapper">
            <Flex className="flex-row items-center justify-center">
                <PartnerLogo h="60px" w="60px" />
            </Flex>
        </div>
    );
}

function LoadingScreenWrapper({ children }) {
    return (
        <Box className="flex h-screen flex-col items-center justify-center text-6xl">
            {children}
        </Box>
    );
}

export function LoadingSpinner() {
    return (
        <LoadingScreenWrapper>
            <ChakraSpinner />
        </LoadingScreenWrapper>
    );
}

function LoadingScreen() {
    return (
        <LoadingScreenWrapper>
            <ChakraSpinner />
            <LoadingPhrases />
            <LoadingScreenLogo />
        </LoadingScreenWrapper>
    );
}

export function LoadingSpinnerWithMessage({ message, isMainScreen = false }) {
    const { darkMode } = useSelector((state) => state.settings);
    return (
        <Box
            className={classNames(
                `${isMainScreen ? 'h-screen' : 'h-spinner'}`,
                'flex flex-col items-center justify-center text-6xl',
            )}>
            <ChakraSpinner />
            <div className="loading-phrases-container">
                <div className="loading-message-container">
                    <div
                        className={classNames(
                            darkMode ? '!text-gray-300' : '',
                            'loading-message',
                        )}>
                        {message}
                    </div>
                </div>
            </div>
        </Box>
    );
}

export default LoadingScreen;
