import {Box} from '@chakra-ui/react';
import Heading from '../../components/v4/Heading';
import Text from '../../components/v4/Text';
import Link from '../../components/v4/Link';
import {guidErrorStyles, guidErrorHeadingStyles} from './guidErrorStyles';
import {logoStyles} from './partnerTrialStyles';
import {useSelector} from 'react-redux';
import config from '../../config.json';
import ImageLoader from '../../components/v4/ImageLoader';

export default function NoGuid() {
    const imagePathLightMode = useSelector(
        (state) => state.login.imagePathLightMode,
    );
    return (
        <Box sx={guidErrorStyles}>
            <ImageLoader
                image={config.imageBase + imagePathLightMode}
                objectProps={{
                    alt: 'Domain Logo',
                    style: logoStyles,
                    type: 'image/svg+xml',
                }}
                imageProps={{
                    sx: {
                        maxHeight: '300px',
                        maxWidth: '300px',
                        margin: '0 auto 25px auto',
                    },
                }}
            />
            <Heading as="h2" fontSize="2xl" sx={guidErrorHeadingStyles}>
                No registration ID
            </Heading>
            <Text>
                You came to the partner trial page without a registration ID. If
                you haven't already filled out our partner trial form, please do
                so over {` `}
                <Link
                    href="https://tcap.cloud/tcap-partners-free-trial"
                    color="brand.500"
                    _hover={{color: 'brand.500'}}>
                    here
                </Link>
                .
            </Text>
        </Box>
    );
}
