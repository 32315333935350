import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Button, Table} from 'react-bootstrap';
import RefreshIcon from '@material-ui/icons/Refresh';
import PlaylistPlayRoundedIcon from '@material-ui/icons/PlaylistPlayRounded';
import CircularProgress from '@material-ui/core/CircularProgress';

import {TableAndModal, Error, Succeed} from '../../../../components';
import {axios} from '../../../../services/axios';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from '@mui/material';
import { IconButton } from '@material-ui/core';

/*
 * Component for the Trigger History of a Trigger Object
 */
const History = (props) => {
    // default columns for table
    const defaultColumns = [
        {
            title: 'Triggered At (UTC)',
            field: 'triggeredAt',
            type: 'datetime',
            defaultSort: 'desc',
            width: '40%',
        },
        {
            title: 'Actions',
            render: (rowData) => (
                <Tooltip title="Refire" arrow>
                    <IconButton onClick={() => {
                        setRefire((prevFire) => ({...prevFire, rowData: rowData}));
                        handleClickOpen();
                    }}>
                    <PlaylistPlayRoundedIcon  />
                    </IconButton>
                </Tooltip>
            ),
            width: '60%',
        },
    ];

    const {tableFetch} = props;
    const dispatch = useDispatch();
    const tableRef = useRef();
    const [refire, setRefire] = useState({
        state: '',
        rowData: null,
        error: null,
    });
    // dialog open/close
    const [open, setOpen] = React.useState(false);
    // child from table state
    const {child} = useSelector((state) => {
        return {...state.table};
    });

    // table loading from state
    const {loading: tableLoading} = useSelector((state) => {
        return {...state.table};
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRefire = () => {
        setRefire({state: 'loading', rowData: refire.rowData});
    }

    useEffect(() => {
        if (tableLoading) {
            axios
                .get('/trigger/' + child?.id)
                .then((res) => {
                    dispatch({
                        type: 'CHANGE_TABLE_STATE',
                        payload: {
                            columns: defaultColumns,
                            data: res.data.triggerHistory,
                        },
                    });

                    dispatch({type: 'CHANGE_TABLE_LOADING'});
                })
                .catch((e) => {
                    dispatch({
                        type: 'CHANGE_TABLE_ERROR',
                        payload: e,
                    });
                });
        }
    }, [tableLoading]);

    useEffect(() => {
        if (refire.state === 'loading') {
            if (Array.isArray(refire.rowData)) {
                const responses = refire.rowData?.map(({id}) =>
                    axios.post('/trigger/historicalfire/' + id),
                );
                Promise.all(responses)
                    .then((res) => {
                        setRefire({state: 'succeed', rowData: res.data});
                        dispatch({type: 'CHANGE_TABLE_LOADING'});
                    })
                    .catch((error) => {
                        setRefire({state: 'error', rowData: null, error});
                    });
                return;
            }
            axios
                .post('/trigger/historicalfire/' + refire.rowData?.id)
                .then((res) => {
                    setRefire({state: 'succeed', rowData: res.data});
                    dispatch({type: 'CHANGE_TABLE_LOADING'});
                    setRefire({state: 'succeed', rowData: null});
                    handleClose();
                })
                .catch((error) => {
                    setRefire({state: 'error', rowData: null, error});
                });
        }
    }, [refire]);

    if (refire.state === 'loading') {
        return <CircularProgress style={{ marginTop: '2.5%', marginLeft: '50%' }} />;
    }

    return (
        <>
            {refire.state === 'succeed' && <Succeed message="Successful" />}
            {refire.state === 'error' && <Error error={refire.error} />}
            <TableAndModal
                tableRef={tableRef}
                overrideTableLoading
                selection
                noRefresh
                title="Trigger History"
                newPage
                defaultColumns={defaultColumns}
                // remove below line to allow for actions column
                // above actions in default column overrides default material table action column
                noActionsColumn
                additionalActions={[
                    {
                        icon: () => <RefreshIcon />,
                        tooltip: 'Refresh',
                        position: 'toolbar',
                        onClick: () => {
                            axios
                                .get(tableFetch)
                                .then((res) => {
                                    dispatch({
                                        type: 'CHANGE_PARENT_TABLE',
                                        payload: res.data,
                                    });
                                    dispatch({type: 'CHANGE_TABLE_LOADING'});
                                })
                                .catch((e) => {});
                        },
                    },
                ]}
                detailPanel={(rowData) => {
                    return (
                        <Table
                            style={{maxWidth: '100', margin: 'auto'}}
                            className="bootstrapTable detailPanel">
                            <thead>
                                <tr>
                                    <th className="bootstrapTableRow heading">
                                        Key
                                    </th>
                                    <th className="bootstrapTableRow heading">
                                        Value
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(rowData.parameters ?? {})
                                    ?.map(([key, value]) => {
                                        return {
                                            key,
                                            value,
                                        };
                                    })
                                    .sort((left, right) => {
                                        return left?.key.localeCompare(
                                            right?.key,
                                        );
                                    })
                                    .map(({key, value}, idx) => (
                                        <tr key={idx}>
                                            <td>{key}</td> <td>{value}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    );
                }}
            />

            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                Refire Trigger
                </DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Are you sure you wish to refire trigger?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                <Button onClick={handleRefire} autoFocus>
                    Refire
                </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default History;
