import {Select} from '@chakra-ui/react';

// Use this select for basic array of strings ['hi', 'bye']
export default function ChakraSelectSingle({options, ...props}) {
    return (
        <Select {...props}>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </Select>
    );
}
