import { Box, Spinner, Text, Tooltip } from '@chakra-ui/react';

import ServiceProfileCard from 'components/v4/Cards/ServiceProfileCard/ServiceProfileCard';
import ServiceProfileCardTitle from 'components/v4/Cards/ServiceProfileCard/ServiceProfileCardTitle';

import walkthroughIds from 'pages/services/walkthroughIds';

import vendor from 'assets/images/vendor.png';
import ServiceProfileCardImage from 'components/v4/Cards/ServiceProfileCard/ServiceProfileCardImage';
import { isEmpty } from 'lodash';

export default function VendorDefinedService({
    serviceProfile,
    serviceWizard,
    id,
}) {
    const { ufNewServiceProfileCard } = walkthroughIds.dashboardServices;

    return (
        <ServiceProfileCard
            key={id}
            pointerEvents={serviceWizard.isCreatingService ? 'none' : 'auto'}
            onClick={(e) => {
                // custom flow for vendor defined to get template
                serviceWizard.setSelectedServiceProfile(serviceProfile);
                serviceWizard.setFlow('VendorDefined');
            }}
            vendorDefined={
                serviceProfile.serviceDefinition?.name || 'Vendor Defined'
            }
            data-walkthroughid={`${ufNewServiceProfileCard}/${id}`}>
            {serviceWizard.isCreatingService && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        left: '0',
                        top: '0',
                        background: 'white',
                        opacity: '0.7',
                    }}>
                    {serviceWizard.selectedServiceProfile.serviceType ===
                        'VendorDefined' && (
                        <Spinner
                            thickness="4px"
                            emptyColor="gray.200"
                            color="brand.500"
                            size="xl"
                        />
                    )}
                </Box>
            )}
            {/* image is empty, use default, else use large logo */}
            <ServiceProfileCardImage
                src={
                    isEmpty(serviceProfile.serviceDefinition.logoLarge)
                        ? vendor
                        : process.env.NODE_ENV === 'development'
                          ? import.meta.env.VITE_APP_IMAGE_BASE +
                            '/images/' +
                            serviceProfile.serviceDefinition.logoLarge
                          : window.location.origin +
                            '/images/' +
                            serviceProfile.serviceDefinition.logoLarge
                }
            />
            <ServiceProfileCardTitle>
                {serviceProfile.serviceDefinition?.name}
            </ServiceProfileCardTitle>
            <Tooltip label={serviceProfile.serviceDefinition?.descriptionLong}>
                <Text>
                    {serviceProfile.serviceDefinition?.descriptionShort}
                </Text>
            </Tooltip>
        </ServiceProfileCard>
    );
}
