import { useSelector } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Button,
} from 'components/v4';

const walkthroughIdModal = {
    ufCancelButton: '/services-profile-add/modal/confirmation/cancel',
    ufAddButton: '/services-profile-add/modal/confirmation/add',
};

export default function ConfirmationModal({
    onClose,
    isOpen,
    serviceName,
    vendorDefined,
    handleSubmit,
}) {
    const { colorScheme } = useSelector((state) => state.settings);
    return (
        <Modal
            closeOnOverlayClick={false}
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            size="md">
            <ModalOverlay backdropFilter="blur(10px)" />
            <ModalContent as="form" onSubmit={handleSubmit}>
                <ModalHeader>
                    Add {vendorDefined ? vendorDefined : serviceName}
                </ModalHeader>
                <ModalBody>
                    <p className="text-base">
                        Are you sure you would like to add{' '}
                        {vendorDefined ? vendorDefined : serviceName}?
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="button"
                        variant="outline"
                        className="secondary mr-4 !w-full"
                        onClick={onClose}
                        data-walkthroughid={walkthroughIdModal.ufCancelButton}>
                        Cancel
                    </Button>
                    <Button
                        bg={`${colorScheme}.500`}
                        _hover={{
                            bg: `${colorScheme}.600`,
                        }}
                        className={`flex min-h-[38px] !w-full items-center justify-center rounded-md px-3 py-1.5 text-base font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[var(--chakra-colors-${colorScheme}-600)]`}
                        type="submit"
                        data-walkthroughid={walkthroughIdModal.ufAddButton}>
                        Add
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
