import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import {Box} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { PARTNER } from './BillingForm';

import walkthroughIds from '../walkthroughIds';
/*
 * Component for custom attributes in regards to companies, parters and wholesalers
 */
const EntityCustomFieldsForm = ({ mode, disabled = false }) => {
  const dispatch = useDispatch();

  // state access
  const { state, stateError } = useSelector((state) => {
    return { ...state.modal };
  });
  const { currentScope } = useSelector((state) => {
    return { ...state.login };
  });
  const isPartner = mode === PARTNER;

  // return the custom fields form
  return (
    <Box sx={{
      marginTop: 2,
      marginInline: '8%'
    }}>
      {isPartner
        ? ['pAttribute1', 'pAttribute2', 'pAttribute3'].map(
          (v, idx) => (
            <Row style={{ minHeight: 60 }} key={v}>
              <Col>
                <TextField
                  label={`Custom Attribute ${idx + 1}`}
                  size="small"
                  disabled={disabled}
                  placeholder={`Custom Partner Attribute ${idx + 1
                    }`}
                  value={state?.[v] ?? ''}
                  error={Boolean(stateError?.[v])}
                  helperText={stateError?.[v]}
                  fullWidth
                  onChange={(e) => {
                    dispatch({
                      type: 'CHANGE_MODAL_STATE',
                      payload: {
                        [v]: e.target.value,
                      },
                    });
                  }}
                  data-walkthroughid={`${walkthroughIds.custom
                      .ufCustomAttribute
                    }/${idx + 1}`}
                />{' '}
              </Col>
            </Row>
          ),
        )
        : ['attribute1', 'attribute2', 'attribute3'].map((v, idx) => (
          <Row style={{ minHeight: 60 }} key={v}>
            <Col>
              <TextField
                label={`Custom Attribute ${idx + 1}`}
                size="small"
                disabled={disabled}
                placeholder={`Custom Attribute ${idx + 1}`}
                value={state?.[v] ?? ''}
                error={Boolean(stateError?.[v])}
                helperText={stateError?.[v]}
                fullWidth
                onChange={(e) => {
                  dispatch({
                    type: 'CHANGE_MODAL_STATE',
                    payload: {
                      [v]: e.target.value,
                    },
                  });
                }}
                data-walkthroughid={`${walkthroughIds.custom.ufCustomAttribute
                  }/${idx + 1}`}
              />{' '}
            </Col>
          </Row>
        ))}

      {isPartner
      ? <Row style={{ minHeight: 60 }}>
          {currentScope > 40 && <Col>
            <TextField
              label={`Service Countries`}
              size="small"
              disabled={disabled}
              placeholder={`Partner Service Countries`}
              value={state?.['pServiceCountries'] ?? ''}
              error={Boolean(stateError?.['pServiceCountries'])}
              helperText={stateError?.['pServiceCountries']}
              fullWidth
              onChange={(e) => {
                dispatch({
                  type: 'CHANGE_MODAL_STATE',
                  payload: {
                    ['pServiceCountries']: e.target.value,
                  },
                });
              }}
            />{' '}
          </Col>}
        </Row>
        :
        // eventhough shared between wholesaler and company
        // wholesaler menu option is not visible therefore partner and above to view companies
        <Row style={{ minHeight: 60 }}>
          {currentScope > 20 &&<Col>
            <TextField
              label={`Service Countries`}
              size="small"
              disabled={disabled}
              placeholder={`Service Countries`}
              value={state?.['serviceCountries'] ?? ''}
              error={Boolean(stateError?.['serviceCountries'])}
              helperText={stateError?.['serviceCountries']}
              fullWidth
              onChange={(e) => {
                dispatch({
                  type: 'CHANGE_MODAL_STATE',
                  payload: {
                    ['serviceCountries']: e.target.value,
                  },
                });
              }}
            />{' '}
          </Col>}
        </Row>
      }
    </Box>
  );
};

export default EntityCustomFieldsForm;
