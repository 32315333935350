import { Box, HStack, Image, Spinner, Text, Tooltip } from '@chakra-ui/react';

import ServiceProfileCard from 'components/v4/Cards/ServiceProfileCard/ServiceProfileCard';
import ServiceProfileCardImage from 'components/v4/Cards/ServiceProfileCard/ServiceProfileCardImage';
import ServiceProfileCardTitle from 'components/v4/Cards/ServiceProfileCard/ServiceProfileCardTitle';

import sbcLogo from 'assets/images/SbcaasLogo.png';
import cloudLogo from 'assets/images/cloud.png';
import callLogo from 'assets/images/call.png';

import walkthroughIds from 'pages/services/walkthroughIds';
import { useSelector } from 'react-redux';

function SbcService({ serviceProfile, serviceWizard, id }) {
    const { ufNewServiceProfileCard } = walkthroughIds.dashboardServices;

    const { currentPartner } = useSelector((state) => state.navigationLists);
    return (
        <ServiceProfileCard
            pointerEvents={serviceWizard.isCreatingService ? 'none' : 'auto'}
            onClick={(e) => {
                console.log('e sbc: ', e, ' | service wizard: ', serviceWizard);
                serviceWizard.createService(serviceProfile);
            }}
            key={id}
            data-walkthroughid={`${ufNewServiceProfileCard}/${id}`}>
            {serviceWizard.isCreatingService && (
                <Box
                    pointerEvents={
                        serviceWizard.isCreatingService ? 'none' : 'auto'
                    }
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        left: '0',
                        top: '0',
                        background: 'white',
                        opacity: '0.7',
                    }}>
                    {serviceWizard.selectedServiceProfile.serviceType ===
                        'SBCaaS' && (
                        <Spinner
                            thickness="4px"
                            emptyColor="gray.200"
                            color="brand.500"
                            size="xl"
                        />
                    )}
                </Box>
            )}
            <ServiceProfileCardImage src={sbcLogo} />
            <ServiceProfileCardTitle>
                SBC as a Service
            </ServiceProfileCardTitle>
            <Text>
                Connect SIP services to your company. This enables you to
                connect legacy and other cloud-based SIP platforms.
            </Text>
            {currentPartner == '100d5296-0c38-4192-1c43-08dbec03eb47' && (
                <HStack mt={'auto'}>
                    <Tooltip label="Other">
                        <Image boxSize={'5'} src={cloudLogo} />
                    </Tooltip>
                    <Tooltip label="Fixed Line Calling">
                        <Image boxSize={'5'} src={callLogo} />
                    </Tooltip>
                </HStack>
            )}
        </ServiceProfileCard>
    );
}

export default SbcService;
