import {
    createContext as createReactContext,
    useContext as useReactContext,
} from 'react';

import { root } from './constant';

function getErrorMessage(hook, provider) {
    return `${hook} returned \`undefined\`. Seems you forgot to wrap the components in ${provider}" `;
}

export function createContext(options) {
    const {
        name,
        strict = true,
        hookName = 'useContext',
        providerName = 'Provider',
        errorMessage,
    } = options;

    const defaultValue = {
        styles: {
            root,
            footer: {
                padding: 8,
            },
        },
    };
    const Context = createReactContext(defaultValue);
    Context.displayName = name;

    function useContext() {
        const context = useReactContext(Context);

        if (!context && strict) {
            const error = new Error(
                errorMessage ?? getErrorMessage(hookName, providerName),
            );
            error.name = 'ContextError';
            Error.captureStackTrace?.(error, useContext);
            throw error;
        }

        return context;
    }

    return [Context.Provider, useContext, Context];
}

const [StylesProvider, useStyles] = createContext({
    name: 'StylesContext',
    errorMessage:
        'useStyles: `styles` is undefined. Seems you forgot to wrap the components in `<StylesProvider />` ',
});

export { StylesProvider, useStyles };

/**
 * Helper function that creates context with a standardized errorMessage related to the component
 * @param {string} componentName
 * @typedef {import('react').Context<Record<string, import('@chakra-ui/react').SystemProps>>} CreateStyleContextReturn
 * @returns {CreateStyleContextReturn} [StylesProvider, useStyles]  */
export function createStylesContext(componentName) {
    return createContext({
        name: `${componentName}StylesContext`,
        errorMessage: `useStyles: "styles" is undefined. Seems you forgot to wrap the components in "<${componentName} />" `,
    });
}

export const [CardStylesProvider, useCardStyles] = createStylesContext('Card');
