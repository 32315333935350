// external imports
import React from 'react';
import { Grid, TextField, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { MultiSelect } from '../../../../components';
import { getInputDarkStyle } from '@/constants';

/*
 * Component for Adding a operator profile
 * Grid of options that have onChange handlers that set the state for submission
 */
const ProfilesAdd = (props) => {
    const dispatch = useDispatch();

    // state access
    const { state: modalState, stateError: modalStateError } = useSelector(
        (state) => {
            return {
                ...state.modal,
            };
        },
    );

    // current wholesaler from state
    const { currentWholesaler } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    const { darkMode } = useSelector((state) => state.settings);

    const inputDarkStyle = getInputDarkStyle(darkMode);
    // function to set modal state easily
    const setState = (payload) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload,
        });
    };

    /*
     * Mui Grid, Textfield and a multiselect for regions
     * all of these use setState to set the state for the operator profiles page to perform the api requests
     */
    return (
        <Box sx={{ flexGrow: 1, marginTop: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={6} sx={inputDarkStyle}>
                    <TextField
                        sx={{ paddingTop: 0.6 }}
                        fullWidth
                        id="Profile Name"
                        InputLabelProps={{ shrink: true }}
                        type="string"
                        label="Profile Name"
                        size="small"
                        onChange={(e) => {
                            setState({
                                name: e.target.value,
                            });
                        }}
                        error={modalStateError?.name}
                        helperText={modalStateError?.name}
                    />
                </Grid>
                <Grid item xs={6} sx={inputDarkStyle}>
                    <TextField
                        sx={{ paddingTop: 0.6 }}
                        fullWidth
                        id="Carrier Name"
                        InputLabelProps={{ shrink: true }}
                        type="string"
                        label="Carrier Name"
                        size="small"
                        onChange={(e) => {
                            setState({
                                carrierName: e.target.value,
                            });
                        }}
                        error={modalStateError?.carrierName}
                        helperText={modalStateError?.carrierName}
                    />
                </Grid>
                <Grid item xs={6} sx={inputDarkStyle}>
                    <TextField
                        fullWidth
                        id="App ID"
                        InputLabelProps={{ shrink: true }}
                        type="string"
                        label="App ID"
                        size="small"
                        onChange={(e) => {
                            setState({
                                appID: e.target.value,
                            });
                        }}
                        error={modalStateError?.appID}
                        helperText={modalStateError?.appID}
                    />
                </Grid>
                <Grid item xs={6} sx={inputDarkStyle}>
                    <TextField
                        fullWidth
                        id="Tenant ID"
                        InputLabelProps={{ shrink: true }}
                        type="string"
                        label="Tenant ID"
                        size="small"
                        onChange={(e) => {
                            setState({
                                tenantID: e.target.value,
                            });
                        }}
                        error={modalStateError?.tenantID}
                        helperText={modalStateError?.tenantID}
                    />
                </Grid>
                <Grid item xs={6} sx={inputDarkStyle}>
                    <TextField
                        fullWidth
                        id="Client Secret"
                        InputLabelProps={{ shrink: true }}
                        type="string"
                        label="Client Secret"
                        size="small"
                        onChange={(e) => {
                            setState({
                                clientSecret: e.target.value,
                            });
                        }}
                        error={modalStateError?.clientSecret}
                        helperText={modalStateError?.clientSecret}
                    />
                </Grid>
                <Grid item xs={6} sx={inputDarkStyle}>
                    <MultiSelect
                        label="Regions"
                        fieldFetch={
                            '/wholesaler/' + currentWholesaler + '/regions/all'
                        }
                        dependency={currentWholesaler}
                        value={modalState?.regions}
                        placeholder="+"
                        setState={(e) =>
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    ...modalState,
                                    regions: e,
                                },
                            })
                        }
                        onError
                        errorText={modalStateError?.regions}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ProfilesAdd;
