import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';

import { Button } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { axios } from '@/services/axios';
import { Box, ChakraProvider, Heading, useDisclosure } from '@chakra-ui/react';
import EditSipGeneral from './EditSipGeneral';
import EditSipEndpoints from './EditSipEndpoints';
import EditTrunkAdminTab from './EditTrunkAdminTab';
import { useDispatch, useSelector } from 'react-redux';
import { TabContext, TabPanel } from '@material-ui/lab';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from 'components/v4';
import ChakraButton from 'components/v4/Button';
import chakratheme from '@/chakratheme';
import { toast } from 'react-toastify';

const EditSipTrunk = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [tabView, setTabView] = useState(0);

    const [title, setTitle] = useState('');
    const [trunkValid, setTrunkValid] = useState(true);
    // redundancy warning dialog display
    const [warning, setWarning] = useState(false);

    const openHandler = () => {
        onOpen();
    };
    // close handler for the dialogue component when confirmation is not clicked
    const closeHandler = () => {
        onClose();
    };
    // close handler when confirmation is clicked
    const closeAndSubmitHandler = () => {
        onClose();
        handleEditTrunk();
    };

    const handleBackbutton = () => {
        navigate('/trunks');
        dispatch({
            type: 'RESET_MODAL',
        });
    };

    const handleTabChange = (e, value) => {
        setTabView(value);
    };

    const statusToString = (status) => {
        switch (status) {
            case 0:
                return 'OK';
            case 1:
                return 'Adding';
            case 3:
                return 'Pending Approval';
            case 4:
                return 'Updating';
            case 8:
                return 'Degraded';
            case 9:
                return 'Deleting';
            default:
                return 'Error';
        }
    };

    // state access
    const { state } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    const { currentCompany } = useSelector((state) => ({
        ...state.navigationLists,
    }));

    useEffect(() => {
        if (id) {
            axios
                .get(`/Trunk/${id}`)
                .then((res) => {
                    dispatch({
                        type: 'CHANGE_MODAL_STATE',
                        payload: {
                            ...res.data,
                        },
                    });
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                    setIsLoading(false);
                });
        }
    }, [id]);

    // check if trunk is valid
    useEffect(() => {
        if (state) {
            // IP Auth
            if (state?.authType === 0) {
                if (
                    state.trunkCollectionHosts?.length === 0 ||
                    state.trunkTerminationEndPoints?.length === 0 ||
                    state.trunkCollectionPorts?.length === 0
                ) {
                    setTrunkValid(false);
                    setTitle(
                        'Edit Trunk -> ' +
                            state.sipHeader +
                            ' -> Status: ' +
                            'Incomplete Trunk',
                    );
                    setIsLoading(false);
                } else if (
                    state.trunkCollectionHosts?.length > 0 &&
                    state.trunkTerminationEndPoints?.length > 0 &&
                    state.trunkCollectionPorts?.length > 0
                ) {
                    setTrunkValid(true);
                    setTitle(
                        'Edit Trunk -> ' +
                            state.sipHeader +
                            ' -> Status: ' +
                            statusToString(state.status),
                    );
                    setIsLoading(false);
                }
            }
            // Sip Reg
            else if (state?.authType === 1) {
                if (state.trunkCollectionPorts?.length === 0) {
                    setTrunkValid(false);
                    setTitle(
                        'Edit Trunk -> ' +
                            state.sipHeader +
                            ' -> Status: ' +
                            'Incomplete Trunk',
                    );
                    setIsLoading(false);
                } else if (state.trunkCollectionPorts?.length > 0) {
                    setTrunkValid(true);
                    setTitle(
                        'Edit Trunk -> ' +
                            state.sipHeader +
                            ' -> Status: ' +
                            statusToString(state.status),
                    );
                    setIsLoading(false);
                }
            }
        }
    }, [state]);

    let convertedSBCList = [];
    let convertedCodecList = [];
    let convertedTerminationList = [];
    /*
     * gets the detailed list of SBCs, Codecs and Termination endpopints
     * from the state and then creates a prioritised list with ID and priority for submission
     */
    const transformData = () => {
        if (state.sbcListDetailed) {
            state.sbcListDetailed.forEach((element) => {
                convertedSBCList.push({
                    priority: state.sbcListDetailed.indexOf(element),
                    item: element.id,
                });
            });
        }
        if (state.audioCodecList) {
            state.audioCodecList.forEach((element) => {
                convertedCodecList.push({
                    priority: state.audioCodecList.indexOf(element),
                    item: element.item,
                });
            });
        }
        if (state.trunkTerminationEndPoints) {
            state.trunkTerminationEndPoints.forEach((element) => {
                convertedTerminationList.push({
                    ...element,
                    priority: state.trunkTerminationEndPoints.indexOf(element),
                });
            });
        }
    };
    /*
     * handlers for the 3 tables in the advanced tab
     * need to loop through the database and get the current values
     * compare them to the ones in state and then perform
     * updates, deletes and adds if needed
     */
    const collectionPortHandler = () => {
        const promises = [];

        if (state?.trunkCollectionPorts?.length !== 0) {
            state.trunkCollectionPorts.forEach((port) => {
                if (!port.id) {
                    // if a port doesnt have a id it means it is a new addition so needs a post request
                    // as the id is got from the database when a port is cryeated
                    const promise = axios.post('/trunkcollectionport', {
                        ...port,
                    });
                    promises.push(promise);
                }
            });
        }
        // loop through the list of ports in the edited port list and do a put request with their updated values
        if (state.editedPortIDs) {
            state.editedPortIDs.forEach((port) => {
                const promise = axios.put('/trunkcollectionport', {
                    ...port,
                });
                promises.push(promise);
            });
        }
        // loop through ports in the deleted port list and perform a delete request with the port's ID
        if (state.deletedPortIDs) {
            state.deletedPortIDs.forEach((port) => {
                if (port.id) {
                    const promise = axios.delete(
                        '/trunkcollectionport/' + port.id,
                    );
                    promises.push(promise);
                }
            });
        }
        return Promise.all(promises);
    };
    // same logic as above, just for collection posts instead of ports
    const collectionHostHandler = () => {
        const promises = [];
        // add hosts

        if (state?.trunkCollectionHosts?.length !== 0) {
            state.trunkCollectionHosts.forEach((host) => {
                if (!host.id) {
                    const promise = axios.post('/trunkcollectionhost', {
                        ...host,
                    });
                    promises.push(promise);
                }
            });
        }
        // edit hosts
        if (state.editedHostIDs) {
            state.editedHostIDs.forEach((host) => {
                const promise = axios.put('/trunkcollectionhost', {
                    ...host,
                });
                promises.push(promise);
            });
        }
        // deletes hosts
        if (state.deletedHostIDs) {
            state.deletedHostIDs.forEach((host) => {
                if (host.id) {
                    const promise = axios.delete(
                        '/trunkcollectionhost/' + host.id,
                    );
                    promises.push(promise);
                }
            });
        }
        return Promise.all(promises);
    };
    // same logic as above, just for termination endpoints instead of ports
    const terminationEndpointHandler = () => {
        const promises = [];
        // add endpoint
        if (convertedTerminationList != []) {
            convertedTerminationList.forEach((endpoint) => {
                if (!endpoint.id) {
                    const promise = axios.post('/trunkterminationendpoint', {
                        ...endpoint,
                    });
                    promises.push(promise);
                }
            });
        }
        // edit endpoint
        if (state.editedEndpointIDs) {
            state.editedEndpointIDs.forEach((endpoint) => {
                // edit logic
                const promise = axios.put('/trunkterminationendpoint', {
                    ...endpoint,
                });
                promises.push(promise);
            });
        }
        // delete endpoint
        if (state.deletedEndpointIDs) {
            state.deletedEndpointIDs.forEach((endpoint) => {
                if (endpoint.id) {
                    const promise = axios.delete(
                        '/trunkterminationendpoint/' + endpoint.id,
                    );
                    promises.push(promise);
                }
            });
        }
        return Promise.all(promises);
    };

    // editing a trunk api call
    const handleEditTrunk = () => {
        setIsLoading(true);
        transformData();
        axios
            .put(
                '/trunk',
                {
                    ...state,
                    audioCodecList: convertedCodecList,
                    trunkTerminationEndPoints: convertedTerminationList,
                    sbcList: convertedSBCList,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            )
            .then(() => {
                Promise.all([
                    collectionPortHandler(),
                    collectionHostHandler(),
                    terminationEndpointHandler(),
                ])
                    .then(() => {
                        navigate(`/trunks`);
                        dispatch({
                            type: 'RESET_MODAL',
                        });
                        dispatch({
                            type: 'RESET_TABLE',
                        });
                    })
                    .catch((err) => {
                        toast.error(err?.response?.data?.message);
                    });
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            })
            .finally(() => setIsLoading(false));
    };

    // reset navigation on wholesaler/company change
    if (state?.companyID !== currentCompany) {
        navigate('/trunks');
    }

    return isLoading ? (
        <CircularProgress style={{ marginTop: '2.5%', marginLeft: '50%' }} />
    ) : (
        <>
            <Heading fontSize="3xl" as="h2" mb={'1%'}>
                <span className="text-2xl font-semibold">{title}</span>
            </Heading>
            <TabContext value={tabView}>
                <Box
                    sx={{
                        '& .MuiTabs-indicator': {
                            backgroundColor: 'brand.500',
                        },
                    }}>
                    <Tabs
                        value={tabView}
                        centered
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleTabChange}>
                        <Tab label="General" key="General" />
                        <Tab label="Endpoints" key="Endpoints" />
                        <Tab label="Admin" key="Admin" />
                    </Tabs>
                    <TabPanel
                        className="MuiTypography-body1"
                        style={{ display: tabView === 0 ? 'block' : 'none' }}
                        value={tabView}>
                        <EditSipGeneral
                            trunkValid={trunkValid}
                            warning={warning}
                            setWarning={setWarning}
                        />
                    </TabPanel>
                    <TabPanel
                        className="MuiTypography-body1"
                        style={{ display: tabView === 1 ? 'block' : 'none' }}
                        value={tabView}>
                        <EditSipEndpoints
                            trunkValid={trunkValid}
                            authPassword={location.state}
                        />
                    </TabPanel>
                    <TabPanel
                        className="MuiTypography-body1"
                        style={{ display: tabView === 2 ? 'block' : 'none' }}
                        value={tabView}>
                        <EditTrunkAdminTab trunkValid={trunkValid} />
                    </TabPanel>
                </Box>
            </TabContext>

            {/* Back/Save Changes Button */}
            <div
                style={{
                    border: 'none',
                    textAlign: 'center',
                    justifyContent: 'center',
                    marginBottom: 10,
                }}>
                <Button
                    variant="secondary"
                    className="min-w-[5rem]"
                    onClick={handleBackbutton}>
                    Back
                </Button>
                <div style={{ marginLeft: 16, display: 'inline' }}>
                    <Button
                        variant="primary"
                        className="min-w-[5rem]"
                        type="submit"
                        onClick={warning ? openHandler : handleEditTrunk}>
                        Update
                    </Button>
                </div>
            </div>

            {/* redundancy level changed */}
            <ChakraProvider theme={chakratheme} resetCSS={false}>
                <Modal isOpen={isOpen} onClose={onClose} isCentered>
                    <ModalOverlay />
                    <ModalContent minW={'30%'}>
                        <ModalHeader>Redundancy Level Changes</ModalHeader>
                        <ModalBody>
                            Changing redundancy level will cause trunk to be
                            re-provisioned causing all current sessions to drop.
                            Are you sure you wish to submit this change?
                        </ModalBody>

                        <ModalFooter>
                            <ChakraButton
                                variant="outline"
                                mr={3}
                                onClick={() => closeHandler()}>
                                No
                            </ChakraButton>
                            <ChakraButton
                                onClick={() => closeAndSubmitHandler()}>
                                Yes
                            </ChakraButton>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </ChakraProvider>
        </>
    );
};

export default EditSipTrunk;

