import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import {
    // Button,
    TextField,
    Autocomplete,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import { axios } from '@/services/axios';
import tableIcons from 'utils/MaterialTableIcons';
import { ChakraProvider, Box as ChakraBox } from '@chakra-ui/react';
import chakratheme from '@/chakratheme';
import { Select } from 'chakra-react-select';
import { defaultChakraSelectStyle, chakraSelectDarkMode } from '@/constants';
import Button from '@/components/v4/Button';

// Function to display list and handle crud
const EditSipAudioCodecs = () => {
    const muiTheme = useMemo(
        () =>
            createTheme({
                head: {
                    backgroundColor: null,
                },
                overrides: {
                    MuiToolbar: {
                        root: {
                            color: 'rgba(255, 255, 255, 0.7)',
                            backgroundColor: '#212121',
                        },
                    },
                    MuiTable: {
                        root: {
                            WebkitTextFillColor:
                                'rgba(255, 255, 255, 0.7) !important',
                            backgroundColor: '#212121 !important',
                        },
                    },
                    MuiTableHead: {
                        root: {
                            backgroundColor: '#212121 !important',
                        },
                    },
                    MuiTablePagination: {
                        root: {
                            backgroundColor: 'white',
                        },
                    },
                    MuiPaper: {
                        root: {
                            backgroundColor: null,
                        },
                    },
                    MuiIconButton: {
                        label: {
                            color: 'rgba(255, 255, 255, 0.3)',
                        },
                    },
                },
            }),
        [],
    );
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [audioCodecList, setAudioCodecList] = useState([]);
    const [audioCodec, setAudioCodec] = useState();
    const [loading, setLoading] = useState(false);
    const tableRef = useRef(MaterialTable);

    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);

    const dispatch = useDispatch();
    // state access
    const { state } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // column for the table
    const columns = [{ title: 'Codec', field: 'item', sorting: false }];

    // logic for open and close + fetch on open
    const handleOpen = () => {
        getAudioCodecList();
        setOpen(true);
    };

    const handleClose = () => {
        setAudioCodec(null);
        setOpen(false);
    };

    // gets Codec list availible to trunkTypeID
    const getAudioCodecList = () => {
        return axios.get('/Trunk/AudioCodecs/Available').then((res) => {
            setAudioCodecList([...res.data]);
        });
    };

    /*
     * Deletes a audio codec from the list
     * The row data is passed into the component
     * The state is looped through to check if the items in the state are different from the row
     * if they are they are added to a new array, else they are not
     * The new object missing what was deleted is appended to state
     */
    const deleteHandler = (row) => {
        let result = [];
        state.audioCodecList?.forEach((v) => {
            if (v.item !== row.item) {
                const temp = {
                    priority: v.priority,
                    item: v.item,
                };
                result.push(temp);
            }
        });
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                audioCodecList: result,
            },
        });
    };

    /*
     * Submit handler for the button in the Dialogue
     * Takes the state and appends it with the item added by the user
     */
    const onSubmitHandler = (codec) => {
        if (state.audioCodecList) {
            dispatch({
                type: 'CHANGE_MODAL_STATE',
                payload: {
                    audioCodecList: [
                        ...state.audioCodecList,
                        {
                            item: codec.id,
                        },
                    ],
                },
            });
        } else {
            dispatch({
                type: 'CHANGE_MODAL_STATE',
                payload: {
                    audioCodecList: [
                        {
                            item: codec.id,
                        },
                    ],
                },
            });
        }
        setAudioCodec(null);
        handleClose();
    };

    /*
     * Logic to set the rows of the table based on the state
     * If there is a list in the state and its not loading ->
     * for each in the detailed list, pull of its data and append it to result
     * Set the tables rows to this result
     * Do this whenever the state's debtailed list changes
     */
    useEffect(() => {
        if (state.audioCodecList && loading === false) {
            let result = [];
            state.audioCodecList.forEach((v) => {
                const temp = {
                    item: v.item,
                };
                result.push(temp);
            });
            setRows([...result]);
        }
    }, [state?.audioCodecList, loading]);

    /*
     * Gets data from the API when the compoent first renders and sets it to state/sets the rows
     * If the mode is Edit -> get the data from the endpoint using the state.id
     * For each value in the result -> Create a temp with the object and push it to the result variable
     * Set this result array to the state/rows
     */
    useEffect(() => {
        setLoading(true);
        axios.get('/trunk/' + state.id).then((res) => {
            let result = [];
            res.data.audioCodecList.forEach((value) => {
                const temp = {
                    item: value.item,
                };
                result.push(temp);
            });
            dispatch({
                type: 'CHANGE_MODAL_STATE',
                payload: {
                    audioCodecList: result,
                },
            });
            setRows(result);
            setLoading(false);
        });
    }, []);

    /*
     * Logic when you click the up arrow in the AudioCodecList List(rowData is the clicked row)
     * create variables for the reordering of the list, values above, values lower than, and the value to go up/down
     * oldData is the state of the current table -> for each row of the old table ->
     * If that row's id = the passed in rowData.id then it needs to be moved.
     * create array of things above, things below and the two values to be swapped
     * create new table state by combining these arrays and setting state to the new array
     */
    const moveUpHandler = (rowData) => {
        const oldData = tableRef.current.state.data;
        let valuesUnder = [];
        let valuesOver = [];
        let valueDown;
        let valueUp;
        let result = [];
        oldData.forEach((row) => {
            if (row.tableData.id > rowData.tableData.id) {
                valuesOver.push(row);
            } else if (row.tableData.id === rowData.tableData.id - 1) {
                valueDown = row;
            } else if (row.tableData.id === rowData.tableData.id) {
                valueUp = row;
            } else {
                valuesUnder.push(row);
            }
        });
        result = [...valuesUnder, valueUp, valueDown, ...valuesOver];
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                audioCodecList: result,
            },
        });
    };

    /*
     * Same logic as the move up handler ->
     * move down instead so the else if logic is slightly differnet
     */
    const moveDownHandler = (rowData) => {
        const oldData = tableRef.current.state.data;
        let valuesUnder = [];
        let valuesOver = [];
        let valueDown;
        let valueUp;
        let result = [];
        oldData.forEach((row) => {
            if (row.tableData.id < rowData.tableData.id) {
                valuesUnder.push(row);
            } else if (row.tableData.id === rowData.tableData.id) {
                valueDown = row;
            } else if (row.tableData.id === rowData.tableData.id + 1) {
                valueUp = row;
            } else {
                valuesOver.push(row);
            }
        });
        result = [...valuesUnder, valueUp, valueDown, ...valuesOver];
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                audioCodecList: result,
            },
        });
    };

    /*
     * Material Table from the material table documentation https://material-table.com/#/
     * Dialogue with a form to add a new audio codec list
     * Drop down list with the component LoadingFieldDropdown
     * Confirm button to close the modal and add the value to the table
     */
    return (
        <ThemeProvider theme={darkMode ? muiTheme : null}>
            <Box
                sx={{
                    height: '100%',
                    th: {
                        height: '80.5px',
                        backgroundColor: darkMode
                            ? '#212121 !important'
                            : 'white !important',
                    },
                    td: {
                        color: darkMode ? 'black !important' : null,
                    },
                    boxShadow: darkMode ? 1 : 0,
                    borderWidth: darkMode ? 1 : 0,
                    borderStyle: darkMode ? 'solid' : null,
                    borderColor: darkMode ? '#DDD' : null,
                }}>
                <MaterialTable
                    style={{ height: '100%' }}
                    tableRef={tableRef}
                    title="Audio Codecs"
                    icons={tableIcons}
                    columns={columns}
                    data={rows}
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        sorting: true,
                        pageSize: 3,
                        pageSizeOptions: [],
                        draggable: false,
                    }}
                    actions={[
                        {
                            icon: tableIcons.Add,
                            tooltip: 'Add',
                            isFreeAction: true,
                            onClick: () => {
                                handleOpen();
                            },
                        },
                        {
                            icon: tableIcons.Delete,
                            tooltip: 'Delete',
                            onClick: (e, rowData) => {
                                deleteHandler(rowData);
                            },
                            hidden: state?.audioCodecList?.length < 2,
                        },
                        (rowData) => ({
                            icon: tableIcons.MoveDown,
                            tooltip: 'Decrease Priority',
                            onClick: (e, rowData) => {
                                moveDownHandler(rowData);
                            },
                            hidden: rowData.tableData.id === rows.length - 1,
                        }),
                        (rowData) => ({
                            icon: tableIcons.MoveUp,
                            tooltip: 'Increase Priority',
                            onClick: (e, rowData) => {
                                moveUpHandler(rowData);
                            },
                            hidden: rowData.tableData.id === 0,
                        }),
                    ]}
                    localization={{
                        header: {
                            actions: '',
                        },
                    }}
                />
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                sx={{
                    '& .MuiPaper-root': {
                        bgcolor: darkMode ? '#212121' : null,
                        color: darkMode ? 'white' : null,
                    },
                }}>
                <DialogTitle>Add Audio Codec</DialogTitle>
                <DialogContent
                    sx={{
                        height: '45vh',
                        width: '50vh',
                    }}>
                    <DialogContentText
                        sx={{
                            paddingBottom: 2,
                            color: darkMode ? 'white' : 'inherit',
                        }}>
                        Please Select Audio Codecs from the list below
                    </DialogContentText>
                    <ChakraProvider theme={chakratheme} resetCSS={true}>
                        <ChakraBox
                            sx={{
                                display: 'flex',
                                width: 'full',
                                alignItems: 'flex-start',
                            }}>
                            <Select
                                className="w-full"
                                sx={{ marginBottom: 2 }}
                                minMenuHeight={'40vh'}
                                getOptionValue={(option) => option.id}
                                options={audioCodecList}
                                getOptionLabel={(option) => option.displayName}
                                isLoading={loading}
                                onChange={(event) => {
                                    setAudioCodec(event);
                                }}
                                placeholder={'- Select Audio Codec -'}
                                chakraStyles={{
                                    ...defaultChakraSelectStyle,
                                    ...chakraSelectDark,
                                }}
                                selectedOptionColor="brand"
                            />
                        </ChakraBox>
                    </ChakraProvider>
                    {/* <Autocomplete
                        disablePortal
                        id="codec-list-combo-box"
                        options={audioCodecList}
                        onChange={(event, value) => {
                            setAudioCodec(value)
                        }}
                        getOptionLabel={(option) => option.displayName}
                        sx={{ marginBottom: 2,  }}
                        renderInput={(params) => (
                            <TextField
                                id="combo-text-field"
                                {...params}
                                label="Audio Codec"
                                
                            />
                        )}
                    /> */}
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button
                        // sx={{
                        //     backgroundcolor: '#0d6efd',
                        //     color: '#ffffff',
                        // }}
                        isDisabled={!audioCodec}
                        // style={{
                        //     color: darkMode ? 'white' : null,
                        //     backgroundColor: darkMode ? '#1976d2' : null,
                        //     opacity: darkMode && !audioCodec ? '0.4' : null,
                        // }}
                        onClick={() => {
                            onSubmitHandler(audioCodec);
                        }}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default EditSipAudioCodecs;
