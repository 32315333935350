import { theme as baseTheme } from '@chakra-ui/react';
import { DEFAULT_COLOR } from 'context/LayoutContext';
import { customColors } from '@/constants';

export function getBrandColor(colorScheme = DEFAULT_COLOR) {
    switch (colorScheme) {
        case 'primary':
            return customColors.primary;
        case 'secondary':
            return customColors.secondary;
        case 'tertiary':
            return customColors.tertiary;
        case 'gray':
            return customColors.gray;
        case 'burgundy':
            return customColors.burgundy;
        case 'mud':
            return customColors.mud;
        case 'brown':
            return customColors.brown;
        default:
            return (
                baseTheme.colors[colorScheme] || baseTheme.colors[DEFAULT_COLOR]
            );
    }
}
