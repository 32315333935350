import { useDefinedAttributes } from '../DefinedAttributesContext';
import BoolAttribute from './boolAttribute';
import GuidAttribute from './guidAttribute';
import IntAttribute from './intAttribute';
import ListAttribute from './listAttribute';
import TextAttribute from './textAttribute';

export default function DefinedAttributes({ requiredScope }) {
    const { attributesList } = useDefinedAttributes();

    // leave it as it is when display order is same, otherwise sort
    return attributesList
        ?.sort((a, b) =>
            a.displayOrder === b.displayOrder
                ? 0
                : a.displayOrder - b.displayOrder,
        )
        ?.map((att, key) => {
            // defined attribute validation type is a json string
            const validation = JSON.parse(att.definedAttributeValidationType);
            switch (validation.Type) {
                case 'Int':
                    return (
                        <IntAttribute
                            index={key}
                            attribute={att}
                            requiredScope={requiredScope}
                        />
                    );
                case 'Text':
                    return (
                        <TextAttribute
                            index={key}
                            attribute={att}
                            requiredScope={requiredScope}
                        />
                    );

                case 'Bool':
                    return (
                        <BoolAttribute
                            index={key}
                            attribute={att}
                            requiredScope={requiredScope}
                        />
                    );

                case 'Guid':
                    return (
                        <GuidAttribute
                            index={key}
                            attribute={att}
                            requiredScope={requiredScope}
                        />
                    );

                case 'List':
                    return (
                        <ListAttribute
                            index={key}
                            attribute={att}
                            requiredScope={requiredScope}
                        />
                    );
                default:
                    break;
            }
        });
}
