import UnprotectedNoMatch from '../components/v4/UnprotectedNoMatch';
import Login from '../pages/auth/new/Login';
import LoginSSO from '../pages/auth/new/sso/';
import Register from '../pages/auth/new/Register';
import ForgotPassword from '../pages/auth/new/ForgotPassword';
import ChangePassword from '../pages/auth/new/ChangePassword';
import ActivateAccount from '../pages/auth/new/ActivateAccount';
import { PartnerTrial } from '../pages/partner-trial';
import MSRedirect from 'pages/auth/new/sso/redirect';

export default [
    {
        path: '/',
        element: <Login />,
    },
    {
        path: '/register',
        element: <Register />,
    },
    {
        path: '/register/*',
        element: <Register />,
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword />,
    },
    {
        path: '/change-password',
        element: <ChangePassword />,
    },
    {
        path: '/activate',
        element: <ActivateAccount />,
    },
    {
        path: '/partnertrial',
        element: <PartnerTrial />,
    },
    // {
    //     path: '/sso',
    //     element: <LoginSSO />
    //
    // },
    {
        path: '/sso/ms/redirect',
        element: <MSRedirect />,
    },
    { path: '*', element: <UnprotectedNoMatch /> },
];
