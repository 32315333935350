import React from 'react';
import {renderTelephoneNumber} from '../../../utils/utils';
import config from '../../../config.json';

const customAttributes = [
    {
        title: 'Custom Attribute 1',
        field: 'attribute1',
    },
    {
        title: 'Custom Attribute 2',
        field: 'attribute2',
    },
    {
        title: 'Custom Attribute 3',
        field: 'attribute3',
    },
];

export const sharedColumns = [
    {
        title: 'UPN',
        field: 'o365UserPrincipalName',
    },
    {
        title: 'Telephone Number',
        field: 'telephoneNumber',
        render: (rowData) => renderTelephoneNumber(rowData, 'telephoneNumber'),
    },
    {
        title: 'Dial Plan',
        field: 'dialPlan',
    },
    {
        title: 'Voice Policy',
        field: 'voicePolicy',
    },
    {
        title: 'Managed By',
        field: 'managedBy',
        lookup: config.users.managedByJSON,
    },
    {
        title: 'Plan',
        field: 'plan',
    }
];

export const succeedAPIColumns = [
    {
        title: 'UPN',
        field: 'o365UserPrincipalName',
    },
];

export const failedAPIColumns = [
    {
        title: 'UPN',
        field: 'o365UserPrincipalName',
    },
    {
        title: 'Failure Reasons',
        field: 'apiErrors',
    },
];

// default columns for the table
export const userTableColumns = [
    {
        title: 'Display Name',
        field: 'displayName',
        defaultSort: 'asc',
    },
    {
        title: 'Active',
        field: 'isActive',
        width: '5%',
        type: 'boolean',
    },
    ...sharedColumns,
    ...config.table.custom,
    {
        title: 'Usage Location',
        field: 'usageLocation',
    },
];

export const passedColumns = [
    {
        title: 'Display Name',
        field: 'displayName',
    },
    ...sharedColumns,
    ...customAttributes,
    {
        title: 'Verification Status',
        field: 'verificationStatus',
    },
];

export const failedColumns = [
    {
        title: 'Display Name',
        field: 'displayName',
    },
    ...sharedColumns,
    ...customAttributes,
    {
        title: 'Failure reasons',
        field: 'errors',
        width: '40%',
        render: (rowData) => {
            if (Array.isArray(rowData.errors) && rowData.errors.length > 0) {
                const erorString = rowData.errors.join('\n');
                return (
                    <span style={{whiteSpace: 'pre-line'}}> {erorString} </span>
                );
            }
            return null;
        },
    },
].map((v) => ({
    ...v,
    defaultSort: v.field === 'o365UserPrincipalName' ? 'asc' : undefined,
}));
