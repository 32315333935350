import { useSelector } from 'react-redux';
import { Box, FormControl, FormLabel } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { TpmBody, TpmFooter } from './';
import { Input, Button } from 'components/v4';

import { useTpmContext } from './TpmProvider';
import walkthroughIds from './walkthroughIds';

function TeamsPhoneMobileProcess() {
    const {
        tpmNumber: [state, setState],
        tpmAddress: [, setAddressAction],
    } = useTpmContext();
    const navigate = useNavigate();

    const { darkMode } = useSelector((state) => state.settings);

    const { ufPhoneNumberInput, ufActivateButton, ufCancelButton } =
        walkthroughIds.teamsPhoneMobile;

    const handleNumber = (e) => {
        setState({
            status: 'SUBMITTING',
            number: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setState({
            status: 'LOADING',
        });

        // TODO: add BE logic to check number.
        const isNumberExist = true;
        const nextAction = isNumberExist
            ? 'AUTHORIZATION_PROCESS'
            : 'NOT_EXIST';
        setTimeout(() => {
            setAddressAction(nextAction);
            setState({
                status: 'SUCCESS',
                number: state.number,
            });
        }, 400);
    };

    return (
        <Box as="form" onSubmit={handleSubmit}>
            <TpmBody>
                <FormControl
                    id="number"
                    data-walkthroughid={ufPhoneNumberInput}>
                    <FormLabel htmlFor="activate-number">
                        What is the number to activate?
                    </FormLabel>

                    <Input
                        type="text"
                        id="activate-number"
                        value={state.number}
                        onChange={handleNumber}
                    />
                </FormControl>
            </TpmBody>

            <TpmFooter className="mx-auto justify-between">
                <Button
                    variant="outline"
                    bg={darkMode ? 'white' : null}
                    w={{ base: 'full', md: '80px' }}
                    onClick={() => {
                        navigate('/dashboard-services');
                    }}
                    data-walkthroughid={ufCancelButton}>
                    Cancel
                </Button>
                <Button
                    type="submit"
                    w={{ base: 'full', md: '80px' }}
                    isDisabled={!state.number}
                    data-walkthroughid={ufActivateButton}>
                    Activate
                </Button>
            </TpmFooter>
        </Box>
    );
}

export default TeamsPhoneMobileProcess;
