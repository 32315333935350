import { lightTheme, darkTheme } from '@/theme/themes';

const changeToLightTheme = () => {
    return {
        type: 'CHANGE_TO_LIGHT',
    };
};

const changeToDarkTheme = () => {
    return {
        type: 'CHANGE_TO_DARK',
    };
};

const themeObject = lightTheme;

const themeReducer = (state = themeObject, action) => {
    switch (action.type) {
        case 'CHANGE_TO_LIGHT':
            return lightTheme;
        case 'CHANGE_TO_DARK':
            return darkTheme;
        default:
            return state;
    }
};

export { themeReducer, changeToLightTheme, changeToDarkTheme };
