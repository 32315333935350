import { axios } from "services/axios";

export const postRequestNumberVerification = ({ serviceID, phoneNumber }) =>
    axios.post(`/Services/TeamsPhoneMobile/${serviceID}/RequestNumberVerification`, { phoneNumber })
export const postSubmitNumberVerification = ({ serviceID, phoneNumber, code }) =>
    axios.post(`/Services/TeamsPhoneMobile/${serviceID}/SubmitNumberVerification`, { phoneNumber, code })
export const postActivateNumbers = ({ serviceID, phoneNumbers }) =>
    axios.post(`/Services/TeamsPhoneMobile/${serviceID}/ActivateNumbers`, phoneNumbers)
export const postDeactivateNumbers = ({ serviceID, phoneNumbers }) =>
    axios.post(`/Services/TeamsPhoneMobile/${serviceID}/DeactivateNumbers`, phoneNumbers)

