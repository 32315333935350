//TODO:
//used for just script page
const initScriptState = {loading: true};

export const scriptReducer = (script = initScriptState, action) => {
    //maybe wanna default to pingco's?
    switch (action.type) {
        case `CHANGE_SCRIPT_DATA`:
            return {
                ...script,
                data: action.payload,
            };
        case `OPEN_SCRIPT`:
            return {
                rowData: action.payload,
                loading: true,
            };
        case `CHANGE_SCRIPT_LOADING`:
            return {
                ...script,
                loading: action.payload,
            };
        case `CHANGE_SCRIPT_ERROR`:
            return {
                ...script,
                error: action.payload,
                loading: false,
            };
        case `RESET_SCRIPT`:
            return {...initScriptState};
        case `REDIRECT`:
            return {...initScriptState};
        case 'CLEAR_TOKEN':
            return {loading: true};
        default:
            return script;
    }
};
