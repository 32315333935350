import {
    Box,
    FormErrorMessage,
    FormHelperText,
    HStack,
    Skeleton,
    SkeletonCircle,
    Stack,
    Switch,
    Tooltip,
    VStack,
} from '@chakra-ui/react';
import { Button, FormControl, FormLabel, Input } from 'components/v4';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import walkthroughIds from '../walkthroughIds';
import { QuestionIcon } from '@chakra-ui/icons';
import { useQuery } from '@tanstack/react-query';
import {
    getPasswordPolicy,
    postPasswordPolicy,
    updatePasswordPolicy,
} from 'API/PasswordPolicy';

export default function PasswordPolicy() {
    const [isLoading, setIsLoading] = useState(true);
    const [isSwitchDisabled, setIsSwitchDisabled] = useState(false);

    const { currentCompany } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    const initialState = {
        isEnabled: false,
        numberOfDays: '180',
        reuseLimit: '5',
        minPasswordAge: '1',
    };

    const [passwordPolicy, setPasswordPolicy] = useState(initialState);
    const [errors, setErrors] = useState(null);

    // use query for getting password policy
    useQuery({
        queryKey: ['passwordPolicy', currentCompany],
        queryFn: () => getPasswordPolicy(currentCompany),
        enabled: !!currentCompany, // Ensures the query is only executed when currentCompany is truthy
        onSuccess: (data) => {
            setPasswordPolicy({ ...data });
            setIsLoading(false);
        },
        onError: (err) => {
            toast.error(err?.response?.data?.message);
            setIsLoading(false);
        },
        refetchOnWindowFocus: false,
        staleTime: 0,
    });

    // enable/disable password policy
    const handlePasswordCheckbox = async (value) => {
        setErrors(null);
        setIsSwitchDisabled(true);

        // set to never expire, call endpoint to disable password expiration policy
        try {
            if (value && passwordPolicy?.id) {
                var pp = {
                    id: passwordPolicy.id,
                    companyID: currentCompany,
                    numberOfDays: passwordPolicy?.numberOfDays || 0,
                    reuseLimit: passwordPolicy?.reuseLimit || 0,
                    minPasswordAge: passwordPolicy?.minPasswordAge || 0,
                    isEnabled: false,
                };

                const result = await updatePasswordPolicy(pp);
                setPasswordPolicy({ ...result.data });
                toast.success(
                    'Password expiration policy has been disabled successfully!',
                );
            }
            setPasswordPolicy({
                ...passwordPolicy,
                isEnabled: value ? false : true,
            });
        } catch (e) {
            toast.error(e?.response?.data?.message);
        } finally {
            setIsSwitchDisabled(false);
        }
    };

    // create/update password policy
    const handlePasswordPolicy = async () => {
        // validation check, number of days cannot be empty or undefined
        if (passwordPolicy?.numberOfDays === '') {
            return setErrors({
                ...errors,
                numberOfDays: 'Field is required.',
            });
        } else if (passwordPolicy?.reuseLimit === '') {
            return setErrors({
                ...errors,
                reuseLimit: 'Field is required.',
            });
        } else if (passwordPolicy?.minPasswordAge === '') {
            return setErrors({
                ...errors,
                minPasswordAge: 'Field is required.',
            });
        }

        setIsLoading(true);
        setErrors(null);
        var pp = {
            id: passwordPolicy?.id,
            companyID: currentCompany,
            numberOfDays: passwordPolicy?.numberOfDays,
            reuseLimit: passwordPolicy?.reuseLimit,
            minPasswordAge: passwordPolicy?.minPasswordAge,
            isEnabled: passwordPolicy?.isEnabled,
        };

        try {
            if (passwordPolicy?.id) {
                const data = await updatePasswordPolicy(pp);
                setPasswordPolicy({ ...data });
                toast.success(
                    'Password expiration policy has been updated successfully!',
                );
            } else {
                const data = await postPasswordPolicy(pp);
                setPasswordPolicy({ ...data });
                toast.success(
                    'Password expiration policy has been enabled successfully!',
                );
            }
        } catch (e) {
            toast.error(e?.response?.data?.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <h4 style={{ marginBottom: '20px', fontSize: '1.5rem' }}>
                Password Expiration Policy
            </h4>
            {isLoading ? (
                <Stack>
                    <HStack width={'24%'} mb={3}>
                        <SkeletonCircle w={9} mr={1} size={5} />
                        <Skeleton w={334} h={5} />
                    </HStack>

                    <HStack>
                        <VStack
                            w={'100%'}
                            style={{ WebkitAlignItems: 'normal' }}>
                            <HStack width={'60%'}>
                                <Skeleton border={'10px'} flex={1} h={6} />
                            </HStack>
                            <HStack mb={1}>
                                <Skeleton
                                    border={'10px'}
                                    w={'100%'}
                                    h={9}
                                    mr={3}
                                />
                            </HStack>
                            <HStack width={'9%'} mb={1}>
                                <Skeleton flex={1} h={5} />
                            </HStack>
                        </VStack>

                        <VStack
                            w={'100%'}
                            style={{ WebkitAlignItems: 'normal' }}>
                            <HStack width={'50%'}>
                                <Skeleton border={'10px'} flex={1} h={6} />
                            </HStack>
                            <HStack mb={1}>
                                <Skeleton
                                    border={'10px'}
                                    w={'100%'}
                                    h={9}
                                    mr={3}
                                />
                            </HStack>
                            <HStack width={'9%'} mb={1}>
                                <Skeleton flex={1} h={5} />
                            </HStack>
                        </VStack>

                        <VStack
                            w={'100%'}
                            style={{ WebkitAlignItems: 'normal' }}>
                            <HStack width={'53%'}>
                                <Skeleton border={'10px'} flex={1} h={6} />
                            </HStack>
                            <HStack mb={1}>
                                <Skeleton
                                    border={'10px'}
                                    w={'100%'}
                                    h={9}
                                    mr={3}
                                />
                            </HStack>
                            <HStack width={'9%'} mb={1}>
                                <Skeleton flex={1} h={5} />
                            </HStack>
                        </VStack>
                    </HStack>

                    <VStack mt={'16px !important'}>
                        <Skeleton border={'10px'} w={'4%'} h={9} />
                    </VStack>
                </Stack>
            ) : (
                <>
                    <FormControl mb={3}>
                        <Box className="flex items-center">
                            <Switch
                                isChecked={!passwordPolicy?.isEnabled}
                                isDisabled={isSwitchDisabled || isLoading}
                                onChange={(e) =>
                                    handlePasswordCheckbox(e.target.checked)
                                }
                                mb={0}
                                data-walkthroughid={
                                    walkthroughIds.adminPasswordPolicy
                                        .ufPasswordPolicySwitch
                                }
                            />
                            <FormLabel ml={3} mb={0}>
                                Set passwords to never expire (recommended)
                            </FormLabel>
                        </Box>
                    </FormControl>

                    {/* policy is disabled, do not show inputs */}
                    {passwordPolicy?.isEnabled && (
                        <>
                            <Box className="flex items-center">
                                {/* Number of days */}
                                <FormControl
                                    mb={4}
                                    mr={3}
                                    isRequired
                                    isInvalid={errors?.numberOfDays}>
                                    <FormLabel mr={0}>
                                        Number of Days before passwords expire
                                    </FormLabel>
                                    <Input
                                        isDisabled={
                                            isLoading || isSwitchDisabled
                                        }
                                        value={passwordPolicy.numberOfDays}
                                        placeholder="Please enter number of days before passwords expire"
                                        onChange={(e) => {
                                            if (
                                                /^[1-9]\d*$/.test(
                                                    e.target.value,
                                                ) &&
                                                e.target.value.length <= 4
                                            ) {
                                                setErrors({
                                                    ...errors,
                                                    numberOfDays: '',
                                                });
                                                setPasswordPolicy({
                                                    ...passwordPolicy,
                                                    numberOfDays:
                                                        e.target.value,
                                                });
                                            } else if (e.target.value === '') {
                                                setErrors({
                                                    ...errors,
                                                    numberOfDays:
                                                        'Field is required.',
                                                });
                                                setPasswordPolicy({
                                                    ...passwordPolicy,
                                                    numberOfDays:
                                                        e.target.value,
                                                });
                                            }
                                        }}
                                        data-walkthroughid={
                                            walkthroughIds.adminPasswordPolicy
                                                .ufNumberOfDaysInput
                                        }
                                    />

                                    {errors?.numberOfDays ? (
                                        <FormErrorMessage>
                                            {errors.numberOfDays}
                                        </FormErrorMessage>
                                    ) : (
                                        <FormHelperText>eg. 180</FormHelperText>
                                    )}
                                </FormControl>

                                {/* Password reuse limit */}
                                <FormControl
                                    mb={4}
                                    mr={3}
                                    isRequired
                                    isInvalid={errors?.reuseLimit}>
                                    <Box className="flex items-center">
                                        <FormLabel>
                                            Password Reuse Limit
                                        </FormLabel>

                                        <Tooltip
                                            label={
                                                'Prohibited from reusing the last \'X\' passwords.'
                                            }
                                            hasArrow
                                            placement="bottom">
                                            <QuestionIcon mb={2} />
                                        </Tooltip>
                                    </Box>

                                    <Input
                                        isDisabled={
                                            isLoading || isSwitchDisabled
                                        }
                                        value={passwordPolicy.reuseLimit}
                                        placeholder="Please enter password reuse limit"
                                        onChange={(e) => {
                                            if (
                                                /^(0|[1-9]\d*)$/.test(
                                                    e.target.value,
                                                ) &&
                                                e.target.value.length <= 3
                                            ) {
                                                setErrors({
                                                    ...errors,
                                                    reuseLimit: '',
                                                });
                                                setPasswordPolicy({
                                                    ...passwordPolicy,
                                                    reuseLimit: e.target.value,
                                                });
                                            } else if (e.target.value === '') {
                                                setErrors({
                                                    ...errors,
                                                    reuseLimit:
                                                        'Field is required.',
                                                });
                                                setPasswordPolicy({
                                                    ...passwordPolicy,
                                                    reuseLimit: e.target.value,
                                                });
                                            } else {
                                                setErrors({
                                                    ...errors,
                                                    reuseLimit:
                                                        'Only numeric values between 0 and 100 are allowed.',
                                                });
                                            }
                                        }}
                                        data-walkthroughid={
                                            walkthroughIds.adminPasswordPolicy
                                                .ufPasswordReuseLimitInput
                                        }
                                    />
                                    {errors?.reuseLimit ? (
                                        <FormErrorMessage>
                                            {errors.reuseLimit}
                                        </FormErrorMessage>
                                    ) : (
                                        <FormHelperText>eg. 10</FormHelperText>
                                    )}
                                </FormControl>

                                {/* Minimum password age */}
                                <FormControl
                                    mb={4}
                                    mr={3}
                                    isRequired
                                    isInvalid={errors?.minPasswordAge}>
                                    <Box className="flex items-center">
                                        <FormLabel>
                                            Minimum Password Age (in days)
                                        </FormLabel>

                                        <Tooltip
                                            label={
                                                'How much time must elapse after the last password change before user can change it again.'
                                            }
                                            hasArrow
                                            placement="bottom">
                                            <QuestionIcon mb={2} />
                                        </Tooltip>
                                    </Box>

                                    <Input
                                        isDisabled={
                                            isLoading || isSwitchDisabled
                                        }
                                        value={passwordPolicy.minPasswordAge}
                                        placeholder="Please enter password reset limit"
                                        onChange={(e) => {
                                            if (
                                                /^(0|[1-9]\d*)$/.test(
                                                    e.target.value,
                                                ) &&
                                                e.target.value.length <= 3
                                            ) {
                                                setErrors({
                                                    ...errors,
                                                    minPasswordAge: '',
                                                });
                                                setPasswordPolicy({
                                                    ...passwordPolicy,
                                                    minPasswordAge:
                                                        e.target.value,
                                                });
                                            } else if (e.target.value === '') {
                                                setErrors({
                                                    ...errors,
                                                    minPasswordAge:
                                                        'Field is required.',
                                                });
                                                setPasswordPolicy({
                                                    ...passwordPolicy,
                                                    minPasswordAge:
                                                        e.target.value,
                                                });
                                            }
                                        }}
                                        data-walkthroughid={
                                            walkthroughIds.adminPasswordPolicy
                                                .ufMinPasswordAgeInput
                                        }
                                    />

                                    {errors?.minPasswordAge ? (
                                        <FormErrorMessage>
                                            {errors.minPasswordAge}
                                        </FormErrorMessage>
                                    ) : (
                                        <FormHelperText>eg. 5</FormHelperText>
                                    )}
                                </FormControl>
                            </Box>

                            <Box className="justify-content-center flex">
                                <Button
                                    className="disabled:cursor-not-allowed"
                                    disabled={
                                        isLoading ||
                                        isSwitchDisabled ||
                                        passwordPolicy?.numberOfDays === '' ||
                                        passwordPolicy?.reuseLimit === '' ||
                                        passwordPolicy?.minPasswordAge === ''
                                    }
                                    data-walkthroughid={
                                        walkthroughIds.adminPasswordPolicy
                                            .ufSaveBtn
                                    }
                                    _focus={{ boxShadow: 'none' }}
                                    onClick={(e) => handlePasswordPolicy(e)}>
                                    Save
                                </Button>
                            </Box>
                        </>
                    )}
                </>
            )}
        </>
    );
}
