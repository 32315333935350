import { useLocation } from 'react-router-dom';
import { AddRounded } from '@material-ui/icons';
import { Button } from 'react-bootstrap';

import SyncButton from './SyncButton';

import walkthroughIds from '../../users/walkthroughIds';
import useUsers from 'hooks/useUsers';

/**
 * @description Table header of users and resource accounts table
 * @typedef {Object} TableHeaderProps
 * @property {() => void} onClick - onOpen from useDisclosure hook
 * @param {TableHeaderProps} props
 * */
export default function TableHeader({ onClick }) {
    const { ufSyncBtn, ufBuilderBtn, ufHelperBtn, ufCsvUpdateBtn } =
        walkthroughIds;
    const { pathname } = useLocation();
    // const isUsersPath = pathname.includes('/users');
    const isReAccountsPath = pathname.includes('/resource-accounts');
    const {
        syncState,
        syncButtonCondition,
        handleBuilderBtn,
        handleCsvUpdate,
        handleSyncStateClick,
    } = useUsers();
    return (
        <div>
            <div className="mb-1 flex justify-start gap-4">
                {/* TODO: add resource account csv when feature finishes */}
                <Button
                    data-walkthroughid={ufCsvUpdateBtn}
                    className="btn btn-sm flex items-center focus:shadow-none"
                    variant="primary"
                    onClick={handleCsvUpdate}>
                    CSV Update
                </Button>
                {syncButtonCondition && (
                    <div data-walkthroughid={ufSyncBtn}>
                        <SyncButton
                            state={syncState}
                            onClick={handleSyncStateClick}
                        />
                    </div>
                )}
                {isReAccountsPath && (
                    <div data-walkthroughid={ufBuilderBtn}>
                        <Button
                            className="btn btn-sm !flex items-center focus:shadow-none"
                            onClick={handleBuilderBtn}>
                            <AddRounded fontSize="small" className="-ml-2" />
                            Builder
                        </Button>
                    </div>
                )}
                <div data-walkthroughid={ufHelperBtn}>
                    <Button
                        className="btn btn-primary my-button flex min-w-10 items-center justify-center !rounded-full focus:shadow-none"
                        onClick={onClick}>
                        ?
                    </Button>
                </div>
            </div>
        </div>
    );
}
