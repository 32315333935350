import chakratheme from '@/chakratheme';
import { ChakraProvider } from '@chakra-ui/react';
import { Routes, Route } from 'react-router-dom';
import { SBCaaS } from '../edit/Sbcaas';
import { OC } from '../edit/OC';
import EditAzureService from '../ServiceWizard/Azure/EditAzureService';
import DirectRouting from 'pages/services/provisionedservices/DirectRouting';
import AppAuthEdit from 'pages/services/provisionedservices/AppAuth';
// import OCC from 'pages/services/serviceprofiles/new/OCC';
// TPM
import TPM from 'pages/services/provisionedservices/TPM';
import { TpmProvider } from 'pages/tpm/TpmProvider';
import { TeamsPhoneMobile, TeamsPhoneMobileTrial } from 'pages/tpm';

import ProtectedNoMatch from 'components/v4/ProtectedNoMatch';
import path from '@/routes/constants';
import OCCEditService from '../ServiceDashboard/Services/OCC/OccEditService';
import ActivateServiceUsers from 'pages/services/serviceprofiles/vendorDefined/users';
import ActivateServiceNumbers from 'pages/services/serviceprofiles/vendorDefined/numbers';
import { DefinedAttributesProvider } from 'pages/services/serviceprofiles/vendorDefined/DefinedAttributesContext';
import EditVendorDefined from '../ServiceDashboard/Services/VendorDefined/EditVendorDefined';

// NOTE: some pages has no create route as they're in file with mode change
export default function ServiceRoutes() {
    const {
        SBCAAS,
        DIRECT_ROUTING,
        OPERATOR_CONNECT,
        OPERATOR_CONNECT_CONFERENCE,
        TEAM_PHONE_MOBILE,
        SERVICES_TPM_TRIAL,
        AZURE_CONNECT_SERVICE,
        APP_AUTH,
        EDIT,
        CREATE,
        VENDOR_DEFINED,
        SERVICES_VD_USERS,
        SERVICES_VD_NUMBERS,
    } = path;
    return (
        <ChakraProvider resetCSS theme={chakratheme}>
            <Routes>
                {/* SBC as a service */}
                <Route path={SBCAAS}>
                    <Route path={EDIT} element={<SBCaaS />} />
                </Route>
                {/* Operating Connect */}
                <Route path={OPERATOR_CONNECT}>
                    <Route path={EDIT} element={<OC />} />
                </Route>
                {/* OCC */}
                <Route path={OPERATOR_CONNECT_CONFERENCE}>
                    {/* <Route path={CREATE} element={<OCC />} /> */}
                    <Route path={EDIT} element={<OCCEditService />} />
                </Route>
                {/* Direct Routing */}
                <Route path={DIRECT_ROUTING}>
                    <Route
                        path={EDIT}
                        element={<DirectRouting mode="edit" />}
                    />
                </Route>
                {/* App Auth */}
                <Route path={APP_AUTH}>
                    <Route path={EDIT} element={<AppAuthEdit />} />
                </Route>
                {/* Azure */}
                <Route path={AZURE_CONNECT_SERVICE}>
                    <Route
                        path={CREATE}
                        element={<EditAzureService mode="ADD" />}
                    />
                    <Route
                        path={EDIT}
                        element={<EditAzureService mode="EDIT" />}
                    />
                </Route>
                {/* Tpm */}
                <Route path={TEAM_PHONE_MOBILE}>
                    <Route
                        index
                        element={
                            <TpmProvider>
                                <TeamsPhoneMobile />
                            </TpmProvider>
                        }
                    />
                    <Route
                        path={SERVICES_TPM_TRIAL}
                        element={
                            <TpmProvider>
                                <TeamsPhoneMobileTrial />
                            </TpmProvider>
                        }
                    />
                    <Route path={EDIT} element={<TPM mode="EDIT" />} />
                </Route>
                {/* Vendor Defined */}
                <Route path={VENDOR_DEFINED}>
                    <Route
                        path={EDIT}
                        element={
                            <DefinedAttributesProvider>
                                <EditVendorDefined />
                            </DefinedAttributesProvider>
                        }
                    />
                    <Route
                        path={SERVICES_VD_USERS}
                        element={<ActivateServiceUsers />}
                    />
                    <Route
                        path={SERVICES_VD_NUMBERS}
                        element={<ActivateServiceNumbers />}
                    />
                </Route>
                {/* No Match */}
                <Route path="*" element={<ProtectedNoMatch />} />
            </Routes>
        </ChakraProvider>
    );
}
