import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import { Box, useDisclosure } from '@chakra-ui/react';
import { EditIcon, LockIcon } from '@chakra-ui/icons';

// DR Card
import DashboardServiceCard from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCard';
import DashboardServiceCardDrStatusbar from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardDrStatusbar';
import DashboardServiceCardContent from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardContent';
import DashboardServiceCardHeader from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardHeader';
import DashboardServiceCardHeaderActionButton from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardHeaderActionButton';
import DirectRoutingStatus from './DirectRoutingStatus';

// Components
import Text from 'components/v4/Text';
import Image from 'components/v4/Image';
import ServiceDashboardItemSyncUsers from 'components/v4/Services/ServiceDashboard/ServiceDashboardItemSyncUsers';
import ServiceDashboardTenantId from 'components/v4/Services/ServiceDashboard/ServiceDashboardTenantId';
import { MenuItem } from 'components/v4';

// Util
import { dateStringToDate } from 'utils/utils';

// API
// import getAppAuthByID from 'API/AppAuth/getAppAuthById';
import getService from 'API/Service/getService';

// Assets
import sbcLogo from 'assets/images/sbcaas.png';
import msTeamsLogo from 'assets/images/ms_teams_logo.png';

import path from '@/routes/constants';

export function ServiceDashboardItemLastHealthCheck({ data }) {
    if (!data.lastProcessed)
        return (
            <Box
                sx={{
                    justifyContent: 'space-between',
                    marginBottom: '15px',
                }}>
                <Text
                    sx={{
                        fontWeight: '500',
                        minWidth: '120px',
                        marginBottom: '5px',
                        visibility: 'hidden',
                    }}>
                    {data.lastProcessed && 'Last Health Check'}
                </Text>
                <Text sx={{ visibility: 'hidden' }}>
                    {'Last Health Check,Last Health Check,Last Health Check'}
                </Text>
            </Box>
        );

    return (
        <Box
            sx={{
                justifyContent: 'space-between',
                marginBottom: '15px',
            }}>
            <Text
                sx={{
                    fontWeight: '500',
                    minWidth: '120px',
                    marginBottom: '5px',
                }}>
                {data.lastProcessed && 'Last Health Check'}
            </Text>
            <Text>{data.lastProcessed ?? ''}</Text>
        </Box>
    );
}

export default function DirectRoutingCard({
    service,
    serviceWizard,
    companyAppAuth,
    companyData,
}) {
    const modalState = useDisclosure();
    const accountScope = useSelector((state) => state.login.requiredScope);

    const [data, setData] = useState(service);
    const [authData, setAuthData] = useState(null);

    const [serviceObj, setServiceObj] = useState(null);

    const [currentCompanyAppAuth, setCurrentCompanyAppAuth] = useState(null);
    const [cardStatus, setCardStatus] = useState({
        color: 'gray',
        isAnimated: false,
        hasStripes: false,
        status: '...',
        to: null,
        toText: null,
        tooltip: false,
    });

    const [actionPermissions, setActionPermissions] = useState({
        menuEditServiceScope: 40,
    });
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (companyAppAuth && currentCompanyAppAuth?.id !== companyAppAuth.id) {
            //set MS Service Auth & Service Data.
            setData({
                ...serviceWizard.msServiceAuth,
                ...service,
                hasAppAuthData: true,
                lastProcessed:
                    serviceWizard.msServiceAuth?.lastProcessedTS &&
                    dateStringToDate(
                        serviceWizard.msServiceAuth?.lastProcessedTS,
                        'datetime',
                    ),
            });
            setAuthData(serviceWizard.msServiceAuth);
            setCurrentCompanyAppAuth(companyAppAuth);
        }
        // Full service details
        getService(service.id)
            .then((response) => {
                setServiceObj(response.data);
            })
            .finally(() => {});
    }, [companyAppAuth, service, serviceWizard.msServiceAuth]);

    useEffect(() => {
        if (serviceObj) {
            // Provide Access
            if (
                (serviceObj?.status === 2 && currentCompanyAppAuth === null) ||
                (serviceObj?.status === 2 &&
                    (data.userSync === false || data.graphAuth === false)) ||
                currentCompanyAppAuth?.status === 20
            ) {
                setActionPermissions((p) => ({
                    ...p,
                    ...{
                        menuProvideAccessScope: 20,
                    },
                }));
            } else {
                setActionPermissions((p) => {
                    if (
                        Object.prototype.hasOwnProperty.call(
                            p,
                            'menuProvideAccessScope',
                        )
                    ) {
                        delete p.menuProvideAccessScope;
                        return p;
                    }
                    return p;
                });
            }
        }
    }, [currentCompanyAppAuth, companyAppAuth, serviceObj, service]);

    const classes = classNames({
        flash: service.changes === 'added',
    });

    useEffect(() => {
        for (const [key, value] of Object.entries(actionPermissions)) {
            if (accountScope >= value || actionPermissions[key] === 20) {
                // for provide access case
                setShow(true);
            }
        }
    }, [actionPermissions]);

    // const ActionsMenu = memo(function ActionsMenu() {
    //     // Iterate obj, are any of these above or equal to my account level scope
    //     const [show, setShow] = useState(false);

    //     useEffect(() => {
    //         for (const [key, value] of Object.entries(actionPermissions)) {
    //             if (accountScope >= value) {
    //                 setShow(true);
    //             }
    //         }
    //     }, []);

    //     if (!show) return null;

    //     return (
    //         <DashboardServiceCardHeaderActionButton
    //             dataWalkthroughId={service.name}>
    //             {/* Edit service */}
    //             {accountScope >= actionPermissions.menuEditServiceScope && (
    //                 <MenuItem
    //                     icon={<EditIcon />}
    //                     onClick={() => {
    //                         navigate('/services', {
    //                             state: {
    //                                 serviceId: data.id,
    //                             },
    //                         });
    //                     }}
    //                     data-walkthroughid={`/dashboard-services/card/${service.name}/edit-service`}>
    //                     <Text marginBottom={'0'}>Edit Service</Text>
    //                 </MenuItem>
    //             )}

    //             {/* Selective Sync */}
    //             {accountScope >= actionPermissions.menuSelectiveSyncScope && (
    //                 <MenuItem
    //                     icon={<RepeatIcon />}
    //                     onClick={() => {
    //                         serviceWizard.setSelectiveSyncEditData(authData);
    //                         serviceWizard.setSelectedServiceProfile(service);
    //                         // is there an auth already?
    //                         // yes - leave edit menu as is
    //                         // no - send them to the flow
    //                         if (serviceWizard?.msServiceAuth?.id) {
    //                             serviceWizard.setFlow('SelectiveSyncUsersEdit');
    //                         } else {
    //                             serviceWizard.drFlow();
    //                         }
    //                     }}
    //                     data-walkthroughid={`/dashboard-services/card/${service.name}/selective-sync`}>
    //                     <Text marginBottom={'0'}>Selective Sync</Text>
    //                 </MenuItem>
    //             )}

    //             {/* Provide Access */}
    //             {accountScope >= actionPermissions.menuProvideAccessScope && (
    //                 <MenuItem
    //                     icon={<LockIcon />}
    //                     onClick={async () => {
    //                         if (
    //                             serviceObj?.company?.selectiveSyncPolicy === 2
    //                         ) {
    //                             if (serviceWizard.msServiceAuth?.id) {
    //                                 await serviceWizard.microsoftAuth();
    //                             } else {
    //                                 serviceWizard.drFlow();
    //                             }
    //                         } else if (
    //                             serviceObj?.company?.selectiveSyncPolicy ===
    //                                 1 ||
    //                             serviceObj?.company?.selectiveSyncPolicy === 0
    //                         ) {
    //                             await serviceWizard.microsoftAuth();
    //                         }
    //                     }}
    //                     data-walkthroughid={`/dashboard-services/card/${service.name}/provide-access`}>
    //                     <Text marginBottom={'0'}>Provide Access</Text>
    //                 </MenuItem>
    //             )}
    //         </DashboardServiceCardHeaderActionButton>
    //     );
    // });

    return (
        <>
            <ServiceDashboardTenantId {...modalState} />
            <DashboardServiceCard
                className={classes}
                dataWalkthroughId={service.name}>
                <DashboardServiceCardDrStatusbar status={cardStatus} />
                <DashboardServiceCardHeader>
                    <Text fontSize="lg" className="truncate" marginBottom={'0'}>
                        {data.name}
                    </Text>
                    {/* <ActionsMenu /> NOTE: comment this because of flickering button */}
                    {show && (
                        <DashboardServiceCardHeaderActionButton
                            id={'direct-routing'}
                            dataWalkthroughId={service.name}>
                            {/* Edit service */}
                            {accountScope >=
                                actionPermissions.menuEditServiceScope && (
                                <Link
                                    id="edit-dr-service"
                                    className="bg-transparent !no-underline hover:text-inherit"
                                    to={`${path.SERVICES_DIRECT_ROUTING}/${data.id}/edit`}
                                    state={{ serviceId: data.id }}>
                                    <MenuItem
                                        icon={<EditIcon />}
                                        // onClick={() => {
                                        //     navigate('/services', {
                                        //         state: {
                                        //             serviceId: data.id,
                                        //         },
                                        //     });
                                        // }}
                                        data-walkthroughid={`/dashboard-services/card/${service.name}/edit-service`}>
                                        <Text marginBottom={'0'}>
                                            Edit Service
                                        </Text>
                                    </MenuItem>
                                </Link>
                            )}

                            {/* Provide Access */}
                            {accountScope >=
                                actionPermissions.menuProvideAccessScope && (
                                <MenuItem
                                    id="dr-provide-access"
                                    icon={<LockIcon />}
                                    onClick={async () => {
                                        if (
                                            serviceObj?.company
                                                ?.selectiveSyncPolicy === 2
                                        ) {
                                            if (
                                                serviceWizard.msServiceAuth?.id
                                            ) {
                                                await serviceWizard.microsoftAuth();
                                            } else {
                                                serviceWizard.drFlow();
                                            }
                                        } else if (
                                            serviceObj?.company
                                                ?.selectiveSyncPolicy === 1 ||
                                            serviceObj?.company
                                                ?.selectiveSyncPolicy === 0
                                        ) {
                                            await serviceWizard.microsoftAuth();
                                        }
                                    }}
                                    data-walkthroughid={`/dashboard-services/card/${service.name}/provide-access`}>
                                    <Text marginBottom={'0'}>
                                        Provide Access
                                    </Text>
                                </MenuItem>
                            )}
                        </DashboardServiceCardHeaderActionButton>
                    )}
                </DashboardServiceCardHeader>
                <DashboardServiceCardContent>
                    <Box>
                        <Image
                            src={
                                service.serviceType === 'SBCaaS'
                                    ? sbcLogo
                                    : msTeamsLogo
                            }
                            sx={{
                                maxWidth: '50px',
                                margin: '0 auto 15px auto',
                            }}
                        />

                        <Box
                            sx={{
                                display: 'flex',
                                flexDir: 'column',
                            }}>
                            {/* Status */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: '15px',
                                }}>
                                <Text
                                    sx={{
                                        fontWeight: '500',
                                    }}>
                                    Status
                                </Text>
                                <DirectRoutingStatus
                                    companyData={companyData}
                                    serviceObj={serviceObj}
                                    authData={authData}
                                    cardStatus={cardStatus}
                                    setCardStatus={setCardStatus}
                                    accountScope={accountScope}
                                />
                            </Box>

                            {/* Sync */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: '15px',
                                }}>
                                <Text
                                    sx={{
                                        fontWeight: '500',
                                    }}>
                                    Sync
                                </Text>
                                <Text sx={{ textAlign: 'left' }}>
                                    <ServiceDashboardItemSyncUsers
                                        data={data}
                                    />
                                </Text>
                            </Box>

                            {/* Last Health Check */}
                            <ServiceDashboardItemLastHealthCheck data={data} />
                        </Box>
                    </Box>
                </DashboardServiceCardContent>
            </DashboardServiceCard>
        </>
    );
}
