import {useEffect, useState, useRef} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Box, SlideFade} from '@chakra-ui/react';
import {useSelector} from 'react-redux';
import {InfoIcon, ExternalLinkIcon} from '@chakra-ui/icons';

import Text from '../../components/v4/Text';
import Dropdown from '../../components/v4/Select';
import DropdownSingle from '../../components/v4/SelectSingle';
import Image from '../../components/v4/Image';
import FormLabel from '../../components/v4/FormLabel';
import Heading from '../../components/v4/Heading';
import Button from '../../components/v4/Button';
import Input from '../../components/v4/Input';
import Tooltip from '../../components/v4/Tooltip';
import Alert from '../../components/v4/Alert';
import AlertDescription from '../../components/v4/AlertDescription';
import AlertIcon from '../../components/v4/AlertIcon';
import PartnerLoginPreview from '../../components/v4/partners/PartnerBranding/PartnerBrandingLoginPreview';
import ImageLoader from '../../components/v4/ImageLoader';

import useDetectFileType from '../../hooks/useDetectFileType';
import useFieldValidation from '../../hooks/useFieldValidation';
import useFileUpload from '../../hooks/useFileUpload';
import usUrlAssetToFile from '../../hooks/usUrlAssetToFile';

import config from '../../config.json';

import PartnerTrialCreate from './PartnerTrialCreate';

import {
    mainHeadingStyles,
    containerStyles,
    stepContainersStyles,
    stepContainerHeading,
    stepContainerStyles,
    stepContentContainerStyles,
    stepSpacerStyles,
    stepNumberParagraphSpacerStyles,
    dropdownStyles,
    textInputStyles,
    uploadLogoBtnContainerStyles,
    logoPreviewContainerStyles,
    logoPreviewImageStyles,
    logoContainerStyles,
    setupBtnContainer,
    validateCnameBtnStyles,
    hrStyles,
    cnameBtnValidationContainer,
} from './styles';
import {brandOptions, albyDevApiKey} from './consts';
import {Spinner} from '@chakra-ui/react';

import tcapLogo from '../../assets/images/TCAP_Logo.svg';
import tcapLogoDark from '../../assets/images/TCAP_Logo_white.svg';

// Parts
import InvalidGuid from './InvalidGuid';
import NotfoundGuid from './NotfoundGuid';
import NoGuid from './NoGuid';

export default function PartnerTrial() {
    // Logos
    const imagePathLightMode = useSelector(
        (state) => state.login.imagePathLightMode,
    );
    const imagePathDarkMode = useSelector(
        (state) => state.login.imagePathDarkMode,
    );

    // General hooks
    const [searchParams] = useSearchParams();

    // Refs
    const logoRef = useRef(null);
    const logoDarkRef = useRef(null);
    const logoFileRef = useRef(null);
    const logoDarkFileRef = useRef(null);

    // Flags
    const [isValid, setIsValid] = useState(false); // Form is ready to be submitted
    const [isValidLead, setIsValidLead] = useState(null); // Valid (200), Invalid (400), NotFound (404), NoGuid (no ?RegistrationId)
    const [isBranded, setIsBranded] = useState(null);
    const [isEmailInUse, setIsEmailInUse] = useState(false);
    const [isCnameValid, setIsCnameValid] = useState(null); // Empty (no CNAME entry) Wrong (found record, pointing to wrong place) Multiple (multiple CNAMEs) Valid (pointing to portal.tcap.cloud)
    const [hasWrongCname, setHasWrongCname] = useState(null);
    const [hasCreateErrors, setHasCreateErrors] = useState(null);
    const [isStageTwo, setIsStageTwo] = useState(false);
    const [isValidatingCname, setIsValidatingCname] = useState(false);

    // Options
    const [registrationId, setRegistrationId] = useState();
    const [lead, setLead] = useState();
    const [availableRegions, setAvailableRegions] = useState([]);
    const [emailOveride, setEmailOveride] = useState('');

    // Fields
    const [region, setRegion] = useState(null);
    const [cname, setCname] = useState('');
    const {
        uploadHandler: logoWhiteHandler,
        fileReaderResult: logoWhiteUploaded,
        file: logoWhiteFile,
    } = useFileUpload({
        allowedExtensions: ['.png', '.svg'],
        logoFileRef: logoFileRef,
    });
    const {
        uploadHandler: logoDarkHandler,
        fileReaderResult: logoDarkUploaded,
        file: logoDarkFile,
    } = useFileUpload({
        allowedExtensions: ['.png', '.svg'],
        logoFileRef: logoDarkFileRef,
    });

    // Logos file types
    const logoFileType = useDetectFileType(
        logoWhiteUploaded || config.imageBase + imagePathLightMode,
    );
    const darkLogoFileType = useDetectFileType(logoDarkUploaded);

    // Validation (new email)
    const {validationErrors, validatefn} = useFieldValidation(
        {emailOveride},
        {
            emailOveride: [
                {type: 'isEmail', message: 'Please enter a valid email.'},
            ],
        },
    );

    // Image url to File object hook
    const {convert} = usUrlAssetToFile();

    // Getters
    function getLead(registrationID) {
        fetch(
            `https://albydev.pingco.com.au/TcapLeads/api/leads/${registrationID}?apikey=${albyDevApiKey}`,
            {
                headers: {
                    'x-requestscope': '20',
                    accept: 'application/json, text/plain, */*',
                },
                method: 'GET',
            },
        ).then(async (response) => {
            const data = await response.json();
            if (response.status === 200) {
                setLead(data);
                setIsValidLead('Valid');
            } else if (response.status === 400) {
                setIsValidLead('Invalid');
            } else if (response.status === 404) {
                setIsValidLead('NotFound');
            }
        });
    }
    function getRegions() {
        fetch(`https://portal.tcap.cloud/api/ui/PartnerTrial/Regions`, {
            headers: {
                'x-requestscope': '20',
                accept: 'application/json, text/plain, */*',
            },
            method: 'GET',
        }).then(async (response) => {
            const data = await response.json();
            setAvailableRegions([...['Select a region'], ...data]);
        });
    }

    // Trial create
    function createPartnerTrial() {
        // Host param
        const forgotPasswordHost = cname ? cname : `portal.tcap.cloud`;
        const forgotPasswordUrl = `${forgotPasswordHost}/ui/change-password?token=[TOKEN]`;

        // HostHeader param
        const hostHeader = cname ?? '';

        // First/Last name
        const [firstName, lastName] = lead.contactName.split(' ');

        // Params
        const queryParams = `?Region=${region}&HostHeader=${hostHeader}&PartnerName=${
            lead.organizationName
        }&EmailAddress=${encodeURIComponent(
            emailOveride || lead.email,
        )}&FirstName=${firstName}&LastName=${
            lastName || ''
        }&Host=${forgotPasswordUrl}`;

        // Fetch params
        let fetchParams = {
            method: 'POST',
            headers: {
                'x-requestscope': '20',
                //'Content-Type': 'multipart/form-data',
            },
        };

        // Logo
        let formData = new FormData();
        if (logoWhiteFile) {
            formData.append('File', logoFileRef.current.files[0]);
        }
        if (formData.get('File')) {
            fetchParams = {
                ...fetchParams,
                body: formData,
            };
        }

        // Create TCAP Partner Trial
        fetch(
            `https://portal.tcap.cloud/api/ui/PartnerTrial/Register${queryParams}`,
            fetchParams,
        )
            .then(async (response) => {
                if (response.status === 200) {
                    consumeLead();
                    setIsEmailInUse(false);
                    setHasCreateErrors(null);
                    return;
                }
                const data = await response.json();
                if (
                    data?.message ===
                    'Unable to create a new Partner using the supplied Email Address.'
                ) {
                    setIsEmailInUse(true);
                    setHasCreateErrors(data.message);
                    return;
                } else {
                    setIsEmailInUse(false);
                    setHasCreateErrors(data.message);
                    return;
                }
            })
            .catch((error) => {
                // 500's
                setHasCreateErrors(
                    'Something went wrong. Please contact support.',
                );
            });
    }
    function consumeLead() {
        // Create TCAP Partner Trial
        fetch(
            `https://albydev.pingco.com.au/TcapLeads/api/leads/${registrationId}?apikey=${albyDevApiKey}`,
            {
                method: 'PUT',
                headers: {
                    'x-requestscope': '20',
                },
            },
        )
            .then(async (response) => {
                if (response.status === 200) {
                    setIsStageTwo(true);
                    setHasCreateErrors(null);
                }
            })
            .catch((error) => {
                // 500's
                setHasCreateErrors(
                    'Something went wrong. Please contact support.',
                );
            });
    }

    // Handlers
    function onEmailOverideChange(e) {
        setEmailOveride(e.target.value);
    }
    function onValidateCname() {
        if (cname) {
            setIsValidatingCname(true);
            setTimeout(() => {
                fetch(
                    `https://dns.google/resolve?name=${cname}&type=CNAME`,
                    {},
                ).then(async (response) => {
                    const data = await response.json();

                    // No records
                    if (!data.Answer) {
                        setIsCnameValid('Empty');
                        return;
                    } else {
                        // Multiple records
                        if (data.Answer.length > 1) {
                            setIsCnameValid('Multiple');
                            return;
                        } else {
                            // Valid record (NOTE the . - google add this to the response)
                            if (data.Answer[0].data === 'portal.tcap.cloud.') {
                                setHasWrongCname(null);
                                setIsCnameValid('Valid');
                                return;
                            } else {
                                // Invalid
                                setHasWrongCname(
                                    data.Answer[0].data.slice(0, -1),
                                );
                                setIsCnameValid('Wrong');
                            }
                        }
                    }
                });

                setIsValidatingCname(false);
            }, 3000);
        }
    }
    function onSetupBtnClick() {
        if (isValid) {
            if (isEmailInUse) {
                if (!validatefn()) {
                    return;
                } else {
                    createPartnerTrial();
                }
            } else {
                createPartnerTrial();
            }
        } else {
            setIsStageTwo(false);
        }
    }
    function setRegionHandler(e) {
        const val = e.target.value;
        val === 'Select a region' ? setRegion(null) : setRegion(val);
    }
    function setIsBrandedHandler(e) {
        const val = e.target.value;
        val === 'true' && setIsBranded(true);
        val === 'false' && setIsBranded(false);
        val === 'Select an option' && setIsBranded(null);
    }
    function setCnameHandler(e) {
        // Reset the validation state whenever typing
        setIsCnameValid(null);
        setHasWrongCname(null);

        // Set cname
        setCname(e.target.value);
    }

    // Fire once at the start to direct the traffic, the traffic being
    // the state of the GUID
    useEffect(() => {
        const registrationID = searchParams.get('RegistrationId');
        if (registrationID) {
            setRegistrationId(registrationID);
            getLead(registrationID);
            getRegions();
        } else {
            setIsValidLead('NoGuid');
        }
    }, []);

    // Used to determine when the form is acceptable to POST (validation)
    useEffect(() => {
        // Ignore the first render, we only want to check if the form is valid
        // but we still want to set it to false, incase they swap from false to true
        if (isBranded === null || region === null) {
            setIsValid(false);
            return;
        }

        if (isBranded) {
            // Branding validation goes here
            if (isCnameValid === 'Valid') {
                setIsValid(true);
            } else {
                setIsValid(false);
            }
        } else {
            // Non branding validation goes here
            const hasSelectedARegion = region !== null;
            const hasSelectedBrandOptions = isBranded !== null;
            if (hasSelectedBrandOptions && hasSelectedARegion) setIsValid(true);
        }
    }, [isBranded, region, logoWhiteFile, isCnameValid]);

    return (
        <>
            {isStageTwo && (
                <PartnerTrialCreate
                    registrationId={registrationId}
                    lead={lead}
                    emailOveride={emailOveride || false}
                    region={region}
                    cname={cname}
                    logoWhiteFile={logoWhiteFile}
                    logoDarkFile={logoDarkFile}
                    isBranded={isBranded}
                    logoFileType={logoFileType}
                />
            )}
            {isValidLead === 'Invalid' && !isStageTwo && <InvalidGuid />}
            {isValidLead === 'NotFound' && !isStageTwo && <NotfoundGuid />}
            {isValidLead === 'NoGuid' && !isStageTwo && <NoGuid />}
            {isValidLead === 'Valid' && !isStageTwo && (
                <Box sx={containerStyles}>
                    {/* Logo */}
                    <ImageLoader
                        image={config.imageBase + imagePathLightMode}
                        objectProps={{
                            alt: 'Domain Logo',
                            style: {
                                display: 'block',
                                maxWidth: '300px',
                                maxHeight: '300px',
                                margin: '0 auto 20px auto',
                            },
                            type: 'image/svg+xml',
                        }}
                        imageProps={{
                            sx: {
                                display: 'block',
                                maxWidth: '300px',
                                maxHeight: '300px',
                                margin: '0 auto 20px auto',
                            },
                        }}
                    />

                    {/* Main Heading */}
                    <Heading as="h3" size="xl" sx={mainHeadingStyles}>
                        {lead.organizationName} - TCAP Setup Wizard
                    </Heading>

                    {/* Step One */}
                    <Box sx={stepContainersStyles}>
                        <Box sx={stepContainerStyles}>
                            <Heading
                                as="h3"
                                fontSize="xl"
                                sx={stepContainerHeading}>
                                Step 1
                            </Heading>
                            <Text>
                                Select the region you would like to start your
                                trial with
                            </Text>
                        </Box>
                        <Box sx={stepContentContainerStyles}>
                            <FormLabel
                                size="md"
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: 'md',
                                    marginBottom: '13px',
                                }}>
                                What region would you like?
                            </FormLabel>
                            <DropdownSingle
                                sx={dropdownStyles}
                                options={availableRegions}
                                onChange={setRegionHandler}
                            />
                        </Box>
                    </Box>

                    {/* Step Two */}
                    {region && (
                        <>
                            <Box sx={stepSpacerStyles}></Box>
                            <SlideFade
                                style={{transitionDuration: '100ms'}}
                                in={region}
                                offsetY="20px"
                                animateOpacity>
                                <Box sx={stepContainersStyles}>
                                    <Box sx={stepContainerStyles}>
                                        <Heading
                                            as="h3"
                                            size="md"
                                            sx={stepContainerHeading}>
                                            Step 2
                                        </Heading>
                                        <Text>
                                            Would you like to brand TCAP as
                                            yourself or use our branding?
                                        </Text>
                                    </Box>
                                    <Box sx={stepContentContainerStyles}>
                                        <FormLabel
                                            sx={{
                                                fontWeight: 'bold',
                                                fontSize: 'md',
                                                marginBottom: '13px',
                                            }}>
                                            Would you like to brand TCAP?
                                        </FormLabel>
                                        <Dropdown
                                            sx={dropdownStyles}
                                            options={brandOptions}
                                            onChange={setIsBrandedHandler}
                                        />
                                    </Box>
                                </Box>
                            </SlideFade>
                        </>
                    )}

                    {/* Step Three */}
                    {isBranded && (
                        <>
                            <Box sx={stepSpacerStyles}></Box>

                            <SlideFade
                                style={{transitionDuration: '100ms'}}
                                in={isBranded}
                                offsetY="20px"
                                animateOpacity>
                                <Box sx={stepContainersStyles}>
                                    <Box sx={stepContainerStyles}>
                                        <Heading
                                            as="h3"
                                            size="md"
                                            sx={stepContainerHeading}>
                                            Step 3
                                        </Heading>
                                        <Text>
                                            Upload a logo with a transparent
                                            background. Use SVG or PNG format.
                                        </Text>
                                    </Box>
                                    <Box sx={stepContentContainerStyles}>
                                        {/* Logo container */}
                                        <Box sx={uploadLogoBtnContainerStyles}>
                                            {/* Logo - White Bg */}
                                            <Box sx={logoContainerStyles}>
                                                <Text
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        fontSize: 'md',
                                                        marginBottom: '13px',
                                                    }}>
                                                    Logo, white background
                                                </Text>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDir: 'column',
                                                        marginBottom: '13px',
                                                    }}>
                                                    <Box
                                                        sx={
                                                            logoPreviewContainerStyles
                                                        }>
                                                        {logoFileType ===
                                                            'png' && (
                                                            <Image
                                                                sx={
                                                                    logoPreviewImageStyles
                                                                }
                                                                ref={logoRef}
                                                                src={
                                                                    logoWhiteUploaded ||
                                                                    config.imageBase +
                                                                        imagePathLightMode
                                                                }
                                                            />
                                                        )}
                                                        {logoFileType ===
                                                            'svg' && (
                                                            <object
                                                                alt="Domain Logo"
                                                                style={
                                                                    logoPreviewImageStyles
                                                                }
                                                                data={
                                                                    logoWhiteUploaded ||
                                                                    config.imageBase +
                                                                        imagePathLightMode
                                                                }
                                                                type="image/svg+xml"></object>
                                                        )}
                                                    </Box>
                                                    <Button
                                                        as={FormLabel}
                                                        size="xs"
                                                        id="image-input"
                                                        sx={
                                                            uploadLogoBtnContainerStyles
                                                        }>
                                                        Choose File
                                                        <input
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                            type="file"
                                                            id="image-input"
                                                            accept=".svg,.png"
                                                            onChange={
                                                                logoWhiteHandler
                                                            }
                                                            ref={logoFileRef}
                                                        />
                                                    </Button>
                                                </Box>
                                            </Box>

                                            {/* Logo - Dark Bg */}
                                            <Box sx={logoContainerStyles}>
                                                <Text
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        fontSize: 'lg',
                                                        marginBottom: '13px',
                                                    }}>
                                                    Logo, dark background
                                                </Text>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDir: 'column',
                                                        marginBottom: '13px',
                                                    }}>
                                                    <Box
                                                        sx={{
                                                            ...logoPreviewContainerStyles,
                                                            ...{
                                                                background:
                                                                    'gray.900',
                                                                borderColor:
                                                                    'gray.700',
                                                            },
                                                        }}>
                                                        {logoFileType ===
                                                            'png' && (
                                                            <Image
                                                                sx={
                                                                    logoPreviewImageStyles
                                                                }
                                                                ref={
                                                                    logoDarkRef
                                                                }
                                                                src={
                                                                    logoWhiteUploaded ||
                                                                    config.imageBase +
                                                                        imagePathDarkMode
                                                                }
                                                            />
                                                        )}
                                                        {logoFileType ===
                                                            'svg' && (
                                                            <object
                                                                alt="Domain Logo"
                                                                style={
                                                                    logoPreviewImageStyles
                                                                }
                                                                data={
                                                                    logoWhiteUploaded ||
                                                                    config.imageBase +
                                                                        imagePathDarkMode
                                                                }
                                                                type="image/svg+xml"></object>
                                                        )}
                                                    </Box>

                                                    <Tooltip
                                                        hasArrow
                                                        label="If you would like to use a seperate dark logo file, please contact us.">
                                                        <InfoIcon
                                                            boxSize={5}
                                                            size="xl"
                                                            sx={{
                                                                margin: '15px 0 0 auto',
                                                                color: 'brand.500',
                                                            }}
                                                        />
                                                    </Tooltip>
                                                    {/*<Button
                                                        as={FormLabel}
                                                        size="xs"
                                                        id="image-input"
                                                        sx={
                                                            uploadLogoBtnContainerStyles
                                                        }>
                                                        Choose File
                                                        <input
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                            type="file"
                                                            id="image-input"
                                                            accept=".svg,.png"
                                                            onChange={
                                                                logoDarkHandler
                                                            }
                                                            ref={
                                                                logoDarkFileRef
                                                            }
                                                        />
                                                    </Button>*/}
                                                </Box>
                                            </Box>
                                        </Box>

                                        {/* Preview Login */}
                                        <SlideFade
                                            style={{
                                                transitionDuration: '100ms',
                                            }}
                                            in={true}
                                            offsetY="20px"
                                            animateOpacity>
                                            <PartnerLoginPreview
                                                paddingOveride={{
                                                    padding: 0,
                                                }}
                                                logo={
                                                    logoWhiteUploaded ||
                                                    config.imageBase +
                                                        imagePathLightMode
                                                }
                                                email={lead.email}
                                                favicon={null}
                                                brandName={
                                                    lead.organizationName
                                                }
                                                backgroundOptions={{
                                                    backgroundType: 0,
                                                }}
                                            />
                                        </SlideFade>
                                    </Box>
                                </Box>
                            </SlideFade>

                            <Box sx={stepSpacerStyles}></Box>
                        </>
                    )}

                    {/* Step Four */}
                    {isBranded && (
                        <>
                            <SlideFade
                                style={{transitionDuration: '100ms'}}
                                in={isBranded}
                                offsetY="20px"
                                animateOpacity>
                                <Box sx={stepContainersStyles}>
                                    <Box sx={stepContainerStyles}>
                                        <Heading
                                            as="h3"
                                            size="md"
                                            sx={stepContainerHeading}>
                                            Step 4
                                        </Heading>
                                        <Text>
                                            Choose a URL / Domain for your
                                            portal. We recommend
                                            portal.yourdomain.com
                                        </Text>

                                        <Box
                                            sx={stepNumberParagraphSpacerStyles}
                                        />

                                        <Text>
                                            You will need to create a CNAME
                                            record and point this to
                                            portal.tcap.cloud.
                                        </Text>

                                        <Box
                                            sx={stepNumberParagraphSpacerStyles}
                                        />

                                        <Text>
                                            Once setup your SSL site will be
                                            encrypted within 24 hours.
                                        </Text>

                                        <Box
                                            sx={stepNumberParagraphSpacerStyles}
                                        />

                                        <Text>
                                            Please keep in mind it can take upto
                                            24 hours for your CNAME record to
                                            fully propagate.
                                        </Text>
                                    </Box>
                                    <Box sx={stepContentContainerStyles}>
                                        <FormLabel
                                            sx={{
                                                fontWeight: 'bold',
                                                fontSize: 'md',
                                                marginBottom: '13px',
                                            }}>
                                            Enter the address you would like to
                                            use for branding. This address will
                                            require a CNAME record pointing to
                                            portal.tcap.cloud.
                                        </FormLabel>

                                        <Input
                                            value={cname}
                                            onChange={setCnameHandler}
                                            sx={textInputStyles}
                                            placeholder="portal.domain.com"
                                        />

                                        <Box sx={cnameBtnValidationContainer}>
                                            {isCnameValid === 'Empty' && (
                                                <Text sx={{color: 'red.500'}}>
                                                    No CNAME record found.
                                                </Text>
                                            )}
                                            {isCnameValid === 'Wrong' && (
                                                <Text sx={{color: 'red.500'}}>
                                                    We found a CNAME record
                                                    pointing to {hasWrongCname}.
                                                    It should be pointing to
                                                    portal.tcap.cloud.
                                                </Text>
                                            )}
                                            {isCnameValid === 'Multiple' && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDir: 'column',
                                                    }}>
                                                    <Text
                                                        sx={{color: 'red.500'}}>
                                                        We found multiple CNAME
                                                        records. Please ensure
                                                        you only have one
                                                        pointing to
                                                        portal.tcap.cloud. Here
                                                        are the records we
                                                        found:
                                                    </Text>
                                                    <Box>
                                                        {hasWrongCname.map(
                                                            (err) => (
                                                                <Text
                                                                    sx={{
                                                                        color: 'red.500',
                                                                    }}>
                                                                    -{' '}
                                                                    {err.data.slice(
                                                                        0,
                                                                        -1,
                                                                    )}
                                                                </Text>
                                                            ),
                                                        )}
                                                    </Box>
                                                </Box>
                                            )}
                                            {isCnameValid === 'Valid' && (
                                                <Text sx={{color: 'green.500'}}>
                                                    Validation Passed
                                                </Text>
                                            )}
                                            <Button
                                                disabled={isValidatingCname}
                                                size="xs"
                                                sx={validateCnameBtnStyles}
                                                onClick={onValidateCname}>
                                                {isValidatingCname ? (
                                                    <>
                                                        <Spinner
                                                            size="xs"
                                                            sx={{
                                                                marginRight:
                                                                    '3px',
                                                            }}
                                                        />
                                                        Validating...
                                                    </>
                                                ) : (
                                                    'Validate'
                                                )}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </SlideFade>
                        </>
                    )}
                    <Box sx={hrStyles} />

                    {hasCreateErrors && (
                        <Alert
                            status="error"
                            sx={{
                                borderRadius: '10px',
                                marginBottom: '20px',
                            }}>
                            <AlertIcon />
                            <AlertDescription>
                                {hasCreateErrors}
                                {` `}
                                {isEmailInUse &&
                                    'Please enter a different email address in the field below, and click the button again.'}
                            </AlertDescription>
                        </Alert>
                    )}

                    {isEmailInUse && (
                        <>
                            <FormLabel>New email</FormLabel>
                            <Input
                                onChange={onEmailOverideChange}
                                sx={{marginBottom: '20px'}}
                            />
                            {validationErrors?.emailOveride && (
                                <Text marginTop="-10px" color="red.500">
                                    You need to enter a valid email address.
                                </Text>
                            )}
                        </>
                    )}

                    <Box sx={setupBtnContainer}>
                        <Button disabled={!isValid} onClick={onSetupBtnClick}>
                            Setup Now
                        </Button>
                    </Box>
                </Box>
            )}
        </>
    );
}
