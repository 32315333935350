//used for setInterval in js
//cleans up intervals via clearInterval for each
//TODO: NOT USED, CLEAN
const initIntervalTrackerState = {
    intervalIDs: [],
};

export const intervalTrackerReducer = (
    state = initIntervalTrackerState,
    action,
) => {
    switch (action.type) {
        case 'ADD_INTERVAL_ID':
            return {
                ...state,
                intervalIDs: [...state.intervalIDs, action.payload],
            };
        case 'REMOVE_ALL_INTERVAL_IDS':
            state.intervalIDs.forEach((intervalID) => {
                clearInterval(intervalID);
            });
            return {
                ...state,
                intervalIDs: [],
            };
        case 'CLEAR_TOKEN':
            return {
                intervalIDs: [],
            };
        default:
            return state;
    }
};
