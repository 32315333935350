import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

import { TableAndModal } from '../../../../components/';
import { axios } from '../../../../services/axios';
import { extractFilename } from '../../../../utils/utils';
import config from '../../../../config.json';
import ModalApprovalContent from '../ModalApprovalContent';
import ModalEditContent from './InvoiceLineEdit';
import { Box, Button, ChakraProvider } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import chakratheme from 'src/chakratheme';
import { NegativeSixDP } from 'components/maintenance/NumberFormatCustom';
import walkthroughIds from '../walkthroughIds';

const EDITABLE_STATUS = [0, 1, 8];
/*
 * Component rendered when somoene clicks the invoice lines button on the Invoices.jsx component
 */
const InvoiceLines = () => {
    const dispatch = useDispatch();
    const tableRef = useRef();
    const [currentInvoice, setCurrentInvoice] = useState(null);

    // scope from state
    const { requiredScope } = useSelector((state) => {
        return { ...state.login };
    });

    // table data from state
    const {
        child,
        state: tableState,
        parent,
        invoiceType,
    } = useSelector((state) => {
        return { ...state.table };
    });

    // modal data from state
    const {
        data: modalData,
        state: modalState,
        mode: modalMode,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    const { invoiceLines } = walkthroughIds;

    // default columns for the table
    const defaultColumns = useMemo(() => {
        return [
            {
                title: 'Date/Time (UTC)',
                field: 'createTS',
                type: 'datetime',
                width: '20%',
                defaultSort: 'desc',
            },
            {
                title: 'Item Code',
                field: 'itemCode',
                lookup: {
                    CDR: 'CDR',
                    Company: 'Company',
                    NumberBlock: 'Number Block',
                    NumberBlockPorting: 'Number Block Porting',
                    RGS: 'RGS',
                    TeamsUser: 'Teams User',
                    Trunk: 'Trunk',
                },
            },

            {
                title: ' Unit Price',
                field: 'unitPrice',
                doNotOverrideSort: true,
                render: (rowData) => {
                    return (
                        <NegativeSixDP
                            displayType={'text'}
                            value={rowData['unitPrice']}
                        />
                    );
                },
            },
            {
                title: 'Qty',
                field: 'quantity',
                width: '5%',
            },
            {
                title: 'Line Total',
                field: 'lineTotal',
                doNotOverrideSort: true,
                render: (rowData) => {
                    return (
                        <NegativeSixDP
                            displayType={'text'}
                            value={rowData['lineTotal']}
                        />
                    );
                },
            },
            {
                title: 'Description',
                field: 'description',
                grouping: false,
                filtering: false,
                sorting: false,
                width: '45%',
            },
        ];
    }, []);

    // Total of the lines on the UI
    const currentTotal = useMemo(() => {
        if (tableState?.data) {
            return tableState.data.reduce((result, currentValue) => {
                result = result + currentValue?.lineTotal ?? 0;
                return result;
            }, 0);
        }
    }, [tableState?.data]);

    const scopeCanEdit = requiredScope > [20, 40, 60][invoiceType];

    /*
     * Component for the Invoice Total and Update Status button at top of hte invoice lines component
     */
    const titleProps = currentInvoice && (
        <Box className="flex flex-row justify-between gap-1 w-full">
            <TextField
                disabled
                className="mt-1"
                label="Invoice Total"
                value={currentTotal}
                InputProps={{
                    inputComponent: NegativeSixDP,
                }}
                data-walkthroughid={invoiceLines.ufInvoiceTotalInput}
            />
            {currentInvoice?.invoiceStatus !== 8 && scopeCanEdit && (
                <Button
                    size="md"
                    colorScheme="brand"
                    variant="solid"
                    className="mt-2"
                    onClick={() => {
                        dispatch({
                            type: 'OPEN_MODAL',
                            payload: {
                                mode: 'Approval',
                                data: currentInvoice,
                                state: config.invoices.statusUpload[
                                    currentInvoice?.invoiceStatus
                                ][0],
                            },
                        });
                    }}
                    data-walkthroughid={invoiceLines.ufUpdateStatusBtn}>
                    Update Status
                </Button>
            )}
            {EDITABLE_STATUS.includes(currentInvoice?.invoiceStatus) &&
                scopeCanEdit && (
                    <Button
                        size="md"
                        colorScheme="brand"
                        variant="solid"
                        className="mt-2"
                        leftIcon={<AddIcon />}
                        onClick={() => {
                            dispatch({
                                type: 'OPEN_MODAL',
                                payload: {
                                    state: {},
                                    loading: false,
                                    mode: 'Add',
                                    hash: '',
                                },
                            });
                            dispatch({
                                type: 'CHANGE_MODAL_HASH',
                            });
                        }}
                        data-walkthroughid={invoiceLines.ufAddInvoiceLineBtn}>
                        Add Invoice Line
                    </Button>
                )}
        </Box>
    );

    // set current invoice use Effect
    useEffect(() => {
        if (child?.id) {
            const tmp = parent.find((v) => v.id === child?.id);
            setCurrentInvoice(tmp);
        }
    }, [child?.id]);

    /*
     * Table and Modal of Invoice Lines
     */
    return (
        <ChakraProvider theme={chakratheme}>
            <TableAndModal
                tableRef={tableRef}
                overrideTableLoading
                titleProps={titleProps}
                noRefresh
                title="Invoice Line"
                noTitle
                modalUpload={(freshData, state) => {
                    return {
                        ...freshData,
                        ...state,
                    };
                }}
                onModalSucceed={(res) => {
                    const modalDataID = modalData?.id;
                    dispatch({
                        type: 'RESET_MODAL',
                    });
                    var dataIndex = null;
                    switch (modalMode) {
                        case 'Edit':
                            var newTableData = [...tableState.data];
                            dataIndex = newTableData.findIndex(
                                (element) => element.id === res.data.id,
                            );
                            newTableData.splice(dataIndex, 1, res.data);
                            dispatch({
                                type: 'CHANGE_TABLE_STATE',
                                payload: {
                                    ...tableState,
                                    data: newTableData,
                                },
                            });
                            break;
                        case 'Add':
                            dispatch({
                                type: 'CHANGE_TABLE_STATE',
                                payload: {
                                    ...tableState,
                                    data: [...tableState.data, res.data],
                                },
                            });
                            break;
                        case 'Delete':
                            var newTableData2 = [...tableState.data];
                            dataIndex = newTableData2.findIndex(
                                (element) => element.id === modalDataID,
                            );
                            newTableData2.splice(dataIndex, 1);
                            dispatch({
                                type: 'CHANGE_TABLE_STATE',
                                payload: {
                                    ...tableState,
                                    data: newTableData2,
                                },
                            });
                            break;
                        default:
                            break;
                    }
                }}
                modalUpdate="/invoiceline/"
                onModalAdd={() => {
                    return axios.post('/invoiceline/', {
                        ...modalState,
                        invoiceID: child?.id,
                    });
                }}
                modalFetch={
                    modalMode === 'Approval'
                        ? `/invoice/${modalData?.id}`
                        : `/invoiceline/${modalData?.id}`
                }
                modalDelete={`/invoiceline/${modalData?.id}`}
                modalApproval={`/invoice/${modalData?.id}/${modalState?.upload}`}
                modalTitle={
                    modalMode === 'Approval'
                        ? ` Update Invoice Status of:  ${modalData?.parentName} - ${modalData?.entityName} - ${modalData?.invoiceNumber}`
                        : `${modalMode} Invoice Line`
                }
                modalContent={
                    modalMode === 'Approval' ? (
                        <ModalApprovalContent />
                    ) : (
                        <ModalEditContent />
                    )
                }
                modalButtons={
                    modalMode === 'Approval' && (
                        <Button
                            size="md"
                            colorScheme="brand"
                            variant="solid"
                            onClick={() => {
                                dispatch({
                                    type: 'ON_MODAL_SUBMIT',
                                    payload: true,
                                });
                            }}
                            data-walkthroughid={
                                invoiceLines.ufModalUpdateStatusBtn
                            }>
                            Update
                        </Button>
                    )
                }
                groupable
                newPage
                maxBodyHeight={
                    window.innerHeight * config.breadcrumbView.tableHeight
                }
                rowActions={{
                    editable: requiredScope > [20, 40, 60][invoiceType],
                    // cdr: {
                    //     position: 'toolbar',
                    //     onClick: () => {
                    //         axios
                    //             .get('/invoice/' + child?.id + '/export/cdr', {
                    //                 responseType: 'blob',
                    //             })
                    //             .then((res) => {
                    //                 const filename = extractFilename(
                    //                     res.headers['content-disposition'] ||
                    //                         'attachment; filename="Sample_File.csv"; filename*=UTF-8\'\'CDR_Wounds%20Australia_.csv',
                    //                 );
                    //                 const blob = new Blob([res.data]);
                    //                 const link = document.createElement('a');
                    //                 link.href =
                    //                     window.URL.createObjectURL(blob);
                    //                 document.body.appendChild(link);

                    //                 link.download = filename;
                    //                 link.click();
                    //             })
                    //             .catch((e) => {});
                    //     },
                    // },
                    // xero: invoiceType === 0 && {
                    //     position: 'toolbar',
                    //     onClick: () => {
                    //         axios
                    //             .get('/invoice/' + child?.id + '/export/xero', {
                    //                 responseType: 'blob',
                    //             })
                    //             .then((res) => {
                    //                 const filename = extractFilename(
                    //                     res.headers['content-disposition'] ||
                    //                         'attachment; filename="Sample_File.csv"; filename*=UTF-8\'\'CDR_Wounds%20Australia_.csv',
                    //                 );
                    //                 const blob = new Blob([res.data]);
                    //                 const link = document.createElement('a');
                    //                 link.href =
                    //                     window.URL.createObjectURL(blob);
                    //                 document.body.appendChild(link);

                    //                 link.download = filename;
                    //                 link.click();
                    //             })
                    //             .catch((e) => {});
                    //     },
                    // },
                }}
                columns={tableState?.data?.length > 0 ? defaultColumns : null}
                defaultColumns={defaultColumns}
                additionalActions={[
                    {
                        icon: () => (
                            <RefreshIcon
                                data-walkthroughid={
                                    invoiceLines.ufRefreshIconBtn
                                }
                            />
                        ),
                        tooltip: 'Refresh',
                        position: 'toolbar',
                        onClick: () => {
                            dispatch({
                                type: 'INIT_WEB_WORKER_STATE',
                                payload: {
                                    fetchURL: `/invoicelines/${child.id}`,
                                    attribute: 'invoiceLines',
                                },
                            });
                        },
                    },
                    (rowData) => ({
                        icon: () => (
                            <DeleteForeverRoundedIcon
                                data-walkthroughid={
                                    invoiceLines.ufDeleteForeverBtn
                                }
                            />
                        ),
                        tooltip: 'Delete',
                        hidden: !rowData.manualEntry,
                        position: 'row',
                        onClick: (event, rowData) => {
                            dispatch({
                                type: 'OPEN_MODAL',
                                payload: {
                                    mode: 'Delete',
                                    data: rowData,
                                },
                            });
                        },
                    }),
                ]}
            />
        </ChakraProvider>
    );
};

export default InvoiceLines;
