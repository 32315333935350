import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Edit from '@material-ui/icons/Edit';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

import { axios } from '@/services/axios';

import MaterialTable from 'components/v4/MaterialTable';
import { columnDataRender } from './TableHelpers';
import { SkeletonTable } from '../../../../components/v4/SkeletonTable/Index';
import { TableTitleAdd } from './TableTitleAdd';

const CompanyTable = () => {
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const { currentPartner, currentCompanyData, currentWholesaler } =
        useSelector((state) => state.navigationLists);
    const tableRef = useRef(null);

    const companyQuery = useQuery(['companies'], async () => {
        return await axios
            .get('companies/List', {
                params: {
                    wholesalerID: currentWholesaler,
                    partnerID: currentPartner,
                },
            })
            .then(async (res) => await res.data)
            .catch((err) => err);
    });

    const { data: companies, status, error } = companyQuery;
    const columnData = columnDataRender(companies);

    if (status === 'loading') return <SkeletonTable title="Companies" />;

    return (
        <MaterialTable
            tableRef={tableRef}
            title={<TableTitleAdd title="Companies" />}
            columns={columnData}
            refreshFn={() => {
                // TODO: refresh table
            }}
            localization={{
                body: {
                    emptyDataSourceMessage: (
                        <div
                            id="displayMessage"
                            style={{ textAlign: 'center' }}>
                            {!loaded &&
                            isEmpty(
                                tableRef?.current?.dataManager?.searchedData,
                            )
                                ? 'Loading...'
                                : 'No records to display'}
                        </div>
                    ),
                },
            }}
            data={companies}
            actions={[
                {
                    icon: Edit,
                    tooltip: 'Edit',
                    isFreeAction: false,
                    onClick: (e, rowData) => {
                        navigate(`${rowData.id}`);
                    },
                },
            ]}
        />
    );
};

export default CompanyTable;
