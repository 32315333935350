import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdPhonelinkRing } from 'react-icons/md';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Box, Icon } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';

import DashboardServiceCardContent from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardContent';
import DashboardServiceCard from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCard';
import DashboardServiceCardOcStatusbar from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardOcStatusbar';
import DashboardServiceCardHeader from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardHeader';
import DashboardServiceCardHeaderActionButton from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardHeaderActionButton';
import Text from 'components/v4/Text';
import { MenuItem } from 'components/v4';

import walkthroughIds from 'pages/services/walkthroughIds';
import path from '@/routes/constants';

export default function TPMDashboard({ service }) {
    const classes = classNames({
        flash: service.changes === 'added',
    });

    const tpmPath = `${path.SERVICES_TPM}`;
    const navigate = useNavigate();
    const [cardStatus, setCardStatus] = useState({
        color: 'green',
        isAnimated: false,
        hasStripes: false,
        status: '...',
        to: null,
        toText: null,
    });
    const { requiredScope } = useSelector((state) => state.login);
    const { darkMode } = useSelector((state) => state.settings);

    const [data, setData] = useState({});
    const { ufTpmEditServiceButton, ufTpmVerifyServiceButton } =
        walkthroughIds.dashboardServices;
    const handleGetData = () => {
        // getService(service?.id)
        //     .then((res) => {
        //         setData(res.data);
        //     })
        //     .catch((e) => {
        //         console.log('error get acs', {e});
        //     });
    };
    useEffect(() => {
        // setCardStatus({
        //     color: 'green',
        //     isAnimated: false,
        //     hasStripes: false,
        //     status: 'OK',
        // });
    }, []);

    useEffect(() => {
        handleGetData();
    }, [service?.changes]);

    return (
        <DashboardServiceCard
            className={classes}
            dataWalkthroughId={service.name}>
            <DashboardServiceCardOcStatusbar status={cardStatus} />

            <DashboardServiceCardHeader>
                <Text fontSize="lg" className="truncate" marginBottom={'0'}>
                    Teams Phone Mobile
                </Text>

                <DashboardServiceCardHeaderActionButton
                    dataWalkthroughId={service.serviceType}>
                    {requiredScope >= 40 && (
                        <MenuItem
                            icon={<EditIcon />}
                            onClick={() => {
                                navigate(`/${tpmPath}/${service.id}/edit`);
                            }}
                            data-walkthroughid={ufTpmEditServiceButton}>
                            <Text marginBottom={'0'}>Edit Service</Text>
                        </MenuItem>
                    )}
                    <MenuItem
                        icon={<EditIcon />}
                        onClick={() => {
                            navigate(`/${tpmPath}/${service.id}/activation`);
                        }}
                        data-walkthroughid={ufTpmVerifyServiceButton}>
                        <Text marginBottom={'0'}>Verify Numbers</Text>
                    </MenuItem>
                </DashboardServiceCardHeaderActionButton>
            </DashboardServiceCardHeader>
            <DashboardServiceCardContent>
                <Box>
                    {/* // TODO: change this with TPM logo later */}
                    <Box className="flex items-center justify-center">
                        <Icon
                            className="mb-[15px]"
                            as={MdPhonelinkRing}
                            fontSize={50}
                            color={darkMode ? '#7c84ec' : '#4e57c0'}
                        />
                    </Box>

                    <Box className="flex flex-col">
                        {/* Status */}
                        <Box className="mb-[15px] flex justify-between">
                            <Text className="font-medium">Status</Text>
                            <Text>OK</Text>
                        </Box>

                        {/* Available Numbers // TODO: change with real data later */}
                        <Box className="mb-[15px] flex justify-between">
                            <Text className="font-medium">
                                Available Numbers
                            </Text>
                            <Text className="text-left">27</Text>
                        </Box>

                        {/* Active Numbers  */}
                        <Box className="mb-[15px] flex justify-between">
                            <Text className="font-medium">Active Numbers</Text>
                            <Text className="text-left">0</Text>
                        </Box>
                    </Box>
                </Box>
            </DashboardServiceCardContent>
        </DashboardServiceCard>
    );
}
