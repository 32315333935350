export const initialState = { attributesList: [], attributesErrors: [] };

export const useDefinedAttributesReducer = (state = initialState, action) => {
    switch (action.type) {
        // set attribute list
        case 'UPDATE_ATT_LIST': {
            return {
                ...state,
                attributesList: action.payload,
            };
        }
        // update value of an attribute by index
        case 'UPDATE_ATT_VALUE': {
            return {
                ...state,
                attributesList: state.attributesList.map((item, index) => {
                    if (index === action.index) {
                        // If the current index matches the action index, update the value property
                        return {
                            ...item, // Copy the current item properties
                            value: action.payload, // Update the value property
                        };
                    }
                    return item; // Return the unchanged item for other indices
                }),
            };
        }
        // add a attribute error if does not exist
        case 'ADD_ATT_ERROR': {
            if (!state.attributesErrors.some((obj) => obj.id == action.index)) {
                return {
                    ...state,
                    attributesErrors: [
                        ...state.attributesErrors,
                        {
                            ...action.payload,
                        },
                    ],
                };
            } else return { ...state };
        }
        // remove/override attribute error
        case 'UPDATE_ATT_ERROR': {
            if (state.attributesErrors.some((obj) => obj.id == action.index)) {
                return {
                    ...state,
                    attributesErrors: state.attributesErrors.filter(
                        (obj) => obj.id !== action.index,
                    ),
                };
            } else return { ...state };
        }
        default:
            return state;
    }
};
