import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import config from '../../../config.json';
import walkthroughIds from './walkthroughIds';
import { ChakraProvider, FormControl, FormLabel } from '@chakra-ui/react';
import chakratheme from '@/chakratheme';
import { Select } from 'chakra-react-select';
import { Alert, AlertDescription, AlertIcon, Input } from 'components/v4';
import { defaultChakraSelectStyle, chakraSelectDarkMode } from '@/constants';

/*
 * Component that modals the approval content for Invoice.jsx and InvoiceLines.jsx
 */
const ModalApprovalContent = () => {
    const dispatch = useDispatch();

    // modal data from state
    const { state: modalState } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    const { darkMode } = useSelector((state) => state.settings);

    const chakraSelectDark = chakraSelectDarkMode(darkMode);

    const { invoiceLines } = walkthroughIds;

    const [options] = useState(
        config?.invoices?.statusUpload[modalState?.invoiceStatus],
    );

    // auto selects the first option
    useEffect(() => {
        if (modalState?.upload) {
            console.log("only comes here once");
            dispatch({
                type: 'CHANGE_MODAL_STATE',
                payload: {
                    upload: config.invoices.statusUpload[
                        modalState?.invoiceStatus
                    ][0]?.upload,
                },
            });
        }
    }, [options]);

    return (
        <>
            <Row style={{ minHeight: 60 }}>
                <Col>
                    <ChakraProvider resetCSS={false} theme={chakratheme}>
                        <FormControl mb={2}>
                            <FormLabel>From</FormLabel>
                            <Input
                                className="w-full"
                                name="from"
                                value={
                                    config.invoices.statusJSON[
                                        modalState?.invoiceStatus
                                    ]
                                }
                                isDisabled
                                data-walkthroughid={
                                    invoiceLines.ufModalFromInput
                                }
                            />
                        </FormControl>

                        <FormControl dataWalkthroughid={invoiceLines.ufModalToInput}>
                            <FormLabel>To</FormLabel>
                            <Select
                                options={options}
                                getOptionLabel={(opt) => opt.name}
                                getOptionValue={(opt) => opt.upload}
                                placeholder={'Please select invoice status'}
                                value={options?.find(
                                    (a) => a.upload == modalState?.upload,
                                )}
                                onChange={(v) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            upload: v.upload,
                                        },
                                    });
                                }}
                                dataWalkthroughid={invoiceLines.ufModalToInput}
                                chakraStyles={{
                                    ...defaultChakraSelectStyle,
                                    ...chakraSelectDark,
                                }}
                            />
                        </FormControl>
                    </ChakraProvider>
                </Col>
            </Row>

            {modalState?.invoiceStatus === 0 && (
                <Alert status="warning" mt={3}>
                    <AlertIcon />
                    <AlertDescription>
                        This action can't be reverted
                    </AlertDescription>
                </Alert>
            )}
        </>
    );
};

export default ModalApprovalContent;
