import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { useNavigateLocation } from 'hooks/useNavigateLocation/useNavigateLocation';

export const UpdateUserToast = ({ state }) => {
    const toast = useToast();
    const {handleNavigateLocation: navigateLocation} = useNavigateLocation();
    const { pathname } = useLocation();
    const isUsersPath = pathname.includes('/users');

    useEffect(() => {
        if (state.formStatus === 'success') {
            toast({
                title: 'Success',
                description: `${isUsersPath ? 'User Account': 'Resource Account' } updated successfully`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            navigateLocation(
                `${isUsersPath ? '/users' : '/resource-accounts'}`,
            );
        }

        if (state.formStatus === 'error') {
            toast({
                title: 'Error',
                description: `${state?.message}`,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    }, [state.formStatus]);
    return null;
};
