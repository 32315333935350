import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Divider, Box, ListItem, UnorderedList, Text } from '@chakra-ui/react';
import { DownloadIcon, AttachmentIcon } from '@chakra-ui/icons';

import useCSVTemplate from './useCSVTemplate';
import Button from '../../../../components/v4/Button';
import FormLabel from '../../../../components/v4/FormLabel';
import Heading from '../../../../components/v4/Heading';
// import Text from '../../../../components/v4/Text';
import walkthroughIds from '../walkthroughIds';
import { useSelector } from 'react-redux';

export const CSVPrompt = ({ sentence, index }) => {
    const instructionMessage = sentence.includes(
        'You are responsible for the changes you are about to make through this data import',
    );

    return (
        <>
            {index === 2 ? ( // instruction for address import
                <span className="font-bold">{sentence}</span>
            ) : (
                <>
                    {instructionMessage
                        ? `${sentence.split('.')[0]}.`
                        : sentence}
                    <span className="font-bold">
                        {instructionMessage && `${sentence.split('.')[1]}.`}
                    </span>
                </>
            )}
        </>
    );
};

export default function CSVTemplateView({
    handleUploadUsersData,
    downloadCSVText,
    header,
    step1Text,
    step2Text,
    instructionsHeader,
    bgColor,
    instructionsBody,
    handleDownloadTemplate,
    loading,
}) {
    const navigate = useNavigate();
    const { darkMode } = useSelector((state) => state.settings);

    const handleBackButton = () => {
        navigate(-1);
    };

    return (
        <Box display={'flex'} justifyContent="center">
            <Box
                width={400}
                display={'flex'}
                flexDirection={'column'}
                gap={'1px'}
                fontFamily={'Helvetica'}
                justifyContent={'center'}
                boxShadow="base"
                p="6"
                bg={darkMode ? 'dark.tcap' : 'white'}
                rounded="md">
                <Heading size="xl">{header}</Heading>
                <Box mt={'10px'}>
                    <Text size="md"> {step1Text} </Text>
                    <Button
                        isLoading={loading}
                        rightIcon={<DownloadIcon />}
                        sx={{ marginTop: 2, width: '100%' }}
                        size="sm"
                        onClick={handleDownloadTemplate}
                        data-walkthroughid={walkthroughIds.csv.ufDownloadBtn}>
                        {downloadCSVText}
                    </Button>
                </Box>

                {/* <Divider orientation="horizontal" /> */}

                <Box mt={'25px'}>
                    <Text size="md"> {step2Text} </Text>
                    <Button
                        size="sm"
                        as={FormLabel}
                        sx={{ marginTop: 2, width: '100%' }}
                        data-walkthroughid={walkthroughIds.csv.ufUploadBtn}
                        className="hover:cursor-pointer">
                        Choose File <AttachmentIcon ml={1} />
                        <input
                            style={{ display: 'none' }}
                            type="file"
                            id="image-input"
                            accept=".csv"
                            onChange={handleUploadUsersData}
                        />
                    </Button>
                </Box>

                <Box mt={'10px'} mb={'10px'}>
                    <Text size="lg" fontWeight="bold">
                        {' '}
                        {instructionsHeader}{' '}
                    </Text>
                    <UnorderedList>
                        {instructionsBody.map((v, i) => (
                            <ListItem key={i}>
                                <CSVPrompt sentence={v} index={i} />
                            </ListItem>
                        ))}
                    </UnorderedList>
                </Box>
                <Button
                    variant="outline"
                    sx={{ marginTop: 2, width: '100%', bgColor: 'white' }}
                    size="sm"
                    onClick={handleBackButton}
                    data-walkthroughid={walkthroughIds.csv.ufCloseBtn}>
                    Close
                </Button>
            </Box>
        </Box>
    );
}
