import {Box} from '@chakra-ui/react';

/**
 * @param {Object} props
 * @param {import('react').ReactNode} props.children
 * @param {import('react').FormEventHandler<HTMLFormElement>} props.handleSubmit
 * @param {import('react').CSSProperties} props.className
 * @returns
 */
function TpmBody({children, className = ''}) {
    return (
        <Box id="tpm-body" className={`${className}`}>
            {children}
        </Box>
    );
}

export default TpmBody;
