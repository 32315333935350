import {Box} from '@chakra-ui/react';

/**
 * @param {Object} props
 * @param {import('react').ReactNode} props.children
 * @param {import('react').CSSProperties} props.className
 */
export default function TpmFooter({children, className = ''}) {
    return (
        <Box id="tpm-footer" className={`flex mt-6 ${className}`}>
            {children}
        </Box>
    );
}
