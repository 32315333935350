import { useState, useEffect } from 'react';
import {
    Checkbox,
    TextField,
    FormControlLabel,
    Grid,
    FormLabel,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
    ChakraProvider,
    Box,
    Button,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    FormControl,
    FormHelperText,
    Tooltip,
    Alert,
    AlertIcon,
    Text,
} from '@chakra-ui/react';
import { deepmerge } from '@mui/utils';
import { createTheme } from '@mui/material/styles';

import config from '../../../../config.json';
import {
    calculateLastNumber,
    validateBlockSizeRanges,
} from 'utils/numberBlockUtils';
import { postNumberBlock } from 'API/NumberBlocks/postNumberBlock';

import { LoadingFieldDropdown } from '@/components';
import AddCustomFields from './AddCustomFields';
import AddBlockTable from './AddBlockTable';

import walkthroughIds from '../walkthroughIds';
import chakratheme from '@/chakratheme';
import { tabList, initTableState } from './constants';
import { customTabStyles, selectedTabStyles } from '@/constants';
import AddStatus from './AddStatus';
import { Container, Modal } from 'react-bootstrap';
import { axios } from '@/services/axios';

/*
 * Component when Number Blocks Add component is selected
 * Performs the addition logic/form for the number block component
 */

export default function AddNumberBlockModal({ handleBack }) {
    const dispatch = useDispatch();
    const [aggregateTable, setAggregateTable] = useState(initTableState);
    const [hasError, setHasError] = useState(false);
    const [errorValues, setErrorValues] = useState([]);
    const [successValues, setSuccessValues] = useState([]);
    const [hasEmptyNumber, setHasEmptyNumber] = useState(false);
    const [hasOverlapBlocksize, setHasOverlapBlocksize] = useState(false);

    const [submit, setSubmit] = useState(false);
    const [validForm, setValidForm] = useState(false);

    const {
        ufInboundCarrier,
        ufOutboundCarrier,
        ufPublicPoolCheckbox,
        ufGlobalEntityCheckbox,
        ufWholesalerSelect,
        ufPartnerSelect,
        ufCompanySelect,
        ufNumBlockPortingType,
        ufCustomAttribute,
        ufNotesComment,
    } = walkthroughIds;

    // state access
    const { state } = useSelector((state) => {
        return { ...state.modal };
    });
    const { requiredScope } = useSelector((state) => {
        return { ...state.login };
    });
    const { darkMode } = useSelector((state) => state.settings);
    const theme = createTheme(
        deepmerge(chakratheme, {
            palette: { mode: darkMode ? 'dark' : 'light' },
        }),
    );

    const updateAggregateTable = (updatedTable) => {
        setAggregateTable(updatedTable);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmit(true);
        for (const item of aggregateTable) {
            const lastNumber = calculateLastNumber(
                Number(item.number),
                parseInt(item.blockSize),
            );
            let formData = {
                inboundCarrierID: state.inboundCarrierID,
                outboundCarrierID: state.outboundCarrierID,
                publicPool: state.publicPool,
                globalEntity: state.globalEntity,
                wholesalerID: state.wholesalerID,
                partnerID: state.partnerID,
                companyID: state?.companyID,
                numberBlockPortingType: state.numberBlockPortingType,
                internalNotes: state?.internalNotes,
                attribute1: state?.attribute1,
                attribute2: state?.attribute2,
                attribute3: state?.attribute3,
                blockSize: parseInt(item.blockSize),
                first: item.number,
                last: lastNumber,
            };
            try {
                await postNumberBlock(formData);
                setSuccessValues((prev) => [
                    ...prev,
                    {
                        id: item.id,
                        blockSize: item.blockSize,
                        number: item.number,
                    },
                ]);

                if (hasEmptyNumber) {
                    setValidForm(false);
                    return;
                }

                setValidForm(true);
            } catch (err) {
                setErrorValues((prev) => [
                    ...prev,
                    {
                        message: err?.response?.data?.message,
                        id: item.id,
                        blockSize: item.blockSize,
                        number: item.number,
                    },
                ]);
            }
        }
    };

    useEffect(() => {
        if (
            !state?.inboundCarrierID ||
            !state?.outboundCarrierID ||
            !state?.wholesalerID ||
            hasEmptyNumber
        ) {
            setValidForm(false);
        } else {
            setValidForm(true);
        }
    }, [
        state?.inboundCarrierID,
        state?.outboundCarrierID,
        state?.wholesalerID,
        hasEmptyNumber,
    ]);

    // check whether there is an overlap in the blocksize
    useEffect(() => {
        setHasOverlapBlocksize(validateBlockSizeRanges(aggregateTable));
        if (hasOverlapBlocksize) {
            setHasError(true);
        } else {
            setHasError(false);
        }
    }, [setHasOverlapBlocksize, aggregateTable, hasOverlapBlocksize, hasError]);

    // initialise number block table
    useEffect(() => {
        if (!aggregateTable?.length) {
            setAggregateTable([
                {
                    id: 0,
                    blockSize: 1,
                    number: '',
                },
            ]);
        }
    }, [aggregateTable]);

    // NOTE: fix useRef persist value at first row between render
    useEffect(() => {
        const clearButton = document.querySelector('button#Clear-0');
        clearButton.click();
    }, []);

    // return the form for Add Number Blocks
    return (
        <ChakraProvider resetCSS theme={theme}>
            <Container fluid>
                <Modal.Title> Add Number Block </Modal.Title>
                <Box
                    sx={{ flexGrow: 1, paddingTop: '1%', paddingBlock: '1%' }}
                    className="mx-auto min-w-[36rem] max-w-4xl">
                    <Tabs>
                        <TabList justifyContent="center" borderBottom={0}>
                            {tabList.map((t) => (
                                <Tab
                                    sx={customTabStyles}
                                    key={t}
                                    _selected={selectedTabStyles}>
                                    {t}
                                </Tab>
                            ))}
                        </TabList>
                        <TabPanels>
                            <TabPanel className="mt-4">
                                <Grid container spacing={2}>
                                    {requiredScope === 80 && (
                                        <Grid item xs={4}>
                                            {/* Wholesaler */}
                                            <FormControl>
                                                <LoadingFieldDropdown
                                                    searchable
                                                    init={true}
                                                    fieldFetch={'/wholesalers/'}
                                                    fieldName="Wholesaler"
                                                    dropDownValue="id"
                                                    displayField="name"
                                                    onChange={(v) => {
                                                        dispatch({
                                                            type: 'CHANGE_MODAL_STATE',
                                                            payload: {
                                                                numberBlockPortingType: 0,
                                                                wholesalerID: v,
                                                                partnerID: null,
                                                                companyID: null,
                                                                outboundCarrierID:
                                                                    v
                                                                        ? null
                                                                        : state?.outboundCarrierID,
                                                                inboundCarrierID:
                                                                    v
                                                                        ? null
                                                                        : state?.inboundCarrierID,
                                                            },
                                                        });
                                                    }}
                                                    fieldValue={
                                                        state?.wholesalerID
                                                    }
                                                    dataWalkthroughid={
                                                        ufWholesalerSelect
                                                    }
                                                />
                                                {!state?.wholesalerID && (
                                                    <FormHelperText
                                                        mt={0}
                                                        ml={1}>
                                                        Field is required
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    )}

                                    <Grid
                                        item
                                        xs={requiredScope === 80 ? 4 : 6}>
                                        {/* Partner */}
                                        {state?.wholesalerID && (
                                            <LoadingFieldDropdown
                                                searchable
                                                init={true}
                                                fieldFetch={
                                                    '/partners/' +
                                                    state?.wholesalerID
                                                }
                                                dependency={state?.wholesalerID}
                                                hasDependency={true}
                                                fieldName="Partner"
                                                dropDownValue="id"
                                                displayField="name"
                                                onChange={(v) => {
                                                    dispatch({
                                                        type: 'CHANGE_MODAL_STATE',
                                                        payload: {
                                                            numberBlockPortingType: 0,
                                                            partnerID: v,
                                                            companyID: null,
                                                        },
                                                    });
                                                }}
                                                fieldValue={state?.partnerID}
                                                onError={(payload) =>
                                                    dispatch({
                                                        type: 'CHANGE_MODAL_BACKEND_ERROR',
                                                        payload,
                                                    })
                                                }
                                                dataWalkthroughid={
                                                    ufPartnerSelect
                                                }
                                            />
                                        )}
                                    </Grid>

                                    <Grid
                                        item
                                        xs={requiredScope === 80 ? 4 : 6}>
                                        {/* Company */}
                                        {state?.partnerID && (
                                            <LoadingFieldDropdown
                                                searchable
                                                onFieldFetch={() => {
                                                    return axios.get(
                                                        `/companies/list?partnerID=${state?.partnerID}`,
                                                        {
                                                            headers: {
                                                                'X-RequestScope':
                                                                    '40',
                                                            },
                                                        },
                                                    );
                                                }}
                                                fieldName="Company"
                                                dependency={state?.partnerID}
                                                hasDependency={true}
                                                dropDownValue="id"
                                                displayField="name"
                                                onChange={(v) => {
                                                    dispatch({
                                                        type: 'CHANGE_MODAL_STATE',
                                                        payload: {
                                                            companyID: v,
                                                        },
                                                    });
                                                }}
                                                fieldValue={state?.companyID}
                                                onError={(payload) =>
                                                    dispatch({
                                                        type: 'CHANGE_MODAL_BACKEND_ERROR',
                                                        payload,
                                                    })
                                                }
                                                dataWalkthroughid={
                                                    ufCompanySelect
                                                }
                                            />
                                        )}
                                    </Grid>
                                    {/* Inbound Carrier */}
                                    <Grid item xs={4}>
                                        <FormControl>
                                            <LoadingFieldDropdown
                                                searchable
                                                fieldFetch={
                                                    state?.wholesalerID
                                                        ? `/carriers/${state?.wholesalerID}`
                                                        : '/carriers/'
                                                }
                                                fieldName="Inbound Carrier"
                                                noEmptyOption
                                                dropDownValue="id"
                                                displayField="name"
                                                onChange={(v) => {
                                                    dispatch({
                                                        type: 'CHANGE_MODAL_STATE',
                                                        payload: {
                                                            inboundCarrierID: v,
                                                        },
                                                    });
                                                }}
                                                fieldValue={
                                                    state?.inboundCarrierID
                                                }
                                                dataWalkthroughid={
                                                    ufInboundCarrier
                                                }
                                            />
                                            {!state?.inboundCarrierID && (
                                                <FormHelperText mt={0} ml={1}>
                                                    Field is required
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>

                                    {/* Outbound Carrier */}
                                    <Grid item xs={4}>
                                        <FormControl>
                                            <LoadingFieldDropdown
                                                searchable
                                                fieldFetch={
                                                    state?.wholesalerID
                                                        ? `/carriers/${state?.wholesalerID}`
                                                        : '/carriers/'
                                                }
                                                fieldName="Outbound Carrier"
                                                noEmptyOption
                                                dropDownValue="id"
                                                displayField="name"
                                                onChange={(v) => {
                                                    dispatch({
                                                        type: 'CHANGE_MODAL_STATE',
                                                        payload: {
                                                            outboundCarrierID:
                                                                v,
                                                        },
                                                    });
                                                }}
                                                fieldValue={
                                                    state?.outboundCarrierID
                                                }
                                                dataWalkthroughid={
                                                    ufOutboundCarrier
                                                }
                                                defaultValue={null}
                                            />
                                            {!state?.outboundCarrierID && (
                                                <FormHelperText mt={0} ml={1}>
                                                    Field is required
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>

                                    {/* Public Pool */}
                                    <Grid item xs={4}>
                                        <FormControlLabel
                                            className="!-ml-[2px]"
                                            control={
                                                <Checkbox
                                                    checked={state?.publicPool}
                                                    onChange={() => {
                                                        dispatch({
                                                            type: 'CHANGE_MODAL_STATE',
                                                            payload: {
                                                                publicPool:
                                                                    !state?.publicPool,
                                                            },
                                                        });
                                                    }}
                                                    data-walkthroughid={
                                                        ufPublicPoolCheckbox
                                                    }
                                                />
                                            }
                                            label="Public Pool"
                                        />
                                    </Grid>

                                    {/* Number Block Porting Type */}
                                    <Grid item xs={4}>
                                        <LoadingFieldDropdown
                                            staticEnum={
                                                state?.companyID
                                                    ? config.numbers.porting
                                                    : [
                                                          {
                                                              value: 0,
                                                              name: 'None',
                                                          },
                                                      ]
                                            }
                                            noEmptyOption
                                            fieldName="Number Block Porting Type"
                                            dropDownValue="value"
                                            displayField="name"
                                            onChange={(v) => {
                                                dispatch({
                                                    type: 'CHANGE_MODAL_STATE',
                                                    payload: {
                                                        numberBlockPortingType:
                                                            v,
                                                    },
                                                });
                                            }}
                                            fieldValue={
                                                state?.numberBlockPortingType
                                            }
                                            toggleLimit={6}
                                            onError={(payload) =>
                                                dispatch({
                                                    type: 'CHANGE_MODAL_BACKEND_ERROR',
                                                    payload,
                                                })
                                            }
                                            dataWalkthroughid={
                                                ufNumBlockPortingType
                                            }
                                        />
                                    </Grid>

                                    {/* Global Entity */}
                                    {requiredScope === 80 && (
                                        <Grid item xs={4}>
                                            <FormControlLabel
                                                className="!-ml-[2px]"
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            state?.globalEntity
                                                        }
                                                        onChange={() => {
                                                            dispatch({
                                                                type: 'CHANGE_MODAL_STATE',
                                                                payload: {
                                                                    globalEntity:
                                                                        !state?.globalEntity,
                                                                },
                                                            });
                                                        }}
                                                        data-walkthroughid={
                                                            ufGlobalEntityCheckbox
                                                        }
                                                    />
                                                }
                                                label="Global Entity"
                                            />
                                        </Grid>
                                    )}

                                    {/* Notes */}
                                    <Grid item xs={12}>
                                        {requiredScope === 80 && (
                                            <TextField
                                                label="Notes"
                                                fullWidth
                                                placeholder="Relevant notes regarding adding number blocks"
                                                multiline
                                                rows={3}
                                                rowsMax={5}
                                                value={state?.internalNotes}
                                                onChange={(e) => {
                                                    dispatch({
                                                        type: 'CHANGE_MODAL_STATE',
                                                        payload: {
                                                            internalNotes:
                                                                e.target.value,
                                                        },
                                                    });
                                                }}
                                                data-walkthroughid={
                                                    ufNotesComment
                                                }
                                            />
                                        )}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box className="ml-1">
                                            <FormLabel className="!font-bold leading-4">
                                                Number Block Table
                                            </FormLabel>
                                            <Alert
                                                status="info"
                                                display="-webkit-box"
                                                mb="20px"
                                                mt="15px">
                                                <AlertIcon />
                                                To quickly add multiple numbers
                                                of the same block size, copy and
                                                paste a line separated list of
                                                numbers into the{' '}
                                                <Text as="b" mr="3px">
                                                    First Number
                                                </Text>
                                                field below
                                            </Alert>

                                            {!submit ? (
                                                <AddBlockTable
                                                    aggregateTable={
                                                        aggregateTable
                                                    }
                                                    updateAggregateTable={
                                                        updateAggregateTable
                                                    }
                                                    hasError={hasError}
                                                    setHasError={setHasError}
                                                    hasEmptyNumber={
                                                        hasEmptyNumber
                                                    }
                                                    setHasEmptyNumber={
                                                        setHasEmptyNumber
                                                    }
                                                    hasOverlapBlocksize={
                                                        hasOverlapBlocksize
                                                    }
                                                />
                                            ) : (
                                                <AddStatus
                                                    successValues={
                                                        successValues
                                                    }
                                                    setSuccessValues={
                                                        setSuccessValues
                                                    }
                                                    setSubmit={setSubmit}
                                                    aggregateTable={
                                                        aggregateTable
                                                    }
                                                    updateAggregateTable={
                                                        updateAggregateTable
                                                    }
                                                    errorValues={errorValues}
                                                    setErrorValues={
                                                        setErrorValues
                                                    }
                                                />
                                            )}
                                        </Box>
                                    </Grid>

                                    {/* End Grid Items */}
                                </Grid>
                            </TabPanel>
                            <TabPanel className="mt-4">
                                <AddCustomFields
                                    state={state}
                                    dispatch={dispatch}
                                    ufCustomAttribute={ufCustomAttribute}
                                />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                    {!submit && (
                        <Box display="flex" className="mt-3 justify-center">
                            <Button
                                variant="outline"
                                bg="white"
                                minW={['82px']}
                                onClick={() => {
                                    dispatch({
                                        type: 'RESET_MODAL',
                                    });
                                    handleBack();
                                }}>
                                Back
                            </Button>
                            <Tooltip
                                hasArrow
                                label="Please do not leave any first number field blank"
                                display={hasEmptyNumber ? 'block' : 'none'}>
                                <span>
                                    <Button
                                        ml={4}
                                        onClick={handleSubmit}
                                        isDisabled={!validForm || hasError}>
                                        Create
                                    </Button>
                                </span>
                            </Tooltip>
                        </Box>
                    )}
                </Box>
            </Container>
        </ChakraProvider>
    );
}
