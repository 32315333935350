import React, { useState } from 'react';
import {
    Stack,
    Box,
    Button,
    Heading,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import {
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from 'components/v4';
import walkthroughIds from '../walkthroughIds';
import { axios } from '@/services/axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useNavigateLocation } from 'hooks/useNavigateLocation/useNavigateLocation';

/**
 * Convert from RA to User
 * @returns
 */
const AdminRA = ({ RaID }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { handleNavigateLocation } = useNavigateLocation();

    async function handleConvert() {
        setIsLoading(true);
        try {
            // axios call to convert the ra to user
            await axios.post('/resourceaccount/converttouser/' + RaID);
            // reset modal and table
            dispatch({
                type: 'RESET_MODAL',
            });
            dispatch({
                type: 'RESET_TABLE',
            });

            // toast message
            toast.success('Converted RA to user successfully!');
            handleNavigateLocation('/resource-accounts');
        } catch (err) {
            toast.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <Box
                sx={{
                    py: '10px',
                    px: 0,
                    maxWidth: '48rem',
                    minWidth: '36rem',
                    mx: 'auto',
                }}>
                <Heading as="h4" fontSize="2xl" mb={4}>
                    Danger Zone
                </Heading>
                <Stack border="2px solid red" borderRadius="8px">
                    <Stack direction="row" p={2} fontSize="1rem" minH={15}>
                        <Box minW="80%" display="flex" flexDirection="column">
                            <Text fontWeight="semibold">
                                Convert RA to User
                            </Text>

                            <Text>
                                This action will convert a resource account to a
                                user account
                            </Text>
                        </Box>
                        <Box
                            display="flex"
                            w="20%"
                            alignItems="center"
                            justifyContent="flex-end"
                            marginTop={10}>
                            <Button
                                colorScheme="red"
                                // variant="danger"
                                onClick={onOpen}
                                data-walkthroughid={
                                    walkthroughIds.admin.ufConvertBtn
                                }>
                                Convert
                            </Button>
                        </Box>
                    </Stack>
                </Stack>
            </Box>

            <Modal
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={false}
                motionPreset="scale">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Convert RA to User?</ModalHeader>
                    <ModalBody>
                        <Text>
                            Are you sure you would like to convert this resource
                            account to a user account?
                        </Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme="primary"
                            mr={3}
                            onClick={onClose}
                            data-walkthroughid={walkthroughIds.admin.ufNoBtn}>
                            No
                        </Button>
                        <Button
                            colorScheme="red"
                            isLoading={isLoading}
                            onClick={handleConvert}
                            data-walkthroughid={walkthroughIds.admin.ufYesBtn}>
                            Yes
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AdminRA;
