import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Locations from './Locations';
import Regions from './Regions';
import { TabView } from '../../../components';
import config from '../../../config.json';

/*
 * Tab View rendered for the regions and locations page on the Sidebar
 */
const TrunkLocations = () => {
    const dispatch = useDispatch();

    // state access
    const { tab } = useSelector((state) => {
        return { ...state.view };
    });

    // use effect to change tab view using redux
    useEffect(() => {
        if (Number.isInteger(tab)) {
            dispatch({
                type: 'CHANGE_TAB_VIEW',
                payload: {
                    level1: 0,
                    level2: 0,
                },
            });
        }
    }, [tab]);
  return (
    <>
      <h1 className="mb-2 text-[2.5rem] font-medium">Regions and Locations</h1>
      <TabView
                onChange={(e, v) => {
                    dispatch({
                        type: 'CHANGE_TAB_VIEW',
                        payload: {
                            level1: v,
                            level2: 0,
                        },
                    });
                }}
        value={tab['level1']}
        tabLabels={config.trunkLocations.tabs}
        contents={[<Regions />, <Locations />]}
      />
    </>
  );
};

export default TrunkLocations;
