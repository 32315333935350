export const approvalCodesColumns = [
    {
        title: 'Name',
        field: 'name',
    },
    {
        title: 'Approval Code',
        field: 'code',
    },
    {
        title: 'Auto Approval',
        field: 'autoApproveCustom',
    },
    {
        title: 'Customer Default Plan',
        field: 'planName',
    },
    {
        title: 'Trial Duration (days)',
        field: 'trialDays',
    },
    {
        title: 'Selective Sync Policy',
        field: 'selectiveSyncPolicyCustom',
    },
    {
        title: 'Approval Code Expiry Date',
        field: 'validToCustom',
    },
];

export const validationSchema = {
    name: [{type: 'isEmpty', message: 'Please enter a valid name.'}],
    code: [
        {
            type: 'isLength',
            message: 'Please enter a minimum of 6 characters.',
            conditions: {min: 6},
        },
    ],
    trialDays: [
        {
            type: 'isEmpty',
            message: 'Please enter how many days your trial will last.',
        },
    ],
    planID: [
        {
            type: 'isEmpty',
            message: 'Please select a Partner Plan.',
        },
    ],
    selectiveSyncPolicy: [
        {
            type: 'isEmpty',
            message: 'Please select a Selective Sync Policy.',
        },
    ],
};

    export const selectiveSyncOptions = [
        { value: 0, label: 'Disabled' },
        { value: 1, label: 'Enabled' },
        { value: 2, label: 'Forced' },
    ];
    export const autoApprovalOptions = [
        {
            value: 0,
            label: 'None',
        },
        {
            value: 1,
            label: 'Trial',
        },
        {
            value: 2,
            label: 'Live',
        },
    ];
