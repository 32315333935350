import React from 'react';
import styles from './styles';
import {Progress} from '@chakra-ui/react';

const sbcStates = {
    0: 'green', // OK
    1: 'green', // Adding
    3: 'green', // Pending Approval
    4: 'green', // Updating
    8: 'yellow', // Degraded
    9: 'green', // Deleting
    20: 'red', // Error
};

const sbcWorkingStates = {
    0: false,
    1: true,
    3: false, // Unused now
    4: true,
    8: false,
    9: true,
    20: false,
};

export default function DashboardServiceCardSbcStatusbar({
    children,
    status,
    ...props
}) {

    return (
        <Progress
            {...props}
            value={100}
            sx={styles.verticalBar}
            hasStripe={sbcWorkingStates[status]}
            isAnimated={sbcWorkingStates[status]}
            colorScheme={sbcStates[status] || "gray"}
        />
    );
}
