import React, { useRef } from 'react';
import {
    TextField,
    // Checkbox,
    FormControlLabel,
    FormHelperText,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import {
    Box,
    ChakraProvider,
    Container,
    FormControl,
    FormErrorMessage,
    FormLabel,
    forwardRef,
    Stack,
} from '@chakra-ui/react';
import NumberFormat from 'react-number-format';
import { Input as ChakraInput, Checkbox } from '@/components/v4';

import TableAndModal, {
    floatSearch,
} from '../../../../components/tables/TableAndModal';
import NumberFormatCustom, {
    NonNegativeInteger,
} from '../../../../components/maintenance/NumberFormatCustom';
import config from '../../../../config.json';
import { floatingLabelStyles as floatingLabel } from '@/chakratheme';

const Input = forwardRef((props, ref) => (
    <ChakraInput ref={ref} {...props} placeholder=" " size="md" data-peer />
));

// default colulmns for the table
const defaultColumns = [
    {
        title: 'Country',
        field: 'country',
        defaultSort: 'asc',
    },

    {
        title: 'Name',
        field: 'name',
    },
    {
        title: 'Priority',
        field: 'priority',
        type: 'number',
        doNotOverrideSort: true,
    },
    {
        title: 'Prefix',
        field: 'prefix',
    },
    {
        title: 'Flag Fall In Rate',
        field: 'flagFallInRate',
        doNotOverrideSort: true,
        render: (rowData) => {
            return (
                <NumberFormatCustom
                    displayType={'text'}
                    value={rowData['flagFallInRate']}
                />
            );
        },
        customFilterAndSearch: (v, rowData) =>
            floatSearch(v, rowData, 'flagFallInRate'),
    },
    {
        title: 'Flag Fall Out Rate',
        field: 'flagFallOutRate',
        doNotOverrideSort: true,
        render: (rowData) => {
            return (
                <NumberFormatCustom
                    displayType={'text'}
                    value={rowData['flagFallOutRate']}
                />
            );
        },
        customFilterAndSearch: (v, rowData) =>
            floatSearch(v, rowData, 'flagFallOutRate'),
    },
    {
        title: 'Inbound Rate',
        field: 'inboundRate',
        doNotOverrideSort: true,
        render: (rowData) => {
            return (
                <NumberFormatCustom
                    displayType={'text'}
                    value={rowData['inboundRate']}
                />
            );
        },
        customFilterAndSearch: (v, rowData) =>
            floatSearch(v, rowData, 'inboundRate'),
    },
    {
        title: 'Outbound Rate',
        field: 'outboundRate',
        doNotOverrideSort: true,
        render: (rowData) => {
            return (
                <NumberFormatCustom
                    displayType={'text'}
                    value={rowData['outboundRate']}
                />
            );
        },
        customFilterAndSearch: (v, rowData) =>
            floatSearch(v, rowData, 'outboundRate'),
    },
    {
        title: 'Minimum Cost',
        field: 'minPrice',
        doNotOverrideSort: true,
        render: (rowData) => {
            return (
                <NumberFormatCustom
                    displayType={'text'}
                    value={rowData['minPrice']}
                />
            );
        },
        customFilterAndSearch: (v, rowData) =>
            floatSearch(v, rowData, 'minPrice'),
    },
    {
        title: 'Active',
        field: 'isActive',
        type: 'boolean',
    },
];

/*
 * Component for the International Rates Page inside 'International Rates List' Sidebar tab
 */
const InternationalRate = ({ disabled }) => {
    const dispatch = useDispatch();
    const tableRef = useRef();

    // modal data from state
    const { modalData, modalState, modalMode, modalStateError } = useSelector(
        (state) => {
            return {
                modalLoading: state.modal.loading,
                modalData: state.modal.data,
                modalState: state.modal.state,
                modalMode: state.modal.mode,
                modalStateError: state.modal.stateError,
                modalUploading: state.modal.uploading,
            };
        },
    );

    // child from table state
    const { child } = useSelector((state) => {
        return {
            ...state.table,
        };
    });
    const { darkMode } = useSelector((state) => state.settings);
    const priorityInputRef = useRef(null);

    const floatingLabelStyles = floatingLabel(darkMode);
    const handleInputChange = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                [e.target.name]: e.target.value,
            },
        });
    };

    // return table and modal with modal content inside this as a prop
    return (
        <TableAndModal
            tableRef={tableRef}
            tableFetch={'/internationalrates/' + child?.id}
            modalFetch={'/internationalrate/' + modalData?.id}
            modalAdd="/internationalrate/"
            modalUpdate="/internationalrate/"
            noTitle
            modalValidate={() => {
                const newErrorState = {
                    name:
                        (!modalState?.name || modalState?.name?.length < 1) &&
                        'Field is required.',
                    country:
                        (!modalState?.country ||
                            modalState?.country?.length < 1) &&
                        'Field is required.',
                    prefix:
                        (!modalState?.prefix ||
                            modalState?.prefix?.length < 1) &&
                        'Field is required.',
                    priority:
                        (!modalState?.priority ||
                            modalState?.priority?.length < 1) &&
                        'Field is required.',
                };
                dispatch({
                    type: 'CHANGE_MODAL_STATE_ERROR',
                    payload: newErrorState,
                });
                return !(
                    newErrorState.name ||
                    newErrorState.country ||
                    newErrorState.prefix ||
                    newErrorState.priority
                );
            }}
            editable={{
                update: true,
            }}
            modalUpload={(freshData, state) => {
                return {
                    ...freshData,
                    ...state,
                    internationalRateListID: child?.id,
                };
            }}
            title={'International Rate'}
            defaultColumns={defaultColumns}
            add={!disabled}
            modalInitState={{
                isActive: true,
            }}
            groupable
            filterable
            maxBodyHeight={
                window.innerHeight * config.breadcrumbView.tableHeight
            }
            rowActions={{
                editable: !disabled,
            }}
            modalTitle={
                modalMode === 'Add'
                    ? "Add Int'l Rate"
                    : 'Edit ' + modalData?.name + " Int'l Rate"
            }
            modalContent={
                <>
                    <Box as="section">
                        <Box
                            display="flex"
                            flexDir="row"
                            alignItems="center"
                            mb={4}
                            mt={2}
                            className="gap-4">
                            <FormControl
                                isInvalid={Boolean(modalStateError?.country)}
                                isRequired
                                sx={floatingLabelStyles}>
                                <Input
                                    name="country"
                                    value={modalState?.country}
                                    onChange={handleInputChange}
                                />
                                <FormLabel htmlFor="country" variant="floating">
                                    Country
                                </FormLabel>
                                {Boolean(modalStateError?.country) ? (
                                    <FormErrorMessage>
                                        {modalStateError?.country}
                                    </FormErrorMessage>
                                ) : (
                                    <FormHelperText>
                                        {modalStateError?.country}{' '}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                isRequired
                                isInvalid={Boolean(modalStateError?.name)}
                                sx={floatingLabelStyles}>
                                <Input
                                    name="name"
                                    value={modalState?.name}
                                    onChange={handleInputChange}
                                />
                                <FormLabel htmlFor="name" variant="floating">
                                    Name
                                </FormLabel>
                                {Boolean(modalStateError?.country) ? (
                                    <FormErrorMessage>
                                        {modalStateError?.country}
                                    </FormErrorMessage>
                                ) : (
                                    <FormHelperText>
                                        {modalStateError?.country}{' '}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                isRequired
                                isInvalid={Boolean(modalStateError?.prefix)}
                                sx={floatingLabelStyles}>
                                <Input
                                    name="prefix"
                                    value={modalState?.prefix}
                                    onChange={handleInputChange}
                                />
                                <FormLabel htmlFor="prefix" variant="floating">
                                    Prefix
                                </FormLabel>
                                {Boolean(modalStateError?.prefix) ? (
                                    <FormErrorMessage>
                                        {modalStateError?.prefix}
                                    </FormErrorMessage>
                                ) : (
                                    <FormHelperText>
                                        {modalStateError?.prefix}{' '}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Box>

                        <FormControl>
                            <Box className="flex items-center">
                                <Checkbox
                                    isChecked={modalState?.isActive}
                                    onChange={() =>
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                isActive: !modalState?.isActive,
                                            },
                                        })
                                    }
                                    name="isActive"
                                    colorScheme="whatsapp"
                                    mb={0}
                                />
                                <FormLabel mb={0} ml={2}>
                                    Active
                                </FormLabel>
                            </Box>
                        </FormControl>

                        <Box mt={8} display="flex" className="gap-4">
                            <FormControl
                                isInvalid={Boolean(modalStateError?.priority)}
                                isRequired
                                sx={floatingLabelStyles}>
                                <Input
                                    as={NumberFormat}
                                    isNumericString
                                    allowNegative={false}
                                    name="priority"
                                    value={modalState?.priority}
                                    getInputRef={(el) =>
                                        (priorityInputRef.current = el)
                                    }
                                    ref={priorityInputRef}
                                    onChange={handleInputChange}
                                />
                                <FormLabel
                                    htmlFor="priority"
                                    variant="floating">
                                    Priority
                                </FormLabel>
                                {Boolean(modalStateError?.priority) ? (
                                    <FormErrorMessage>
                                        {modalStateError?.priority}
                                    </FormErrorMessage>
                                ) : (
                                    <FormHelperText>
                                        {modalStateError?.priority}{' '}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl sx={floatingLabelStyles}>
                                <Input
                                    as={NumberFormatCustom}
                                    name="minPrice"
                                    value={modalState?.minPrice}
                                    onChange={handleInputChange}
                                />
                                <FormLabel
                                    htmlFor="minPrice"
                                    variant="floating">
                                    Minimum Cost
                                </FormLabel>
                            </FormControl>
                        </Box>

                        <Box mt={8} display="flex" className="gap-4">
                            <FormControl sx={floatingLabelStyles}>
                                <Input
                                    as={NumberFormatCustom}
                                    name="flagFallInRate"
                                    value={modalState?.flagFallInRate}
                                    onChange={handleInputChange}
                                />
                                <FormLabel
                                    htmlFor="flagFallInRate"
                                    variant="floating">
                                    Flag Fall In Rate
                                </FormLabel>
                            </FormControl>

                            <FormControl sx={floatingLabelStyles}>
                                <Input
                                    as={NumberFormatCustom}
                                    name="flagFallOutRate"
                                    value={modalState?.flagFallOutRate}
                                    onChange={handleInputChange}
                                />
                                <FormLabel
                                    htmlFor="flagFallOutRate"
                                    variant="floating">
                                    Flag Fall Out Rate
                                </FormLabel>
                            </FormControl>
                        </Box>

                        <Box mt={8} display="flex" className="gap-4">
                            <FormControl sx={floatingLabelStyles}>
                                <Input
                                    as={NumberFormatCustom}
                                    name="inboundRate"
                                    value={modalState?.inboundRate}
                                    onChange={handleInputChange}
                                />
                                <FormLabel
                                    htmlFor="inboundRate"
                                    variant="floating">
                                    Inbound Rate
                                </FormLabel>
                            </FormControl>

                            <FormControl sx={floatingLabelStyles}>
                                <Input
                                    as={NumberFormatCustom}
                                    name="outboundRate"
                                    value={modalState?.outboundRate}
                                    onChange={handleInputChange}
                                />
                                <FormLabel
                                    htmlFor="outboundRate"
                                    variant="floating">
                                    Outbound Rate
                                </FormLabel>
                            </FormControl>
                        </Box>
                    </Box>
                </>
            }
        />
    );
};

export default InternationalRate;
