import {
    Box,
    Stack,
    FormControl,
    FormLabel,
    FormErrorMessage,
} from '@chakra-ui/react';
import { Input } from '@/components/v4';

/**
 * @note A Chakra custom attributes tab, it may need a ChakraProvider wrapper to render properly
 * @typedef {Object} CustomAttributesProps
 * @property {{attribute1: string; attribute2: string; attribute3: string}} state
 * @property {Function} onChange - Dispatch setState function
 * @param {CustomAttributesProps} props
 * @returns
 */
export default function CustomAttributes({ state, onChange }) {
    const maxLength = 100;
    const maxLengthError = `Max field length is ${maxLength} characters.`;
    const errorState1 = state.attribute1?.length > maxLength;
    const errorState2 = state.attribute2?.length > maxLength;
    const errorState3 = state.attribute3?.length > maxLength;

    return (
        <Stack spacing={3}>
            <FormControl isInvalid={errorState1}>
                <FormLabel htmlFor="attribute2">Custom Attributes 1</FormLabel>
                <Box className="flex h-10">
                    <Input
                        className="w-full"
                        name="attribute1"
                        value={state.attribute1}
                        onChange={onChange}
                        placeholder='Custom Attribute 1'
                    />
                </Box>
                <FormErrorMessage>
                    {errorState1 && maxLengthError}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errorState2}>
                <FormLabel htmlFor="attribute2">Custom Attributes 2</FormLabel>
                <Box className="flex h-10">
                    <Input
                        className="w-full"
                        name="attribute2"
                        value={state.attribute2}
                        onChange={onChange}
                        placeholder='Custom Attribute 2'
                    />
                </Box>
                <FormErrorMessage>
                    {errorState2 && maxLengthError}
                </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errorState3}>
                <FormLabel htmlFor="attribute1">Custom Attributes 3</FormLabel>

                <Box className="flex h-10">
                    <Input
                        className="w-full"
                        name="attribute3"
                        value={state.attribute3}
                        onChange={onChange}
                        placeholder='Custom Attribute 3'
                    />
                </Box>
                <FormErrorMessage>
                    {errorState3 && maxLengthError}
                </FormErrorMessage>
            </FormControl>
        </Stack>
    );
}
