import {
    Box,
    Link,
    ListItem,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Portal,
    Progress,
    Stack,
    Text,
    UnorderedList,
} from '@chakra-ui/react';
import { Alert, AlertDescription, AlertIcon, Button } from 'components/v4';
import { useSelector } from 'react-redux';

// Used in service users and service numbers
export default function ServiceStatus({
    title,
    successCount,
    failedCount,
    totalCount,
    activateError,
    deactivateError,
    handleBack,
}) {
    const { darkMode } = useSelector((state) => {
        return { ...state.settings };
    });
    return (
        <Box
            sx={{
                padding: '10px 0',
                maxWidth: '2xl',
                minWidth: 'xl',
                margin: '0 auto',
            }}>
            {successCount + failedCount >= totalCount ? (
                <>
                    <Alert status="success" mt={5}>
                        <AlertIcon />
                        <AlertDescription>Completed</AlertDescription>
                    </Alert>
                </>
            ) : (
                <Box
                    mt={5}
                    mb={10}
                    shadow={'md'}
                    rounded={'md'}
                    bg={'blue.100'}
                    padding={'10px'}>
                    <Text color={'black'} size="md">{`Working on Request ${
                        successCount + failedCount
                    } of ${totalCount}`}</Text>
                    <Progress
                        rounded={'md'}
                        mt={2}
                        hasStripe
                        isAnimated
                        value={successCount + failedCount}
                        max={totalCount}
                    />
                </Box>
            )}

            <Box mt={5} padding={'10px'}>
                <Text sx={{ color: 'green.500' }} size="md">
                    Successful : {successCount}
                </Text>
                <Text sx={{ color: 'red.500' }} mt={1} size="md">
                    Failed : {failedCount}
                </Text>
                {deactivateError?.count > 0 && (
                    <Box sx={{ color: 'red.500' }} mt={3} size="md">
                        <UnorderedList color="red.500">
                            <ListItem>
                                Unable to deactivate{' '}
                                <Popover
                                    autoFocus={false}
                                    returnFocusOnClose={false}
                                    isLazy={true}>
                                    {({ isOpen, onClose }) => (
                                        <>
                                            <PopoverTrigger>
                                                <Link
                                                    textDecoration={'underline'}
                                                    variant="custom"
                                                    fontWeight={'bold'}>
                                                    {deactivateError.count}{' '}
                                                    {title}
                                                </Link>
                                            </PopoverTrigger>
                                            <Portal>
                                                <PopoverContent
                                                    bg={
                                                        darkMode
                                                            ? 'dark.bgDarkGray'
                                                            : 'inherit'
                                                    }
                                                    borderColor={
                                                        darkMode
                                                            ? 'dark.bgDarkGray'
                                                            : 'inherit'
                                                    }>
                                                    <PopoverArrow />
                                                    <PopoverHeader border="0">
                                                        {title}
                                                    </PopoverHeader>
                                                    <PopoverBody>
                                                        {
                                                            deactivateError.message
                                                        }
                                                    </PopoverBody>
                                                    <PopoverFooter border="0">
                                                        <Box
                                                            display={'flex'}
                                                            justifyContent={
                                                                'end'
                                                            }>
                                                            <Button
                                                                onClick={
                                                                    onClose
                                                                }>
                                                                Close
                                                            </Button>
                                                        </Box>
                                                    </PopoverFooter>
                                                </PopoverContent>
                                            </Portal>
                                        </>
                                    )}
                                </Popover>
                                .
                            </ListItem>
                        </UnorderedList>
                    </Box>
                )}
                {activateError?.count > 0 && (
                    <Box sx={{ color: 'red.500' }} mt={3} size="md">
                        <UnorderedList color="red.500">
                            <ListItem>
                                Unable to activate{' '}
                                <Popover
                                    autoFocus={false}
                                    returnFocusOnClose={false}
                                    isLazy={true}>
                                    {({ isOpen, onClose }) => (
                                        <>
                                            <PopoverTrigger>
                                                <Link
                                                    textDecoration={'underline'}
                                                    variant="custom"
                                                    fontWeight={'bold'}>
                                                    {activateError.count}{' '}
                                                    {title}
                                                </Link>
                                            </PopoverTrigger>
                                            <Portal>
                                                <PopoverContent
                                                    bg={
                                                        darkMode
                                                            ? 'dark.bgDarkGray'
                                                            : 'inherit'
                                                    }
                                                    borderColor={
                                                        darkMode
                                                            ? 'dark.bgDarkGray'
                                                            : 'inherit'
                                                    }>
                                                    <PopoverArrow />
                                                    <PopoverHeader border="0">
                                                        {title}
                                                    </PopoverHeader>
                                                    <PopoverBody>
                                                        {activateError.message}
                                                    </PopoverBody>
                                                    <PopoverFooter border="0">
                                                        <Box
                                                            display={'flex'}
                                                            justifyContent={
                                                                'end'
                                                            }>
                                                            <Button
                                                                onClick={
                                                                    onClose
                                                                }>
                                                                Close
                                                            </Button>
                                                        </Box>
                                                    </PopoverFooter>
                                                </PopoverContent>
                                            </Portal>
                                        </>
                                    )}
                                </Popover>
                                .
                            </ListItem>
                        </UnorderedList>
                    </Box>
                )}
            </Box>

            {successCount + failedCount !== totalCount && (
                <Box mt={5} padding={'10px'}>
                    <Text size="md">
                        Do not close this window or navigate away. This may take
                        a few minutes.
                    </Text>
                </Box>
            )}

            <Stack
                spacing="6"
                px={{ base: '4', md: '6' }}
                pt={{ base: '5', md: '6' }}
                pb="2px"
                sx={{
                    justifyContent: 'center',
                    width: '170px',
                    margin: '0 auto',
                }}
                direction={{ base: 'column', md: 'row' }}>
                <Button
                    isDisabled={!(successCount + failedCount === totalCount)}
                    onClick={() => handleBack()}>
                    Close
                </Button>
            </Stack>
        </Box>
    );
}
