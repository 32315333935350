import { useSelector } from 'react-redux';
import { MenuList } from '@chakra-ui/react';

/**
 * @typedef {Object} ChakraMenuItemProps
 * @property {import('react').ReactNode} children
 * @param {import('@chakra-ui/react').MenuListProps} props
 */
const ChakraMenuList = ({ children, ...props }) => {
    const { darkMode } = useSelector((state) => state.settings);

    const bgColorCondition = {
        bg: darkMode ? 'rgba(255, 255, 255, 0.16)' : 'gray.200',
    };

    const menuListStyle = {
        '& a': bgColorCondition,
        button: {
            '&:hover': bgColorCondition,
        },
        'button[role="menuitem"]:active': bgColorCondition,
    };

    return (
        <MenuList
            sx={menuListStyle}
            bg={darkMode ? 'dark.tcap' : 'white'}
            borderColor={darkMode ? 'dark.border' : 'light.border'}
            {...props}
        >
            {children}
        </MenuList>
    );
};

export default ChakraMenuList;
