import React, {useEffect, useState} from 'react';

import {Card, Script, CompanyAuth} from '../../components/';
import Microsoft from '../../assets/images/smallerMsoft.png';
import {axios} from '../../services/axios';
import {useSelector} from 'react-redux';
import useAddServiceProfile from '../../hooks/useAddServiceProfile';

/*
 * Scripts component for validating a service for a company
 */

const useScriptsHomepage = () => {
    const [view, setView] = useState('');
    const [hasOperatorConnect, setHasOperatorConnect] = useState(false);
    const {script} = useSelector((state) => {
        return {script: {...state.script}};
    });
    // navigation data from state
    const {currentPartner, currentCompany} = useSelector((state) => {
        return {...state.navigationLists};
    });
    const viewCurrentCompany = !Boolean(script?.rowData?.id);
    const partnerID = viewCurrentCompany
        ? currentPartner
        : script?.rowData?.partnerID;
    const companyID = viewCurrentCompany ? currentCompany : script?.rowData?.id;
    const {addProvisionedServiceHandler} = useAddServiceProfile(companyID);

    // back button click handler
    const onBackButtonClick = () => {
        setView('');
    };

    // Check if company has OC as service type
    useEffect(() => {
        const endpoint = `/Services/Company/${companyID}/ServiceProfiles/Available`;
        axios.get(endpoint).then((res) => {
            res.data.forEach((entry) => {
                if (entry.serviceType === 'OperatorConnect') {
                    setHasOperatorConnect(true);
                }
            });
        });
    }, []);

    return {
        viewCurrentCompany,
        partnerID,
        companyID,
        addProvisionedServiceHandler,
        view,
        setView,
        hasOperatorConnect,
        setHasOperatorConnect,
        onBackButtonClick,
    };
};
export default function ScriptsHomepage() {
    const {
        viewCurrentCompany,
        partnerID,
        companyID,
        addProvisionedServiceHandler,
        view,
        setView,
        hasOperatorConnect,
        setHasOperatorConnect,
        onBackButtonClick,
    } = useScriptsHomepage();

    switch (view) {
        case 'APP_AUTH':
            return (
                <CompanyAuth
                    viewCurrentCompany={viewCurrentCompany}
                    onClick={onBackButtonClick}
                />
            );

        case 'POWERSHELL':
            return (
                <Script
                    viewCurrentCompany={viewCurrentCompany}
                    onClick={onBackButtonClick}
                />
            );
        default:
            return (
                <>
                    <div
                        style={{
                            height: 32,
                            fontWeight: 500,
                            lineHeight: 1.2,
                            fontSize: '1.25rem',
                            width: '100%',
                            maxWidth: '650px',
                            margin: '25px auto 0 auto',
                        }}>
                        Select method
                    </div>
                    <div
                        style={{
                            width: '100%',
                            maxWidth: '650px',
                            margin: '15px auto 0 auto',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}>
                        {hasOperatorConnect && (
                            <div>
                                <Card
                                    cardOnClick={() => {
                                        setView('Operator Connect');
                                        addProvisionedServiceHandler();
                                    }}
                                    description="Authorize your Microsoft Teams tenancy to connect using Operator Connect API endpoints. This is only available to approved partners"
                                    title={'Operator Connect'}
                                    image={{
                                        alt: 'Basic template image',
                                        image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Microsoft_Office_Teams_%282018%E2%80%93present%29.svg/800px-Microsoft_Office_Teams_%282018%E2%80%93present%29.svg.png',
                                        title: 'Basic',
                                        style: {
                                            maxWidth: 175,
                                            width: '100%',
                                            padding: 10,
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            display: 'block',
                                        },
                                    }}
                                    divStyle={{padding: 25}}
                                />
                            </div>
                        )}
                        <div>
                            <Card
                                cardOnClick={() => {
                                    setView('APP_AUTH');
                                }}
                                APP_AUTH
                                title={'Direct Routing'}
                                description="Authorize TCAP access to your Microsoft 365 tenancy. Requires a global administrator account to authorize the application."
                                image={{
                                    alt: 'Basic template image',
                                    image: Microsoft,
                                    title: 'Basic',
                                    style: {
                                        width: '100%',
                                        padding: 10,
                                        paddingTop: 80,
                                    },
                                }}
                            />
                        </div>
                    </div>
                </>
            );
    }
}
