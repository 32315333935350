import config from '@/config.json';
import { axios } from '@/services/axios';
import { Box, Divider, useDisclosure } from '@chakra-ui/react';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Button,
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from 'components/v4';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import APIKeyModal from '../APIKeyModal';
import walkthroughIds from '../../walkthroughIds';

export default function EditAdmin({ apiUser, apiUserLoading }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        isOpen: isDeleteOpen,
        onOpen: onDeleteOpen,
        onClose: onDeleteClose,
    } = useDisclosure();
    const {
        isOpen: isResetOpen,
        onOpen: onResetOpen,
        onClose: onResetClose,
    } = useDisclosure();

    const {
        isOpen: isAPIKeyOpen,
        onOpen: onAPIKeyOpen,
        onClose: onAPIKeyClose,
    } = useDisclosure();
    const [apiKey, setApiKey] = useState('');
    const { darkMode } = useSelector((state) => state.settings);

    const [isDeleteBusy, setIsDeleteBusy] = useState(false);
    const [isResetBusy, setIsResetBusy] = useState(false);

    const handleDelete = async () => {
        try {
            setIsDeleteBusy(true);
            await axios.delete(`${config.apiUsers.modalDelete}${apiUser?.id}`);
            toast.success('API User deleted successfully!');
            dispatch({
                type: 'RESET_TABLE',
            });
            navigate('/admins', { state: { index: 2 } });
        } catch (e) {
            toast.error(e?.response?.data?.message);
        } finally {
            setIsDeleteBusy(false);
        }
    };

    const handleReset = async () => {
        try {
            setIsResetBusy(true);
            const res = await axios.post(
                `${config.apiUsers.modalReset}${apiUser?.id}`,
            );

            dispatch({ type: 'RESET_TABLE' });
            setApiKey(res.data.apiKey);
            onResetClose();
            onAPIKeyOpen();
        } catch (e) {
            toast.error(e?.response?.data?.message);
        } finally {
            setIsResetBusy(false);
        }
    };

    const handleBack = () => {
        onAPIKeyClose();
    };

    return (
        <>
            <Heading as="h4" fontSize={'1.5rem'} fontWeight={500}>
                {' '}
                Danger Zone{' '}
            </Heading>
            <Box
                sx={{
                    marginTop: '12.5px',
                    border: '2px solid red',
                    borderRadius: '5px',
                    padding: '5px 15px 5px 15px',
                    backgroundColor: darkMode ? 'dark.bgDark' : 'inherit',
                    textColor: darkMode ? 'white' : 'inherit',
                    '& > hr': {
                        borderColor: 'rgba(0, 0, 0, 0.12)',
                        margin: '10px auto',
                    },
                }}>
                <Box className="flex flex-row">
                    <Box className="w-3.5/4 flex flex-col">
                        <Text className="text-lg font-semibold">Reset</Text>
                        <Text>
                            Forgot or can't remember your api key? This action
                            will invalidate your current API key and generate a
                            new one
                        </Text>
                    </Box>
                    <Box className="flex w-1/4 items-center justify-end">
                        <Button
                            colorScheme="red"
                            minW="80px"
                            onClick={() => onResetOpen()}
                            _focus={{
                                boxShadow: '0 0 0 3px rgba(225, 66, 87, 0.6)',
                            }}
                            data-walkthroughid={
                                walkthroughIds.adminApiUsersAdmin.ufReset
                            }>
                            Reset
                        </Button>
                    </Box>
                </Box>

                <Divider
                    bg={darkMode ? 'white' : 'inherit'}
                    borderColor={'gray.500 !important'}
                />
                <Box className="flex flex-row">
                    <Box className="flex w-3/4 flex-col">
                        <Text className="text-lg font-semibold">Delete</Text>
                        <Text>
                            After deleting, this user will no longer have
                            access.
                        </Text>
                    </Box>
                    <Box className="flex w-1/4 items-center justify-end">
                        <Button
                            colorScheme="red"
                            size={'md'}
                            minW="80px"
                            onClick={() => onDeleteOpen()}
                            _focus={{
                                boxShadow: '0 0 0 3px rgba(225, 66, 87, 0.6)',
                            }}
                            data-walkthroughid={
                                walkthroughIds.adminApiUsersAdmin.ufDelete
                            }>
                            Delete
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Modal isOpen={isDeleteOpen} onClose={onDeleteClose} isCentered>
                <ModalOverlay />
                <ModalContent minW={'30%'}>
                    <ModalHeader>Delete API User: {apiUser?.name}</ModalHeader>
                    <ModalBody>
                        <Alert status="warning">
                            <AlertIcon />
                            <AlertDescription>
                                Are you sure you would like to delete this API
                                user? This will permanently deauthorize the API
                                key and cause any apps using it to break. This
                                cannot be undone.
                            </AlertDescription>
                        </Alert>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            isDisabled={isDeleteBusy}
                            mr={3}
                            onClick={() => onDeleteClose()}
                            data-walkthroughid={
                                walkthroughIds.adminApiUsers.ufClose
                            }>
                            Close
                        </Button>
                        <Button
                            isDisabled={apiUserLoading}
                            variant="solid"
                            colorScheme="red"
                            isLoading={isDeleteBusy}
                            onClick={() => handleDelete()}
                            data-walkthroughid={
                                walkthroughIds.adminApiUsersAdmin.ufModalDelete
                            }>
                            Delete
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isResetOpen} onClose={onResetClose} isCentered>
                <ModalOverlay />
                <ModalContent minW={'30%'}>
                    <ModalHeader>Reset API Key</ModalHeader>
                    <ModalBody>
                        <Alert status="warning" mb={2}>
                            <AlertIcon />
                            <AlertDescription>
                                This action will invalidate your current API key
                                and may result in API calls from your
                                application failing until you switch to the new
                                API key. Any external data asociated with the
                                current API key will be transferred to the new
                                API key.
                            </AlertDescription>
                        </Alert>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            variant="outline"
                            isDisabled={isResetBusy}
                            mr={3}
                            onClick={() => onResetClose()}
                            data-walkthroughid={
                                walkthroughIds.adminApiUsers.ufClose
                            }>
                            Close
                        </Button>
                        <Button
                            isDisabled={apiUserLoading}
                            variant="solid"
                            colorScheme="red"
                            isLoading={isResetBusy}
                            onClick={() => handleReset()}
                            data-walkthroughid={
                                walkthroughIds.adminApiUsersAdmin.ufModalReset
                            }>
                            Reset
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <APIKeyModal
                apiKey={apiKey}
                setApiKey={setApiKey}
                onClose={onAPIKeyClose}
                isOpen={isAPIKeyOpen}
                handleBack={handleBack}
            />
        </>
    );

    {
        /* NEED TO INCLUDE THE DELETE MODAL HERE AND THE RESET MODAL */
    }
}
