export const initialState = {
    // changes to att value will be saved in services
    services: [],
    originalData: [],
    attributesErrors: [],
};

export const useServicesReducer = (state = initialState, action) => {
    switch (action.type) {
        // set services against a user
        case 'ADD_SERVICES': {
            return {
                ...state,
                services: action.payload,
                originalData: action.payload
            };
        }
        // update value of an attribute by index and service index
        case 'UPDATE_ATT_VALUE': {
            return {
                ...state,
                services: state.services.map((service, sIndex) => {
                    if (sIndex !== action.serviceIndex) {
                        return service;
                    }
                    return {
                        ...service,
                        attributes: service.attributes.map(
                            (attribute, aIndex) => {
                                if (aIndex !== action.attIndex) {
                                    return attribute;
                                }
                                return {
                                    ...attribute,
                                    value: action.payload,
                                };
                            },
                        ),
                    };
                }),
            };
        }
        // update original data after successfully save attribute
        case 'UPDATE_OG_ATT_VALUE': {
            return {
                ...state,
                originalData: state.originalData.map((service, sIndex) => {
                    if (sIndex !== action.serviceIndex) {
                        return service;
                    }
                    return {
                        ...service,
                        attributes: service.attributes.map(
                            (attribute, aIndex) => {
                                if (aIndex !== action.attIndex) {
                                    return attribute;
                                }
                                return {
                                    ...attribute,
                                    value: action.payload,
                                };
                            },
                        ),
                    };
                }),
            };
        }
        // add a attribute error if does not exist
        case 'ADD_ATT_ERROR': {
            if (
                !state.attributesErrors.some(
                    (obj) =>
                        obj.id == action.index &&
                        obj.serviceIndex == action.serviceIndex,
                )
            ) {
                return {
                    ...state,
                    attributesErrors: [
                        ...state.attributesErrors,
                        {
                            ...action.payload,
                        },
                    ],
                };
            } else return { ...state };
        }
        // remove/override attribute error
        case 'UPDATE_ATT_ERROR': {
            if (
                state.attributesErrors.some(
                    (obj) =>
                        obj.id == action.index &&
                        obj.serviceIndex == action.serviceIndex,
                )
            ) {
                return {
                    ...state,
                    attributesErrors: state.attributesErrors.filter(
                        (obj) =>
                            obj.id !== action.index ||
                            obj.serviceIndex !== action.serviceIndex,
                    ),
                };
            } else return { ...state };
        }
        default:
            return state;
    }
};
