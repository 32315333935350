import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import {
    Box,
    FormControl,
    Tooltip,
    forwardRef,
    Link as ALink,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import { FaExternalLinkAlt } from 'react-icons/fa';
import ReCAPTCHA from 'react-google-recaptcha';

import useDetectFileType from 'hooks/useDetectFileType';
import useFieldValidation from 'hooks/useFieldValidation';
import useNormaliseQueryStrings from 'hooks/useNormaliseQueryStrings';
import useTempPasswordChecker, { PasswordFieldValidation } from '../Passwords';

import {
    Alert,
    AlertDescription,
    AlertIcon,
    Button,
    Checkbox,
    FormLabel as ChakraFormLabel,
    Input as ChakraInput,
    Image,
} from 'components/v4/';
import Grid from 'components/v4/Grid';
import FieldValidation from 'components/v4/FieldValidation';
import RegisterSuccess from './RegisterSuccess';

import { validationSchema } from './consts';

const FormLabel = forwardRef((props, ref) => (
    <ChakraFormLabel ref={ref} className="!text-black" {...props} />
));

const Input = forwardRef((props, ref) => (
    <ChakraInput ref={ref} className="!text-black" {...props} />
));

export default function Register() {
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const [hasRegistered, setHasRegistered] = useState(false);
    const [hasError, setHasError] = useState(null);
    const [isRegistering, setIsRegistering] = useState(false);

    const [publicPartnerData, setPublicPartnerData] = useState(null);

    const normaliseQueryStrings = useNormaliseQueryStrings();

    /**
     * Google captcha setup.
     */
    const recaptchaRef = useRef();
    const gCaptchaCb = (token) => {
        setRegistration((p) => ({
            ...p,
            ['g-recaptcha-response']: token,
        }));
    };

    /**
     * Pingco Logo.
     */
    const { imagePathLightMode } = useSelector((state) => {
        return { ...state.login };
    });

    const fileType = useDetectFileType(
        import.meta.env.VITE_APP_IMAGE_BASE + imagePathLightMode,
    );

    const [showPasswordModal, setShowPasswordModal] = useState();

    const [registration, setRegistration] = useState({
        companyName: '',
        firstName: '',
        lastName: '',
        emailAddress: ``,
        password: '',
        contactNumber: '',
        'g-recaptcha-response': '',
        partnerCode: '',
        approvalCode: '',
    });

    const [hasHardCodedPartnerCode, setHasHardCodedPartnerCode] =
        useState(false);

    const { validationErrors, validatefn } = useFieldValidation(
        registration,
        validationSchema,
    );

    const [hasAcceptedTos, setHasAcceptedTos] = useState(false);
    const [tosAcceptError, setTosAcceptError] = useState(false);

    const passwordErrors = useTempPasswordChecker(registration.password);

    /**
     * Check for partner codes.
     */
    useEffect(() => {
        // Check for query params to populate the form with
        parseQueryParams();

        // Check for partner codes
        const hasPartnerCode = location.pathname.split('/register/');
        if (hasPartnerCode.length > 1) {
            const partnerCode = location.pathname.replace('/register/', '');
            setHasHardCodedPartnerCode(true);
            setRegistration((p) => ({
                ...p,
                partnerCode,
            }));
        }

        // Grab public branding object to detect TOS link
        const host = window.location.host.startsWith('127.0.0.1')
            ? 'teamsdev.tcap.dev'
            : window.location.host;
        fetch(`${import.meta.env.VITE_APP_API_URL}/theme/${host}`, {
            headers: {
                'x-requestscope': '20',
            },
        }).then(async (response) => {
            const data = await response.json();
            setPublicPartnerData(data);
        });
    }, []);

    function registrationFormHandler(e, key) {
        e.persist();
        setRegistration((p) => ({
            ...p,
            [key]: e.target.value,
        }));
    }

    function parseQueryParams() {
        const newParams = normaliseQueryStrings();

        const approvalCode = newParams.get('approvalcode');
        const partnerCode = newParams.get('partnercode');
        const companyName = newParams.get('companyname');
        const firstName = newParams.get('firstname');
        const lastName = newParams.get('lastname');
        const emailAddress = newParams.get('email');
        const telephonenumber = newParams.get('telephonenumber');

        if (partnerCode) {
            setRegistration((p) => ({
                ...p,
                partnerCode,
            }));
        }
        if (approvalCode) {
            setRegistration((p) => ({
                ...p,
                approvalCode,
            }));
        }
        if (companyName) {
            setRegistration((p) => ({
                ...p,
                companyName,
            }));
        }
        if (firstName) {
            setRegistration((p) => ({
                ...p,
                firstName,
            }));
        }
        if (lastName) {
            setRegistration((p) => ({
                ...p,
                lastName,
            }));
        }
        if (emailAddress) {
            setRegistration((p) => ({
                ...p,
                emailAddress,
            }));
        }
        if (telephonenumber) {
            setRegistration((p) => ({
                ...p,
                contactNumber: telephonenumber,
            }));
        }
    }

    function onRegister(e) {
        e.preventDefault();
        setIsRegistering(true);

        // Validate TOS checkbox, if the partner has set TOS
        if (
            publicPartnerData?.extLinkRegistrationTerms ||
            publicPartnerData?.extLinkPrivacyPolicy
        ) {
            if (!hasAcceptedTos) {
                setTosAcceptError(["You didn't accept the Terms of Service."]);
                setIsRegistering(false);
                recaptchaRef.current.reset();
            } else {
                setTosAcceptError(null);
            }
        }

        // Validate Fields
        if (!validatefn() || passwordErrors?.length) {
            setIsRegistering(false);
            recaptchaRef.current.reset();
            return;
        }

        let formData = new FormData();
        formData.append('registrationFormDomain', window.location.host);
        formData.append('o365Sync', true);
        formData.append('trunkLocationID', 'MEL');
        Object.entries(registration).map((field) => {
            formData.append(field[0], field[1]);
        });

        fetch(`${import.meta.env.VITE_APP_API_URL}/register`, {
            headers: {
                'x-requestscope': '20',
            },
            body: formData,
            method: 'POST',
            mode: 'cors',
        }).then(async (response) => {
            setIsRegistering(false);

            const data = await response.json();
            if (data.message) {
                recaptchaRef.current.reset();
                setHasError(data.message);
            }
            if (data.success) {
                setHasError(null);
                setHasRegistered(true);
            }
        });
    }

    return (
        <>
            {hasRegistered ? (
                <Box
                    sx={{
                        padding: '30px 60px',
                        background: 'white',
                        boxShadow: '2xl',
                        maxWidth: '700px',
                        width: '100%',
                        margin: '0 auto',
                        borderRadius: '10px',
                    }}>
                    {fileType === 'svg' && (
                        <object
                            style={{
                                display: 'block',
                                maxHeight: '150px',
                                maxWidth: '300px',
                                margin: '0 auto 40px auto',
                            }}
                            data={
                                import.meta.env.VITE_APP_IMAGE_BASE +
                                imagePathLightMode
                            }
                            type="image/svg+xml">
                            Domain Logo
                        </object>
                    )}
                    {fileType === 'png' && (
                        <Image
                            alt="Domain Logo"
                            style={{
                                display: 'block',
                                maxHeight: '150px',
                                maxWidth: '300px',
                                margin: '0 auto 40px auto',
                            }}
                            src={
                                import.meta.env.VITE_APP_IMAGE_BASE +
                                imagePathLightMode
                            }
                        />
                    )}
                    <RegisterSuccess email={registration.emailAddress} />
                </Box>
            ) : (
                <Box
                    sx={{
                        padding: '20px 60px',
                        background: 'white',
                        boxShadow: '2xl',
                        maxWidth: '1000px',
                        width: '100%',
                        margin: '0 auto',
                        borderRadius: '10px',
                    }}>
                    {fileType === 'svg' && (
                        <object
                            style={{
                                display: 'block',
                                maxHeight: '150px',
                                maxWidth: '300px',
                                margin: '0 auto',
                            }}
                            data={
                                import.meta.env.VITE_APP_IMAGE_BASE +
                                imagePathLightMode
                            }
                            type="image/svg+xml">
                            Domain Logo
                        </object>
                    )}
                    {fileType === 'png' && (
                        <Image
                            alt="Domain Logo"
                            style={{
                                display: 'block',
                                maxHeight: '150px',
                                maxWidth: '300px',
                                margin: '0 auto',
                            }}
                            src={
                                import.meta.env.VITE_APP_IMAGE_BASE +
                                imagePathLightMode
                            }
                        />
                    )}
                    <Box sx={{ marginBottom: 25, display: 'flex' }} />
                    <Box>
                        <form onSubmit={onRegister}>
                            <Grid columns={2} className="gap-x-10 gap-y-8">
                                {/* Friendly company name */}
                                <Box>
                                    <FormLabel
                                        sx={{ margin: 0 }}
                                        for="register-friendly-company-name">
                                        COMPANY NAME
                                    </FormLabel>
                                    <Input
                                        borderColor="gray"
                                        variant="flushed"
                                        size="sm"
                                        id="register-friendly-company-name"
                                        value={registration.companyName}
                                        onChange={(e) => {
                                            registrationFormHandler(
                                                e,
                                                'companyName',
                                            );
                                            registrationFormHandler(
                                                e,
                                                'entityName',
                                            );
                                        }}
                                    />
                                    <FieldValidation
                                        errors={validationErrors?.companyName}
                                    />
                                </Box>

                                {/* First name */}
                                <Box>
                                    <FormLabel
                                        sx={{ margin: 0 }}
                                        for="register-first-name">
                                        FIRST NAME
                                    </FormLabel>
                                    <Input
                                        borderColor="gray"
                                        variant="flushed"
                                        size="sm"
                                        id="register-first-name"
                                        value={registration.firstName}
                                        onChange={(e) =>
                                            registrationFormHandler(
                                                e,
                                                'firstName',
                                            )
                                        }
                                    />
                                    <FieldValidation
                                        errors={validationErrors?.firstName}
                                    />
                                </Box>

                                {/* Last name */}
                                <Box>
                                    <FormLabel
                                        sx={{ margin: 0 }}
                                        for="register-last-name">
                                        LAST NAME
                                    </FormLabel>
                                    <Input
                                        borderColor="gray"
                                        variant="flushed"
                                        size="sm"
                                        id="register-last-name"
                                        value={registration.lastName}
                                        onChange={(e) =>
                                            registrationFormHandler(
                                                e,
                                                'lastName',
                                            )
                                        }
                                    />
                                    <FieldValidation
                                        errors={validationErrors?.lastName}
                                    />
                                </Box>

                                {/* Email */}
                                <Box>
                                    <FormLabel
                                        sx={{ margin: 0 }}
                                        for="register-username">
                                        EMAIL
                                    </FormLabel>
                                    <Input
                                        borderColor="gray"
                                        variant="flushed"
                                        size="sm"
                                        id="register-username"
                                        value={registration.emailAddress}
                                        onChange={(e) =>
                                            registrationFormHandler(
                                                e,
                                                'emailAddress',
                                            )
                                        }
                                    />
                                    <FieldValidation
                                        errors={validationErrors?.emailAddress}
                                    />
                                </Box>

                                {/* Password */}
                                <Box position="relative">
                                    <FormLabel
                                        sx={{ margin: 0 }}
                                        for="register-password">
                                        PASSWORD
                                    </FormLabel>
                                    <Input
                                        onFocus={() => {
                                            setShowPasswordModal(true);
                                        }}
                                        onBlur={() => {
                                            setShowPasswordModal(false);
                                        }}
                                        type="password"
                                        borderColor="gray"
                                        variant="flushed"
                                        size="sm"
                                        id="register-password"
                                        value={registration.password}
                                        onChange={(e) => {
                                            registrationFormHandler(
                                                e,
                                                'password',
                                            );
                                        }}
                                    />

                                    <PasswordFieldValidation
                                        show={showPasswordModal}
                                        errors={passwordErrors}
                                    />
                                </Box>

                                {/* Contact Number */}
                                <Box>
                                    <FormLabel
                                        sx={{ margin: 0 }}
                                        for="register-contactNumber">
                                        TELEPHONE NUMBER
                                    </FormLabel>
                                    <Input
                                        borderColor="gray"
                                        variant="flushed"
                                        size="sm"
                                        id="register-contactNumber"
                                        value={registration.contactNumber}
                                        onChange={(e) =>
                                            registrationFormHandler(
                                                e,
                                                'contactNumber',
                                            )
                                        }
                                    />
                                    <FieldValidation
                                        errors={validationErrors?.contactNumber}
                                    />
                                </Box>

                                {/* Partner Code */}
                                <FormControl
                                    as="fieldset"
                                    isReadOnly={hasHardCodedPartnerCode}>
                                    <FormLabel
                                        sx={{ margin: 0 }}
                                        for="register-partnerCode">
                                        PARTNER CODE (OPTIONAL)
                                        <Tooltip
                                            label={
                                                "If you are using a partner to assist in your onboarding, please enter their code below. If you don't have one you can leave it blank."
                                            }
                                            fontSize="md">
                                            <InfoIcon ml={1} />
                                        </Tooltip>
                                    </FormLabel>
                                    <Input
                                        borderColor="gray"
                                        variant="flushed"
                                        size="sm"
                                        id="register-partnerCode"
                                        value={registration.partnerCode}
                                        onChange={(e) =>
                                            registrationFormHandler(
                                                e,
                                                'partnerCode',
                                            )
                                        }
                                    />
                                    <FieldValidation
                                        errors={
                                            validationErrors?.registerPartnerCode
                                        }
                                    />
                                </FormControl>

                                {/* Approve Code */}
                                {registration.approvalCode && (
                                    <FormControl as="fieldset">
                                        <FormLabel sx={{ margin: 0 }}>
                                            APPROVAL CODE
                                        </FormLabel>
                                        <Input
                                            borderColor="gray"
                                            variant="flushed"
                                            size="sm"
                                            value={registration.approvalCode}
                                            onChange={(e) =>
                                                registrationFormHandler(
                                                    e,
                                                    'approvalCode',
                                                )
                                            }
                                        />
                                        <FieldValidation
                                            errors={
                                                validationErrors?.registerApprovalCode
                                            }
                                        />
                                    </FormControl>
                                )}
                            </Grid>

                            <Box sx={{ margin: '25px 0', display: 'flex' }} />

                            {(publicPartnerData?.extLinkRegistrationTerms ||
                                publicPartnerData?.extLinkPrivacyPolicy) && (
                                <>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            border: '1px dashed',
                                            borderColor: 'gray.400',
                                            borderRadius: '5px',
                                            p: '10px',
                                        }}>
                                        <Checkbox
                                            size="lg"
                                            mb={0}
                                            mr={'6px'}
                                            colorScheme="whatsapp"
                                            onChange={() =>
                                                setHasAcceptedTos(
                                                    !hasAcceptedTos,
                                                )
                                            }
                                        />
                                        <FormLabel
                                            sx={{
                                                fontWeight: 'normal',
                                                margin: 0,
                                            }}>
                                            By clicking this box you have read
                                            and agree to the {` `}
                                            {publicPartnerData?.extLinkRegistrationTerms && (
                                                <Box
                                                    sx={{
                                                        color: 'brand.500',
                                                        display: 'inline',
                                                    }}>
                                                    <ALink
                                                        target="_blank"
                                                        href={
                                                            publicPartnerData?.extLinkRegistrationTerms
                                                        }>
                                                        Terms and Conditions{' '}
                                                        <FaExternalLinkAlt
                                                            className="sidebar-item-icon inline-block"
                                                            fontSize="small"
                                                        />{' '}
                                                    </ALink>{' '}
                                                </Box>
                                            )}
                                            {publicPartnerData?.extLinkRegistrationTerms &&
                                                publicPartnerData?.extLinkPrivacyPolicy &&
                                                ' and '}
                                            {publicPartnerData?.extLinkPrivacyPolicy && (
                                                <Box
                                                    sx={{
                                                        color: 'brand.500',
                                                        display: 'inline',
                                                    }}>
                                                    <ALink
                                                        target="_blank"
                                                        href={
                                                            publicPartnerData?.extLinkPrivacyPolicy
                                                        }>
                                                        Privacy Policy{' '}
                                                        <FontAwesomeIcon
                                                            icon={
                                                                FaExternalLinkAlt
                                                            }
                                                            className="sidebar-item-icon"
                                                            fontSize="small"
                                                        />{' '}
                                                    </ALink>{' '}
                                                </Box>
                                            )}
                                            and you are authorized to accept
                                            these on behalf of your company.
                                        </FormLabel>
                                    </FormControl>

                                    <Box
                                        sx={{
                                            margin: '3px 0',
                                            display: 'flex',
                                        }}
                                    />

                                    <FieldValidation errors={tosAcceptError} />
                                </>
                            )}

                            <Box sx={{ margin: '25px 0', display: 'flex' }} />

                            <Box>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey="6Lci89EUAAAAAHIeIMWNhPxlbJE55ExpBlRkWr93"
                                    onChange={gCaptchaCb}></ReCAPTCHA>
                                <FieldValidation
                                    errors={
                                        validationErrors?.[
                                            'g-recaptcha-response'
                                        ]
                                    }
                                />
                            </Box>

                            <Box sx={{ marginTop: '25px', display: 'flex' }} />

                            {hasError && (
                                <>
                                    <Alert status="error">
                                        <AlertIcon />
                                        <AlertDescription className="text-black">
                                            {hasError}
                                        </AlertDescription>
                                    </Alert>
                                    <Box
                                        sx={{
                                            margin: '0 0 20px 0',
                                            display: 'flex',
                                        }}
                                    />
                                </>
                            )}

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}>
                                <Button
                                    colorScheme="messenger"
                                    type="submit"
                                    disabled={isRegistering}>
                                    REGISTER
                                </Button>
                            </Box>
                        </form>
                        <Link to="/">
                            <Button
                                variant="link"
                                sx={{
                                    textAlign: 'center',
                                    margin: '10px auto 0 auto',
                                    display: 'block',
                                }}>
                                Back to login
                            </Button>
                        </Link>
                    </Box>
                </Box>
            )}
        </>
    );
}
