import { useState, useEffect, useReducer, memo } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import {
    Box,

    // MenuItem,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { EditIcon, LockIcon, RepeatIcon, DeleteIcon } from '@chakra-ui/icons';
import { FaUserCog } from 'react-icons/fa';
import { SiAzuredevops } from 'react-icons/si';

// DR Card
import DashboardServiceCard from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCard';
import DashboardServiceCardDrStatusbar from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardDrStatusbar';
import DashboardServiceCardContent from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardContent';
import DashboardServiceCardHeader from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardHeader';
import DashboardServiceCardHeaderActionButton from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardHeaderActionButton';
import AppAuthStatus from './AppAuthStatus';

// Components
import Text from 'components/v4/Text';
import Image from 'components/v4/Image';
import ServiceDashboardItemSyncUsers from 'components/v4/Services/ServiceDashboard/ServiceDashboardItemSyncUsers';
import ServiceDashboardTenantId from 'components/v4/Services/ServiceDashboard/ServiceDashboardTenantId';
import { MenuItem } from 'components/v4';

// Util
import { dateStringToDate } from 'utils/utils';

// API
import postOverrideLimit from 'API/Service/postOverrideLimit';
import postSyncAzure from 'API/Service/postSyncAzure';

// Assets
import MsAppAuthLogo from 'assets/images/Graph_API_logo.png';
import postTCAPUserReset from 'API/Service/postTCAPUserReset';

import {
    INVALID_USERNAME_PASSWORD,
    EXPIRED_PASSWORD,
    DISABLED_DELETE_USER,
} from '../constants';
import OverrideLimitModal from 'components/modal/OverrideLimitModal';
import { cardStatusReducer, initialState } from './cardStatusReducer';
import path from '@/routes/constants';

const walkthroughIdPrefix = `/dashboard-services/card`;

export function ServiceDashboardItemLastHealthCheck({ data }) {
    if (!data.lastProcessed)
        return (
            <Box
                sx={{
                    justifyContent: 'space-between',
                    marginBottom: '15px',
                }}
            >
                <Text
                    sx={{
                        fontWeight: '500',
                        minWidth: '120px',
                        marginBottom: '5px',
                        visibility: 'hidden',
                    }}
                >
                    {data.lastProcessed && 'Last Health Check'}
                </Text>
                <Text sx={{ visibility: 'hidden' }}>
                    {'Last Health Check,Last Health Check,Last Health Check'}
                </Text>
            </Box>
        );

    return (
        <Box
            sx={{
                justifyContent: 'space-between',
                marginBottom: '15px',
            }}
        >
            <Text
                sx={{
                    fontWeight: '500',
                    minWidth: '120px',
                    marginBottom: '5px',
                }}
            >
                {data.lastProcessed && 'Last Health Check'}
            </Text>
            <Text>{data.lastProcessed ?? ''}</Text>
        </Box>
    );
}

export default function AppAuthCard({
    service,
    serviceWizard,
    companyAppAuth,
    companyData,
}) {
    const modalState = useDisclosure();
    const accountScope = useSelector((state) => state.login.requiredScope);
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [status, setStatus] = useState('idle');
    const [data, setData] = useState(service);
    const [authData, setAuthData] = useState(null);

    const [serviceObj, setServiceObj] = useState(null);

    const [currentCompanyAppAuth, setCurrentCompanyAppAuth] = useState(null);
    const [state, dispatch] = useReducer(cardStatusReducer, initialState);
    const { cardStatus } = state;

    const [actionPermissions, setActionPermissions] = useState({
        menuEditServiceScope: 20,
        menuProvideAccessScope: 20,
        menuSelectiveSyncScope: 20,
        menuOverrideDeleteLimit: 20,
        menuSyncAzure: 20,
    });

    useEffect(() => {
        // if (companyAppAuth && currentCompanyAppAuth?.id !== companyAppAuth.id) {
        if (companyAppAuth) {
            //set MS Service Auth & Service Data.
            setData({
                ...serviceWizard.msServiceAuth,
                ...service,
                hasAppAuthData: true,
                lastProcessed:
                    serviceWizard.msServiceAuth?.lastProcessedTS &&
                    dateStringToDate(
                        serviceWizard.msServiceAuth?.lastProcessedTS,
                        'datetime',
                    ),
            });
            setAuthData(serviceWizard.msServiceAuth);
            setCurrentCompanyAppAuth(companyAppAuth);
        }
        if (service.id) {
            setServiceObj({ ...service, company: companyData });
        }
        // Full service details
        // getService(service.id)
        //     .then((response) => {
        //         setServiceObj(response.data);
        //     })
        //     .finally(() => {
        //         setIsLoading(false);
        //     });
    }, [companyAppAuth, service, serviceWizard.msServiceAuth]);

    useEffect(() => {
        if (serviceObj) {
            // Provide Access
            if (data.graphAuth === false) {
                setActionPermissions((p) => ({
                    ...p,
                    ...{
                        menuProvideAccessScope: 20,
                    },
                }));
            } else {
                setActionPermissions((p) => {
                    if (p.hasOwnProperty('menuProvideAccessScope')) {
                        delete p.menuProvideAccessScope;
                        return p;
                    }
                    return p;
                });
            }

            // Selective Sync
            if (
                serviceObj?.company?.selectiveSyncPolicy !== 0 &&
                serviceWizard?.msServiceAuth?.selectiveSync
            ) {
                //not disabled
                setActionPermissions((p) => ({
                    ...p,
                    ...{
                        menuSelectiveSyncScope: 20,
                    },
                }));
            } else {
                setActionPermissions((p) => {
                    if (p.hasOwnProperty('menuSelectiveSyncScope')) {
                        delete p.menuSelectiveSyncScope;
                        return p;
                    }
                    return p;
                });
            }
        }
    }, [
        currentCompanyAppAuth,
        companyAppAuth,
        serviceObj,
        service,
        serviceObj?.selectiveSync,
    ]);

    const classes = classNames({
        flash: service.changes === 'added',
    });

    useEffect(() => {
        if (!isOpen) {
            setStatus('idle');
        }
    }, [isOpen]);

    const handlePostOverrideLimit = async () => {
        setStatus('loading');
        const res = await postOverrideLimit(data.id);
        if (res.status === 200) {
            setStatus('success');
            toast({
                title: 'Override Limit',
                description:
                    'Override limit has been submitted for processing.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            onClose();
        } else {
            setStatus('error');
            toast({
                title: 'Override Limit',
                description:
                    'Unable to Process Request. One or more validation errors occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleSyncAzure = async () => {
        const res = await postSyncAzure(data.id);
        if (res.status === 200) {
            toast({
                title: 'Sync Azure',
                description: 'Azure Sync has been submitted for processing.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } else {
            toast({
                title: 'Sync Azure',
                description:
                    'Unable to Process Request. One or more validation errors occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleResetServiceAdmin = async () => {
        const res = await postTCAPUserReset(data.id);
        if (res.status === 200) {
            toast({
                title: 'Reset Service Admin',
                description: 'Reset Service Admin has been submitted.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } else {
            toast({
                title: 'Reset Service Admin',
                description:
                    'Unable to Process Request. One or more validation errors occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    // submit Override Limit modal
    const handleSubmit = (e) => {
        e.preventDefault();
        handlePostOverrideLimit();
    };

    // const ActionsMenu = memo(function ActionsMenu() {
    //     // Iterate obj, are any of these above or equal to my account level scope
    //     const [show, setShow] = useState(false);

    //     useEffect(() => {
    //         for (const [key, value] of Object.entries(actionPermissions)) {
    //             if (accountScope >= value) {
    //                 setShow(true);
    //             }
    //         }
    //     }, []);

    //     if (!show) return null;

    //     return (
    //         <DashboardServiceCardHeaderActionButton
    //             dataWalkthroughid={service.serviceType}>
    //             {/* Edit service */}
    //             <Link
    //                 to={`/services/${data.id}`}
    //                 state={service}
    //                 className="hover:!no-underline hover:text-inherit">
    //                 <MenuItem
    //                     icon={<EditIcon />}
    //                     data-walkthroughid={`${walkthroughIdPrefix}/${service.serviceType}/edit-appAuth`}>
    //                     <Text marginBottom={'0'}>Edit AppAuth</Text>
    //                 </MenuItem>
    //             </Link>

    //             {/* Override Delete Limit */}

    //             {accountScope >= actionPermissions.menuOverrideDeleteLimit && (
    //                 <MenuItem
    //                     icon={<DeleteIcon />}
    //                     onClick={onOpen}
    //                     data-walkthroughid={`${walkthroughIdPrefix}/${service.serviceType}/override-deleteLimit`}>
    //                     <Text marginBottom={'0'}>Override Delete Limit</Text>
    //                 </MenuItem>
    //             )}

    //             {/* Sync Azure */}
    //             {accountScope >= actionPermissions.menuSyncAzure && (
    //                 <MenuItem
    //                     icon={<SiAzuredevops />}
    //                     onClick={handleSyncAzure}
    //                     data-walkthroughid={`${walkthroughIdPrefix}/${service.serviceType}/sync-azure`}>
    //                     <Text marginBottom={'0'}>Sync Azure</Text>
    //                 </MenuItem>
    //             )}
    //             {/* Reset Service Admin */}
    //             {serviceWizard.companyAppAuth[0].status === 20 &&
    //                 [
    //                     INVALID_USERNAME_PASSWORD,
    //                     EXPIRED_PASSWORD,
    //                     DISABLED_DELETE_USER,
    //                 ].some((message) =>
    //                     serviceWizard.companyAppAuth[0]?.engineMessage.includes(
    //                         message,
    //                     ),
    //                 ) && (
    //                     <MenuItem
    //                         icon={<FaUserCog />}
    //                         onClick={handleResetServiceAdmin}
    //                         data-walkthroughid={`${walkthroughIdPrefix}/${service.serviceType}/reset-serviceAdmin`}>
    //                         <Text>Reset Service Admin</Text>
    //                     </MenuItem>
    //                 )}

    //             {/* Provide Access */}
    //             {accountScope >= actionPermissions.menuProvideAccessScope && (
    //                 <MenuItem
    //                     icon={<LockIcon />}
    //                     onClick={async () =>
    //                         await serviceWizard.microsoftAuth()
    //                     }
    //                     data-walkthroughid={`${walkthroughIdPrefix}/${service.serviceType}/provide-access`}>
    //                     <Text marginBottom={'0'}>Provide Access</Text>
    //                 </MenuItem>
    //             )}
    //         </DashboardServiceCardHeaderActionButton>
    //     );
    // });

    return (
        <>
            <ServiceDashboardTenantId {...modalState} />
            <DashboardServiceCard
                className={classes}
                dataWalkthroughId={service.name || service.serviceType}
            >
                <DashboardServiceCardDrStatusbar status={cardStatus} />
                <DashboardServiceCardHeader>
                    <Text fontSize="lg" className="truncate" marginBottom={'0'}>
                        Microsoft Graph API
                    </Text>
                    {/* MENU ITEMS  */}
                    {/* <ActionsMenu /> NOTE: comment this because of flickering button */}
                    <DashboardServiceCardHeaderActionButton
                        dataWalkthroughId={service.serviceType}
                    >
                        {/* Edit service */}
                        <Link
                            to={`${path.SERVICES_APP_AUTH}/${data.id}/edit`}
                            state={service}
                            className="hover:text-inherit hover:!no-underline"
                        >
                            <MenuItem
                                icon={<EditIcon />}
                                data-walkthroughid={`${walkthroughIdPrefix}/${service.serviceType}/edit-appAuth`}
                            >
                                <Text marginBottom={'0'}>Edit AppAuth</Text>
                            </MenuItem>
                        </Link>

                        {/* Override Delete Limit */}

                        {accountScope >=
                            actionPermissions.menuOverrideDeleteLimit && (
                            <MenuItem
                                icon={<DeleteIcon />}
                                onClick={onOpen}
                                data-walkthroughid={`${walkthroughIdPrefix}/${service.serviceType}/override-deleteLimit`}
                            >
                                <Text marginBottom={'0'}>
                                    Override Delete Limit
                                </Text>
                            </MenuItem>
                        )}

                        {/* Sync Azure */}
                        {accountScope >= actionPermissions.menuSyncAzure && (
                            <MenuItem
                                icon={<SiAzuredevops />}
                                onClick={handleSyncAzure}
                                data-walkthroughid={`${walkthroughIdPrefix}/${service.serviceType}/sync-azure`}
                            >
                                <Text marginBottom={'0'}>Sync Azure</Text>
                            </MenuItem>
                        )}
                        {/* Reset Service Admin */}
                        {serviceWizard.companyAppAuth[0].status === 20 &&
                            [
                                INVALID_USERNAME_PASSWORD,
                                EXPIRED_PASSWORD,
                                DISABLED_DELETE_USER,
                            ].some((message) =>
                                serviceWizard.companyAppAuth[0]?.engineMessage.includes(
                                    message,
                                ),
                            ) && (
                                <MenuItem
                                    icon={<FaUserCog />}
                                    onClick={handleResetServiceAdmin}
                                    data-walkthroughid={`${walkthroughIdPrefix}/${service.serviceType}/reset-serviceAdmin`}
                                >
                                    <Text>Reset Service Admin</Text>
                                </MenuItem>
                            )}

                        {/* Provide Access */}
                        {accountScope >=
                            actionPermissions.menuProvideAccessScope && (
                            <MenuItem
                                icon={<LockIcon />}
                                onClick={async () =>
                                    await serviceWizard.microsoftAuth()
                                }
                                data-walkthroughid={`${walkthroughIdPrefix}/${service.serviceType}/provide-access`}
                            >
                                <Text marginBottom={'0'}>Provide Access</Text>
                            </MenuItem>
                        )}
                        {/* Selective Sync */}
                        {/* {accountScope >= */}
                        {/*     actionPermissions.menuSelectiveSyncScope && ( */}
                        {/*     <MenuItem */}
                        {/*         icon={<RepeatIcon />} */}
                        {/*         onClick={() => { */}
                        {/*             serviceWizard.setSelectiveSyncEditData( */}
                        {/*                 authData, */}
                        {/*             ); */}
                        {/*             serviceWizard.setSelectedServiceProfile( */}
                        {/*                 service, */}
                        {/*             ); */}
                        {/*             serviceWizard.setFlow( */}
                        {/*                 'SelectiveSyncUsersEdit', */}
                        {/*             ); */}
                        {/*         }} */}
                        {/*         data-walkthroughid={`/dashboard-services/card/${service.name}/selective-sync`}> */}
                        {/*         <Text marginBottom={'0'}>Selective Sync</Text> */}
                        {/*     </MenuItem> */}
                        {/* )} */}
                    </DashboardServiceCardHeaderActionButton>
                </DashboardServiceCardHeader>
                <DashboardServiceCardContent>
                    <Box>
                        <Image
                            src={MsAppAuthLogo}
                            sx={{
                                maxWidth: '50px',
                                margin: '0 auto 15px auto',
                            }}
                        />

                        <Box
                            sx={{
                                display: 'flex',
                                flexDir: 'column',
                            }}
                        >
                            {/* Status */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: '15px',
                                }}
                            >
                                <Text
                                    sx={{
                                        fontWeight: '500',
                                    }}
                                >
                                    Status
                                </Text>
                                <AppAuthStatus
                                    companyData={companyData}
                                    serviceObj={serviceObj}
                                    authData={authData}
                                    dispatch={dispatch}
                                    cardStatus={cardStatus}
                                    accountScope={accountScope}
                                />
                            </Box>

                            {/* Sync */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: '15px',
                                }}
                            >
                                <Text
                                    sx={{
                                        fontWeight: '500',
                                    }}
                                >
                                    Sync
                                </Text>
                                <Text sx={{ textAlign: 'left' }}>
                                    {/* TODO: APPAUTH change this when backend is ready */}
                                    <ServiceDashboardItemSyncUsers
                                        data={data}
                                    />
                                </Text>
                            </Box>

                            {/* Last Health Check */}
                            <ServiceDashboardItemLastHealthCheck data={data} />
                        </Box>
                    </Box>
                </DashboardServiceCardContent>
            </DashboardServiceCard>
            {/* Override Delete Limit Modal */}
            <OverrideLimitModal
                isOpen={isOpen}
                onClose={onClose}
                status={status}
                handleSubmit={handleSubmit}
            />
        </>
    );
}
