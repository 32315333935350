import React from 'react';
import MultiSelect from './MultiselectSearchbox';
import {onModalError} from '../tables/TableAndModal';

/*
 * A multiselect variant that remembers the input its hovered overed and then when clicking ->
 * Adds the option to the multiselect that is being focussed
 */
const FillOnClickMultiselect = ({
    staticEnum,
    activeTextareaID,
    activeTextareaField,
    setState,
    label,
    cursorPosition,
    setCursorPosition,
    dataWalkthroughId,
}) => {
    function handleBtn(value) {
        const inputSelected = document.getElementById(activeTextareaID);
        if (inputSelected) {
            const textValue =
                inputSelected.value.substring(0, cursorPosition) +
                value +
                inputSelected.value.substring(
                    cursorPosition,
                    inputSelected.value.length,
                );
            setState({[activeTextareaField]: textValue});
            inputSelected.value = textValue;
            inputSelected.focus();
            inputSelected.setSelectionRange(
                cursorPosition + value.length,
                cursorPosition + value.length,
            );
            setCursorPosition(inputSelected.selectionStart);
        }
    }

    return (
        <MultiSelect
            onError={onModalError}
            disabled
            label={label ?? 'Fields to include (Autofill)'}
            placeholder="+"
            staticEnum={staticEnum}
            value={staticEnum}
            setState={() => {}}
            chip={{
                clickable: true,
                onClick: (e, option) => {
                    handleBtn(option.id);
                },
            }}
            dataWalkthroughid={dataWalkthroughId}
        />
    );
};

export default FillOnClickMultiselect;
