import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form, Row, Col, Container } from 'react-bootstrap';
import Button from 'components/v4/Button';

import { axios } from '../../services/axios';
import { Succeed, Error } from '..';
import { TpmWrapper } from 'pages/tpm';
import walkthroughIds from './walkthroughIds';

/*
 * Form template that either returns a add or edit form depending on redux modal
 */
export default function({
  formContent,
  validateOnSubmit,
  reduxPrefix,
  formAdd,
  formAddOptions,
  reduxState,
  formUpdateOptions,
  submit,
  formUpload,
  mode,
  formFetch,
  formUpdate,
}) {
  const dispatch = useDispatch();
  const [succeed, setSucceed] = useState(false);
  const [uploading, setUploading] = useState(false);

  // state access
  const { loading, restError, freshData, data, state } = useSelector(
    (state) => {
      return { ...state[reduxState] };
    },
  );

  useEffect(() => {
    if (loading) {
      if (formFetch) {
        axios
          .get(formFetch)
          .then((res) => {
            dispatch({
              type: reduxPrefix + 'CHANGE_MODAL_STATE',
              payload: {
                ...res.data,
              },
            });
            dispatch({
              type: reduxPrefix + 'CHANGE_MODAL_HASH',
            });
            dispatch({
              type: reduxPrefix + 'CHANGE_MODAL_LOADING',
              payload: false,
            });
          })
          .catch((e) => {
            dispatch({
              type: reduxPrefix + 'CHANGE_MODAL_BACKEND_ERROR',
              payload: e,
            });
          });
      } else {
        dispatch({
          type: reduxPrefix + 'CHANGE_MODAL_LOADING',
          payload: false,
        });
      }
    }
  }, [loading]);

  // return a circular spinner if loading or uploading
  if (loading || uploading) {
    return (
      <CircularProgress
        style={{ marginTop: '2.5%', marginLeft: '50%' }}
      />
    );
  }

  const onSubmit = (event) => {
    event.preventDefault();
    //nulls error state
    dispatch({
      type: reduxPrefix + 'CHANGE_MODAL_BACKEND_ERROR',
      payload: null,
    });
    //validate first:
    if (
      validateOnSubmit && //need to validate
      !validateOnSubmit() //invalid data detected
    ) {
      dispatch({
        type: reduxPrefix + 'CHANGE_MODAL_BACKEND_ERROR',
        payload: {
          name: 'Field Error',
          message: 'There are some field errors.',
        },
      });
      return;
    }
    setUploading(true);
    setSucceed(false);

    // Add or Get for data in the form fields incase of editing
    switch (mode) {
      case 'Add':
        axios
          .post(
            formAdd,
            formUpload
              ? formUpload(freshData || data, state)
              : {
                ...(freshData || data),
                ...state,
              },
            formAddOptions,
          )
          .then((res) => {
            dispatch({
              type: 'CHANGE_MODAL_HASH',
            });
            setUploading(false);
            setSucceed(true);
          })
          .catch((e) => {
            setUploading(false);
            dispatch({
              type: reduxPrefix + 'CHANGE_MODAL_BACKEND_ERROR',
              payload: e,
            });
          });
        break;
      case 'Edit':
        axios
          .put(
            formUpdate,
            formUpload(freshData || data, state),
            formUpdateOptions,
          )
          .then((res) => {
            dispatch({
              type: 'CHANGE_MODAL_HASH',
            });
            setUploading(false);
            setSucceed(true);
          })
          .catch((e) => {
            setUploading(false);
            dispatch({
              type: reduxPrefix + 'CHANGE_MODAL_BACKEND_ERROR',
              payload: e,
            });
          });
        break;
      default:
        break;
    }
  };
  return (
    <Form onSubmit={onSubmit}>
      <Container fluid style={{ marginTop: 10 }}>
        {succeed && (
          <Succeed
            message="Successful"
          />
        )}
        {restError && (
          <Error error={restError} style={{ marginBottom: 10 }} />
        )}
        {formContent}
        <Row>
          <Col style={{ textAlign: 'center', marginTop: 10 }}>
            <TpmWrapper>
              <Button
                isDisabled={submit?.disabled}
                type="submit"
                data-walkthroughid={walkthroughIds.ufSubmitBtn}
              >
                {submit?.text || 'Submit'}
              </Button>
            </TpmWrapper>
          </Col>
        </Row>
      </Container>
    </Form>
  );
}
