import { Box } from '@chakra-ui/react';

/**
 * Wrapper container for the form page
 * @param {import('@chakra-ui/react').BoxProps} props
 * @property {React.ReactNode} props.children
 * @returns
 */
export function Container(props) {
    return (
        <Box px={0} py={10} maxW="2xl" minW="xl" m="0 auto" {...props}>
            {props.children}
        </Box>
    );
}
