import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    Box,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Text,
    // Alert,
    // AlertIcon,
} from '@chakra-ui/react';

import { classNames } from 'utils/classNames';

import { TpmTrialTableBody, useTpmContext } from './';
import { Alert, AlertIcon, Button } from '@/components/v4';
// import Button from 'components/v4/Button';
import walkthroughIds from './walkthroughIds';
import { postActivateNumbers } from 'API/TPMTrial';

function TpmTrialTable() {
    const {
        tpmNumber: [state],
        tpmAddress: [, setAddressAction],
        tpmActivatedNumber: [telephoneNumbers, setTelephoneNumbers],
    } = useTpmContext();

    const { ufTrialTableNextButton, ufTrialTableCancelButton } =
        walkthroughIds.teamsPhoneMobileTrial;
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState('');
    const { darkMode } = useSelector((state) => state.settings);

    const allNumbers = telephoneNumbers
        .filter((v) => v.check === 'SUCCESS')
        .map((v) => v.number);

    const handleBackButton = () => setAddressAction('SELECT_SERVICES');
    const handleNextButton = async () => {
        //TODO: call activate number here
        setIsLoading(true);
        setError('');
        try {
            await postActivateNumbers({
                serviceID: id,
                phoneNumbers: allNumbers,
            });
            setAddressAction('TRIAL_DONE');
        } catch (e) {
            const msg =
                e?.response?.data?.message ||
                'Error submitting request. Please try again.';
            setError(msg);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!telephoneNumbers.some((v) => v.number === state.number)) {
            setTelephoneNumbers((prev) => [
                {
                    number: state.number,
                    action: '',
                    code: '',
                    check: 'SUCCESS',
                },
                ...prev,
            ]);
        }
    }, [state.number]);

    return (
        <Box className="flex h-tpm flex-col items-center justify-center">
            <Text as="h6" className="mb-6 text-xl font-semibold">
                Enter the additional numbers you would like activated
            </Text>
            <TableContainer
                overflowY="auto"
                maxHeight="500px"
                className={classNames(
                    darkMode
                        ? 'bg-[var(--chakra-colors-tcap-gray)]'
                        : 'bg-white',
                    'w-[768px] max-w-5xl rounded-sm shadow',
                )}>
                <Table>
                    <Thead
                        position="sticky"
                        top={0}
                        background="whiteAlpha.900"
                        zIndex={3}>
                        <Tr>
                            <Th>Telephone Number</Th>
                            <Th>Action</Th>
                            <Th>Code</Th>
                            <Th>Validate</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <TpmTrialTableBody setError={setError} />
                    </Tbody>
                </Table>
            </TableContainer>

            {error ? (
                <Alert status="error" className="mt-4 max-w-[768px]">
                    <AlertIcon />
                    {error}
                </Alert>
            ) : (
                <Box className="mt-[72px]" aria-label="spacing box" />
            )}
            <Box className="mt-6 flex w-[700px] max-w-5xl justify-between">
                <Button
                    variant="outline"
                    bg={darkMode ? 'white' : null}
                    w={{ base: 'full', md: '80px' }}
                    onClick={handleBackButton}
                    data-walkthroughid={ufTrialTableCancelButton}>
                    Back
                </Button>
                <Button
                    onClick={handleNextButton}
                    w={{ base: 'full', md: '80px' }}
                    data-walkthroughid={ufTrialTableNextButton}
                    isLoading={isLoading}
                    isDisabled={isLoading || allNumbers.length === 0}>
                    <div class="absolute -right-2 -top-2 inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-brand-400 bg-white text-xs font-bold text-brand-400">
                        {allNumbers.length}
                    </div>
                    Activate
                </Button>
            </Box>
        </Box>
    );
}

export default TpmTrialTable;

