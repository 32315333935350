import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import {
    Box,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import validator from 'validator';
import tableIcons from 'utils/MaterialTableIcons';
import walkthroughIds from '../walkthroughIds';
import {
    ChakraProvider,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
} from '@chakra-ui/react';
import { Input } from 'components/v4';
import chakratheme from '@/chakratheme';

const columns = [
    {
        field: 'record',
        title: 'Record',
        width: '100%',
        editComponent: (props) => {
            return (
                <TextField
                    size="small"
                    variant="standard"
                    value={props.value}
                    onChange={(e) => {
                        props.onChange(e.target.value);
                    }}
                />
            );
        },
    },
];

const EditSipRegistrationAcl = () => {
    const muiTheme = useMemo(
        () =>
            createTheme({
                head: {
                    backgroundColor: null,
                },
                overrides: {
                    MuiToolbar: {
                        root: {
                            color: 'rgba(255, 255, 255, 0.7)',
                            backgroundColor: '#212121',
                        },
                    },
                    MuiTable: {
                        root: {
                            WebkitTextFillColor:
                                'rgba(255, 255, 255, 0.7) !important',
                            backgroundColor: '#212121 !important',
                        },
                    },
                    MuiTableHead: {
                        root: {
                            backgroundColor: '#212121 !important',
                        },
                    },
                    MuiTablePagination: {
                        root: {
                            backgroundColor: 'white',
                        },
                    },
                    MuiPaper: {
                        root: {
                            backgroundColor: null,
                        },
                    },
                    MuiIconButton: {
                        label: {
                            color: 'rgba(255, 255, 255, 0.3)',
                        },
                    },
                },
            }),
        [],
    );
    const [errors, setErrors] = useState();
    const [open, setOpen] = useState(false);
    const [record, setRecord] = useState();
    const tableRef = useRef(MaterialTable);
    const [rows, setRows] = useState([]);

    const { darkMode } = useSelector((state) => state.settings);

    const dispatch = useDispatch();
    const { state } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // logic for open and close
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setRecord(null);
        setErrors(null);
        setOpen(false);
    };

    /*
     * Function that takes in row data and deletes it
     * The state value of colletion hosts is checked and the row passed into the function is removed
     * This new array is dispatched to state
     * The deletedEndpointIDs array is then deleted in Trunk.jsx using API calls
     */
    const deleteHandler = (row) => {
        // Filter out item
        const newSipAllowedHosts = rows.filter((host) => {
            if (host.record !== row.record) {
                return host;
            }
        });
        setRows(newSipAllowedHosts);

        // CSVify our rows
        const csv = arrayToCsv(newSipAllowedHosts);
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                sipAllowedHosts: csv,
            },
        });
    };

    function arrayToCsv(arr) {
        return arr.map((item) => item.record);
    }

    const onSubmitHandler = () => {
        // We have a new value (record), do we append it or create a fresh array?
        const newRows = rows?.length
            ? [...rows, ...[{ record: record }]]
            : [{ record: record }];
        setRows(newRows);

        // CSVify our rows
        const csv = arrayToCsv(newRows);
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                sipAllowedHosts: csv,
            },
        });
        
        handleClose();
    };

    // Populate list on load if there are values set
    useEffect(() => {
        if (state?.sipAllowedHosts?.length && !rows?.length) {
            const _rows = state.sipAllowedHosts.map((row) => ({ record: row }));

            setRows(_rows);
        }
    }, [state.sipAllowedHosts]);

    function Header() {
        return (
            <>
                <Typography variant="h6" sx={{ margin: '25px 0 0 0' }}>
                    SIP Registration ACL
                </Typography>
                You are required to list the IP's or FQDN's that will have
                access to register.
            </>
        );
    }

    return (
        <ThemeProvider theme={darkMode ? muiTheme : null}>
            <Box
                sx={{
                    th: {
                        backgroundColor: darkMode
                            ? '#212121 !important'
                            : 'white !important',
                    },
                    td: {
                        color: darkMode ? 'black !important' : null,
                    },
                    boxShadow: darkMode ? 1 : 0,
                    borderWidth: darkMode ? 1 : 0,
                    borderStyle: darkMode ? 'solid' : null,
                    borderColor: darkMode ? '#DDD' : null,
                }}>
                <MaterialTable
                    style={{ height: '100%' }}
                    tableRef={tableRef}
                    title={<Header />}
                    icons={tableIcons}
                    columns={columns}
                    data={rows}
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        sorting: true,
                        pageSize: 4,
                        pageSizeOptions: [],
                        draggable: false,
                    }}
                    actions={[
                        {
                            icon: tableIcons.Delete,
                            tooltip: 'Delete',
                            onClick: (e, rowData) => {
                                deleteHandler(rowData);
                            },
                        },
                        {
                            disabled: rows?.length === 4,
                            icon: tableIcons.Add,
                            tooltip: 'Add Entry',
                            isFreeAction: true,
                            onClick: () => {
                                handleOpen();
                            },
                        },
                    ]}
                    /*editable={{
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const editedRows = rows.map((row) => {
                                    if (row.record === oldData.record) {
                                        return {
                                            ...row,
                                            ...{record: newData.record},
                                        };
                                    } else {
                                        return row;
                                    }
                                });
                                setRows(editedRows);
                                dispatch({
                                    type: 'CHANGE_MODAL_STATE',
                                    payload: {
                                        sipAllowedHosts: editedRows,
                                    },
                                });
                                resolve();
                            }, 1000);
                        }),
                }}*/
                    localization={{
                        header: {
                            actions: '',
                        },
                    }}
                />
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                sx={{
                    '& .MuiPaper-root': {
                        bgcolor: darkMode ? '#212121' : null,
                        color: darkMode ? 'white' : null,
                    },
                }}>
                <DialogTitle>SIP Registration ACL</DialogTitle>

                <DialogContent sx={{ width: 400 }}>
                    <ChakraProvider theme={chakratheme} resetCSS>
                        <FormControl isInvalid={errors?.record} isRequired>
                            <FormLabel color={darkMode ? 'white' : null}>
                                Host IP/FQDN{' '}
                            </FormLabel>
                            <Input
                                size={'sm'}
                                placeholder={'Please enter a Host IP/FQDN'}
                                data-walkthroughid={
                                    walkthroughIds.endpoints.modal.ufHostInput
                                }
                                onChange={(e) => {
                                    setErrors({ record: '' });
                                    setRecord(e.target.value);

                                    const isDomain = validator.isFQDN(
                                        e.target.value,
                                    );
                                    const isIp = validator.isIP(e.target.value);

                                    if (!isDomain && !isIp) {
                                        setErrors({
                                            record: 'Only IP addresses or FQDN are permitted',
                                        });
                                    } else {
                                        setErrors(null);
                                    }
                                }}
                            />

                            {errors?.record ? (
                                <FormErrorMessage>
                                    {errors.record}
                                </FormErrorMessage>
                            ) : (
                                <FormHelperText>
                                    eg. 10.1.1.1 or cdn.example.com
                                </FormHelperText>
                            )}
                        </FormControl>
                    </ChakraProvider>

                    {/* <DialogContentText
                        sx={{ paddingBottom: 2, color: 'white' }}>
                        Please Enter an IP or FQDN
                    </DialogContentText> */}
                    {/* <TextField
                        autoFocus
                        sx={{ width: '100%', marginBottom: 2, color: 'white' }}
                        label={isSRV ? 'Domain' : 'Host IP/FQDN'}
                        placeholder={'10.1.1.1 or cdn.example.com'}
                        onChange={(e) => {
                            setErrors({ record: '' });
                            setRecord(e.target.value);

                            const isDomain = validator.isFQDN(e.target.value);
                            const isIp = validator.isIP(e.target.value);

                            if (!isDomain && !isIp) {
                                setErrors({
                                    record: 'Only IP addresses or FQDN are permitted',
                                });
                            } else {
                                setErrors(null);
                            }
                        }}
                        error={errors?.record}
                        helperText={errors?.record}
                        data-walkthroughid={
                            walkthroughIds.endpoints.modal.ufHostInput
                        }
                    /> */}
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button
                        sx={{ backgroundcolor: '#0d6efd', color: '#ffffff' }}
                        variant="contained"
                        disabled={errors || !record}
                        style={{
                            color: darkMode ? 'white' : null,
                            backgroundColor: darkMode ? '#1976d2' : null,
                            opacity:
                                darkMode && (errors || !record) ? '0.4' : null,
                        }}
                        onClick={() => {
                            onSubmitHandler();
                        }}
                        data-walkthroughid={
                            walkthroughIds.endpoints.modal.ufAddButton
                        }>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default EditSipRegistrationAcl;
