import { Box, ChakraProvider, Button, forwardRef } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';

import { handleGoBack } from '@/lib/data-router/utils/helpers';

import Heading from '@/components/v4/Heading';
import Text from '@/components/v4/Text';

import chakratheme from '@/chakratheme';

const ErrorContent = ({ title, message }) => {
    return (
        <>
            <Heading as="h1" sx={{ textAlign: 'center' }}>
                {title}
            </Heading>
            <Text sx={{ textAlign: 'center', fontSize: 16, mt: '20px' }}>
                {message}
            </Text>
        </>
    );
};

export default function ErrorStatusPage({ status }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const parentPath = pathname.split('/')[1];

    const onClick = () => {
        handleGoBack(navigate, pathname, parentPath);
    };

    const content = (() => {
        switch (status) {
            case 400:
                return (
                    <ErrorContent
                        title="Bad Request!"
                        message="We couldn't find the information you're looking for."
                    />
                );
            case 401:
                return (
                    <ErrorContent
                        title="Unauthorized!"
                        message="You are not authorized to access this page."
                    />
                );
            case 403:
                return (
                    <ErrorContent
                        title="Forbidden!"
                        message="You are not authenticated to access this page."
                    />
                );
            default:
                return (
                    <ErrorContent
                        title="404"
                        message="We couldn't find the page you're looking for."
                    />
                );
        }
    })();

    return (
        <ChakraProvider theme={chakratheme} resetCSS={true}>
            <Box sx={{ margin: '250px auto' }}>
                {content}
                <Box className="mt-10 flex items-center justify-center gap-x-6">
                    <Button colorScheme="messenger" onClick={onClick}>
                        Go back
                    </Button>
                </Box>
            </Box>
        </ChakraProvider>
    );
}
