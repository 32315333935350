import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Spinner} from '@chakra-ui/react';
import {CheckIcon} from '@chakra-ui/icons';
import {Box, SlideFade} from '@chakra-ui/react';
import Progress from '../../components/v4/Progress';
import Text from '../../components/v4/Text';
import Alert from '../../components/v4/Alert';
import AlertDescription from '../../components/v4/AlertDescription';
import Image from '../../components/v4/Image';
import AlertIcon from '../../components/v4/AlertIcon';
import Link from '../../components/v4/Link';
import config from '../../config.json';
import ImageLoader from '../../components/v4/ImageLoader';

import {albyDevApiKey} from './consts';

import {
    containerStyles,
    logoStyles,
    stepContainerStyles,
    progressbarStyles,
} from './partnerTrialStyles';
import {ExternalLinkIcon} from '@chakra-ui/icons';

const dev = false;
const devTime = 1000;

export default function PartnerTrialCreate({
    registrationId,
    lead,
    region,
    cname,
    logoWhiteFile,
    logoDarkFile,
    isBranded,
    logoFileType,
}) {
    const imagePathLightMode = useSelector(
        (state) => state.login.imagePathLightMode,
    );

    const [step1, setStep1] = useState('Loading');
    const [step2, setStep2] = useState('Loading');
    const [step3, setStep3] = useState('Loading');
    const [step4, setStep4] = useState('Loading');
    const [step5, setStep5] = useState('Loading');
    const [step6, setStep6] = useState('Loading');

    const [showStep1, setShowStep1] = useState(true);
    const [showStep2, setShowStep2] = useState(false);
    const [showStep3, setShowStep3] = useState(false);
    const [showStep4, setShowStep4] = useState(false);
    const [showStep5, setShowStep5] = useState(false);
    const [showStep6, setShowStep6] = useState(false);

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setTimeout(
            () => {
                setStep1('Finished');
                setProgress(20);
                setShowStep2(true);
            },
            dev ? devTime : 2500,
        );

        setTimeout(
            () => {
                setStep2('Finished');
                setProgress(40);
                setShowStep3(true);
            },
            dev ? devTime : 5000,
        );
        setTimeout(
            () => {
                setStep3('Finished');
                setProgress(50);
                setShowStep4(true);
            },
            dev ? devTime : 7500,
        );
        setTimeout(
            () => {
                setStep4('Finished');
                setProgress(75);
                setShowStep5(true);
            },
            dev ? devTime : 10000,
        );
        setTimeout(
            () => {
                setStep5('Finished');
                setProgress(85);
                setShowStep6(true);
            },
            dev ? devTime : 12500,
        );

        setTimeout(
            () => {
                setStep6('Finished');
                setProgress(100);
            },
            dev ? 100 : 15000,
        );
    }, []);

    return (
        <SlideFade
            sx={containerStyles}
            style={{
                transitionDuration: '100ms',
                width: '100%',
                margin: '0 auto',
                maxWidth: '600px',
            }}
            in={true}
            offsetY="200px"
            animateOpacity>
            <Box sx={containerStyles}>
                <ImageLoader
                    image={config.imageBase + imagePathLightMode}
                    objectProps={{
                        alt: 'Domain Logo',
                        style: {
                            display: 'block',
                            maxWidth: '300px',
                            maxHeight: '150px',
                            margin: '0 auto 25px auto',
                        },
                        type: 'image/svg+xml',
                    }}
                    imageProps={{
                        sx: {
                            ...logoStyles,
                        },
                    }}
                />
         
                {showStep1 && (
                    <SlideFade
                        style={stepContainerStyles}
                        in={showStep1}
                        offsetY="20px"
                        animateOpacity>
                        {step1 === 'Loading' && (
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Spinner sx={{marginRight: '6px'}} size="sm" />
                                <Text margin={0}>Filling in some forms</Text>
                            </Box>
                        )}
                        {step1 === 'Finished' && (
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <CheckIcon
                                    sx={{
                                        marginRight: '6px',
                                        color: 'green.400',
                                    }}
                                />
                                <Text margin={0}> Filling in some forms</Text>
                            </Box>
                        )}
                    </SlideFade>
                )}
                {showStep2 && (
                    <SlideFade
                        style={stepContainerStyles}
                        in={showStep2}
                        offsetY="20px"
                        animateOpacity>
                        {step2 === 'Loading' && (
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Spinner sx={{marginRight: '6px'}} size="sm" />
                                <Text margin={0}>Creating partner account</Text>
                            </Box>
                        )}
                        {step2 === 'Finished' && (
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <CheckIcon
                                    sx={{
                                        marginRight: '6px',
                                        color: 'green.400',
                                    }}
                                />
                                <Text margin={0}>Creating partner account</Text>
                            </Box>
                        )}
                    </SlideFade>
                )}
                {showStep3 && (
                    <SlideFade
                        style={stepContainerStyles}
                        in={showStep3}
                        offsetY="20px"
                        animateOpacity>
                        {step3 === 'Loading' && (
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Spinner sx={{marginRight: '6px'}} size="sm" />
                                <Text margin={0}>Setting up plans</Text>
                            </Box>
                        )}
                        {step3 === 'Finished' && (
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <CheckIcon
                                    sx={{
                                        marginRight: '6px',
                                        color: 'green.400',
                                    }}
                                />
                                <Text margin={0}>Setting up plans</Text>
                            </Box>
                        )}
                    </SlideFade>
                )}
                {showStep4 && (
                    <SlideFade
                        style={stepContainerStyles}
                        in={showStep4}
                        offsetY="20px"
                        animateOpacity>
                        {step4 === 'Loading' && (
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Spinner sx={{marginRight: '6px'}} size="sm" />
                                <Text margin={0}>
                                    Allocating telephone numbers
                                </Text>
                            </Box>
                        )}
                        {step4 === 'Finished' && (
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <CheckIcon
                                    sx={{
                                        marginRight: '6px',
                                        color: 'green.400',
                                    }}
                                />
                                <Text margin={0}>
                                    Allocating telephone numbers
                                </Text>
                            </Box>
                        )}
                    </SlideFade>
                )}
                {showStep5 && (
                    <SlideFade
                        style={stepContainerStyles}
                        in={showStep5}
                        offsetY="20px"
                        animateOpacity>
                        {step5 === 'Loading' && (
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Spinner sx={{marginRight: '6px'}} size="sm" />
                                <Text margin={0}>Setting up branding</Text>
                            </Box>
                        )}
                        {step5 === 'Finished' && (
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <CheckIcon
                                    sx={{
                                        marginRight: '6px',
                                        color: 'green.400',
                                    }}
                                />
                                <Text margin={0}>Setting up branding</Text>
                            </Box>
                        )}
                    </SlideFade>
                )}
                {showStep6 && (
                    <SlideFade
                        style={stepContainerStyles}
                        in={showStep6}
                        offsetY="20px"
                        animateOpacity>
                        {step6 === 'Loading' && (
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Spinner sx={{marginRight: '6px'}} size="sm" />
                                <Text margin={0}>Deploying portal</Text>
                            </Box>
                        )}
                        {step6 === 'Finished' && (
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <CheckIcon
                                    sx={{
                                        marginRight: '6px',
                                        color: 'green.400',
                                    }}
                                />
                                <Text margin={0}>Deploying portal</Text>
                            </Box>
                        )}
                    </SlideFade>
                )}
                <Box sx={{width: '100%'}}>
                    <Progress
                        //hasStripe
                        hasStripe={progress !== 100}
                        isAnimated={progress !== 100}
                        value={progress}
                        size="md"
                        colorScheme="green"
                        sx={progressbarStyles}
                    />

                    {step6 === 'Finished' && (
                        <SlideFade
                            sx={containerStyles}
                            style={{
                                transitionDuration: '100ms',
                            }}
                            in={step6 === 'Finished'}
                            offsetY="200px"
                            animateOpacity>
                            <Alert status="success" sx={{borderRadius: '10px'}}>
                                <AlertIcon />
                                <Box sx={{display: 'flex', flexDir: 'column'}}>
                                    <AlertDescription>
                                        All done! You should have received an
                                        email to activate your portal login.{' '}
                                        {cname &&
                                            isBranded &&
                                            'Click the link below to go to your new portal!'}
                                    </AlertDescription>
                                    {cname && isBranded && (
                                        <AlertDescription>
                                            <Link
                                                isExternal
                                                href={`http://${cname}`}
                                                target="_blank"
                                                color="brand.500"
                                                _hover={{color: 'brand.500'}}>
                                                {cname} <ExternalLinkIcon />
                                            </Link>
                                        </AlertDescription>
                                    )}
                                </Box>
                            </Alert>
                        </SlideFade>
                    )}
                </Box>
            </Box>
        </SlideFade>
    );
}
