//external imports
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

/*
 * View of what is inside a tab panel for TabView.jsx
 */
const TabPanel = (props) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography component="span" key={index}>
                        {children}
                    </Typography>
                </Box>
            )}
        </div>
    );
};

export default TabPanel;
