import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function UnprotectedNoMatch() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/');
    }, []);

    return null;
}
