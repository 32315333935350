import { Box } from '@chakra-ui/react';
import Image from '../../Image';
import styles from './styles';

export default function ServiceProfileCardImage({src, ...props}) {
    return (
        <Box sx={styles.imageContainer}>
            <Image src={src} sx={styles.image} {...props} />
        </Box>
    );
}
