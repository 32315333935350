import config from '@/config.json';
import { customTabStyles, selectedTabStyles } from '@/constants';
import { axios } from '@/services/axios';
import {
    Box,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { Button, Heading } from 'components/v4';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import walkthroughIds from '../../walkthroughIds';
import EditAdmin from './EditAdmin';
import EditGeneral from './EditGeneral';

export default function ApiUserEdit() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const { apiUserId } = useParams();

    const date = new Date();

    const { currentCompany } = useSelector((state) => state.navigationLists);

    const initialState = {
        id: '',
        name: '',
        isActive: false,
        expirationDate: new Date(moment().add(1, 'day')),
        expirationTime: '',
        permissionSetID: '',
        companyID: currentCompany,
    };
    const [apiUser, setApiUser] = useState(initialState);
    const [errors, setErrors] = useState({
        name: { isError: false, message: '' },
        permission: { isError: false, message: '' },
        expirationTime: { isError: false, message: '' },
    });
    const [isLoading, setIsLoading] = useState(false);

    // get api data
    const { data: apiUserData, isFetching: apiUserLoading } = useQuery({
        queryKey: ['apiUser', { apiUserId }],
        queryFn: async () => {
            const res = await axios.get(
                `${config.apiUsers.modalFetch}${apiUserId}`,
            );
            return res.data;
        },
        enabled: Boolean(apiUserId),
        onSuccess: (data) => {
            const localDateTime = moment.utc(data.expiration).local();
            setApiUser({
                ...data,
                expirationDate: new Date(localDateTime),
                expirationTime: moment(localDateTime).format('HH:mm'),
            });
        },
        onError: (err) => {
            toast.error(err?.response?.data?.message);
        },
        refetchOnWindowFocus: false,
    });

    // NOTE: in case users use history link to access this page
    const apiUserName = `${state?.name || apiUserData?.name}`;

    const editAPIUser = () => {
        setIsLoading(true);
        let hasError = false;

        if (!apiUser.name || apiUser.name.length < 1) {
            setErrors({
                ...errors,
                name: {
                    isError: true,
                    message: 'Field is required.',
                },
            });
            hasError = true;
        }
        if (!apiUser.permissionSetID) {
            setErrors((prevState) => ({
                ...prevState,
                permission: {
                    isError: true,
                    message: 'Field is required.',
                },
            }));
            hasError = true;
        }
        if (
            moment(apiUser.expirationDate).format('YYYY-MM-DD') <=
                moment(date).format('YYYY-MM-DD') &&
            apiUser?.expirationTime &&
            apiUser?.expirationTime < moment(date).format('HH:mm')
        ) {
            setErrors((prevState) => ({
                ...prevState,
                expirationTime: {
                    isError: true,
                    message: 'Time cannot be less than now.',
                },
            }));
            hasError = true;
        }

        if (hasError) {
            setIsLoading(false);
            return;
        }
        setErrors({
            name: { isError: false, message: '' },
            permission: { isError: false, message: '' },
            expirationTime: { isError: false, message: '' },
        });

        let selectedExpiration;
        if (apiUser?.expirationTime) {
            const selectedDate = moment(apiUser.expirationDate);
            selectedDate.set({
                hour: apiUser?.expirationTime.split(':')[0],
                minute: apiUser?.expirationTime.split(':')[1],
                second: 0,
            });
            selectedExpiration = moment(selectedDate);
        } else {
            const resetTime = moment(apiUser.expirationDate).set({
                hour: 0,
                minute: 0,
                second: 0,
            });
            selectedExpiration = moment(resetTime);
        }
        axios
            .put(config.apiUsers.modalUpdate, {
                ...apiUser,
                expiration: selectedExpiration,
                companyID: currentCompany,
            })
            .then((res) => {
                toast.success('API User updated successfully!');
                dispatch({ type: 'RESET_TABLE' });
                navigate('/admins', { state: { index: 2 } });
            })
            .catch((e) => {
                toast.error(e?.response?.data?.message);
            })
            .finally(() => setIsLoading(false));
    };

    const handleBack = () => {
        navigate('/admins', { state: { index: 2 } });
    };

    if (apiUserData?.companyID !== currentCompany) {
        handleBack();
    }

    return (
        <>
            <Heading fontSize="3xl" as="h2" mb={2} pl={4}>
                <span className="text-2xl font-semibold">
                    Edit API User: {apiUserName}
                </span>
            </Heading>
            <Box
                sx={{
                    padding: '10px 0',
                    maxWidth: '2xl',
                    minWidth: 'xl',
                    margin: '0 auto',
                }}
                className="admin-general-temp">
                <Box
                    px={{ base: '4', md: '6' }}
                    pt={{ base: '5', md: '6' }}
                    pb={0}>
                    <Tabs>
                        <TabList justifyContent="center" borderBottom={0}>
                            {['General', 'Admin'].map((t, index) => (
                                <Tab
                                    data-walkthroughid={
                                        walkthroughIds.ufadminApiUserTabButton +
                                        t
                                    }
                                    sx={customTabStyles}
                                    key={t}
                                    _selected={selectedTabStyles}>
                                    {t}
                                </Tab>
                            ))}
                        </TabList>
                        <TabPanels>
                            <TabPanel px={0}>
                                <EditGeneral
                                    apiUser={apiUser}
                                    setApiUser={setApiUser}
                                    isLoading={isLoading}
                                    errors={errors}
                                    setErrors={setErrors}
                                    apiUserLoading={apiUserLoading}
                                />
                            </TabPanel>
                            <TabPanel px={0}>
                                <EditAdmin
                                    apiUserLoading={apiUserLoading}
                                    apiUser={apiUser}
                                />
                            </TabPanel>
                        </TabPanels>

                        <Stack
                            spacing="6"
                            px={{ base: '4', md: '6' }}
                            pt={{ base: '5', md: '6' }}
                            pb="2px"
                            sx={{
                                justifyContent: 'center',
                                width: '170px',
                                margin: '0 auto',
                            }}
                            direction={{ base: 'column', md: 'row' }}>
                            <Box>
                                <Button
                                    variant="outline"
                                    isDisabled={isLoading}
                                    className="btn btn-secondary btn-sm min-w-[5rem] disabled:cursor-not-allowed"
                                    data-walkthroughid={
                                        walkthroughIds.adminApiUsers.ufBack
                                    }
                                    onClick={() => handleBack()}>
                                    Back
                                </Button>
                            </Box>
                            <Box>
                                <Button
                                    isLoading={isLoading}
                                    onClick={() => editAPIUser()}
                                    className="btn btn-primary btn-sm min-w-[5rem] disabled:cursor-not-allowed"
                                    data-walkthroughid={
                                        walkthroughIds.adminApiUsers.ufSave
                                    }
                                    type="submit">
                                    Save
                                </Button>
                            </Box>
                        </Stack>
                    </Tabs>
                </Box>
            </Box>
        </>
    );
}
