import { forwardRef } from 'react';
import { Box, Paper, Typography as MuiTypography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import MuiDropdown from 'components/v4/mui/Dropdown';
import walkthroughIds from '../walkthroughIds';
import { Select } from 'chakra-react-select';
import { defaultChakraSelectStyle, chakraSelectDarkMode } from '@/constants';
import { ChakraProvider, FormLabel } from '@chakra-ui/react';
import chakratheme from '@/chakratheme';
import { FormControl } from 'components/v4';

export const options = [
    {
        label: 'IP Authentication',
        value: 0,
    },
    {
        label: 'SIP Registration',
        value: 1,
    },
];

export default function EditSipAuth() {
    const { darkMode } = useSelector((state) => state.settings);

    const chakraSelectDark = chakraSelectDarkMode(darkMode);
    const dispatch = useDispatch();
    // state access
    const { state } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    const Typography = forwardRef((props, ref) => (
        <MuiTypography
            ref={ref}
            {...props}
            color={darkMode ? 'rgba(255, 255, 255, 0.7)' : null}
        />
    ));

    // auth change handler
    const authChange = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                authType: e.value,
            },
        });
    };

    return (
        <Box
            sx={{
                boxShadow: 1,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: '#DDD',
                display: 'flex',
                flexDirection: 'column',
                background: darkMode ? '#212121' : '#FFF',
                height: '100%',
            }}>
            {/* Header */}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '64px',
                    paddingLeft: '24px',
                    paddingRight: '8px',
                }}>
                <Typography variant="h6">Authentication</Typography>
            </div>
            <Typography sx={{ paddingLeft: '24px', paddingRight: '8px' }}>
                Select the trunk authentication method below.
            </Typography>
            {/* Content */}
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: '24px',
                    paddingRight: '8px',
                    marginBottom: 40,
                }}>
                <ChakraProvider theme={chakratheme} resetCSS={true}>
                    <FormControl>
                        <FormLabel
                            color={
                                darkMode
                                    ? 'rgba(255, 255, 255, 0.7) !important'
                                    : null
                            }>
                            Auth Type
                        </FormLabel>
                        <Box
                            sx={{
                                // marginBottom: '10px',
                                display: 'flex',
                                width: 'full',
                                alignItems: 'flex-start',
                            }}
                            data-walkthroughid={
                                walkthroughIds.endpoints.ufAuthSelect
                            }>
                            <Select
                                className="w-full"
                                value={options.find(
                                    (a) => a.value == state?.authType,
                                )}
                                options={options}
                                name="authType"
                                onChange={(e) => authChange(e)}
                                getOptionLabel={(i) => i.label}
                                getOptionValue={(i) => i.value}
                                chakraStyles={{
                                    ...defaultChakraSelectStyle,
                                    ...chakraSelectDark,
                                }}
                            />
                        </Box>
                    </FormControl>
                </ChakraProvider>
                {/* THIS IS THE OLD UI USING MUI SELECT, NEED TO REMOVE SOON, INCLUDING THE COMPONENT */}
                {/* <MuiDropdown
                    dataWalkthroughid={walkthroughIds.endpoints.ufAuthSelect}
                    label="Auth"
                    value={state?.authType}
                    onChange={authChange}
                    options={[
                        {
                            label: 'IP Authentication',
                            value: 0,
                        },
                        {
                            label: 'SIP Registration',
                            value: 1,
                        },
                    ]}></MuiDropdown> */}
            </div>
        </Box>
    );
}
