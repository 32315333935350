export const INVALID_USERNAME_PASSWORD =
    'Login unsuccessful due to invalid username or password';
export const EXPIRED_PASSWORD = 'Login unsuccessful due to expired password';
export const DISABLED_DELETE_USER =
    'Login unsuccessful due to disabled or deleted user';
export const CONDITIONAL_ACCESS_REQUIREMENT =
    'Login unsuccessful due to conditional access requirement';
export const MFA_REQUIREMENT = 'Login unsuccessful due to MFA requirement';

export const SERVICE_STATUS_HAS_PDF = {
    [INVALID_USERNAME_PASSWORD]: {
        actionType: 'link',
        payload: null,
    },
    [EXPIRED_PASSWORD]: {
        actionType: 'link',
        payload: null,
    },
    [DISABLED_DELETE_USER]: {
        actionType: 'link',
        payload: null,
    },
    [CONDITIONAL_ACCESS_REQUIREMENT]: {
        actionType: 'link',
        payload: '/Guides/MFA_for_TCAP.pdf',
    },
    [MFA_REQUIREMENT]: {
        actionType: 'link',
        payload: '/Guides/MFA_for_TCAP.pdf',
    },
};
