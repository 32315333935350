import {Box, Stack} from '@chakra-ui/react';
import Skeleton from '../../Skeleton';
import Grid from '../../Grid';
import styles from './styles';
import ServiceProfileCard from '../ServiceProfileCard/ServiceProfileCard';
import {SkeletonCircle} from '@chakra-ui/react';
export default function ServiceProfileCardPlaceholder({
    skeletons,
    rows,
    columns,
    items,
    ...props
}) {
    return (
        <Grid rows={rows} columns={columns} gap={4} {...props}>
            {Array.from(Array(items)).map((v) => (
                <ServiceProfileCard>
                    <Stack>
                        <SkeletonCircle size="10" sx={styles.skeletonCircle} />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </Stack>
                </ServiceProfileCard>
            ))}
        </Grid>
    );
}
