import {Box} from '@chakra-ui/react';

const containerStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
};

const baseStyles = {
    width: '20px',
    height: '20px',
    display: 'block',
    borderRadius: '100%',
    background: 'primary.200',
    marginRight: 5,
};
const activeStyles = {
    background: 'primary.500',
};

export default function MfaWizardStepProgress({step}) {
    return (
        <Box sx={{...containerStyles}}>
            {[...Array(3)].map((doesnothing, index) =>
                step - 1 === index ? (
                    <Box sx={{...baseStyles, ...activeStyles}}></Box>
                ) : (
                    <Box sx={baseStyles}></Box>
                ),
            )}
        </Box>
    );
}
