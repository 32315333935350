import React from 'react';
import { Box } from '@chakra-ui/react';
import style from './styles';
import { classNames } from '@/utils/classNames';
import { useSelector } from 'react-redux';

export default function DashboardServiceCard({
    children,
    sx,
    dataWalkthroughId,
    ...props
}) {
    const { darkMode } = useSelector((state) => state.settings);

    return (
        <Box
            sx={{ ...style.root, ...sx }}
            {...props}
            className={classNames(
                darkMode ? 'bg-[var(--chakra-colors-dark-tcap)]' : 'bg-white',
                'rounded-l rounded-r !shadow-lg',
            )}
            data-walkthroughid={`/dashboard-services/card/${dataWalkthroughId}`}
        >
            {children}
        </Box>
    );
}
