import {useEffect, useState} from 'react';
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    SimpleGrid,
    Text,
} from '@chakra-ui/react';
import {CheckCircleIcon} from '@chakra-ui/icons';

import Button from 'components/v4/Button';
import {useTpmContext} from './TpmProvider';

function PortingNumber() {
    const [state, setState] = useState({
        carrier: '',
        company: '',
        name: '',
        email: '',
        contactNumber: '',
    });

    const {
        tpmAddress: [, setAddressAction],
    } = useTpmContext();

    const [customFields, setCustomFields] = useState({
        customField1: '',
        customField2: '',
        customField3: '',
    });
    const [status, setStatus] = useState('IDLE');
    const [errors, setErrors] = useState({});
    const onSubmit = (e) => {
        e.preventDefault();
        setStatus('LOADING');

        for (const field in state) {
            if (state[field] === '') {
                setErrors((errors) => ({
                    ...errors,
                    [field]: 'Field is required',
                }));
                setStatus('ERROR');
            }
        }
    };

    const handleCancelButton = () => {
        setAddressAction('GET');
    };

    const handleChangeInput = (e) => {
        const {name, value} = e.target;

        setState((state) => ({
            ...state,
            [name]: value,
        }));
        setErrors((errors) => ({
            ...errors,
            [name]: '',
        }));
    };

    useEffect(() => {
        setTimeout(() => {
            if (status !== 'ERROR' && Object.values(errors).includes('')) {
                setStatus((prevState) => 'SUCCESS');
            }
        }, 1000);
    }, [status]);

    return (
        <Box as="form" className="max-w-5xl mx-auto" onSubmit={onSubmit}>
            <Text as="h6" className="mb-12 text-xl font-semibold">
                Porting Number to Carrier
            </Text>
            {status === 'SUCCESS' ? (
                <>
                    <Box w={600}>
                        <Box className="flex flex-col justify-center">
                            <Box className="text-center">
                                <CheckCircleIcon
                                    color="#38A169"
                                    className="text-center mb-6"
                                    w={16}
                                    h={16}
                                />
                                <Text className="text-base text-left">
                                    Your porting request has been sent to your
                                    carrier. Please wait up to X business days.
                                </Text>
                            </Box>
                        </Box>
                    </Box>

                    <Box className="text-center mt-12">
                        <Button
                            type="submit"
                            onClick={() =>
                                setAddressAction('AUTHORIZATION_PROCESS')
                            }>
                            Done
                        </Button>
                    </Box>
                </>
            ) : (
                <>
                    <SimpleGrid
                        columns={[2, null, 3]}
                        spacingX={6}
                        spacingY={6}>
                        {/* Carrier */}
                        <FormControl isInvalid={errors['carrier']}>
                            <FormLabel>Carrier</FormLabel>
                            <Input
                                type="text"
                                name="carrier"
                                value={state.carrier}
                                placeholder="carrier"
                                onChange={handleChangeInput}
                            />
                            {errors['carrier'] && (
                                <FormErrorMessage>
                                    {errors['carrier']}
                                </FormErrorMessage>
                            )}
                        </FormControl>

                        {/* Company Register Number */}
                        <FormControl isInvalid={errors['company']}>
                            <FormLabel>Company Register Number</FormLabel>
                            <Input
                                type="text"
                                name="company"
                                value={state.company}
                                placeholder="company register number"
                                onChange={handleChangeInput}
                            />
                            {errors['company'] && (
                                <FormErrorMessage>
                                    {errors['company']}
                                </FormErrorMessage>
                            )}
                        </FormControl>

                        {/* Empty Grid Filler  */}
                        <Box />

                        {/* Authorised Contact Full Name */}
                        <FormControl isInvalid={errors['name']}>
                            <FormLabel>Authorized Contact Full Name</FormLabel>
                            <Input
                                type="text"
                                name="name"
                                value={state.name}
                                placeholder="authorised contact full name"
                                onChange={handleChangeInput}
                            />
                            {errors['name'] && (
                                <FormErrorMessage>
                                    {errors['name']}
                                </FormErrorMessage>
                            )}
                        </FormControl>

                        {/* Authorised Contact Email*/}
                        <FormControl isInvalid={errors['email']}>
                            <FormLabel>Authorized Contact Email</FormLabel>
                            <Input
                                type="email"
                                name="email"
                                value={state.email}
                                placeholder="authorised contact email"
                                onChange={handleChangeInput}
                            />
                            {errors['email'] && (
                                <FormErrorMessage>
                                    {errors['email']}
                                </FormErrorMessage>
                            )}
                        </FormControl>

                        {/* Authorised Contact Number */}
                        <FormControl isInvalid={errors['contactNumber']}>
                            <FormLabel>
                                Authorized Contact Number
                            </FormLabel>
                            <Input
                                type="text"
                                name="contactNumber"
                                value={state.contactNumber}
                                placeholder="authorised contact number"
                                onChange={handleChangeInput}
                            />
                            {errors['contactNumber'] && (
                                <FormErrorMessage>
                                    {errors['contactNumber']}
                                </FormErrorMessage>
                            )}
                        </FormControl>

                        {['customField1', 'customField2', 'customField3'].map(
                            (v, idx) => (
                                <FormControl key={`Custom Field ${idx + 1}`}>
                                    <FormLabel>{`Custom Field ${
                                        idx + 1
                                    }`}</FormLabel>
                                    <Input
                                        type="text"
                                        value={customFields[v]}
                                        placeholder={`custom field ${idx + 1}`}
                                        onChange={(e) => {
                                            setCustomFields({
                                                [v]: e.target.value,
                                            });
                                        }}
                                    />
                                </FormControl>
                            ),
                        )}
                    </SimpleGrid>
                    <Box className="flex mt-12 justify-between">
                        <Button variant="outline" onClick={handleCancelButton}>
                            Cancel
                        </Button>
                        <Button type="submit" isDisabled={status === 'LOADING'}>
                            {status === 'LOADING' ? 'Loading...' : 'Next'}
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
}

export default PortingNumber;
