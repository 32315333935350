import { Box as ChakraBox, forwardRef } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const BoxWithBorder = forwardRef((props, ref) => {
    const { darkMode } = useSelector((state) => state.settings);
    return (
        <ChakraBox
            ref={ref}
            sx={{
                my: 1,
                borderTop: `1px solid ${darkMode ? 'rgba(81, 81, 81, 1)' : 'rgba(221,221,221,1)'}`,
            }}
            aria-label="Box with border"
            {...props}
        />
    );
});

export { BoxWithBorder };
