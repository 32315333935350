//Settings for a user
//each action changes one setting
//TODO: refactor
import config from '../../config.json';

const initSettingsState = {
    lastUpdate: Date.now(), //for comparing lastUpdated time against backend. NOTE! THIS THING IS BUGGY, it always resourceaccounts
    help: true, // not used. if in future you want to show step by step guide and opt out, this is the variable.
    timeZone: {
        //MDN's intl.datetimeformat spec.
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hourCycle: 'h12',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: 'UTC',
        timeZoneName: 'short',
        second: 'numeric',
        language: 'en-AU',
        hour12: undefined,
    },
    mixedMode: true, //for themes
    darkMode: false, //for themes
    colorScheme: 'primary', // for themes
    allowUnlimited: false, //for tables that need buffering.
    defaultTablePage: config.datatable.defaultTablePage, //not used
    displayCustomFields: {
        //whole json not used yet. this is for displaying custom stuff in table view for some menus.
        wholesalers: true,
        partners: true,
        companies: true,
        users: true,
        invoices: true,
        numbers: true,
        numberBlocks: true,
    },
};

export const settingsReducer = (state = initSettingsState, action) => {
    switch (action.type) {
        case 'CHANGE_ALLOW_UNLIMITED': //this is for tables that need buffer (CDR and invoicelines), if they are willing to view >11000 rows.
            return {
                ...state,
                allowUnlimited: action.payload,
                lastUpdate: Date.now(),
            };
        case 'CHANGE_MODE': //newer ways
            return {
                ...state,
                darkMode: action.payload.darkMode,
                mixedMode: action.payload.mixedMode,
                lastUpdate: Date.now(),
            };
        case 'CHANGE_COLOR_SCHEME':
            return {
                ...state,
                colorScheme: action.payload,
                lastUpdate: Date.now(),
            };
        case 'CHANGE_TIMEZONE':
            return {
                ...state,
                timeZone: {
                    weekday: 'short',
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hourCycle: 'h12',
                    timeZone: 'UTC',
                    second: 'numeric',
                    timeZoneName: 'short',
                    language: 'en-AU',
                    ...state.timeZone,
                    ...action.payload,
                    hour12: undefined,
                },
                lastUpdate: Date.now(),
            };
        case 'INIT_TIMEZONE':
            return {
                ...state,
                timeZone: {
                    weekday: 'short',
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hourCycle: 'h12',
                    timeZone: 'UTC',
                    second: 'numeric',
                    timeZoneName: 'short',
                    language: 'en-AU',
                    ...state.timeZone,
                    hour12: undefined,
                },
            };
        case 'CLEAR_TOKEN':
            return {
                lastUpdate: Date.now(), //for comparing lastUpdated time against backend. NOTE! THIS THING IS BUGGY, it always resourceaccounts
                help: true, // not used. if in future you want to show step by step guide and opt out, this is the variable.
                timeZone: {
                    //MDN's intl.datetimeformat spec.
                    weekday: 'short',
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hourCycle: 'h12',
                    hour: 'numeric',
                    minute: 'numeric',
                    timeZone: 'UTC',
                    timeZoneName: 'short',
                    second: 'numeric',
                    language: 'en-AU',
                    hour12: undefined,
                },
                mixedMode: true, //for themes
                darkMode: false, //for themes
                colorScheme: 'primary', // for themes
                allowUnlimited: false, //for tables that need buffering.
                defaultTablePage: config.datatable.defaultTablePage, //not used
                displayCustomFields: {
                    //whole json not used yet. this is for displaying custom stuff in table view for some menus.
                    wholesalers: true,
                    partners: true,
                    companies: true,
                    users: true,
                    invoices: true,
                    numbers: true,
                    numberBlocks: true,
                },
            };
        case 'CHANGE_ALL_SETTINGS': //for backend sync.
            return {
                ...state,
                ...action.payload,
            };

        case 'LOAD_SETTINGS_FROM_LOCAL_STORAGE':
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
