import { chakraSelectDarkMode, defaultChakraSelectStyle } from '@/constants';
import { InfoIcon } from '@chakra-ui/icons';
import {
    Box,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Tooltip,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useSelector } from 'react-redux';
import style from '../../edit/styles';
import { useDefinedAttributes } from '../DefinedAttributesContext';
import useDefinedAttributeValidation from './useDefinedAttributeValidation';

export default function BoolAttribute({ attribute, index, requiredScope }) {
    const options = [
        {
            value: '0',
            label: 'False',
        },
        {
            value: '1',
            label: 'True',
        },
    ];
    const validation = JSON.parse(attribute.definedAttributeValidationType);
    const { removeAttributeError, handleAttributeChange } =
        useDefinedAttributeValidation();
    const { attributesErrors } = useDefinedAttributes();
    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);

    const error = attributesErrors.find((obj) => obj.id === index);
    return (
        <Box sx={style.fieldContainer}>
            <FormControl isRequired={validation.Required} isInvalid={error}>
                <FormLabel sx={{ ...style.label, mb: 0, ml: 2 }}>
                    {attribute.name}{' '}
                    {attribute.toolTip && (
                        <Tooltip
                            label={attribute.toolTip}
                            hasArrow
                            placement="top-end">
                            <InfoIcon />
                        </Tooltip>
                    )}
                </FormLabel>
                <Select
                    value={options?.find(
                        (item) => item.value === attribute.value,
                    )}
                    options={options}
                    onChange={(e) => {
                        removeAttributeError(index);
                        handleAttributeChange(e.value, index);
                    }}
                    placeholder="Please select an option"
                    getOptionLabel={(i) => i.label}
                    chakraStyles={{
                        ...defaultChakraSelectStyle,
                        ...chakraSelectDark,
                    }}
                    isDisabled={requiredScope < attribute.updateScope}
                    selectedOptionColor="brand"
                />

                {error ? (
                    <FormErrorMessage>{error.message}</FormErrorMessage>
                ) : attribute?.description ? (
                    <FormHelperText>{attribute?.description}</FormHelperText>
                ) : null}
            </FormControl>
        </Box>
    );
}
