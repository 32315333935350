// only needs to be imported once
import { SearchIcon } from '@chakra-ui/icons';
import {
      Box,
      Divider,
      Input,
      InputGroup,
      InputLeftElement,
      Skeleton,
      Tooltip,
} from '@chakra-ui/react';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import { useEffect } from 'react';
import {
      AutoSizer,
      CellMeasurer,
      CellMeasurerCache,
      Column,
      Table,
} from 'react-virtualized';

import config from '@/config.json';
import { Alert, AlertIcon, Checkbox, Heading, Text } from 'components/v4';
import useVirtualizedTableFilter from 'hooks/useVirtualizedTableFilter';
import { useSelector } from 'react-redux';

const cache = new CellMeasurerCache({
    defaultHeight: 100,
    minHeight: 100,
});

export const SearchBar = ({
    onSearch,
    query,
    isDisabled = false,
    placeholder = '',
    captionName = '',
}) => {
    const { darkMode } = useSelector((state) => {
        return { ...state.settings };
    });
    return (
        <InputGroup>
            <InputLeftElement children={<SearchIcon />} />{' '}
            <Input
                color={darkMode ? 'dark.textDark' : null}
                background={darkMode ? 'dark.bgDark' : null}
                borderColor={darkMode ? 'dark.tcap-borderGray' : null}
                data-walkthroughid={
                    '/service-profiles/input/search/' + captionName
                }
                type="text"
                placeholder={placeholder}
                isDisabled={isDisabled}
                onChange={(e) => {
                    const value = e.target.value;
                    onSearch(value);
                }}
                value={query}
            />
        </InputGroup>
    );
};

export default function VirtualizedServiceUserTable({
    checkedArray = [],
    onClick = (v) => {},
    data = [],
    captionName,
    isLoading,
    placeholder = '',
    // on click save button, disable changes
    isDisabled,
}) {
    const { darkMode } = useSelector((state) => {
        return { ...state.settings };
    });
    const { handleChangeSearch, setSearchField, filteredData, tableData } =
        useVirtualizedTableFilter({ data });

    useEffect(() => {
        setSearchField('o365UserPrincipalName');
    }, []);

    return (
        <Box
            className="min-w-[500px] max-w-[500px]"
            sx={{
                '.ReactVirtualized__Table__rowColumn': {
                    padding: 'var(--chakra-space-2)',
                    textTransform: 'none',
                },
                '.ReactVirtualized__Table__headerColumn': {
                    padding: 'var(--chakra-space-2)',
                    color: darkMode ? 'white' : 'inherit',
                },
                '.ReactVirtualized__Table__row:nth-of-type(odd)': {
                    background: darkMode
                        ? '#263238'
                        : 'var(--chakra-colors-messenger-100)',
                },
                '.ReactVirtualized__Table__row': {
                    background: darkMode
                        ? 'var(--chakra-colors-dark-tcap)'
                        : 'inherit',
                },
            }}>
            <Heading className="block min-h-[50px] pb-0 pt-2" fontSize={'2xl'}>
                {captionName} ({tableData.length})
            </Heading>
            <Box className="py-2">
                <SearchBar
                    onSearch={handleChangeSearch}
                    isDisabled={data.length === 0 || isDisabled}
                    placeholder={placeholder}
                    captionName={captionName}
                />
            </Box>
            <AutoSizer disableHeight>
                {({ height, width }) => (
                    <Table
                        width={width}
                        height={480}
                        headerHeight={41.5}
                        rowHeight={40}
                        deferredMeasurementCache={cache}
                        onRowClick={(e) => {
                            if (isDisabled) return;
                            onClick(e.rowData);
                        }}
                        noRowsRenderer={() => {
                            return isLoading ? (
                                [...Array(15)].map(() => (
                                    <>
                                        <Skeleton h={9} />
                                        <Divider h={1} />
                                    </>
                                ))
                            ) : (
                                <Alert status="info">
                                    <AlertIcon />
                                    No records to display
                                </Alert>
                            );
                        }}
                        rowCount={isLoading ? 0 : filteredData.length}
                        rowGetter={({ index }) => filteredData[index]}>
                        <Column
                            label="UPN"
                            dataKey="o365UserPrincipalName"
                            cellRenderer={ColumnAutoSize}
                            minWidth={'300px'}
                            flexGrow={2}
                            maxWidth={'300px'}
                        />
                        <Column
                            label="Status"
                            dataKey="status"
                            cellRenderer={StatusColumnAutoSize}
                            minWidth={'100px'}
                            flexGrow={2}
                            maxWidth={'100px'}
                        />
                        <Column
                            className="truncate-none"
                            label="Action"
                            cellRenderer={CheckboxCell}
                            dataKey="disabled"
                            columnData={{
                                onClick,
                                checkedArray,
                            }}
                        />
                    </Table>
                )}
            </AutoSizer>
        </Box>
    );
}

const ColumnAutoSize = ({ dataKey, parent, rowData, rowIndex }) => {
    return (
        <CellMeasurer
            cache={cache}
            columnIndex={0}
            key={dataKey + ' row ' + rowIndex}
            parent={parent}
            rowIndex={rowIndex}>
            <Tooltip label={rowData[dataKey]} hasArrow>
                <span>
                    <Text as={'span'}>{rowData[dataKey]}</Text>
                </span>
            </Tooltip>
        </CellMeasurer>
    );
};

const StatusColumnAutoSize = ({ dataKey, parent, rowData, rowIndex }) => {
    return (
        <CellMeasurer
            cache={cache}
            columnIndex={0}
            key={dataKey + ' row ' + rowIndex}
            parent={parent}
            rowIndex={rowIndex}>
            <Tooltip
                label={
                    rowData?.returnedMessage?.length > 0
                        ? rowData?.returnedMessage
                        : config.users.status[rowData.status]
                }>
                <FiberManualRecordRoundedIcon
                    style={{
                        color: config.users.statusColor[rowData.status],
                        fontSize: 'small',
                    }}
                />
            </Tooltip>
        </CellMeasurer>
    );
};

const CheckboxCell = ({ dataKey, parent, rowData, columnData, rowIndex }) => {
    return (
        <CellMeasurer
            cache={cache}
            columnIndex={0}
            key={dataKey}
            parent={parent}
            rowIndex={rowIndex}>
            <Box cursor={'pointer'}>
                <Checkbox
                    isChecked={columnData.checkedArray.some(
                        (checked) => checked.id === rowData.id,
                    )}
                    pointerEvents="none"
                    sx={{ mt: 1, mb: 0 }}
                />
            </Box>
        </CellMeasurer>
    );
};
