import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Checkbox,
    FormControl,
    FormLabel,
} from '@chakra-ui/react';
import { Text } from 'components/v4';

import { useContext } from 'react';
import FilterSkeleton from './FilterSkeleton';
import { FilterContext } from './FilterContext';

export default function FilterServiceProfiles({ serviceProfiles }) {
    const { filterState, changeCheckbox, clearFilter } =
        useContext(FilterContext);

    if (serviceProfiles === null) {
        return <FilterSkeleton />;
    }

    if (!serviceProfiles?.length) {
        return null;
    }

    return (
        <Accordion
            height={'100%'}
            minW={'230px'}
            overflowY={'auto'}
            defaultIndex={[0, 1, 2]}
            allowMultiple
            allowToggle>
            <AccordionItem
                borderTop={0}
                display={'flex'}
                justifyContent={'space-between'}>
                <Text fontWeight={'bold'} fontSize={'lg'}>
                    Filter
                </Text>
                <Text as={'u'} cursor={'pointer'} onClick={() => clearFilter()}>
                    Clear all
                </Text>
            </AccordionItem>

            <AccordionItem>
                <AccordionButton>
                    <AccordionIcon mr={2} />
                    <Box as="span" flex="1" textAlign="left">
                        UCaaS Provider
                    </Box>
                </AccordionButton>

                <AccordionPanel>
                    <FormControl>
                        <Box className="flex">
                            <Checkbox
                                colorScheme="whatsapp"
                                isChecked={filterState.microsoft}
                                onChange={() => changeCheckbox('microsoft')}
                            />
                            <FormLabel fontWeight={'normal'} ml={3}>
                                Microsoft
                            </FormLabel>
                        </Box>
                    </FormControl>
                    <FormControl>
                        <Box className="flex">
                            <Checkbox
                                colorScheme="whatsapp"
                                isChecked={filterState.cisco}
                                onChange={() => changeCheckbox('cisco')}
                            />
                            <FormLabel fontWeight={'normal'} ml={3}>
                                Cisco
                            </FormLabel>
                        </Box>
                    </FormControl>
                    <FormControl>
                        <Box className="flex">
                            <Checkbox
                                colorScheme="whatsapp"
                                isChecked={filterState.zoom}
                                onChange={() => changeCheckbox('zoom')}
                            />
                            <FormLabel fontWeight={'normal'} ml={3}>
                                Zoom
                            </FormLabel>
                        </Box>
                    </FormControl>
                    <FormControl>
                        <Box className="flex">
                            <Checkbox
                                colorScheme="whatsapp"
                                isChecked={filterState.google}
                                onChange={() => changeCheckbox('google')}
                            />
                            <FormLabel fontWeight={'normal'} ml={3}>
                                Google
                            </FormLabel>
                        </Box>
                    </FormControl>
                    <FormControl>
                        <Box className="flex">
                            <Checkbox
                                colorScheme="whatsapp"
                                isChecked={filterState.other}
                                onChange={() => changeCheckbox('other')}
                            />
                            <FormLabel fontWeight={'normal'} ml={3}>
                                Other
                            </FormLabel>
                        </Box>
                    </FormControl>
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem borderTop={0}>
                <AccordionButton>
                    <AccordionIcon mr={2} />
                    <Box as="span" flex="1" textAlign="left">
                        Services
                    </Box>
                </AccordionButton>

                <AccordionPanel>
                    <FormControl>
                        <Box className="flex">
                            <Checkbox
                                colorScheme="whatsapp"
                                isChecked={filterState.fixed}
                                onChange={() => changeCheckbox('fixed')}
                            />
                            <FormLabel fontWeight={'normal'} ml={3}>
                                Fixed Line Calling
                            </FormLabel>
                        </Box>
                    </FormControl>

                    <FormControl>
                        <Box className="flex">
                            <Checkbox
                                colorScheme="whatsapp"
                                isChecked={filterState.mobile}
                                onChange={() => changeCheckbox('mobile')}
                            />
                            <FormLabel fontWeight={'normal'} ml={3}>
                                Mobile Calling
                            </FormLabel>
                        </Box>
                    </FormControl>

                    <FormControl>
                        <Box className="flex">
                            <Checkbox
                                colorScheme="whatsapp"
                                isChecked={filterState.audio}
                                onChange={() => changeCheckbox('audio')}
                            />
                            <FormLabel fontWeight={'normal'} ml={3}>
                                Audio Conferencing
                            </FormLabel>
                        </Box>
                    </FormControl>

                    <FormControl>
                        <Box className="flex">
                            <Checkbox
                                colorScheme="whatsapp"
                                isChecked={filterState.ccaas}
                                onChange={() => changeCheckbox('ccaas')}
                            />
                            <FormLabel fontWeight={'normal'} ml={3}>
                                CCaaS Enablement
                            </FormLabel>
                        </Box>
                    </FormControl>

                    <FormControl>
                        <Box className="flex">
                            <Checkbox
                                colorScheme="whatsapp"
                                isChecked={filterState.migration}
                                onChange={() => changeCheckbox('migration')}
                            />
                            <FormLabel fontWeight={'normal'} ml={3}>
                                Migration
                            </FormLabel>
                        </Box>
                    </FormControl>
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem borderY={0}>
                <AccordionButton>
                    <AccordionIcon mr={2} />
                    <Box as="span" flex="1" textAlign="left">
                        Add-On Services
                    </Box>
                </AccordionButton>

                <AccordionPanel>
                    <FormControl>
                        <Box className="flex">
                            <Checkbox
                                colorScheme="whatsapp"
                                isChecked={filterState.security}
                                onChange={() => changeCheckbox('security')}
                            />
                            <FormLabel fontWeight={'normal'} ml={3}>
                                Security
                            </FormLabel>
                        </Box>
                    </FormControl>

                    <FormControl>
                        <Box className="flex">
                            <Checkbox
                                colorScheme="whatsapp"
                                isChecked={filterState.compliance}
                                onChange={() => changeCheckbox('compliance')}
                            />
                            <FormLabel fontWeight={'normal'} ml={3}>
                                Compliance
                            </FormLabel>
                        </Box>
                    </FormControl>

                    <FormControl>
                        <Box className="flex">
                            <Checkbox
                                colorScheme="whatsapp"
                                isChecked={filterState.business}
                                onChange={() => changeCheckbox('business')}
                            />
                            <FormLabel fontWeight={'normal'} ml={3}>
                                Business Intelligence
                            </FormLabel>
                        </Box>
                    </FormControl>

                    <FormControl>
                        <Box className="flex">
                            <Checkbox
                                colorScheme="whatsapp"
                                isChecked={filterState.management}
                                onChange={() => changeCheckbox('management')}
                            />
                            <FormLabel fontWeight={'normal'} ml={3}>
                                Change Management
                            </FormLabel>
                        </Box>
                    </FormControl>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
}
