import {Box} from '@chakra-ui/react';

import MfaTeamsPhoneMobileStepOne from './MfaTeamsPhoneMobileStepOne';

function MfaTeamsPhoneMobileWizard() {
    return (
        <Box className="max-w-[700px] mx-auto my-0">
            <MfaTeamsPhoneMobileStepOne />
        </Box>
    );
}

export default MfaTeamsPhoneMobileWizard;
