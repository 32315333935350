import {
    ChakraProvider,
    Box,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
} from '@chakra-ui/react';

import General from './General';
import Admin from './Admin';
import { customTabStyles, selectedTabStyles } from '@/constants';
import chakratheme from '@/chakratheme';

export default function AdminsCreate() {
    return (
        <ChakraProvider resetCSS theme={chakratheme}>
            <Box
                sx={{
                    padding: '10px 0',
                    maxWidth: '2xl',
                    minWidth: 'xl',
                    margin: '0 auto',
                }}
                className="admin-general-temp">
                <Box
                    px={{ base: '4', md: '6' }}
                    pt={{ base: '5', md: '6' }}
                    pb={0}>
                    <Tabs>
                        <TabList justifyContent="center" borderBottom={0}>
                            {['General', 'Admin'].map((t, index) => (
                                <Tab
                                    sx={customTabStyles}
                                    key={t}
                                    _selected={selectedTabStyles}>
                                    {t}
                                </Tab>
                            ))}
                        </TabList>
                        <TabPanels>
                            <TabPanel px={0}>
                                <General />
                            </TabPanel>
                            <TabPanel px={0}>
                                <Admin />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
            </Box>
        </ChakraProvider>
    );
}
