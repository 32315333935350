export const statusDisplayLevel = {
    Ok: 0,
    Warning: 1,
    Alert: 2,
    Ok_Animate: 10,
    Warning_Animate: 11,
    Alert_Animate: 12,
    Unknown: -1,
};

export const ACTIONS = {
    GET_SERVICE_ACTIONS: 'GET_SERVICE_ACTIONS',
    UPDATE_SERVICE_ACTIONS: 'UPDATE_SERVICE_ACTIONS',
    UPDATE_SERVICE: 'UPDATE_SERVICE',
    CHANGE_SERVICE_STATUS: 'CHANGE_SERVICE_STATUS',
};
