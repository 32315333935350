import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {makeStyles} from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {useSelector} from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 5,
    },
}));

/*
 * Button group for the image viewer
 * This button group resets, and zooms for the image viewer
 */
const Buttons = ({onSubtract, onReset, onAdd, text}) => {
    const classes = useStyles();
    const {darkMode} = useSelector((state) => {
        return {...state.settings};
    });
    return (
        <div className={classes.root}>
            <ButtonGroup
                variant="contained"
                style={{backgroundColor: darkMode ? '#121212' : '#E0E0E0'}}>
                <IconButton
                    onClick={onSubtract}
                    style={{backgroundColor: darkMode ? '#121212' : '#E0E0E0'}}>
                    <RemoveIcon />
                </IconButton>
                <Button onClick={onReset}> {text}</Button>
                <IconButton
                    onClick={onAdd}
                    style={{backgroundColor: darkMode ? '#121212' : '#E0E0E0'}}>
                    <AddIcon />
                </IconButton>
            </ButtonGroup>
        </div>
    );
};

export default Buttons;
