import React, { useState, useEffect } from 'react';
import {
    TextField,
    Checkbox,
    FormControlLabel,
    Divider,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Container, Button } from 'react-bootstrap';

import { onModalError } from 'components/tables/TableAndModal';
import { axios } from 'services/axios';
import { LoadingFieldDropdown } from 'components';
import { EditForm } from 'components/forms/MultiChild';

import walkthroughIds from '../walkthroughIds';

/*
 * General Tab when Editing A number block
 * Part of the CRUD process for number blocks
 */
const NumberBlocksGeneralForm = (props) => {
    const dispatch = useDispatch();
    const [deleteable, setDeletable] = useState('UNKNOWN');
    const [outboundCallerIDDropdown, setOutboundCallerIDDropdown] = useState([
        {
            telephoneNumber: 'Restricted',
            display: 'Restricted',
        },
        {
            telephoneNumber: 'DEFAULT',
            display: 'DEFAULT',
        },
    ]);

    // state access
    const { child } = useSelector((state) => {
        return { ...state.table };
    });

    const { state: modalState, data: modalData } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    const { requiredScope } = useSelector((state) => {
        return { ...state.login };
    });

    const {
        ufWholesalerSelect,
        ufInboundCarrier,
        ufOutboundCarrier,
        ufPartnerSelect,
        ufCountrySelect,
        ufCompanySelect,
        ufPublicPoolCheckbox,
        ufGlobalEntityCheckbox,
        ufNotesComment,
        ufOutboundCallerId,
    } = walkthroughIds;

    //function to determin if wholesalerID exists and which carrier endpoint to access
    const carrierFetch = () => {
        if (modalState?.wholesalerID) {
            return '/carriers/' + modalState?.wholesalerID;
        } else {
            return '/carriers';
        }
    };

    //fetch outbound caller ID dropdown
    useEffect(() => {
        modalState?.companyID &&
            axios
                .get(`/company/${modalState?.companyID}/numbers/presentable`)
                .then((res) => {
                    const newData = res.data
                        ?.map(({ telephoneNumber }) => ({
                            telephoneNumber,
                            display: Number(telephoneNumber)
                                ? '+' + telephoneNumber
                                : telephoneNumber,
                        }))
                        .concat([
                            {
                                telephoneNumber: 'Restricted',
                                display: 'Restricted',
                            },
                            {
                                telephoneNumber: 'DEFAULT',
                                display: 'DEFAULT',
                            },
                        ]);

                    setOutboundCallerIDDropdown(newData);
                });
    }, [modalState?.companyID]);

    //see if this num block is deletable / not. fetching from same endpoint as its corresponding delete table.
    useEffect(() => {
        if (deleteable === 'UNKNOWN') {
            axios.get('/numbers/' + child?.id).then((res) => {
                setDeletable(
                    res.data.findIndex(
                        ({ userID, status }) =>
                            Boolean(userID) || // this num belongs to someone
                            (status !== 'Disabled' && status !== 'OK'), //this number is not in OK status
                    ) === -1,
                );
            });
        }
    }, [deleteable]);

    // function to render the modal content
    const modalContent = () => {
        return (
            <>
                {requiredScope === 80 && (
                    <Row style={{ minHeight: 60 }}>
                        <Col>
                            <LoadingFieldDropdown
                                searchable
                                fieldFetch={'/wholesalers'}
                                fieldName="Wholesaler"
                                dropDownValue="id"
                                displayField="name"
                                onChange={(v) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            wholesalerID: v,
                                            partnerID: null,
                                            companyID: null,
                                            cliPresentation: null,
                                        },
                                    });
                                }}
                                fieldValue={modalState?.wholesalerID}
                                onError={onModalError}
                                dataWalkthroughid={ufWholesalerSelect}
                            />
                        </Col>
                        <Col>
                            <LoadingFieldDropdown
                                searchable
                                fieldFetch={carrierFetch()}
                                fieldName="Inbound Carrier"
                                dropDownValue="id"
                                displayField="name"
                                onChange={(v) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            inboundCarrierID: v,
                                        },
                                    });
                                }}
                                noEmptyOption
                                fieldValue={modalState?.inboundCarrierID}
                                onError={onModalError}
                                dataWalkthroughid={ufInboundCarrier}
                            />
                        </Col>
                        <Col>
                            <LoadingFieldDropdown
                                searchable
                                fieldFetch={carrierFetch()}
                                fieldName="Outbound Carrier"
                                dropDownValue="id"
                                displayField="name"
                                onChange={(v) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            outboundCarrierID: v,
                                        },
                                    });
                                }}
                                noEmptyOption
                                fieldValue={modalState?.outboundCarrierID}
                                onError={onModalError}
                                dataWalkthroughid={ufOutboundCarrier}
                            />
                        </Col>
                    </Row>
                )}
                <Row style={{ minHeight: 60 }}>
                    {requiredScope > 40 && modalState?.wholesalerID && (
                        <Col>
                            <LoadingFieldDropdown
                                searchable
                                fieldFetch={
                                    '/partners/' + modalState?.wholesalerID
                                }
                                fieldName="Partner"
                                dropDownValue="id"
                                dependency={modalState?.wholesalerID}
                                hasDependency={true}
                                displayField="name"
                                onChange={(v) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            partnerID: v,
                                            companyID: null,
                                            cliPresentation: null,
                                        },
                                    });
                                }}
                                fieldValue={modalState?.partnerID}
                                onError={onModalError}
                                dataWalkthroughid={ufPartnerSelect}
                            />
                        </Col>
                    )}
                    {modalState?.partnerID && (
                        <Col>
                            <LoadingFieldDropdown
                                searchable
                                onFieldFetch={() => {
                                    return axios.get(
                                        `/companies/list?partnerID=${modalState?.partnerID}`,
                                        {
                                            headers: {
                                                'X-RequestScope': '40',
                                            },
                                        },
                                    );
                                }}
                                fieldName="Company"
                                dependency={modalState?.partnerID}
                                hasDependency={true}
                                dropDownValue="id"
                                displayField="name"
                                onChange={(v) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            companyID: v,
                                            cliPresentation: null,
                                        },
                                    });
                                }}
                                fieldValue={modalState?.companyID}
                                onError={onModalError}
                                dataWalkthroughid={ufCompanySelect}
                            />
                        </Col>
                    )}
                    <Col>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={modalState?.publicPool}
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                publicPool:
                                                    !modalState?.publicPool,
                                            },
                                        });
                                    }}
                                    data-walkthroughid={ufPublicPoolCheckbox}
                                />
                            }
                            label="Public Pool"
                        />
                        {requiredScope === 80 && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled
                                        checked={modalState?.globalEntity}
                                        data-walkthroughid={
                                            ufGlobalEntityCheckbox
                                        }
                                    />
                                }
                                label="Global Entity"
                            />
                        )}
                    </Col>
                </Row>
                <Row style={{ minHeight: 60 }}>
                    <Col xs={4}>
                        <TextField
                            label="Country"
                            fullWidth
                            disabled
                            value={modalState?.country !== "" ? modalState.country : '- Not Allocated -'}
                            dataWalkthroughid={ufCountrySelect}
                        />
                    </Col>
                </Row>
                {requiredScope === 80 && (
                    <Row style={{ minHeight: 100 }}>
                        <Col>
                            <TextField
                                label="Notes"
                                fullWidth
                                placeholder="Relevant notes regarding this number block"
                                multiline
                                minRows={3}
                                maxRows={5}
                                value={modalState?.internalNotes}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            internalNotes: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={ufNotesComment}
                            />
                        </Col>
                    </Row>
                )}
                <>
                    <h4
                        style={{
                            fontWeight: 500,
                            fontSize: '1.5rem',
                            marginBottom: '0.5rem',
                        }}>
                        Danger Zone
                    </h4>
                    <Container fluid className="roundBox danger">
                        <Row style={{ minHeight: 60 }}>
                            <Col style={{ minWidth: '60%' }}>
                                <b> Outbound Caller ID</b> <br /> This will
                                overwrite the CLI presentation for ALL numbers
                                in the block.
                            </Col>
                            <Col
                                style={{
                                    minWidth: '40%',
                                    textAlign: 'right',
                                }}>
                                {modalState?.companyID && (
                                    <LoadingFieldDropdown
                                        searchable
                                        fieldName=""
                                        dependency={modalState?.companyID}
                                        dropDownValue="telephoneNumber"
                                        displayField="display"
                                        staticEnum={outboundCallerIDDropdown}
                                        onChange={(v) => {
                                            dispatch({
                                                type: 'CHANGE_MODAL_STATE',
                                                payload: {
                                                    cliPresentation: v,
                                                },
                                            });
                                        }}
                                        fieldValue={modalState?.cliPresentation}
                                        onError={onModalError}
                                        dataWalkthroughid={ufOutboundCallerId}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Container>
                </>
            </>
        );
    };

    return (
        <EditForm
            modalUpload={(freshData, state) => {
                return {
                    ...freshData,
                    ...state,
                };
            }}
            modalContent={modalContent()}
            modalFetch={'/numberblock/' + modalData?.id}
            modalUpdate="/numberblock/"
        />
    );
};

export default NumberBlocksGeneralForm;
