import { useSelector } from 'react-redux';
import ResizeTextarea from 'react-textarea-autosize';

import { Textarea, forwardRef } from '@chakra-ui/react';

/**
 * @param {import('@chakra-ui/react').TextareaProps} props
 * @returns {JSX.Element} AutoResizeTextarea
 */
export const AutoResizeTextArea = forwardRef((props, ref) => {
    const { colorScheme } = useSelector((state) => state.settings);
    return (
        <Textarea
            minH="unset"
            overflow={'hidden'}
            w="100%"
            resize={'none'}
            ref={ref}
            minRows={3}
            as={ResizeTextarea}
            focusBorderColor={'brand.500'}
            {...props}
        />
    );
});
