import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    useToast,
} from '@chakra-ui/react';

import { axios } from '../../../../services/axios';
import useDetectFileType from 'hooks/useDetectFileType';
import useFileUpload from 'hooks/useFileUpload';
// import config from '../../../../config.json';

import PartnerLoginPreview from 'components/v4/partners/PartnerBranding/PartnerBrandingLoginPreview';

import Links from './Links';
import Heading from 'components/v4/Heading';
import Text from 'components/v4/Text';
import FormLabel from 'components/v4/FormLabel';
import Dropdown from 'components/v4/Select';
import Button from 'components/v4/Button';
import Image from 'components/v4/Image';
// import ChakraAlert from 'components/v4/Alert';
// import ChakraAlertIcon from 'components/v4/AlertIcon';

import walkthroughIds from '../../walkthroughIds';
import {
    customTabStylesCombineTheme,
    selectedTabStylesCombineTheme,
} from '@/constants';

const imageContainerStyles = {
    height: '100px',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
};
const faviconContainerStyles = {
    width: '100px',
};
const bgImageContainerStyles = {
    height: '100px',
    width: '210px',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
};

const darkBgImageContainerStyles = {
    ...bgImageContainerStyles,
    background: 'gray.900',
    borderColor: 'gray.700',
};

const logoImageStyles = { maxHeight: '100%', maxWidth: '100%' };
const faviconImageStyles = {
    maxHeight: '100%',
    maxWidth: '100%',
    margin: '0 auto',
};

export default function PartnerBranding({ partner }) {
    const dispatch = useDispatch();
    const toast = useToast();

    // Logos
    const [logoSet, setLogoSet] = useState();

    // Branding
    const [brandingSet, setBrandingSet] = useState();
    const [submitted, setSubmitted] = useState('idle');

    // Logo refs
    const logoRef = useRef(null);
    const logoDarkRef = useRef(null);
    const logoFaviconRef = useRef(null);

    // Bg image ref
    const bgImageRef = useRef(null);

    // File refs
    const logoFileRef = useRef(null);
    const logoDarkFileRef = useRef(null);
    const logoFaviconFileRef = useRef(null);
    const bgImageFileRef = useRef(null);

    // File handlers
    const {
        uploadHandler: logoWhiteHandler,
        fileReaderResult: logoWhiteUploaded,
        file: logoWhiteFile,
    } = useFileUpload({
        allowedExtensions: ['.png', '.svg'],
        logoFileRef: logoFileRef,
    });
    const {
        uploadHandler: logoDarkHandler,
        fileReaderResult: logoDarkUploaded,
        file: logoDarkFile,
    } = useFileUpload({
        allowedExtensions: ['.png', '.svg'],
        logoFileRef: logoDarkFileRef,
    });
    const {
        uploadHandler: faviconHandler,
        fileReaderResult: faviconUploaded,
        file: faviconFile,
    } = useFileUpload({
        allowedExtensions: ['.png', '.svg'],
        logoFileRef: logoFaviconFileRef,
    });
    const {
        uploadHandler: bgImageHandler,
        fileReaderResult: bgImageUploaded,
        file: bgImageFile,
    } = useFileUpload({
        allowedExtensions: ['.png', '.svg'],
        logoFileRef: bgImageFileRef,
    });

    // File types
    const logoFileType = useDetectFileType(logoWhiteUploaded || logoSet?.light);
    const darkLogoFileType = useDetectFileType(
        logoDarkUploaded || logoSet?.dark,
    );
    const faviconFileType = useDetectFileType(
        faviconUploaded || logoSet?.favicon,
    );
    const bgImageFileType = useDetectFileType(
        bgImageUploaded || brandingSet?.backgroundImage,
    );

    //for links tab
    const [linksData, setLinksData] = useState({});

    const {
        defaultCompany, //company ID based on login
    } = useSelector((state) => state.login);

    const { darkMode } = useSelector((state) => state.settings);

    const { branding } = walkthroughIds.partners;

    const tabList = ['Logos', 'Links'];

    //on submission success, display a toast
    useEffect(() => {
        if (submitted === 'success') {
            toast({
                title: 'Logos and Links Tabs have been submitted successfully.',
                status: 'success',
                duration: 6000,
                // isClosable: true,
                position: 'bottom',
            });
        }
    }, [submitted]);

    useEffect(() => {
        if (partner) getPartnerData();
    }, [partner]);

    async function getPartnerData() {
        // If no host header has been set, use a random string (unknown in this case) to return the default branding set (TCAP)
        const hostHeader = partner.hostHeader ? partner.hostHeader : 'unknown';
        const defaultBrandingKit = await axios
            .get('/theme/' + hostHeader)
            .then((response) => response.data)
            .catch((error) => {
                console.log(error);
            });

        const {
            logoFile,
            darkLogoFile,
            faviconFile,
            partnerBranding: {
                backgroundType,
                backgroundImage,
                backgroundColor,
                extLinkSupport,
                extLinkRegistrationTerms,
                extLinkPrivacyPolicy,
            },
        } = partner;

        setLogoSet({
            light: logoFile
                ? `https://${window.location.host}/images/${logoFile}`
                : `https://${window.location.host}${defaultBrandingKit.lightLogo}`,
            dark: darkLogoFile
                ? `https://${window.location.host}/images/${darkLogoFile}`
                : `https://${window.location.host}${defaultBrandingKit.darkLogo}`,
            favicon: faviconFile
                ? `https://${window.location.host}/images/${faviconFile}`
                : `https://${window.location.host}${defaultBrandingKit.favicon}`,
        });

        setBrandingSet({
            ...defaultBrandingKit?.data,
            backgroundType: backgroundType ?? defaultBrandingKit.backgroundType,
            backgroundImage: backgroundImage
                ? `https://${window.location.host}/images/${backgroundImage}`
                : `https://${window.location.host}${defaultBrandingKit.backgroundImage}`,
            backgroundColor: backgroundColor
                ? `#${backgroundColor}`
                : `#${defaultBrandingKit.backgroundColor}`,
        });
        setLinksData({
            extLinkSupport,
            extLinkRegistrationTerms,
            extLinkPrivacyPolicy,
        });
    }

    async function updatePartnerbranding() {
        setSubmitted('pending');
        const endpoint = `/partner/${partner.id}/logo`;

        // Branding
        const bgColor = brandingSet.backgroundColor.replace('#', '');

        //current partner's data
        const currentPartner = await axios.get(`/partner/${partner?.id}`);

        const urlPattern = /^(http|https):\/\//i;

        if (
            linksData?.extLinkRegistrationTerms &&
            !urlPattern.test(linksData?.extLinkRegistrationTerms)
        ) {
            setSubmitted('idle');
            toast({
                title: 'Enter a valid Terms and Conditions URL including https://',
                status: 'error',
                duration: 6000,
                // isClosable: true,
                position: 'bottom',
            });
            return;
        }

        if (
            linksData?.extLinkPrivacyPolicy &&
            !urlPattern.test(linksData?.extLinkPrivacyPolicy)
        ) {
            setSubmitted('idle');
            toast({
                title: 'Enter a valid Privacy Policy URL including https://',
                status: 'error',
                duration: 6000,
                // isClosable: true,
                position: 'bottom',
            });
            return;
        }

        axios
            .put('/partner', {
                ...currentPartner.data,
                partnerBranding: {
                    ...currentPartner.data.partnerBranding,
                    backgroundType: brandingSet.backgroundType,
                    backgroundColor: bgColor,
                    extLinkSupport: linksData.extLinkSupport,
                    extLinkRegistrationTerms:
                        linksData.extLinkRegistrationTerms,
                    extLinkPrivacyPolicy: linksData.extLinkPrivacyPolicy,
                },
            })
            // If we update the partner after uploading logos, it will overwrite them,
            // so we need to chain it with .then()
            .then(() => {
                // Light
                if (logoWhiteFile) {
                    let formData = new FormData();
                    formData.append('file', logoWhiteFile);
                    axios.post(endpoint, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        params: { mode: 'light' },
                    });
                }

                // Dark
                if (logoDarkFile) {
                    let formData = new FormData();
                    formData.append('file', logoDarkFile);
                    axios.post(endpoint, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        params: { mode: 'dark' },
                    });
                }

                // Favicon
                if (faviconFile) {
                    let formData = new FormData();
                    formData.append('file', faviconFile);
                    axios.post(endpoint, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        params: { mode: 'favicon' },
                    });
                }

                // Login bg image
                if (bgImageFile) {
                    let formData = new FormData();
                    formData.append('file', bgImageFile);
                    axios.post(endpoint, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        params: { mode: 'BG' },
                    });
                }
                if (partner?.id === defaultCompany) {
                    dispatch({
                        type: 'SET_SUPPORT_URL',
                        payload: linksData.extLinkSupport,
                    });
                }

                setSubmitted('success');

                // "Redirect back"
                setTimeout(() => {
                    dispatch({ type: 'RESET_MODAL' });
                }, 1500);
            })
            .catch((e) => {
                setSubmitted('idle');
                toast({
                    title:
                        e?.response?.data?.message ||
                        'Unable to update partner branding!',
                    status: 'error',
                    duration: 6000,
                    // isClosable: true,
                    position: 'bottom',
                });
            });
    }

    // Branding handlers
    function onPartnerBrandingChange(e) {
        e.persist();
        setBrandingSet((p) => ({
            ...p,
            ...{
                backgroundType: Number(e.target.value),
            },
        }));
    }
    function onPartnerLoginColorChange(e) {
        e.persist();
        setBrandingSet((p) => ({
            ...p,
            ...{
                backgroundColor: e.target.value,
            },
        }));
    }

    if (!logoSet || !brandingSet) return null;

    return (
        <>
            <Heading as="h1" sx={{ marginBottom: '35px', fontSize: '3xl' }}>
                Edit partner branding: {partner.name}
            </Heading>
            <Tabs
                colorScheme="brand"
                _focus={{
                    boxShadow: '0 0 0 3px rgba(255,66,87,0.6)',
                }}>
                <Box sx={{ maxW: '900px', margin: '0 auto' }}>
                    <TabList borderBottom={'none'} justifyContent="center">
                        {tabList.map((tab, index) => (
                            <Tab
                                key={tab}
                                data-walkthroughid={`${branding.ufBrandingTab}/${tab}`}
                                onMouseDown={(e) =>
                                    e.button === 2 && e.preventDefault()
                                }
                                sx={customTabStylesCombineTheme}
                                _selected={{
                                    ...selectedTabStylesCombineTheme,
                                    textColor: darkMode
                                        ? 'rgba(255, 255, 255)'
                                        : 'inherit',
                                }}
                                _focus={{
                                    boxShadow:
                                        '0 0 0 3px rgba(153, 202, 255, 1)',
                                }}>
                                {tab}
                            </Tab>
                        ))}
                    </TabList>
                </Box>
                <TabPanels>
                    <TabPanel>
                        <Box sx={{ maxW: '900px', margin: '0 auto' }}>
                            {/* Logo */}
                            <Heading
                                as="h3"
                                sx={{ marginBottom: '25px', fontSize: '2xl' }}>
                                Logo
                            </Heading>

                            {/* Logos*/}
                            <Box sx={{ display: 'flex' }} className="gap-5">
                                {/* Logo - White Bg */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDir: 'column',
                                        width: '210px',
                                    }}>
                                    <Text
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: 'md',
                                            marginBottom: '13px',
                                        }}>
                                        Logo, white background
                                    </Text>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDir: 'column',
                                            marginBottom: '13px',
                                        }}>
                                        <Box
                                            shadow="md"
                                            border="3px solid"
                                            borderColor="gray.700"
                                            background="white"
                                            sx={imageContainerStyles}>
                                            {logoFileType === 'png' && (
                                                <Image
                                                    sx={logoImageStyles}
                                                    ref={logoRef}
                                                    src={
                                                        logoWhiteUploaded ||
                                                        logoSet?.light
                                                    }
                                                />
                                            )}
                                            {logoFileType === 'svg' && (
                                                <object
                                                    alt="Domain Logo"
                                                    style={logoImageStyles}
                                                    data={
                                                        logoWhiteUploaded ||
                                                        logoSet?.light
                                                    }
                                                    type="image/svg+xml"></object>
                                            )}
                                        </Box>
                                        <Button
                                            className="hover:cursor-pointer"
                                            as={FormLabel}
                                            size="xs"
                                            id="image-input"
                                            sx={{ margin: '15px auto 0 0' }}
                                            data-walkthroughid={`
                                            ${branding.ufLogoUploadButton}/whiteBackground
                                            `}>
                                            Choose File
                                            <input
                                                style={{ display: 'none' }}
                                                type="file"
                                                id="image-input"
                                                accept=".svg,.png"
                                                onChange={logoWhiteHandler}
                                                ref={logoFileRef}
                                            />
                                        </Button>
                                    </Box>
                                </Box>

                                {/* Logo - Dark Bg */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDir: 'column',
                                        width: '210px',
                                    }}>
                                    <Text
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: 'md',
                                            marginBottom: '13px',
                                        }}>
                                        Logo, dark background
                                    </Text>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDir: 'column',
                                        }}>
                                        <Box
                                            shadow="md"
                                            border="3px solid"
                                            borderColor="gray.700"
                                            sx={darkBgImageContainerStyles}>
                                            {darkLogoFileType === 'png' && (
                                                <Image
                                                    sx={logoImageStyles}
                                                    ref={logoDarkRef}
                                                    src={
                                                        logoDarkUploaded ||
                                                        logoSet?.dark
                                                    }
                                                />
                                            )}
                                            {darkLogoFileType === 'svg' && (
                                                <object
                                                    alt="Domain Logo"
                                                    style={logoImageStyles}
                                                    data={
                                                        logoDarkUploaded ||
                                                        logoSet?.dark
                                                    }
                                                    type="image/svg+xml"></object>
                                            )}
                                        </Box>
                                        <Button
                                            className="hover:cursor-pointer"
                                            as={FormLabel}
                                            size="xs"
                                            id="image-input"
                                            sx={{ margin: '15px auto 0 0' }}
                                            data-walkthroughid={`${branding.ufLogoUploadButton}/darkBackground`}>
                                            Choose File
                                            <input
                                                style={{ display: 'none' }}
                                                type="file"
                                                id="image-input"
                                                accept=".svg,.png"
                                                ref={logoDarkFileRef}
                                                onChange={logoDarkHandler}
                                            />
                                        </Button>
                                    </Box>
                                </Box>

                                {/* Logo - Favicon */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDir: 'column',
                                        width: '100px',
                                    }}>
                                    <Text
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: 'md',
                                            marginBottom: '13px',
                                        }}>
                                        Favicon
                                    </Text>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: '',
                                            flexDir: 'column',
                                        }}>
                                        <Box
                                            shadow="md"
                                            border="3px solid"
                                            borderColor="gray.700"
                                            sx={{
                                                ...imageContainerStyles,
                                                ...faviconContainerStyles,
                                            }}>
                                            {faviconFileType === 'png' && (
                                                <Image
                                                    sx={faviconImageStyles}
                                                    ref={logoFaviconRef}
                                                    src={
                                                        faviconUploaded ||
                                                        logoSet?.favicon
                                                    }
                                                />
                                            )}
                                            {faviconFileType === 'svg' && (
                                                <object
                                                    alt="Domain Logo"
                                                    style={{
                                                        ...faviconImageStyles,
                                                    }}
                                                    data={
                                                        faviconUploaded ||
                                                        logoSet?.favicon
                                                    }
                                                    type="image/svg+xml"></object>
                                            )}
                                        </Box>
                                        <Button
                                            className="hover:cursor-pointer"
                                            as={FormLabel}
                                            size="xs"
                                            id="image-input"
                                            sx={{ margin: '15px auto 0 0' }}
                                            data-walkthroughid={`${branding.ufLogoUploadButton}/favicon`}>
                                            Choose File
                                            <input
                                                style={{ display: 'none' }}
                                                type="file"
                                                id="image-input"
                                                accept=".svg,.ng"
                                                onChange={faviconHandler}
                                                ref={logoFaviconFileRef}
                                            />
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>

                            <Box sx={{ margin: '25px 0' }}></Box>

                            <Heading as="h3" size="lg" sx={{ fontSize: '2xl' }}>
                                Login background
                            </Heading>

                            <Box sx={{ margin: '25px 0' }}></Box>

                            <FormLabel sx={{ marginBottom: '13px' }}>
                                Background Type
                            </FormLabel>
                            <Dropdown
                                sx={{
                                    borderColor: 'gray.300',
                                    color: 'gray.700',
                                }}
                                onChange={onPartnerBrandingChange}
                                defaultValue={brandingSet.backgroundType}
                                options={[
                                    {
                                        name: 'Default',
                                        value: 0,
                                    },
                                    { name: 'Solid color', value: 1 },
                                    { name: 'Background Image', value: 2 },
                                    { name: 'Bing Image of the day', value: 3 },
                                    { name: 'NASA Image of the day', value: 4 },
                                ]}
                                data-walkthroughid={
                                    branding.ufBackgroundTypeSelect
                                }
                            />

                            <Box sx={{ margin: '25px 0' }}></Box>

                            {/* Login - Solid bg */}
                            {brandingSet.backgroundType === 1 && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '210px',
                                        flexDir: 'column',
                                    }}>
                                    <Text
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: 'md',
                                            marginBottom: '13px',
                                        }}>
                                        Background Color
                                    </Text>
                                    <input
                                        id="color-picker"
                                        type="color"
                                        value={brandingSet.backgroundColor}
                                        onChange={onPartnerLoginColorChange}
                                        style={{
                                            height: 100,
                                            width: 200,
                                            border: '2px solid',
                                            padding: '1px',
                                        }}
                                        //style={{ visibility: "hidden" }}
                                    />
                                </Box>
                            )}

                            {/* Login - Bg Image */}
                            {brandingSet.backgroundType === 2 && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDir: 'column',
                                        width: '210px',
                                    }}>
                                    <Text
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: 'md',
                                            marginBottom: '13px',
                                        }}>
                                        Background Image
                                    </Text>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDir: 'column',
                                        }}>
                                        <Box
                                            shadow="md"
                                            border="3px solid"
                                            borderColor="gray.700"
                                            sx={imageContainerStyles}>
                                            {bgImageFileType === 'png' && (
                                                <Image
                                                    sx={logoImageStyles}
                                                    ref={bgImageRef}
                                                    src={
                                                        bgImageUploaded ||
                                                        brandingSet.backgroundImage
                                                    }
                                                />
                                            )}
                                            {bgImageFileType === 'svg' && (
                                                <object
                                                    alt="Domain Logo"
                                                    style={{
                                                        logoImageStyles,
                                                    }}
                                                    data={
                                                        bgImageUploaded ||
                                                        brandingSet.bgImageUploaded
                                                    }
                                                    type="image/svg+xml"></object>
                                            )}
                                        </Box>
                                        <Button
                                            className="hover:cursor-pointer"
                                            as={FormLabel}
                                            size="xs"
                                            id="image-input"
                                            sx={{ margin: '15px 0 0 auto' }}
                                            data-walkthroughid={`${branding.ufLogoUploadButton}/darkBackground`}>
                                            Choose File
                                            <input
                                                style={{ display: 'none' }}
                                                type="file"
                                                id="image-input"
                                                accept=".svg,.png"
                                                onChange={bgImageHandler}
                                                ref={bgImageFileRef}
                                            />
                                        </Button>
                                    </Box>
                                </Box>
                            )}

                            <Box sx={{ marginBottom: '25px' }}></Box>

                            <Heading as="h3" sx={{ fontSize: '2xl' }}>
                                Login Preview
                            </Heading>

                            <PartnerLoginPreview
                                logo={logoWhiteUploaded || logoSet.light}
                                favicon={faviconUploaded || logoSet.favicon}
                                backgroundOptions={{
                                    backgroundType: brandingSet.backgroundType,
                                    backgroundImage:
                                        bgImageUploaded ||
                                        brandingSet.backgroundImage,
                                    backgroundColor:
                                        brandingSet.backgroundColor,
                                }}
                                dataWalkthroughId={branding.ufFakeLoginScreen}
                            />

                            {/* Save / Cancel */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    maxW: '140px',
                                    margin: '0 auto',
                                }}>
                                <Button
                                    variant="outline"
                                    _hover={{
                                        bg: 'brand.50',
                                        color: 'brand.600',
                                    }}
                                    sx={{ background: 'white' }}
                                    onClick={() =>
                                        dispatch({ type: 'RESET_MODAL' })
                                    }
                                    data-walkthroughid={`${branding.ufBackButton}/logos`}>
                                    Back
                                </Button>
                                <Button
                                    ml={4}
                                    onClick={updatePartnerbranding}
                                    data-walkthroughid={`${branding.ufSaveButton}/logos`}>
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </TabPanel>
                    <TabPanel>
                        <Box sx={{ maxW: '900px', margin: '0 auto' }}>
                            <Links
                                submitState={submitted}
                                partner={partner}
                                partnerBranding={linksData}
                                setPartnerBranding={setLinksData}
                                updatePartnerbranding={updatePartnerbranding}
                            />
                        </Box>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
}
