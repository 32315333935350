import {Box} from '@chakra-ui/react';
import {ArrowBackIcon, ExternalLinkIcon} from '@chakra-ui/icons';

// Components
import Button from '../../../../components/v4/Button';
import Text from '../../../../components/v4/Text';
import Heading from '../../../../components/v4/Heading';

// MS Card
import MicrosoftAuthCard from '../../../../components/v4/Cards/MicrosoftAuthCard/MicrosoftAuthCard';
import MicrosoftAuthCardImage from '../../../../components/v4/Cards/MicrosoftAuthCard/MicrosoftAuthCardImage';

export default function ServiceWizardMsAuth({serviceWizard}) {
    return (
        <Box sx={{pos: 'relative'}}>
            <Button
                size="sm"
                onClick={() => serviceWizard.reset()}
                sx={{position: 'absolute', top: '6px', right: 0}}>
                <ArrowBackIcon />
                Back to services
            </Button>

            <Heading as="h2">Authenticate your Microsoft Tenancy?</Heading>

            <Box
                sx={{
                    display: 'flex',
                    marginBottom: '15px',
                }}
            />

            {serviceWizard.serviceCreationResponse.authPolicy === 1 && (
                <>
                    <Text width={'800px'}>
                        Your company's authentication policy is set to optional.
                        If you would like to authenticate with Microsoft now,
                        click Authenticate now. Otherwise you can skip this step
                        by clicking Do this later.
                    </Text>

                    <Box
                        sx={{
                            display: 'flex',
                            marginBottom: '15px',
                            marginTop: '15px',
                        }}
                    />
                </>
            )}

            <Box
                sx={{
                    display: 'flex',
                    height: '1px',
                    width: '100%',
                    background: 'gray.300',
                }}
            />

            <Box
                sx={{
                    display: 'flex',
                    marginBottom: '15px',
                    marginTop: '15px',
                }}
            />

            {/* Content */}
            <MicrosoftAuthCard>
                <MicrosoftAuthCardImage />

                <Button
                    onClick={() => {
                        serviceWizard.microsoftAuth();
                    }}
                    size="sm"
                    sx={{margin: '0 auto', display: 'block'}}
                    variant="link">
                    Authenticate Now <ExternalLinkIcon />
                </Button>

                <Box
                    sx={{
                        height: '1px',
                        width: '15px',
                        background: 'gray.500',
                        margin: '7px auto',
                    }}></Box>

                <Button
                    onClick={() => {
                        serviceWizard.reset();
                    }}
                    size="xs"
                    sx={{
                        margin: '0 auto',
                        color: 'gray.500',
                        textAlign: 'center',
                        display: 'block',
                    }}
                    variant="link">
                    Do this later
                </Button>
            </MicrosoftAuthCard>
        </Box>
    );
}
