export const getDarkToggleBtnStyles = (darkMode) => ({
    'div button.MuiToggleButton-root': {
        color: 'white',
        backgroundColor: '#007BFFA6',
        border: darkMode ? '1px solid #434343' : null,
        '&.Mui-selected': {
            color: 'white',
            backgroundColor: '#007bff',
        },
    },
});
