import { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import {
    EventType,
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser';

import { callSsoApi } from '@sso/utils/callSsoApi';
import { useAuth } from '@sso/context/authContext';

import { loginRequest, callMsToken } from '@sso/config';

export const useMsData = () => {
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { errorMessage, formState, setErrorMessage, setFormState } =
    useAuth();
  const [loginData, setLoginData] = useState({
    token: '',
  });
  const dispatch = useDispatch();

  const requestData = useCallback(async () => {
    if (!loginData?.token && inProgress === InteractionStatus.None) {
      try {
        const accessTokenRequest = {
          ...loginRequest,
          account: accounts[0],
        };

        try {
          const accessTokenResponse =
            await instance.acquireTokenSilent(accessTokenRequest);
          const { accessToken } = accessTokenResponse;
          // when user signs in from another tab or window
          instance.enableAccountStorageEvents();

          instance.addEventCallback((event) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
              const account = event.payload.account;
              instance.setActiveAccount(account)
            }
          });
          await callSsoApi(
            accessToken,
            dispatch,
            setLoginData,
            setFormState,
            setErrorMessage,
          );
        } catch (error) {
          // NOTE: will prevent error when users logout of SSO
          if (error instanceof InteractionRequiredAuthError) {
            try {
              const accessTokenResponse =
                await instance.acquireTokenPopup(
                  accessTokenRequest,
                );
              const { accessToken } = accessTokenResponse;
              const response = await callMsToken(accessToken);
              setLoginData(response);
            } catch (err) {
              console.error(err);
            }
          }
          console.log('no account error second try catch')
          throw new Error(error);
        }
      } catch (error) {
        console.log('no account error first try catch')
        throw new Error(error);
      }
    }
  }, [loginData?.token, inProgress, instance, accounts]);

  useEffect(async () => {
    if (isAuthenticated) {
      await requestData();
    }
  }, [isAuthenticated, requestData])

  return {
    requestData,
    errorMessage,
    setErrorMessage,
    formState,
  };
};
