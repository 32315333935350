import {
    Box,
    Stack,
    FormControl,
    FormLabel,
    Text,
    Button,
} from '@chakra-ui/react';
import { Checkbox } from '@/components/v4';
import walkthroughIds from '../walkthroughIds';
import { useSelector } from 'react-redux';

export default function EditAdmin({ adminUser, handleCheckboxChange, onOpen }) {
    const { adminsAdmin } = walkthroughIds;
    const { darkMode } = useSelector((state) => state.settings);
    return (
        <Stack spacing="3">
            <FormControl>
                <Box className="flex items-center">
                    <Checkbox
                        isChecked={adminUser.canOverrideOverStamping}
                        onChange={handleCheckboxChange}
                        name="canOverrideOverStamping"
                        colorScheme="whatsapp"
                        mb={0}
                        data-walkthroughid={adminsAdmin.ufOverrideOverstampList}
                    />
                    <FormLabel mb={0} ml={2}>
                        Override Overstamp List
                    </FormLabel>
                </Box>
            </FormControl>

            <FormControl>
                <Box className="flex items-center">
                    <Checkbox
                        isChecked={adminUser.apiDocReader}
                        onChange={handleCheckboxChange}
                        name="apiDocReader"
                        colorScheme="whatsapp"
                        mb={0}
                        data-walkthroughid={adminsAdmin.ufApiDocReader}
                    />
                    <FormLabel mb={0} ml={2}>
                        API Doc Reader
                    </FormLabel>
                </Box>
            </FormControl>

            <Box className="!mt-12">
                <Text className="text-2xl font-medium">Danger Zone</Text>
                <Box
                    padding={'5px 15px 5px 15px'}
                    bg={darkMode ? 'dark.bgDark' : 'inherit'}
                    className="mt-3 flex flex-row rounded-lg border-[2px] border-red-500">
                    <Box className="flex w-3/4 flex-col">
                        <Text className="text-lg font-semibold">Delete</Text>
                        <Text>
                            After deleting, this user will no longer be able to
                            log in.
                        </Text>
                    </Box>
                    <Box className="flex w-1/4 items-center justify-end">
                        <Button
                            variant="solid"
                            colorScheme="red"
                            minW={['100%', '80px']}
                            onClick={onOpen}>
                            Delete
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Stack>
    );
}
