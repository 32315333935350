import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { axios } from '../../../../services/axios';
// import config from '../../../../config.json';

import { LoginNormal, LoginMfa, MfaWizard } from '../';
import useFieldValidation from '../../../../hooks/useFieldValidation';

import { Box } from '@chakra-ui/react';

import { validationSchema } from './consts';
import { useSearchParams } from 'react-router-dom';

const MFA_LOGIN = 'MFA_LOGIN';
const MFA_SETUP = 'MFA_SETUP';
const NORMAL_LOGIN = 'NORMAL_LOGIN';

export default function LoginLoader({ setAuthAction }) {
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState({
        isError: false,
        message: '',
    });
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();

    const { validationErrors, validatefn } = useFieldValidation(
        { email: email, password: password },
        validationSchema,
    );

    const [loginAction, setLoginAction] = useState(NORMAL_LOGIN);
    const [authResponse, setAuthResponse] = useState(null);
    const [thisAdmin, setThisAdmin] = useState(null);

    // No token = MFA login
    // Token = MFA Login + Setup or successful non mfa login
    const afterFirstLogin = (data) => {
        setAuthResponse(data);

        // No token = mfa login
        if (!data.token) {
            setLoginAction(MFA_LOGIN);
            return;
        }

        fetch(`${import.meta.env.VITE_APP_API_URL}/AdminUser/me`, {
            headers: {
                accept: 'application/json, text/plain, */*',
                authorization: `bearer ${data.token}`,
                'x-requestscope': '20',
            },
            method: 'GET',
            mode: 'cors',
            credentials: 'omit',
        })
            .then(async (response) => {
                // Find `this` admin user
                const adminData = await response.json();
                setThisAdmin(adminData);
                // this one call second, NOTE: comment this for now to reduce API /company
                // dispatch({
                //     type: 'LOGIN_SUCCESS',
                //     payload: {
                //         ...adminData,
                //         // companyID: null,
                //     },
                // });

                // It's a MFA login
                if (adminData.mfaEnabled && !adminData.mfatotpConfirmed) {
                    dispatch({
                        type: 'LOGIN_MFA_SUCCESS',
                        payload: {
                            ...data,
                            email: data?.user,
                            showMFA: true,
                        },
                    });
                    setLoginAction(MFA_SETUP);
                }
                // It's a normal login
                else {
                    // navigate('/dashboard-services');
                    // this one call last
                    dispatch({
                        type: 'LOGIN_SUCCESS',
                        payload: {
                            ...data,
                            email: data?.user,
                            loadAccessList: true,
                        },
                    });
                }
            })
            .catch((e) => console.error(e));
    };

    const onFirstLogin = (e) => {
        e.preventDefault();

        if (!validatefn()) return;
        setLoading(true);
        localStorage.clear();
        sessionStorage.clear();
        dispatch({ type: 'LOGOUT' });
        dispatch({ type: 'CLEAR_TOKEN' });
        // dispatch({ type: 'persist/PERSIST' });
        // dispatch({ type: 'persist/REHYDRATE', key: 'root' });
        // dispatch({ type: 'persist/REHYDRATE', key: 'login' });

        axios
            .post('/auth', {
                username: email,
                password: password,
            })
            .then((res) => {
                setLoginError({ ...loginError, isError: false, message: '' });
                const { data } = res;
                // this one call first
                dispatch({
                    type: 'LOGIN_SUCCESS',
                    payload: {
                        id: null,
                    },
                });
                afterFirstLogin(data);
            })
            .catch((e) => {
                setLoginError({
                    ...loginError,
                    isError: true,
                    message: e?.response?.data?.message ?? '',
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const cancelLogin = () => {
        setLoginAction(NORMAL_LOGIN);
        setEmail('');
        setPassword('');
    };

    //in case we have stale data, clear them out
    useEffect(() => {
        // localStorage.clear();
        // sessionStorage.clear();
        // dispatch({type: 'LOGOUT'});
        // dispatch({type: 'CLEAR_TOKEN'});
        // dispatch({type: 'persist/PERSIST'});
        // dispatch({type: 'persist/REHYDRATE', key: 'root'});
        // dispatch({type: 'persist/REHYDRATE', key: 'login'});
    }, []);

    // We always show the normal login
    // After we login, we need to decide to either:
    // - Redirect user to app cause theres no MFA
    // - Show the MFA setup flow
    // - Show the MFA login
    return (
        <>
            {loginAction === NORMAL_LOGIN && (
                <Box
                    sx={{
                        display: 'flex',
                        padding: '20px 60px',
                        background: 'white',
                        boxShadow: '2xl',
                        width: '500px',
                        margin: '0 auto',
                        borderRadius: '10px',
                    }}>
                    <LoginNormal
                        validationErrors={validationErrors}
                        loginError={loginError}
                        setLoginError={setLoginError}
                        email={email}
                        setEmail={setEmail}
                        password={password}
                        setPassword={setPassword}
                        loading={loading}
                        onFirstLogin={onFirstLogin}
                        setAuthAction={setAuthAction}
                        changePasswordSucceed={searchParams.get(
                            'changePassword',
                        )}
                    />
                </Box>
            )}
            {loginAction === MFA_LOGIN && (
                <Box
                    sx={{
                        display: 'flex',
                        padding: '20px 60px',
                        background: 'white',
                        boxShadow: '2xl',
                        width: '650px',
                        maxHeight: '500px',
                        margin: '0 auto',
                        borderRadius: '10px',
                    }}>
                    <LoginMfa
                        admin={thisAdmin}
                        authResponse={authResponse}
                        username={email}
                        password={password}
                        cancelLogin={cancelLogin}
                    />
                </Box>
            )}

            {loginAction === MFA_SETUP && (
                <MfaWizard
                    authResponse={authResponse}
                    username={email}
                    password={password}
                />
            )}
        </>
    );
}
