export default function trunkStatusCodeToText(code) {
    switch (code) {
        case 0:
            return 'OK';
        case 1:
            return 'Adding';
        case 3:
            return 'Pending Approval';
        case 4:
            return 'Updating';
        case 8:
            return 'Degraded';
        case 9:
            return 'Deleting';
        case 20:
            return 'Error';
    }
}
