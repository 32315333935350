import { CheckIcon } from '@chakra-ui/icons';
import {
    Box,
    Circle,
    Flex,
    Text,
    VStack,
    useRadio,
    useRadioGroup,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Scrollbars } from 'react-custom-scrollbars-2';
import { renderThumb, renderTrack, renderView } from 'components/sidebar';

import { useChakraTheme } from 'hooks/useChakraTheme';
import { useLayoutContext } from 'context/LayoutContext';

const Radio = (props) => {
    const { getInputProps, getCheckboxProps: getRadioProps } = useRadio(props);
    const { darkMode, mixedMode, colorScheme } = useSelector(
        (state) => state.settings,
    );

    const input = getInputProps();
    const checkbox = getRadioProps();

    return (
        <Box as="label" display="inline-flex" alignItems="center">
            <input {...input} />
            <Box
                {...checkbox}
                cursor="pointer"
                borderRadius="full"
                boxShadow="md"
                bg={darkMode || mixedMode ? 'dark.bgDark' : '#F5F5F5'}
                _checked={{
                    color: 'white',
                    borderColor: `${props.color}.500`,
                    borderWidth: '1px',
                }}
                _focus={{
                    boxShadow: 'outline',
                }}
                _hover={{
                    borderColor: `${props.color}.500`,
                    borderWidth: '1px',
                }}
                w={8}
                h={8}
                aria-label="radio circle outer"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Circle
                    bg={`${props.color}.500`}
                    fontWeight="bold"
                    w={6}
                    h={6}
                    aria-label="radio circle inner"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    {input.checked && <CheckIcon w={3} h={3} />}
                </Circle>
            </Box>
            <Text
                className="ms-2 text-base capitalize"
                color={darkMode || mixedMode ? 'white' : 'gray.700'}
            >
                {props.children}
            </Text>
        </Box>
    );
};

export const ThemeGroup = () => {
    const { colorList } = useChakraTheme();
    const { color, handleColorChange } = useLayoutContext();
    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'colorScheme',
        defaultValue: color,
        onChange: handleColorChange,
    });
    const group = getRootProps();
    return (
        <Flex
            as={Scrollbars}
            autoHide
            autoHeightMax="600px"
            renderTrackVertical={renderTrack}
            renderThumbVertical={renderThumb}
            renderView={renderView}
        >
            <VStack
                alignItems="flex-start"
                {...group}
                sx={{
                    '& label': {
                        mb: 0,
                    },
                }}
            >
                {colorList.map((value) => {
                    const radio = getRadioProps({ value });
                    return (
                        <Radio key={value} {...radio} color={value}>
                            {value}
                        </Radio>
                    );
                })}
            </VStack>
        </Flex>
    );
};

Radio.propTypes = {};

export default Radio;
