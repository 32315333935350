import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { axios } from 'services/axios';
import {
    Box,
    Table,
    TableContainer,
    Tbody,
    Tr,
    Td,
    IconButton as ChakraIconButton,
    Text,
    forwardRef,
} from '@chakra-ui/react';

import { FormLabel } from '@/components/v4';

import { ArrowUpIcon, ArrowDownIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { Select } from 'chakra-react-select';

import { multiSelectDataMapper } from 'utils/utils';
import {
    defaultChakraSelectStyle,
    chakraSelectDarkMode,
    chakraTableStyle,
} from '@/constants';
import { useLocation } from 'react-router-dom';
import { tcapDarkShadow } from '@/chakratheme';

const IconButton = forwardRef((props, ref) => (
    <ChakraIconButton
        size="sm"
        _focus={{
            shadow: 'none',
        }}
        ref={ref}
        {...props}
    />
));

/*
 * Ordered list component that allows the use to move them up and down
 */
const OrderedLists = ({
    setState,
    attribute = 'regions',
    header = 'Regions',
    fieldFetch,
    dependency,
    options: fetchedOptions,
    modalState,
    placeholder,
    dataWalkthroughId,
}) => {
    const [add, setAdd] = useState(false);
    const [options, setOptions] = useState(fetchedOptions || []);
    const location = useLocation();
    const isGaLocation = location.pathname.includes('/regions-and-locations');

    const initialSelectedRegion = {
        id: '',
        name: placeholder,
        label: '',
        value: '',
    };
    const [selectedRegion, setSelectedRegion] = useState(initialSelectedRegion);

    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);
    const chakraStyles = {
        ...defaultChakraSelectStyle,
        ...chakraSelectDark,
        singleValue: (provided) => ({
            ...provided,
            color: 'gray.400',
        }),
    };

    const getTableState = () => {
        return modalState?.[attribute] ?? [];
    };

    const remainder = useMemo(() => {
        return options
            .filter(
                (available) =>
                    !getTableState().some(
                        (chosenValue) => chosenValue.id === available.id,
                    ),
            )
            .map(multiSelectDataMapper);
    }, [getTableState(), options]);

    const setTableState = (payload) => {
        setState({ [attribute]: payload });
    };

    useEffect(() => {
        if (dependency) {
            axios
                .get(fieldFetch)
                .then((res) => {
                    setOptions(res.data);
                })
                .catch((e) => {});
        }
    }, [dependency]);

    const swap = ({ currentIndex, newIndex }) => {
        var newTable = [...getTableState()];
        var currentData = newTable[currentIndex];
        var swapData = newTable[newIndex];

        //delete current index, replace with swapData:
        newTable.splice(currentIndex, 1, swapData);

        //replace newIndex with currentData:
        newTable.splice(newIndex, 1, currentData);
        setTableState(newTable);
    };

    const arrowDownAlign = getTableState().length > 1 ? '!mr-[35px]' : null;
    const noBorderBottom = (index) =>
        index === getTableState().length - 1 ? '!border-b-0' : null;
    // GA Regions and locations table style
    const tableContainerShadow =
        isGaLocation && darkMode ? tcapDarkShadow : null;
    const tableContainerBorder = darkMode ? 'dark.tcap-borderGray' : null;
    const colorTableDarkStyle = darkMode ? 'dark.bgDark' : '#FFF';
    const bdTableTdStyle = darkMode ? '#6a696a' : 'gray.200';
    const textDarkStyle = darkMode ? 'white' : 'black';
    const iconButtonDarkHover = {
        bg: darkMode ? 'whiteAlpha.800' : 'blackAlpha.100',
        color: darkMode ? 'black' : null,
    };

    return (
        <>
            <FormLabel sx={{ fontSize: 'md' }}>{header}</FormLabel>
            <TableContainer
                fontFamily="body"
                borderWidth={getTableState().length > 0 ? 1 : 0}
                borderColor={tableContainerBorder}
                boxShadow={tableContainerShadow}
                mb={getTableState().length > 0 ? 2 : 0}
                borderRadius="md">
                <Table
                    variant="simple"
                    sx={chakraTableStyle}
                    data-walkthroughid={dataWalkthroughId}>
                    <Tbody>
                        {getTableState().map((data, currentIndex) => (
                            <Tr
                                key={`row-${currentIndex}`}
                                sx={{ bg: colorTableDarkStyle }}
                                className={`row-${currentIndex}`}>
                                <Td
                                    className={noBorderBottom(currentIndex)}
                                    borderBottomColor={bdTableTdStyle}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            backgroundColor:
                                                colorTableDarkStyle,
                                            borderRadius: '5px',
                                            paddingLeft: '5px',
                                            paddingRight: '5px',
                                        }}>
                                        {/* Display Region Table Cell  */}
                                        <Text color={textDarkStyle}>
                                            {data['location'] || data['name']}
                                        </Text>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                borderRadius: '5px',
                                                paddingLeft: '5px',
                                                paddingRight: '5px',
                                                '& button': {
                                                    marginInline: '1px',
                                                },
                                            }}>
                                            {currentIndex !==
                                                getTableState().length - 1 && (
                                                <IconButton
                                                    _hover={iconButtonDarkHover}
                                                    backgroundColor={
                                                        colorTableDarkStyle
                                                    }
                                                    color={textDarkStyle}
                                                    className={
                                                        currentIndex === 0 &&
                                                        arrowDownAlign
                                                    }
                                                    onClick={() => {
                                                        swap({
                                                            currentIndex,
                                                            newIndex:
                                                                currentIndex +
                                                                1,
                                                        });
                                                    }}
                                                    aria-label="Move Down"
                                                    icon={<ArrowDownIcon />}
                                                />
                                            )}
                                            {currentIndex !== 0 && (
                                                <IconButton
                                                    _hover={iconButtonDarkHover}
                                                    backgroundColor={
                                                        colorTableDarkStyle
                                                    }
                                                    color={textDarkStyle}
                                                    onClick={() => {
                                                        swap({
                                                            currentIndex,
                                                            newIndex:
                                                                currentIndex -
                                                                1,
                                                        });
                                                    }}
                                                    aria-label="Move Up"
                                                    icon={<ArrowUpIcon />}
                                                />
                                            )}
                                            <IconButton
                                                _hover={iconButtonDarkHover}
                                                backgroundColor={
                                                    colorTableDarkStyle
                                                }
                                                color={textDarkStyle}
                                                onClick={() => {
                                                    //remove data
                                                    if (
                                                        getTableState()
                                                            .length === 1
                                                    ) {
                                                        setTableState([]);
                                                        return;
                                                    }
                                                    var newTable = [
                                                        ...getTableState(),
                                                    ];
                                                    newTable.splice(
                                                        currentIndex,
                                                        1,
                                                    );
                                                    setTableState(newTable);
                                                }}
                                                aria-label="Clear"
                                                icon={<SmallCloseIcon />}
                                            />
                                        </Box>
                                    </Box>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            {remainder.length > 0 && (
                <Box onClick={() => !add && setAdd(true)}>
                    <Select
                        chakraStyles={chakraStyles}
                        name="regions"
                        value={selectedRegion}
                        placeholder={placeholder}
                        onChange={(value) => {
                            setSelectedRegion(value);
                            const newTable = [...getTableState()];
                            newTable.push(value);
                            setTableState(newTable);
                            setAdd(false);
                            setSelectedRegion({
                                ...initialSelectedRegion,
                                name: placeholder,
                            });
                        }}
                        options={remainder}
                        getOptionLabel={(option) =>
                            option.name || option.location
                        }
                        getOptionValue={(option) => option.id}
                    />
                </Box>
            )}
        </>
    );
};

export default OrderedLists;
