import React from 'react';

import {
    AdminCentre,
    VirtualUser,
    ResourceAcc,
    SaveChanges,
} from '../../../../assets/images/Instructions_VirtualUserLicense';

// Guide to setting up with a virtual user lisence
export default function VirtualUserGuide() {
    return (
        <>
            <h2>
                Assigning your Microsoft Teams Resource Account with “Microsoft
                365 Phone System – Virtual User” license{' '}
            </h2>
            <p>
                Note: Only the resource account that has an inbound number
                associated requires this license attached. This license is also
                free ($0.00) and can be obtained from your current Microsoft CSP
                provider or from directly from your M365 tenancy.
            </p>

            <h3>1. License Purchase</h3>
            <p>
                To purchase the license directly from your Microsoft 365
                tenancy, first login to your Microsoft 365 admin centre via
                https://admin.microsoft.com
            </p>
            <ol>
                <li>
                    Navigate to Billing &gt; Licenses &gt; Microsoft 365 Phone
                    system - virtual user <br />
                    <img
                        alt=""
                        src={AdminCentre}
                        width="60%"
                        style={{padding: 10}}
                    />
                </li>
                <li>
                    Select the amount of users you wish to purchase and your
                    payment method <br />
                    <img
                        alt=""
                        src={VirtualUser}
                        width="60%"
                        style={{padding: 10}}
                    />
                </li>
            </ol>
            <h3> 2. Resource Accounts</h3>
            <ol>
                <li>
                    {' '}
                    Log into the Microsoft Teams admin portal &gt; Find the
                    'Resource Accounts' tab &gt; Take not of the 'Username' of
                    this account
                    <br />
                    <img
                        alt=""
                        src={ResourceAcc}
                        width="60%"
                        style={{padding: 10}}
                    />
                </li>
            </ol>
            <h3>3. Virtual User Assignment</h3>
            <ol>
                <li>
                    Log back into the Microsoft 365 Admin portal &gt; Find the
                    resource account noted in the previous step &gt; Assign the
                    free 'Microsoft 365 Phone System - Virtual User' and save
                    the changes.
                    <br />
                    <img
                        alt=""
                        src={SaveChanges}
                        width="60%"
                        style={{padding: 10}}
                    />
                </li>
            </ol>
        </>
    );
}
