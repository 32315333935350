import { useState, useEffect } from 'react';

export default function ServiceDashboardItemSyncUsers({ data }) {
    const [syncStatus, setSyncStatus] = useState('...');

    useEffect(() => {
        if (syncStatus === '...') {
            setSyncStatus(data?.userSync ? 'Enabled' : 'Off');
        } else if (syncStatus !== data?.userSync) {
            setSyncStatus(data?.userSync ? 'Enabled' : 'Off');
        }
    }, [data?.userSync]);

    return syncStatus;
}
