import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { axios } from '../../../../services/axios';
import { Alert, AlertTitle } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Visibility from '@material-ui/icons/VisibilityRounded';

import TableAndModal, {
    modalTitle,
    onModalError,
} from '../../../../components/tables/TableAndModal';
import { NonNegativeInteger } from '../../../../components/maintenance/NumberFormatCustom';
import config from '../../../../config.json';
import { LoadingFieldDropdown, MultiSelect } from '../../../../components';
import DeleteConfirmation, { DeleteButton } from './DeleteConfirmation';

import walkthroughIds from '../walkthroughIds';

const CallInclusions = (props) => {
    const dispatch = useDispatch();
    const tableRef = useRef();
    const [availableRatesList, setAvailableRatesList] = useState([]);
    const [add, setAdd] = useState(true);

    // state access
    const {
        data: modalData,
        state: modalState,
        mode: modalMode,
        show: modalShow,
        stateError: modalStateError,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    const { loading: tableLoading } = useSelector((state) => {
        return { ...state.table };
    });

    const { child } = useSelector((state) => {
        return {
            ...state.table,
        };
    });

    const { callingInclusions } = walkthroughIds;

    // default columns for the table
    const defaultColumns = [
        {
            title: 'Name',
            field: 'name',
            defaultSort: 'asc',
        },

        {
            title: 'Priority',
            field: 'priority',
        },
        {
            title: 'Included Minutes',
            field: 'inclusionAmount',
        },

        {
            title: 'Included Minutes Per',
            field: 'inclusionType',
            lookup: config.callInclusions.typeJSON,
        },
    ];

    // get rates for the child based on the modal
    useEffect(() => {
        if (child?.id) {
            axios
                .get('/rates/' + child?.id)
                .then((res) => {
                    setAdd(res?.data?.length > 0);
                })
                .catch((e) => {});
        }
    }, [child?.id]);

    // useEffect to get the availibile rates list for a specific child id
    useEffect(() => {
        if (
            modalShow &&
            modalState?.appliesTo &&
            modalState?.modified !== true
        ) {
            axios
                .get('/CallInclusion/AvailableRates/' + child?.id)
                .then((res) => {
                    var dumpedDataIDs = modalState.appliesTo.filter(
                        (id) =>
                            !res.data.some((validData) => validData.id === id),
                    );
                    var newAppliesTo = res.data.filter((availableRate) =>
                        modalState.appliesTo.some(
                            (item) => availableRate.id === item,
                        ),
                    );
                    dispatch({
                        type: 'CHANGE_MODAL_STATE',
                        payload: {
                            appliesTo: newAppliesTo,
                            modified: true,
                            dumpedDataIDs,
                        },
                    });
                    setAvailableRatesList(res.data);
                });
        }
    }, [modalState?.modified, modalShow, modalState?.appliesTo]);

    // return a table and modal for Call Inclusions
    return (
        <>
            {!tableLoading && !add && (
                <Alert severity="warning" style={{ marginTop: 10 }}>
                    <AlertTitle>No Calling Rates</AlertTitle>
                    To add a call inclusion, you must have at least 1 calling
                    rate.
                </Alert>
            )}
            <TableAndModal
                tableRef={tableRef}
                tableFetch={'/callinclusions/' + child?.id}
                modalFetch={'/callinclusion/' + modalData?.id}
                modalAdd="/callinclusion/"
                modalUpdate="/callinclusion/"
                modalUpload={(freshData, state) => {
                    return {
                        ...freshData,
                        ...state,
                        planID: child?.id,
                        appliesTo:
                            state?.appliesTo
                                ?.map((v) =>
                                    Object.keys(v).includes('id') ? v.id : v,
                                )
                                .filter((v) => v.length > 0) || [],
                    };
                }}
                title="Call Inclusions"
                noTitle
                defaultColumns={defaultColumns}
                add={!tableLoading && add}
                groupable
                filterable
                rowActions={{
                    editable: true,
                }}
                additionalActions={[
                    (rowData) => ({
                        isFreeAction: false,
                        hidden: true,
                        onClick: () =>
                            dispatch({
                                type: 'OPEN_MODAL',
                                payload: {
                                    mode: 'Edit',
                                    data: rowData,
                                },
                            }),
                        icon: () => <Visibility fontSize="small" />,
                        tooltip: 'View Call Inclusions',
                    }),
                ]}
                modalInitState={{
                    isActive: true,
                    appliesTo: [],
                }}
                maxBodyHeight={
                    window.innerHeight * config.breadcrumbView.tableHeight
                }
                modalTitle={modalTitle(
                    modalData,
                    'name',
                    ' Call Inclusions ',
                    modalMode,
                )}
                modalButtons={
                    modalMode === 'Edit' && (
                        <>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    dispatch({
                                        type: 'ON_MODAL_SUBMIT',
                                        payload: true,
                                    });
                                }}
                                >
                                Save Changes
                            </Button>
                            <DeleteButton />
                        </>
                    )
                }
                modalValidate={() => {
                    const newErrorState = {
                        name:
                            (!modalState?.name ||
                                modalState?.name?.length < 1) &&
                            'Field is required.',
                        priority:
                            ((modalState?.priority !== 0 &&
                                !modalState?.priority) ||
                                String(modalState?.priority)?.length < 1) &&
                            'Field is required.',
                        inclusionAmount:
                            (!modalState?.inclusionAmount ||
                                modalState?.inclusionAmount?.length < 1) &&
                            'Field is required.',
                        inclusionType:
                            !Object.keys(config.callInclusions.type).includes(
                                String(modalState.inclusionType),
                            ) && 'Field is required.',
                        appliesTo:
                            (!Array.isArray(modalState?.appliesTo) ||
                                modalState?.appliesTo?.length < 1) &&
                            'Must apply to at least 1 rate.',
                    };
                    dispatch({
                        type: 'CHANGE_MODAL_STATE_ERROR',
                        payload: newErrorState,
                    });
                    return !(
                        newErrorState.name ||
                        newErrorState.priority ||
                        newErrorState.inclusionAmount ||
                        newErrorState.inclusionType ||
                        newErrorState.appliesTo
                    );
                }}
                newPage
                modalContent={
                    <>
                        <DeleteConfirmation
                            show={modalState?.showPrompt}
                            mode={'Call Inclusion'}
                        />
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    type="text"
                                    value={modalState?.name}
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                name: e.target.value,
                                            },
                                        });
                                    }}
                                    error={Boolean(modalStateError?.name)}
                                    helperText={modalStateError?.name}
                                    data-walkthroughid={
                                        callingInclusions.ufNameInput
                                    }
                                />
                            </Col>
                            <Col>
                                <TextField
                                    label="Priority"
                                    value={modalState?.priority}
                                    InputProps={{
                                        inputComponent: NonNegativeInteger,
                                    }}
                                    error={Boolean(modalStateError?.priority)}
                                    helperText={modalStateError?.priority}
                                    fullWidth
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                priority: e.target.value,
                                            },
                                        });
                                    }}
                                    data-walkthroughid={
                                        callingInclusions.ufPriorityInput
                                    }
                                />
                            </Col>
                        </Row>
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <TextField
                                    label="Included Minutes"
                                    value={modalState?.inclusionAmount}
                                    InputProps={{
                                        inputComponent: NonNegativeInteger,
                                    }}
                                    error={Boolean(
                                        modalStateError?.inclusionAmount,
                                    )}
                                    helperText={
                                        modalStateError?.inclusionAmount
                                    }
                                    fullWidth
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                inclusionAmount: e.target.value,
                                            },
                                        });
                                    }}
                                    data-walkthroughid={
                                        callingInclusions.ufInclusionAmountInput
                                    }
                                />
                            </Col>
                            <Col>
                                <LoadingFieldDropdown
                                    fieldName="Included Minutes Per"
                                    dropDownValue={'value'}
                                    displayField="name"
                                    fieldValue={modalState?.inclusionType}
                                    staticEnum={config.callInclusions.type}
                                    onChange={(v) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                inclusionType: v,
                                            },
                                        });
                                    }}
                                    errorText={modalStateError?.inclusionType}
                                    noEmptyOption
                                    dataWalkthroughid={
                                        callingInclusions.ufInclusionTypeSelect
                                    }
                                />
                            </Col>
                        </Row>
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                {modalState?.modified ? (
                                    <MultiSelect
                                        onError={onModalError}
                                        label="Applies To"
                                        placeholder="+"
                                        staticEnum={availableRatesList}
                                        setState={(v) =>
                                            dispatch({
                                                type: 'CHANGE_MODAL_STATE',
                                                payload: {
                                                    appliesTo: v,
                                                },
                                            })
                                        }
                                        errorText={modalStateError?.appliesTo}
                                        value={modalState?.appliesTo}
                                        dataWalkthroughid={
                                            callingInclusions.ufAppliesToSelect
                                        }
                                    />
                                ) : (
                                    <Skeleton style={{ marginTop: '2%' }} />
                                )}
                            </Col>
                        </Row>
                    </>
                }
            />
        </>
    );
};

export default CallInclusions;
