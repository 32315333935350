import {Box} from '@chakra-ui/react';

import LoadingIndicator from 'components/v4/LoadingIndicator';

import {
    TpmWrapper,
    TpmContent,
    TpmHeader,
    TeamsPhoneMobileProcess,
    PortingNumber,
    AuthorizationProcess,
    MfaTeamsPhoneMobileWizard,
    TransferList,
    TpmSuccess,
    useTpmContext,
} from './';

function TeamsPhoneMobile() {
    const {
        tpmNumber: [state],
        tpmAddress: [addressAction],
    } = useTpmContext();

    return (
        <TpmWrapper>
            <TpmHeader />
            <Box>
                <TpmContent className="flex h-tpm flex-col items-center justify-center">
                    {addressAction === 'NOT_EXIST' && <PortingNumber />}

                    {addressAction === 'AUTHORIZATION_PROCESS' && (
                        <AuthorizationProcess />
                    )}

                    {addressAction === 'SMS_AUTHORIZATION' && (
                        <MfaTeamsPhoneMobileWizard />
                    )}

                    {addressAction === 'SELECT_SERVICES' && <TransferList />}

                    {addressAction === 'WIZARD_SUCCESS' && <TpmSuccess />}

                    {addressAction === 'GET' &&
                        (state.status === 'LOADING' ? (
                            <Box className="justify-center items-center">
                                <LoadingIndicator className="my-auto" />
                            </Box>
                        ) : (
                            <TeamsPhoneMobileProcess />
                        ))}
                </TpmContent>
            </Box>
        </TpmWrapper>
    );
}

export default TeamsPhoneMobile;
