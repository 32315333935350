import { useDispatch, useSelector } from 'react-redux';
import { Select, MenuItem } from '@material-ui/core';
import { FormControl } from '@chakra-ui/react';
import { SortByAlphaRounded as SortByAlphaRoundedIcon } from '@material-ui/icons';

import tableWalkthroughIds from '@/components/tables/walkthroughIds';
import { muiSelectStyles } from '../constants';

const AlphabetSelect = ({ alphabet, alphabetOpen, alphabetFilter }) => {
    const dispatch = useDispatch();
    const { index: alphabetIndex } = useSelector((state) => state.table);
    return (
        <FormControl size="small" sx={muiSelectStyles}>
            <Select
                className="select-change h-[31px] rounded-md focus:!bg-none"
                data-walkthroughid={tableWalkthroughIds.table.ufSortBtn}
                startAdornment={
                    <SortByAlphaRoundedIcon
                        style={{
                            marginBottom: '5%',
                            paddingRight: '5%',
                        }}
                    />
                }
                name="alphabetSelect"
                value={alphabet}
                open={alphabetOpen}
                onChange={(event) => {
                    dispatch({
                        type: 'CHANGE_TABLE_ALPHABET_FILTER',
                        payload: event.target.value,
                    });
                    alphabetFilter(event.target.value, 'displayName');
                }}>
                <MenuItem disabled value="">
                    Filter A-Z
                </MenuItem>
                <MenuItem value="All">All</MenuItem>

                {alphabetIndex?.map((v, idx) => (
                    <MenuItem
                        key={idx}
                        value={v}
                        data-walkthroughid={`/table/toolbar/viewColumnBtn/${v}/${idx}`}>
                        {v}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default AlphabetSelect;
