// Main heading
export const mainHeadingStyles = {
    textAlign: 'center',
    marginBottom: '60px',
};

// Main container
export const containerStyles = {
    marginTop: '150px',
    marginBottom: '150px',
    maxWidth: 1100,
    width: '100%',
   // minHeight: 'calc(100vh - 150px)',
    background: '#FFF',
    borderRadius: '10px',
    padding: '20px',
    boxShadow: "md"
};

// Cname validate btn and message container
export const cnameBtnValidationContainer = {
    display: 'flex',
    alignItems: 'center',
    marginTop: '15px',
};

// Steps
export const stepContainersStyles = {
    display: 'flex',
};
export const stepContainerStyles = {
    maxWidth: 300,
    width: '100%',
    marginRight: '50px',
};
export const stepContainerHeading = {
    marginBottom: '15px',
};
export const stepContentContainerStyles = {
    width: '100%',
};

// Spacer
export const stepSpacerStyles = {
    marginTop: '20px',
    marginBottom: '20px',
    display: 'flex',
};
export const stepNumberParagraphSpacerStyles = {
    marginTop: '5px',
    marginBottom: '5px',
    display: 'flex',
};

// Dropdown
export const dropdownStyles = {
    borderColor: 'gray.300',
};

// text field
export const textInputStyles = {
    borderColor: 'gray.300',
};

// Upload logo button
export const uploadLogoBtnContainerStyles = {
    display: 'flex',
    margin: '15px 0 0 auto',
};

// Image uploads
export const logoContainerStyles = {
    display: 'flex',
    flexDir: 'column',
    width: '210px',
    marginRight: '25px',
};
export const logoPreviewContainerStyles = {
    height: '100px',
    width: '210px',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'gray.300',
    borderRadius: 'md',
};
export const logoPreviewImageStyles = {maxHeight: '100%', maxWidth: '100%'};

// Setup now
export const setupBtnContainer = {
    display: 'flex',
    justifyContent: 'flex-end',
};

// Validate cname button
export const validateCnameBtnStyles = {
    margin: '0 0 0 auto',
    display: 'flex',
    minWidth: '60px',
};

// HR
export const hrStyles = {
    marginTop: '50px',
    marginBottom: '20px',
    display: 'flex',
    height: '1px',
    width: '100%',
    background: 'gray.300',
};
