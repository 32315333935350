import { useSelector } from 'react-redux';
import { red } from '@mui/material/colors';
import { Box, Paper, Typography, Button } from '@mui/material';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { axios } from '@/services/axios';
import walkthroughIds from '../walkthroughIds';
import { useState } from 'react';
import '../edit/endpoints/styles.css'

export default function EditSipRegistration({ aPass }) {
    const { darkMode } = useSelector((state) => state.settings);
    const [isGeneratingPassword, setIsGeneratingPassword] = useState(false);
    const [authPassword, setAuthPassword] = useState(aPass || ``);

    const { state } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    function generatePassword(id) {
        setIsGeneratingPassword(true);
        axios
            .get(`/Trunk/${id || state.id}/NewSipPassword`)
            .then((response) => {
                setAuthPassword(response.data);
                setTimeout(() => {
                    setIsGeneratingPassword(false);
                }, 2000);
            });
    }

    return (
        <Box
            sx={{
                boxShadow: 1,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: '#DDD',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                background: darkMode ? '#212121' : null,
                color: darkMode ? 'rgba(255, 255, 255, 0.7)' : null,
            }}>
            {/* Header */}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '64px',
                    paddingLeft: '24px',
                    paddingRight: '8px',
                }}>
                <Typography variant="h6">SIP Registration</Typography>
            </div>
            {/* Content */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    paddingLeft: '24px',
                    paddingRight: '8px',
                    marginBottom: 20,
                    marginTop: 10,
                }}>
                <Typography variant="p" fontWeight={'bold'}>
                    User
                </Typography>
                <Typography variant="p">
                    <Box sx={{ display: 'flex' }}>
                        <Box
                            className="copy-clipboard-btn-container"
                            sx={{
                                '&': {
                                    color: darkMode ? null : 'black',
                                },
                            }}>
                            {state.sipUsername}
                            <Box
                                className="copy-clipboard-btn"
                                onClick={() =>
                                    navigator.clipboard.writeText(state.sipUsername)
                                }>
                                <FileCopyIcon
                                    fontSize="small"
                                    style={{ margin: '3px' }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Typography>

                <Box sx={{ marginTop: '5px', marginBottom: '5px' }} />
                <Typography
                    variant="p"
                    fontWeight={'bold'}
                    color={darkMode ? null : 'black'}>
                    Password
                </Typography>

                <Typography variant="p">
                    {authPassword && !isGeneratingPassword ? (
                        <Box sx={{ display: 'flex' }}>
                            <Box className="copy-clipboard-btn-container">
                                <span>{authPassword}</span>
                                <Box
                                    className="copy-clipboard-btn"
                                    onClick={() =>
                                        navigator.clipboard.writeText(
                                            authPassword,
                                        )
                                    }>
                                    <FileCopyIcon
                                        fontSize="small"
                                        style={{ margin: '3px' }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        <Button
                            size="medium"
                            variant="contained"
                            sx={{ textTransform: 'none' }}
                            onClick={() => generatePassword()}
                            data-walkthroughid={
                                walkthroughIds.endpoints.ufGeneratePassButton
                            }>
                            {isGeneratingPassword
                                ? 'Generating password...'
                                : 'Regenerate Password'}
                        </Button>
                    )}
                </Typography>

                <Box sx={{ marginTop: '5px', marginBottom: '5px' }} />

                <Typography variant="body2" sx={{ color: red[400] }}>
                    Please ensure you have taken note of the password.
                    Generating a new password will require you to reconfigure
                    any devices using this trunk. Navigating away from this page
                    will clear your password from the screen.
                </Typography>
            </div>
        </Box>
    );
}
