/**
 * @param {Object} state
 * @param {CardStatusType} state.cardStatus
 * @param {{type: string; payload: {[k: string]: any; statusDisplay: {statusDisplayLevel: number}}}} action
 * */
export const cardStatusReducer = (state, action) => {
    switch (action.type) {
        case 'SET_STATUS_SUCCESS': {
            return {
                ...state,
                cardStatus: {
                    color: 'green',
                    isAnimated: false,
                    hasStripes: false,
                    status: 'Healthy',
                    to: '/services',
                    toText: 'edit',
                },
            };
        }

        case 'SET_STATUS_ERROR': {
            return {
                ...state,
                cardStatus: {
                    color: 'red',
                    isAnimated: false,
                    hasStripes: false,
                    status: 'Error',
                },
            };
        }

        case 'SET_STATUS_DEGRADED': {
            const { authData, SERVICE_STATUS_HAS_PDF } = action.payload;

            if (authData?.status !== 0 && authData?.engineMessage) {
                const hasPDFLink = Object.entries(SERVICE_STATUS_HAS_PDF).find(
                    ([errorMsg]) =>
                        authData?.engineMessage
                            .toLowerCase()
                            .includes(errorMsg.toLowerCase()),
                );
                const pdfLink = hasPDFLink && hasPDFLink[1];
                return {
                    ...state,
                    cardStatus: {
                        color: 'yellow',
                        isAnimated: false,
                        hasStripes: false,
                        status: authData?.engineMessage,
                        tooltip: true,
                        to: `${window.location.origin}${pdfLink?.payload}`,
                    },
                };
            }
            return state;
        }

        case 'SET_STATUS_PAIRING': {
            const { statusDisplayLevel } = action?.payload?.statusDisplay;
            return {
                ...state,
                cardStatus: {
                    color: 'yellow',
                    isAnimated: statusDisplayLevel === 11 ? true : false,
                    hasStripes: statusDisplayLevel === 11 ? true : false,
                    status: 'Pairing',
                },
            };
        }

        default:
            return state;
    }
};

/**
 * @typedef {Object} CardStatusType
 * @param {CardStatusType} initialState.cardStatus
 * */
export const initialState = {
    cardStatus: {
        color: '',
        isAnimated: false,
        hasStripes: false,
        status: '...',
        tooltip: false,
        to: '',
        toText: '',
        isAuthlessThanFive: false,
        toPayload: null,
        toScope: 0,
    },
};
