import { useEffect, useRef, useCallback } from 'react';
import { useToast } from '@chakra-ui/react';

export default function AdminStateToasts({
    resetMfaSuccess,
    resetMfaError,
    formState,
    hasError,
}) {
    const toast = useToast();
    const activeToastCount = useRef(0);

    const showToast = useCallback(
        (status, title, description) => {
            if (activeToastCount.current >= 3) {
                toast.closeAll();
                activeToastCount.current = 0;
            }

            const toastId = toast({
                title,
                status,
                description,
                duration: 3000,
            });

            activeToastCount.current++;
            if (activeToastCount.current > 1) {
                toast.close(toastId);
            }
        },
        [toast],
    );

    useEffect(() => {
        if (resetMfaSuccess) {
            showToast('success', 'Reset MFA Success', 'MFA token cleared!');
        }
        if (resetMfaError) {
            showToast(
                'error',
                'Reset MFA Error',
                'You do not have permission to reset this MFA token.',
            );
        }
        if (formState === 'success') {
            showToast(
                'success',
                'Account Update Success',
                'Account has been updated!',
            );
        }
        if (formState === 'error') {
            showToast(
                'error',
                'Account Update Error',
                hasError.message ?? 'Account updated failure!',
            );
        }
        if (formState === 'delete') {
            showToast(
                'info',
                'Account Delete Success',
                'Account has been deleted!',
            );
        }
        if (formState === 'delete error') {
            showToast(
                'error',
                'Account Update Error',
                hasError?.message ?? 'Account updated failure!',
            );
        }
        if (formState === 'reset password error') {
            showToast(
                'error',
                'Reset Password Error',
                hasError?.message ||
                    'Unable to process request. Please contact your administrator.',
            );
        }
        if (formState === 'bypass min password age error') {
            showToast(
                'error',
                'Bypass Minimum Password Age Error',
                hasError?.message ||
                    'Unable to process request. Please contact your administrator.',
            );
        }
        if (hasError?.message) {
            showToast('error', 'Error', hasError?.message);
        }
    }, [resetMfaSuccess, resetMfaError, formState, hasError, showToast]);
    return null;
}
