import { axios } from '@/services/axios';
import {
    ACTIONS,
    userAccEPoint,
} from '@/pages/accounts/resourceAccounts/constants';

export function updateForNoneTcap(endpoint, data, userData, state, dispatch) {
    // change from managedBy other to managedBy tcap
    if (userData.managedBy !== 0 && state.managedBy === 0) {
        return Promise.all([
            axios.put(`${endpoint}/`, {
                ...data,
            }),
            axios.put(`${endpoint}/${userData.id}/Attributes`, {
                ...data,
            }),
        ])
            .then(
                (_res) => {
                    dispatch({ type: ACTIONS.SUBMIT_FORM_SUCCESS });
                },
                (rej) => {
                    console.error(rej);
                    dispatch({
                        type: ACTIONS.SUBMIT_FORM_FAILURE,
                        message:
                            rej?.response?.data.message ||
                            'There was an error when updating, please try again later.',
                    });
                },
            )
            .finally(() => dispatch({ type: ACTIONS.RESET_FORM_STATUS }));
    }
    return axios
        .put(`${userAccEPoint}/${userData.id}/Attributes`, {
            ...data,
        })
        .then(() => dispatch({ type: ACTIONS.SUBMIT_FORM_SUCCESS }));
}
