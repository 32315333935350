import { TableAndModal } from 'components/index';
import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../config.json';
import { Button } from 'react-bootstrap';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { useNavigate } from 'react-router-dom';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { Box, Tooltip } from '@chakra-ui/react';

const Countries = () => {
    const dispatch = useDispatch();
    const tableRef = useRef();
    const navigate = useNavigate();

    const { requiredScope } = useSelector((state) => state.login);

    useEffect(() => {
        if (requiredScope != 80) {
            // get parent menu item Accounts
            let dashboard = document.querySelector('.Dashboards');
            // if only 1 then the menu item is closed
            if (dashboard?.children?.length == 1) {
                var divElement = dashboard.querySelector('div');
                // create on click event
                const clickEvent = new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                });
                divElement.dispatchEvent(clickEvent);
            }

            // redirect is to select the sub menu item
            // scope 20 to focus on currently managing company
            dispatch({
                type: 'REDIRECT',
                payload: {
                    pathname: '//dashboardServices',
                    scope: 20,
                },
            });

            navigate('/dashboard-services');
        }
    }, [requiredScope]);

    const { state: tableState } = useSelector((state) => {
        return {
            ...state.table,
        };
    });

    // default columns
    const defaultColumns = [
        {
            title: 'Name',
            field: 'name',
            defaultSort: 'asc',
        },
        {
            title: 'Country Code',
            field: 'countryCode',
        },
        {
            title: 'Alpha-2',
            field: 'alpha2',
        },
        {
            title: 'Alpha-3',
            field: 'alpha3',
        },
        // keep original array, map codes to a string
        {
            title: 'National Codes',
            field: 'nationalCodesString',
            width: '30%',
        },
        // keep original array, map codes to a string
        {
            title: 'Multi-National Codes',
            field: 'multiNationalCodesString',
            width: '25%',
        },
    ];

    const transformTableData = () => {
        const newTableData = tableState.data.map(
            ({ nationalCodes, multiNationalCodes, ...rest }) => ({
                ...rest,
                nationalCodesString: nationalCodes?.join(', '),
                multiNationalCodesString: multiNationalCodes?.join(', '),
            }),
        );
        dispatch({
            type: 'CHANGE_TABLE_STATE',
            payload: {
                columns: defaultColumns,
                data: newTableData,
                modified: true,
            },
        });
        return;
    };

    //
    useEffect(() => {
        if (!tableState?.modified && tableState?.data?.length > 0) {
            transformTableData();
        }
    }, [tableState?.data, tableState?.modified]);

    // returns Table and Modal for the component
    return (
        <>
            <h1 className="mb-2 text-[2.5rem] font-medium">Countries</h1>
            <TableAndModal
                tableRef={tableRef}
                tableFetch={'/countries'}
                size={'md'}
                title="Countries"
                noTitle
                defaultColumns={defaultColumns}
                additionalActions={[
                    (rowData) => ({
                        icon: () => <EditRoundedIcon fontSize="small" />,
                        tooltip: 'Edit Country',
                        position: 'row',
                        onClick: () => {
                            navigate(`edit/${rowData.id}`);
                        },
                    }),
                ]}
                titleProps={
                    <Button
                        className="btn btn-primary btn-sm"
                        style={{ marginBottom: '1%' }}
                        onClick={() => navigate('/countries/add')}>
                        {' '}
                        <AddRoundedIcon
                            fontSize="small"
                            style={{
                                marginBottom: '2%',
                            }}
                        />{' '}
                        Country
                    </Button>
                }
                groupable
                filterable
                maxBodyHeight={
                    window.innerHeight * config.plainView.tableHeight
                }
            />
        </>
    );
};

export default Countries;
