export const validationSchema = {
    password: [{type: 'isEmpty', message: 'Please enter a valid password.'}],
    code: [{type: 'isEmpty', message: 'Please enter a valid entity name.'}],
    name: [{type: 'isEmpty', message: 'Please enter a valid entity name.'}],
    trialDays: [
        {type: 'isEmpty', message: 'Please enter a valid entity name.'},
    ],
    autoApprove: [
        {type: 'isEmpty', message: 'Please enter a valid entity name.'},
    ],
    validTo: [{type: 'isEmpty', message: 'Please enter a valid entity name.'}],
    selectiveSyncPolicy: [{type: 'none'}],
};
