export async function callMsToken(accessToken) {
    const requestHeaders = new Headers();
    const encodedToken = window.btoa(accessToken);

    return fetch(
        `${import.meta.env.VITE_APP_API_URL}/auth/sso/Microsoft`,
        {
            method: 'POST',
            headers: {
                ...requestHeaders,
                ...{
                    'Content-Type': 'application/json',
                    'X-RequestScope': 20,
                },
            },
            mode: 'cors',
            credentials: 'omit',
            body: JSON.stringify(encodedToken),
        },
    )
        .then((res) => {
            if (!res.ok) {
                return res.json().then((data) => {
                    return data?.message
                })
            }
            return res.json()
        } 
        )
        .catch((error) => {
            console.error(error)
        } 
        );
}
