import {axios} from '../../services/axios';

export const getAllPartnerServiceProfiles = ({wholesalerID}) => {
    return axios.get(`/ServiceProfiles/Partner/All/${wholesalerID}`);
};

export const getPartnerServiceProfiles = ({partnerID}) => {
    return axios.get(`/serviceprofiles/partner/${partnerID}`);
};

export const getAllWholesalerServiceProfiles = () => {
    return axios.get(`/serviceprofiles/wholesaler/all`);
};

export const getWholesalerServiceProfiles = ({wholesalerID}) => {
    return axios.get(`/serviceprofiles/wholesaler/${wholesalerID}`);
};
