import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import useDetectFileType from 'hooks/useDetectFileType';

export function PartnerLogo({ h, w }) {
    const { imagePathLightMode, imagePathDarkMode } = useSelector(
        (state) => state.login,
    );
    const { darkMode } = useSelector((state) => state.settings);
    const imgPath = darkMode ? imagePathDarkMode : imagePathLightMode;
    const imageFileType = useDetectFileType(imgPath);
    return (
        <>
            {imageFileType === 'png' && (
                <Image
                    alt="Domain Logo"
                    style={{
                        display: 'block',
                        maxWidth: w ?? '300px',
                        maxHeight: h ?? '150px',
                        margin: '0 auto',
                    }}
                    src={import.meta.env.VITE_APP_IMAGE_BASE + imgPath}
                />
            )}
            {imageFileType === 'svg' && (
                <object
                    alt="Domain Logo"
                    style={{
                        display: 'block',
                        maxWidth: w ?? '300px',
                        maxHeight: h ?? '150px',
                        margin: '0 auto',
                    }}
                    data={import.meta.env.VITE_APP_IMAGE_BASE + imgPath}
                    type="image/svg+xml"
                ></object>
            )}
        </>
    );
}
