import {useState} from 'react';
import isEmail from 'validator/es/lib/isEmail';
import isEmpty from 'validator/es/lib/isEmpty';
import isMobilePhone from 'validator/es/lib/isMobilePhone';
import isLength from 'validator/es/lib/isLength';

function validate(type, value, conditions) {
    switch (type) {
        case 'isLength':
            return isLength(value, conditions);
        case 'isEmail':
            return isEmail(value, {
                require_tld: false,
            });
        case 'isEmpty':
            return isEmpty(value) ? false : true;
        case 'isMobilePhone':
            return isMobilePhone(value);
        case 'isArrayEmpty':
            return value.length !== 0;
        case 'isBetween':
            return value >= conditions[0] && value <= conditions[1];
        case 'isAboveEQ':
            return value >= conditions[0];
        case 'isStrongPassword':
            let errors = [];
            if (value.includes('<')) errors.push('No < allowed.');
            if (value.includes('>')) errors.push('No > allowed.');
            if (value.match(/^[A-Za-z0-9<>]+$/)) {
                errors.push('You need at least one special character.');
            }
            if (value.length < 8)
                errors.push('You need at least 8 characters.');
            if (!value.match(/[A-Z]/))
                errors.push('You need at least one uppercase character.');
            if (!value.match(/[a-z]/))
                errors.push('You need at least one lowercase character.');
            if (!value.match(/[0-9]/))
                errors.push('You need at least one numeric value.');
            if (errors.length) {
                return {
                    customErrorMessages: errors,
                };
            } else {
                return true;
            }
        case 'none':
            return true;
    }
}

export default function useFieldValidation(fields, fieldValidators) {
    const [validationErrors, setValidationErrors] = useState(null);

    function validateItem(validateConditions, key, value) {
        const isValid = validate(
            validateConditions.type,
            value,
            validateConditions.conditions,
        );
        // Workaround for now to return a custom message instead of user defined
        if (isValid?.customErrorMessages) {
            setValidationErrors((p) => {
                return {
                    ...p,
                    password: isValid.customErrorMessages,
                };
            });
            return false;
        }

        if (!isValid) {
            setValidationErrors((p) => ({
                ...p,
                [key]: [validateConditions.message],
            }));
            return false;
        } else {
            setValidationErrors((p) => {
                let errors = p;
                if (errors) {
                    if (errors.hasOwnProperty(key)) {
                        delete errors[key];
                    }
                }
                return errors;
            });
            return true;
        }
    }

    function validatefn() {
        let hasError = [];
        for (const [key, value] of Object.entries(fields)) {
            try {
                // Throw an error if we don't have a 1:1 match of properties
                if (!fieldValidators.hasOwnProperty(key)) {
                    throw new Error(
                        `No validation key found for the "${key}" field.`,
                    );
                }
                // Get validation conditions
                const validateConditions = fieldValidators[key];

                // Single or multiple validation conditions
                if (Array.isArray(validateConditions)) {
                    validateConditions.map((validateCondition) => {
                        !validateItem(validateCondition, key, value) &&
                            hasError.push(true);
                    });
                } else {
                    !validateItem(validateConditions, key, value) &&
                        hasError.push(true);
                }
            } catch (error) {}
        }
        return hasError.some(Boolean) ? false : true; // If any truth values are found in the array, return false to stop execution
    }

    return {validationErrors, validatefn};
}
