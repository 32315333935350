import React, { forwardRef, useMemo } from 'react';
import MaterialTable, { MTableBody } from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import NotInterestedRoundedIcon from '@material-ui/icons/NotInterestedRounded';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Container from 'react-bootstrap/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MTableBodyRow } from 'material-table';
import CustomTableFilterRow from './CustomTableFilterRow';
import { CustomTableCell, CustomTableToolbar } from '..';
import './datatable.css';
import { useSelector } from 'react-redux';
import { loadingColumnsConstructor } from './ReduxTable';

// Icons for use in the material table
const tableIcons = {
  Add: forwardRef((props, ref) => (
    <AddBox {...props} ref={ref} fontSize="small" />
  )),
  Check: forwardRef((props, ref) => (
    <Check {...props} ref={ref} fontSize="small" />
  )),
  Clear: forwardRef((props, ref) => (
    <Clear {...props} ref={ref} fontSize="small" />
  )),
  Delete: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} fontSize="small" />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} fontSize="small" />
  )),
  Edit: forwardRef((props, ref) => (
    <Edit {...props} ref={ref} fontSize="small" />
  )),
  Export: forwardRef((props, ref) => (
    <SaveAlt {...props} ref={ref} fontSize="small" />
  )),
  Filter: forwardRef((props, ref) => (
    <FilterList {...props} ref={ref} fontSize="small" />
  )),
  FirstPage: forwardRef((props, ref) => (
    <FirstPage {...props} ref={ref} fontSize="small" />
  )),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

// cell validation funciton
export const validateCell = (props, invalid, attribute) => {
  if (props[attribute] === invalid) {
    return <NotInterestedRoundedIcon />;
  }
  return props[attribute];
};

/*
 * Returns the skeleton of a material table with the props passed in
 * This is the final componet that TableAndModal.jsx uses to render the table
 */
export default function DataTable(props) {
  const {
    state,
    editable,
    titleName,
    tableRef,
    style,
    title,
    options,
    actions,
    remoteData,
    addActionRef,
    overrideOnRowSelected,
    ...rest
  } = props;
  
  // table loading state
  const { loading } = useSelector((state) => {
    return { ...state.table };
  });

  const loadingColumns = useMemo(
    () => loadingColumnsConstructor(state.columns),
    [state.columns],
  );
  const dummyTable = [];
  const dummyTableLength = 12;

  for (let i = 0; i < dummyTableLength; i++) {
      const obj = {
          title: `Title ${i + 1}`,
          field: `Field ${i + 1}`,
      };
      dummyTable.push(obj);
  }

  return (
    <>
      <MaterialTable
        title={title}
        actions={actions}
        columns={loading ? loadingColumns : state.columns}
        icons={tableIcons}
        data={loading ? dummyTable : (remoteData || state.data)}
        style={style}
        options={options}
        // localization={{
        //   body: {
        //     emptyDataSourceMessage: (
        //       <div
        //         id="displayMessage"
        //         style={{ textAlign: 'center' }}
        //       >
        //         {!loaded && isEmpty(tableRef?.current?.dataManager?.searchedData)
        //           ? 'Loading...'
        //           : 'No records to display'}
        //       </div>
        //     ),
        //   },
        // }}
        components={{
          Cell: (props) => {
            return <CustomTableCell {...props} />;
          },
          Toolbar: (props) => {
            const toolbarButtonAlignment =
              props.selectedRows.length > 0
                ? 'left'
                : props.toolbarButtonAlignment;
            return (
              <CustomTableToolbar
                {...props}
                toolbarButtonAlignment={toolbarButtonAlignment}
              />
            );
          },
          Row: (props) => {
            const actions = props.actions.map((v) =>
              typeof v.action === 'function'
                ? { action: v.action, position: 'row' }
                : v,
            );
            const onRowSelected = (e, path, dataClicked) => {
              props.onRowSelected(e, path, dataClicked);
              overrideOnRowSelected &&
                overrideOnRowSelected(e, path, dataClicked);
            };
            const newProps = { ...props, onRowSelected, actions };

            if (
              Object.keys(props.data).includes('isActive') &&
              !props.data.isActive
            ) {
              return (
                <MTableBodyRow
                  {...newProps}
                  className="tableRow inactive"
                />
              );
            }

            if (
              titleName === 'Engines' &&
              props.data?.lastActive &&
              props.data?.lastActive !== '0001-01-01T00:00:00'
            ) {
              if (
                new Date() -
                new Date(props.data?.lastActive + 'Z') <
                15 * 60 * 1000
              ) {
                return (
                  <MTableBodyRow
                    {...newProps}
                    className="tableRow"
                  />
                );
              }

              if (
                new Date() -
                new Date(props.data?.lastActive + 'Z') <
                30 * 60 * 1000
              ) {
                return (
                  <MTableBodyRow
                    {...newProps}
                    className="tableRow yellow"
                  />
                );
              }
              return (
                <MTableBodyRow
                  {...newProps}
                  className="tableRow red"
                />
              );
            }
            return (
              <MTableBodyRow {...newProps} className="tableRow" />
            );
          },

          Container: (props) => {
            return (
              <Container
                fluid
                {...props}
                style={{ padding: 0 }}
              />
            );
          },
          OverlayLoading: () => {
            return (
              <CircularProgress
                style={{
                  marginTop: '25%',
                  marginLeft: '50%',
                }}
              />
            );
          },
          FilterRow: (props) => {
            return <CustomTableFilterRow {...props} />;
          },
        }}
        tableRef={tableRef}
        editable={editable}
        {...rest}
      />
    </>
  );
}
