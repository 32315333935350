import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumbs, Link } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Box } from '@chakra-ui/react';

import { TableAndModal, Form, TabPanel, LoadingFieldDropdown } from '..';
import walkthroughIds from './walkthroughIds';

/*
 * A Edit Form that renders the children passed into it from state
 * this is rendered in a modal with data from the modal State
 */
export const EditForm = ({
    modalUpload,
    modalContent,
    modalValidate,
    modalFetch,
    modalAdd,
    modalUpdate,
}) => {
    const dispatch = useDispatch();

    // loading state
    const { loading: modalLoading } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // child data from table state
    const { child } = useSelector((state) => {
        return { ...state.table };
    });

    // use effect that dispatches a open modal with the child data whenever the child changes
    useEffect(() => {
        if (child?.id) {
            dispatch({
                type: 'OPEN_MODAL',
                payload: {
                    mode: 'Edit',
                    data: child,
                    show: true,
                    loading: true,
                },
            });
        }
    }, [child]);

    // returns a form with the modal State from above
    return (
        <Form
            reduxState={'modal'}
            loading={modalLoading}
            validateOnSubmit={modalValidate}
            reduxPrefix={''}
            formUpdate={modalUpdate}
            formAdd={modalAdd}
            mode={'Edit'}
            formFetch={modalFetch}
            formUpload={modalUpload}
            formContent={modalContent}
        />
    );
};

/*
 * A component that takes in title, tabs, loadingfielddropdown and presents them as a tabbed series of forms
 */
const MultiChild = ({
    title,
    tabs,
    loadingFieldDropdown = {},
    showBreadcrumbs = true,
    ...rest
}) => {
    const dispatch = useDispatch();

    // table state
    const { child, parent } = useSelector((state) => {
        return {
            ...state.table,
        };
    });

    // breadcrum from state
    const { breadcrumb } = useSelector((state) => {
        return { ...state.view };
    });

    // function for tabChange dispatch
    const onTabChange = (e, v) => {
        if (v !== breadcrumb) {
            dispatch({
                type: 'SWITCH_CHILD_TABLE',
                payload: v,
            });
        }
    };

    /*
     * Returns a tabbed page using the EditForm component above if a child exists
     * else render a TableAndModal.jsx
     */
    return (
        <>
            {child ? (
                <>
                    {showBreadcrumbs && (
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                href="/"
                                color="inherit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    dispatch({
                                        type: 'RESET_TABLE_MULTICHILD',
                                        payload: 'all',
                                    });
                                }}
                                data-walkthroughid={
                                    walkthroughIds.ufBreadcrumbs
                                }>
                                All {title}
                            </Link>
                            <div style={{ minWidth: 500 }}>
                                <LoadingFieldDropdown
                                    dropDownValue="id"
                                    displayField="name"
                                    staticEnum={parent}
                                    onChange={(v) => {
                                        const childData = parent.find(
                                            (parentValue) =>
                                                parentValue.id === v,
                                        );

                                        dispatch({
                                            type: 'CHANGE_CHILD_TABLE',
                                            payload: childData,
                                        });
                                    }}
                                    searchable
                                    noEmptyOption
                                    fieldValue={child.id}
                                    dataWalkthroughid={
                                        walkthroughIds.ufDropdown
                                    }
                                    {...loadingFieldDropdown}
                                />
                            </div>
                        </Breadcrumbs>
                    )}

                    <Box
                        sx={{
                            '& .MuiTabs-indicator': {
                                backgroundColor: 'brand.500',
                            },
                        }}>
                        <Tabs value={breadcrumb} onChange={onTabChange}>
                            {tabs.map(({ label, details }, index) => (
                                <Tab
                                    label={
                                        details
                                            ? label ?? `${title} Details`
                                            : label
                                    }
                                    data-walkthroughid={`/tab/${title}/${index}`}
                                />
                            ))}
                        </Tabs>
                        {tabs.map(({ component, details }, index) => (
                            <TabPanel value={breadcrumb} index={index}>
                                {details ? <EditForm {...rest} /> : component}
                            </TabPanel>
                        ))}
                    </Box>
                </>
            ) : (
                <TableAndModal title={title} {...rest} />
            )}
        </>
    );
};

export default MultiChild;
