import { chakraSelectDarkMode, defaultChakraSelectStyle } from '@/constants';
import { axios } from '@/services/axios';
import { AttachmentIcon, InfoIcon } from '@chakra-ui/icons';
import {
    Box,
    ChakraProvider,
    Container,
    FormErrorMessage,
    FormHelperText,
    Image,
    InputGroup,
    InputLeftElement,
    Stack,
    Tooltip,
} from '@chakra-ui/react';
import noImage from 'assets/images/noImage.png';
import { Select } from 'chakra-react-select';
import { Button, FormControl, FormLabel, Heading, Input } from 'components/v4';
import { useDeepMerge } from 'hooks/useDeepMerge';
import { isEmpty } from 'lodash';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import walkthroughIds from '../walkthroughIds';

export default function AddServiceCategory() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { theme } = useDeepMerge();

    const { ufID, ufName, ufDescription, ufImage, ufBack, ufCreate } =
        walkthroughIds.serviceCategories;

    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);

    // image upload
    const inputRef = useRef();

    const options = [
        {
            value: '0',
            label: 'Standard',
        },
        {
            value: '1',
            label: 'Location',
        },
    ];

    const initialState = {
        id: '',
        name: '',
        description: '',
        image: '',
        type: '0',
    };
    const [serviceCategory, setServiceCategory] = useState(initialState);
    const initialErrorState = {
        id: { isError: false, message: '' },
        name: { isError: false, message: '' },
        description: { isError: false, message: '' },
        image: { isError: false, message: '' },
    };
    const [errorState, setErrorState] = useState(initialErrorState);
    const [isDisabled, setIsDisabled] = useState(false);

    const handleChange = (key, e) => {
        setServiceCategory((prevState) => ({
            ...prevState,
            [key]: e.target.value,
        }));

        // remove error
        handleError(key, false, '');
    };

    const handleError = (key, isError, message) => {
        setErrorState((prevState) => ({
            ...prevState,
            [key]: { isError: isError, message: message },
        }));
    };

    const handleAdd = async () => {
        try {
            setIsDisabled(true);
            // validation check (id, name, image)
            let hasError = false;
            if (isEmpty(serviceCategory.id)) {
                handleError('id', true, 'ID is required.');
                hasError = true;
            }
            if (isEmpty(serviceCategory.name)) {
                handleError('name', true, 'Name is required.');
                hasError = true;
            }
            if (!(serviceCategory.image instanceof File)) {
                handleError('image', true, 'Image is required.');
                hasError = true;
            }

            // do not proceed if has errors
            if (hasError) {
                setIsDisabled(false);
                return;
            }

            var formData = new FormData();
            formData.append('id', serviceCategory.id);
            formData.append('name', serviceCategory.name);
            formData.append('description', serviceCategory.description);
            formData.append('image', serviceCategory.image);
            formData.append('type', serviceCategory.type);
            await axios.post('/servicecategory', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success('Service category created successfully!');
            dispatch({
                type: 'RESET_TABLE',
            });
            navigate('/service-categories');
        } catch (e) {
            toast.error(e?.response?.data?.message);
        } finally {
            setIsDisabled(false);
        }
    };

    return (
        <ChakraProvider resetCSS theme={theme}>
            <Container>
                <Heading
                    as={'h4'}
                    lineHeight={'1.5'}
                    fontSize={'1.5rem'}
                    fontWeight={500}>
                    Add Service Category
                </Heading>
                <Box
                    sx={{ flexGrow: 1, paddingTop: '1%', paddingBlock: '1%' }}
                    className="mx-auto min-w-[36rem] max-w-4xl">
                    <Stack direction={['column', 'row']} spacing={'24px'}>
                        <FormControl
                            isRequired
                            mb={2}
                            isInvalid={errorState.id.isError}>
                            <FormLabel>ID</FormLabel>
                            <Input
                                data-walkthroughid={ufID}
                                value={serviceCategory.id}
                                isDisabled={isDisabled}
                                onChange={(e) => handleChange('id', e)}
                                onKeyPress={(event) => {
                                    // only alphabets and hyphens
                                    if (!/^[A-Za-z-]+$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                placeholder="Please enter an ID"
                            />

                            {errorState.id.isError ? (
                                <FormErrorMessage>
                                    {errorState.id.message}
                                </FormErrorMessage>
                            ) : (
                                <FormHelperText>
                                    eg. TPM (alphabets and hyphens)
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl
                            isRequired
                            mb={2}
                            isInvalid={errorState.name.isError}>
                            <FormLabel>Name</FormLabel>
                            <Input
                                data-walkthroughid={ufName}
                                value={serviceCategory.name}
                                isDisabled={isDisabled}
                                onChange={(e) => handleChange('name', e)}
                                placeholder="Please enter a Name"
                            />
                            {errorState.name.isError ? (
                                <FormErrorMessage>
                                    {errorState.name.message}
                                </FormErrorMessage>
                            ) : (
                                <FormHelperText>
                                    eg. Teams Phone Mobile
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Stack>

                    <Stack direction={['column', 'row']} spacing={'24px'}>
                        {/* Category Type */}
                        <FormControl isRequired mb={2}>
                            <FormLabel>
                                Type
                                <Tooltip
                                    label={`'Standard' indicates the service's filter option, while 'Location' specifies its grouping.`}
                                    hasArrow
                                    placement="top-end">
                                    <InfoIcon ml={1} />
                                </Tooltip>
                            </FormLabel>
                            <Select
                                value={options?.find(
                                    (item) =>
                                        item.value === serviceCategory.type,
                                )}
                                options={options}
                                placeholder="Please select an option"
                                getOptionLabel={(i) => i.label}
                                onChange={(e) => {
                                    setServiceCategory((prevState) => ({
                                        ...prevState,
                                        type: e.value,
                                    }));
                                }}
                                chakraStyles={{
                                    ...defaultChakraSelectStyle,
                                    ...chakraSelectDark,
                                }}
                                isDisabled={isDisabled}
                            />

                            <FormHelperText>Default: Standard</FormHelperText>
                        </FormControl>

                        <FormControl
                            isRequired
                            isInvalid={errorState.image.isError}
                            mb={2}>
                            <FormLabel>Image</FormLabel>
                            <InputGroup>
                                <InputLeftElement pointerEvents="none">
                                    <AttachmentIcon />
                                </InputLeftElement>
                                <input
                                    type="file"
                                    onChange={(e) => {
                                        if (e.target.files.length > 0) {
                                            if (
                                                !(
                                                    e.target.files[0].name
                                                        .split('.')
                                                        .pop() == 'png' ||
                                                    e.target.files[0].name
                                                        .split('.')
                                                        .pop() == 'svg'
                                                )
                                            ) {
                                                handleError(
                                                    'image',
                                                    true,
                                                    'Invalid file format.',
                                                );
                                            } else {
                                                // reset error
                                                if (errorState.image.isError)
                                                    handleError(
                                                        'image',
                                                        false,
                                                        '',
                                                    );
                                                setServiceCategory(
                                                    (prevState) => ({
                                                        ...prevState,
                                                        image: e.target
                                                            .files[0],
                                                    }),
                                                );
                                            }
                                        }
                                    }}
                                    hidden
                                    name={'imageUpload'}
                                    accept={'.png,.svg'}
                                    ref={inputRef}
                                />
                                <Input
                                    data-walkthroughid={ufImage}
                                    pl="2.5rem"
                                    placeholder="Select an image to upload"
                                    onClick={(e) => {
                                        inputRef.current.click();
                                    }}
                                    isDisabled={isDisabled}
                                    isReadOnly={true}
                                    value={serviceCategory.image?.name}
                                />
                            </InputGroup>
                            {errorState.image.isError ? (
                                <FormErrorMessage>
                                    {errorState.image.message}
                                </FormErrorMessage>
                            ) : (
                                <FormHelperText>
                                    Allowed types .png .svg
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Stack>

                    <FormControl mb={2}>
                        <FormLabel>Description</FormLabel>
                        <Input
                            data-walkthroughid={ufDescription}
                            value={serviceCategory.description}
                            isDisabled={isDisabled}
                            onChange={(e) => handleChange('description', e)}
                            placeholder="Please enter a Description"
                        />
                        <FormHelperText>
                            eg. Answer and move calls seamlessly between devices
                        </FormHelperText>
                    </FormControl>

                    <Box
                        display={'flex'}
                        justifyContent={'center'}
                        maxBlockSize={300}>
                        <Image
                            fit={'contain'}
                            src={
                                serviceCategory.image
                                    ? URL.createObjectURL(serviceCategory.image)
                                    : noImage
                            }
                        />
                    </Box>

                    <Box display="flex" className="mt-3 justify-center">
                        <Button
                            variant="outline"
                            data-walkthroughid={ufBack}
                            bg="white"
                            minW={['100%', '82px']}
                            isDisabled={isDisabled}
                            onClick={() => {
                                navigate('/service-categories');
                            }}>
                            Back
                        </Button>

                        <Button
                            data-walkthroughid={ufCreate}
                            ml={4}
                            isLoading={isDisabled}
                            onClick={() => handleAdd()}>
                            Create
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ChakraProvider>
    );
}
