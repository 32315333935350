import {createContext, useContext, useState, useCallback} from 'react';
import {numbersAvailableMock} from './mockdata';

const VirtualizedTableContext = createContext();

export const VirtualizedTableProvider = ({children, data}) => {
    // const [query, setQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [search, setSearch] = useState('');
    // const handleChangeSearchDeb = useCallback(
    //     debounce(handleChangeSearch, 100),
    //     [],
    // );
    const handleChangeSearch = (e) => {
        setSearch(e);
        const newFilteredData =
            e.target.value.length === 0
                ? data
                : data.filter((v) => v['telephoneNumber'] === search);
        setFilteredData(newFilteredData);
    };
    // useEffect(() => {
    //     setFilteredData(data);
    // }, [data]);
    const value = {filteredData, search, setFilteredData, handleChangeSearch};

    return (
        <VirtualizedTableContext.Provider value={value}>
            {children}
        </VirtualizedTableContext.Provider>
    );
};

export const useVirtualizedTable = () => {
    const context = useContext(VirtualizedTableContext);
    if (!context) {
        throw new Error(
            'useVirtualizedTable must be used within a VirtualizedTableProvider',
        );
    }
    return context;
};
