import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { TextField } from '@material-ui/core';

import config from '../../../../config.json';
import { TableAndModal } from '../../../../components';

import walkthroughIds from '../walkthroughIds';

/**
 * Item Code Mappings component from the Plans comonent of TCAp
 */
const ItemCodeMappings = () => {
    const dispatch = useDispatch();
    const tableRef = useRef();

    // table data from state
    const { child } = useSelector((state) => {
        return {
            ...state.table,
        };
    });

    // modal data from state
    const { modalData, modalState, modalMode } = useSelector((state) => {
        return {
            modalLoading: state.modal.loading,
            modalData: state.modal.data,
            modalState: state.modal.state,
            modalMode: state.modal.mode,
            modalStateError: state.modal.stateError,
            modalUploading: state.modal.uploading,
        };
    });

    const { itemCodeMappings } = walkthroughIds;

    // default columns for the table
    const defaultColumns = [
        {
            title: 'Category',
            field: 'category',
            defaultSort: 'asc',
        },
        {
            title: 'Item Code',
            field: 'itemCode',
        },

        {
            title: 'Account Code',
            field: 'accountCode',
        },
        {
            title: 'Tracking Name 1',
            field: 'trackingName1',
        },
        {
            title: 'Tracking Option 1',
            field: 'trackingOption1',
        },
    ];

    // render the table and modal with the modal content specified
    return (
        <TableAndModal
            tableRef={tableRef}
            tableFetch={'/itemcodemappings/' + child?.id}
            modalFetch={'/itemcodemapping/' + modalData?.id}
            modalAdd={'/itemcodemapping/' + child?.id}
            modalUpdate="/itemcodemapping/"
            noTitle
            rowActions={{
                editable: true,
            }}
            newPage
            modalUpload={(freshData, state) => {
                return {
                    ...freshData,
                    ...state,
                    planID: child?.id,
                };
            }}
            title="Item Code Mappings"
            defaultColumns={defaultColumns}
            groupable
            filterable
            maxBodyHeight={window.innerHeight * config.plainView.tableHeight}
            modalTitle={
                modalMode === 'Edit'
                    ? 'Edit Item Code Mapping : ' + modalData?.category
                    : 'Generate Item Code Mappings'
            }
            modalContent={
                <>
                    <Row style={{ minHeight: 60 }}>
                        <Col>
                            <TextField
                                fullWidth
                                id="Account Code"
                                label="Account Code"
                                type="text"
                                value={modalState?.accountCode}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            accountCode: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={
                                    itemCodeMappings.ufAccountCode
                                }
                            />
                        </Col>
                        <Col>
                            <TextField
                                fullWidth
                                id="Item Code"
                                label="Item Code"
                                type="text"
                                value={modalState?.itemCode}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            itemCode: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={itemCodeMappings.ufItemCode}
                            />
                        </Col>
                    </Row>
                    <Row style={{ minHeight: 60 }}>
                        <Col>
                            <TextField
                                fullWidth
                                id="Tracking Name 1"
                                label="Tracking Name 1"
                                type="text"
                                value={modalState?.trackingName1}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            trackingName1: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={
                                    itemCodeMappings.ufTrackingName1
                                }
                            />
                        </Col>
                        <Col>
                            <TextField
                                fullWidth
                                id="Tracking Option 1"
                                label="Tracking Option 1"
                                type="text"
                                value={modalState?.trackingOption1}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            trackingOption1: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={
                                    itemCodeMappings.ufTrackingOption1
                                }
                            />
                        </Col>
                    </Row>
                </>
            }
        />
    );
};

export default ItemCodeMappings;
