import { Box, Skeleton } from '@chakra-ui/react';
import { Button, Heading } from 'components/v4';
import { useNavigate, useParams } from 'react-router-dom';

import { axios } from '@/services/axios';
import { useQuery } from '@tanstack/react-query';
import { differenceBy } from 'lodash';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ServiceStatus from '../users/ServiceStatus';
import VirtualizedServiceNumberTable from './VirtualizedServiceNumberTable';
import { VirtualizedServiceNumberTableProvider } from './VirtualizedServiceNumberTableProvider';

function BadgeWithCount({ count }) {
    return (
        <div className="absolute -right-2 -top-2 inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-brand-400 text-xs font-bold text-white dark:border-gray-900">
            {count}
        </div>
    );
}

function MoveButton({
    children,
    isDisabled,
    handleOnClick,
    dataWalkthroughId,
}) {
    const { darkMode } = useSelector((state) => {
        return { ...state.settings };
    });

    return (
        <Button
            type="button"
            class="focus:ring-blue-30 relative inline-flex h-8 w-full items-center justify-center rounded-lg border-[1px] border-brand-500 p-3 text-center font-medium leading-7 text-brand-500 focus:outline-none focus:ring-4 disabled:cursor-not-allowed disabled:opacity-40"
            disabled={isDisabled}
            bg={darkMode ? '#263238 !important' : 'inherit'}
            onClick={handleOnClick}
            data-walkthroughid={dataWalkthroughId}>
            {children}
        </Button>
    );
}

export default function ActivateServiceNumbers() {
    const { id: serviceId } = useParams();
    const navigate = useNavigate();

    const { currentCompany } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    // left side table (numbers pool)
    const [numbersAvailable, setNumbersAvailable] = useState([]);
    // right side table (activated/yet to be activated numbers)
    const [numbersChosen, setNumbersChosen] = useState([]);
    // service numbers that are already activated
    const [activatedNumbers, setActivatedNumbers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [checkedAvailable, setCheckedAvailable] = useState([]);
    const [checkedChosen, setCheckedChosen] = useState([]);

    // service status
    const [success, setSuccess] = useState(0);
    const [failed, setFailed] = useState(0);
    const [activateError, setActivateError] = useState({
        count: 0,
        message: '',
    });
    const [deactivateError, setDeactivateError] = useState({
        count: 0,
        message: '',
    });
    const [total, setTotal] = useState(0);

    // get users available to be activated for the service
    const { data: service, isFetching: serviceLoading } = useQuery({
        queryKey: ['service', serviceId],
        queryFn: async () => {
            const res = await axios.get(`service/${serviceId}`);
            return res.data;
        },
        enabled: Boolean(serviceId),
        refetchOnWindowFocus: false,
    });

    // get numbers available to be activated for the service
    const { data: companyNumbers, isFetching: companyNumbersLoading } =
        useQuery({
            queryKey: ['companyNumbers', currentCompany],
            queryFn: async () => {
                const res = await axios.get(
                    `company/${currentCompany}/numbers`,
                );
                return res.data;
            },
            enabled: Boolean(currentCompany),
            onSuccess: async (data) => {
                // numbers avaialble to be activated
                setNumbersAvailable(data);
            },
            onError: (err) => {
                toast.error(err?.response?.data?.message);
            },
            refetchOnWindowFocus: false,
        });

    // get activated numbers for the service
    const { isFetching: serviceNumbersLoading } = useQuery({
        queryKey: ['serviceNumbers', serviceId],
        queryFn: async () => {
            const res = await axios.get(
                `service/vendordefined/${serviceId}/numbers`,
            );
            return res.data;
        },
        // service id, company numbers is not loading and company numbers is greater than 0, then fetch service numbers
        enabled:
            Boolean(serviceId) &&
            !companyNumbersLoading &&
            companyNumbers?.length > 0,
        onSuccess: async (data) => {
            if (numbersAvailable) {
                let ua = [...numbersAvailable];
                let numbersChosen = [];

                ua = ua.filter((number) => {
                    if (data.includes(number.id)) {
                        numbersChosen.push(number);
                        return false; // Exclude this number from numbersAvailable
                    }
                    return true; // Keep this number in numbersAvailable
                });

                // Push filtered available numbers
                setNumbersAvailable(ua);
                // Push chosen numbers into numbersChosen
                setNumbersChosen(numbersChosen);
                // numbers that are already activated
                setActivatedNumbers(numbersChosen);
            }
        },
        onError: (err) => {
            toast.error(err?.response?.data?.message);
        },
        refetchOnWindowFocus: false,
    });

    // from chosen to available
    const handleMoveToAvailable = () => {
        setNumbersAvailable((prev) => [...prev, ...checkedChosen]);
        setNumbersChosen((all) => differenceBy(all, checkedChosen, 'id'));
        setCheckedChosen([]);
    };
    // from avaialble to chosen
    const handleMoveToChosen = () => {
        setNumbersChosen((prev) => [...prev, ...checkedAvailable]);
        setNumbersAvailable((all) => differenceBy(all, checkedAvailable, 'id'));
        setCheckedAvailable([]);
    };
    const handleCheckAvailableRow = (data) => {
        const rowSelected = checkedAvailable.some((a) => a.id == data.id);
        if (rowSelected) {
            setCheckedAvailable((prev) => prev.filter((v) => v.id !== data.id));
            return;
        }
        setCheckedAvailable((prev) => [...prev, data]);
    };

    const handleCheckChosenRow = (data) => {
        const rowSelected = checkedChosen.some((a) => a.id == data.id);
        if (rowSelected) {
            setCheckedChosen((prev) => prev.filter((v) => v.id !== data.id));
            return;
        }
        setCheckedChosen((prev) => [...prev, data]);
    };

    // all from chosen to available
    const handleMoveAllToAvailable = () => {
        setNumbersAvailable((prev) => [...prev, ...numbersChosen]);
        setNumbersChosen((all) => differenceBy(all, numbersChosen, 'id'));
        setCheckedChosen([]);
    };
    // all from available to chosen
    const handleMoveAllToChosen = () => {
        setNumbersChosen((prev) => [...prev, ...numbersAvailable]);
        setNumbersAvailable((all) => differenceBy(all, numbersAvailable, 'id'));
        setCheckedAvailable([]);
    };

    const handleBackButton = () => {
        setIsLoading(false);
        setActivateError({
            count: 0,
            message: '',
        });
        setDeactivateError({
            count: 0,
            message: '',
        });
        setTotal(0);
        setSuccess(0);
        setFailed(0);
        navigate('/dashboard-services');
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        let failedDeactivateNumbers = [];
        let failedActivateNumbers = [];
        try {
            let activateNumbers = differenceBy(
                numbersChosen,
                activatedNumbers,
                'id',
            );
            let deactivateNumbers = differenceBy(
                activatedNumbers,
                numbersChosen,
                'id',
            );

            setTotal(activateNumbers.length + deactivateNumbers.length);

            // activate service numbers
            for (const number of activateNumbers) {
                try {
                    await axios.post(
                        `/service/vendordefined/${serviceId}/number/${number.id}/activate`,
                    );
                    setSuccess((prev) => prev + 1);
                } catch (e) {
                    setFailed((prev) => prev + 1);
                    failedActivateNumbers.push(number);
                }
            }

            // deactivate service numbers
            for (const number of deactivateNumbers) {
                try {
                    await axios.post(
                        `/service/vendordefined/${serviceId}/number/${number.id}/deactivate`,
                    );
                    setSuccess((prev) => prev + 1);
                } catch (e) {
                    setFailed((prev) => prev + 1);
                    failedDeactivateNumbers.push(number);
                }
            }
        } catch (e) {
            toast.error(e?.response?.data?.message);
        } finally {
            // setIsLoading(false);
            if (failedActivateNumbers.length > 0) {
                let message = '';
                for (const [index, number] of failedActivateNumbers.entries()) {
                    if (index !== failedActivateNumbers.length - 1) {
                        message = message.concat(number.telephoneNumber + ', ');
                    } else {
                        message = message.concat(number.telephoneNumber + '.');
                    }
                }
                setActivateError((prevState) => ({
                    ...prevState,
                    count: failedActivateNumbers.length,
                    message: message,
                }));
            }

            if (failedDeactivateNumbers.length > 0) {
                let message = '';
                for (const [
                    index,
                    number,
                ] of failedDeactivateNumbers.entries()) {
                    if (index !== failedDeactivateNumbers.length - 1) {
                        message = message.concat(number.telephoneNumber + ', ');
                    } else {
                        message = message.concat(number.telephoneNumber + '.');
                    }
                }
                setDeactivateError((prevState) => ({
                    ...prevState,
                    count: failedDeactivateNumbers.length,
                    message: message,
                }));
            }
        }
    };

    return (
        <>
            <Heading fontSize="3xl" as="h2" mb={8}>
                <Box display={'flex'} flexDirection={'row'}>
                    Activate Service Numbers:
                    {serviceLoading ||
                    companyNumbersLoading ||
                    serviceNumbersLoading ? (
                        <Skeleton mt={1} w={200} h={8} ml={1} />
                    ) : (
                        <> {service?.name || 'Vendor Defined'}</>
                    )}
                </Box>
            </Heading>

            {isLoading ? (
                <ServiceStatus
                    title={'Numbers'}
                    failedCount={failed}
                    activateError={activateError}
                    deactivateError={deactivateError}
                    successCount={success}
                    totalCount={total}
                    handleBack={handleBackButton}
                />
            ) : (
                <>
                    <Box className="flex flex-row justify-center">
                        <VirtualizedServiceNumberTableProvider
                            data={numbersAvailable}
                            field={'telephoneNumber'}>
                            <VirtualizedServiceNumberTable
                                checkedArray={checkedAvailable}
                                onClick={handleCheckAvailableRow}
                                data={numbersAvailable}
                                captionName="Available Numbers"
                                placeholder="Search available numbers"
                                // dependant on service numbers, so track status, else it will load then list may change because of activated service numbers
                                isLoading={
                                    companyNumbersLoading ||
                                    serviceNumbersLoading
                                }
                                isDisabled={isLoading}
                            />
                            <Box className="flex flex-col justify-center gap-4 p-4">
                                <MoveButton
                                    isDisabled={
                                        checkedAvailable.length === 0 ||
                                        isLoading
                                    }
                                    dataWalkthroughId={
                                        '/service-profiles/button/move/chosen'
                                    }
                                    handleOnClick={handleMoveToChosen}>
                                    &gt;
                                    <BadgeWithCount
                                        count={checkedAvailable.length}
                                    />
                                </MoveButton>

                                <MoveButton
                                    isDisabled={
                                        checkedChosen.length === 0 || isLoading
                                    }
                                    dataWalkthroughId={
                                        '/service-profiles/button/move/available'
                                    }
                                    handleOnClick={handleMoveToAvailable}>
                                    &lt;
                                    <BadgeWithCount
                                        count={checkedChosen.length}
                                    />
                                </MoveButton>

                                <MoveButton
                                    isDisabled={isLoading}
                                    dataWalkthroughId={
                                        '/service-profiles/button/move/chosen/all'
                                    }
                                    handleOnClick={handleMoveAllToChosen}>
                                    &gt;&gt;
                                </MoveButton>

                                <MoveButton
                                    isDisabled={isLoading}
                                    dataWalkthroughId={
                                        '/service-profiles/button/move/available/all'
                                    }
                                    handleOnClick={handleMoveAllToAvailable}>
                                    &lt;&lt;
                                </MoveButton>
                            </Box>

                            <VirtualizedServiceNumberTable
                                checkedArray={checkedChosen}
                                onClick={handleCheckChosenRow}
                                data={numbersChosen}
                                captionName="Chosen Numbers"
                                placeholder="Search chosen numbers"
                                // dependant on company numbers, so track status, else it will not show skeleton
                                isLoading={
                                    serviceNumbersLoading ||
                                    companyNumbersLoading
                                }
                                isDisabled={isLoading}
                            />
                        </VirtualizedServiceNumberTableProvider>
                    </Box>

                    <Box className="mt-12 flex justify-center">
                        <Button
                            className="mr-14"
                            variant="outline"
                            w="84px"
                            isDisabled={isLoading}
                            onClick={handleBackButton}
                            data-walkthroughid={
                                '/service-profiles/button/back'
                            }>
                            Back
                        </Button>
                        <Button
                            className="ml-14"
                            w="84px"
                            isLoading={isLoading}
                            onClick={handleSubmit}
                            data-walkthroughid={
                                '/service-profiles/button/save'
                            }>
                            Save
                        </Button>
                    </Box>
                </>
            )}
        </>
    );
}
