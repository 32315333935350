import {Heading} from '@chakra-ui/react';

function TpmHeader() {
    return (
        <Heading as="h1" className="!font-semibold">
            Teams Phone Mobile
        </Heading>
    );
}

export default TpmHeader;
