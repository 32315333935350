import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useLocation,
    useNavigate,
    Navigate,
    useParams,
} from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import Button from 'components/v4/Button';
import Grid from 'components/v4/Grid';
import Input from 'components/v4/Input';
import FormLabel from 'components/v4/FormLabel';
import { Select as MultiSelect } from 'chakra-react-select';
import { multiSelectDataMapper, query } from 'utils/utils';
import Heading from 'components/v4/Heading';
import Alert from 'components/v4/Alert';
import AlertDescription from 'components/v4/AlertDescription';
import AlertIcon from 'components/v4/AlertIcon';
import LoadingIndicator from 'components/v4/LoadingIndicator';

import useFieldValidation from 'hooks/useFieldValidation';
import FieldValidation from 'components/v4/FieldValidation';

import { axios } from '@/services/axios';
import style from 'pages/services/serviceprofiles/edit/styles';
import FooterContainer from 'pages/services/serviceprofiles/edit/FooterContainer';
import { validationSchemaOCC } from 'pages/services/serviceprofiles/edit/schema';
import { chakraSelectDarkMode, defaultChakraSelectStyle } from '@/constants';

const DEFAULT_OCC_PAYLOAD = {
    name: 'Operator Connect Conferencing',
    callingProfiles: [],
};

export default function OCCEditService() {
    const { state: data } = useLocation();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();

    const [callingProfiles, setCallingProfiles] = useState([]);

    const { currentCompany: companyID } = useSelector(
        (state) => state.navigationLists,
    );

    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);
    const chakraStyles = {
        outlineColor: 'gray.300',
        ...defaultChakraSelectStyle,
        ...chakraSelectDark,
    };

    const [modalState, setModalState] = useState(data || DEFAULT_OCC_PAYLOAD);
    const { validationErrors, validatefn } = useFieldValidation(
        modalState,
        validationSchemaOCC,
    );

    const handleGetDropdownData = async () => {
        setIsLoading(true);
        try {
            const result = await axios.get(`/service/${modalState?.id}`);
            const callingProfiles = result.data?.callingProfiles?.map(
                multiSelectDataMapper,
            );

            setModalState({ ...result.data, callingProfiles });

            const cpResult = await axios.get(
                `/Services/OperatorConnectConferencing/Company/${companyID}/Profile/${result.data.operatorProfileID}/CallingProfiles/Available`,
            );
            setCallingProfiles(cpResult.data.map(multiSelectDataMapper));
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleGetDropdownData();
    }, []);

    const handleSubmit = async () => {
        setError(null);
        //validate
        if (!validatefn()) {
            setError({
                response: {
                    data: {
                        message:
                            'There are some validation errors in the form. Please check and try again.',
                    },
                },
            });
            return;
        }
        setIsLoading(true);
        const payload = {
            ...modalState,
            companyID,
        };
        await axios
            .put('/Service/OperatorConnectConferencing', payload)
            .then(() => {
                handleBackButton();
            })
            .catch((e) => setError(e))
            .finally(() => setIsLoading(false));
    };

    // function to set modal state with a given payload
    const handleChangeState = (payload) => {
        setModalState((prev) => ({
            ...prev,
            ...payload,
        }));
    };

    const handleBackButton = () => {
        navigate('/dashboard-services');
    };

    if (!id) {
        return <Navigate to="/dashboard-services" />;
    }

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Box sx={{ maxWidth: '2xl', minWidth: 'xl', margin: 'auto' }}>
            <Heading fontSize="3xl" as="h2" sx={{ marginBottom: '10px' }}>
                Edit {modalState.name}
            </Heading>

            {error && (
                <Alert status="error" sx={{ marginTop: '1rem' }}>
                    <AlertIcon />
                    <AlertDescription>
                        {error?.response?.data?.message ||
                            'An error occured. Please try again later.'}
                    </AlertDescription>
                </Alert>
            )}
            {modalState?.callingProfiles && callingProfiles && (
                <Box sx={style.fieldContainer}>
                    <FormLabel sx={style.label} pt={4}>
                        {' '}
                        Calling Profiles{' '}
                    </FormLabel>
                    <MultiSelect
                        isMulti
                        placeholder="- Select Calling Profiles -"
                        closeMenuOnSelect={false}
                        tagVariant="solid"
                        onChange={(selectedOptions) => {
                            handleChangeState({
                                callingProfiles: selectedOptions,
                            });
                        }}
                        options={callingProfiles}
                        defaultValue={modalState.callingProfiles}
                        chakraStyles={chakraStyles}
                        selectedOptionColor="brand"
                    />
                    <FieldValidation
                        errors={validationErrors?.callingProfiles}
                    />
                </Box>
            )}

            <FooterContainer>
                <Button
                    variant="outline"
                    sx={{ background: 'white' }}
                    onClick={handleBackButton}>
                    Close
                </Button>
                <Button onClick={handleSubmit}>Update</Button>
            </FooterContainer>
        </Box>
    );
}
