import { createContext, useContext, useMemo, useReducer } from 'react';
import {
    initialState,
    useDefinedAttributesReducer,
} from './useDefinedAttributesReducer';

export const DefinedAttributesContext = createContext(null);

export const DefinedAttributesProvider = (props) => {
    const [state, dispatch] = useReducer(
        useDefinedAttributesReducer,
        initialState,
    );
    const { attributesList, attributesErrors } = state;

    const value = useMemo(
        () => ({
            dispatch,
            attributesErrors,
            attributesList,
        }),
        [dispatch, attributesErrors, attributesList],
    );

    return <DefinedAttributesContext.Provider value={value} {...props} />;
};

export const useDefinedAttributes = () => {
    const context = useContext(DefinedAttributesContext);
    if (context === undefined) {
        throw new Error(
            `useDefinedAttributes must be used within a DefinedAttributesProvider`,
        );
    }

    return context;
};
