export const invoiceFormatList = [
    {
        title: 'CDR',
        contents: [
            {
                fields: {
                    label: 'CDR Detail Fields',
                    value: 'cdrDetailFields',
                },
                format: {
                    label: 'CDR Detail Format',
                    value: 'cdrDetailFormat',
                    defaultValue: 'cdrDetailDefaultFormat',
                },
            },

            {
                fields: {
                    label: 'CDR Summary Fields',
                    value: 'cdrSummaryFields',
                },
                format: {
                    label: 'CDR Summary Format',
                    value: 'cdrSummaryFormat',
                    defaultValue: 'cdrSummaryDefaultFormat',
                },
            },
        ],
    },
    {
        title: 'Number Block',
        contents: [
            {
                fields: {
                    label: 'Number Block Detail Fields',
                    value: 'numberBlockDetailFields',
                },
                format: {
                    label: 'Number Block Detail Format',
                    value: 'numberBlockDetailFormat',
                    defaultValue: 'numberBlockDetailDefaultFormat',
                },
            },

            {
                fields: {
                    label: 'Number Block Porting Detail Fields',
                    value: 'numberBlockPortingDetailFields',
                },
                format: {
                    label: 'Number Block Porting Detail Format',
                    value: 'numberBlockPortingDetailFormat',
                    defaultValue: 'numberBlockPortingDetailDefaultFormat',
                },
            },
            {
                fields: {
                    label: 'Number Block Setup Detail Fields',
                    value: 'numberBlockSetupDetailFields',
                },
                format: {
                    label: 'Number Block Setup Detail Format',
                    value: 'numberBlockSetupDetailFormat',
                    defaultValue: 'numberBlockSetupDetailDefaultFormat',
                },
            },

            {
                fields: {
                    label: 'Number Block Summary Fields',
                    value: 'numberBlockSummaryFields',
                },
                format: {
                    label: 'Number Block Summary Format',
                    value: 'numberBlockSummaryFormat',
                    defaultValue: 'numberBlockSummaryDefaultFormat',
                },
            },
        ],
    },
    {
        title: 'RGS',
        contents: [
            {
                fields: {
                    label: 'RGS Detail Fields',
                    value: 'rgsDetailFields',
                },
                format: {
                    label: 'RGS Detail Format',
                    value: 'rgsDetailFormat',
                    defaultValue: 'rgsDetailDefaultFormat',
                },
            },

            {
                fields: {
                    label: 'RGS Setup Detail Fields',
                    value: 'rgsSetupDetailFields',
                },
                format: {
                    label: 'RGS Setup Detail Format',
                    value: 'rgsSetupDetailFormat',
                    defaultValue: 'rgsSetupDetailDefaultFormat',
                },
            },

            {
                fields: {
                    label: 'RGS Summary Fields',
                    value: 'rgsSummaryFields',
                },
                format: {
                    label: 'RGS Summary Format',
                    value: 'rgsSummaryFormat',
                    defaultValue: 'rgsSummaryDefaultFormat',
                },
            },
        ],
    },

    {
        title: 'User',
        contents: [
            {
                fields: {
                    label: 'User Detail Fields',
                    value: 'userDetailFields',
                },
                format: {
                    label: 'User Detail Format',
                    value: 'userDetailFormat',
                    defaultValue: 'userDetailDefaultFormat',
                },
            },

            {
                fields: {
                    label: 'User Setup Detail Fields',
                    value: 'userSetupDetailFields',
                },
                format: {
                    label: 'User Setup Detail Format',
                    value: 'userSetupDetailFormat',
                    defaultValue: 'userSetupDetailDefaultFormat',
                },
            },

            {
                fields: {
                    label: 'User Summary Fields',
                    value: 'userSummaryFields',
                },
                format: {
                    label: 'User Summary Format',
                    value: 'userSummaryFormat',
                    defaultValue: 'userSummaryDefaultFormat',
                },
            },
        ],
    },
];
