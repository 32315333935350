import { FormControl } from '@chakra-ui/react';

/**
 * @typedef {Object} ChakraFormControlProps
 * @property {import('react').ReactNode} children
 * @param {ChakraFormControlProps & import('@chakra-ui/react').FormControlProps} props
 * @param {import('@chakra-ui/react').FormControlProps} props.rest
 * @returns
 */
export default function ChakraFormControl({ children, ...rest }) {
    return <FormControl {...rest}>{children}</FormControl>;
}
