import store from '@/store/store';
import { redirect } from 'react-router-dom';

function getCurrentStoreState() {
    const currentState = store.getState();
    return currentState;
}

function getAccessToken() {
    const token = store.getState().login?.token;
    return token;
}

function handleGoBack(navigate, pathname, parentPath) {
    if (pathname.split('/').length > 2) {
        navigate(`/${parentPath}`);
    } else {
        navigate(-1);
    }
}

function isLoggedIn() {
    const currentState = getCurrentStoreState();

    const isLoggedIn =
        JSON.parse(
            window.localStorage.getItem(import.meta.env.VITE_APP_LOGIN_SECRET),
        )?.loggedIn === 'true' || currentState.login.loggedIn;
    return isLoggedIn;
}

function isRequiredMFA() {
    const currentState = getCurrentStoreState();

    const isRequiredMFA =
        JSON.parse(
            window.localStorage.getItem(import.meta.env.VITE_APP_LOGIN_SECRET),
        )?.showMFA === 'true' || currentState.login.showMFA;
    console.log('currentState.login.showMFA', isRequiredMFA);
    return isRequiredMFA;
}

function requiredLoggedIn() {
    if (!isLoggedIn()) {
        throw redirect('/');
    }
}

export {
    store,
    getCurrentStoreState,
    getAccessToken,
    handleGoBack,
    isLoggedIn,
    isRequiredMFA,
    requiredLoggedIn,
};
