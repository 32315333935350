import { useState } from 'react';

export function useMultiNationalCode() {
    const multiNationalCodePattern = /^\d+$/;
    const [multiNationalCodes, setMultiNationalCodes] = useState([]);
    const [multiNationalCodeInput, setMultiNationalCodeInput] = useState('');
    const [multiNationalCodeError, setMultiNationalCodeError] = useState({
        isError: false,
        message: '',
        duplicates: ''
    });

    //#region National Codes
    const addMultiNationalCodes = (multiNationalCodesToAdd) => {
        const newMultiNationalCodes = [...multiNationalCodes, ...multiNationalCodesToAdd];

        setMultiNationalCodes(newMultiNationalCodes);
        setMultiNationalCodeInput('');
    };

    const removeMultiNationalCode = (multiNationalCode) => {
        const index = multiNationalCodes.findIndex((e) => e === multiNationalCode);
        if (index !== -1) {
            const newMultiNationalCodes = [...multiNationalCodes];
            newMultiNationalCodes.splice(index, 1);
            setMultiNationalCodes(newMultiNationalCodes);
        }
    };

    const handleMultiNationalCodesChange = (e) => {
        setMultiNationalCodeError({ isError: false, message: '', duplicates: '' });
        setMultiNationalCodeInput(e.target.value.trim());
    };

    const handleMultiNationalCodesKeyDown = (e) => {
        if (['Enter', 'Tab', ','].includes(e.key)) {
            setMultiNationalCodeError({ isError: false, message: '', duplicates: '' });
            e.preventDefault();

            // check for duplicates
            if (multiNationalCodes.includes(multiNationalCodeInput)) {
                setMultiNationalCodeError((prevState) => ({
                    ...prevState,
                    isError: true,
                    duplicates: `Multi-National Code already exists ${multiNationalCodeInput}.`,
                }));
                return;
            }
            // check for format including empty string
            if (!multiNationalCodePattern.test(multiNationalCodeInput)) {
              setMultiNationalCodeError((prevState) => ({
                    ...prevState,
                    isError: true,
                    message: `Invalid Multi-National Code ${multiNationalCodeInput}.`,
                }));
                return;
            }
            // add value to list
            addMultiNationalCodes([multiNationalCodeInput]);
        }
    };

    const handleMultiNationalCodesPaste = (e) => {
        e.preventDefault();
        setMultiNationalCodeError({ isError: false, message: '', duplicates: '' });

        const pastedData = e.clipboardData.getData('text');
        // remove return and new line, split by comma and filter by empty string if exist
        const pastedMultiNationalCodes = pastedData
            .trim()
            .replace(/\s+/g, '')
            .split(',')
            .filter((value) => value !== '');

        let invalidIds = [];
        let duplicateIds = [];
        let allowedMultiNationalCodes = [];
        pastedMultiNationalCodes.map((multiNationalCode) => {
            // national code already exist, add to duplicate error message
            if ([...multiNationalCodes, ...allowedMultiNationalCodes].includes(multiNationalCodeInput)) {
                duplicateIds.push(multiNationalCode);
                return;
            }
            // national code does not match numeric format, add to invalid ids error message
            if (!multiNationalCodePattern.test(multiNationalCode)) {
                invalidIds.push(multiNationalCode);
                return;
            }
            allowedMultiNationalCodes.push(multiNationalCode);
        });

        addMultiNationalCodes(allowedMultiNationalCodes);

        let duplicateString = '';
        duplicateIds?.map((dup) => {
            // if last index, add it with a fullstop, else comma
            if (duplicateIds.indexOf(dup) === duplicateIds.length - 1) {
                duplicateString += dup + '.';
            } else {
                duplicateString += dup + ', ';
            }
        });
        if (duplicateString) {
            setMultiNationalCodeError((prevState) => ({
                ...prevState,
                isError: true,
                duplicates: 'Multi-National Code already exists ' + duplicateString,
            }));
        }

        let invalidString = '';
        invalidIds?.map((inv) => {
            // if last index, add it with a fullstop, else comma
            if (invalidIds.indexOf(inv) === invalidIds.length - 1) {
                invalidString += inv + '.';
            } else {
                invalidString += inv + ', ';
            }
        });

        if (invalidString) {
            setMultiNationalCodeError((prevState) => ({
                ...prevState,
                isError: true,
                message: 'Invalid Multi-National Code ' + invalidString,
            }));
        }
    };

    const handleMultiNationalCodesCloseClick = (multiNationalCode) => {
        removeMultiNationalCode(multiNationalCode);
    };
    //#endregion

    return {
        multiNationalCodes,
        setMultiNationalCodes,
        multiNationalCodeInput,
        multiNationalCodeError,
        handleMultiNationalCodesChange,
        handleMultiNationalCodesKeyDown,
        handleMultiNationalCodesPaste,
        handleMultiNationalCodesCloseClick,
    };
}
