// external imports
import React from 'react';
import { Box, Grid, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { MultiSelect } from '../../../../components';
import { getInputDarkStyle } from '@/constants';

/*
 * Component for editing a operator profile
 * Grid of options that have onChange handlers that set the state for submission
 */
const ProfilesEdit = (props) => {
    const dispatch = useDispatch();

    // state access
    const { state: modalState, stateError: modalStateError } = useSelector(
        (state) => {
            return {
                ...state.modal,
            };
        },
    );

    // current wholsaler from state
    const { currentWholesaler } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    const { darkMode } = useSelector((state) => state.settings);
    const inputDarkStyle = getInputDarkStyle(darkMode);
    const inputDarkImportantStyle = getInputDarkStyle(darkMode, true);

    // function to set modal state easily
    const setState = (payload) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload,
        });
    };

    return (
        <Box
            sx={{ flexGrow: 1, marginTop: 2 }}
            className="operator-connect-profiles">
            <Grid container spacing={2}>
                <Grid item xs={6} sx={inputDarkStyle}>
                    <TextField
                        sx={{ paddingTop: 0.6 }}
                        fullWidth
                        id="Profile Name"
                        InputLabelProps={{ shrink: true }}
                        type="string"
                        label="Profile Name"
                        value={modalState?.name}
                        size="small"
                        onChange={(e) => {
                            setState({
                                payload: {
                                    name: e,
                                },
                            });
                        }}
                        error={modalStateError?.name}
                        helperText={modalStateError?.name}
                    />
                </Grid>
                <Grid item xs={6} sx={inputDarkStyle}>
                    <MultiSelect
                        label="Regions"
                        fieldFetch={
                            '/wholesaler/' + currentWholesaler + '/regions/all'
                        }
                        dependency={currentWholesaler}
                        value={modalState?.regions}
                        placeholder="+"
                        setState={(e) =>
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    ...modalState,
                                    regions: e,
                                },
                            })
                        }
                        errorText={modalStateError?.regions}
                    />
                </Grid>
                <Grid item xs={6} sx={inputDarkStyle}>
                    <TextField
                        fullWidth
                        id="Client Secret"
                        InputLabelProps={{ shrink: true }}
                        type="string"
                        label="Client Secret"
                        value={modalState?.clientSecret}
                        size="small"
                        placeholder="Client Secret"
                        onChange={(e) => {
                            setState({
                                clientSecret: e.target.value,
                            });
                        }}
                        error={modalStateError?.clientSecret}
                        helperText={modalStateError?.clientSecret}
                    />
                </Grid>
                <Grid item xs={6} sx={inputDarkStyle}>
                    <TextField
                        fullWidth
                        id="Carrier Name"
                        InputLabelProps={{ shrink: true }}
                        type="string"
                        label="Carrier Name"
                        value={modalState?.carrierName}
                        size="small"
                        onChange={(e) => {
                            setState({
                                payload: {
                                    carrierName: e,
                                },
                            });
                        }}
                        error={modalStateError?.carrierName}
                        helperText={modalStateError?.carrierName}
                    />
                </Grid>
                <Grid item xs={6} sx={inputDarkStyle}>
                    <TextField
                        fullWidth
                        id="App ID"
                        InputLabelProps={{ shrink: true }}
                        type="string"
                        label="App ID"
                        value={modalState?.appID}
                        size="small"
                        onChange={(e) => {
                            setState({
                                payload: {
                                    appID: e,
                                },
                            });
                        }}
                        error={modalStateError?.appID}
                        helperText={modalStateError?.appID}
                    />
                </Grid>
                <Grid item xs={6} sx={inputDarkStyle}>
                    <TextField
                        fullWidth
                        id="Tenant ID"
                        InputLabelProps={{ shrink: true }}
                        type="string"
                        label="Tenant ID"
                        value={modalState?.tenantID}
                        size="small"
                        onChange={(e) => {
                            setState({
                                payload: {
                                    tenantID: e,
                                },
                            });
                        }}
                        error={modalStateError?.tenantID}
                        helperText={modalStateError?.tenantID}
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sx={{
                        '.operator-connect-profiles': {
                            '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled':
                                {
                                    color: darkMode
                                        ? '#FFFFFF80!important'
                                        : null,
                                },
                            fieldset: {
                                borderColor: darkMode
                                    ? '#545454!important'
                                    : null,
                                color: darkMode ? '#FFFFFF80!important' : null,
                            },
                        },
                    }}>
                    <TextField
                        fullWidth
                        id="Last Sync"
                        InputLabelProps={{ shrink: true }}
                        disabled
                        type="string"
                        label="Last Sync"
                        value={modalState?.lastSync}
                        size="small"
                    />
                </Grid>
                <Grid item xs={6} sx={inputDarkImportantStyle}>
                    <TextField
                        fullWidth
                        disabled
                        id="Total Consents"
                        InputLabelProps={{ shrink: true }}
                        type="string"
                        value={modalState?.totalConsents}
                        label="Total Consents"
                        size="small"
                    />
                </Grid>
                <Grid item xs={6} sx={inputDarkImportantStyle}>
                    <TextField
                        fullWidth
                        disabled
                        id="Status"
                        InputLabelProps={{ shrink: true }}
                        type="string"
                        value={modalState?.status}
                        label="Status"
                        size="small"
                    />
                </Grid>
                <Grid item xs={12} sx={inputDarkImportantStyle}>
                    <TextField
                        fullWidth
                        id="Calling Profiles"
                        InputLabelProps={{ shrink: true }}
                        type="string"
                        label="Calling Profiles"
                        multiline
                        rows={4}
                        value={modalState?.callingProfiles}
                        size="small"
                        disabled
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ProfilesEdit;
