import {createContext, useContext, useState, useCallback} from 'react';

const VirtualizedTableContext = createContext();

export const VirtualizedServiceNumberTableProvider = ({children, data, field}) => {

    const [filteredData, setFilteredData] = useState([]);
    const [search, setSearch] = useState('');
    
    const handleChangeSearch = (e) => {
        setSearch(e);
        const newFilteredData =
            e.target.value.length === 0
                ? data
                : data.filter((v) => v[field] === search);
        setFilteredData(newFilteredData);
    };

    const value = {filteredData, search, setFilteredData, handleChangeSearch};

    return (
        <VirtualizedTableContext.Provider value={value}>
            {children}
        </VirtualizedTableContext.Provider>
    );
};

export const useVirtualizedTable = () => {
    const context = useContext(VirtualizedTableContext);
    if (!context) {
        throw new Error(
            'useVirtualizedTable must be used within a VirtualizedTableProvider',
        );
    }
    return context;
};
