import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, IconButton, Tooltip } from '@chakra-ui/react';
import { InfoIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';

import Text from 'components/v4/Text';

import { SERVICE_STATUS_HAS_PDF } from '../constants';

export default function OperatorConnectStatus({
    serviceObj,
    cardStatus,
    authData,
    setCardStatus,
}) {
    const { darkMode } = useSelector((state) => state.settings);

    useEffect(() => {
        if (!serviceObj) return;
        if (authData?.status !== 0 && authData?.engineMessage) {
            const hasPDFLink = Object.entries(SERVICE_STATUS_HAS_PDF).find(
                ([errorMsg]) =>
                    authData?.engineMessage
                        ?.toLowerCase()
                        .includes(errorMsg.toLowerCase()),
            );
            const pdfLink = hasPDFLink && hasPDFLink[1];
            setCardStatus({
                color: 'yellow',
                isAnimated: false,
                hasStripes: false,
                status: authData?.engineMessage,
                tooltip: true,
                to: pdfLink?.payload,
            });
            return;
        }

        // Server status
        if (
            serviceObj.status === 0 &&
            serviceObj.statusDisplay.statusDisplayDetail ===
                'Healthy (Pending Consent)'
        ) {
            setCardStatus({
                color: 'green',
                isAnimated: true,
                hasStripes: true,
                status: 'Healthy (Pending Consent)',
            });
        } else if (serviceObj.status === 0) {
            setCardStatus({
                color: 'green',
                isAnimated: false,
                hasStripes: false,
                status: 'Healthy',
            });
        } else if (serviceObj.status === 2) {
            setCardStatus({
                color: 'yellow',
                isAnimated: false,
                hasStripes: false,
                status: 'Access Required',
            });
        }
    }, [serviceObj]);

    if (!serviceObj) return <Text>...</Text>;

    if (cardStatus?.tooltip) {
        return (
            <Box
                sx={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                <Text>Degraded</Text>

                <span className="inline-flex self-center flex-shrink-0 mx-1">
                    <Tooltip
                        label={cardStatus?.status}
                        hasArrow
                        placement="bottom">
                        <IconButton
                            icon={<InfoIcon />}
                            size="xs"
                            bg="transparent"
                            _hover={{ bg: 'transparent' }}
                        />
                    </Tooltip>
                </span>

                {/* NOTE: for now leave the guide to AppAuth only - Alby
                {cardStatus?.to && (
                    <span className="inline-flex self-center flex-shrink-0 my-auto">
                        <Link
                            to={cardStatus?.to}
                            target="_blank"
                            className="hover:bg-inherit hover:text-inherit">
                            <IconButton
                                icon={<ExternalLinkIcon />}
                                size="xs"
                                variant="outline"
                                _hover={{
                                    bg: darkMode
                                        ? 'rgba(255, 255, 255, 0.16)'
                                        : 'gray.100',
                                }}
                                _active={{
                                    bg: darkMode ? 'gray.400' : 'gray.200',
                                }}
                            />
                        </Link>
                    </span>
                )}
                            */}
            </Box>
        );
    }

    return <Text>{cardStatus.status}</Text>;
}
