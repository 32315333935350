import { useState } from 'react';
import {
    Box,
    FormControl,
    FormErrorMessage,
    // Input,
    Td,
    Tr,
    Text,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { CheckCircleIcon } from '@chakra-ui/icons';

import { Input } from 'components/v4';

import Button from 'components/v4/Button';
import { useTpmContext } from './';
import TelephoneNumberList from './constants';
import walkthroughIds from './walkthroughIds';
import {
    postRequestNumberVerification,
    postSubmitNumberVerification,
} from 'API/TPMTrial';

function TpmTrialTableBody({ setError }) {
    // const [telephoneNumbers, setTelephoneNumbers] = useState([
    //     {number: '', action: 'IDLE', code: '', check: 'IDLE'},
    //     {number: '', action: 'IDLE', code: '', check: 'IDLE'},
    //     {number: '', action: 'IDLE', code: '', check: 'IDLE'},
    //     {number: '', action: 'IDLE', code: '', check: 'IDLE'},
    // ]);

    const { id } = useParams();

    const {
        tpmActivatedNumber: [telephoneNumbers, setTelephoneNumbers],
    } = useTpmContext();

    // const handleNumberInputCount = (count) => {
    //     for (number in telephoneNumbers) {
    //         if (number.check === 'SUCCESS') {
    //             setCount((prevCount) => prevCount + 1);
    //         }
    //     }
    // };

    const {
        ufTrialTableNumberInput,
        ufTrialTableNumberSendButton,
        ufTrialTableNumberCodeInput,
        ufTrialTableCheckButton,
    } = walkthroughIds.teamsPhoneMobileTrial;

    const handleNumberChange = (e, index) => {
        setTelephoneNumbers((prevState) => {
            const newTelephoneNumbers = [...prevState];
            newTelephoneNumbers[index].number = e.target.value;
            return newTelephoneNumbers;
        });
    };

    const handleSendButtonClick = async (index) => {
        setError('');
        // TODO: axios backend call to send MFA code.
        setTelephoneNumbers((prevState) => {
            const newTelephoneNumbers = [...prevState];
            newTelephoneNumbers[index].action = 'LOADING';
            return newTelephoneNumbers;
        });
        try {
            const res = await postRequestNumberVerification({
                serviceID: id,
                phoneNumber: telephoneNumbers[index].number,
            });
            setTelephoneNumbers((prevState) => {
                const newTelephoneNumbers = [...prevState];

                newTelephoneNumbers[index].action = 'SUCCESS';
                return newTelephoneNumbers;
            });
        } catch (e) {
            console.log('this isnt working', { e });
            setTelephoneNumbers((prevState) => {
                const newTelephoneNumbers = [...prevState];

                newTelephoneNumbers[index].action =
                    newTelephoneNumbers[index].action === 'SUCCESS'
                        ? newTelephoneNumbers[index].action
                        : 'IDLE';
                return newTelephoneNumbers;
            });
            const msg =
                e?.response?.data?.message ||
                'Error submitting request. Please try again.';
            setError(msg);
        }
    };

    const handleCodeChange = (e, index) => {
        setTelephoneNumbers((prevState) => {
            const newTelephoneNumbers = [...prevState];
            newTelephoneNumbers[index].code = e.target.value;
            return newTelephoneNumbers;
        });
    };

    const handleCheckChange = async (e, index) => {
        //TODO: axios backend call to check MFA code.
        setTelephoneNumbers((prevState) => {
            const newTelephoneNumbers = [...prevState];
            newTelephoneNumbers[index].check = 'LOADING';
            return newTelephoneNumbers;
        });
        try {
            const res = await postSubmitNumberVerification({
                serviceID: id,
                phoneNumber: telephoneNumbers[index].number,
                code: telephoneNumbers[index].code,
            });
            setTelephoneNumbers((prevState) => {
                const newTelephoneNumbers = [...prevState];

                newTelephoneNumbers[index].check = 'SUCCESS';

                return newTelephoneNumbers;
            });
        } catch (e) {
            setTelephoneNumbers((prevState) => {
                const newTelephoneNumbers = [...prevState];

                newTelephoneNumbers[index].check = 'FAIL';

                return newTelephoneNumbers;
            });
        }
    };

    // main function for logic of render table

    return telephoneNumbers.map((item, index) => {
        // TODO: remove when real BE is ready
        // const placeholderCondition = (item, index) => {
        //     const placeholder =
        //         item.action === 'SUCCESS'
        //             ? TelephoneNumberList[index].code
        //             : 'Enter Code';

        //     return placeholder;
        // };
        // const placeholder = placeholderCondition(item, index);
        const buttonAction =
            item.action === 'SUCCESS'
                ? 'Re-Send Code'
                : item.action === 'LOADING'
                ? 'Sending Code'
                : 'Send Code';

        return (
            <Tr
                key={index}
                sx={{
                    '.& td': {
                        marginY: 'auto',
                        height: '100px',
                    },
                }}>
                {item.check === 'SUCCESS' ? (
                    <>
                        <Td colSpan={3}>
                            <FormControl>
                                <Text>{item.number}</Text>
                            </FormControl>
                        </Td>

                        <Td>
                            <CheckCircleIcon color="green.500" fontSize="2xl" />
                        </Td>
                    </>
                ) : (
                    <>
                        <Td>
                            <FormControl
                                data-walkthroughid={ufTrialTableNumberInput}>
                                <Input
                                    onChange={(e) => {
                                        e.persist();
                                        handleNumberChange(e, index);
                                    }}
                                />
                            </FormControl>
                        </Td>
                        <Td>
                            <Box>
                                <Button
                                    className="!capitalize"
                                    w={{base: 'full', md: '120px'}}
                                    isDisabled={
                                        item.number.length < 1 ||
                                        item.action === 'LOADING'
                                    }
                                    isLoading={item.action === 'LOADING'}
                                    onClick={() => {
                                        // TODO mark resend
                                        handleSendButtonClick(index);
                                    }}
                                    data-walkthroughid={
                                        ufTrialTableNumberSendButton
                                    }>
                                    {buttonAction}
                                </Button>
                            </Box>
                        </Td>
                        <Td>
                            <FormControl
                                isInvalid={item.check === 'FAIL'}
                                data-walkthroughid={
                                    ufTrialTableNumberCodeInput
                                }>
                                <Input
                                    type="text"
                                    placeholder={'Enter 6 digit code'}
                                    onChange={(e) => {
                                        e.persist();
                                        handleCodeChange(e, index);
                                    }}
                                    isDisabled={item.action !== 'SUCCESS'}
                                    value={item.code}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter')
                                            handleCheckChange(e, index);
                                    }}
                                />
                                {item.check === 'FAIL' && (
                                    <FormErrorMessage>
                                        Your code is not correct!
                                    </FormErrorMessage>
                                )}
                            </FormControl>
                        </Td>
                        <Td>
                            <Box>
                                <Button
                                    isDisabled={item.code < 1}
                                    isLoading={item.check === 'LOADING'}
                                    onClick={(e) => handleCheckChange(e, index)}
                                    data-walkthroughid={
                                        ufTrialTableCheckButton
                                    }>
                                    Validate
                                </Button>
                            </Box>
                        </Td>
                    </>
                )}
            </Tr>
        );
    });
}

export default TpmTrialTableBody;