import React from 'react';
import styles from './styles';
import {Progress} from '@chakra-ui/react';

export default function DashboardServiceCardDrStatusbar({
    children,
    status,
    ...props
}) {
    return (
        <Progress
            {...props}
            value={100}
            sx={styles.verticalBar}
            colorScheme={status.color}
            hasStripe={status.hasStripes}
            isAnimated={status.isAnimated}
        />
    );
}
