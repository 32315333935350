import { useEffect, useMemo, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { Box, ChakraProvider, Image, Tooltip } from '@chakra-ui/react';
import { RangeDatepicker } from 'chakra-dayzed-datepicker';

import inbound from '../../../assets/images/incomingCall.png';
import inboundDarkMode from '../../../assets/images/incomingCallDarkMode.png';
import outbound from '../../../assets/images/outgoingCall.png';
import outboundDarkMode from '../../../assets/images/outgoingCallDarkMode.png';
import { Error, TableAndModal } from '../../../components';
import config from '../../../config.json';
import { renderTelephoneNumber } from '../../../utils/utils';

import chakratheme, {
    ChakraDatePickerDarkModeStyles,
    ChakraDatePickerStyles,
} from '@/chakratheme';
import { chakraDatepicker, chakraDatepickerDarkMode } from '@/constants';

/*
 * Calls Component that returns a pagenated and bufferd table with a to and from date
 * This to and from date can be chanegd and calls can be searched for
 */

import { nullError } from 'components/error';
import { Button } from 'components/v4';
import Grid from 'components/v4/Grid';
import walkthroughIds from './walkthroughIds.js';
import { useDeepMerge } from 'hooks/useDeepMerge';

// theme from material ui
const theme = {
    textColor: '#ffffff',
    fontSize: 11,
    axis: {
        domain: {
            line: {
                stroke: '#777777',
                strokeWidth: 1,
            },
        },
        ticks: {
            line: {
                stroke: '#777777',
                strokeWidth: 3,
            },
        },
    },
    grid: {
        line: {
            stroke: '#dddddd',
            strokeWidth: 1,
        },
    },
};

const Calls = () => {
    const dispatch = useDispatch();
    const tableRef = useRef();
    const today = new Date();
    const { theme } = useDeepMerge();
    const [from, setFrom] = useState(
        new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
            .toISOString()
            .substring(0, 10),
    );
    const [to, setTo] = useState(new Date().toISOString().substring(0, 10));
    // const [date, setDate] = useState(new Date());
    const [selectedDates, setSelectedDates] = useState([
        new Date(),
        new Date(),
    ]);

    // state access
    const { darkMode } = useSelector((state) => {
        return { ...state.settings };
    });
    const { currentCompany } = useSelector((state) => {
        return { ...state.navigationLists };
    });
    const { loading: tableLoading, state: tableState } = useSelector(
        (state) => {
            return { ...state.table };
        },
    );

    const { ...ufElements } = walkthroughIds;

    // default columns
    const defaultColumns = useMemo(
        () => [
            {
                title: 'Date & Time',
                field: 'dateTime',
                type: 'datetime',
                minWidth: 250,
                maxWidth: 300,
                defaultSort: 'desc',
            },
            {
                title: 'Source Number',
                field: 'sourceNumber',
                minWidth: 200,
                render: (rowData) =>
                    renderTelephoneNumber(rowData, 'sourceNumber'),
            },
            {
                title: 'Destination Number',
                field: 'destinationNumber',
                minWidth: 200,
                render: (rowData) =>
                    renderTelephoneNumber(rowData, 'destinationNumber'),
            },

            {
                title: 'Direction',
                field: 'direction',
                render: (rowData) => (
                    <ChakraProvider resetCSS={false} theme={theme}>
                        <Tooltip label={rowData.direction} hasArrow>
                            <Image
                                alt=""
                                src={
                                    rowData.direction == 'Outbound'
                                        ? darkMode
                                            ? outboundDarkMode
                                            : outbound
                                        : darkMode
                                          ? inboundDarkMode
                                          : inbound
                                }
                                boxSize={5}
                            />
                        </Tooltip>
                    </ChakraProvider>
                ),
                lookup: {
                    Outbound: 'Outbound',
                    Inbound: 'Inbound',
                },
                lookupDescription: {
                    Outbound: (
                        <img
                            data-walkthroughid={ufElements.ufPhoneIcon}
                            alt=""
                            src={darkMode ? outboundDarkMode : outbound}
                            width={20}
                            height={20}
                        />
                    ),
                    Inbound: (
                        <img
                            data-walkthroughid={ufElements.ufPhoneIcon}
                            alt=""
                            src={darkMode ? inboundDarkMode : inbound}
                            width={20}
                            height={20}
                        />
                    ),
                },
            },
            {
                title: 'Duration',
                field: 'duration',
                minWidth: 100,
            },
        ],
        [],
    );

    // TODO: check with local server
    useEffect(() => {
        if (selectedDates.length > 1) {
            setFrom(new Date(selectedDates[0]).toISOString().substring(0, 10));
            setTo(new Date(selectedDates[1]).toISOString().substring(0, 10));
        }
    }, [selectedDates]);

    // use effect to fetch the call detail record
    useEffect(() => {
        if (!currentCompany) return;
        if (tableLoading) {
            dispatch({
                type: 'INIT_WEB_WORKER_STATE',
                payload: {
                    fetchURL: `/calldetailrecords/${currentCompany}/${from}/${to}`,
                    attribute: 'callDetailRecords',
                },
            });
        }
    }, [currentCompany, tableLoading]);

    // onClick to fetch the call detail record
    const onClick = () => {
        dispatch({
            type: 'INIT_WEB_WORKER_STATE',
            payload: {
                fetchURL: `/calldetailrecords/${currentCompany}/${from}/${to}`,
                attribute: 'callDetailRecords',
            },
        });
    };

    // if no company is selected, return a alert
    if (!currentCompany) {
        return <Error error={nullError} />;
    }

    // container that holds this page
    return (
        <Container fluid>
            <ChakraProvider theme={chakratheme}>
                <Grid columns={2} gap={4} className="w-[600px]">
                    <Box
                        className="h-10"
                        sx={
                            darkMode
                                ? {
                                      '.chakra-popover__popper': {
                                          zIndex: 1201,
                                      },
                                      ...chakraDatepickerDarkMode,
                                  }
                                : {
                                      '.chakra-popover__popper': {
                                          zIndex: 1201,
                                      },
                                      ...chakraDatepicker,
                                  }
                        }
                        data-walkthroughid={ufElements.ufRangePickerInput}>
                        <RangeDatepicker
                            selectedDates={selectedDates}
                            onDateChange={setSelectedDates}
                            configs={{
                                dateFormat: ' ccc, dd MMM, yyyy',
                            }}
                            propsConfigs={
                                darkMode
                                    ? {
                                          ...ChakraDatePickerDarkModeStyles,
                                          inputProps: {
                                              size: 'sm',
                                              borderRadius: '4px',
                                              width: '100%',
                                              height: '40px',
                                          },
                                      }
                                    : {
                                          ...ChakraDatePickerStyles,
                                          inputProps: {
                                              size: 'sm',
                                              borderRadius: '4px',
                                              width: '100%',
                                              height: '40px',
                                          },
                                      }
                            }
                        />
                    </Box>
                    <Box>
                        <Button
                            onClick={onClick}
                            data-walkthroughid={ufElements.ufSearchButton}>
                            Search
                        </Button>
                    </Box>
                </Grid>
            </ChakraProvider>
            <TableAndModal
                tableRef={tableRef}
                overrideTableLoading
                title="Calls"
                noTitle
                defaultColumns={defaultColumns}
                columns={tableState?.data?.length > 0 ? defaultColumns : null}
                groupable
                filterable
                maxBodyHeight={
                    window.innerHeight * config.plainView.tableHeight
                }
            />
        </Container>
    );
};

export default Calls;
