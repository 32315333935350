import {
    createContext,
    useContext,
    useState,
    useMemo,
    useDebugValue,
} from 'react';

const DashboardMenuContext = createContext(null);
DashboardMenuContext.displayName = 'DashboardMenuContext';

export const DashboardMenuProvider = (props) => {
    const [isQueryDisabled, setIsQueryDisabled] = useState(false);

    const value = useMemo(
        () => ({
            isQueryDisabled,
            setIsQueryDisabled,
        }),
        [isQueryDisabled, setIsQueryDisabled],
    );

    return <DashboardMenuContext.Provider value={value} {...props} />;
};

export const useDashboardMenu = () => {
    const context = useContext(DashboardMenuContext);
    useDebugValue(context, `DashboardMenuContext`);
    if (context === undefined) {
        throw new Error(`useDashboardMenu must be used within a AuthProvider`);
    }

    return context;
};
