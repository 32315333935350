import {
    chakra,
    forwardRef,
    omitThemingProps,
    useMultiStyleConfig,
} from '@chakra-ui/react';

import { classNames as cx } from 'utils/classNames';
import { CardStylesProvider } from './card-context';
import { root, body, header, footer } from './constant';

/**
 * @typedef {Object} CardOptions
 * @property {import('@chakra-ui/react').SystemProps['flexDirection']} [direction] - The flex direction of the card. Valid values are
 *       similar to CSS flex-direction property (e.g., "row", "column", "row-reverse").
 * @property {import('@chakra-ui/react').SystemProps['alignItems']} [align] - The flex alignment of the card. Valid values are similar
 *       to CSS align-items property (e.g., "start", "center", "end", "stretch").
 * @property {import('@chakra-ui/react').SystemProps['justifyContent']} [justify] - The flex distribution of the card. Valid values are
 *       similar to CSS justify-content property (e.g., "start", "center", "end", "space-between").
 */

/**
 * @typedef {import('@chakra-ui/react').HTMLChakraProps<"div"> & CardOptions} CardProps
 * @type React.ForwardRefRenderFunction<HTMLDivElement, CardProps>
 * @returns {JSX.Element}
 */
export const CardRoot = forwardRef(function CardRoot(props, ref) {
    const {
        children,
        direction = 'column',
        justify,
        align,
        ...rest
    } = omitThemingProps(props);

    const styles = {
        ...useMultiStyleConfig('Card', props),
        root,
        body,
        header,
        footer,
    };

    return (
        <CardStylesProvider value={styles}>
            <chakra.div
                ref={ref}
                {...rest}
                className={cx('chakra-card', props.className)}
                flexDirection={direction}
                justifyContent={justify}
                alignItems={align}
                __css={styles.root}
            >
                {children}
            </chakra.div>
        </CardStylesProvider>
    );
});
