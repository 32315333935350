import React from 'react';
import {Row, Container, Col} from 'react-bootstrap';
import {TextField} from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';

import {onModalError} from '../../../../components/tables/TableAndModal';
import {LoadingFieldDropdown, MultiSelect} from '../../../../components';

/**
 *  Standard form for editing a wholesaler
 */
const General = () => {
    const dispatch = useDispatch();

    // modal data from state
    const {
        data: modalData,
        state,
        mode: modalMode,
        stateError: modalStateError,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // navigation data from state
    const {currentWholesaler} = useSelector((state) => {
        return {...state.navigationLists};
    });

    // return the form for editing a wholesaler
    return (
        <>
            <Container style={{marginTop: '2%'}}>
                {/* Name */}
                <Row style={{minHeight: 60}}>
                    <Col>
                        <TextField
                            fullWidth
                            label={'Name'}
                            placeholder="Name"
                            type="text"
                            error={Boolean(modalStateError?.name)}
                            helperText={modalStateError?.name}
                            value={state?.name}
                            onChange={(e) => {
                                dispatch({
                                    type: 'CHANGE_MODAL_STATE',
                                    payload: {
                                        name: e.target.value,
                                    },
                                });
                            }}
                        />
                    </Col>
                </Row>
                {modalMode === 'Add' && (
                    <>
                        {/* Default Plan */}
                        <Row style={{minHeight: 60}}>
                            <Col>
                                <LoadingFieldDropdown
                                    searchable
                                    fieldName="Default Plan"
                                    dropDownValue="id"
                                    onError={onModalError}
                                    displayField="name"
                                    helperText={
                                        'If there is no Default Plan set, this wholesaler will not be billed.'
                                    }
                                    onChange={(v) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                defaultPlanID: v,
                                            },
                                        });
                                    }}
                                    fieldValue={state?.defaultPlanID}
                                    toggleLimit={5}
                                    noEmptyOption
                                    fieldFetch={`/wholesaler/${currentWholesaler}/plans/available`}
                                />
                            </Col>
                        </Row>
                        {/* Business Number */}
                        <Row style={{minHeight: 60}}>
                            <Col>
                                <TextField
                                    fullWidth
                                    label={'Business Number'}
                                    placeholder="(ACN / ABN / Registration ID / Tax ID)"
                                    type="text"
                                    error={Boolean(
                                        modalStateError?.businessNumber,
                                    )}
                                    helperText={modalStateError?.businessNumber}
                                    value={state?.businessNumber}
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                businessNumber: e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        {/* Primary Contact Name */}
                        <Row style={{minHeight: 60}}>
                            <Col>
                                <TextField
                                    fullWidth
                                    label={'Primary Contact Name'}
                                    placeholder="Primary Contact Name"
                                    type="text"
                                    error={Boolean(
                                        modalStateError?.primaryContactName,
                                    )}
                                    helperText={
                                        modalStateError?.primaryContactName
                                    }
                                    value={state?.primaryContactName}
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                primaryContactName:
                                                    e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        {/* Primary Contact Email*/}
                        <Row style={{minHeight: 60}}>
                            <Col>
                                <TextField
                                    fullWidth
                                    label={'Primary Contact Email'}
                                    placeholder="Primary Contact Email"
                                    type="text"
                                    error={Boolean(
                                        modalStateError?.primaryContactEmail,
                                    )}
                                    helperText={
                                        modalStateError?.primaryContactEmail
                                    }
                                    value={state?.primaryContactEmail}
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                primaryContactEmail:
                                                    e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        {/* Primary Contact Phone */}
                        <Row style={{minHeight: 60}}>
                            <Col>
                                <TextField
                                    fullWidth
                                    label={'Primary Contact Phone'}
                                    placeholder="Primary Contact Phone"
                                    type="text"
                                    error={Boolean(
                                        modalStateError?.primaryContactPhone,
                                    )}
                                    helperText={
                                        modalStateError?.primaryContactPhone
                                    }
                                    value={state?.primaryContactPhone}
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                primaryContactPhone:
                                                    e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        {/* Accounts Contact Email */}
                        <Row style={{minHeight: 60}}>
                            <Col>
                                <TextField
                                    fullWidth
                                    label={'Accounts Contact Email'}
                                    placeholder="Accounts Contact Email"
                                    type="text"
                                    error={Boolean(
                                        modalStateError?.accountsContactEmail,
                                    )}
                                    helperText={
                                        modalStateError?.accountsContactEmail
                                    }
                                    value={state?.accountsContactEmail}
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                accountsContactEmail:
                                                    e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        {/* Support Contact Email */}
                        <Row style={{minHeight: 60}}>
                            <Col>
                                <TextField
                                    fullWidth
                                    label={'Support Contact Email'}
                                    placeholder="Support Contact Email"
                                    type="text"
                                    error={Boolean(
                                        modalStateError?.supportContactEmail,
                                    )}
                                    helperText={
                                        modalStateError?.supportContactEmail
                                    }
                                    value={state?.supportContactEmail}
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                supportContactEmail:
                                                    e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                    </>
                )}
                {modalMode !== 'Add' && (
                    <>
                        {/* Carriers */}
                        <Row style={{minHeight: 60}}>
                            <Col>
                                <MultiSelect
                                    label="Carriers"
                                    // placeholder="Name"
                                    onError={onModalError}
                                    fieldFetch={
                                        '/wholesaler/' +
                                        modalData?.id +
                                        '/carriers/all'
                                    }
                                    dependency={modalData?.id}
                                    value={state?.carriers}
                                    placeholder="+"
                                    setState={(v) =>
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                carriers: v,
                                            },
                                        })
                                    }
                                />
                            </Col>
                        </Row>
                        {/* Regions */}
                        <Row style={{minHeight: 60}}>
                            <Col>
                                <MultiSelect
                                    label="Regions"
                                    fieldFetch={
                                        '/wholesaler/' +
                                        modalData?.id +
                                        '/regions/all'
                                    }
                                    dependency={modalData?.id}
                                    value={state?.regions}
                                    placeholder="+"
                                    setState={(v) =>
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                regions: v,
                                            },
                                        })
                                    }
                                    onError={onModalError}
                                />
                            </Col>
                        </Row>
                        {/* Business Number */}
                        <Row style={{minHeight: 60}}>
                            <Col>
                                <TextField
                                    fullWidth
                                    label={'Company Registration Number'}
                                    placeholder="Company Registration Number"
                                    type="text"
                                    error={Boolean(
                                        modalStateError?.businessNumber,
                                    )}
                                    helperText={modalStateError?.businessNumber}
                                    value={state?.businessNumber}
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                businessNumber: e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        {/* Primary Contact Name */}
                        <Row style={{minHeight: 60}}>
                            <Col>
                                <TextField
                                    fullWidth
                                    label={'Primary Contact Name'}
                                    placeholder="Primary Contact Name"
                                    type="text"
                                    error={Boolean(
                                        modalStateError?.primaryContactName,
                                    )}
                                    helperText={
                                        modalStateError?.primaryContactName
                                    }
                                    value={state?.primaryContactName}
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                primaryContactName:
                                                    e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        {/* Primary Contact Email*/}
                        <Row style={{minHeight: 60}}>
                            <Col>
                                <TextField
                                    fullWidth
                                    label={'Primary Contact Email'}
                                    placeholder="Primary Contact Email"
                                    type="text"
                                    error={Boolean(
                                        modalStateError?.primaryContactEmail,
                                    )}
                                    helperText={
                                        modalStateError?.primaryContactEmail
                                    }
                                    value={state?.primaryContactEmail}
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                primaryContactEmail:
                                                    e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        {/* Primary Contact Phone */}
                        <Row style={{minHeight: 60}}>
                            <Col>
                                <TextField
                                    fullWidth
                                    label={'Primary Contact Phone'}
                                    placeholder="Primary Contact Phone"
                                    type="text"
                                    error={Boolean(
                                        modalStateError?.primaryContactPhone,
                                    )}
                                    helperText={
                                        modalStateError?.primaryContactPhone
                                    }
                                    value={state?.primaryContactPhone}
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                primaryContactPhone:
                                                    e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        {/* Accounts Contact Email */}
                        <Row style={{minHeight: 60}}>
                            <Col>
                                <TextField
                                    fullWidth
                                    label={'Accounts Contact Email'}
                                    placeholder="Accounts Contact Email"
                                    type="text"
                                    error={Boolean(
                                        modalStateError?.accountsContactEmail,
                                    )}
                                    helperText={
                                        modalStateError?.accountsContactEmail
                                    }
                                    value={state?.accountsContactEmail}
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                accountsContactEmail:
                                                    e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        {/* Support Contact Email */}
                        <Row style={{minHeight: 60}}>
                            <Col>
                                <TextField
                                    fullWidth
                                    label={'Support Contact Email'}
                                    placeholder={'Support Contact Email'}
                                    type="text"
                                    error={Boolean(
                                        modalStateError?.supportContactEmail,
                                    )}
                                    helperText={
                                        modalStateError?.supportContactEmail
                                    }
                                    value={state?.supportContactEmail}
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                supportContactEmail:
                                                    e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </Container>
        </>
    );
};

export default General;
