import { Spinner, ModalCloseButton } from '@chakra-ui/react';

import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalOverlay,
} from '@/components/v4';

const walkthroughIdModal = {
    ufCancelButton: '/dashboard-services/modal/override-limit/cancel',
    ufOverrideButton: '/dashboard-services/modal/override-limit/override',
};

function OverrideLimitModal({ isOpen, onClose, handleSubmit, status }) {
    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose} size="md">
            <ModalOverlay backdropFilter="blur(10px)" />
            <ModalContent as="form" onSubmit={handleSubmit}>
                <ModalHeader>Override Delete Limit</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <p className="text-base">
                        Are you sure you would like to override delete limit?{' '}
                    </p>
                    <p className="mt-2 text-xs text-gray-400">
                        This will allow the next sync process to remove more
                        than 50&#37; of the current users
                    </p>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="button"
                        className="modal-btn-secondary"
                        onClick={onClose}
                        data-walkthroughid={walkthroughIdModal.ufCancelButton}>
                        Cancel
                    </button>
                    <button
                        className="flex min-h-[38px] w-full items-center justify-center rounded-md bg-red-600 px-3 py-1.5 text-base font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                        type="submit"
                        data-walkthroughid={
                            walkthroughIdModal.ufOverrideButton
                        }>
                        {status === 'loading' ? (
                            <Spinner color="white.500" size="sm" />
                        ) : (
                            'Override'
                        )}
                    </button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default OverrideLimitModal;
