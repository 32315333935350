import { useSelector } from 'react-redux';
import {
    Box as ChakraBox,
    Button as ChakraButton,
    ChakraProvider,
    FormControl,
    ModalOverlay,
    Stack,
    Tooltip,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';

import {
    Modal,
    ModalContent,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Input,
    FormLabel,
    Checkbox as ChakraCheckbox,
} from '@/components/v4';

import chakratheme, {
    floatingLabelStyles as floatingStyle,
} from '@/chakratheme';

import { chakraSelectDarkMode, defaultChakraSelectStyle } from '@/constants';
export default function BillingNotesModal({
    isOpen,
    onClose,
    modal,
    desc,
    setDesc,
    scopes,
    setScope,
    locations,
    location,
    setLocation,
    isActive,
    setIsActive,
    onSubmitHandler,
}) {
    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);
    const floatingLabelStyles = floatingStyle(darkMode);

    const chakraStyles = {
        ...defaultChakraSelectStyle,
        ...chakraSelectDark,
    };
    return (
        <ChakraProvider theme={chakratheme} resetCSS={false}>
            <Modal
                closeOnOverlayClick
                isCentered
                isOpen={isOpen}
                onClose={onClose}>
                <ModalOverlay />

                <ModalContent>
                    <ModalHeader className="pb-0">Billing Note</ModalHeader>
                    <span className="mb-4 mt-2 px-6 italic">
                        Please enter a billing note description
                    </span>
                    <form>
                        <ModalBody>
                            <Stack gap={1}>
                                <FormControl
                                    isRequired
                                    sx={floatingLabelStyles}>
                                    <Input
                                        placeholder=" "
                                        onChange={(e) => {
                                            setDesc(e.target.value);
                                        }}
                                        value={desc ?? ''}
                                        data-peer
                                        data-walkthroughid={
                                            modal.ufBillingNoteInput
                                        }
                                    />
                                    <FormLabel
                                        htmlForl="Billing Note"
                                        variant="floating">
                                        Billing Note
                                    </FormLabel>
                                </FormControl>
                                <FormControl
                                    data-walkthroughid={
                                        modal.ufRequiredScopeSelect
                                    }>
                                    <FormLabel
                                        fontSize="14px"
                                        htmlFor="requiredScope">
                                        Required Scope
                                    </FormLabel>
                                    <Select
                                        options={scopes}
                                        chakraStyles={chakraStyles}
                                        onChange={(e) => {
                                            setScope(e.value);
                                        }}
                                        defaultValue={scopes[0]}
                                        selectedOptionColor="brand"
                                    />
                                </FormControl>
                                <FormControl>
                                    <ChakraBox className="flex items-center">
                                        <ChakraCheckbox
                                            isChecked={isActive}
                                            onChange={(e) =>
                                                setIsActive(e.target.checked)
                                            }
                                            colorScheme="whatsapp"
                                            name="isActive"
                                            mb={0}
                                        />
                                        <FormLabel mb={0} ml={2}>
                                            Active
                                        </FormLabel>
                                    </ChakraBox>
                                </FormControl>
                                <FormControl>
                                    <FormLabel fontSize="14px">
                                        Note Location
                                    </FormLabel>
                                    <Select
                                        chakraStyles={chakraStyles}
                                        options={locations}
                                        value={locations.find(
                                            (v) => v.value === location,
                                        )}
                                        defaultValue={locations[0]}
                                        onChange={(e) => setLocation(e.value)}
                                        selectedOptionColor="brand"
                                    />
                                </FormControl>
                            </Stack>
                        </ModalBody>
                        <ModalFooter justifyContent="space-evenly">
                            <ChakraButton
                                variant="outline"
                                backgroundColor="white"
                                w="100%"
                                type="button"
                                mr={8}
                                onClick={onClose}>
                                Close
                            </ChakraButton>
                            <ChakraButton
                                w="100%"
                                isDisabled={!desc}
                                onClick={onSubmitHandler}>
                                {!desc ? (
                                    <Tooltip label="Please enter a billing note description">
                                        Add
                                    </Tooltip>
                                ) : (
                                    'Add'
                                )}
                            </ChakraButton>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </ChakraProvider>
    );
}
