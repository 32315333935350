import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import {
    Button,
    Box,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import validator from 'validator';
import { axios } from '@/services/axios';
import tableIcons from 'utils/MaterialTableIcons';
import walkthroughIds from '../walkthroughIds';
import chakratheme from '@/chakratheme';
import {
    ChakraProvider,
    Checkbox as ChakraCheckbox,
    Box as ChakraBox,
    FormHelperText,
    FormControl,
    FormErrorMessage,
    FormLabel,
} from '@chakra-ui/react';
import { Input } from 'components/v4';
import { Select } from 'chakra-react-select';
import { defaultChakraSelectStyle, chakraSelectDarkMode } from '@/constants';

// Protocols for the protocol textfield drop down
const protocols = [
    { value: 'TCP', label: 'TCP' },
    { value: 'UDP', label: 'UDP' },
    { value: 'TLS', label: 'TLS' },
];

// value/label transform for service values
const serviceValues = [{ value: 'sip', label: 'SIP' }];

/*
 * Component for Termination endpoints
 * This works the same way as the collection hosts and ports, the form is more complex though
 * Results can either have mutliple entries if they are not a SRV record
 * If they are a SRV record then you can only ever have 1 entry
 * Rendering the Table and the modal form to add another one
 */
const EditSipTerminationEndpoint = () => {
    const muiTheme = useMemo(
        () =>
            createTheme({
                head: {
                    backgroundColor: null,
                },
                overrides: {
                    MuiToolbar: {
                        root: {
                            color: 'rgba(255, 255, 255, 0.7)',
                            backgroundColor: '#212121',
                        },
                    },
                    MuiTable: {
                        root: {
                            WebkitTextFillColor:
                                'rgba(255, 255, 255, 0.7) !important',
                            backgroundColor: '#212121 !important',
                        },
                    },
                    MuiTableHead: {
                        root: {
                            backgroundColor: '#212121 !important',
                        },
                    },
                    MuiTablePagination: {
                        root: {
                            backgroundColor: 'white',
                        },
                    },
                    MuiPaper: {
                        root: {
                            backgroundColor: null,
                        },
                    },
                    MuiIconButton: {
                        label: {
                            color: 'rgba(255, 255, 255, 0.3)',
                        },
                    },
                },
            }),
        [],
    );

    // columns for when the records are not a SRV record
    const terminationColumns = [
        {
            field: 'issrv',
            title: 'Is SRV?',
            width: '15%',
            editable: 'never',
            render: (rowdata) => (
                <ChakraProvider theme={chakratheme} resetCSS>
                    <ChakraCheckbox
                        sx={{
                            '> span': {
                                background: darkMode
                                    ? rowdata.issrv
                                        ? null
                                        : '#212121 !important'
                                    : null,
                                borderColor: darkMode
                                    ? 'rgba(255, 255, 255, 0.3) !important'
                                    : rowdata.issrv
                                      ? null
                                      : 'rgba(0,0,0, 0.26) !important',
                            },
                        }}
                        colorScheme="whatsapp"
                        paddingLeft={4}
                        pt={2}
                        isDisabled
                        isChecked={rowdata.issrv}
                    />
                </ChakraProvider>

                // <Checkbox disabled checked={rowdata.issrv} />
            ),
        },
        {
            field: 'record',
            title: 'Record',
            width: '40%',
            editComponent: (props) => {
                return (
                    <ChakraProvider theme={chakratheme} resetCSS>
                        <Input
                            className="w-full"
                            name="record"
                            size={'sm'}
                            value={props.value}
                            onChange={(e) => {
                                props.onChange(e.target.value);
                            }}
                            placeholder="Enter Record"
                        />
                    </ChakraProvider>
                    // <TextField
                    //     size="small"
                    //     variant="standard"
                    //     value={props.value}
                    //     onChange={(e) => {
                    //         props.onChange(e.target.value);
                    //     }}
                    // />
                );
            },
        },
        {
            field: 'port',
            title: 'Port',
            width: '15%',
            editComponent: (props) => {
                return (
                    <ChakraProvider theme={chakratheme} resetCSS>
                        <Input
                            className="w-full"
                            name="port"
                            size={'sm'}
                            type="number"
                            value={props.value}
                            onChange={(e) => {
                                props.onChange(e.target.value);
                            }}
                            placeholder="Enter Port"
                        />
                    </ChakraProvider>
                    // <TextField
                    //     type="number"
                    //     variant="standard"
                    //     size="small"
                    //     value={props.value}
                    //     onChange={(e) => {
                    //         props.onChange(e.target.value);
                    //     }}
                    // />
                );
            },
        },
        {
            field: 'protocol',
            title: 'Protocol',
            width: '20%',
            editComponent: (props) => {
                return (
                    <ChakraProvider theme={chakratheme} resetCSS>
                        <Select
                            className="w-full"
                            value={protocols.find(
                                (a) => a.value == props.value,
                            )}
                            options={protocols}
                            size="sm"
                            onChange={(e) => {
                                props.onChange(e.value);
                            }}
                            placeholder={'- Select Protocol -'}
                            chakraStyles={{
                                ...defaultChakraSelectStyle,
                                ...chakraSelectDark,
                            }}
                        />
                    </ChakraProvider>

                    // <TextField
                    //     select
                    //     size="small"
                    //     variant="standard"
                    //     value={props.value}
                    //     onChange={(e) => props.onChange(e.target.value)}>
                    //     {protocols.map((option) => (
                    //         <MenuItem key={option.label} value={option.value}>
                    //             {option.label}
                    //         </MenuItem>
                    //     ))}
                    // </TextField>
                );
            },
        },
    ];

    // columns for when the record is a SRV record
    const srvColumns = [
        {
            field: 'issrv',
            title: 'Is SRV?',
            width: '15%',
            editable: 'never',
            render: (rowdata) => (
                <ChakraProvider theme={chakratheme} resetCSS>
                    <ChakraCheckbox
                        sx={{
                            '> span': {
                                background: darkMode
                                    ? rowdata.issrv
                                        ? null
                                        : '#212121 !important'
                                    : null,
                                borderColor: darkMode
                                    ? 'rgba(255, 255, 255, 0.3) !important'
                                    : rowdata.issrv
                                      ? null
                                      : 'rgba(0,0,0, 0.26) !important',
                            },
                        }}
                        colorScheme="whatsapp"
                        paddingLeft={4}
                        pt={2}
                        isDisabled
                        isChecked={rowdata.issrv}
                    />
                </ChakraProvider>

                // <Checkbox disabled checked={rowdata.issrv} />
            ),
        },
        {
            field: 'record',
            title: 'Record',
            width: '40%',
            editComponent: (props) => {
                return (
                    <ChakraProvider theme={chakratheme} resetCSS>
                        <Input
                            className="w-full"
                            name="record"
                            size={'sm'}
                            value={props.value}
                            onChange={(e) => {
                                props.onChange(e.target.value);
                            }}
                            placeholder="Enter Record"
                        />
                    </ChakraProvider>
                    // <TextField
                    //     size="small"
                    //     variant="standard"
                    //     value={props.value}
                    //     onChange={(e) => {
                    //         props.onChange(e.target.value);
                    //     }}
                    // />
                );
            },
        },
        {
            field: 'service',
            title: 'Service',
            width: '30%',
            editComponent: (props) => {
                return (
                    <ChakraProvider theme={chakratheme} resetCSS>
                        <Input
                            className="w-full"
                            name="service"
                            size={'sm'}
                            value={props.value}
                            onChange={(e) => {
                                props.onChange(e.target.value);
                            }}
                            placeholder="Enter Service"
                        />
                    </ChakraProvider>
                    // <TextField
                    //     size="small"
                    //     variant="standard"
                    //     value={props.value}
                    //     onChange={(e) => {
                    //         props.onChange(e.target.value);
                    //     }}
                    // />
                );
            },
        },
        {
            field: 'protocol',
            title: 'Protocol',
            width: '15%',
            editComponent: (props) => {
                return (
                    <ChakraProvider theme={chakratheme} resetCSS>
                        <Select
                            className="w-full"
                            value={protocols.find(
                                (a) => a.value == props.value,
                            )}
                            options={protocols}
                            size="sm"
                            onChange={(e) => {
                                props.onChange(e.value);
                            }}
                            placeholder={'- Select Protocol -'}
                            chakraStyles={{
                                ...defaultChakraSelectStyle,
                                ...chakraSelectDark,
                            }}
                        />
                    </ChakraProvider>
                    // <TextField
                    //     variant="standard"
                    //     size="small"
                    //     select
                    //     value={props.value}
                    //     onChange={(e) => props.onChange(e.target.value)}>
                    //     {protocols.map((option) => (
                    //         <MenuItem key={option.label} value={option.value}>
                    //             {option.label}
                    //         </MenuItem>
                    //     ))}
                    // </TextField>
                );
            },
        },
    ];

    const [rows, setRows] = useState([]);
    const [errors, setErrors] = useState();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSRV, setIsSRV] = useState(false);
    const [record, setRecord] = useState();
    const [protocol, setProtocol] = useState('TCP');
    const [port, setPort] = useState(5060);
    const [service, setService] = useState('sip');
    const [checked, setChecked] = useState();
    const tableRef = useRef(MaterialTable);
    const dispatch = useDispatch();

    const { darkMode } = useSelector((state) => state.settings);

    const chakraSelectDark = chakraSelectDarkMode(darkMode);

    const { state } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    /*
     * Logic when you click the up arrow in the Termination List(rowData is the clicked row)
     * create variables for the reordering of the list, values above, values lower than, and the value to go up/down
     * oldData is the state of the current table -> for each row of the old table ->
     * If that row's id = the passed in rowData.id then it needs to be moved.
     * create array of things above, things below and the two values to be swapped
     * create new table state by combining these arrays and setting state to the new array
     */
    const moveUpHandler = (rowData) => {
        const oldData = tableRef.current.state.data;
        let valuesUnder = [];
        let valuesOver = [];
        let valueDown;
        let valueUp;
        let result = [];
        oldData.forEach((row) => {
            if (row.tableData.id > rowData.tableData.id) {
                valuesOver.push(row);
            } else if (row.tableData.id === rowData.tableData.id - 1) {
                valueDown = row;
            } else if (row.tableData.id === rowData.tableData.id) {
                valueUp = row;
            } else {
                valuesUnder.push(row);
            }
        });
        result = [...valuesUnder, valueUp, valueDown, ...valuesOver];
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                trunkTerminationEndPoints: result,
            },
        });
    };

    /*
     * Same logic as the move up handler ->
     * move down instead so the else if logic is slightly differnet
     */
    const moveDownHandler = (rowData) => {
        const oldData = tableRef.current.state.data;
        let valuesUnder = [];
        let valuesOver = [];
        let valueDown;
        let valueUp;
        let result = [];
        oldData.forEach((row) => {
            if (row.tableData.id < rowData.tableData.id) {
                valuesUnder.push(row);
            } else if (row.tableData.id === rowData.tableData.id) {
                valueDown = row;
            } else if (row.tableData.id === rowData.tableData.id + 1) {
                valueUp = row;
            } else {
                valuesOver.push(row);
            }
        });
        result = [...valuesUnder, valueUp, valueDown, ...valuesOver];
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                trunkTerminationEndPoints: result,
            },
        });
    };

    // logic for open and close
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setProtocol('TCP');
        setErrors(null);
        setIsSRV(false);
        setPort(5060);
        setService('sip');
        setChecked(false);
        setOpen(false);
        setRecord(null);
    };

    /*
     * Function that takes in row data and deletes it
     * The state value of colletion hosts is checked and the row passed into the function is removed
     * This new array is dispatched to state
     * The deletedEndpointIDs array is then deleted in Trunk.jsx using API calls
     */
    const deleteHandler = (row) => {
        let result = [];
        state.trunkTerminationEndPoints.forEach((v) => {
            if (
                v.issrv === row.issrv &&
                v.record === row.record &&
                v.service === row.service &&
                v.protocol === row.protocol &&
                v.port === row.port
            ) {
                dispatch({
                    type: 'CHANGE_MODAL_STATE',
                    payload: {
                        deletedEndpointIDs: [
                            {
                                id: v.id,
                            },
                            ...state.deletedEndpointIDs,
                        ],
                    },
                });
            } else {
                const temp = {
                    ...v,
                };
                result.push(temp);
            }
        });
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                trunkTerminationEndPoints: result,
            },
        });
    };

    /*
     * Submit Handler for API calls
     * Checks the state values have all the required fields for submission to the API
     * No fields can be null for the API call
     * differnet fields are rquired based on if its a SRV record or not
     */
    const onSubmitHandler = () => {
        let tempEndPoints;
        if (state.trunkTerminationEndPoints) {
            if (isSRV !== true) {
                tempEndPoints = [
                    ...state.trunkTerminationEndPoints,
                    {
                        trunkID: state.id,
                        index: 0,
                        issrv: isSRV,
                        record: record,
                        service: '',
                        protocol: protocol,
                        port: port,
                    },
                ];
            } else {
                tempEndPoints = [
                    {
                        trunkID: state.id,
                        index: 0,
                        issrv: isSRV,
                        record: record,
                        service: service,
                        protocol: protocol,
                        port: 0,
                    },
                ];
            }
        } else {
            if (isSRV === true) {
                tempEndPoints = [
                    {
                        trunkID: state.id,
                        index: 0,
                        issrv: isSRV,
                        record: record,
                        service: service,
                        protocol: protocol,
                        port: 0,
                    },
                ];
            } else {
                tempEndPoints = [
                    {
                        trunkID: state.id,
                        index: 0,
                        issrv: isSRV,
                        record: record,
                        service: '',
                        protocol: protocol,
                        port: port,
                    },
                ];
            }
        }
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                trunkTerminationEndPoints: tempEndPoints,
            },
        });
        setRecord(null);
        handleClose();
    };

    /*
     * If there is a state value for termination endpoints ->
     * Extract it and push it to the rows state
     */
    useEffect(() => {
        if (state.trunkTerminationEndPoints && loading === false) {
            let result = [];
            state.trunkTerminationEndPoints.forEach((v) => {
                const temp = {
                    ...v,
                };
                result.push(temp);
            });
            setRows([...result]);
        }
    }, [state.trunkTerminationEndPoints, loading]);

    /*
     * When the page first loads, run a API call and set the state/rows based on the return
     * If the mode is edit and termination endpoints is empty(ie no endpoints exist)
     * For each value, extract it to the format needed to display it and push to the new result
     * this new result gets dispatched to state
     */
    useEffect(() => {
        setLoading(true);
        if (state.trunkTerminationEndPoints?.length === 0) {
            axios.get('/trunk/' + state.id).then((res) => {
                let result = [];
                res.data.trunkTerminationEndPoints.forEach((v) => {
                    let p;

                    if (v.protocol === 0) {
                        p = 'TCP';
                    } else if (v.protocol === 1) {
                        p = 'UDP';
                    } else if (v.protocol === 2) {
                        p = 'TLS';
                    }
                    const temp = {
                        ...v,
                        ...{
                            protocol: p,
                        },
                    };
                    result.push(temp);
                });
                dispatch({
                    type: 'CHANGE_MODAL_STATE',
                    payload: {
                        trunkTerminationEndPoints: result,
                    },
                });
                setRows(result);
                setLoading(false);
            });
        } else {
            let result = [];
            state.trunkTerminationEndPoints.forEach((v) => {
                let p;
                if (v.protocol === 0) {
                    p = 'TCP';
                } else if (v.protocol === 'TCP') {
                    p = 'TCP';
                } else if (v.protocol === 1) {
                    p = 'UDP';
                } else if (v.protocol === 'UDP') {
                    p = 'UDP';
                } else if (v.protocol === 2) {
                    p = 'TLS';
                } else if (v.protocol === 'TLS') {
                    p = 'TLS';
                }
                result.push({ ...v, ...{ protocol: p } });
            });
            dispatch({
                type: 'CHANGE_MODAL_STATE',
                payload: {
                    trunkTerminationEndPoints: result,
                },
            });
            setRows(result);
            setLoading(false);
        }
    }, []);

    /*
     * Checks if the first record in the state is a srv
     * if it is the sets srv to true which disables the add button and changes the table render
     */
    useEffect(() => {
        if (state?.trunkTerminationEndPoints[0]?.issrv === true) {
            setIsSRV(true);
        } else {
            setIsSRV(false);
        }
    }, [state?.trunkTerminationEndPoints]);

    /*
     * Spread operators needed empty arrays or else they would break
     * This just sets some empty state values for the deleted/edited list
     */
    useEffect(() => {
        if (!state.deletedEndpointIDs) {
            dispatch({
                type: 'CHANGE_MODAL_STATE',
                payload: {
                    deletedEndpointIDs: [],
                },
            });
        }
        if (!state.editedEndpointIDs) {
            dispatch({
                type: 'CHANGE_MODAL_STATE',
                payload: {
                    editedEndpointIDs: [],
                },
            });
        }
    }, []);

    /*
     * Material Table with a dialogue to add a termination endpoint and editible fields
     * If a record is a SRV it needs Service, Protocol and Domain
     * If it is not a SRV you need to enter Protocol, Host/HQDN and Port
     * This is based on material table documentation
     */
    return (
        <ThemeProvider theme={darkMode ? muiTheme : null}>
            <Box
                sx={{
                    th: {
                        height: '80.5px',
                        backgroundColor: darkMode
                            ? '#212121 !important'
                            : 'white !important',
                    },
                    td: {
                        color: darkMode ? 'black !important' : null,
                    },
                    boxShadow: darkMode ? 1 : 0,
                    borderWidth: darkMode ? 1 : 0,
                    borderStyle: darkMode ? 'solid' : null,
                    borderColor: darkMode ? '#DDD' : null,
                }}>
                <MaterialTable
                    style={{ height: '100%' }}
                    tableRef={tableRef}
                    title="Termination Endpoints"
                    icons={tableIcons}
                    columns={isSRV ? srvColumns : terminationColumns}
                    data={rows}
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        sorting: true,
                        pageSize: 3,
                        pageSizeOptions: [],
                        draggable: false,
                    }}
                    actions={[
                        (rowData) => ({
                            icon: tableIcons.MoveDown,
                            tooltip: 'Decrease Priority',
                            onClick: (e, rowData) => {
                                moveDownHandler(rowData);
                            },
                            hidden: rowData.tableData.id === rows.length - 1,
                        }),
                        (rowData) => ({
                            icon: tableIcons.MoveUp,
                            tooltip: 'Increase Priority',
                            onClick: (e, rowData) => {
                                moveUpHandler(rowData);
                            },
                            hidden: rowData.tableData.id === 0,
                        }),
                        {
                            icon: tableIcons.Delete,
                            tooltip: 'Delete',
                            onClick: (e, rowData) => {
                                deleteHandler(rowData);
                            },
                        },
                        {
                            disabled: isSRV,
                            icon: tableIcons.Add,
                            tooltip: 'Add Endpoint',
                            isFreeAction: true,
                            onClick: () => {
                                handleOpen();
                            },
                        },
                    ]}
                    // Edit functionaltiy for inline editing of the termination endpoint
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const dataUpdate = [...rows];
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = newData;
                                    setRows([...dataUpdate]);
                                    let result = [];
                                    state.trunkTerminationEndPoints.forEach(
                                        (v) => {
                                            if (
                                                v.issrv === oldData.issrv &&
                                                v.record === oldData.record &&
                                                v.service === oldData.service &&
                                                v.protocol ===
                                                    oldData.protocol &&
                                                v.port === oldData.port &&
                                                v.priority === oldData.priority
                                            ) {
                                                // if one state value matches the old data - update this value
                                                const temp = {
                                                    ...newData,
                                                    id: v.id,
                                                };
                                                result.push(temp);
                                                dispatch({
                                                    type: 'CHANGE_MODAL_STATE',
                                                    payload: {
                                                        editedEndpointIDs: [
                                                            {
                                                                ...temp,
                                                            },
                                                            ...state.editedPortIDs,
                                                        ],
                                                    },
                                                });
                                            } else {
                                                // make sure to grab that host and keep it in state
                                                const temp = { ...v };
                                                result.push(temp);
                                            }
                                        },
                                    );
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            trunkTerminationEndPoints: result,
                                        },
                                    });
                                    resolve();
                                }, 1000);
                            }),
                    }}
                    localization={{
                        header: {
                            actions: '',
                        },
                    }}
                />
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                sx={{
                    '& .MuiPaper-root': {
                        bgcolor: darkMode ? '#212121' : null,
                        color: darkMode ? 'white' : null,
                    },
                }}>
                <DialogTitle>Termination Endpoints</DialogTitle>
                <DialogContent sx={{ width: 400 }}>
                    <ChakraProvider theme={chakratheme} resetCSS>
                        <FormControl>
                            <ChakraBox className="flex">
                                <ChakraCheckbox
                                    sx={{
                                        '> span': {
                                            background: darkMode
                                                ? checked
                                                    ? null
                                                    : '#212121 !important'
                                                : null,
                                            borderColor: darkMode
                                                ? 'rgba(255, 255, 255, 0.3) !important'
                                                : null,
                                        },
                                    }}
                                    size={'md'}
                                    colorScheme="whatsapp"
                                    isChecked={checked}
                                    onChange={(e) => {
                                        setErrors(null);
                                        setChecked(e.target.checked);
                                        setIsSRV(e.target.checked);
                                    }}
                                    data-walkthroughid={
                                        walkthroughIds.endpoints.ufSrvCheckbox
                                    }
                                />
                                <FormLabel fontWeight={'normal'} ml={3}>
                                    is SRV?
                                </FormLabel>
                            </ChakraBox>
                        </FormControl>

                        {/* <FormControlLabel
                        control={
                            <Checkbox
                            style={{color: darkMode ? 'rgba(255, 255, 255, 0.3)' : null}}
                                checked={checked}
                                onChange={(e) => {
                                    setErrors(null);
                                    setChecked(e.target.checked);
                                    setIsSRV(e.target.checked);
                                }}
                                data-walkthroughid={
                                    walkthroughIds.endpoints.ufSrvCheckbox
                                }
                            />
                        }
                        label="is SRV?"
                    /> */}

                        {isSRV && (
                            <FormControl>
                                <FormLabel color={darkMode ? 'white' : null}>
                                    Service
                                </FormLabel>
                                <ChakraBox
                                    mb={4}
                                    data-walkthroughid={
                                        walkthroughIds.endpoints.modal
                                            .ufServiceSelect
                                    }>
                                    <Select
                                        className="w-full"
                                        size="sm"
                                        placeholder={'- Select Service -'}
                                        chakraStyles={{
                                            ...defaultChakraSelectStyle,
                                            ...chakraSelectDark,
                                        }}
                                        value={serviceValues.find(
                                            (a) => a.value == service,
                                        )}
                                        onChange={(e) => {
                                            setErrors({ service: '' });
                                            setService(e.target.value);
                                            let reg = new RegExp(
                                                /^[a-zA-Z]/,
                                            ).test(e.target.value);
                                            if (!reg) {
                                                setErrors({
                                                    ...errors,
                                                    service:
                                                        'service can only be letters',
                                                });
                                            } else {
                                                setErrors(null);
                                            }
                                        }}
                                        options={serviceValues}
                                    />
                                </ChakraBox>
                            </FormControl>

                            // <TextField
                            //     select
                            //     error={errors?.service}
                            //     helperText={errors?.service}
                            //     sx={{ width: '100%', marginBottom: 2 }}
                            //     label="Service"
                            //     value={service}
                            //     >
                            //     {serviceValues.map((option) => (
                            //         <MenuItem
                            //             key={option.label}
                            //             value={option.value}>
                            //             {option.label}
                            //         </MenuItem>
                            //     ))}
                            // </TextField>
                        )}
                        <FormControl mb={4}>
                            <FormLabel color={darkMode ? 'white' : null}>
                                Protocol
                            </FormLabel>
                            <ChakraBox
                                data-walkthroughid={
                                    walkthroughIds.endpoints.modal
                                        .ufProtocolSelect
                                }>
                                <Select
                                    className="w-full"
                                    size={'sm'}
                                    placeholder={'- Select Protocol -'}
                                    chakraStyles={{
                                        ...defaultChakraSelectStyle,
                                        ...chakraSelectDark,
                                    }}
                                    value={protocols.find(
                                        (a) => a.value == protocol,
                                    )}
                                    onChange={(e) => {
                                        setProtocol(e.value);
                                    }}
                                    options={protocols}
                                />
                            </ChakraBox>
                        </FormControl>

                        {/* <TextField
                        select
                        sx={{ width: '100%', marginBottom: 2 }}
                        label="Protocol"
                        id="termination-protocol"
                        value={protocol}
                        onChange={(e) => setProtocol(e.target.value)}
                        data-walkthroughid={
                            walkthroughIds.endpoints.modal.ufProtocolSelect
                        }>
                        {protocols.map((option) => (
                            <MenuItem key={option.label} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField> */}

                        <FormControl
                            mb={4}
                            isInvalid={errors?.record}
                            isRequired>
                            <FormLabel color={darkMode ? 'white' : null}>
                                {isSRV ? 'Domain' : 'Host IP/FQDN'}
                            </FormLabel>
                            <Input
                                size={'sm'}
                                placeholder={
                                    isSRV
                                        ? 'Please enter a Domain'
                                        : 'Please enter a Host IP/FQDN'
                                }
                                data-walkthroughid={
                                    walkthroughIds.endpoints.modal.ufHostInput
                                }
                                onChange={(e) => {
                                    setErrors({ ...errors, record: '' });
                                    setRecord(e.target.value);

                                    const isDomain = validator.isFQDN(
                                        e.target.value,
                                    );
                                    const isIp = validator.isIP(e.target.value);

                                    if (!isDomain && !isIp) {
                                        setErrors({
                                            ...errors,
                                            record: 'Only positive integers are allowed',
                                        });
                                    } else {
                                        setErrors(null);
                                    }
                                }}
                            />
                            {errors?.record ? (
                                <FormErrorMessage>
                                    {errors.record}
                                </FormErrorMessage>
                            ) : (
                                <FormHelperText>
                                    {isSRV
                                        ? 'eg. example.com'
                                        : 'eg. 196.168.123.132 / mail.example.com'}
                                </FormHelperText>
                            )}
                        </FormControl>

                        {/* <TextField
                        sx={{ width: '100%', marginBottom: 2 }}
                        label={isSRV ? 'Domain' : 'Host IP/FQDN'}
                        placeholder={isSRV ? '10.1.1.1 or cdn.example.com' : ''}
                        onChange={(e) => {
                            setErrors({ record: '' });
                            setRecord(e.target.value);

                            const isDomain = validator.isFQDN(e.target.value);
                            const isIp = validator.isIP(e.target.value);

                            if (!isDomain && !isIp) {
                                setErrors({
                                    record: 'Only positive integers are allowed',
                                });
                            } else {
                                setErrors(null);
                            }

                        }}
                        error={errors?.record}
                        helperText={errors?.record}
                        data-walkthroughid={
                            walkthroughIds.endpoints.modal.ufHostInput
                        }
                    /> */}

                        {!isSRV && (
                            <FormControl isInvalid={errors?.port} isRequired>
                                <FormLabel color={darkMode ? 'white' : null}>
                                    Port
                                </FormLabel>
                                <Input
                                    value={port}
                                    type="number"
                                    placeholder="Please enter a Port"
                                    size={'sm'}
                                    onChange={(e) => {
                                        setErrors({ ...errors, port: '' });
                                        setPort(e.target.value);
                                        let reg = new RegExp(/^[1-9]\d*$/).test(
                                            e.target.value,
                                        );
                                        if (!reg) {
                                            setErrors({
                                                port: 'Only positive integers are allowed',
                                            });
                                        } else {
                                            setErrors(null);
                                        }
                                    }}
                                    data-walkthroughid={
                                        walkthroughIds.endpoints.modal
                                            .ufPortInput
                                    }
                                />
                                {errors?.port ? (
                                    <FormErrorMessage>
                                        {errors.port}
                                    </FormErrorMessage>
                                ) : (
                                    <FormHelperText>eg. 5060</FormHelperText>
                                )}
                            </FormControl>

                            // <TextField
                            //     error={errors?.port}
                            //     helperText={errors?.port}
                            //     value={port}
                            //     sx={{ width: '100%', marginBottom: 2 }}
                            //     label="Port"
                            //     type="number"
                            //     onChange={(e) => {
                            //         setErrors({ ...errors, port: '' });
                            //         setPort(e.target.value);
                            //         let reg = new RegExp(/^[1-9]\d*$/).test(
                            //             e.target.value,
                            //         );
                            //         if (!reg) {
                            //             setErrors({
                            //                 port: 'Only positive integers are allowed',
                            //             });
                            //         } else {
                            //             setErrors(null);
                            //         }
                            //     }}
                            //     data-walkthroughid={
                            //         walkthroughIds.endpoints.modal.ufPortInput
                            //     }
                            // />
                        )}
                    </ChakraProvider>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button
                        sx={{ backgroundcolor: '#0d6efd', color: '#ffffff' }}
                        variant="contained"
                        disabled={errors || !record}
                        style={{
                            color: darkMode ? 'white' : null,
                            backgroundColor: darkMode ? '#1976d2' : null,
                            opacity:
                                darkMode && (errors || !record) ? '0.4' : null,
                        }}
                        onClick={() => {
                            onSubmitHandler();
                        }}
                        data-walkthroughid={
                            walkthroughIds.endpoints.modal.ufAddButton
                        }>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default EditSipTerminationEndpoint;
