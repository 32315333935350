import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import walkthroughIds from '../walkthroughIds';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },
  content: {
    padding: '5%',
  },
}));

const useTroubleshoot = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleRARedirect = (e) => {
    e.preventDefault();
    navigate('/virtualuserguide');
  };
  const handleAACQRedirect = (e) => {
    e.preventDefault();
    navigate('/guide');
  };

  return {
    issueHeader: `Can't see all your user accounts?`,
    modalHeader: `Help`,
    issueBody: `TCAP requires the MSOL User's UserType to be 'Member'.\nThis can be done via PowerShell by:`,
    issueCode: `Get-MsolUser -All | Where-Object UserType -eq $Null | Set-MsolUser -UserType 'Member'`,
    issueLink:
      'https://docs.microsoft.com/en-us/powershell/module/msonline/set-msoluser?view=azureadps-1.0',
    clickHereText: `Click here`,
    issueLinkText: ' for more references.',
    aacqHeader: 'Step by Step Guide to Auto Attendant and Queues',
    raHeader: ' Step by Step Guide to licensing a resource account',
    handleRARedirect,
    handleAACQRedirect,
    viewFullGuideText: ' to view our full guide.',
  };
};

/*
 * Pop up when you click the '?' button on any of the accounts pages - this links to some guides on setting up TCAP
 */
export default function Troubleshoot({ handleClose, show }) {
  const classes = useStyles();
  const {
    issueHeader,
    modalHeader,
    issueBody,
    issueCode,
    issueLink,
    clickHereText,
    issueLinkText,
    aacqHeader,
    raHeader,
    handleRARedirect,
    handleAACQRedirect,
    viewFullGuideText,
  } = useTroubleshoot();
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      data-walkthroughid={walkthroughIds.modal.ufModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>{modalHeader} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={classes.root}>
          <div className={classes.content}>
            <h4> {issueHeader} </h4>
            <p style={{ whiteSpace: 'pre-line' }}>{issueBody}</p>
            <p>
              <pre>
                <code className="script">{issueCode}</code>
              </pre>
            </p>
            <p>
              <a
                target="_blank"
                href={issueLink}
                data-walkthroughid={
                  walkthroughIds.modal.ufMicrosoftGuide
                }
              >
                {clickHereText}
              </a>
              {issueLinkText}
            </p>
            <br />
            <h4>{aacqHeader}</h4>
            <p>
              <a
                target="_blank"
                href="/"
                onClick={handleAACQRedirect}
                data-walkthroughid={
                  walkthroughIds.modal.ufAAGuide
                }
              >
                {clickHereText}
              </a>
              {viewFullGuideText}
            </p>
            <br />
            <h4>{raHeader}</h4>
            <p>
              <a
                target="_blank"
                href="/"
                onClick={handleRARedirect}
                data-walkthroughid={
                  walkthroughIds.modal.ufResourceGuide
                }
              >
                {clickHereText}
              </a>
              {viewFullGuideText}
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
