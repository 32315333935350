import React, { useRef } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {TextField} from '@material-ui/core';
import {Row, Col} from 'react-bootstrap';

import {modalTitle} from '../../../components/tables/TableAndModal';
import {MultiChild} from '../../../components';
import config from '../../../config.json';
import SBC from './SBC';

/*
 * Locations tab of the Regions and Locations Tab in TCAP
 * Handles CRUD for Locations
 */
const Locations = (props) => {
    const dispatch = useDispatch();
    const tableRef = useRef();

    // state access
    const {
        data: modalData,
        state: modalState,
        mode: modalMode,
        stateError: modalStateError,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // default columns for Locations tab
    const defaultColumns = [
        {
            title: 'ID',
            field: 'id',
        },
        {
            title: 'Location',
            field: 'location',
        },
    ];

    // change handlers
    const locationChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                location: e.target.value,
            },
        });
    };

    const idChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                id: e.target.value,
            },
        });
    };

    return (
        <MultiChild
            tableRef={tableRef}
            isTrunk
            loadingFieldDropdown={{displayField: 'location'}}
            tableFetch={'/trunklocations/'}
            modalFetch={'/trunklocation/' + modalData?.id}
            modalAdd="/trunklocation/"
            modalUpdate="/trunklocation/"
            tabs={[{details: true}, {component: <SBC />, label: 'SBC'}]}
            size={'md'}
            modalUpload={(freshData, state) => {
                return {
                    ...freshData,
                    ...state,
                };
            }}
            title="Locations"
            noTitle
            defaultColumns={defaultColumns}
            add
            groupable
            filterable
            maxBodyHeight={window.innerHeight * config.tabView.tableHeight}
            modalTitle={modalTitle(modalData, 'id', ' Location ', modalMode)}
            rowActions={{
                multiChildTable: {name: 'Edit Locations and SBCs'},
            }}
            modalValidate={() => {
                const newErrorState = {
                    id:
                        (!modalState?.id || modalState?.id?.length < 1) &&
                        'Field is required.',
                    location:
                        (!modalState?.location ||
                            modalState?.location?.length < 1) &&
                        'Field is required.',
                };
                dispatch({
                    type: 'CHANGE_MODAL_STATE_ERROR',
                    payload: newErrorState,
                });
                return !(newErrorState.id || newErrorState.location);
            }}
            modalContent={
                <>
                    <Row style={{minHeight: 60}}>
                        <Col>
                            <TextField
                                fullWidth
                                id="ID"
                                label="ID"
                                type="text"
                                disabled={modalMode === 'Edit'}
                                value={modalState?.id}
                                error={Boolean(modalStateError?.id)}
                                helperText={modalStateError?.id}
                                onChange={idChangeHandler}
                            />
                        </Col>
                        <Col>
                            <TextField
                                fullWidth
                                id="Location"
                                label="Location"
                                type="text"
                                value={modalState?.location}
                                error={Boolean(modalStateError?.location)}
                                helperText={modalStateError?.location}
                                onChange={locationChangeHandler}
                            />
                        </Col>
                    </Row>
                </>
            }
        />
    );
};

export default Locations;
