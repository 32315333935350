import {createStore, combineReducers, applyMiddleware} from 'redux';
import config from '../config.json';
import isEqual from 'lodash/isEqual';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {navigationListsReducer} from './reducers/navigationLists';
import {tableReducerTypes} from './reducers/table';
import {loginReducer} from './reducers/login';
import {menuReducer} from './reducers/menu';
import {urlReducer} from './reducers/url';
import {modalReducerTypes} from './reducers/modal';
import {headerReducer} from './reducers/header';
import {viewReducer} from './reducers/view';
import {settingsReducer} from './reducers/settings';
import {scriptReducer} from './reducers/scripts';
import {modalChangesMiddleware} from './middlewares/modalChangesMiddleware';
import {webWorkerReducer} from './reducers/webWorker';
import {intervalTrackerReducer} from './reducers/intervalTracker';
import {composeWithDevTools} from '@redux-devtools/extension';
import { themeReducer } from './reducers/theme';

export const isModified = (state, initState) => {
    return !isEqual(state, initState);
};

//make some states persistence upon page reloads:
const rootPersistConfig = {
    key: 'root',
    storage: storage, //localForage, //for webworker - it cant use localstorage!
    whitelist: [
        'menu',
        'login',
        'settings',
        'intervalTracker',
        'script',
        'url',
        'header',
    ], // which reducer want to store
};
const loginPersistConfig = {
    key: 'login',
    storage: storage, // localForage,
    blacklist: ['currentScope'],
};
const pReducer = persistReducer(
    rootPersistConfig,
    combineReducers({
        menu: menuReducer,
        url: urlReducer,
        script: scriptReducer,
        login: persistReducer(loginPersistConfig, loginReducer), //loginReducer,
        navigationLists: navigationListsReducer,
        header: headerReducer,
        view: viewReducer,
        settings: settingsReducer,
        webWorker: webWorkerReducer,
        modal: modalReducerTypes(''),
        table: tableReducerTypes(''),
        trunkBasic: modalReducerTypes(config.trunks.basic.redux),
        intervalTracker: intervalTrackerReducer,
        trunkCollectionHosts: tableReducerTypes(
            config.trunks.collectionHosts.redux,
        ),
        trunkCollectionPorts: tableReducerTypes(
            config.trunks.collectionPorts.redux,
        ),
        trunkTerminationEndpoints: tableReducerTypes(
            config.trunks.terminationEndpoints.redux,
        ),
    theme: themeReducer,
    }),
);

const composeEnhancers = composeWithDevTools({trace: true});

const store = createStore(
    pReducer,
    composeEnhancers(applyMiddleware(modalChangesMiddleware)),
);

export default store;
export const persistor = persistStore(store);
