import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    TextField,
    Checkbox,
    FormControlLabel,
    Typography,
} from '@material-ui/core';
import { Box, Stack } from '@mui/material';
import { IconButton } from '@chakra-ui/react';
import { MdHelp } from 'react-icons/md';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useSelector, useDispatch } from 'react-redux';

import { classNames } from 'utils/classNames';

import { MultiSelect, LoadingFieldDropdown } from '../../../../components';
import LoadingFieldDropdownWithPlaceholder from 'components/presentational/LoadingFieldDropdownWithPlaceholder';
import { onModalError } from '../../../../components/tables/TableAndModal';
import { axios } from '../../../../services/axios';
import useAddress from '../../../../hooks/useAddress';

import walkthroughIds from '../../walkthroughIds';

/*
 * Standard form that is loaded when you click on a company to edit them
 * Also used when adding a company
 */
const General = (props) => {
    const { setState, voicePoliciesAvailable } = props;
    const dispatch = useDispatch();
    const [hasChange, setHasChange] = useState(false);

    const { darkMode } = useSelector((state) => state.settings);
    const navigate = useNavigate();

    // modal data from state
    const {
        data: modalData,
        state,
        mode: modalMode,
        stateError: modalStateError,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // navigation data from state
    const { currentWholesaler } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    // scope data from state
    const { requiredScope } = useSelector((state) => {
        return { ...state.login };
    });

    const { general } = walkthroughIds.companies;

    const handleDefaultPlanButton = () => {
        dispatch({
            type: 'RESET_MODAL',
        });
        dispatch({
            type: 'RESET_TABLE',
        });
        navigate('/plans');
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setHasChange(false);
        }, 4000);

        return () => clearTimeout(timeout);
    }, [!state?.isActive]);

    //renders the company standard form and the modal
    const [addresses, setAddresses] = useState([]);
    const { concatAddressFields } = useAddress();
    useEffect(() => {
        axios.get(`/addresses/${state?.id}`).then((response) => {
            const addressesConcated = response.data.map((address) => {
                const addressConcated = concatAddressFields(address);
                if (address.description.length) {
                    return {
                        ...address,
                        description: `${address.description} - ${addressConcated}`,
                    };
                }
                return {
                    ...address,
                    description: addressConcated,
                };
            });
            setAddresses(addressesConcated);
        });
    }, []);

    return (
        <Stack
            sx={{
                padding: '0',
                marginTop: 2,
                marginBottom: 2,
                marginInline: '8%',
            }}
            spacing={2}>
            {/* Friendly Company Name*/}
            {modalMode === 'Add' && (
                <TextField
                    fullWidth
                    placeholder="Company Name"
                    size="small"
                    label="Company Name"
                    error={Boolean(modalStateError?.name)}
                    helperText={modalStateError?.name}
                    value={state?.name}
                    onChange={(e) => {
                        setState({ name: e.target.value });
                        setState({ entityName: e.target.value });
                    }}
                    data-walkthroughid={general.ufCompanyNameInput}
                />
            )}

            {modalMode !== 'Add' && (
                <>
                    <TextField
                        fullWidth
                        placeholder="Friendly Company Name"
                        size="small"
                        label="Friendly Company Name"
                        error={Boolean(modalStateError?.name)}
                        helperText={modalStateError?.name}
                        value={state?.name}
                        onChange={(e) => {
                            setState({ name: e.target.value });
                        }}
                        data-walkthroughid={general.ufFriendlyNameInput}
                    />

                    <TextField
                        fullWidth
                        placeholder="Legal Entity Name"
                        size="small"
                        label="Legal Entity Name"
                        error={Boolean(modalStateError?.entityName)}
                        helperText={modalStateError?.entityName}
                        value={state?.entityName}
                        onChange={(e) => {
                            setState({ entityName: e.target.value });
                        }}
                        data-walkthroughid={general.ufLegalNameInput}
                    />
                    {state?.initialDomain && (
                        <Typography style={{ paddingLeft: '5px' }}>
                            Initial Domain: {state.initialDomain}
                        </Typography>
                    )}

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state?.isActive}
                                onChange={() => {
                                    setState({
                                        isActive: !state?.isActive,
                                    });
                                    setHasChange(true);
                                }}
                                data-walkthroughid={general.ufActiveCheckbox}
                            />
                        }
                        label="Active"
                    />

                    {!state.isActive && hasChange && (
                        <Alert severity="info" color="info">
                            <AlertTitle>
                                {`Status is inactive! Please save your change before navigating away from this page.`}
                            </AlertTitle>
                        </Alert>
                    )}

                    {/* Addresses */}
                    {modalMode !== typeof undefined && (
                        <LoadingFieldDropdown
                            staticEnum={addresses}
                            searchable
                            fieldName="Default Address"
                            noEmptyOption
                            dropDownValue="id"
                            displayField="description"
                            onChange={(v) => {
                                setState({
                                    primaryAddressID: v,
                                });
                            }}
                            fieldValue={state?.primaryAddressID}
                            onError={onModalError}
                            dataWalkthroughid={general.ufDefaultAddressSelect}
                        />
                    )}

                    {/* Selective Sync */}
                    <LoadingFieldDropdown
                        fieldName="Selective Sync"
                        dropDownValue="value"
                        onError={onModalError}
                        displayField="label"
                        onChange={(v) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    selectiveSyncPolicy: v,
                                },
                            });
                        }}
                        fieldValue={state?.selectiveSyncPolicy}
                        staticEnum={[
                            { value: 0, label: 'Disabled' },
                            { value: 1, label: 'Enabled' },
                            { value: 2, label: 'Required' },
                        ]}
                        noEmptyOption
                        dataWalkthroughid={general.ufSelectiveSyncSelect}
                    />
                </>
            )}

            {/* Default Plan*/}
            {modalMode === 'Add' && (
                <>
                    <Box className="group relative">
                        {/* Default Plan */}
                        <LoadingFieldDropdownWithPlaceholder
                            searchable
                            fieldName="Default Plan"
                            dropDownValue="id"
                            onError={onModalError}
                            displayField="name"
                            onChange={(v) => {
                                dispatch({
                                    type: 'CHANGE_MODAL_STATE',
                                    payload: {
                                        defaultPlanID: v,
                                    },
                                });
                            }}
                            fieldValue={state?.defaultPlanID}
                            toggleLimit={5}
                            hasDependency
                            dependency={state?.partnerID}
                            fieldFetch={`/partner/${state?.partnerID}/plans`}
                            noEmptyOption
                            placeholder={'Please select a default plan'}
                            dataWalkthroughid={general.ufDefaultPlanSelect}
                            handleDefaultPlanButton={handleDefaultPlanButton}
                        />
                        {state['Default Plan']?.length === 0 && (
                            <>
                                <span
                                    onClick={handleDefaultPlanButton}
                                    className={classNames(
                                        darkMode
                                            ? 'bg-[#FFFFFF29] text-gray-100'
                                            : 'bg-gray-800 text-gray-100',
                                        `absolute left-[6.5%] top-[15%] m-4 mx-auto -translate-x-1/2 translate-y-full cursor-pointer rounded-md bg-gray-800 
    px-2 py-2 text-sm text-gray-100 opacity-0 transition-opacity group-hover:opacity-100`,
                                    )}>
                                    <IconButton
                                        id="default-plan-tooltip"
                                        aria-label="tooltip"
                                        mx={2}
                                        mb="1px"
                                        className="cursor-pointer"
                                        as="span"
                                        icon={<MdHelp />}
                                    />
                                    Setup a default plan
                                </span>
                            </>
                        )}
                    </Box>
                </>
            )}

            {modalMode !== 'Add' && (
                <>
                    {/* Default Trunk */}
                    <LoadingFieldDropdown
                        searchable
                        fieldName="Default Trunk"
                        dropDownValue="id"
                        onError={onModalError}
                        displayField="sipHeader"
                        onChange={(v) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    defaultTrunkID: v,
                                },
                            });
                        }}
                        fieldValue={state?.defaultTrunkID}
                        fieldFetch={'/trunks/' + modalData?.id}
                        dataWalkthroughid={general.ufDefaultTrunkSelect}
                    />

                    {/* Engine Locations */}
                    <MultiSelect
                        onError={onModalError}
                        label="Engine Locations"
                        placeholder="+"
                        getOptionLabel={(item) => item.name}
                        fieldFetch={'/enginelocations'}
                        dependency={modalData?.id}
                        setState={(v) =>
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    engineLocations: v,
                                },
                            })
                        }
                        value={state?.engineLocations}
                    />

                    {/* Business Number */}
                    <TextField
                        fullWidth
                        placeholder="Company Registration Number"
                        size="small"
                        label="Company Registration Number"
                        helperText={modalStateError?.businessNumber}
                        value={state?.businessNumber}
                        onChange={(e) => {
                            setState({
                                businessNumber: e.target.value,
                            });
                        }}
                        data-walkthroughid={
                            general.ufCompanyRegistrationNumberInput
                        }
                    />

                    {/* Primary Contact Name */}

                    <TextField
                        fullWidth
                        placeholder="Primary Contact Name"
                        size="small"
                        label="Primary Contact Name"
                        helperText={modalStateError?.primaryContactName}
                        value={state?.primaryContactName}
                        onChange={(e) => {
                            setState({
                                primaryContactName: e.target.value,
                            });
                        }}
                        data-walkthroughid={general.ufPrimaryContactNameInput}
                    />

                    {/* Primary Contact Phone */}
                    <TextField
                        fullWidth
                        placeholder="Primary Contact Phone"
                        size="small"
                        label="Primary Contact Phone"
                        helperText={modalStateError?.primaryContactPhone}
                        value={state?.primaryContactPhone}
                        onChange={(e) => {
                            setState({
                                primaryContactPhone: e.target.value,
                            });
                        }}
                        data-walkthroughid={general.ufPrimaryContactPhoneInput}
                    />

                    {/* Primary Contact Email */}
                    <TextField
                        fullWidth
                        placeholder="Primary Contact Email"
                        size="small"
                        label="Primary Contact Email"
                        helperText={modalStateError?.primaryContactEmail}
                        value={state?.primaryContactEmail}
                        onChange={(e) => {
                            setState({
                                primaryContactEmail: e.target.value,
                            });
                        }}
                        data-walkthroughid={general.ufPrimaryContactEmailInput}
                    />

                    {/* Accounts Contact Email */}
                    <TextField
                        fullWidth
                        placeholder="Accounts Contact Email"
                        size="small"
                        label="Accounts Contact Email"
                        helperText={modalStateError?.accountsContactEmail}
                        value={state?.accountsContactEmail}
                        onChange={(e) => {
                            setState({
                                accountsContactEmail: e.target.value,
                            });
                        }}
                        data-walkthroughid={general.ufAccountsContactEmailInput}
                    />

                    {/* Support Contact Email */}
                    <TextField
                        fullWidth
                        placeholder="Support Contact Email"
                        size="small"
                        label="Support Contact Email"
                        helperText={modalStateError?.supportContactEmail}
                        value={state?.supportContactEmail}
                        onChange={(e) => {
                            setState({
                                supportContactEmail: e.target.value,
                            });
                        }}
                        data-walkthroughid={general.ufSupportContactEmailInput}
                    />
                </>
            )}
        </Stack>
    );
};

export default General;
