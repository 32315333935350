import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getNotification } from '@/API/General/getNotification';
import path from '@/routes/constants';

/**
 * Custom hook for functions and states related to users
 * @typedef {Object} useUsersProps
 * @property {'loading' | 'success' | 'error'} syncState - sync state of company button
 * @property {boolean} syncButtonCondition - condition for sync button from company status
 * @property {(rowData: Object) => void} handleEditBtn - Navigate function to edit button click, passing row data
 * @property {() => void} handleBuilderBtn - Navigate function to builder page
 * @property {() => void} handleSyncStateClick - handle sync button click event
 * @property {() => void} handleCsvUpdate -  Navigate function to csv update page
 * @returns {useUsersProps}
 */
export default function useUsers() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { CSVUSERS, CSVRESOURCEACCOUNTS } = path;
    const isUserPath = pathname.includes('/users');
    const { currentCompany, currentCompanyData } = useSelector(
        (state) => state.navigationLists,
    );
    const [syncState, setSyncState] = useState('idle');
    // NOTE: true condition to hack sync button of pending company
    const syncButtonCondition =
        currentCompanyData?.status === 0 ||
        (currentCompanyData?.status > 11 && currentCompanyData?.status < 20) ||
        true;

    const handleEditBtn = (rowData) => {
        navigate(`${pathname}/${rowData?.id}/edit`, {
            state: { rowData },
        });
    };

    const handleCsvUpdate = () => {
        navigate(`${pathname}/${isUserPath ? CSVUSERS : CSVRESOURCEACCOUNTS}`);
    };

    // resource acc
    const handleBuilderBtn = () => {
        navigate(`${pathname}/builder`);
    };

    const handleSyncStateClick = () => {
        setSyncState('loading');
        getNotification('company', currentCompany)
            .then(() => {
                setSyncState('succeed');
            })
            .catch((e) => {
                console.log(e);
                setSyncState('error');
            });
    };

    return {
        syncState,
        syncButtonCondition,
        handleEditBtn,
        handleBuilderBtn,
        handleSyncStateClick,
        handleCsvUpdate,
    };
}
