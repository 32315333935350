import {axios} from '../../services/axios';

export default async function updateServiceProfiles({
    type,
    level,
    payload,
    scope,
}) {
    try {
        const result = await axios.put(
            `/serviceprofile/${type}/${level}`,
            payload,
            {
                headers: {
                    'X-RequestScope': scope,
                },
            },
        );
        return result;
    } catch (e) {
        throw e;
    }
}
