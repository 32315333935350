import { Box, ChakraProvider, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Heading from '../../components/v4/Heading';
import Text from '../../components/v4/Text';
import chakratheme from '../../chakratheme';

export default function FourOFour() {
    const navigate = useNavigate();
    const onClick = () => {
        navigate('/dashboard-services');
    };
    return (
        <ChakraProvider theme={chakratheme} resetCSS={true}>
            <Box sx={{ margin: '250px auto' }}>
                <Heading as="h1" sx={{ textAlign: 'center' }}>
                    404
                </Heading>
                <Text sx={{ textAlign: 'center' }}>
                    We could not find the page you are looking for.
                </Text>
                <Box className="mt-10 flex items-center justify-center gap-x-6">
                    <Button onClick={onClick}>Go to Dashboard Services</Button>
                </Box>
            </Box>
        </ChakraProvider>
    );
}
