import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { newTableMap } from 'pages/accounts/resourceAccounts/table';

/**
 * Custom hook to handle alphabetize and table toolbar actions, used for RA and Users table
 * @typedef {Object} TableToolbarType
 * @property {Array} data - data to be filtered
 * @property {import('material-table').Column<any>[]} defaultColumns - columns of table
 * @property {<TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined) => Promise<QueryObserverResult<any, unknown>>} refetch
 * @property {import('react').Dispatch<SetStateAction<{columns: Column<any>[];data: never[];}[]>} setTableData - table data set after filter
 * @param {TableToolbarType} props
 * @returns
 */
export default function useTableToolbar({
    data,
    defaultColumns,
    refetch,
    setTableData,
}) {
    const newTableData = newTableMap(data);
    const [alphabetOpen, setAlphabetOpen] = useState(false);
    const [, force] = useState();
    const dispatch = useDispatch();
    const { alphabet } = useSelector((state) => state.table);
    const refreshFn = () => {
        dispatch({
            type: 'CHANGE_TABLE_LOADING',
        });
        dispatch({
            type: 'CHANGE_TABLE_FILTERING',
        });
        dispatch({
            type: 'CHANGE_TABLE_ALPHABET_FILTER',
            payload: 'All',
        });
        refetch();
    };

    useEffect(() => {
        if (data) {
            dispatch({
                type: 'CHANGE_TABLE_INDEX',
                payload: [
                    ...new Set(
                        newTableData?.map((v) => {
                            if (
                                v.displayName[0].toUpperCase() < 'A' ||
                                v.displayName[0].toUpperCase() > 'Z'
                            ) {
                                return '#';
                            }
                            return v.displayName[0].toUpperCase();
                        }),
                    ),
                ].sort((a, b) => {
                    return a.localeCompare(b);
                }),
            });
            setTableData({
                columns: defaultColumns,
                data: newTableData,
            });
        }
    }, [data]);

    const alphabetFilter = async (alpha, field) => {
        if (alpha === 'All') {
            setTableData({
                columns: defaultColumns,
                data: newTableData,
            });
            return;
        }
        const filtered = newTableData?.filter((rowData) => {
            return (
                (alpha === '#' &&
                    (rowData[field][0].toUpperCase() < 'A' ||
                        rowData[field][0].toUpperCase() > 'Z')) ||
                rowData[field][0].toUpperCase() === alpha
            );
        });

        setTableData({
            columns: defaultColumns,
            data: filtered,
        });
        force(); // NOTE: hack to filter table
    };
    return {
        alphabet,
        alphabetOpen,
        setAlphabetOpen,
        alphabetFilter,
        refreshFn,
    };
}
