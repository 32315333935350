import { Box, Grid } from '@mui/material';
import { TextField } from '@material-ui/core';

import { TextEditor } from '@/components/index';
import AutoFillMultiSelect from '@/components/presentational/FillOnClickMultiselect';

import walkthroughIds from '@/pages/organisations/walkthroughIds';

const { billing } = walkthroughIds.companies;

// component to determine the way to format the fields for the invoice
export const InvoiceFormatFields = ({
    fields,
    format,
    globalCursorPosition,
    setGlobalCursorPosition,
    billingState,
    setBillingState,
}) => (
    <Box key={format.value} sx={{ flexGrow: 1, marginTop: 2 }}>
        <Grid container spacing={2}>
            {billingState[fields.value].length > 0 && (
                <Grid item xs={12}>
                    <AutoFillMultiSelect
                        staticEnum={
                            billingState[fields.value].length > 0
                                ? billingState[fields.value]
                                      .split(',')
                                      .map((v) => ({
                                          id: '[' + v.trim() + ']',
                                          name: v.trim(),
                                      }))
                                : []
                        }
                        activeTextareaID={format.value}
                        activeTextareaField={format.value}
                        setState={setBillingState}
                        label={fields.label}
                        setCursorPosition={(value) => {
                            setGlobalCursorPosition({
                                ...globalCursorPosition,
                                [format.value]: value,
                            });
                        }}
                        cursorPosition={globalCursorPosition[format.value] ?? 0}
                        dataWalkthroughId={`${billing.ufAccordionSelect}/${format.value}`}
                    />
                </Grid>
            )}
            <Grid item xs={6}>
                {fields ? (
                    <TextEditor
                        id={format.value}
                        label={format.label}
                        setCursorPosition={(value) => {
                            setGlobalCursorPosition({
                                ...globalCursorPosition,
                                [format.value]: value,
                            });
                        }}
                        textfield={{
                            value: billingState[format.value],
                            defaultValue: billingState[format.value],
                            label: format.label,
                            rows: false,
                            rowsMax: false,
                            multiline: false,
                            onChange: (e) => {
                                setBillingState({
                                    [format.value]: e.target.value,
                                });
                            },
                        }}
                        dataWalkthroughId={`${billing.ufAccordionInput}/${format.value}`}
                    />
                ) : (
                    <TextField
                        id={format.value}
                        label={format.label}
                        size="small"
                        value={billingState[format.value]}
                        fullWidth
                        onChange={(e) => {
                            setBillingState({
                                [format.value]: e.target.value,
                            });
                        }}
                        data-walkthroughid={`${billing.ufAccordionInput}/${format.value}`}
                    />
                )}
            </Grid>
        </Grid>
    </Box>
);
