import { axios } from '@/services/axios';

const getTrunkTypesSpPartner = async (partnerId) => {
    try {
        const trunkResult = await axios.get(
            `/ServiceProfiles/SBCaaS/Partner/${partnerId}/TrunkTypes/Available`,
        );
        return trunkResult;
    } catch (e) {
        throw new Error(e);
    }
};

export default getTrunkTypesSpPartner;
