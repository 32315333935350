import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Text, Radio, RadioGroup, Stack } from '@chakra-ui/react';

import { Alert, AlertIcon, AlertDescription, Button } from 'components/v4';
import { useTpmContext } from './TpmProvider';
import walkthroughIds from './walkthroughIds';

const EMAIL_SUCCCESS_MESSAGE = `A verification code has been sent to the primary
account holder email.`;
const SMS_SUCCESS_MESSAGE = `A verification code has been sent to the primary account holder mobile number.`;

function AuthorizationProcess() {
    const {
        tpmNumber: [state],
        tpmAddress: [, setAddressAction],
    } = useTpmContext();
    const [sendMethod, setSendMethod] = useState('EMAIL');
    const [status, setStatus] = useState('IDLE');
    const [primaryAccountHolder, setPrimaryAccountHolder] = useState('1');
    const { darkMode } = useSelector((state) => state.settings);
    const {
        ufAuthorizationSendSmsButton,
        ufAuthorizationSendEmailButton,
        ufAuthorizationCancelButton,
    } = walkthroughIds.teamsPhoneMobile;

    const handlePrimaryAccountHolder = (v) => {
        setPrimaryAccountHolder(v);
    };

    const isPrimaryAccountHolder = primaryAccountHolder === '1';
    const radioButtonStyles = {
        label: {
            marginBottom: 0,
        },
    };

    const handleEmailButtonClick = () => {
        // TODO: BE call for send method email  / sms.
        setStatus('LOADING');
        setTimeout(() => {
            setStatus('SUCCESS');
            setSendMethod('EMAIL');
        }, 500);
        setStatus('IDLE');
    };
    const handleSMSButtonClick = () => {
        // TODO: BE call for send method email  / sms.
        setStatus('LOADING');
        setTimeout(() => {
            setStatus('SUCCESS');
            setSendMethod('SMS');
            setAddressAction('SMS_AUTHORIZATION');
        }, 500);
    };

    const handleCancelButtonClick = () => {
        setAddressAction('GET');
    };

    useEffect(() => {
        const timeout = () =>
            setTimeout(() => {
                setStatus('IDLE');
            }, 3000);
        if (status === 'SUCCESS') {
            timeout();
        }

        return () => clearTimeout(timeout);
    }, [status]);

    return (
        <Box w={600} className="mx-auto">
            {/* Main Content */}
            {status === 'SUCCESS' && (
                <Box className="flex max-w-[600px]">
                    <Alert status="success">
                        <AlertIcon />
                        <AlertDescription className="text-base">
                            {sendMethod === 'EMAIL'
                                ? EMAIL_SUCCCESS_MESSAGE
                                : SMS_SUCCESS_MESSAGE}
                        </AlertDescription>
                    </Alert>
                </Box>
            )}
            <Box className="py-16">
                <Text
                    as="h6"
                    className="mb-6 text-center text-lg font-semibold">
                    Let&apos;s authorize your service
                </Text>
                {/* TEMP BE response */}
                <Box className="mb-6 text-center">
                    <RadioGroup
                        name="primary-account-holder"
                        onChange={handlePrimaryAccountHolder}
                        value={primaryAccountHolder}>
                        <Stack
                            direction="row"
                            justifyContent={'center'}
                            sx={radioButtonStyles}>
                            <Radio value="1">Primary</Radio>
                            <Radio value="0">Not Primary</Radio>
                        </Stack>
                    </RadioGroup>
                </Box>
                <Box>
                    {isPrimaryAccountHolder ? (
                        <Text className="text-center text-base">
                            {' '}
                            You are the primary account holder.{' '}
                        </Text>
                    ) : (
                        <Text className="text-center text-base">
                            {' '}
                            You are not the primary account holder for &nbsp;
                            <span className="ml-0 font-semibold">
                                {state.number}
                            </span>
                            . A verification code can be sent to the primary
                            account holder via email or sms.
                        </Text>
                    )}
                    <Box className="mt-12 flex justify-between">
                        <Button
                            variant="outline"
                            colorScheme="messenger"
                            bg={darkMode ? 'white' : null}
                            onClick={handleCancelButtonClick}
                            data-walkthroughid={ufAuthorizationCancelButton}>
                            Cancel
                        </Button>
                        {!isPrimaryAccountHolder && (
                            <Button
                                colorScheme="green"
                                onClick={handleEmailButtonClick}
                                data-walkthroughid={
                                    ufAuthorizationSendEmailButton
                                }>
                                Send Email
                            </Button>
                        )}
                        <Button
                            colorScheme="messenger"
                            onClick={handleSMSButtonClick}
                            data-walkthroughid={ufAuthorizationSendSmsButton}>
                            Send SMS
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
export default AuthorizationProcess;
